import { NavigateFunction } from 'react-router-dom'

import { UserAuthData } from 'common/types/user'
import { UserInstanceData } from 'common/types/instance'
import { LicenseData } from 'common/types/license'
import { AppInstanceInfo } from 'common/types/application'
import { Code } from 'common/types/code'
import { FileManagerData } from 'common/types/fileManager'

// Use enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.

export enum UserActionTypes {
  LOGIN = '@@user/LOGIN',
  LOGOUT = '@@user/LOGOUT',
  SELECT_COMPANY = '@@user/SELECT_COMPANY',
  SET_USER_AUTH_DATA = '@@user/SET_USER_AUTH_DATA',
  SET_USER_INSTANCE_DATA = '@@user/SET_USER_INSTANCE_DATA',
  SET_SELECTED_COMPANY_ID = '@@user/SET_SELECTED_COMPANY_ID',
  LOAD_USER_INSTANCE_DATA = '@@user/LOAD_USER_INSTANCE_DATA',
  SET_USER = '@@user/SET_USER',
  GET_USER = '@@user/GET_USER',
  SELECT_USER = '@@user/SELECT_USER',
  SELECTED = '@@user/SELECTED',
  SET_LICENSE_DATA = '@@user/SET_LICENSE_DATA',
  SET_INVALID_AUTH_ERROR = '@@user/SET_INVALID_AUTH_ERROR',
  CHANGE_ACTIVE_INSTANCE = '@@user/CHANGE_ACTIVE_INSTANCE',
  LOAD_CODE_LAYOUTS = '@@user/LOAD_CODE_LAYOUTS',
  SET_CODE_LAYOUTS = '@@user/SET_CODE_LAYOUTS',
  SET_SELECTED_CODE_LAYOUT = '@@user/SET_SELECTED_CODE_LAYOUT',
  TOGGLE_RESULT_TOOLBAR = '@@user/TOGGLE_RESULT_TOOLBAR',
  EDIT_USER_PROFILE = '@@user/EDIT_USER_PROFILE',
  SET_CODE_DIVIDER = '@@user/SET_CODE_DIVIDER',
  SET_CODE_EXPANDED = '@@user/SET_CODE_EXPANDED',
  SET_WIDGET_EXPANDED = '@@user/SET_WIDGET_EXPANDED',
  SET_LAST_PATH = '@@user/SET_LAST_PATH',
  VIEW_PREFERENCE = '@@user/VIEW_PREFERENCE',
  SELECTED_FROM_SEARCH = '@@user/SELECTED_FROM_SEARCH',
  PRESERVE_CONSOLE_LOG = '@@user/PRESERVE_CONSOLE_LOG',
  CHANGE_COMPANY = '@@user/CHANGE_COMPANY',
  COMPANY_HAS_CHANGED = '@@user/COMPANY_HAS_CHANGED',
  RESET_APP_USER_INFO = '@@user/RESET_APP_USER_INFO',
  SHOW_HIDDEN_FILES = '@@user/SHOW_HIDDEN_FILES',
  NEED_TO_CHANGE_PASSWORD = '@@user/NEED_TO_CHANGE_PASSWORD',
  ACTIVATE_EXTERNAL_INSTANCE = '@@user/ACTIVATE_EXTERNAL_INSTANCE',
  CONTINUE_LOGIN = '@@user/CONTINUE_LOGIN',
  ENABLE_LOGIN = '@@user/ENABLE_LOGIN',
  IS_LOADING_COMPANY = '@@user/IS_LOADING_COMPANY',
  RELOAD_INSTANCES = '@@user/RELOAD_INSTANCES',
  SAML_SELECT_COMPANY = '@@user/SAML_SELECT_COMPANY'
}

interface ReturnedActionType<T, P> {
  type: T
  payload: P
}

interface UserActionType<P> extends ReturnedActionType<UserActionTypes, P> {}

export interface IdNumberPayload extends UserActionType<{ id: number }> {}
export interface SKStringAndModelinfoPayload
  extends UserActionType<{ newInstanceId: string; newApplicationInfo: AppInstanceInfo }> {}
export interface HistoryPayload extends UserActionType<{ navigate: NavigateFunction }> {}
export interface HistoryAndStringPayload
  extends UserActionType<{
    navigate: NavigateFunction
    data?: string | null
    pathname: string
    disableOpenWithLoginAction?: boolean
  }> {}
export interface UserInstanceDataPayload
  extends UserActionType<{ userInstanceData: UserInstanceData }> {}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface UserState {
  readonly authData?: UserAuthData
  readonly instanceData?: UserInstanceData
  readonly selectedCompanyId?: number | undefined
  readonly licenseData?: LicenseData
  readonly authErrorMsg?: string
  readonly code?: Code
  readonly fileManager?: FileManagerData
  readonly companyHasChanged?: boolean
  readonly needToChangePassword?: boolean
  readonly isLoadingCompany?: boolean
  readonly reloadInstances?: boolean
}
