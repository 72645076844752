import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'
import { INSTANCE_ID } from 'common/api/storageConsts'
import { UserAuthData } from 'common/types/user'
import { LicenseData } from 'common/types/license'
import {
  UserInstanceData,
  InstanceData,
  SystemResources,
  ResourceOption
} from 'common/types/instance'
import {
  ChangeExpiredPasswordResponse,
  ImageTag,
  SetQRCodeResponse,
  VerifyMFAAuthResponse
} from 'common/types/security'

const ENDPOINT = '/security'

class SecurityApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right bjsonplaceholder.typicode.comefore the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, SecurityApi.prototype)
    this.loggedIn = this.loggedIn.bind(this)
    this.setCSRF = this.setCSRF.bind(this)
    this.logIn = this.logIn.bind(this)
    this.createInstance = this.createInstance.bind(this)
    this.getInstance = this.getInstance.bind(this)
    this.logOut = this.logOut.bind(this)
    this.getMyCompanyInstances = this.getMyCompanyInstances.bind(this)
  }

  public async loggedIn(): Promise<boolean> {
    const cleanLocalStorage = () => {
      sessionStorage.removeItem(INSTANCE_ID)
    }
    try {
      const res: AxiosResponse<boolean> = await this.get<string, AxiosResponse<boolean>>(
        '/session/'
      )
      if (res.status === 200 || res.statusText === 'OK') {
        return true
      }
      cleanLocalStorage()
      return false
    } catch (error) {
      cleanLocalStorage()
      return false
    }
  }

  public async setCSRF(): Promise<any> {
    const res: AxiosResponse<any> = await this.get<string, AxiosResponse<any>>('/set-csrf/')
    return this.success(res)
  }

  public async logIn({
    username,
    password
  }: {
    username: string
    password: string
  }): Promise<UserAuthData> {
    const res: AxiosResponse<UserAuthData> = await this.post<
      string,
      { username: string; password: string },
      AxiosResponse<UserAuthData>
    >('/login/', { username: username, password: password }, {})
    return this.success(res)
  }

  public async createInstance({
    companyId,
    companyCode
  }: {
    companyId?: number
    companyCode?: string
  }): Promise<[true, UserInstanceData] | [false, LicenseData | any]> {
    try {
      const res: AxiosResponse<UserInstanceData | LicenseData> = await this.post<
        string,
        { companyId?: number; companyCode?: string },
        AxiosResponse<UserInstanceData | LicenseData>
      >(`${ENDPOINT}/createInstance`, {
        ...(companyId ? { companyId } : null),
        ...(companyCode ? { companyCode } : null)
      })
      return [true, this.success(res) as UserInstanceData]
    } catch (error: any) {
      return [false, error.response?.data as LicenseData | any | string]
    }
  }

  public async getInstance(): Promise<UserInstanceData> {
    const res: AxiosResponse<any> = await this.post<string, AxiosResponse<UserInstanceData>>(
      `${ENDPOINT}/getInstance`
    )
    return this.success<UserInstanceData>(res)
  }

  public async logOut(): Promise<any> {
    try {
      const res: AxiosResponse<void> = await this.get<string, AxiosResponse<void>>(
        `${ENDPOINT}/logout/`
      )
      return this.success(res)
    } catch (error) {
      return false
    }
  }
  public async unlockUser(username: string): Promise<any> {
    try {
      const res: AxiosResponse<any> = await this.post(
        `${ENDPOINT}/unlockUser/?username=${username}`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getMyCompanyInstances(companyId?: number | string): Promise<InstanceData[]> {
    try {
      const query = companyId ? `?companyId=${companyId}` : ''
      const res: AxiosResponse<InstanceData[]> = await this.get(
        `${ENDPOINT}/getMyCompanyInstances/${query}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getAllInstances(sort: string, withApp?: boolean): Promise<InstanceData[]> {
    try {
      const res: AxiosResponse<InstanceData[]> = await this.get(
        `${ENDPOINT}/getAllInstances/?only_with_app=${
          withApp === false ? false : true
        }&sort=${sort}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async killInstanceById(
    instanceId: string,
    sendNotification: boolean = true
  ): Promise<void> {
    const query = `?instance_id=${instanceId}&send_notification=${sendNotification}`
    await this.get(`${ENDPOINT}/killInstanceById/${query}`)
  }
  public async requireLogout(instanceId: string): Promise<void> {
    const query = `?instance_id=${instanceId}`
    await this.get(`${ENDPOINT}/requireLogout/${query}`)
  }
  public async getSystemResources(instanceId?: string): Promise<SystemResources> {
    try {
      let query = ''
      if (instanceId) {
        query = `?instance_id=${instanceId}`
      }
      const res: AxiosResponse<SystemResources> = await this.get(
        `${ENDPOINT}/getSystemResources/${query}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async changePassword(password: string): Promise<any> {
    try {
      const res: AxiosResponse<any> = await this.post(
        `${ENDPOINT}/changePassword/`,
        { password },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async validateRepeatedPassword(password: string): Promise<ChangeExpiredPasswordResponse> {
    try {
      const res: AxiosResponse<ChangeExpiredPasswordResponse> = await this.post(
        `${ENDPOINT}/validateRepeatedPassword/`,
        { password },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async setMultiFactorAuth(userId: string, hostName: string): Promise<SetQRCodeResponse> {
    try {
      const res: AxiosResponse<SetQRCodeResponse> = await this.post(
        '/multiFactorAuth/',
        { userId, hostName },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async verifyMultiFactorAuth(token: string): Promise<VerifyMFAAuthResponse> {
    try {
      const res: AxiosResponse<VerifyMFAAuthResponse> = await this.post(
        '/multiFactorAuth/verifyOTPCode/',
        { token },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async sendEmailAuthenticationCode(): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(
        '/multiFactorAuth/sendEmailAuthenticationCode/',
        {},
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async verifyEmailAuthenticationCode(code: string): Promise<VerifyMFAAuthResponse> {
    try {
      const res: AxiosResponse<VerifyMFAAuthResponse> = await this.post(
        '/multiFactorAuth/verifyEmailAuthenticationCode/',
        { code },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getImageTag(): Promise<ImageTag> {
    try {
      const res: AxiosResponse<ImageTag> = await this.get(`${ENDPOINT}/getImageTag/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async getUserToken(): Promise<string> {
    const res: AxiosResponse<string> = await this.get<string, AxiosResponse<string>>(
      `${ENDPOINT}/getUserToken/`
    )
    return this.success(res)
  }

  public async ping(): Promise<boolean> {
    const res: AxiosResponse<string> = await this.get<string, AxiosResponse<string>>(
      `${ENDPOINT}/ping/`
    )
    return res.status === 200
  }

  public async getResourceList(): Promise<ResourceOption[]> {
    const res: AxiosResponse<ResourceOption[]> = await this.get(`${ENDPOINT}/getResourceList/`)
    return this.success(res)
  }
}

export const securityApi = new SecurityApi(apiConfig)
