import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios'
import qs from 'qs'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'

import { MainFolder } from 'common/types/home'
import { ZipParams } from 'common/types/fileManager'
import { UploaderParams } from 'store/socket/constants'

const FILE_MANAGER = '/fileManager'

interface DownloadParams {
  sources: string[]
}

interface CopyOrMoveData {
  sources: string[]
  target: string
}

interface CopyAppToWorkspaceData {
  source: string
}
interface copyAppToTeamData {
  source: string
  team_folder: string
}

export class FileManagerApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, FileManagerApi.prototype)
    this.getMainFolders = this.getMainFolders.bind(this)
    this.downloadFileOrFolder = this.downloadFileOrFolder.bind(this)
  }

  public async getWorkspaceAndPublic(): Promise<MainFolder[]> {
    try {
      const res: AxiosResponse<MainFolder[]> = await this.get(
        `${FILE_MANAGER}/getWorkspaceAndPublic/`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {string} path -Location of the folder.
   * @description Get size of a folder.
   */
  public async getFolderSize(path: string): Promise<{ result: string }> {
    try {
      const query = `?folder=${encodeURIComponent(path)}`
      const res: AxiosResponse<{ result: string }> = await this.get(
        `${FILE_MANAGER}/getFolderSize/${query}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {string} text -File or folder name to search.
   * @description Search a file or folder.
   */
  public async search(text: string, folder_path: string, hidden?: boolean): Promise<MainFolder[]> {
    try {
      const query = `?text=${encodeURIComponent(
        text
      )}&hidden=${hidden}&folder_path=${encodeURIComponent(folder_path)}`
      const res: AxiosResponse<MainFolder[]> = await this.get(`${FILE_MANAGER}/search/${query}`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {string} source -Location of the file or folder to rename.
   * @param {string} newName -New name of the folder or file.
   * @description Rename a file or folder.
   */
  public async renameFileOrFolder(source: string, newName: string): Promise<{ path: string }> {
    try {
      const data = { source: source.trim(), newName: newName.trim() }
      const res: AxiosResponse<{ path: string }> = await this.post(
        `${FILE_MANAGER}/renameFile/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async getMainFolders(): Promise<MainFolder[]> {
    const res: AxiosResponse<MainFolder[]> = await this.get(`${FILE_MANAGER}/getMainFolders/`)
    return this.success(res)
  }

  /**
   *
   * @param {string} fullPath -Location of the folder.
   * @description Get childrens of a folder.
   */
  public async getFoldersAndFiles(fullPath: string): Promise<MainFolder[]> {
    const res: AxiosResponse<MainFolder[]> = await this.get(
      `${FILE_MANAGER}/getFiles/?folder=${encodeURIComponent(fullPath)}`
    )
    return this.success(res)
  }

  public async copyFiles(params: CopyOrMoveData): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.get(`${FILE_MANAGER}/copyFiles/`, {
        params,
        paramsSerializer: (params: CopyOrMoveData) =>
          qs.stringify(params, { arrayFormat: 'repeat' })
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async copyAppToWorkspace(params: CopyAppToWorkspaceData): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.get(`${FILE_MANAGER}/copyAppToWorkspace/`, {
        params
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async copyAppToTeam(params: copyAppToTeamData): Promise<boolean> {
    try {
      const res: AxiosResponse<boolean> = await this.get(`${FILE_MANAGER}/copyAppToTeam/`, {
        params
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async copyAppToPublic(params: CopyAppToWorkspaceData): Promise<boolean> {
    try {
      const res: AxiosResponse<boolean> = await this.get(`${FILE_MANAGER}/copyAppToPublic/`, {
        params
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {string[]} sources -Location of files or folders to delete.
   * @description Delete files or folders.
   */
  public async deleteFilesOrFolder(sources: string[]): Promise<void> {
    sources = sources.map((source) => source.trim())
    const data = { sources: sources }
    try {
      const res: AxiosResponse<void> = await this.delete(`${FILE_MANAGER}/deleteFiles/`, {
        data: data
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async downloadFileOrFolder(
    params: DownloadParams,
    progressEvent?: (progressEvent: ProgressEvent) => void
  ): Promise<any> {
    try {
      const res: AxiosResponse<any> = await this.get(`${FILE_MANAGER}/download/`, {
        responseType: 'blob',
        params,
        paramsSerializer: (params: DownloadParams) =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
        onDownloadProgress(progress) {
          progressEvent?.(progress)
        }
      })
      return { data: this.success<any>(res), headers: res.headers }
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {string[]} sources -Location of files to zip.
   * @description Zip a file.
   */
  public async zipFileOrFolder({ sources }: ZipParams): Promise<string> {
    const params = { sources }
    try {
      const res: AxiosResponse<string> = await this.get(`${FILE_MANAGER}/zipFiles/`, {
        params,
        paramsSerializer: (params: ZipParams) => qs.stringify(params, { arrayFormat: 'repeat' })
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {string} source -Location of file to unzip.
   * @param {string} targetFolder -Location where unzip the files.
   * @description Unzip a file.
   */
  public async unZipFile(
    source: string,
    targetFolder: string,
    deleteFile?: boolean
  ): Promise<void> {
    try {
      const query = `?source=${encodeURIComponent(source)}&targetFolder=${encodeURIComponent(
        targetFolder
      )}&deleteFile=${deleteFile ? deleteFile : ''}`
      const res: AxiosResponse<void> = await this.get(`${FILE_MANAGER}/unzipFile/${query}`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {string[]} sources -Location of files to duplicate.
   * @description Duplicate files.
   */
  public async duplicateFile({ sources }: ZipParams): Promise<string[]> {
    const params = { sources }
    try {
      const res: AxiosResponse<string[]> = await this.get(`${FILE_MANAGER}/duplicateFiles/`, {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {string} folder_path -The location for the new folder.
   * @param {string} folder_name -The name of the new folder.
   * @description Create a new folder.
   */
  public async createFolder({
    folder_path,
    folder_name
  }: {
    folder_path: string
    folder_name: string
  }): Promise<{ path: string }> {
    folder_path = folder_path.trim()
    folder_name = folder_name.trim()
    const res: AxiosResponse<{ path: string }> = await this.post(`${FILE_MANAGER}/createFolder/`, {
      folder_path,
      folder_name
    })
    return this.success(res)
  }

  /**
   *
   * @param {Object} data -File chunks,file name and location.
   * @description Upload a file.
   */
  public async uploadFile(data: FormData, token: CancelToken): Promise<{ path: string }> {
    const res: AxiosResponse<{ path: string }> = await this.post(`${FILE_MANAGER}/upload/`, data, {
      cancelToken: token
    })
    return this.success(res)
  }

  /**
   *
   * @param {string[]} sources -Location of files to move.
   * @param {string} target -New location of the files.
   * @description Move files.
   */
  public async moveFile({ sources, target }: CopyOrMoveData): Promise<void> {
    const params = { sources, target }
    try {
      const res: AxiosResponse<void> = await this.get(`${FILE_MANAGER}/moveFiles/`, {
        params,
        paramsSerializer: (params: CopyOrMoveData) =>
          qs.stringify(params, { arrayFormat: 'repeat' })
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async modifyFileContent(path: string, content: string): Promise<any> {
    try {
      const res: AxiosResponse<any> = await this.post(`${FILE_MANAGER}/modifyFileContent/`, {
        path,
        content
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async completeUpload(uploadParams: UploaderParams): Promise<boolean> {
    try {
      const res: AxiosResponse<boolean> = await this.post(
        `${FILE_MANAGER}/completeUpload/`,
        uploadParams
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async optimizeTemplates(params: DownloadParams): Promise<{ optimized: boolean }> {
    try {
      const res: AxiosResponse<{ optimized: boolean }> = await this.get(
        `${FILE_MANAGER}/optimizeTemplates/`,
        {
          params,
          paramsSerializer: (params: DownloadParams) =>
            qs.stringify(params, { arrayFormat: 'repeat' })
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const fileManagerApi = new FileManagerApi(apiConfig)
