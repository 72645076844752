import { Reducer } from 'redux'

import { AppState, AppActionTypes } from './types'
import { modelReducer } from 'store/model'
import { interfaceReducer } from 'store/interface'

const resetState: AppState = {
  info: undefined,
  hasUnsavedChanges: undefined,
  saving: false,
  model: {},
  showCreateAppDialog: false,
  appReloaded: undefined,
  hasProcessDelayed: undefined,
  interface: {
    tabs: [],
    list: [],
    reloadLayout: 0
  },
  changingAppVersion: false,
  creatingAppVersion: false,
  appVersionsDialog: {
    show: false,
    folder: undefined,
    openAsReadOnly: undefined,
    openOnNewInstance: undefined
  },
  refreshScenariosTableCounter: 0,
  waiting: false
}
const initialState: AppState = {
  ...resetState,
  broadcastChannel: undefined
}

const reducer: Reducer<AppState> = (
  state: AppState = initialState,
  action: { type: AppActionTypes; payload?: any }
) => {
  switch (action.type) {
    case AppActionTypes.SET_OPEN_APP: {
      return {
        ...state,
        ...{ info: action.payload || undefined },
        model: { nodeErrors: [], interfaceErrors: [], nodeInterfaceErrors: [], nodeConsole: [] }
      }
    }
    case AppActionTypes.RESET_APP_INFO: {
      return {
        ...state,
        ...resetState
      }
    }
    case AppActionTypes.GET_APP_CONSOLIDATION_DIFFERENCES: {
      return {
        ...state,
        appConsolidationDifferences: action.payload
      }
    }
    case AppActionTypes.SET_APP_HAS_UNSAVED_CHANGES: {
      return {
        ...state,
        hasUnsavedChanges: action.payload
      }
    }
    case AppActionTypes.SET_SAVING_APP: {
      return {
        ...state,
        saving: action.payload
      }
    }
    case AppActionTypes.SHOW_UNREAD_NOTIFICATIONS: {
      return {
        ...state,
        showUnreadNotifications: action.payload
      }
    }
    case AppActionTypes.SHOW_CREATE_APP_DIALOG: {
      return {
        ...state,
        showCreateAppDialog: action.payload
      }
    }
    case AppActionTypes.SET_APP_INFO: {
      return {
        ...state,
        info: action.payload
      }
    }
    case AppActionTypes.UPDATE_DEFAULT_INTERFACE_ID: {
      return {
        ...state,
        info: { ...state.info, defaultInterfaceId: action.payload }
      }
    }
    case AppActionTypes.APP_RELOADED: {
      return {
        ...state,
        appReloaded: action.payload
      }
    }
    case AppActionTypes.SET_CHANGING_APP_VERSION: {
      return {
        ...state,
        changingAppVersion: action.payload
      }
    }
    case AppActionTypes.SET_CREATING_APP_VERSION: {
      return {
        ...state,
        creatingAppVersion: action.payload
      }
    }
    case AppActionTypes.HAS_PROCESS_DELAYED: {
      return {
        ...state,
        hasProcessDelayed: action.payload
      }
    }
    case AppActionTypes.SHOW_APP_VERSIONS_DIALOG: {
      return {
        ...state,
        appVersionsDialog: action.payload
      }
    }
    case AppActionTypes.REFRESH_SCENARIOS_TABLE: {
      return {
        ...state,
        refreshScenariosTableCounter: (state.refreshScenariosTableCounter || 0) + 1
      }
    }
    case AppActionTypes.SET_OPEN_APP_AFTER_SAVE: {
      return {
        ...state,
        openAppAfterSave: action.payload
      }
    }
    case AppActionTypes.ADD_BROADCAST_CHANNEL: {
      const channel = new BroadcastChannel(action.payload)
      if (state.broadcastChannel) {
        state.broadcastChannel.close()
      }
      return {
        ...state,
        broadcastChannel: channel
      }
    }
    case AppActionTypes.REMOVE_BROADCAST_CHANNEL: {
      if (state.broadcastChannel) {
        state.broadcastChannel.close()
      }
      return {
        ...state,
        broadcastChannel: undefined
      }
    }
    case AppActionTypes.SET_SAVING_APP_WITH_INTERFACE_EDIT_MODE: {
      return {
        ...state,
        savingAppWithInterfaceEditMode: action.payload
      }
    }
    case AppActionTypes.SET_OPEN_SAVE_APP_DIALOG_FROM_INTERFACE: {
      return {
        ...state,
        openSaveAppDialogFromInterface: action.payload
      }
    }
    case AppActionTypes.SET_WAITING: {
      return {
        ...state,
        waiting: action.payload
      }
    }
    case (action.type.match('^@@model') || {}).input:
      return { ...state, model: modelReducer(state.model, action) }

    case (action.type.match('^@@interface') || {}).input:
      return { ...state, interface: interfaceReducer(state.interface, action) }

    default: {
      return state
    }
  }
}

export { reducer as appReducer }
