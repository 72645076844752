import { ThemeOptions } from '@mui/material/styles'

const darkTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFF'
    },
    toggleButtonBg: {
      main: '#014F98'
    },
    tdAutocompleteArrow: {
      main: '#eee'
    },
    homeText: {
      main: '#fff'
    },
    homeBox: {
      main: '#2E344C'
    },
    homeMainBox: {
      main: '#1E2336'
    },
    textFieldBorder: {
      main: 'rgba(255, 255, 255, 0.25)'
    },
    companyCard: {
      main: '#181c2b'
    },
    hightLightOption: {
      main: 'rgba(255, 255, 255, 0.16)'
    },
    disabledAction: {
      main: 'rgba(255,255,255,0.3)'
    },
    folderBg: {
      main: '#fff'
    },
    notificationTitle: {
      main: '#fff'
    },
    primaryText: {
      main: '#FFF',
      light: '#ededed',
      dark: '#032a4f'
    },
    secondaryBackground: {
      main: '#1E2336',
      dark: '#0f121c',
      light: '#FFFFFF2C'
    },
    altBackground: {
      main: '#3C5473',
      light: 'rgb(157 169 185)',
      dark: '#30435c'
    },
    secondary: {
      main: '#3089E4'
    },
    taskStatusWarning: {
      main: '#f5f514',
      dark: '#57570f'
    },
    tabHoverColor: {
      main: '#368EE0'
    },
    defaultTableHeaderColor: {
      main: '#fff'
    },
    defaultHeaderColor: {
      main: '#fff'
    },
    defaultHeaderBackgroundColor: {
      main: '#1d2236'
    },
    defaultTableHeaderBackgroundColor: {
      main: '#25639C'
    },
    taskStatusSuccess: {
      main: '#127517',
      dark: 'rgb(10 70 13)'
    },
    black: {
      main: 'rgba(0,0,0,0.8)'
    },
    badgeColor: {
      main: '#66a4de'
    },
    white: {
      main: '#fff'
    },
    darkGrey: {
      main: 'rgba(0,0,0,0.5)'
    },
    primaryOnly: {
      main: '#368EE0'
    },
    secondaryText: {
      main: '#FFF'
    },
    altText: {
      main: '#CCC'
    },
    error: {
      main: '#FF0D15'
    },
    appCardShadow: {
      main: '0 4px 4px 0 rgba(0, 0, 0, 0.25)'
    },
    instanceManagerMarker: {
      main: '#ff7f0e'
    },
    resizerDivider: {
      main: '#fff'
    },
    shadows: {
      main: '#161924'
    },
    secondaryShadow: {
      main: '#368EE050'
    },
    background: {
      default: '#292e42',
      paper: '#292e42'
    },
    folderIcon: {
      main: '#D989E5'
    },
    fileIcon: {
      main: '#8A92DB'
    },
    nodeText: {
      main: '#FFFFFF'
    },
    basicNodeSelectedBg: {
      main: '#26415E'
    },
    dataReadingSelectedBg: {
      main: '#304534'
    },
    outputSelectedBg: {
      main: '#4A2845'
    },
    reportSelectedBg: {
      main: '#2D3141'
    },
    indexSelectedBg: {
      main: '#363A5E'
    },
    inputDataArraySelectedBg: {
      main: '#4A422B'
    },
    moduleSelectedBg: {
      main: '#2B374F'
    },
    inputDataArrayIcon: {
      main: '#FFC400'
    },
    indexHierarchyIcon: {
      main: '#9999FF'
    },
    tableHeader: {
      main: '#1b1f32',
      contrastText: '#fff',
      dark: '#3A3F4A'
    },
    tableData: {
      main: '#31374e',
      contrastText: '#b3b3b3'
    },
    tableDataDimmed: {
      main: 'rgba(49, 55, 78, 0.6)',
      contrastText: 'rgba(179, 179, 179, 0.6)'
    },
    tableEditableData: {
      main: '#1D2236',
      contrastText: '#fff'
    },
    tableEditableDataDimmed: {
      main: 'rgba(29, 34, 54, 0.6)',
      contrastText: 'rgba(255, 255, 255, 0.6)'
    },
    widgetBackground: {
      main: '#1d2236'
    },
    userTitle: {
      main: '#7EBCF5'
    },
    lightGrey: {
      main: '#AAAAAA'
    },
    altWidgetBackground: {
      main: '#292e42'
    },
    backgroundPlaceholder: {
      main: '#1d2236'
    },
    zipIcon: {
      main: '#fff'
    },
    dbIcon: {
      main: '#fff'
    },
    csvIcon: {
      main: '#fff'
    },
    jsonIcon: {
      main: '#fff'
    },
    templateIcon: {
      main: '#fff'
    },
    oldIcon: {
      main: '#fff'
    },
    defaultIcon: {
      main: '#fff'
    },
    leftChat: {
      main: '#363A5E'
    },
    rightChat: {
      main: '#3C5473'
    },
    variableNode: {
      main: '#4CBCFF'
    },
    readDataNode: {
      main: '#7FCF2E'
    },
    inputNode: {
      main: '#FFC400'
    },
    widgetDivider: {
      main: 'rgba(255, 255, 255, 0.12)'
    },
    whiteBlackContrast: {
      main: '#fff'
    },
    filterComponent: {
      main: '#191e2d'
    }
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 16
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          '*::-webkit-scrollbar': {
            width: '.5rem',
            height: '.5rem'
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: '#1b1e2b'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#808BA4',
            borderRadius: '100px'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#292E42',
          backgroundImage: 'none'
        },
        rounded: {
          borderRadius: '5px'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '48px'
        },
        regular: {
          minHeight: '48px',
          height: '48px',
          '@media (min-width: 600px)': {
            minHeight: '48px'
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        vertical: {
          width: '2px',
          height: '32px',
          margin: '0 6px',
          backgroundColor: '#FEFEFE', //may not be necessary anymore with MUIv5
          borderColor: '#FEFEFE'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          border: '1px solid #F8FAFE',
          boxShadow: 'none',
          borderRadius: '4px',
          color: '#FFF'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined', color: 'primary', size: 'small', disabled: true },
          style: {
            color: '#B1B1B1 !important',
            backgroundColor: '#FFF',
            border: '1px solid #B1B1B1'
          }
        }
      ],
      defaultProps: {
        fullWidth: true,
        variant: 'contained'
      },
      styleOverrides: {
        outlinedSizeSmall: {
          color: '#014F98',
          backgroundColor: '#FFF',
          border: '1px solid #7EBCF5',
          borderRadius: '4px',
          height: '32px',
          padding: 8,
          textTransform: 'none',
          fontSize: '0.75rem',
          '&:hover': {
            border: '1px solid #C3C3C3',
            backgroundColor: '#F8F5FE'
          }
        },
        root: {
          //this was label
          textTransform: 'none'
        },
        iconSizeSmall: {
          '& > *:first-of-type': {
            fontSize: 10
          }
        },
        containedPrimary: {
          height: '32px',
          backgroundColor: '#368EE0',
          fontSize: '1.1rem',
          color: '#FFF',
          borderRadius: '4px',
          padding: 8,
          border: 'none',
          '&:hover': {
            backgroundColor: '#014F98'
          },
          '&$disabled': {
            color: '#B1B1B1',
            backgroundColor: '#F3F3F3'
          }
        },
        containedSizeSmall: {
          height: '32px',
          fontSize: '0.9rem'
        },
        containedSecondary: {
          color: 'rgba(0, 0, 0, 0.87)',
          backgroundColor: '#FFF',
          '&:hover': {
            backgroundColor: 'rgb(178, 178, 178)'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#368EE0'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
        size: 'small'
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          // height: 19,
          paddingTop: '6.5px',
          paddingBottom: '6.5px',
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '0px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-4px'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          color: '#FFF'
        },
        head: {
          color: '#FFF'
        },
        stickyHeader: {
          backgroundColor: '#1E2336'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'none'
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          textDecoration: 'underline',
          textUnderlineOffset: '3px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&:has(> input:-webkit-autofill)': {
            backgroundColor: '#266798',
            '& input': {
              borderRadius: 'unset'
            }
          }
        }
      }
    }
  }
}

export default darkTheme
