import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { LayoutState, layoutReducer } from './layout'
import { NotifierState, notifierReducer } from './notifier'
import { UserState, userReducer, usersSaga } from './user'
import { AppState, appReducer, appSaga } from './application'
import { interfaceSaga } from './interface'
import { modelSaga } from './model'
import { SocketState, socketReducer, socketSaga } from './socket'
import { assistantBotReducer, assistantBotSaga, AssistantBotState } from './assistantBot'

// The top-level state object
export interface ApplicationState {
  layout: LayoutState
  user: UserState
  notifier: NotifierState
  app: AppState
  socket: SocketState
  assistantBot: AssistantBotState
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = () =>
  combineReducers({
    layout: layoutReducer,
    user: userReducer,
    notifier: notifierReducer,
    app: appReducer,
    socket: socketReducer,
    assistantBot: assistantBotReducer
  })

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(modelSaga),
    fork(usersSaga),
    fork(socketSaga),
    fork(interfaceSaga),
    fork(assistantBotSaga)
  ])
}
