import { AxiosError } from 'axios'

import closeAction from './CloseBtnEnq'
import { randomId } from 'common/helpers/strings'
import { isFriendlyError, isNetworkError } from 'common/helpers/isFriendlyError'
import { enqueueNotification, Notification, NotificationLevels } from 'store/notifier'

const enqueue = (data: AxiosError | Notification) => {
  if ('isAxiosError' in data) {
    const key = randomId()
    let title = data.message || data.code
    let message = data.stack || ''
    let dismissed = false
    if (data.response?.status && data.response?.status >= 500 && isFriendlyError(data)) {
      console.error(data)
      sessionStorage.setItem('serverError', data.response?.data)
      dismissed = true
    } else if (isNetworkError(data)) {
      title = 'Network Error'
      message = 'There was a network issue. Please check your internet connection and try again.'
    }
    if (typeof data.response?.data === 'string') {
      title = data.response.data
      message = ''
    } else if (data.response?.data?.detail) {
      title = data.response?.data?.detail
      message = ''
    }
    if (data.response?.data?.additional_details) {
      Object.keys(data.response?.data?.additional_details).forEach((key) => {
        try {
          console.error(`${key}:\n\n${data.response?.data?.additional_details[key]}`)
        } catch (error) {}
      })
    }

    return enqueueNotification({
      key,
      title,
      message,
      data: {
        ...data,
        request: data.request,
        response: data.response
      },
      options: {
        variant: NotificationLevels.WARNING,
        persist: true,
        action: () => closeAction(key)
      },
      dismissed
    })
  }
  const { key, title, message, options } = data
  return enqueueNotification({
    key,
    message,
    title,
    data,
    options: {
      ...options,
      ...{
        variant: data?.options?.variant || NotificationLevels.WARNING,
        persist: data?.options?.autoHideDuration ? false : true,
        action: () => closeAction(key),
        autoHideDuration: data?.options?.autoHideDuration || undefined
      }
    }
  })
}

export { enqueue }
