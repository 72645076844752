import {
  Diagram,
  SelectedNode,
  WizardInfo,
  NodeFeedback,
  SearchableNodeClasses,
  InterfaceFeedback,
  NodeInterfaceFeedback
} from 'common/types/model'
import { InstallationLog, socketModelDebug } from 'store/socket/constants'

export enum ModelActionTypes {
  NAVIGATE_DIAGRAM = '@@model/NAVIGATE_DIAGRAM',
  REFRESH_DIAGRAM = '@@model/REFRESH_DIAGRAM',
  SET_ELEMENTS = '@@model/SET_ELEMENTS',
  UPDATE_ELEMENTS = '@@model/UPDATE_ELEMENTS',
  SELECT_NODE = '@@model/SELECT_NODE',
  SET_SELECTED_CODE_NODE = '@@model/SET_SELECTED_CODE_NODE',
  UPDATE_CODE_NODE = '@@model/UPDATE_CODE_NODE',
  SET_MODULEID = '@@model/SET_MODULEID',
  DELETE_NODES = '@@model/DELETE_NODES',
  DELETE_NODES_FROM_IDS = '@@model/DELETE_NODES_FROM_IDS',
  CREATE_NODE = '@@model/CREATE_NODE',
  COMPLETE_CREATE_NODE = '@@model/COMPLETE_CREATE_NODE',
  ACTIVE_DRAGGABLE_NODES = '@@model/ACTIVE_DRAGGABLE_NODES',
  MOVE_NODES = '@@model/MOVE_NODES',
  RESIZE_NODES = '@@model/RESIZE_NODES',
  GET_NODE_RESULT = '@@model/GET_NODE_RESULT',
  SET_NODE_RESULT = '@@model/SET_NODE_RESULT',
  GET_NODE_PREVIEW = '@@model/GET_NODE_PREVIEW',
  SET_NODE_PREVIEW = '@@model/SET_NODE_PREVIEW',
  TRIGGER_NODE_INTERFACE = '@@model/TRIGGER_NODE_INTERFACE',
  REMOVE_NODE_FROM_UNSAVED = '@@model/REMOVE_NODE_FROM_UNSAVED',
  INSTALL_LIBRARIES = '@@model/INSTALL_LIBRARIES',
  UPDATE_LIBRARIES_INSTALL_LOG = '@@model/UPDATE_LIBRARIES_INSTALL_LOG',
  CLEAR_LIBRARIES_INSTALL_LOG = '@@model/CLEAR_LIBRARIES_INSTALL_LOG',
  CONFIRM_CODE_NODE = '@@model/CONFIRM_CODE_NODE',
  CANCEL_CODE_NODE = '@@model/CANCEL_CODE_NODE',
  RUN_SELECTED_NODE = '@@model/RUN_SELECTED_NODE',
  SET_RUNNING_NODE = '@@model/SET_RUNNING_NODE',
  UPDATE_PINNED_NODES = '@@model/UPDATE_PINNED_NODES',
  NAVIGATE_TO_NODE = '@@model/NAVIGATE_TO_NODE',
  OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE = '@@model/OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE',
  OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE_FROM_GO_TO = '@@model/OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE_FROM_GO_TO',
  SET_ADDED_NODE_ID_TO_CODE_DEFINITION = '@@model/SET_ADDED_NODE_ID_TO_CODE_DEFINITION',
  OPEN_WIZARD = '@@model/OPEN_WIZARD',
  SET_WIZARD_DATA = '@@model/SET_WIZARD_DATA',
  APPLY_WIZARD = '@@model/APPLY_WIZARD',
  CLOSE_WIZARD = '@@model/CLOSE_WIZARD',
  UNLOCK_PROPERTIES_DIALOG = '@@model/UNLOCK_PROPERTIES_DIALOG',
  SET_NODE_PROPERTIES = '@@model/SET_NODE_PROPERTIES',
  UPDATE_NODE_ERROR = '@@model/UPDATE_NODE_ERROR',
  UPDATE_INTERFACE_ERROR = '@@model/UPDATE_INTERFACE_ERROR',
  UPDATE_NODE_INTERFACE_ERROR = '@@model/UPDATE_NODE_INTERFACE_ERROR',
  UPDATE_NODE_CONSOLE = '@@model/UPDATE_NODE_CONSOLE',
  CLEAR_NODE_CONSOLE = '@@model/CLEAR_NODE_CONSOLE',
  SHOW_NODE_IDS = '@@model/SHOW_NODE_IDS',
  SET_NODES_IN_CLIPBOARD = '@@model/SET_NODES_IN_CLIPBOARD',
  DUPLICATE_NODES = '@@model/DUPLICATE_NODES',
  PASTE_NODES = '@@model/PASTE_NODES',
  FORCE_SELECTION_OF_NODES = '@@model/FORCE_SELECTION_OF_NODES',
  APPLY_TABLE_CHANGES = '@@model/APPLY_TABLE_CHANGES',
  SET_INPUT_NODE_VALUE = '@@model/SET_INPUT_NODE_VALUE',
  SET_SHORTCUT_PRESSED = '@@model/SET_SHORTCUT_PRESSED',
  CREATE_ALIAS = '@@model/CREATE_ALIAS',
  RUN_NODE_AND_EXPAND_RESULT = '@@model/RUN_NODE_AND_EXPAND_RESULT',
  RUN_NODE_AND_EXPAND_CODE = '@@model/RUN_NODE_AND_EXPAND_CODE',
  SET_NODE_SIZE_BASED_ON_OTHER = '@@model/SET_NODE_SIZE_BASED_ON_OTHER',
  SET_NODE_POSITION_BASED_ON_OTHER = '@@model/SET_NODE_POSITION_BASED_ON_OTHER',
  SET_MODULE_NAVIGATION_HISTORY = '@@model/SET_MODULE_NAVIGATION_HISTORY',
  SET_SHOW_RELATED_NODES = '@@model/SET_SHOW_RELATED_NODES',
  SET_NODE_COMPONENT_TO_EDIT = '@@model/SET_NODE_COMPONENT_TO_EDIT',
  SET_DONT_CLEAN_SELECTED_ON_INITIAL_NAVIGATE = '@@model/SET_DONT_CLEAN_SELECTED_ON_INITIAL_NAVIGATE',
  SET_VALUE_VARIANT_OF_INDEX = '@@model/SET_VALUE_VARIANT_OF_INDEX',
  OPEN_CONSOLE = '@@model/OPEN_CONSOLE',
  COPY_AS_VALUES = '@@model/COPY_AS_VALUES',
  SHOW_MODULE_ID = '@@model/SHOW_MODULE_ID',
  OPEN_DATA_READ = '@@model/OPEN_DATA_READ',
  OPEN_INPUT_DATA = '@@model/OPEN_INPUT_DATA',
  SET_DISABLE_DIAGRAM_SCROLL = '@@model/SET_DISABLE_DIAGRAM_SCROLL',
  CLEAR_CONSOLE_ERRORS = '@@model/CLEAR_CONSOLE_ERRORS',
  SET_UPDATE_NODE_RESULT = '@@model/SET_UPDATE_NODE_RESULT',
  UPDATE_NODE_ARROW = '@@model/UPDATE_NODE_ARROW',
  SET_DEBUG_INFO = '@@model/SET_DEBUG_INFO',
  RESET_DEBUG_INFO = '@@model/RESET_DEBUG_INFO',
  ENABLE_ANALYSIS = '@@model/ENABLE_ANALYSIS',
  SET_FILTERED_NODES = '@@model/SET_FILTERED_NODES',
  SET_LAST_WIDGET_FOCUSED = '@@model/SET_LAST_WIDGET_FOCUSED',
  SET_RESULT_WIDGET_FOCUSED = '@@model/SET_RESULT_WIDGET_FOCUSED',
  GO_TO_NODE_FROM_INTERFACE = '@@model/GO_TO_NODE_FROM_INTERFACE',
  SET_CENTER_NODE = '@@model/SET_CENTER_NODE',
  REFRESH_INDEX = '@@model/REFRESH_INDEX',
  LOADING_MODULE = '@@model/LOADING_MODULE',
  CONFIRM_AND_UPDATE_SCALAR_NODE = '@@model/CONFIRM_AND_UPDATE_SCALAR_NODE',
  OPEN_ANALIZE_AND_MANIPULATE_DATA_WIDGET = '@@model/OPEN_ANALIZE_AND_MANIPULATE_DATA_WIDGET',
  SYSTEM_NODE_DEFINITION_HAS_CHANGED = '@@model/SYSTEM_NODE_DEFINITION_HAS_CHANGED',
  SELECT_AND_RUN_NODE = '@@model/SELECT_AND_RUN_NODE',
  SET_HANDLING_DATA_ORIGINAL_NODE_ID = '@@model/SET_HANDLING_DATA_ORIGINAL_NODE_ID',
  LOADING_MANIPULATE_DATA_WIDGET = '@@model/LOADING_MANIPULATE_DATA_WIDGET',
  DISABLE_REDO_ACTION = '@@model/DISABLE_REDO_ACTION'
}
export interface ModelState {
  readonly diagram?: Diagram
  readonly disableDiagramScroll?: boolean
  readonly selectedNode?: SelectedNode
  readonly updatePinnedNodes?: boolean
  readonly runningNode?: string
  readonly centerNode?: string
  readonly filteredNodes?: SearchableNodeClasses[]
  readonly wizard?: WizardInfo
  readonly nodeErrors?: NodeFeedback[]
  readonly interfaceErrors?: InterfaceFeedback[]
  readonly nodeInterfaceErrors?: NodeInterfaceFeedback[]
  readonly nodeConsole?: NodeFeedback[]
  readonly showModuleId?: boolean
  readonly librariesInstallLog?: InstallationLog[]
  readonly nodeComponentToEdit?: { componentId?: string; nodeId?: string }
  readonly dontCleanSelectedOnInitialNavigate?: boolean
  readonly valueVariantOfIndex?: string
  readonly openDataRead?: boolean
  readonly openInputData?: boolean
  readonly goToNodeFromInterface?: boolean
  readonly lastWidgetFocused?: string
  readonly resultWidgetFocused?: boolean
  readonly debugInfo?: socketModelDebug[]
  readonly enableAnalysis?: boolean
  readonly refreshIndex?: boolean
  readonly loadingModule?: boolean
  readonly openAnalyzeAndManipulateDataWidget?: string
  readonly systemNodeDefinitionHasChanged?: boolean
  readonly handlingDataOriginalNodeId?: string
  readonly loadingManipulateDataWidget?: boolean
  readonly disableReDoAction?: boolean
}

export const NODE_CLASSES_WITHOUT_CODE = ['module', 'text']
