import { AxiosError } from 'axios'

export const isFriendlyError = (error: AxiosError) => {
  const friendlyWords = ['friendly']
  try {
    return (
      friendlyWords.some((word) => error.message.includes(word)) ||
      friendlyWords.some(
        (word) => typeof error.response?.data === 'string' && error.response.data.includes(word)
      )
    )
  } catch {
    return false
  }
}

export const isNetworkError = (error: AxiosError) => {
  try {
    return error.message === 'Network Error'
  } catch {
    return false
  }
}
