export const randomId = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

/**
 * Regular expression pattern to validate passwords.
 *
 * Conditions:
 * - At least one lowercase letter.
 * - At least one uppercase letter.
 * - At least one digit.
 * - At least one special character from the following: !@#$%^&*()_\-+=[\]{}|:;"'<>,.?/~
 * - Minimum length of 10 characters.
 */
export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=[\]{}|:;"'<>,.?/~]).{10,}$/
