import { AnyAction } from 'redux'
import { all, put, fork, call, takeLatest, select } from 'redux-saga/effects'

import { InterfaceActionTypes } from './types'
import { loadingTabMenu, setDefaultInterfaceLayout, setIndexFilterData } from './actions'
import { enqueue } from '../../common/notifier'
import { interfaceApi } from 'services/interface'
import { InterfaceEventType, InterfaceEvent, GridLayout } from 'pages/interfaces/containers/types'
import { ApplicationState } from 'store'
import { modelApi } from 'services/model'

function* handleopenIndexFilterDialog({
  payload: { componentId, field, nodeId, title, filters, filterComponentOnly, singleSelect }
}: AnyAction) {
  try {
    if (componentId && field) {
      yield put(
        setIndexFilterData(
          true,
          componentId,
          field,
          nodeId,
          title,
          filters,
          filterComponentOnly,
          singleSelect
        )
      )
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCloseIndexFilterDialog({
  payload: { componentId, field, nodeId, title, filters, filterComponentOnly }
}: AnyAction) {
  try {
    yield put(
      setIndexFilterData(false, componentId, field, nodeId, title, filters, filterComponentOnly)
    )
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handlePasteStyles({
  payload: { componentId, interfaceId, componentProperties, fromInterface }
}: AnyAction) {
  try {
    const stylesToCopy = ((state: ApplicationState) => state.app.interface.stylesToCopy)(
      yield select()
    )
    if (stylesToCopy) {
      const triggerData: InterfaceEvent = {
        event_type: InterfaceEventType.UpdateProperties,
        params: {
          component_id: componentId,
          properties: {
            ...componentProperties,
            styles: stylesToCopy
          }
        }
      }
      if (fromInterface) {
        yield call(interfaceApi.trigger, interfaceId, triggerData, fromInterface)
      } else {
        yield call(modelApi.triggerNodeInterface, interfaceId, triggerData)
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCallDefaultLayout() {
  try {
    yield put(loadingTabMenu(true))
    const defaultInterfaceId: string | undefined = yield select(
      (state: ApplicationState) => state.app.info?.defaultInterfaceId
    )
    if (defaultInterfaceId) {
      const layout: GridLayout | undefined = yield call(
        interfaceApi.getLayout,
        defaultInterfaceId,
        true
      )
      if (layout) {
        yield put(setDefaultInterfaceLayout(layout))
        const initialUpdate: InterfaceEvent = {
          event_type: InterfaceEventType.InitialUpdate,
          params: {}
        }
        yield call(interfaceApi.trigger, defaultInterfaceId, initialUpdate, true)
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(loadingTabMenu(false))
  }
}

function* watchActions() {
  yield all([
    takeLatest(InterfaceActionTypes.OPEN_INDEX_FILTER_DIALOG, handleopenIndexFilterDialog),
    takeLatest(InterfaceActionTypes.CLOSE_INDEX_FILTER_DIALOG, handleCloseIndexFilterDialog),
    takeLatest(InterfaceActionTypes.PASTE_STYLES, handlePasteStyles),
    takeLatest(InterfaceActionTypes.CALL_DEFAULT_LAYOUT, handleCallDefaultLayout)
  ])
}

function* interfaceSaga() {
  yield all([fork(watchActions)])
}

export { interfaceSaga }
