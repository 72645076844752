// Use enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention

import { StatusInternalTests } from 'common/types/internalTests'
import { UploaderParams, SocketInstanceResources } from './constants'

// of Redux's `@@INIT` action.
export enum SocketActionTypes {
  SEND_MESSAGE = '@@socket/SEND_MESSAGE',
  START_SERVER = '@@socket/START_SERVER',
  STOP_SERVER = '@@socket/STOP_SERVER',
  WEBSOCKET_CONNECTION_STATUS_ON = '@@socket/WEBSOCKET_CONNECTION_STATUS_ON',
  WEBSOCKET_CONNECTION_STATUS_OFF = '@@socket/WEBSOCKET_CONNECTION_STATUS_OFF',
  SET_WEBSOCKET_PREVIOUS_CONNECTION_STATUS = '@@socket/SET_WEBSOCKET_PREVIOUS_CONNECTION_STATUS',
  SET_PROGRESS = '@@socket/SET_PROGRESS',
  SET_PROGRESSBAR = '@@socket/SET_PROGRESSBAR',
  UPDATE_INSTANCE_RESOURCES = '@@socket/UPDATE_INSTANCE_RESOURCES',
  HIDE_PROGRESSBAR = '@@socket/HIDE_PROGRESSBAR',
  SET_UPLOADER_PARAMS = '@@socket/SET_UPLOADER_PARAMS',
  INTERNAL_TESTS_STATUS = '@@socket/INTERNAL_TESTS_STATUS',
  RESET_INTERNAL_TESTS = '@@socket/RESET_INTERNAL_TESTS',
  SET_APP_LOCK_INFO = '@@socket/SET_APP_LOCK_INFO',
  SET_REFRESH_APP_NOTIFICATIONS = '@@socket/SET_REFRESH_APP_NOTIFICATIONS',
  SET_ASSISTANT_BOT_STOPPED = '@@socket/SET_ASSISTANT_BOT_STOPPED',
  SET_ASSISTANT_BOT_THREAD_MESSAGE_ID = '@@socket/SET_ASSISTANT_BOT_THREAD_MESSAGE_ID'
}

export interface SocketProgressState {
  message?: string
  show?: boolean
  value: number
  closeWhenFinished?: boolean
}

export enum WebSocketConnectionStatus {
  ON = 'on',
  OFF = 'off',
  UNKNOWN = 'unknown'
}

interface AssistantBotProperties {
  stopped?: boolean
  listenBotMessage?: boolean
  threadMessageId?: string
}

interface AssistantBotState {
  general?: AssistantBotProperties
  code?: AssistantBotProperties
  tests?: AssistantBotProperties
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface SocketState {
  readonly previousWebSocketConnectionStatus?: WebSocketConnectionStatus
  readonly webSocketConnectionStatus?: WebSocketConnectionStatus
  readonly progress?: SocketProgressState
  readonly progressbar?: SocketProgressState
  readonly instanceResources?: SocketInstanceResources
  readonly uploader?: UploaderParams
  readonly internalTests?: StatusInternalTests
  readonly appIsLocked?: boolean
  readonly refreshAppNotifications?: boolean
  readonly assistantBot?: AssistantBotState
}
