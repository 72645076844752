import { all, put, call, fork, takeLatest, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { ApplicationState } from 'store'

import {
  setApplicationAssistantBot,
  setClearMessages,
  showAssistantBot,
  updateMessages
} from './actions'
import { AssistantBotActionTypes, AssistantBotKey } from './types'
import { getAssistantBotStateKey } from 'common/helpers/getAssistantBotStateKey'
import { enqueue } from 'common/notifier'
import { AssistantBotChatMessage } from 'common/types/assistantBot'
import { assistantBotApi } from 'services/assistantBot'

function* handleQueryChat({
  payload: { assistantType, text, threadId, regenerateResponse, applicationAssistantId, engineType }
}: AnyAction) {
  try {
    const assistantBotKey = getAssistantBotStateKey(assistantType)
    // Build entire conversation
    const chatMessages = ((state: ApplicationState) =>
      state.assistantBot[assistantBotKey].messages)(yield select())
    if (regenerateResponse) {
      // Remove last message from assistant
      if (chatMessages.length > 0) {
        chatMessages.pop()
      }
      yield put(updateMessages(assistantBotKey, [...chatMessages]))
    }
    const chatHistory: AssistantBotChatMessage[] = chatMessages.filter(
      (message) => !!!message.filter
    )
    let chatMessage = {} as AssistantBotChatMessage
    if (chatHistory.length > 0) {
      // Retrieve question asked by user
      chatMessage = chatHistory.pop() as AssistantBotChatMessage
    }
    yield call(
      assistantBotApi.chat,
      assistantType,
      chatMessage,
      chatHistory,
      regenerateResponse,
      threadId,
      applicationAssistantId,
      engineType
    )
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleShowApplicationBot({ payload }: AnyAction) {
  try {
    yield put(setApplicationAssistantBot(payload))
    yield put(setClearMessages(AssistantBotKey.general))
    yield put(showAssistantBot(AssistantBotKey.general, true))
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* watchGeneralActions() {
  yield all([takeLatest(AssistantBotActionTypes.QUERY, handleQueryChat)])
  yield all([
    takeLatest(AssistantBotActionTypes.SHOW_APPLICATION_ASSISTANT_BOT, handleShowApplicationBot)
  ])
}

function* assistantBotSaga() {
  yield all([fork(watchGeneralActions)])
}

export { assistantBotSaga }
