import closeAction from 'common/notifier/CloseBtnEnq'

import { NotifierActionTypes, MessageNotification, BlockerActionTypes, Blocker } from './types'


export const enqueueNotification = (notification: MessageNotification) => {
  const key = notification.key || (new Date().getTime() + Math.random()).toString()
  if (!notification.options.action) {
    notification.options.action = () => closeAction(key)
  }

  return {
    type: NotifierActionTypes.ENQUEUE_NOTIFICATION,
    notification: {
      ...notification,
      options: {
        autoHideDuration: notification.options.persist ? null : 3000,
        ...notification.options
      },
      key
    }
  }
}

export const closeSnackbar = (key: string | number) => ({
  type: NotifierActionTypes.CLOSE_NOTIFICATION,
  dismissAll: !key, // dismiss all if no key has been defined
  key
})

export const removeSnackbar = (key: string | number) => ({
  type: NotifierActionTypes.REMOVE_NOTIFICATION,
  key
})

export const enqueueBlocker = (blocker: Blocker) => {
  const key = (new Date().getTime() + Math.random()).toString()
  return {
    type: BlockerActionTypes.ENQUEUE_BLOCKER,
    blocker,
    key
  }
}
