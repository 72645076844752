import { Elements } from 'react-flow-renderer'

import { FormikErrors } from 'formik'

import GenericPaginationParams from './genericPaginationParams'
import { MainFolder } from './home'
import { IBaseComponent } from 'pages/interfaces/components/types'
import Handsontable from 'handsontable'
import { ColumnDataGetterSetterFunction } from 'handsontable/common'

export type NodeType =
  | 'variable'
  | 'datareading'
  | 'outputnode'
  | 'report'
  | 'button'
  | 'module'
  | 'index'
  | 'inputscalar'
  | 'inputselector'
  | 'inputchoice'
  | 'inputdataarray'
  | 'inputcube'
  | 'inputdataframe'
  | 'text'
  | 'form'
  | 'reportwizard'
export interface TabItem {
  id: string
  name: string
  documentation?: boolean
}

export enum AcceptedNodeTypes {
  variable = 'variable',
  datareading = 'datareading',
  outputnode = 'outputnode',
  report = 'report',
  button = 'button',
  module = 'module',
  index = 'index',
  inputscalar = 'inputscalar',
  inputselector = 'inputselector',
  inputchoice = 'inputchoice',
  inputdataarray = 'inputdataarray',
  inputcube = 'inputcube',
  inputdataframe = 'inputdataframe',
  text = 'text',
  form = 'form',
  reportwizard = 'reportwizard'
}

export enum InputDataTypes {
  inputscalar = 'inputscalar',
  inputselector = 'inputselector',
  form = 'form',
  inputcube = 'inputcube'
}

export type SearchableNodeClasses = Exclude<NodeType, 'text'>

export enum AcceptedNodeClasses {
  variable = 'variable',
  datareading = 'datareading',
  outputnode = 'outputnode',
  report = 'report',
  button = 'button',
  module = 'module',
  index = 'index',
  inputscalar = 'inputscalar',
  inputselector = 'inputselector',
  inputchoice = 'inputchoice',
  inputdataarray = 'inputdataarray',
  inputcube = 'inputcube',
  inputdataframe = 'inputdataframe',
  text = 'text',
  alias = 'alias',
  generic = 'generic',
  form = 'form',
  reportwizard = 'reportwizard'
}

export enum BasicNodeSubtypes {
  'variable',
  'datareading',
  'outputnode',
  'report'
}

export type NodeClass = NodeType | 'alias' | 'generic'

export interface ModelCardProps {
  modelName: string
  imgBase64?: string
  modelPath: string
  setReloadApps?: (value: boolean) => void
  isMobile?: boolean
  workspace: string
}

export type BreadcrumbModule = {
  id: string
  title: string
}

type StringOrNull = string | null
type BooleanOrNull = boolean | null
type Arrow = {
  to: string
  from: string
}
type NodeInfo = {
  fill: '1' | '0'
  showBorder: '1' | '0'
  showInputs: '1' | '0'
  showLabel: '1' | '0'
  showOutputs: '1' | '0'
  useNodeFont: '1' | '0'
}

export interface BaseNode {
  identifier: string
  nodeClass?: NodeClass
  title?: string
}

export interface Node extends BaseNode {
  color: string
  originalClass?: NodeClass
  originalId?: string
  moduleId: StringOrNull
}

export type EvaluationError = {
  endColumn: number
  endLineNumber: number
  startColumn: number
  startLineNumber: number
  message: string
}

export interface CodeNode extends BaseNode {
  definition?: string
  isCalc: boolean
  lastEvaluationConsole?: string
  lastEvaluationError?: EvaluationError
  units?: string
  prefixIdentifier?: string
  hierarchy?: string
  resultType?: string
}

export interface NodePropertiesResponse {
  node: string
  properties: [{ name: string; value: any }]
}

export interface NodesPropertiesResponse {
  [key: string]: { [key: string]: string | null }
}

export interface CompareNodesResponse {
  node_id: string
}

export type CodeNodePropertyField =
  | 'title'
  | 'identifier'
  | 'nodeClass'
  | 'units'
  | 'prefixIdentifier'
  | 'hierarchy'
  | 'releaseMemory'
  | 'evaluateOnStart'

export interface CodeNodePropertiesForm {
  title: string
  identifier: string
  nodeClass: string
  units: string
  prefixIdentifier: string
  hierarchy: string
}
export interface CodeNodePropertiesWithOtherProps extends CodeNodePropertiesForm {
  evaluateOnStart: boolean
  releaseMemory: boolean
}

export interface KeyValue {
  Key: string
  Value: string
}

export interface DiagramNode extends Node {
  x: number
  y: number
  z: number
  w: number
  h: number
  nodeInfo: NodeInfo | null
  nodeFont: StringOrNull
  hasPicture: BooleanOrNull
  selectorParams: SelectorParams | null
  inputScalarValue: StringOrNull
  moduleId: string
  hasDescription: boolean
  hierarchy?: string
  hasOutputsOutsideModule?: boolean
  _resultType?: string
}

export interface DiagramNodeInfo extends DiagramNode {
  id: string
  inputTitle?: boolean
  type: NodeType
  description?: string
}

export interface RelatedNodeInfo {
  id: string
  name: string
  nodeClass: string
  moduleId?: string
}
export interface MetadataNodeInfo {
  created_at: string
  last_modified_at: string
  last_user_to_modify: string
}
export interface ResultNode extends RelatedNodeInfo {
  completeText: string
  description: string | null
  params: string
  title: string | null
}

export interface SearchResultNode {
  id: string
  title: string | null
  label: string
}

export interface ModuleInfo {
  arrows: Arrow[]
  breadcrumb?: BreadcrumbModule[]
  moduleId?: string
  nodes: DiagramNodeInfo[]
}

export interface CopyNodesParams {
  identifier: string
  moduleId: string
  x: number
  y: number
}

export type NodeClipboardAction = 'cut' | 'copy'

export interface nodePositionCopied {
  id: string
  x: number
  y: number
}

export interface NodeClipboard {
  nodeIds: string[]
  action: NodeClipboardAction
  nodePositionCopied: nodePositionCopied[]
  forceSelectionNodeIds?: string[]
}

export type GlobalShortcuts = 'save' | 'search'

export interface Diagram {
  moduleId?: string
  moduleNavigationHistory?: string[]
  breadcrumb?: BreadcrumbModule[]
  elements?: Elements
  unconfirmedNodes?: CodeNode[]
  panX?: number
  panY?: number
  showIds?: boolean
  nodeClipboard?: NodeClipboard
  shortcutPressed?: GlobalShortcuts
  openConsole?: boolean
}

export interface SelectedNode {
  identifier?: string
  codeNode?: CodeNode
  result?: IBaseComponent
  preview?: NodePreview
  addedNodeIdToDefinition?: string
  isPropertiesDialogUnlocked?: boolean
}
interface BaseNodeData {
  color: string
  errorInDef: boolean
  h: number
  hasArrow: boolean
  hasDescription: boolean
  hasOutputsOutsideModule: boolean
  hasPicture: boolean
  hierarchy: string | null
  id: string
  isCalc: boolean
  isStatic: boolean
  nodeClass: string
  nodeFont: string | null
  nodeInfo: {
    fill: string
    showBorder: string
    showInputs: string
    showLabel: string
    showOutputs: string
    useNodeFont: string
  }
  originalId: string | null
  picture: string | null
  title: string
  units: string | null
  w: number
  x: number
  y: number
  z: number
  _resultType: string | null
}
export interface DiagramBaseNode {
  id: string
  style: object
  position: object
  type: NodeType
  data: BaseNodeData
}

export interface PinnedNode {
  identifier: string
  label: string
  nodeType: NodeType | 'generic'
  moduleId: string
}

export interface LocalNodesPinned {
  appId: string
  companyId: number
  nodes: PinnedNode[]
}

export interface NodePositionAndSize {
  id: string
  x: number
  y: number
  w: number
  h: number
}

export type ResizeNodeDirection = 'width' | 'height' | 'both'
export type PositionNodeDirection = 'top' | 'bottom' | 'left' | 'right' | 'centerV' | 'centerH'

export interface NodePositionWModule extends NodePositionAndSize {
  moduleId: string | null
}

export interface SetNodesPropertiesParams {
  node: string
  properties: { name: string; value: any }[]
}

export enum DefaultWByNodeType {
  variable = 180,
  datareading = 180,
  outputnode = 180,
  report = 180,
  button = 150,
  module = 180,
  index = 100,
  inputscalar = 180,
  inputselector = 180,
  inputchoice = 180,
  inputdataarray = 180,
  inputcube = 180,
  inputdataframe = 180,
  text = 370,
  form = 180,
  reportwizard = 180
}

export enum DefaultHByNodeType {
  variable = 40,
  datareading = 40,
  outputnode = 40,
  report = 40,
  button = 40,
  module = 40,
  index = 50,
  inputscalar = 30,
  inputselector = 30,
  inputchoice = 30,
  inputdataarray = 40,
  inputcube = 40,
  inputdataframe = 40,
  text = 160,
  form = 40,
  reportwizard = 40
}

export interface SelectorInfo {
  options: string[] | number[]
  selected: number | number[]
  multiselect: boolean
}

export interface SelectorResult {
  index: number
  value: string
}

export interface SelectorOptionsAndIndexInfo {
  count: number
  next: string | null
  previous: string | null
  results: SelectorResult[]
}

export interface SelectorParamsInfo {
  selected: number | number[]
  selected_values: string | string[]
  multiselect: boolean
  include_all: boolean
}

export interface ChoiceInfo {
  options: string[] | number[]
  selected: number
  include_all: boolean
  full_options: string[] | number[]
}

export interface ChoiceResult {
  index: number
  value: string
}

export interface ChoiceOptionsAndIndexInfo {
  count: number
  next: string | null
  previous: string | null
  results: ChoiceResult[]
}

export interface ChoiceParamsInfo {
  selected: number
  selected_values: string
  multiselect: boolean
  include_all: boolean
}
export interface NodeParams extends GenericPaginationParams {
  app_path: string
  required_node: string
  class_filter: string[]
  version?: string
}

export interface NodeWithCHildren {
  children: MainFolder[]
}
export interface NodePropParams {
  app_path: string
  identifier: string
}

export interface SelectorParams {
  selected_indexes?: number[]
  selected_values?: string[]
  multiselect?: boolean
  save_selected_labels?: boolean
  include_all?: boolean
  exception?: string
}

export interface SelectedState {
  componentId: string
  selected_indexes: number[]
  selected_values: string[] | number[]
  multiselect: boolean
  include_all: boolean
  all_selected: boolean
  save_selected_labels: boolean
}

export interface SetSelectorValueParams {
  values: any[]
  selected_state?: SelectedState
  selected_values?: string | string[]
  selected_indexes?: number[]
}

export interface SetInputScalarValueParams {
  value: string
}

export enum NodeInputs {
  scalar = 'inputscalar',
  choice = 'inputchoice',
  selector = 'inputselector'
}

export type SetInputNodeValueParams =
  | {
      inputType: NodeInputs.scalar
      nodeId: string
      value: string
    }
  | {
      inputType: NodeInputs.selector | NodeInputs.choice
      nodeId: string
      value: number[]
    }

type previewResultTypes =
  | "<class 'xarray.core.dataarray.DataArray'>"
  | "<class 'pandas.core.frame.DataFrame'>"
  | "<class 'pandas.core.series.Series'>"
  | string

export interface NodePreview {
  resultType: previewResultTypes
  dims?: string[]
  columns?: string[]
  preview: string
  ui_url?: string
}

// Wizards

export enum WizardTypes {
  SelectColumns = 'SelectColumns',
  InputDataFrame = 'InputDataFrame',
  InputDataArray = 'InputDataArray',
  Form = 'Form',
  CalculatedField = 'CalculatedField',
  DataframeGroupBy = 'DataframeGroupBy',
  DataFrameIndex = 'DataFrameIndex',
  SelectRows = 'SelectRows',
  DataArrayFromPandas = 'DataArrayFromPandas',
  IndexFromPandas = 'IndexFromPandas',
  DataArrayFilter = 'DataArrayFilter',
  EditIndex = 'EditIndex',
  RenameIndexItem = 'RenameIndexItem',
  SourceCSV = 'sourcecsv',
  DataReadWizard = 'DataReadWizard',
  InputScalar = 'InputScalar',
  SourceXLS = 'sourcexls',
  SortDataFrame = 'SortDataFrame',
  DataFrameFromArray = 'DataFrameFromArray',
  CreateIndex = 'CreateIndex',
  ChangeIndex = 'ChangeIndex',
  Selector = 'Selector',
  Report = 'Report',
  InputCube = 'InputCube',
  RenameColumnDataFrame = 'RenameColumnDataFrame',
  DeleteColumnDataFrame = 'DeleteColumnDataFrame',
  DuplicateColumnDataFrame = 'DuplicateColumnDataFrame',
  ChangeColumnTypeDataframe = 'ChangeColumnTypeDataframe',
  OrderColumnDataFrame = 'OrderColumnDataFrame',
  CleanColumnDataFrame = 'CleanColumnDataFrame',
  DragColumnsDataFrame = 'DragColumnsDataFrame',
  DropDuplicatesDataFrame = 'DropDuplicatesDataFrame',
  FillNaNValuesDataFrame = 'FillNaNValuesDataFrame'
}

export enum WizardActions {
  GetColumnList = 'getColumnList',
  GenerateDefinition = 'generateDefinition',
  GenerateNode = 'generateNode',
  GetNodeSchema = 'getNodeSchema',
  GetDataFrameSchema = 'getDataframeSchema',
  GetDataArrayDimensions = 'getDataArrayDimensions',
  GetIndexItems = 'getIndexItems',
  ReadFile = 'readFile',
  GetColumns = 'getColumns'
}

enum WizardColumnIndex {
  Column = 'column',
  Index = 'index'
}

enum WizardDataTypes {
  String = 'string',
  Boolean = 'boolean',
  Numeric = 'numeric',
  Date = 'date',
  Object = 'object',
  Void = 'void'
}

export enum DataframeColumnTypes {
  String = 'string',
  Integer = 'integer',
  Float = 'float',
  Date = 'date',
  Boolean = 'boolean'
}

export interface ModelIndexes {
  title: string
  id: string
}

export interface aggregateDataArrayFromPandas {
  calc: string
  field: string
}

export interface indexesDataArrayFromPandas {
  field: string
  model_index: string
}

export interface SelectColumnsWizardItem {
  field: string
  type: WizardColumnIndex
  dtype: WizardDataTypes
  values?: string[]
}

export interface SelectColumnsWizard extends Array<SelectColumnsWizardItem> {}

export interface DataFrameFromArrayWizard {
  columns: SelectColumnsWizard
  model_indexes: ModelIndexes[]
}

export interface DataArrayWizardItem {
  field: string
  title: string
  values: string[]
}

export interface DataArrayWizardData extends Array<DataArrayWizardItem> {}

export interface EditIndexData extends Array<string> {}

export enum InputDataFrameDataTypes {
  String = 'String',
  Integer = 'Integer',
  Float = 'Float',
  Date = 'Date',
  Boolean = 'Boolean'
}

export interface InputDataFrameField {
  field: string
  column: string
  dtype: InputDataFrameDataTypes
}

export interface InputDataFrameWizard {
  supported_dtypes: InputDataFrameDataTypes[]
  fields: InputDataFrameField[]
}

export enum InputDataArrayDefaultValueTypes {
  Scalar = 'scalar',
  Model_Node = 'model_node'
}

export interface InputDataArrayWizard {
  node_indexes: GetAllIndexesResult[]
  default_value: string
  default_value_type: InputDataArrayDefaultValueTypes
}

export type WizardData =
  | SelectColumnsWizard
  | InputDataFrameWizard
  | InputDataArrayWizard
  | FormData
  | DataFrameFromArrayWizard
  | DataArrayWizardData
  | EditIndexData
  | ReportWizardData
  | InputCubeData
  | ManipulateColumnData

export type CreateAliasResponse = { id: string }[]

export interface WizardInfo {
  nodeId: string
  open: boolean
  wizardType: WizardTypes
  wizardData: WizardData
  editMode?: boolean
  nodeTitle?: string
  isFromDiagram?: boolean
  isFromHandlingData?: boolean
}

export interface OpenWizardParams {
  nodeId: string
}

interface SelectColumnsWizardApplyParams {
  nodeId: string
  columns: string[]
}

export interface filterDataFrame {
  dtype: string
  field: string
  logical_operator: string | null
  operator: string
  type: string
  values: string[]
}

export interface InputDataFrameWizardApplyParams {
  nodeId: string
  fields: InputDataFrameField[]
}

export interface InputDataArrayWizardApplyParams {
  nodeId: string
  title: string
  node_indexes: string[]
  default_value: string
  default_value_type: InputDataArrayDefaultValueTypes
}

export interface CalculatedFieldWizardApplyParams {
  nodeId: string
  formula: string
  name: string
}

export interface FilterDataFrameApplyParams {
  nodeId: string
  filters: filterDataFrame[]
}

export interface DataFrameFromArrayWizardApplyParams {
  nodeId: string
  agg?: aggregateDataArrayFromPandas
  indexes?: indexesDataArrayFromPandas[]
}

export interface CreateIndexFromDataFrameApplyParams {
  nodeId: string
  indexes: string[]
}

export interface DataReadWizardApplyParams {
  encoding: string
  filename: string
  rows: number
  sep: string
}

export interface XLSReadWizardApplyParams {
  filename: string
}

export interface SelectorWizardParams {
  nodeId: string
  values: string[]
  isMultiSelect: boolean
  saveSelectedLabels: boolean
  fromNode: boolean
  fromNodeData?: string
}

export interface DragColumnsDataFrameWizardApplyParams {
  nodeId: string
  columns: (number | string)[]
}

export interface CleanColumnTableData {
  cleanOption: CleanDFColumnOptions
  textToReplace?: string
  replaceWith?: string
}

export interface CleanColumnDataFrameWizardApplyParams {
  nodeId: string
  column: Handsontable.ColumnSettings
  applyChangesInNewColumn: boolean
  data: CleanColumnTableData[]
}

export interface ChangeColumnTypeDataframeWizardApplyParams {
  nodeId: string
  newType: DataframeColumnTypes
  column?: string | number | ColumnDataGetterSetterFunction
  dateFormat?: DateTimeFormatTypes | string
}

export type ApplyWizardParams =
  | SelectColumnsWizardApplyParams
  | InputDataFrameWizardApplyParams
  | InputDataArrayWizardApplyParams
  | FormWizardApplyParams
  | CalculatedFieldWizardApplyParams
  | FilterDataFrameApplyParams
  | DataFrameFromArrayWizardApplyParams
  | CreateIndexFromDataFrameApplyParams
  | DataReadWizardApplyParams
  | XLSReadWizardApplyParams
  | RangeParams
  | SelectorWizardParams
  | ReportWizardApplyParams
  | InputCubeWizardApplyParams
  | DragColumnsDataFrameWizardApplyParams
  | CleanColumnDataFrameWizardApplyParams
  | ChangeColumnTypeDataframeWizardApplyParams

export type CalculatedFieldWizardParams = {
  nodeId: string
  wizardType: WizardTypes
  wizardData: SelectColumnsWizard
  isFromDiagram?: boolean
}

export type GroupAggregateWizardParams = CalculatedFieldWizardParams

export type SetIndexWizardParams = {
  nodeId: string
  wizardType: WizardTypes
  wizardData: SelectColumnsWizard
  variantWizard?: string
  isFromDiagram?: boolean
}

export type FilterDataFrameWizardParams = CalculatedFieldWizardParams

export type SortDataFrameWizardParams = CalculatedFieldWizardParams

export type FilterDataArrayWizardParams = {
  nodeId: string
  wizardType: WizardTypes
  wizardData: DataArrayWizardData
  isFromDiagram?: boolean
}

export type EditIndexWizardParams = {
  nodeId: string
  wizardType: WizardTypes
  wizardData: EditIndexData
}

export type ChangeIndexWizardParams = {
  nodeId: string
  wizardType: WizardTypes
  wizardData: DataArrayWizardData
}

export type CreateIndexParams = {
  nodeId: string
  wizardType: WizardTypes
  wizardData: EditIndexData
}

export type DataArrayFromPandasWizardParams = {
  nodeId: string
  wizardType: WizardTypes
  wizardData: DataFrameFromArrayWizard
}

export type DropDuplicatesDataFrameWizardParams = {
  nodeId: string
  wizardType: WizardTypes
  wizardData: SelectColumnsWizard
}

export interface SelectColumnsWizardParams {
  nodeId: string
  wizardType: WizardTypes
  wizardData: SelectColumnsWizard
  handleClose: () => void
  isFromDiagram?: boolean
}

export interface InputDataFrameWizardParams {
  nodeId: string
  wizardType: WizardTypes
  wizardData: InputDataFrameWizard
  editMode?: boolean
}

export interface InputDataArrayWizardParams {
  nodeId: string
  wizardType: WizardTypes
  wizardData: InputDataArrayWizard
  editMode?: boolean
}

export interface InputCubeDimension {
  field: string
  title: string
}

export enum InputCubeDefaultValueType {
  SCALAR = 'scalar',
  APP_NODE = 'app_node'
}

export interface InputCubeSettings extends InputNodeSettings {
  data_type: DatabaseDataType
  default_value_type: InputCubeDefaultValueType
  default_value: string | number | undefined
  remove_non_existing_combinations?: boolean
  allow_dimension_changes?: boolean
}

export interface InputCubeData {
  table_name: string
  dimensions: InputCubeDimension[]
  settings: InputCubeSettings
  secret_keys?: string[]
}

export interface ManipulateColumnData {
  column: Handsontable.ColumnSettings
  new_column?: string
}

export interface ManipulateColumnWizardParams {
  nodeId: string
  wizardType: WizardTypes
  wizardData: ManipulateColumnData
}

export interface InputCubeWizardParams {
  nodeId: string
  wizardType: WizardTypes
  wizardData: InputCubeData
  editMode?: boolean
}

export interface InputCubeWizardApplyParams {
  node_id: string
  input_cube_data: InputCubeData
}

export interface InputCubeDatabaseSettingsComponent {
  settings: InputCubeSettings
  setFieldValue: (field: string, value: any) => void
}

export type NodeFeedback = {
  nodeId?: string
  title?: string
  moduleId?: string
  feedback?: string
}

export type InterfaceFeedback = {
  interfaceId: string
  interfaceTitle?: string
  componentId: string
  componentTitle?: string
  feedback?: string
}

export type NodeInterfaceFeedback = {
  nodeId: string
  nodeTitle?: string
  feedback?: string
}

export type SearchNodesData = {
  extraClasses: string[]
}

export interface IndexValuesResult {
  type: string
  value: string
  selected: boolean
  geoDef: string | null
}

export interface IndexFilterInfo {
  open: boolean
  componentId: string
  field: string
  nodeId: string
  title: string
  filters: (string | number)[]
  filterComponentOnly: boolean
  singleSelect?: boolean
}

export enum IndexTextFilterChoices {
  Contains = 'contains',
  NotContains = 'notcontains',
  BeginWith = 'begin_with',
  NotBeginWith = 'not_begin_with',
  EndWith = 'end_with',
  NotEndWith = 'not_end_with'
}
export enum IndexFilterChoices {
  Contains = 'contains',
  NotContains = 'notcontains',
  BeginWith = 'begin_with',
  NotBeginWith = 'not_begin_with',
  EndWith = 'end_with',
  NotEndWith = 'not_end_with',
  GreaterThan = 'greater_than',
  GreaterThanEqualTo = 'greater_than_equal_to',
  LessThan = 'less_than',
  LessThanEqualTo = 'less_than_equal_to'

  // Not implemented yet
  // Equal = 'equal',
  // NotEqual = 'notequal',
  // Earliest = 'earliest',
  // Latest = 'latest',
  // Between = 'between',
  // NotBetween = 'notbetween'
}

export enum DataArrayFilterObjectChoices {
  is = 'is'
}

export enum DataFrameFilterObjectChoices {
  Contains = 'contains-contains',
  DoesNotContain = 'does not contain-notcontains',
  is = 'is-==',
  isNot = 'is not-!=',
  StartsWith = 'starts with-startswith',
  DoesNotStartWith = 'does not start with-notstartswith',
  EndsWith = 'ends with-endswith',
  DoesNotEndsWith = 'does not end with-notendswith'
}

export enum DataFrameFilterDateChoices {
  Equals = 'equals-==',
  NotEqual = 'not equal-!=',
  IsBelow = 'is below-<',
  IsAtMost = 'is at most-<=',
  IsGreaterThan = 'is greater than->',
  IsAtLeast = 'is at least->=',
  IsBetween = 'is between-between',
  IsOutside = 'is outside-outside'
}

export enum DataFrameFilterNumberChoices {
  Equals = 'equals-==',
  NotEqual = 'not equal-!=',
  IsBelow = 'is below-<',
  IsAtMost = 'is at most-<=',
  IsGreaterThan = 'is greater than->',
  IsAtLeast = 'is at least->=',
  IsBetween = 'is between-between',
  IsOutside = 'is outside-outside',
  IsDefined = 'is defined-defined',
  IsNotDefined = 'is not defined-undefined'
}

export enum LogicalChoices {
  And = 'and-&',
  Or = 'or-|'
}

export interface InstalledLibrary {
  name: string
  version: string
  location: string
  installer: string
  uninstalling?: boolean
}

export interface GetAllIndexesResult {
  title: string
  id: string
}

export interface GetAllIndexesData {
  count: number
  next: string | null
  previous: string | null
  results: GetAllIndexesResult[]
}

// Form

export enum FormColumnType {
  input = 'input',
  checkbox = 'checkbox',
  selector = 'selector',
  related_selector = 'related_selector',
  calc = 'calc',
  dataframe_column = 'dataframe_column',
  system = 'system'
}

export enum DatabaseDataType {
  string = 'string',
  integer = 'integer',
  float = 'float',
  boolean = 'boolean',
  date = 'date'
}

export interface FormFormula {
  from_node: boolean
  value: string
}

export interface FormSelectorValues {
  from_node: boolean
  values: string | (string | number)[]
}

export interface FormColumn {
  field: string
  title?: string
  column_type?: FormColumnType
  default_value?: string | number
  hidden?: boolean
  read_only?: boolean
  // ColumnType.input attributes only
  data_type?: DatabaseDataType
  // ColumnType.calc attributes only
  formula?: FormFormula
  // ColumnType.selector attributes only
  values?: FormSelectorValues
  filter_rows_by_domain?: boolean
  // ColumnyType.related_selector attributes only
  related_map?: string
  related_map_column_values?: string
  related_columns?: string[]
  id?: number
  nested_headers?: string[]
  // ColumnType.selector and ColumnyType.related_selector attributes only
  strict?: boolean | null
}

export interface WizardFormColumn extends FormColumn {
  id: number
}

export enum DatabaseConnectionString {
  sqlite = 'sqlite',
  encrypted_sqlite = 'encrypted_sqlite',
  postgresql = 'postgresql',
  custom = 'custom'
}

export interface SQLiteConnection {
  database_path?: string
}

export interface EncryptedSQLiteConnection {
  database_path?: string
  password?: string
  secret_key?: string
}

export interface PostgreSQLConnection {
  host?: string
  database?: string
  username?: string
  password?: string
  secret_key?: string
}

interface InputNodeSettings {
  database_engine?: DatabaseConnectionString
  database_connection?: SQLiteConnection | EncryptedSQLiteConnection | PostgreSQLConnection
  database_custom_url?: string
}

export interface FormSettings extends InputNodeSettings {
  initial_empty_rows?: number
  allow_add_rows?: boolean
  allow_remove_rows?: boolean
  allow_add_new_columns?: boolean
  fixed_columns?: number
  from_dataframe?: boolean
  source_dataframe_node?: string
  source_dataframe_id_column?: string
  remove_old_rows?: boolean
}

export interface FormData {
  table_name: string
  columns: FormColumn[]
  settings: FormSettings
  recreate_table?: boolean
  secret_keys?: string[]
}

export interface FormWizardParams {
  nodeId: string
  wizardType: WizardTypes
  wizardData?: FormData
  editMode?: boolean
}

export interface FormWizardApplyParams {
  node_id: string
  form_data: FormData
}

export type FormColumnTypeOption = {
  value: FormColumnType
  label: string
}

export interface FormWizardAdvancedPropsComponent {
  columnData: FormColumn
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<FormColumn>>
  getErrorMessage: (fieldName: string, parentField?: string) => string
}

export interface WizardFormSettings {
  table_name: string
  settings: FormSettings
}

export interface FormWizardSettingsComponent {
  settings: FormSettings
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<WizardFormSettings>>
  getSettingsErrorMessage: (fieldName: string, parentField?: string) => string
  secretKeys: string[]
}

export interface FormSelectedColumn {
  field: string
  title: string
}

export interface GetFormColumnsData {
  count: number
  next: string | null
  previous: string | null
  results: FormSelectedColumn[]
}

// Report wizard

export enum ReportWizardOutputType {
  dataframe = 'dataframe',
  dataarray = 'dataarray'
}

export interface ReportWizardSettings {
  output_type: ReportWizardOutputType
  items_node_id?: string
  dimension_name?: string
  default_value?: string
}

export enum ReportWizardItemSource {
  app_node_id = 'app_node_id',
  input = 'input'
}

export type ReportWizardSourceOption = {
  value: ReportWizardItemSource
  label: string
}

export interface ReportWizardItem {
  name: string
  item_source?: ReportWizardItemSource
  value?: string
  as_negative?: boolean
  is_extra?: boolean
}

export interface ReportWizardExtendedItem extends ReportWizardItem {
  id: number
}

export interface ReportWizardData {
  items_allocation: ReportWizardItem[]
  settings: ReportWizardSettings
}

export interface ReportWizardParams {
  nodeId: string
  wizardType: WizardTypes
  wizardData?: ReportWizardData
  editMode?: boolean
}

export interface ReportWizardApplyParams {
  node_id: string
  report_data: ReportWizardData
}

export enum DataReadingFilesTypes {
  readCSV = 'CSV',
  readExcel = 'Excel',
  readParquet = 'Parquet',
  readPickle = 'Pickle',
  readFeather = 'Feather',
  readHDF = 'HDF',
  readJSON = 'JSON',
  readXML = 'XML',
  readHTML = 'HTML'
}

export enum DataReadingDatabasesTypes {
  readMySQL = 'MySQL',
  readPostgreSQL = 'PostgreSQL',
  readSQLServer = 'SQL Server',
  readOracle = 'Oracle',
  readCustom = 'Custom'
}

export enum ReadOnlineTypes {
  readAWS = 'AWS',
  readAzure = 'Azure',
  readGPC = 'GPC',
  readAPIGet = 'API Get'
}

export enum EncodingTypes {
  Auto = 'Auto/auto',
  UTF8 = 'UTF-8/utf_8',
  UTF16 = 'UTF-16/utf_16',
  UsAscii = 'US-ascii/ascii',
  Latin1 = 'Latin 1/latin_1',
  Windows1251 = 'Windows-1251/cp1251',
  Windows1252 = 'Windows-1252/cp1252',
  IBM850 = 'IBM850/cp852'
}

export enum SeparatorTypes {
  Auto = 'Auto/auto',
  Space = 'Space/" "',
  Comma = ',/,',
  Tab = 'Tab/\t',
  Semicolon = ';/;',
  Or = '|/|'
}

export enum RangeIndexTypes {
  String = 'string',
  Numeric = 'numeric',
  Date = 'date',
  Time = 'time'
}

export enum DateTimeFormatTypes {
  YearDotMonth = '%Y.%m',
  YearDashMonth = '%Y-%m',
  YearSlashMonth = '%Y/%m',
  YearDotMonthDotDay = '%Y.%m.%d',
  YearDashMonthDashDay = '%Y-%m-%d',
  YearSlashMonthSlashDay = '%Y/%m/%d',
  Custom = 'Custom'
}

export enum FrecuencyTypes {
  Yearly = 'Yearly/Y',
  Monthly = 'Monthly/M',
  Weekly = 'Weekly/W',
  Daily = 'Daily/D'
}

export enum CleanDFColumnOptions {
  RemoveDuplicatesWords = 'RemoveDuplicatesWords',
  ReplaceMultiSpace = 'ReplaceMultiSpace',
  RemovePunctuation = 'RemovePunctuation',
  RemoveNumbers = 'RemoveNumbers',
  KeepOnlyAlphaNumeric = 'KeepOnlyAlphaNumeric',
  NormalizeAccents = 'NormalizeAccents',
  RemoveSpaces = 'RemoveSpaces',
  AddSpaceBetweenWords = 'AddSpaceBetweenWords',
  RemoveRepeatedChars = 'RemoveRepeatedChars',
  UpperWordCase = 'UpperWordCase',
  LowerWordCase = 'LowerWordCase',
  TitleWordCase = 'TitleWordCase',
  RemoveHiddenChars = 'RemoveHiddenChars',
  ReplaceHypensWithSpace = 'ReplaceHypensWithSpace',
  ReplaceText = 'ReplaceText'
}

export interface IndexRangeTypes {
  setDisableConfirm: (value: boolean) => void
  setRangeParams: (value: RangeParams) => void
  setDisableDateConfirm: (value: boolean) => void
}

export enum IndexTypes {
  Range = 'range',
  List = 'list'
}

export interface RangeParams {
  type: string
  rangeType: string
  nodeId?: string
  freq?: string | null
  from?: number | null
  to?: number | null
  periods?: number | string | null
  start?: string | Date | null
  end?: string | Date | null
  step?: number | string | null
  stringPrefix?: string | null
  values?: string | null
  format?: string | null
}

export interface WizardCommonTypes {
  nodeId: string
  wizardType: WizardTypes
  action: WizardActions
  editMode?: boolean
}

export interface ApplyWizardTypes extends WizardCommonTypes {
  params: ApplyWizardParams
  modelDiagramId?: string
}

export interface OpenWizardTypes extends WizardCommonTypes {
  includeColumnList?: boolean
  nodeTitle?: string
  isFromDiagram?: boolean
  isFromHandlingData?: boolean
}
