import { NavigateFunction } from 'react-router-dom'

import { AppInstanceInfo, AppVersionsDialogState } from 'common/types/application'
import { InterfaceState } from 'store/interface'
import { ModelState } from 'store/model'
import { DataToCompare } from 'pages/appConsolidation/types'

export enum AppActionTypes {
  OPEN_APP = '@@app/OPEN',
  OPEN_APP_ON_NEW_INSTANCE = '@@app/OPEN_APP_ON_NEW_INSTANCE',
  CREATE_VERSION_FROM_CLOSED_APP = '@@app/CREATE_VERSION_FROM_CLOSED_APP',
  CLOSE_APP = '@@app/CLOSE',
  SET_OPEN_APP = '@@app/SET_OPEN_APP',
  RESET_APP_INFO = '@@app/RESET_APP_INFO',
  SAVE_APP = '@@app/SAVE_APP',
  SAVE_APP_IN_MY_WORKSPACE = '@@app/SAVE_APP_IN_MY_WORKSPACE',
  SAVE_APP_AS_NEW_VERSION = '@@app/SAVE_APP_AS_NEW_VERSION',
  SET_SAVING_APP = '@@app/SET_SAVING_APP',
  SET_APP_HAS_UNSAVED_CHANGES = '@@app/SET_APP_HAS_UNSAVED_CHANGES',
  SHOW_CREATE_APP_DIALOG = '@@app/SHOW_CREATE_APP_DIALOG',
  CREATE_APP = '@@app/CREATE_APP',
  SET_APP_INFO = '@@app/SET_APP_INFO',
  UPDATE_DEFAULT_INTERFACE_ID = '@@app/UPDATE_DEFAULT_INTERFACE_ID',
  APP_RELOADED = '@@app/APP_RELOADED',
  RELOAD_WITH_MY_VENV = '@@app/RELOAD_WITH_MY_VENV',
  DOWNLOAD_EXAMPLE = '@@app/DOWNLOAD_EXAMPLE',
  CHANGE_APP_VERSION = '@@app/CHANGE_APP_VERSION',
  UPDATE_APP_VERSION_PROPERTIES = '@@app/UPDATE_APP_VERSION_PROPERTIES',
  SET_CHANGING_APP_VERSION = '@@app/SET_CHANGING_APP_VERSION',
  SET_CREATING_APP_VERSION = '@@app/SET_CREATING_APP_VERSION',
  HAS_PROCESS_DELAYED = '@@app/HAS_PROCESS_DELAYED',
  SHOW_APP_VERSIONS_DIALOG = '@@app/SHOW_APP_VERSIONS_DIALOG',
  REFRESH_SCENARIOS_TABLE = '@@app/REFRESH_SCENARIOS_TABLE',
  ADD_BROADCAST_CHANNEL = '@@app/ADD_BROADCAST_CHANNEL',
  REMOVE_BROADCAST_CHANNEL = '@@app/REMOVE_BROADCAST_CHANNEL',
  SHOW_UNREAD_NOTIFICATIONS = '@@app/SHOW_UNREAD_NOTIFICATIONS',
  SET_SAVING_APP_WITH_INTERFACE_EDIT_MODE = '@@app/SAVING_APP_WITH_INTERFACE_EDIT_MODE',
  SET_OPEN_SAVE_APP_DIALOG_FROM_INTERFACE = '@@app/SET_OPEN_SAVE_APP_DIALOG_FROM_INTERFACE',
  SET_OPEN_APP_AFTER_SAVE = '@@app/SET_OPEN_APP_AFTER_SAVE',
  GET_APP_CONSOLIDATION_DIFFERENCES = '@@app/GET_APP_CONSOLIDATION_DIFFERENCES',
  SET_WAITING = '@@app/SET_WAITING',
  CLOSE_APP_FROM_OTHER_TAB = '@@app/CLOSE_APP_FROM_OTHER_TAB'
}

export interface AppState {
  readonly info?: AppInstanceInfo
  readonly hasUnsavedChanges?: boolean
  readonly saving?: Boolean
  readonly model: ModelState
  readonly showCreateAppDialog?: Boolean
  readonly appReloaded?: Boolean
  readonly hasProcessDelayed?: Boolean
  readonly interface: InterfaceState
  readonly changingAppVersion?: Boolean
  readonly creatingAppVersion?: Boolean
  readonly appVersionsDialog?: AppVersionsDialogState
  readonly refreshScenariosTableCounter?: number
  readonly broadcastChannel?: BroadcastChannel
  readonly showUnreadNotifications?: boolean
  readonly savingAppWithInterfaceEditMode?: boolean
  readonly openSaveAppDialogFromInterface?: boolean
  readonly openAppAfterSave?: { open: boolean; openInNewInstance: boolean; modelPath: string }
  readonly appConsolidationDifferences?: DataToCompare
  readonly waiting?: boolean
}

export interface OpenAppParams {
  navigate: NavigateFunction
  fullPath: string
  openAsReadOnly?: boolean
  version?: string
}
