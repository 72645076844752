import { NavigateFunction } from 'react-router-dom'
import { Elements, Node, XYPosition } from 'react-flow-renderer'

import {
  CodeNode,
  ModuleInfo,
  NodeFeedback,
  NodeClipboard,
  WizardData,
  WizardTypes,
  SetInputNodeValueParams,
  GlobalShortcuts,
  ResizeNodeDirection,
  PositionNodeDirection,
  ApplyWizardTypes,
  OpenWizardTypes,
  SearchableNodeClasses,
  InterfaceFeedback,
  NodeInterfaceFeedback
} from 'common/types/model'
import { IBaseComponent } from 'pages/interfaces/components/types'
import { InterfaceEvent } from 'pages/interfaces/containers/types'

import { ModelActionTypes } from './types'
import { socketModelDebug } from 'store/socket/constants'
import { NotificationLevels } from 'store/notifier'

export const navigateDiagram = (
  moduleId?: string,
  isStatic: boolean = false,
  cleanSelectedComponent: boolean = true
) => {
  return {
    type: ModelActionTypes.NAVIGATE_DIAGRAM,
    payload: { moduleId, isStatic, cleanSelectedComponent }
  }
}
export const refreshDiagram = () => {
  return {
    type: ModelActionTypes.REFRESH_DIAGRAM
  }
}

export const setElements = (moduleInfo: ModuleInfo | undefined, disableInteractivity?: boolean) => {
  return {
    type: ModelActionTypes.SET_ELEMENTS,
    payload: { moduleInfo, disableInteractivity }
  }
}

export const updateElements = (elements: Elements) => {
  return {
    type: ModelActionTypes.UPDATE_ELEMENTS,
    payload: elements
  }
}

export const updateNodeArrow = (id: string, hasArrow: any) => {
  return {
    type: ModelActionTypes.UPDATE_NODE_ARROW,
    payload: { id, hasArrow }
  }
}

export const selectNode = (nodeId: string | undefined, skipGetNodeResult: boolean = false) => {
  return {
    type: ModelActionTypes.SELECT_NODE,
    payload: {
      nodeId,
      skipGetNodeResult
    }
  }
}

export const setSelectedCodeNode = (codeNode: CodeNode | undefined) => {
  return {
    type: ModelActionTypes.SET_SELECTED_CODE_NODE,
    payload: codeNode
  }
}

export const updateCodeNode = (codeNode: CodeNode) => {
  return {
    type: ModelActionTypes.UPDATE_CODE_NODE,
    payload: codeNode
  }
}

export const setModuleId = (moduleId: string | undefined) => {
  return {
    type: ModelActionTypes.SET_MODULEID,
    payload: moduleId
  }
}

export const deleteNodes = (nodes: Elements) => {
  return {
    type: ModelActionTypes.DELETE_NODES,
    payload: nodes
  }
}

export const deleteNodesFromIds = (nodes: string[]) => {
  return {
    type: ModelActionTypes.DELETE_NODES_FROM_IDS,
    payload: nodes
  }
}

export const createNode = (
  type: string,
  positionX: number,
  positionY: number,
  diagramPosition: XYPosition
) => {
  return {
    type: ModelActionTypes.CREATE_NODE,
    payload: { type, positionX, positionY, diagramPosition }
  }
}

export const completeCreateNode = (
  nodeId: string,
  properties: { name: string; value: any }[],
  typeWizard?: WizardTypes | ''
) => {
  return {
    type: ModelActionTypes.COMPLETE_CREATE_NODE,
    payload: { nodeId, properties, typeWizard }
  }
}

export const activeDraggableNodes = (draggable: boolean) => {
  return {
    type: ModelActionTypes.ACTIVE_DRAGGABLE_NODES,
    payload: draggable
  }
}

export const moveNodes = (nodes: Elements) => {
  return {
    type: ModelActionTypes.MOVE_NODES,
    payload: nodes
  }
}

export const resizeNodes = (
  newWidth: number,
  newHeight: number,
  nodesToResize: Node[],
  direction: string,
  delta: { width: number; height: number },
  nodeDragged: Node
) => {
  return {
    type: ModelActionTypes.RESIZE_NODES,
    payload: { newWidth, newHeight, nodesToResize, direction, delta, nodeDragged }
  }
}

export const getNodeResult = (force: boolean = false) => {
  return {
    type: ModelActionTypes.GET_NODE_RESULT,
    payload: force
  }
}

export const setNodeResult = (nodeInterface: IBaseComponent) => {
  return {
    type: ModelActionTypes.SET_NODE_RESULT,
    payload: nodeInterface
  }
}

export const triggerNodeInterface = (nodeId: string, triggerData: InterfaceEvent) => {
  return {
    type: ModelActionTypes.TRIGGER_NODE_INTERFACE,
    payload: { nodeId, triggerData }
  }
}

export const clearNodeInterface = () => {
  return {
    type: ModelActionTypes.SET_NODE_RESULT
  }
}

export const removeNodeFromUnsaved = (nodeIdentifier: string) => {
  return {
    type: ModelActionTypes.REMOVE_NODE_FROM_UNSAVED,
    payload: nodeIdentifier
  }
}
export const installLibraries = (libraries: string) => {
  return {
    type: ModelActionTypes.INSTALL_LIBRARIES,
    payload: libraries
  }
}
export const updateLibrariesInstallLog = (log: string, notLevel: NotificationLevels) => {
  return {
    type: ModelActionTypes.UPDATE_LIBRARIES_INSTALL_LOG,
    payload: { log, notLevel }
  }
}
export const clearLibrariesInstallLog = () => {
  return {
    type: ModelActionTypes.CLEAR_LIBRARIES_INSTALL_LOG
  }
}
export const confirmCodeNode = (newDefinition?: string) => {
  return {
    type: ModelActionTypes.CONFIRM_CODE_NODE,
    payload: { newDefinition }
  }
}
export const cancelCodeNode = () => {
  return {
    type: ModelActionTypes.CANCEL_CODE_NODE
  }
}
export const runSelectedNode = () => {
  return {
    type: ModelActionTypes.RUN_SELECTED_NODE
  }
}
export const setRunningNode = (nodeId?: string) => {
  return {
    type: ModelActionTypes.SET_RUNNING_NODE,
    payload: nodeId
  }
}
export const setConfirmAndUpdateScalarNode = (nodeId?: string) => {
  return {
    type: ModelActionTypes.CONFIRM_AND_UPDATE_SCALAR_NODE,
    payload: nodeId
  }
}
export const setUpdateNodeResult = () => {
  return {
    type: ModelActionTypes.SET_UPDATE_NODE_RESULT
  }
}
export const getNodePreview = () => {
  return {
    type: ModelActionTypes.GET_NODE_PREVIEW
  }
}
export const setNodePreview = (nodePreview: string) => {
  return {
    type: ModelActionTypes.SET_NODE_PREVIEW,
    payload: nodePreview
  }
}

export const setUpdatePinnedNodes = (value?: boolean) => {
  return {
    type: ModelActionTypes.UPDATE_PINNED_NODES,
    payload: value
  }
}

export const navigateToNode = (
  moduleId: string,
  nodeId: string,
  isStatic: boolean = false,
  changePanePositionFn?: (x: number, y: number, z: number) => void
) => {
  return {
    type: ModelActionTypes.NAVIGATE_TO_NODE,
    payload: { moduleId, nodeId, isStatic, changePanePositionFn }
  }
}

export const openDiagramAndNavigateToNode = (nodeId: string, navigate: NavigateFunction) => {
  return {
    type: ModelActionTypes.OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE,
    payload: { nodeId, navigate }
  }
}

export const openDiagramAndNavigateToNodeFromGoTo = (
  nodeId: string,
  navigate: NavigateFunction,
  codeLayoutId?: string | null,
  runNode?: string | null
) => {
  return {
    type: ModelActionTypes.OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE_FROM_GO_TO,
    payload: { nodeId, navigate, codeLayoutId, runNode }
  }
}

export const setAddedNodeIdToCodeDefinition = (nodeId: string | undefined) => {
  return {
    type: ModelActionTypes.SET_ADDED_NODE_ID_TO_CODE_DEFINITION,
    payload: nodeId
  }
}
export const setCenterNodeFromExternalPage = (nodeId: string | undefined) => {
  return {
    type: ModelActionTypes.SET_CENTER_NODE,
    payload: nodeId
  }
}
export const setLastWidgetFocused = (value?: string) => {
  return {
    type: ModelActionTypes.SET_LAST_WIDGET_FOCUSED,
    payload: value
  }
}

export const openWizard = (data: OpenWizardTypes) => {
  const {
    nodeId,
    wizardType,
    action,
    includeColumnList,
    editMode,
    nodeTitle,
    isFromDiagram,
    isFromHandlingData
  } = data
  return {
    type: ModelActionTypes.OPEN_WIZARD,
    payload: {
      nodeId,
      wizardType,
      action,
      params: { nodeId, includeColumnList },
      editMode,
      nodeTitle,
      isFromDiagram,
      isFromHandlingData
    }
  }
}

export const setWizardData = (
  open: boolean,
  nodeId?: string,
  wizardType?: WizardTypes,
  wizardData?: WizardData,
  editMode?: boolean,
  nodeTitle?: string,
  isFromDiagram?: boolean,
  isFromHandlingData?: boolean
) => {
  return {
    type: ModelActionTypes.SET_WIZARD_DATA,
    payload: {
      nodeId,
      open,
      wizardType,
      wizardData,
      editMode,
      nodeTitle,
      isFromDiagram,
      isFromHandlingData
    }
  }
}

export const copyAsValues = (nodeId: string) => {
  return {
    type: ModelActionTypes.COPY_AS_VALUES,
    payload: { nodeId }
  }
}

export const applyWizard = (payload: ApplyWizardTypes) => {
  return {
    type: ModelActionTypes.APPLY_WIZARD,
    payload
  }
}

export const closeWizard = (nodeId: string, wizardType: WizardTypes) => {
  return {
    type: ModelActionTypes.CLOSE_WIZARD,
    payload: { nodeId, wizardType }
  }
}
export const unlockPropertiesDialog = (closeDialog: boolean) => {
  return {
    type: ModelActionTypes.UNLOCK_PROPERTIES_DIALOG,
    payload: closeDialog
  }
}

export const setNodeProperties = (
  nodeId: string,
  properties: { name: string; value: any }[],
  type?: string
) => {
  return {
    type: ModelActionTypes.SET_NODE_PROPERTIES,
    payload: { nodeId, properties, type }
  }
}

export const updateNodeError = (payload: NodeFeedback) => ({
  type: ModelActionTypes.UPDATE_NODE_ERROR,
  payload
})

export const updateInterfaceError = (payload: InterfaceFeedback) => ({
  type: ModelActionTypes.UPDATE_INTERFACE_ERROR,
  payload
})

export const updateNodeInterfaceError = (payload: NodeInterfaceFeedback) => ({
  type: ModelActionTypes.UPDATE_NODE_INTERFACE_ERROR,
  payload
})

export const updateNodeConsole = (payload: NodeFeedback) => ({
  type: ModelActionTypes.UPDATE_NODE_CONSOLE,
  payload
})

export const clearNodeConsole = () => ({
  type: ModelActionTypes.CLEAR_NODE_CONSOLE
})

export const showNodeIds = (show: boolean) => {
  return {
    type: ModelActionTypes.SHOW_NODE_IDS,
    payload: show
  }
}

export const setNodesInClipboard = (values?: NodeClipboard) => {
  return {
    type: ModelActionTypes.SET_NODES_IN_CLIPBOARD,
    payload: values
  }
}

export const duplicateNodes = (ids: string[]) => {
  return {
    type: ModelActionTypes.DUPLICATE_NODES,
    payload: ids
  }
}

export const pasteNodes = (center?: XYPosition, copyOrCutCenter?: XYPosition) => {
  return {
    type: ModelActionTypes.PASTE_NODES,
    payload: { center, copyOrCutCenter }
  }
}

export const forceSelectionOfNodes = (forceSelectionNodeIds?: string[]) => {
  return {
    type: ModelActionTypes.FORCE_SELECTION_OF_NODES,
    payload: forceSelectionNodeIds
  }
}

export const applyTableChanges = (nodeId: string) => {
  return {
    type: ModelActionTypes.APPLY_TABLE_CHANGES,
    payload: { nodeId }
  }
}

export const setInputNodeValue = (inputNodeParams: SetInputNodeValueParams) => {
  return {
    type: ModelActionTypes.SET_INPUT_NODE_VALUE,
    payload: { inputNodeParams }
  }
}

export const setShortcutPressed = (shortcutPressed?: GlobalShortcuts) => {
  return {
    type: ModelActionTypes.SET_SHORTCUT_PRESSED,
    payload: shortcutPressed
  }
}

export const createAlias = (nodeIds: string[]) => {
  return {
    type: ModelActionTypes.CREATE_ALIAS,
    payload: { nodeIds }
  }
}

export const runNodeAndExpandResult = () => {
  return {
    type: ModelActionTypes.RUN_NODE_AND_EXPAND_RESULT
  }
}

export const runNodeAndExpandCode = () => {
  return {
    type: ModelActionTypes.RUN_NODE_AND_EXPAND_CODE
  }
}

export const setNodeSizeRelativeToOther = (
  direction: ResizeNodeDirection,
  nodesToResize: Node[],
  nodeReference: Node
) => {
  return {
    type: ModelActionTypes.SET_NODE_SIZE_BASED_ON_OTHER,
    payload: { direction, nodesToResize, nodeReference }
  }
}

export const setNodePositionRelativeToOther = (
  direction: PositionNodeDirection,
  nodesToPosition: Node[],
  nodeReference: Node
) => {
  return {
    type: ModelActionTypes.SET_NODE_POSITION_BASED_ON_OTHER,
    payload: { direction, nodesToPosition, nodeReference }
  }
}

export const setModuleNavigationHistory = (moduleNavigationHistory?: string[]) => {
  return {
    type: ModelActionTypes.SET_MODULE_NAVIGATION_HISTORY,
    payload: moduleNavigationHistory
  }
}

export const setShowRelatedNodes = (
  relation: 'showInputs' | 'showOutputs',
  nodeIds: string[],
  show: boolean
) => {
  return {
    type: ModelActionTypes.SET_SHOW_RELATED_NODES,
    payload: { relation, nodeIds, show }
  }
}

export const setNodeComponentToEdit = (componentId?: string, nodeId?: string) => ({
  type: ModelActionTypes.SET_NODE_COMPONENT_TO_EDIT,
  payload: { componentId, nodeId }
})

export const setDontCleanSelectedOnInitialNavigate = (value: boolean) => {
  return {
    type: ModelActionTypes.SET_DONT_CLEAN_SELECTED_ON_INITIAL_NAVIGATE,
    payload: value
  }
}
export const setValueVariantOfindex = (variant?: string) => {
  return {
    type: ModelActionTypes.SET_VALUE_VARIANT_OF_INDEX,
    payload: variant
  }
}
export const setGoToNodeFromInterface = (value?: boolean) => {
  return {
    type: ModelActionTypes.GO_TO_NODE_FROM_INTERFACE,
    payload: value
  }
}

export const setOpenConsole = (value: boolean) => {
  return {
    type: ModelActionTypes.OPEN_CONSOLE,
    payload: value
  }
}
export const setShowModuleId = (value?: boolean) => {
  return {
    type: ModelActionTypes.SHOW_MODULE_ID,
    payload: value
  }
}
export const openDataRead = (value: boolean) => {
  return {
    type: ModelActionTypes.OPEN_DATA_READ,
    payload: value
  }
}
export const openInputData = (value: boolean) => {
  return {
    type: ModelActionTypes.OPEN_INPUT_DATA,
    payload: value
  }
}
export const setDisableDiagramScroll = (value: boolean) => {
  return {
    type: ModelActionTypes.SET_DISABLE_DIAGRAM_SCROLL,
    payload: value
  }
}
export const clearConsoleErrors = () => {
  return {
    type: ModelActionTypes.CLEAR_CONSOLE_ERRORS
  }
}

export const setDebugInfo = (debugInfo: socketModelDebug) => {
  return {
    type: ModelActionTypes.SET_DEBUG_INFO,
    payload: debugInfo
  }
}

export const resetDebugInfo = () => {
  return {
    type: ModelActionTypes.RESET_DEBUG_INFO
  }
}

export const enableAnalysis = (value: boolean) => {
  return {
    type: ModelActionTypes.ENABLE_ANALYSIS,
    payload: value
  }
}

export const setFilteredNodes = (value?: SearchableNodeClasses[]) => {
  return {
    type: ModelActionTypes.SET_FILTERED_NODES,
    payload: value
  }
}
export const setResultWidgetFocused = (value?: boolean) => {
  return {
    type: ModelActionTypes.SET_RESULT_WIDGET_FOCUSED,
    payload: value
  }
}

export const refreshIndex = (value?: boolean) => {
  return {
    type: ModelActionTypes.REFRESH_INDEX,
    payload: value
  }
}
export const setLoadingModule = (value?: boolean) => {
  return {
    type: ModelActionTypes.LOADING_MODULE,
    payload: value
  }
}

export const openAnalizeAndManipulateDataWidget = (nodeId?: string) => {
  return {
    type: ModelActionTypes.OPEN_ANALIZE_AND_MANIPULATE_DATA_WIDGET,
    payload: nodeId
  }
}

export const systemNodeDefinitionHasChanged = (value?: boolean) => {
  return {
    type: ModelActionTypes.SYSTEM_NODE_DEFINITION_HAS_CHANGED,
    payload: value
  }
}

export const selectAndRunNode = (
  nodeId: string,
  propertiesDefinition?: { name: string; value: any }[],
  overwriteDefinition?: boolean
) => {
  return {
    type: ModelActionTypes.SELECT_AND_RUN_NODE,
    payload: { nodeId, propertiesDefinition, overwriteDefinition }
  }
}

export const setHandlingDataOriginalNodeId = (nodeId?: string) => {
  return {
    type: ModelActionTypes.SET_HANDLING_DATA_ORIGINAL_NODE_ID,
    payload: nodeId
  }
}

export const setLoadingManipulateDataWidget = (value?: boolean) => {
  return {
    type: ModelActionTypes.LOADING_MANIPULATE_DATA_WIDGET,
    payload: value
  }
}

export const disableReDoAction = (value?: boolean) => {
  return {
    type: ModelActionTypes.DISABLE_REDO_ACTION,
    payload: value
  }
}
