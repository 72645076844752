import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'
import { GenericBooleanResponse } from 'common/types/managers'
import {
  AppInstanceInfo,
  CustomDiscreteSequenceColorPaletteProperties,
  CustomSeriesColorsProperties
} from 'common/types/application'
import {
  AppVersionProperties,
  AppVersionsNamesData,
  AppVersionStatus,
  CreateAppVersionParams,
  ExportAppVersionsProps,
  GetAppVersionsParams,
  SaveAppInNewVersionParams,
  SelectedScenario,
  UpdateAppVersionParams
} from 'common/types/version'
import {
  GetAppScenariosParams,
  AppScenariosData,
  CreateAppScenarioParams,
  AppScenarioSerializedProperties,
  AppScenarioProperties,
  ExportAppScenariosProps,
  ExportAppScenarioListItem,
  CreateAppScenarioTemplateParams,
  AppScenarioTemplateData,
  AppScenarioTemplateSerializedProperties,
  ScenarioToModifyParams
} from 'common/types/scenario'

const ENDPOINT = '/versions'

interface ListAppByPath {
  app_path: string
}

export class VersionApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, VersionApi.prototype)
    this.getAppVersionProperties = this.getAppVersionProperties.bind(this)
    this.getAppVersions = this.getAppVersions.bind(this)
    this.createAppVersion = this.createAppVersion.bind(this)
    this.saveAppInNewVersion = this.saveAppInNewVersion.bind(this)
    this.changeAppVersion = this.changeAppVersion.bind(this)
    this.updateAppVersionsStatus = this.updateAppVersionsStatus.bind(this)
    this.updateAppVersionProperties = this.updateAppVersionProperties.bind(this)
    this.updateCustomSeriesColors = this.updateCustomSeriesColors.bind(this)
    this.updateCustomDiscreteSequenceColorPalettes = this.updateCustomDiscreteSequenceColorPalettes.bind(
      this
    )
    this.deleteAppVersions = this.deleteAppVersions.bind(this)
    this.exportAppVersions = this.exportAppVersions.bind(this)
    this.importAppVersions = this.importAppVersions.bind(this)
    this.updateAppVersionSelectedScenarios = this.updateAppVersionSelectedScenarios.bind(this)
    this.reloadAppScenarios = this.reloadAppScenarios.bind(this)
    this.getAppScenarioProperties = this.getAppScenarioProperties.bind(this)
    this.getAppScenarios = this.getAppScenarios.bind(this)
    this.createAppScenario = this.createAppScenario.bind(this)
    this.updateAppScenarioProperties = this.updateAppScenarioProperties.bind(this)
    this.deleteAppScenario = this.deleteAppScenario.bind(this)
    this.deleteAppScenarios = this.deleteAppScenarios.bind(this)
    this.duplicateAppScenario = this.duplicateAppScenario.bind(this)
    this.saveAppScenario = this.saveAppScenario.bind(this)
    this.exportAppScenarios = this.exportAppScenarios.bind(this)
    this.importAppScenarios = this.importAppScenarios.bind(this)
    this.checkAppScenarioNameExists = this.checkAppScenarioNameExists.bind(this)
  }

  public async getAppVersionProperties(): Promise<AppVersionProperties> {
    try {
      const res: AxiosResponse<AppVersionProperties> = await this.get(
        `${ENDPOINT}/getAppVersionProperties/`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getAppVersions(data: GetAppVersionsParams): Promise<AppVersionsNamesData> {
    try {
      const res: AxiosResponse<AppVersionsNamesData> = await this.post(
        `${ENDPOINT}/getAppVersions/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async listVersionsByAppPath(data: ListAppByPath): Promise<AppVersionProperties[]> {
    try {
      const res: AxiosResponse<AppVersionProperties[]> = await this.post(
        `${ENDPOINT}/listVersionsByAppPath/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createAppVersion(data: CreateAppVersionParams): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/createAppVersion/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async saveAppInNewVersion(
    data: SaveAppInNewVersionParams
  ): Promise<GenericBooleanResponse> {
    const dataToSend = {
      new_version_name: data.new_version_name,
      labels: data.labels,
      set_as_default: data.set_as_default,
      version_description: data.version_description
    }
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/saveAppInNewVersion/`,
        dataToSend
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async changeAppVersion(version: string): Promise<AppInstanceInfo> {
    try {
      const data = { version }
      const res: AxiosResponse<AppInstanceInfo> = await this.post(
        `${ENDPOINT}/changeAppVersion/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateAppVersionsStatus(
    versions: string[],
    newStatus: AppVersionStatus
  ): Promise<AppInstanceInfo> {
    try {
      const data = { versions, new_status: newStatus }
      const res: AxiosResponse<AppInstanceInfo> = await this.post(
        `${ENDPOINT}/updateAppVersionsStatus/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateAppVersionProperties(
    version: string,
    newProperties: UpdateAppVersionParams
  ): Promise<AppInstanceInfo> {
    try {
      const data = { version, new_properties: newProperties }
      const res: AxiosResponse<AppInstanceInfo> = await this.post(
        `${ENDPOINT}/updateAppVersionProperties/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateCustomSeriesColors(
    version: string,
    newCustomSeriesColors: CustomSeriesColorsProperties[]
  ): Promise<GenericBooleanResponse> {
    try {
      const data = { version, new_custom_series_colors: newCustomSeriesColors }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/updateCustomSeriesColors/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateCustomDiscreteSequenceColorPalettes(
    version: string,
    newCustomDiscreteSequenceColorPalettes: CustomDiscreteSequenceColorPaletteProperties[]
  ): Promise<GenericBooleanResponse> {
    try {
      const data = {
        version,
        new_custom_discrete_sequence_color_palettes: newCustomDiscreteSequenceColorPalettes
      }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/updateCustomDiscreteSequenceColorPalettes/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async deleteAppVersions(versions: string[]): Promise<GenericBooleanResponse> {
    try {
      const data = { versions }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/deleteAppVersions/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async exportAppVersions(versions: string[]): Promise<ExportAppVersionsProps<string>> {
    try {
      const data = { versions_names: versions }
      const res: AxiosResponse<ExportAppVersionsProps<string>> = await this.post(
        `${ENDPOINT}/exportAppVersions/`,
        data,
        {
          responseType: 'blob'
        }
      )
      return { data: this.success<any>(res), headers: res.headers }
    } catch (error) {
      throw error
    }
  }
  public async importAppVersions(zipFilePath: string): Promise<GenericBooleanResponse> {
    try {
      const data = { zip_file_path: zipFilePath }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/importAppVersions/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateAppVersionSelectedScenarios(
    selectedScenarios: SelectedScenario[]
  ): Promise<GenericBooleanResponse> {
    try {
      const data = { selected_scenarios: selectedScenarios }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/updateAppVersionSelectedScenarios/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async reloadAppScenarios(): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.get(`${ENDPOINT}/reloadAppScenarios/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getAppScenarioProperties(
    versionName: string,
    scenarioName: string
  ): Promise<AppScenarioProperties> {
    try {
      const data = { version_name: versionName, scenario_name: scenarioName }
      const res: AxiosResponse<AppScenarioProperties> = await this.post(
        `${ENDPOINT}/getAppScenarioProperties/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getAppScenarios(data: GetAppScenariosParams): Promise<AppScenariosData> {
    try {
      const res: AxiosResponse<AppScenariosData> = await this.post(
        `${ENDPOINT}/getAppScenarios/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createAppScenario(data: CreateAppScenarioParams): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/createAppScenario/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateAppScenarioProperties(
    versionName: string,
    scenarioName: string,
    newProperties: AppScenarioSerializedProperties
  ): Promise<GenericBooleanResponse> {
    try {
      const data = {
        version_name: versionName,
        scenario_name: scenarioName,
        new_properties: newProperties
      }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/updateAppScenarioProperties/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async deleteAppScenario(
    versionName: string,
    scenarioName: string
  ): Promise<GenericBooleanResponse> {
    try {
      const data = { version_name: versionName, scenario_name: scenarioName }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/deleteAppScenario/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async deleteAppScenarios(
    scenariosToDelete: ScenarioToModifyParams[]
  ): Promise<GenericBooleanResponse> {
    try {
      const data = {
        scenarios_to_delete: scenariosToDelete.map((scenarioToDelete) => ({
          version_name: scenarioToDelete.versionName,
          scenario_name: scenarioToDelete.scenarioName
        }))
      }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/deleteAppScenarios/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async duplicateAppScenario(
    versionName: string,
    scenarioName: string
  ): Promise<GenericBooleanResponse> {
    try {
      const data = { version_name: versionName, scenario_name: scenarioName }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/duplicateAppScenario/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async saveAppScenario(
    versionName: string,
    scenarioName: string
  ): Promise<GenericBooleanResponse> {
    try {
      const data = { version_name: versionName, scenario_name: scenarioName }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/saveAppScenario/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async exportAppScenarios(
    versionScenarioList: ExportAppScenarioListItem[]
  ): Promise<ExportAppScenariosProps<string>> {
    try {
      const data = { version_scenario_list: versionScenarioList }
      const res: AxiosResponse<ExportAppScenariosProps<string>> = await this.post(
        `${ENDPOINT}/exportAppScenarios/`,
        data,
        {
          responseType: 'blob'
        }
      )
      return { data: this.success<any>(res), headers: res.headers }
    } catch (error) {
      throw error
    }
  }
  public async importAppScenarios(zipFilePath: string): Promise<GenericBooleanResponse> {
    try {
      const data = { zip_file_path: zipFilePath }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/importAppScenarios/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async checkAppScenarioNameExists(
    versionName: string,
    scenarioName: string
  ): Promise<boolean> {
    try {
      const data = { version_name: versionName, scenario_name: scenarioName }
      const res: AxiosResponse<boolean> = await this.post(
        `${ENDPOINT}/checkAppScenarioNameExists/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async versionNameExists(name: string): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.get(
        `${ENDPOINT}/versionNameExists/?name=${encodeURIComponent(name)}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getAppScenarioTemplates(
    params: GetAppScenariosParams
  ): Promise<AppScenarioTemplateData> {
    try {
      const res: AxiosResponse<AppScenarioTemplateData> = await this.post(
        `${ENDPOINT}/getAppScenarioTemplates/`,
        params
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createAppScenarioTemplate(data: CreateAppScenarioTemplateParams): Promise<boolean> {
    try {
      const res: AxiosResponse<boolean> = await this.post(
        `${ENDPOINT}/createAppScenarioTemplate/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateAppScenarioTemplateProperties(
    version_name: string,
    scenario_template_name: string,
    new_properties: AppScenarioTemplateSerializedProperties
  ): Promise<boolean> {
    try {
      const data = { version_name, scenario_template_name, new_properties: new_properties }
      const res: AxiosResponse<boolean> = await this.post(
        `${ENDPOINT}/updateAppScenarioTemplateProperties/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async deleteAppScenarioTemplate(
    version_name: string,
    scenario_template_name: string
  ): Promise<boolean> {
    try {
      const data = { version_name, scenario_template_name }
      const res: AxiosResponse<boolean> = await this.post(
        `${ENDPOINT}/deleteAppScenarioTemplate/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async duplicateAppScenarioTemplate(
    version_name: string,
    scenario_template_name: string,
    destination_version_name: string
  ): Promise<boolean> {
    try {
      const data = { version_name, scenario_template_name, destination_version_name }
      const res: AxiosResponse<boolean> = await this.post(
        `${ENDPOINT}/duplicateAppScenarioTemplate/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async checkAppScenarioTemplateNameExists(
    version_name: string,
    scenario_template_name: string
  ): Promise<boolean> {
    try {
      const data = { version_name, scenario_template_name }
      const res: AxiosResponse<boolean> = await this.post(
        `${ENDPOINT}/checkAppScenarioTemplateNameExists/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const versionApi = new VersionApi(apiConfig)
