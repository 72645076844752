import {
  all,
  call,
  fork,
  put,
  race,
  take,
  select,
  ActionPattern,
  takeLatest,
  delay
} from 'redux-saga/effects'
import { eventChannel, Channel, END } from 'redux-saga'
import { AxiosResponse } from 'axios'

import ReconnectingWebSocket from 'reconnecting-websocket'
import { CloseEvent } from 'reconnecting-websocket/events'

import { INSTANCE_ID } from 'common/api/storageConsts'
import { enqueue } from 'common/notifier'
import { ApplicationState } from 'store'
import { setAppHasUnsavedChanges } from 'store/application/actions'
import { BlockerActions, NotificationLevels } from 'store/notifier/types'
import { enqueueBlocker } from 'store/notifier/actions'
import {
  setDebugInfo,
  setNodeResult,
  setRunningNode,
  updateCodeNode,
  updateInterfaceError,
  updateLibrariesInstallLog,
  updateNodeConsole,
  updateNodeError,
  updateNodeInterfaceError
} from 'store/model/actions'

import { SocketActionTypes } from './types'
import {
  MessageType,
  SocketMessage,
  SocketInstanceResources,
  SocketNodeFeedback,
  SocketInterfaceComponentsList,
  SocketInterfaceComponentResult,
  SocketInstanceStatusMessage,
  socketModelDebug,
  SocketDownloadMessage,
  SocketUploadMessage,
  SocketMessageInternalTests,
  SocketAppInfo,
  SocketInterfaceFeedback,
  SocketNodeInterfaceFeedback,
  SocketAssistantBotMessage,
  SocketCodeHelperMessage,
  SocketInterfaceFilterComponentResult,
  SocketInterfaceFilterComponentsList
} from './constants'
import {
  setProgress,
  updateInstanceResources,
  setProgressBar,
  setUploaderParams,
  internalTestsStatus,
  setAppLockInfo,
  setRefreshAppNotifications,
  setAssistantBotThreadMessageId,
  setAssistantBotStopped
} from './actions'
import { getAssistantBotStateKey } from 'common/helpers/getAssistantBotStateKey'
import { AssistantBotChatSource } from 'common/types/assistantBot'
import { fileManagerApi } from 'services/fileManager'
import { AssistantBotKey, updateMessages } from 'store/assistantBot'
import {
  setComponentIsLoading,
  setRefreshCurrentInterface,
  setUpdateDefaultInterfaceLayout,
  setApplicationComponent,
  addFilterToFilterComponentState,
  setFilterComponentsLoading
} from 'store/interface/actions'

import { store } from 'index'
import { GridLayout } from 'pages/interfaces/containers/types'

const PING_TIMEOUT_MS = 15000
const {
  location: { origin: locationOrigin }
} = window
const baseURL =
  process.env.NODE_ENV === 'production'
    ? `${locationOrigin.startsWith('https') ? 'wss' : 'ws'}://${
        locationOrigin.split('://')[1]
      }/ws/ui`
    : 'ws://localhost:8000/ws/ui'
const websocketPingTimeoutError = `No PING or message received within ${
  PING_TIMEOUT_MS / 1000
} seconds`

let ws: ReconnectingWebSocket
let pingTimeout: NodeJS.Timeout

function setupPingTimeout() {
  // If no "PING" message is received within PING_TIMEOUT_MS seconds from the last message received, it closes
  // the connection and tries to reconnect to the websocket.
  // This is done because there may be cases where the user has a network connection problem
  // and the websocket connection is lost and it doesn't reconnect for the next 10 minutes
  // unless the page is refreshed.
  clearTimeout(pingTimeout)
  pingTimeout = setTimeout(() => {
    console.error(
      `WebSocket error: ${websocketPingTimeoutError}. Closing WebSocket and trying to reconnect...`
    )
    ws.close(4001, websocketPingTimeoutError)
    ws.reconnect()
  }, PING_TIMEOUT_MS)
}

function createEventServer(companyCode: string, instanceId: string) {
  return eventChannel((emit) => {
    const createWs = () => {
      // Subscribe to websocket
      const options = {
        connectionTimeout: 1000
      }
      ws = new ReconnectingWebSocket(`${baseURL}/${instanceId}`, [], options)

      ws.addEventListener('open', () => {
        emit({ type: SocketActionTypes.WEBSOCKET_CONNECTION_STATUS_ON })
        setupPingTimeout()
      })

      ws.addEventListener('message', (event: MessageEvent) => {
        const message = event.data
        clearTimeout(pingTimeout)
        setupPingTimeout()
        if (message !== 'PING') {
          emit({ data: JSON.parse(message) })
        }
      })

      ws.addEventListener('error', (error) => {
        console.log('WebSocket error: ', error)
      })

      ws.addEventListener('close', (event: CloseEvent) => {
        emit({ type: SocketActionTypes.WEBSOCKET_CONNECTION_STATUS_OFF })
        clearTimeout(pingTimeout)
        if (event.code === 1005) {
          emit(END)
        }
      })
    }

    createWs()

    return () => {
      clearTimeout(pingTimeout)
      ws.close()
    }
  })
}

function* processWSModelActions({
  messageType,
  message,
  title,
  progress,
  interfaceId,
  result,
  notLevel
}: SocketMessage) {
  switch (messageType) {
    case MessageType.OPENING_APP:
      if (progress === 100) {
        yield delay(200)
        yield put(setProgress({ show: false, value: 100, message: title }))
      } else {
        yield put(setProgress({ value: progress || 0, message: title }))
      }
      break
    case MessageType.NODE_BUTTON_FINISH_PROCESSING:
      // TODO: trigger end processing
      break
    case MessageType.APP_UNSAVED_CHANGES:
      yield put(setAppHasUnsavedChanges(Boolean(message)))
      break
    case MessageType.LIBRARIES_INSTALL_PROGRESS:
      yield put(updateLibrariesInstallLog(message, notLevel))
      break
    case MessageType.NODE_INTERFACE_RESULT:
      if (interfaceId && result) {
        yield put(setNodeResult(result))
        yield put(setApplicationComponent(interfaceId, result as any))
        yield put(setComponentIsLoading(interfaceId, [], false))
      }
      break
  }
}

function* callShowMsg({
  title,
  message,
  notLevel,
  autoHideDuration
}: {
  title?: string
  message: string
  notLevel?: NotificationLevels
  autoHideDuration?: number
}) {
  const notification = {
    key: (new Date().getTime() + Math.random()).toString(),
    title,
    message,
    options: {
      variant: notLevel || NotificationLevels.INFO,
      persist: true,
      autoHideDuration
    }
  }
  yield put(enqueue(notification))
}

function* processWSNotificationActions({
  messageType,
  title,
  message,
  notLevel,
  progress,
  closeWhenFinished,
  autoHideDuration
}: SocketMessage) {
  switch (messageType) {
    case MessageType.STANDARD_MESSAGE:
      yield callShowMsg({ title, message, notLevel, autoHideDuration })
      break
    case MessageType.PROGRESS_BAR:
      if (progress === 100) {
        yield delay(200)
        yield put(setProgressBar({ show: true, value: 100, message, closeWhenFinished }))
      } else {
        yield put(setProgressBar({ show: true, value: progress || 0, message, closeWhenFinished }))
      }
      break
    case MessageType.KILLED_INSTANCE:
      yield put(enqueueBlocker({ action: BlockerActions.LOGOUT, title, message }))
      yield sessionStorage.removeItem(INSTANCE_ID)
      break
  }
}

function* processWSInstanceResources(payload: SocketInstanceResources) {
  yield put(updateInstanceResources(payload))
}

function* processWSModelDebug(payload: socketModelDebug) {
  yield put(setDebugInfo(payload))
}

function* processWSNodeFeedback(payload: SocketNodeFeedback) {
  switch (payload.messageType) {
    case MessageType.SEND_NODE_ERROR:
      yield put(updateNodeError(payload.message))
      break
    case MessageType.SEND_NODE_CONSOLE:
      if (payload.message?.feedback || payload.message?.nodeId) {
        yield put(updateNodeConsole(payload.message))
      }
      break
  }
}

function* processWSInterfaceFeedback(payload: SocketInterfaceFeedback) {
  switch (payload.messageType) {
    case MessageType.SEND_INTERFACE_ERROR:
      yield put(updateInterfaceError(payload.message))
      break
  }
}

function* processWSNodeInterfaceFeedback(payload: SocketNodeInterfaceFeedback) {
  switch (payload.messageType) {
    case MessageType.SEND_NODE_INTERFACE_ERROR:
      yield put(updateNodeInterfaceError(payload.message))
      break
  }
}

function* processWSInterfaceComponentResult(
  payload:
    | SocketInterfaceComponentsList
    | SocketInterfaceComponentResult
    | SocketInterfaceFilterComponentResult
    | SocketInterfaceFilterComponentsList
) {
  const interfaceId: string = payload.interfaceId
  switch (payload.messageType) {
    case MessageType.COMPONENTS_THAT_NEED_RENDERING:
      const componentsList = (payload as SocketInterfaceComponentsList).componentsList as string[]
      if (componentsList?.length > 0) {
        yield put(setComponentIsLoading(interfaceId, componentsList, true))
      }
      break
    case MessageType.FILTER_COMPONENTS_THAT_NEED_RENDERING:
      const filterComponentsList = (payload as SocketInterfaceFilterComponentsList).componentIds
      if (filterComponentsList?.length > 0) {
        yield put(setFilterComponentsLoading(filterComponentsList))
      }
      break
    case MessageType.COMPONENT_RESULT:
      const result = (payload as SocketInterfaceComponentResult).result
      const defaultInterfaceId: string | undefined = ((state: ApplicationState) =>
        state.app.info?.defaultInterfaceId)(yield select())
      const defaultInterfaceLayout: GridLayout | undefined = ((state: ApplicationState) =>
        state.app.interface.defaultInterfaceLayout)(yield select())
      const defaultInterfaceHasMenu = defaultInterfaceLayout?.components.some(
        (component) => 'menu' in component.properties
      )
      if (defaultInterfaceId === interfaceId && defaultInterfaceHasMenu) {
        yield put(setUpdateDefaultInterfaceLayout(result, interfaceId))
      }
      yield put(setApplicationComponent(interfaceId, result))
      break
    case MessageType.REFRESH_CURRENT_INTERFACE:
      yield put(setRefreshCurrentInterface(true))
      break
    case MessageType.FILTER_RESULT:
      const resultFilter = (payload as SocketInterfaceFilterComponentResult).result
      yield put(addFilterToFilterComponentState(resultFilter))
      break
  }
}

function* processWSInstanceStatusActions({
  messageType,
  title,
  message,
  instanceId
}: SocketInstanceStatusMessage) {
  switch (messageType) {
    case MessageType.PURGED_SESSION:
      yield put(enqueueBlocker({ action: BlockerActions.LOGOUT, title, message }))
      yield sessionStorage.removeItem(INSTANCE_ID)
      break
    case MessageType.PURGED_INSTANCE:
      yield put(enqueueBlocker({ action: BlockerActions.CLOSE_INSTANCE, title, message }))
      yield sessionStorage.removeItem(INSTANCE_ID)
      break
    case MessageType.ENGINE_DOWN:
      yield put(
        enqueueBlocker({ action: BlockerActions.CLOSE_INSTANCE, title, message, instanceId })
      )
      yield sessionStorage.removeItem(INSTANCE_ID)
      break
  }
}

function* processWSAssistantBot({
  messageType,
  message,
  stop,
  threadId,
  assistantType,
  error
}: SocketAssistantBotMessage) {
  const assistantBotKey: AssistantBotKey = getAssistantBotStateKey(assistantType)
  const listenBotMessages = ((state: ApplicationState) =>
    state.assistantBot[assistantBotKey].listenBotMessages)(yield select())
  const excludedThreadIds = ((state: ApplicationState) =>
    state.assistantBot[assistantBotKey].excludedThreadIds)(yield select())

  if (message) {
    const messages = ((state: ApplicationState) => state.assistantBot[assistantBotKey].messages)(
      yield select()
    )

    if (
      (listenBotMessages || listenBotMessages === undefined) &&
      ((threadId && !excludedThreadIds.includes(threadId)) || (!threadId && error))
    ) {
      if (threadId) {
        yield put(setAssistantBotThreadMessageId(assistantBotKey, threadId))
      }

      if (
        messages.length === 0 ||
        messages[messages.length - 1].role !== AssistantBotChatSource.assistant
      ) {
        if (message.startsWith('\n')) {
          message = message.substring(1)
        }
        messages.push({
          role: AssistantBotChatSource.assistant,
          content: message
        })
      } else {
        messages[messages.length - 1].content += message
      }
      yield put(updateMessages(assistantBotKey, [...messages]))
    }
  }
  if ((threadId && !excludedThreadIds.includes(threadId)) || !!stop) {
    yield put(setAssistantBotStopped(assistantBotKey, stop ? true : false))
  }
  if (error) {
    // Show error in console so that we can know that failed
    console.error(error)
  }
}

function* processWSCodeHelper({ message, nodeId }: SocketCodeHelperMessage) {
  yield put(setRunningNode())
  if (message) {
    const selectedNode = ((state: ApplicationState) => state.app.model.selectedNode)(yield select())
    if (selectedNode?.codeNode && selectedNode.codeNode.identifier === nodeId) {
      yield put(updateCodeNode({ ...selectedNode?.codeNode, definition: message, isCalc: false }))
    }
  }
}

function* processDownloadFile({ filePath }: SocketDownloadMessage) {
  if (filePath) {
    try {
      const filenameArr = filePath.split('/')
      const fileName = filenameArr[filenameArr.length - 1]

      const progressFn = (progressEvent: ProgressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        store.dispatch(
          setProgressBar({
            show: true,
            value: progress || 0,
            message: fileName
          })
        )
      }

      const params = { sources: [filePath] }
      const response: AxiosResponse = yield call(
        fileManagerApi.downloadFileOrFolder,
        params,
        progressFn
      )
      yield call(saveAs, response.data, fileName)
      yield put(setProgressBar({ show: false, value: 0 }))
    } catch (error: any) {
      yield put(enqueue(error))
      yield put(setProgressBar({ show: false, value: 0 }))
    }
  }
}

function* processUploadFiles({ uploadParams }: SocketUploadMessage) {
  if (uploadParams) {
    try {
      uploadParams.show = true
      yield put(setUploaderParams(uploadParams))
    } catch (error: any) {
      yield put(enqueue(error))
    }
  }
}

function* processInternalTests({ statusTests }: SocketMessageInternalTests) {
  if (statusTests) {
    yield put(internalTestsStatus(statusTests))
  }
}

function* processAppLockInfo({ appIsLocked }: SocketAppInfo) {
  yield put(setAppLockInfo(appIsLocked))
}

function* processRefreshAppNotifications() {
  yield put(setRefreshAppNotifications(true))
}

function* initializeWebSocketServer() {
  const instanceData = ((state: ApplicationState) => state.user.instanceData)(yield select())
  if (instanceData) {
    const { company_code: companyCode, id: instanceId } = instanceData
    const channel: Channel<ActionPattern> = yield call(createEventServer, companyCode, instanceId)
    while (true) {
      const { data, type }: { data: any; type?: SocketActionTypes } = yield take(channel)
      if (type) {
        yield put({ type })
      } else {
        switch (data.messageType) {
          // ws is asking to update model or node properties
          case MessageType.OPENING_APP:
          case MessageType.NODE_BUTTON_FINISH_PROCESSING:
          case MessageType.APP_UNSAVED_CHANGES:
          case MessageType.LIBRARIES_INSTALL_PROGRESS:
          case MessageType.NODE_INTERFACE_RESULT:
            yield fork(processWSModelActions, data)
            break

          // ws is asking to show debug info
          case MessageType.NODE_DEBUG_INFORMATION:
            yield fork(processWSModelDebug, data)
            break

          // ws is asking to send a notification
          case MessageType.STANDARD_MESSAGE:
          case MessageType.PROGRESS_BAR:
          case MessageType.KILLED_INSTANCE:
            yield fork(processWSNotificationActions, data)
            break

          // ws is asking to send instance resources info
          case MessageType.UPDATE_INSTANCE_RESOURCES:
            yield fork(processWSInstanceResources, data)
            break
          // ws is asking to send node feedback
          case MessageType.SEND_NODE_ERROR:
          case MessageType.SEND_NODE_CONSOLE:
            yield fork(processWSNodeFeedback, data)
            break
          case MessageType.SEND_INTERFACE_ERROR:
            yield fork(processWSInterfaceFeedback, data)
            break
          case MessageType.SEND_NODE_INTERFACE_ERROR:
            yield fork(processWSNodeInterfaceFeedback, data)
            break
          case MessageType.COMPONENTS_THAT_NEED_RENDERING:
          case MessageType.COMPONENT_RESULT:
          case MessageType.REFRESH_CURRENT_INTERFACE:
          case MessageType.FILTER_RESULT:
            yield fork(processWSInterfaceComponentResult, data)
            break
          case MessageType.PURGED_SESSION:
          case MessageType.PURGED_INSTANCE:
          case MessageType.ENGINE_DOWN:
            yield fork(processWSInstanceStatusActions, data)
            break
          case MessageType.ASSISTANT_BOT_RESPONSE:
            yield fork(processWSAssistantBot, data)
            break
          case MessageType.CODE_HELPER_RESPONSE:
            yield fork(processWSCodeHelper, data)
            break
          case MessageType.DOWNLOAD_FILE:
            yield fork(processDownloadFile, data)
            break
          case MessageType.UPLOAD_FILES:
            yield fork(processUploadFiles, data)
            break
          // ws internal tests
          case MessageType.INTERNAL_TESTS_STATUS:
            yield fork(processInternalTests, data)
            break
          case MessageType.APP_LOCK_INFO:
            yield fork(processAppLockInfo, data)
            break
          case MessageType.REFRESH_APP_NOTIFICATIONS:
            yield fork(processRefreshAppNotifications)
            break
        }
      }
    }
  }
}

export function* startStopServer() {
  yield race({
    task: call(initializeWebSocketServer),
    cancel: take(SocketActionTypes.STOP_SERVER)
  })
  ws?.close()
}

export function* stopServer() {
  yield ws?.close()
}

function* socketSaga() {
  yield all([
    takeLatest(SocketActionTypes.START_SERVER, startStopServer),
    takeLatest(SocketActionTypes.STOP_SERVER, stopServer)
  ])
}

export { socketSaga, ws }
