// Common //
import { Dispatch, ReactNode, RefObject, SetStateAction } from 'react'
import Handsontable from 'handsontable'
import { Figure } from 'react-plotly.js'
import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { ClassNameMap, CSSProperties, DefaultTheme } from '@mui/styles'
import { AutocompleteClassKey } from '@mui/material'
import {
  ChoiceOptionsAndIndexInfo,
  ChoiceParamsInfo,
  GetFormColumnsData,
  IndexFilterChoices,
  IndexTextFilterChoices,
  SelectorOptionsAndIndexInfo,
  SelectorParamsInfo,
  SetSelectorValueParams
} from '../../../common/types/model'
import { Context, InterfaceEvent, InterfaceEventType } from '../containers/types'
import { InterfaceIndexValuesData } from 'common/types/interface'
import { Settings } from 'handsontable/plugins/contextMenu'
import { HotTableClass } from '@handsontable/react'
import { NodeModel } from '@minoru/react-dnd-treeview'
import { CustomDiscreteSequenceColorPaletteValues } from 'common/types/application'
import { RangeType } from 'handsontable/common'

export type InterfaceComponent = {
  component_id: string
  node_id?: string
  error_code?: string
  error_detail?: string
  x?: number
  y?: number
  h?: number
  w?: number
  isLoading?: boolean
  has_description: boolean
}

export enum ComponentTypes {
  Core_Node = 'core.Node',
  Core_Index = 'core.Index',
  Core_Selector = 'core.Selector',
  Core_Chart = 'core.Chart',
  Core_PlotlyChart = 'core.PlotlyChart',
  Core_InputValue = 'core.InputValue',
  Core_Table = 'core.Table',
  Core_InputDataArray = 'core.InputDataArray',
  Core_InputDataFrame = 'core.InputDataFrame',
  Core_Html = 'core.Html',
  Core_Menu = 'core.Menu',
  Core_Empty = 'core.Empty',
  Core_Generic = 'core.Generic',
  Core_Indicator = 'core.Indicator',
  Core_Choice = 'core.Choice',
  Core_Button = 'core.Button',
  Core_Form = 'core.Form',
  Core_DashApp = 'core.DashApp',
  Core_InputData = 'core.InputData',
  Core_MatplotlibChart = 'core.MatplotlibChart',
  Core_Assistant = 'core.Assistant',
  Core_MyTasks = 'core.MyTasks',
  Core_ScheduledTasks = 'core.ScheduledTasks',
  Core_Notifications = 'core.Notifications',
  Core_DynamicHtml = 'core.DynamicHtml',
  Core_InputCube = 'core.InputCube',
  Core_Filter = 'core.Filter'
}

export type actionCallback = (action: 'save' | 'cancel') => void

export type SelectOption = {
  value: string
  label: string
}

type ComponentTitle = {
  custom_text: string
  enabled: boolean
  text: string
  show_units: boolean
  units_text: string
}

export interface ColumnsToShow {
  [key: string]: boolean
}

export type CommonProperties = {
  title: ComponentTitle
  code?: string
  custom_code?: boolean
  hide_header?: boolean
  hide_border?: boolean
  hide_maximize_button?: boolean
  hide_data_handling_icon?: boolean
  header_color?: string
  header_background_color?: string
  show_data_handling_area?: boolean
}

type PageSize = {
  rows: number
  columns: number
}

type PageInfoItem = {
  current_page: number
  from: number
  to: number
  total: number
}

type PageInfo = {
  columns: PageInfoItem
  rows: PageInfoItem
}
export enum PivotAggEnum {
  sum = 'sum',
  avg = 'avg',
  max = 'max',
  min = 'min',
  count = 'count',
  none = ''
}

export type Pivot = {
  agg: { [key: string]: PivotAggEnum }
  params: { [key: string]: string | string[] | boolean | undefined }
}

type HierarchyValues = {
  field: string
  title?: string
}

type UnlinkedIndexItem = {
  field: string
  title?: string
}

export enum ColumnDataType {
  number = 'number',
  date = 'date',
  calc = 'calc',
  calc_item = 'calc_item',
  other = 'other'
}

export type Hierarchy = {
  index_id?: string
  index_title?: string
  values: HierarchyValues[]
}

export interface ExtendedHierarchy extends Hierarchy {
  title?: string
}

export type ColumnDetailItem = {
  field: string
  title: string | undefined
  description: string | undefined
  data_type?: ColumnDataType
  selected_label: string | undefined
  selected_values: (string | number)[]
  hierarchy: Hierarchy
  system?: boolean
  source?: string
}

export interface DrilldownOptionsInfo {
  open: boolean
  componentId: string
  options: ColumnDetailItem[]
  drilldowns: DrilldownLevel[]
  indexes: { [key: string]: string }
  posX?: number
  posY?: number
  scrollX?: number
  scrollY?: number
}

export enum ColumnPivotItemType {
  base = 'base',
  advanced = 'advanced'
}
export enum ColumnPivotItemSource {
  index = 'index',
  measure = 'measure',
  all = 'all',
  values = 'values',
  boolean = 'boolean'
}

export enum PivotItemColumnType {
  dimension = 'dimension',
  measure = 'measure',
  any = 'any'
}

export type ColumnPivotItem = {
  code: string
  title: string
  multiselect: boolean
  source: ColumnPivotItemSource
  type: ColumnPivotItemType
  column_type: PivotItemColumnType
  values: string[]
}

type ColumnsDetailsPivot = {
  params: ColumnPivotItem[]
}

export interface ValueSelectorOption {
  value: string
  label: string
}

export interface SelectorItemProps {
  columnPivotItem: ColumnPivotItem
  component: PivoteableComponent
  eventCallback: (triggerData: InterfaceEvent) => void
}

type IndexDetailOptions = {
  show_pivot_icon: boolean
  show_filter_icon: boolean
}

export type ColumnsDetails = {
  columns: ColumnDetailItem[]
  pivot: ColumnsDetailsPivot
  options: IndexDetailOptions
  error: string
}

export interface ActiveTabComponent {
  [key: string]: GenericComponent
}

export interface ApplicationComponents {
  [key: string]: ActiveTabComponent
}

export interface IBaseComponent extends InterfaceComponent {
  component_type: ComponentTypes
  properties: CommonProperties
  result: unknown
  renderKey: string
}

export interface IMinBaseComponent {
  component_id: string
  component_type: ComponentTypes
  h?: number
  w?: number
  x?: number
  y?: number
  renderKey?: string
  properties: CommonProperties
}

export interface IRenderComponent extends Omit<IBaseComponent, 'properties'> {
  properties: TableProperties
}

type ValueFormatType = 'number' | 'percent' | 'date' | 'datetime'

export interface ValueFormatProperties {
  type: ValueFormatType
  thousands_separator?: boolean
  decimal_places?: number | null
  prefix?: string
  suffix?: string
  date_format?: string
  [key: string]: any
}
export interface ValueFormatPresets {
  [key: string]: ValueFormatProperties
}

export type GenericComponent =
  | IIndex
  | ITable
  | IChart
  | IHTML
  | IMenu
  | IInputValue
  | ISelector
  | IChoice
  | IEmpty
  | IIndicator
  | IBaseComponent
  | IGeneric
  | IButton
  | IDashApp
  | IMatplotlibChart
  | IAssistant
  | IMyTask
  | IScheduledTasks
  | INotifications
  | IDynamicHTML
  | IFilterComponent

type Orientation = 'horizontal' | 'vertical'

export interface ProgressBarProperties {
  max_value: number
  min_hue: number
  max_hue: number
  saturation: number
  lightness: number
  show_value: boolean
  [key: string]: any
}

export enum HeatmapType {
  total = 'total',
  row = 'row',
  column = 'column'
}

export enum HeatmapColor {
  red_green = 'red_green',
  red_green_alt = 'red_green_alt',
  green_red = 'green_red',
  green_red_alt = 'green_red_alt',
  red_white = 'red_white',
  white_red = 'white_red',
  white_green = 'white_green',
  green_white = 'green_white',
  green_blue = 'green_blue',
  blue_green = 'blue_green',
  red_blue = 'red_blue',
  blue_red = 'blue_red',
  custom = 'custom'
}

export interface HeatmapProperties {
  by: HeatmapType
  heatmap_color?: HeatmapColor
  hex_base_background_color?: string
  hex_text_color?: string
  exclude_nans?: boolean
  exclude_right_total?: boolean
  exclude_bottom_total?: boolean
  [key: string]: any
}

// Index //

export enum IndexFormats {
  Default = 'default',
  Small = 'small',
  Hidden = 'hidden',
  Range = 'range',
  Checkboxes = 'checkboxes',
  Select = 'select'
}

export interface IndexProperties extends CommonProperties {
  single_select: boolean
  format: IndexFormats
  orientation: Orientation
  applied_hierarchy?: string
}

export type IndexResult = {
  field_id: string
  title: string
  selected_values: Array<string | number>
  type: string
  hierarchy?: Hierarchy
  all_selected?: boolean
  index_values?: Array<string | number>
}

export interface IIndex extends InterfaceComponent {
  component_type: ComponentTypes.Core_Index
  properties: IndexProperties
  result: IndexResult
  renderKey?: string
}

export type GetInterfaceValuesMethod = (
  interfaceId: string,
  componentId: string,
  indexId: string,
  paginateResults: boolean,
  page?: number,
  filter?: IndexFilterChoices | null,
  text1?: string | null,
  page_size?: number
) => Promise<InterfaceIndexValuesData>

export type GetNodeInterfaceValuesMethod = (
  nodeId: string,
  componentId: string,
  indexId: string,
  paginateResults: boolean,
  page?: number,
  filter?: IndexFilterChoices | null,
  text1?: string | null,
  page_size?: number
) => Promise<InterfaceIndexValuesData>

export interface IIndexSubcomponent {
  interfaceNodeId: string
  componentId: string
  nodeId: string
  fieldId: string
  eventCallback: (triggerData: InterfaceEvent) => void
  singleSelect: boolean
  initSelValues: (string | number)[]
  getValuesMethod: GetInterfaceValuesMethod | GetNodeInterfaceValuesMethod
  initIndexValues: (string | number)[]
  context?: Context
}
export interface IIndexRange extends IIndexSubcomponent {
  component: IIndex
}
export interface IIndexBox extends IIndexSubcomponent {
  type: 'chips' | 'checkboxes'
  orientation: Orientation
  isSmall: boolean
}
export interface IIndexSelector extends IIndexSubcomponent {
  component: IIndex
  label?: string
  handleChangeCustom?: (value: string) => void
  disableTrigger?: boolean
}

export interface IIndexHidden {
  component: IIndex
  eventCallback: (triggerData: InterfaceEvent) => void
  editMode: boolean
  context?: Context
  initSelValues: (string | number)[]
}

export interface ISelectHidden {
  component: ISelectorComponent
  eventCallback: (triggerData: InterfaceEvent) => void
  editMode: boolean
  context?: Context
  initSelValues: (string | number)[]
  getOptionsAndIndexMethod: GetOptionsAndIndexMethod
  multiselect: boolean
  timeoutMs: number
  title: string
  initialIndexesAndValues: IndexesAndOptions
}
// Table //

interface TableColumns extends Handsontable.GridSettings {
  conditional_format?: ConditionalFormatProperties[]
}

export type DrilldownIndexes = {
  [key: string]: string
}

export interface DrilldownLevel {
  indexes: DrilldownIndexes
  drilldown_to: string
}

export enum HideEmptyDataOptions {
  Row = 'row',
  Column = 'column',
  Both = 'both',
  None = ''
}

export enum TableTotalPosition {
  End = 'end',
  Beginning = 'beginning'
}

export enum TableTotalType {
  Right = 'right',
  Bottom = 'bottom'
}

export enum TableStyleFilters {
  equal = 'equal',
  notequal = 'notequal',
  contains = 'contains',
  notcontains = 'notcontains',
  begin_with = 'begin_with',
  not_begin_with = 'not_begin_with',
  equal_to_node_values = 'equal_to_node_values',
  regular_expression = 'regular_expression'
}

export enum TableStyleConditionType {
  index = 'index',
  column = 'column',
  row = 'row'
}

export enum TableStyleConditionApplyTo {
  all = 'all',
  values = 'values',
  labels = 'labels'
}

export interface TableStyleCondition {
  condition_type: TableStyleConditionType
  dimension_name: string
  values: string[]
  filter_type: TableStyleFilters
  apply_to: TableStyleConditionApplyTo
}

export interface WizardTableStyleCondition extends TableStyleCondition {
  id: number
}

export interface TableCellProperties {
  value_format: ValueFormatProperties
  style: CSSProperties
  conditional_format?: ConditionalFormatProperties[]
  progress_bar?: ProgressBarProperties
  heatmap?: HeatmapProperties
  readOnly?: boolean
}

export interface TableCustomStyle {
  conditions: TableStyleCondition[]
  cell_properties: TableCellProperties
}

export interface TableStyle {
  all?: TableCellProperties
  custom?: TableCustomStyle[]
}

export enum CalculatedFieldType {
  expression = 'expression',
  function = 'function'
}

export interface CalculatedField {
  name: string
  calc_type: CalculatedFieldType
  value: string
}

export enum CalculatedItemType {
  custom = 'custom',
  subtotal = 'subtotal'
}

export interface CalculatedItem {
  dimension: string
  name: string
  expression: string
  item_type?: CalculatedItemType
}

export enum ScenarioCalculationType {
  Values = 'Values',
  Difference = 'Difference',
  PercentageDifference = 'PercentageDifference'
}

export interface ScenarioCalculation {
  calculation_type: ScenarioCalculationType
  end_text: string
}

export interface ScenariosProperties {
  compare: boolean
  calculations: ScenarioCalculation[]
}

export interface TableProperties extends CommonProperties {
  table_properties: Handsontable.GridSettings | undefined
  columns: { string: TableColumns }
  renderers: { [key: string]: string }
  page_size: PageSize
  sort: { [key: string]: string }
  pivot: Pivot
  drilldowns?: DrilldownLevel[]
  right_total: boolean
  right_total_position: TableTotalPosition | null
  bottom_total: boolean
  bottom_total_position: TableTotalPosition | null
  hide_empty_data: HideEmptyDataOptions
  styles: TableStyle
  calculated_fields: CalculatedField[]
  calculated_items: CalculatedItem[]
  scenarios: ScenariosProperties
  show_column_filter_icon: boolean
  hide_footer: boolean
  unlinked_indexes: UnlinkedIndexItem[]
}

interface NumericFormat {
  pattern: NumbroNumericPattern
}

export interface TableDataCellProperties {
  style?: CSSProperties
  conditional_format?: ConditionalFormatProperties[]
  progress_bar?: ProgressBarProperties
  numericFormat?: NumericFormat
  prefix?: string
  suffix?: string
  dateRendererFormat?: string
  renderer?: string
  readOnly?: boolean
  [key: string]: any
}

export interface TableDataCellsProperties {
  apply_to: TableStyleConditionApplyTo
  cell_properties: TableDataCellProperties
}

type TableData = {
  headers: any
  columns: Handsontable.ColumnSettings[]
  values: Array<{ string: any }>
  dynamic_properties: Handsontable.GridSettings | undefined
  column_keys: Array<Array<any>>
  edit_mode: boolean
  allow_add_rows: boolean
  allow_remove_rows: boolean
  page_info: PageInfo
  blocked_rows: number[]
  applied_drilldown_indexes: string[]
  drilldowneable_indexes: string[]
  cells_properties: { [key: string]: TableDataCellsProperties[] }
  is_pivot: boolean
  hidden_columns: number[]
}

export interface TableFilters {
  [key: string]: string[]
}

export type TableResult = {
  data: TableData
  columns_details: ColumnsDetails
  filters: TableFilters
  has_scenarios: boolean
}

export interface ITable extends InterfaceComponent {
  component_type:
    | ComponentTypes.Core_Table
    | ComponentTypes.Core_InputDataArray
    | ComponentTypes.Core_InputCube
    | ComponentTypes.Core_InputDataFrame
    | ComponentTypes.Core_Form
  properties: TableProperties
  result: TableResult
}

export type TableChange = {
  keys: { [key: string]: any }
  old_value?: any
  new_value: any
}

export type TableChangePageData = {
  rows_from: number
  columns_from: number
}

export type FormChange = {
  row_id: number | null
  column_name: string
  value: any
  hot_row_id: number
}

// Chart //

type ListenEventChart = {
  [key: string]: {
    event: 'HoverEvent' | 'SelectedEvent'
    component_id: string
  }
}

export type ChartLayoutFinalProp = {
  [key: string]: string | number | boolean
}
export type ChartLayoutLegend = {
  orientation?: 'h' | 'v'
  xanchor?: 'auto' | 'left' | 'center' | 'right'
  x?: number
  yanchor?: 'auto' | 'top' | 'middle' | 'bottom'
  y?: number
  [key: string]: any
}
export type ChartLayoutMargins = {
  t?: number
  r?: number
  b?: number
  l?: number
  [key: string]: any
}
export type ChartLayout = {
  [key: string]:
    | string
    | number
    | boolean
    | ChartLayoutFinalProp
    | ChartLayoutLegend
    | ChartLayoutMargins
}
export type ChartPropertiesProps = {
  [key: string]: string | number | undefined
}

export interface LayoutComponentProps {
  component: IChart
  triggerUpdateProperties: (
    component_id: string,
    propsToUpdate: {
      [key: string]: any
    }
  ) => void
  interfaceId: string
  context: Context
}

export type LayoutSelectOption = {
  code: string
  title: string
}

export enum ChartUniformTextMode {
  none = 'none',
  false = 'false',
  hide = 'hide',
  show = 'show'
}
export enum ChartTextPosition {
  none = 'none',
  inside = 'inside',
  outside = 'outside'
}
export type ChartLabels = {
  field?: string
  text_position?: ChartTextPosition
  min_size?: number | ''
  mode?: ChartUniformTextMode
}

export type ChartYAxisRange = {
  min?: number
  max?: number
}

export interface ChartStyleProperties {
  value_format: ValueFormatProperties
  value_format_secondary_axis?: ValueFormatProperties
  color_discrete_sequence?: string
  color_continuous_scale?: string
  custom_series_colors_name?: string
  labels?: ChartLabels
  labels_secondary_axis?: ChartLabels
  y_axis_range?: ChartYAxisRange
  combined_primary_axis_color?: string
  combined_secondary_axis_color?: string
  show_y_axis_title?: boolean
  show_x_axis_title?: boolean
}

export enum ValueFormatCodes {
  value_format = 'value_format',
  value_format_secondary_axis = 'value_format_secondary_axis'
}

export enum LabelsCodes {
  labels = 'labels',
  labels_secondary_axis = 'labels_secondary_axis'
}

export interface ChartStyle {
  all?: ChartStyleProperties
  custom?: any[]
}

export enum ChartMainType {
  column = 'column',
  bar = 'bar',
  line = 'line',
  pie = 'pie',
  area = 'area',
  scatter = 'scatter',
  combined = 'combined',
  others = 'others'
}

export enum PlotlyImportSource {
  px = 'px',
  go = 'go',
  combined = 'combined'
}

export interface ChartType {
  main_type: ChartMainType
  subtype: string
  plotly_import_source: PlotlyImportSource
  plotly_code: string
  chart_params: { [key: string]: any }
  chart_layout: { [key: string]: any }
}

export type ChartTypeProperties = {
  plotlyImportSource: PlotlyImportSource
  code: string
  label: string
  chartProperties: { [key: string]: any }
  layoutProperties: { [key: string]: any }
  chartUpdateTraces: { [key: string]: any }
}

export type ChartTypeGroupProperties = {
  [key: string]: ChartTypeProperties
}

export interface ChartProperties extends CommonProperties {
  chart_type: ChartType
  chart_properties: ChartPropertiesProps
  chart_layout: ChartLayout
  selected_event: boolean
  hover_event: boolean
  hover_select_targets: string[]
  relayout_event: boolean
  drilldown_event: boolean
  listen: ListenEventChart
  pivot: Pivot
  page_size: PageSize
  drilldowns: DrilldownLevel[]
  available_drilldown_indexes: string[]
  supported_types_for_drilldown: string[]
  hide_empty_data: boolean
  styles: ChartStyle
  calculated_fields: CalculatedField[]
  calculated_items: CalculatedItem[]
  scenarios: ScenariosProperties
  sort: { string: string }
  unlinked_indexes: UnlinkedIndexItem[]
}

export enum ColorScaleSource {
  plotly = 'plotly',
  custom = 'custom',
  pyplan = 'pyplan'
}

export interface ColorScale {
  code: string
  title: string
  values: CustomDiscreteSequenceColorPaletteValues[]
  source: ColorScaleSource
}

export interface ChartExtraProperties {
  is_color_property_numeric: boolean
  color_scales: ColorScale[]
  must_aggregate: boolean
}

export type ChartResult = {
  figure: Figure
  columns_details: ColumnsDetails
  filters: TableFilters
  extra_properties: ChartExtraProperties
  has_scenarios: boolean
}

export interface IChart extends InterfaceComponent {
  component_type: ComponentTypes.Core_Chart | ComponentTypes.Core_PlotlyChart
  properties: ChartProperties
  result: ChartResult
}

// HTML //

interface HTMLProperties extends CommonProperties {
  html: string
}
type HTMLResult = {
  html: string
}

export interface IHTML extends InterfaceComponent {
  component_type: ComponentTypes.Core_Html
  properties: HTMLProperties
  result: HTMLResult
}

// Dynamic HTML //

export enum DynamicHtmlActionTypes {
  OPEN_INTERFACE = 'open_interface',
  GO_TO_SECTION = 'go_to_section',
  RUN_APP_NODE = 'run_app_node',
  ATTRIBUTE_FROM_NODE = 'attribute_from_node',
  CHILDREN_FROM_NODE = 'children_from_node',
  OPEN_ASSISTANT = 'open_assistant'
}

interface ActionTypeValue {
  interfaceName?: string
  interfaceId?: string
  openInNewTab?: boolean
  assistantId?: string
  section?: string
  nodeId?: string
  nodeTitle?: string
  nodePreview?: string
}

export interface DynamicHtmlActionTableData {
  attribute: string
  attributeValue: string
  actionType: DynamicHtmlActionTypes
  actionTypeValue: ActionTypeValue
  targetAttribute?: string
}

interface DynamicHTMLProperties extends CommonProperties {
  html: string
  actionTableData?: DynamicHtmlActionTableData[]
}

type DynamicHTMLResult = {
  html: string
  actionTableData?: DynamicHtmlActionTableData[]
  iframe_id?: string
}

export interface IDynamicHTML extends InterfaceComponent {
  component_type: ComponentTypes.Core_DynamicHtml
  properties: DynamicHTMLProperties
  result: DynamicHTMLResult
}

export type FilterComponent = ISelector | IChoice | IIndex

export interface FilterComponentResult {
  filter_components: FilterComponent[]
}
export interface FilterComponentProperties extends CommonProperties {
  filter_components: FilterComponent[]
  position_sticky: boolean
}
export interface IFilterComponent extends InterfaceComponent {
  component_type: ComponentTypes.Core_Filter
  properties: FilterComponentProperties
  result: FilterComponentResult
}

export interface FilterComponentDialogProps {
  component: IFilterComponent
  interfaceId: string
  openFilterComponentDialog: boolean
  handleCloseFilterComponentDialog: (revert?: boolean) => void
  editMode: boolean
  filterToEdit?: string
}

export enum GoToSectionValues {
  INTERFACES = '/interfaces',
  TASKS = '/tasks',
  CODE = '/code',
  HOME = '/',
  APPS = '/apps',
  FILES = '/files',
  SCENARIO_MANAGER = '/scenarios/scenario-manager',
  SCENARIO_TEMPLATES = '/scenarios/scenario-templates',
  PROCESS = '/process',
  VERSIONS = '/versions',
  APP_CONSOLIDATION = '/app-consolidation',
  INTERFACE_LINKS = '/interface-links',
  API_ENDPOINTS = '/api-endpoints',
  INSTANCES = '/instances',
  LOGS = '/logs',
  GENERAL_SETTINGS = '/general-settings',
  USERS = '/users',
  TEAMS = '/teams',
  DEPARTMENTS = '/departments',
  ROLES = '/roles',
  PERMISSIONS_BY_ROLE = '/permissions-by-role',
  COMPANIES = '/companies',
  ASSISTANT_BOTS = '/assistant-bots'
}

export interface NavigateToPathsTypes {
  value: GoToSectionValues
  label: string
}

export enum TableAttributes {
  Attribute = 'attribute',
  Value = 'value',
  TargetAttribute = 'targetAttribute'
}

// Button //

export enum ButtonStyleCheckboxProperties {
  multiline = 'multiline',
  uppercase = 'uppercase'
}

export interface ButtonStyleProperties {
  style: CSSProperties
  multiline: boolean
  uppercase: boolean
}

export interface ButtonStyle {
  all?: ButtonStyleProperties
  custom?: any[]
}

export interface ButtonProperties extends CommonProperties {
  styles: ButtonStyle
  show_confirmation_dialog: boolean
  confirmation_dialog_message?: string
}

// Menu //

export enum MenuFormat {
  accordion = 'accordion',
  boxes = 'boxes'
}

export enum MenuActionType {
  INTERFACE = 'interface',
  NAVIGATE = 'navigate'
}

export type MenuAction = {
  id: string | number
  pos: number
  text?: string
  action_type: MenuActionType
  icon?: IconDefinition
  reference?: string
  target?: string
  subtitle?: string
  disabled?: boolean
}

export type MenuItem = {
  icon?: IconDefinition
  actions?: MenuAction[]
  open_at_startup?: boolean
  subtitle?: string
}

export type Menu = {
  items: NodeModel<MenuItem>[]
}

type MenuResult = {
  menu: Menu
}
export type AlignmentItems = 'center' | 'right' | 'left'
export interface MenuProperties extends CommonProperties {
  format: MenuFormat
  menu: Menu
  items_per_row: number
  align_items: AlignmentItems
  auto_open_single_action?: boolean
  background_color: string | null
  icon_color: string | null
  hover_color: string | null
  text_color: string | null
}

export interface IMenu extends InterfaceComponent {
  component_type: ComponentTypes.Core_Menu
  properties: MenuProperties
  result: MenuResult
}

// Selector //

export enum SelectorFormats {
  Default = 'default',
  Select = 'select',
  Radio = 'radio',
  Slider = 'slider',
  SelectedChips = 'selected_chips',
  ClickableChips = 'clickable_chips',
  Hidden = 'hidden'
}

export interface SelectorProperties extends CommonProperties {
  format: SelectorFormats | undefined
  orientation?: Orientation
  page_size?: number
}

export type SelectorItem = {
  value: string
  index: number
}

export type SelectorIndexesAndOptions = {
  [key: number]: string
}

export type SelectorAndIndexes = {
  value: string
  index: number
}

export type SelectorResult = {
  selected_indexes: number[]
  selected_values: string[]
  multiselect: boolean
  include_all: boolean
  all_selected: boolean
  save_selected_labels: boolean
  options_and_indexes?: SelectorAndIndexes[]
}

export interface ISelector extends InterfaceComponent {
  component_type: ComponentTypes.Core_Selector
  properties: SelectorProperties
  result: SelectorResult
  renderKey?: string
}

// Choice //

interface ChoiceProperties extends CommonProperties {
  format: 'select' | 'radio' | 'slider' | undefined
  orientation?: Orientation
  page_size?: number
}

export type ChoiceItem = {
  value: string | number
  text: string | number
}

export type ChoiceIndexesAndOptions = {
  [key: number]: string
}

type ChoiceResult = {
  selected_indexes: number[]
  selected_values: string[]
  multiselect: boolean
  include_all: boolean
  all_selected: boolean
  save_selected_labels: boolean
}

export interface IChoice extends InterfaceComponent {
  component_type: ComponentTypes.Core_Choice
  properties: ChoiceProperties
  result: ChoiceResult
  renderKey?: string
}

// InputValue //

export interface InputValueResult {
  value: string | number
  is_editable: boolean
  format: IndicatorFormat
}

export interface InputValueStyleProperties {
  value_format: ValueFormatProperties
  conditional_format?: ConditionalFormatProperties[]
}

export interface InputValueStyle {
  all?: InputValueStyleProperties
  custom?: any[]
}
export enum InputValueDataType {
  Float = 'Float',
  Integer = 'Integer',
  String = 'String',
  Any = 'Any'
}
export enum InputValueRule {
  Between = 'Between',
  NotBetween = 'Not between',
  EqualTo = 'Equal to',
  NotEqualTo = 'Not equal to',
  GreaterThan = 'Greater than',
  LessThan = 'Less than',
  GreaterThanOrEqualTo = 'Greater than or equal to',
  LessThanOrEqualTo = 'Less than or equal to',
  TextLength = 'Text length',
  Any = 'Any'
}
interface InputValueValidationProperties {
  data_type: InputValueDataType
  rule: InputValueRule
  rule_value1: number
  rule_value2: number
  error_message: string | null
}
interface InputValueProperties extends CommonProperties {
  styles: InputValueStyle
  validation_properties: InputValueValidationProperties
}
export interface IInputValue extends InterfaceComponent {
  component_type: ComponentTypes.Core_InputValue
  properties: InputValueProperties
  result: InputValueResult
}

export interface InputScalarComponentProps {
  nodeId: string
  component: IInputValue
  initialValue: string
  eventCallback: (triggerData: InterfaceEvent) => void
  classes?: Partial<ClassNameMap<'root'>>
  selected?: boolean
  disableInteractivy?: boolean
  is_editable: boolean
  unformattedValue: number | string
}

// Empty //

export interface IEmpty extends InterfaceComponent {
  component_type: ComponentTypes.Core_Empty
  properties: CommonProperties
  result: void
}

// Selector component

export type ISelectorComponent = ISelector | IChoice

export type IndexesAndOptions = SelectorIndexesAndOptions | ChoiceIndexesAndOptions

export type ParamsInfo = SelectorParamsInfo | ChoiceParamsInfo

export type OptionsAndIndexesInfo = SelectorOptionsAndIndexInfo | ChoiceOptionsAndIndexInfo

export type GetParamsMethod = (nodeId: string) => Promise<SelectorParamsInfo | ChoiceParamsInfo>

export type GetOptionsAndIndexParams = {
  nodeId: string
  page_size: number
  text_filter?: string | null
  url?: string | null
}

export type GetOptionsAndIndexMethod = (
  nodeId: string,
  page_size: number,
  text_filter?: string | null,
  url?: string | null,
  filter_type?: IndexTextFilterChoices | null
) => Promise<SelectorOptionsAndIndexInfo | ChoiceOptionsAndIndexInfo>

// SelectWithAutocomplete

export interface SelectWithAutocompleteProps {
  nodeId: string
  componentId: string
  pageSize: number
  timeoutMs: number
  initialIndexesAndValues: IndexesAndOptions
  multiselect: boolean
  orientation?: Orientation
  getOptionsAndIndexMethod: GetOptionsAndIndexMethod
  triggerCallback: (T: SetSelectorValueParams) => Promise<void> | void
  label?: string | ReactNode
  classes?: Partial<ClassNameMap<AutocompleteClassKey>>
  limitTags?: number
  disabled?: boolean
  isMultiSelectFromSelectorNode?: boolean
  saveSelectedLabels?: boolean
  isCalc?: boolean
}

// SelectWithCheckboxes

export interface SelectWithCheckboxesProps {
  nodeId: string
  componentId: string
  pageSize: number
  initialIndexesAndValues: IndexesAndOptions
  multiselect: boolean
  allSelected: boolean
  orientation?: Orientation
  getOptionsAndIndexMethod: GetOptionsAndIndexMethod
  triggerCallback: (T: SetSelectorValueParams) => Promise<void> | void
  eventCallback: (triggerData: InterfaceEvent) => void
  saveSelectedLabels?: boolean
}

// SelectWithSlider

export interface SelectWithSliderProps {
  nodeId: string
  componentId: string
  pageSize: number
  timeoutMs: number
  initialIndexesAndValues: IndexesAndOptions
  multiselect: boolean
  orientation?: Orientation
  getOptionsAndIndexMethod: GetOptionsAndIndexMethod
  triggerCallback: (T: SetSelectorValueParams) => Promise<void> | void
  saveSelectedLabels?: boolean
}

// SelectWithSelectedChips

export interface SelectWithSelectedValuesProps {
  nodeId: string
  componentId: string
  title: string
  timeoutMs: number
  multiselect: boolean
  initialIndexesAndValues: IndexesAndOptions
  getOptionsAndIndexMethod: GetOptionsAndIndexMethod
  eventCallback: (triggerData: InterfaceEvent) => void
  saveSelectedLabels?: boolean
  allSelected?: boolean
}

// SelectWithClickableChips

export interface SelectWithClickableChipsProps {
  nodeId: string
  componentId: string
  pageSize: number
  multiselect: boolean
  initialIndexesAndValues: IndexesAndOptions
  getOptionsAndIndexMethod: GetOptionsAndIndexMethod
  eventCallback: (triggerData: InterfaceEvent) => void
  allSelected?: boolean
  saveSelectedLabels?: boolean
  orientation: Orientation
}

// Indicator //

export interface Icon {
  prefix: IconPrefix
  name: IconName
}

export interface Shape {
  icon: Icon
  color?: string
  show_icon_only?: boolean
}

export interface ConditionalFormatProperties {
  condition_type: ConditionalFormatType
  value1: any
  value2?: any
  style: CSSProperties
  shape?: Shape
}

export enum ConditionalFormatType {
  Range = 'range',
  EqualTo = 'equal_to',
  NotEqualTo = 'not_equal_to',
  GreaterThan = 'greater_than',
  GreaterThanOrEqualTo = 'greater_than_or_equal_to',
  LessThan = 'less_than',
  LessThanOrEqualTo = 'less_than_or_equal_to'
}

export interface IndicatorStyleProperties {
  value_format: ValueFormatProperties
  style: CSSProperties
  conditional_format?: ConditionalFormatProperties[]
}

export interface IndicatorStyle {
  all?: IndicatorStyleProperties
  custom?: any[]
}

export interface IndicatorProperties extends CommonProperties {
  pivot: Pivot
  shape?: Shape
  styles: IndicatorStyle
  unlinked_indexes: UnlinkedIndexItem[]
}

interface NumbroNumericPattern {
  thousandSeparated?: boolean
  mantissa?: number
  output?: 'percent' | 'byte' | 'ordinal' | 'time'
  forceSign?: boolean
  optionalMantissa?: boolean
  trimMantissa?: boolean
  spaceSeparated?: boolean
  negative?: 'parenthesis'
  average?: boolean
  totalLength?: number
}

interface IndicatorFormat {
  type?: ValueFormatType
  pattern?: NumbroNumericPattern
  prefix?: string
  suffix?: string
  date_format?: string
}

export interface IndicatorResult {
  value: string | number
  columns_details: ColumnsDetails
  filters: TableFilters
  format: IndicatorFormat
}

export interface IIndicator extends InterfaceComponent {
  component_type: ComponentTypes.Core_Indicator
  properties: IndicatorProperties
  result: IndicatorResult
}

// Generic

type GenericType =
  | 'string'
  | 'html'
  | 'url'
  | 'number'
  | 'list'
  | 'tuple'
  | 'set'
  | 'dict'
  | 'other'

type GenericValue = string | number | any[] | object

interface GenericResult {
  type: GenericType
  value: GenericValue
}

export interface IGeneric extends InterfaceComponent {
  component_type: ComponentTypes.Core_Generic
  properties: CommonProperties
  result: GenericResult
}
interface ButtonResult {
  node_id: string
  title: string
}

export interface IButton extends InterfaceComponent {
  component_type: ComponentTypes.Core_Button
  properties: ButtonProperties
  result: ButtonResult
}

export type EveryComponentProperty =
  | IndexProperties
  | TableProperties
  | ChartProperties
  | HTMLProperties
  | MenuProperties
  | SelectorProperties
  | ChoiceProperties
  | IndicatorProperties
  | ButtonProperties
  | CommonProperties

export interface ComponentToCreate {
  component: ComponentTypes
  position: { x: number; y: number; w?: number; h?: number }
}

// Form

interface FormData extends TableData {
  has_unconfirmed_changes: boolean
  must_scroll_down: boolean
}

export interface FormResult extends TableResult {
  data: FormData
  columns_details: ColumnsDetails
  filters: TableFilters
  has_write_permissions?: boolean
}

export interface FormProperties extends TableProperties {
  hide_id_column: boolean
  n_rows_to_add: number
  confirm_on_change?: boolean
  invalidate_outputs_on_change?: boolean
  invalidate_outputs_on_confirm?: boolean
  update_calc_columns_on_change?: boolean
  update_calc_columns_on_confirm?: boolean
  show_select_columns_icon?: boolean
  selected_column_fields?: string[]
  show_edit_excel_icon?: boolean
}

export interface IForm extends InterfaceComponent {
  component_type: ComponentTypes.Core_Form
  properties: FormProperties
  result: FormResult
}

export type GetFormColumnsParams = {
  nodeId: string
  page_size: number
  text_filter?: string | null
  url?: string | null
}

export type GetNodeFormColumnsMethod = (
  nodeId: string,
  componentId: string,
  paginateResults: boolean,
  page?: number,
  filter?: IndexTextFilterChoices | null,
  text1?: string | null,
  page_size?: number
) => Promise<GetFormColumnsData>

export type GetInterfaceFormColumnsMethod = (
  interfaceId: string,
  componentId: string,
  paginateResults: boolean,
  page?: number,
  filter?: IndexTextFilterChoices | null,
  text1?: string | null,
  page_size?: number
) => Promise<GetFormColumnsData>

export interface IInputNodeToolbar {
  componentId: string
  interfaceOrNodeId: string
  allowAddRows: boolean
  initialRowsToAdd: number
  showConfirmCancelButtons: boolean
  hasUnconfirmedChanges: boolean
  eventCallback: (triggerData: InterfaceEvent) => void
  setHasUnconfirmedChanges: React.Dispatch<React.SetStateAction<boolean>>
  confirmChangesEvent: InterfaceEventType
  cancelChangesEvent: InterfaceEventType
  addRowEvent?: InterfaceEventType
  changeRowsToAddEvent?: InterfaceEventType
  context?: Context
  disableConfirmButton?: boolean
  showSelectColumnsButton?: boolean
  getFormColumnsMethod?: GetNodeFormColumnsMethod | GetInterfaceFormColumnsMethod
  formProperties?: FormProperties
  nodeId?: string
  showEditExcelButton?: boolean
}

export enum InputNodeVariant {
  FORM = 'form',
  INPUT_CUBE = 'inputCube'
}

// InputCube

interface InputCubeData extends TableData {
  has_unconfirmed_changes: boolean
}

export interface InputCubeResult extends TableResult {
  data: InputCubeData
  columns_details: ColumnsDetails
  filters: TableFilters
  has_write_permissions?: boolean
}

export interface InputCubeProperties extends TableProperties {
  confirm_on_change?: boolean
  invalidate_outputs_on_change?: boolean
  invalidate_outputs_on_confirm?: boolean
  update_calc_columns_on_change?: boolean
}

export interface IInputCube extends InterfaceComponent {
  component_type: ComponentTypes.Core_InputCube
  properties: InputCubeProperties
  result: InputCubeResult
}

// Base Table

export interface BaseTableProps {
  component: ITable
  eventCallback: (triggerData: InterfaceEvent) => void
  editMode: boolean
  tableRef: RefObject<HotTableClass>
  context?: Context
  interfaceId?: string
  widgetResultDimensions?: { width: number; height: number }
  columns?: Handsontable.ColumnSettings[]
  hotContextMenu?: Settings
  afterChange?:
    | ((changes: Handsontable.CellChange[] | null, source: Handsontable.ChangeSource) => void)
    | undefined
  afterCreateRow?:
    | ((index: number, amount: number, source?: Handsontable.ChangeSource | undefined) => void)
    | undefined
  beforeRemoveRow?:
    | ((
        index: number,
        amount: number,
        physicalColumns: number[],
        source?: Handsontable.ChangeSource | undefined
      ) => void)
    | undefined
  afterRemoveRow?:
    | ((
        index: number,
        amount: number,
        physicalRows: number[],
        source?: Handsontable.ChangeSource | undefined
      ) => void)
    | undefined
  extraToolbar?: JSX.Element
  afterPaste?: ((data: any[][], coords: RangeType[]) => void) | undefined
  showPivotToolbar?: boolean
  updateShowPivotToolbar?: () => void
}

// DashApp

interface DashAppResult {
  link: string
}

export interface IDashApp extends InterfaceComponent {
  component_type: ComponentTypes.Core_DashApp
  properties: CommonProperties
  result: DashAppResult
}

export type PivoteableComponent = IChart | IIndicator | ITable

// PlotlyChart

export interface IPlotlyChart extends InterfaceComponent {
  component_type: ComponentTypes.Core_PlotlyChart
  properties: ChartProperties
  result: ChartResult
}

// MatplotlibChart

export interface IMatplotlibChart extends InterfaceComponent {
  component_type: ComponentTypes.Core_MatplotlibChart
  properties: CommonProperties
  result: HTMLResult
}

// Assistant

export interface IAssistant extends InterfaceComponent {
  component_type: ComponentTypes.Core_Assistant
  properties: CommonProperties
  result: undefined
}
// My Task

export interface MyTasksProperties extends CommonProperties {
  columns_to_show: ColumnsToShow
}

export interface IMyTask extends InterfaceComponent {
  component_type: ComponentTypes.Core_MyTasks
  properties: MyTasksProperties
  result: undefined
}

export interface INotifications extends InterfaceComponent {
  component_type: ComponentTypes.Core_Notifications
  properties: CommonProperties
  result: undefined
}

// Schedule tasks
export interface IScheduledTasks extends InterfaceComponent {
  component_type: ComponentTypes.Core_ScheduledTasks
  properties: MyTasksProperties
  result: undefined
}

// Export

export interface ExportComponentDialogProps {
  open: boolean
  setOpenExport: Dispatch<SetStateAction<boolean>>
  nodeId: string
  interfaceId: string
  componentInterfaceId: string
}

export enum ExportOptions {
  Full = 'Full',
  Current = 'Current'
}

export enum FileFormat {
  XLSX = 'xlsx',
  CSV = 'csv',
  XLS = 'xls'
}

export enum ColumnSeparator {
  Comma = ',',
  Tab = 'tab',
  Semicolon = ';'
}

export enum NumberFormat {
  TSCDSP = 'TSCDSP-123.45',
  TSPDSC = 'TSPDSC-123,45'
}

export enum FileType {
  CSV = 'text/csv',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip'
}

export interface ExportData {
  columnFormat: string
  compressed: string
  fileFormat: string
  nodeId: string
  exclude: boolean
  numberFormat: string
  interfaceId: string
  exportType: string
  componentId?: string
}

export const getDefaultBackgroundAndColor = (
  theme: DefaultTheme,
  component: GenericComponent,
  context: Context,
  isItemHeader?: boolean
): { color: string; background: string } => {
  let colorStyles = { color: '', background: '' }
  const COMPONENT_TYPES_WITH_CUSTOM_ITEMHEADER = [
    ComponentTypes.Core_Chart,
    ComponentTypes.Core_PlotlyChart,
    ComponentTypes.Core_Table,
    ComponentTypes.Core_InputDataArray,
    ComponentTypes.Core_InputCube,
    ComponentTypes.Core_InputDataFrame,
    ComponentTypes.Core_DashApp,
    ComponentTypes.Core_ScheduledTasks,
    ComponentTypes.Core_MyTasks,
    ComponentTypes.Core_MatplotlibChart,
    ComponentTypes.Core_Form,
    ComponentTypes.Core_Assistant,
    ComponentTypes.Core_Notifications
  ]
  const fromInterface = context === Context.Interfaces

  if (component?.properties?.header_color && isItemHeader) {
    colorStyles.color = component.properties.header_color
  } else {
    if (
      component &&
      component.component_type &&
      COMPONENT_TYPES_WITH_CUSTOM_ITEMHEADER.includes(component.component_type)
    ) {
      colorStyles.color = theme.palette.defaultTableHeaderColor.main
    } else if (fromInterface) {
      colorStyles.color = theme.palette.defaultHeaderColor.main
    }
  }
  if (component?.properties?.header_background_color && isItemHeader) {
    colorStyles.background = component.properties.header_background_color
  } else {
    if (
      component &&
      component.component_type &&
      COMPONENT_TYPES_WITH_CUSTOM_ITEMHEADER.includes(component.component_type)
    ) {
      colorStyles.background = theme.palette.defaultTableHeaderBackgroundColor.main
    } else if (fromInterface) {
      colorStyles.background = theme.palette.defaultHeaderBackgroundColor.main
    }
  }

  return colorStyles
}
export interface SelectorOptionsMax {
  componentId: string
  results: IndexesAndOptions
}
