import { Reducer } from 'redux'
import { UserState, UserActionTypes } from './types'

export const initialState: UserState = {
  authData: undefined,
  instanceData: undefined,
  selectedCompanyId: undefined
}

const reducer: Reducer<UserState> = (
  state: UserState = initialState,
  action: { type: UserActionTypes; payload?: any }
) => {
  switch (action.type) {
    case UserActionTypes.SET_USER_AUTH_DATA: {
      return {
        ...state,
        ...{ authData: action.payload }
      }
    }
    case UserActionTypes.SET_USER_INSTANCE_DATA: {
      return {
        ...state,
        ...{ instanceData: action.payload }
      }
    }
    case UserActionTypes.RESET_APP_USER_INFO: {
      return {
        ...state,
        ...{ instanceData: { ...state.instanceData, applicationInfo: {} } }
      }
    }
    case UserActionTypes.SET_SELECTED_COMPANY_ID: {
      return {
        ...state,
        ...{ selectedCompanyId: action.payload }
      }
    }
    case UserActionTypes.SET_LICENSE_DATA: {
      return {
        ...state,
        ...{ licenseData: action.payload }
      }
    }
    case UserActionTypes.SET_INVALID_AUTH_ERROR: {
      return {
        ...state,
        ...{ authErrorMsg: action.payload }
      }
    }
    case UserActionTypes.SET_CODE_LAYOUTS: {
      return {
        ...state,
        ...{ code: { ...state.code, layouts: action.payload } }
      }
    }
    case UserActionTypes.SET_SELECTED_CODE_LAYOUT: {
      return {
        ...state,
        ...{ code: { ...state.code, selectedLayout: action.payload.codeLayoutId } }
      }
    }
    case UserActionTypes.TOGGLE_RESULT_TOOLBAR: {
      return {
        ...state,
        ...{ code: { ...state.code, showResultToolbar: !(state.code?.showResultToolbar || false) } }
      }
    }
    case UserActionTypes.SET_CODE_DIVIDER: {
      return {
        ...state,
        ...{ code: { ...state.code, ...action.payload } }
      }
    }
    case UserActionTypes.SET_CODE_EXPANDED: {
      return {
        ...state,
        ...{ code: { ...state.code, codeFeedbackExpanded: action.payload } }
      }
    }
    case UserActionTypes.SET_WIDGET_EXPANDED: {
      return {
        ...state,
        ...{
          code: {
            ...state.code,
            widgetExpanded: action.payload
          }
        }
      }
    }
    case UserActionTypes.SET_LAST_PATH: {
      return {
        ...state,
        ...{ fileManager: { ...state.fileManager, lastPath: action.payload } }
      }
    }
    case UserActionTypes.VIEW_PREFERENCE: {
      return {
        ...state,
        ...{ fileManager: { ...state.fileManager, viewPreference: action.payload } }
      }
    }
    case UserActionTypes.SELECTED_FROM_SEARCH: {
      return {
        ...state,
        ...{ fileManager: { ...state.fileManager, selectedFromSearch: action.payload } }
      }
    }
    case UserActionTypes.PRESERVE_CONSOLE_LOG: {
      return {
        ...state,
        ...{ code: { ...state.code, preserveLog: action.payload } }
      }
    }
    case UserActionTypes.COMPANY_HAS_CHANGED: {
      return {
        ...state,
        ...{ companyHasChanged: action.payload }
      }
    }
    case UserActionTypes.SHOW_HIDDEN_FILES: {
      return {
        ...state,
        fileManager: {
          ...state.fileManager,
          showHiddenFiles: action.payload
        }
      }
    }
    case UserActionTypes.NEED_TO_CHANGE_PASSWORD: {
      return {
        ...state,
        ...{ needToChangePassword: action.payload }
      }
    }
    case UserActionTypes.ENABLE_LOGIN: {
      return {
        ...state,
        ...{
          authData: {
            ...state.authData,
            otp_verified: true
          }
        }
      }
    }
    case UserActionTypes.IS_LOADING_COMPANY: {
      return {
        ...state,
        ...{ isLoadingCompany: action.payload }
      }
    }
    case UserActionTypes.RELOAD_INSTANCES: {
      return {
        ...state,
        ...{ reloadInstances: action.payload }
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as userReducer }
