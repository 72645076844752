import { Reducer } from 'redux'
import { ArrowHeadType, Edge, Node, Elements, isEdge } from 'react-flow-renderer'

import { ModelActionTypes, ModelState } from './types'
import {
  CodeNode,
  ModuleInfo,
  AcceptedNodeTypes,
  NodeFeedback,
  InterfaceFeedback,
  NodeInterfaceFeedback
} from 'common/types/model'

const initialState: ModelState = {
  diagram: undefined,
  selectedNode: undefined,
  runningNode: undefined,
  nodeErrors: [],
  interfaceErrors: [],
  nodeInterfaceErrors: [],
  nodeConsole: [],
  librariesInstallLog: []
}

const buildDiagramElements = (moduleInfo: ModuleInfo, disableInteractivity?: boolean): Elements => {
  const nodeArr: Node[] = moduleInfo.nodes.map(
    (node): Node => ({
      id: node.id,
      type:
        node.nodeClass && node.nodeClass in AcceptedNodeTypes
          ? node.nodeClass
          : node.nodeClass === 'alias'
          ? node.originalClass
          : 'generic',
      data: { ...node, isStatic: disableInteractivity },
      position: {
        x: node.x,
        y: node.y
      },
      style: {
        width: node.w,
        height: node.h
      }
    })
  )
  const edgeArr: Edge[] = moduleInfo.arrows.map((conn) => ({
    id: `${conn.from}-${conn.to}`,
    source: conn.from,
    target: conn.to,
    type: conn.from === conn.to ? 'recursive' : 'floating',
    style: {
      strokeWidth: '1px'
    },
    arrowHeadType: ArrowHeadType.ArrowClosed
  }))
  return [...nodeArr, ...edgeArr]
}

const reducer: Reducer<ModelState> = (
  state: ModelState = initialState,
  action: { type: ModelActionTypes; payload?: any }
) => {
  switch (action.type) {
    case ModelActionTypes.SET_ELEMENTS: {
      return {
        ...state,
        ...{
          diagram: action.payload.moduleInfo
            ? {
                ...state.diagram,
                elements: buildDiagramElements(
                  action.payload.moduleInfo,
                  action.payload.disableInteractivity
                ),
                breadcrumb: action.payload.moduleInfo.breadcrumb.reverse()
              }
            : {
                ...state.diagram,
                elements: undefined,
                breadcrumb: undefined
              }
        }
      }
    }
    case ModelActionTypes.UPDATE_ELEMENTS: {
      return {
        ...state,
        ...{
          diagram: {
            ...state.diagram,
            elements: action.payload
          }
        }
      }
    }

    case ModelActionTypes.UPDATE_NODE_ARROW: {
      const { id, hasArrow } = action.payload
      const elements = state.diagram?.elements
      if (!elements) {
        return state
      }
      const updatedNodeFinded = elements.find((el) => el.id === id)
      if (!updatedNodeFinded) {
        return state
      }
      const updatedNode = {
        ...updatedNodeFinded,
        data: {
          ...updatedNodeFinded.data,
          hasArrow
        }
      }
      const updatedElements = elements.map((el) => {
        if (el.id === id) {
          return updatedNode
        }
        return el
      })
      return {
        ...state,
        ...{
          diagram: {
            ...state.diagram,
            elements: updatedElements
          }
        }
      }
    }

    case ModelActionTypes.SELECT_NODE: {
      return {
        ...state,
        ...{ selectedNode: { identifier: action.payload.nodeId } }
      }
    }
    case ModelActionTypes.GO_TO_NODE_FROM_INTERFACE: {
      return {
        ...state,
        goToNodeFromInterface: action.payload
      }
    }
    case ModelActionTypes.SET_CENTER_NODE: {
      return {
        ...state,
        centerNode: action.payload
      }
    }
    case ModelActionTypes.SET_SELECTED_CODE_NODE: {
      return {
        ...state,

        ...{
          selectedNode: {
            ...state.selectedNode,
            codeNode: action.payload
          }
        }
      }
    }
    case ModelActionTypes.UPDATE_CODE_NODE: {
      const unconfirmedNodeIndex = state.diagram?.unconfirmedNodes?.findIndex(
        (item) => item.identifier === action.payload.identifier
      )
      const replacedArray = (items: CodeNode[], index: number, newItem: CodeNode) => {
        const resp = [...items]
        resp.splice(index, 1, newItem)
        return resp
      }
      return {
        ...state,
        ...{
          diagram: {
            ...state.diagram,
            unconfirmedNodes: state.diagram?.unconfirmedNodes
              ? unconfirmedNodeIndex !== undefined && unconfirmedNodeIndex > -1
                ? replacedArray(
                    state.diagram.unconfirmedNodes,
                    unconfirmedNodeIndex,
                    action.payload
                  )
                : [...state.diagram?.unconfirmedNodes, action.payload]
              : [action.payload]
          },
          selectedNode: {
            ...state.selectedNode,
            codeNode: action.payload
          }
        }
      }
    }
    case ModelActionTypes.REMOVE_NODE_FROM_UNSAVED: {
      return {
        ...state,
        ...{
          diagram: {
            ...state.diagram,
            unconfirmedNodes: state.diagram?.unconfirmedNodes?.filter(
              (nd) => nd.identifier !== action.payload
            )
          }
        }
      }
    }
    case ModelActionTypes.SET_MODULEID: {
      return {
        ...state,
        ...{
          diagram: {
            ...state.diagram,
            moduleId: action.payload
          }
        }
      }
    }
    case ModelActionTypes.ACTIVE_DRAGGABLE_NODES: {
      const newElements = state.diagram?.elements?.map((el) => {
        if (isEdge(el)) {
          return el
        }
        return {
          ...el,
          draggable: action.payload
        }
      })
      return {
        ...state,
        ...{
          diagram: {
            ...state.diagram,
            elements: newElements
          }
        }
      }
    }
    case ModelActionTypes.SET_NODE_RESULT: {
      return {
        ...state,

        ...{
          selectedNode: {
            ...state.selectedNode,
            result: action.payload
          }
        }
      }
    }
    case ModelActionTypes.SET_RUNNING_NODE: {
      if (state.selectedNode?.preview) {
        state.selectedNode.preview = undefined
      }
      return {
        ...state,
        runningNode: action.payload
      }
    }
    case ModelActionTypes.SET_NODE_PREVIEW: {
      return {
        ...state,
        ...{
          selectedNode: {
            ...state.selectedNode,
            preview: action.payload
          }
        }
      }
    }
    case ModelActionTypes.UPDATE_PINNED_NODES: {
      return {
        ...state,
        updatePinnedNodes: action.payload
      }
    }
    case ModelActionTypes.SET_UPDATE_NODE_RESULT: {
      return {
        ...state,
        selectedNode: {
          ...state.selectedNode,
          result: undefined,
          preview: undefined
        }
      }
    }
    case ModelActionTypes.SET_ADDED_NODE_ID_TO_CODE_DEFINITION: {
      return {
        ...state,
        ...{
          selectedNode: {
            ...state.selectedNode,
            addedNodeIdToDefinition: action.payload
          }
        }
      }
    }
    case ModelActionTypes.SET_WIZARD_DATA: {
      return {
        ...state,
        wizard: action.payload
      }
    }
    case ModelActionTypes.SET_LAST_WIDGET_FOCUSED: {
      return {
        ...state,
        lastWidgetFocused: action.payload
      }
    }
    case ModelActionTypes.UNLOCK_PROPERTIES_DIALOG: {
      return {
        ...state,
        ...{
          selectedNode: {
            ...state.selectedNode,
            isPropertiesDialogUnlocked: action.payload
          }
        }
      }
    }
    case ModelActionTypes.UPDATE_NODE_ERROR: {
      const payload = action.payload as NodeFeedback
      const { nodeId } = payload
      let currentNodeErrors = state.nodeErrors || []

      if (payload?.feedback) {
        const nodeError = currentNodeErrors.find((item) => item.nodeId === nodeId)
        if (nodeError) {
          nodeError.feedback = payload.feedback
        } else {
          currentNodeErrors.push(payload)
        }
      } else {
        currentNodeErrors = currentNodeErrors.filter((item) => item.nodeId !== nodeId)
      }

      return { ...state, nodeErrors: [...currentNodeErrors] }
    }
    case ModelActionTypes.UPDATE_INTERFACE_ERROR: {
      const payload = action.payload as InterfaceFeedback
      const { componentId, interfaceId } = payload
      let currentInterfaceErrors = state.interfaceErrors || []

      if (payload?.feedback) {
        if (
          !currentInterfaceErrors.find(
            (item) =>
              item.interfaceId === payload.interfaceId &&
              item.componentId === payload.componentId &&
              item.feedback === payload.feedback
          )
        ) {
          currentInterfaceErrors.push(payload)
        }
      } else {
        currentInterfaceErrors = currentInterfaceErrors.filter(
          (item) => !(item.interfaceId === interfaceId && item.componentId === componentId)
        )
      }

      return { ...state, interfaceErrors: [...currentInterfaceErrors] }
    }
    case ModelActionTypes.UPDATE_NODE_INTERFACE_ERROR: {
      const payload = action.payload as NodeInterfaceFeedback
      const { nodeId } = payload
      let currentNodeInterfaceErrors = state.nodeInterfaceErrors || []

      if (payload?.feedback) {
        if (
          !currentNodeInterfaceErrors.find(
            (item) => item.nodeId === payload.nodeId && item.feedback === payload.feedback
          )
        ) {
          currentNodeInterfaceErrors.push(payload)
        }
      } else {
        currentNodeInterfaceErrors = currentNodeInterfaceErrors.filter(
          (item) => !(item.nodeId === nodeId)
        )
      }

      return { ...state, nodeInterfaceErrors: [...currentNodeInterfaceErrors] }
    }

    case ModelActionTypes.UPDATE_NODE_CONSOLE: {
      const currentNodeConsole = state.nodeConsole || []
      currentNodeConsole.push(action.payload)

      return {
        ...state,
        nodeConsole: [...currentNodeConsole]
      }
    }
    case ModelActionTypes.CLEAR_NODE_CONSOLE: {
      return {
        ...state,
        nodeConsole: []
      }
    }
    case ModelActionTypes.UPDATE_LIBRARIES_INSTALL_LOG: {
      return {
        ...state,
        librariesInstallLog: [...(state.librariesInstallLog || []), action.payload]
      }
    }
    case ModelActionTypes.CLEAR_LIBRARIES_INSTALL_LOG: {
      return {
        ...state,
        librariesInstallLog: []
      }
    }
    case ModelActionTypes.SHOW_NODE_IDS: {
      return {
        ...state,
        diagram: {
          ...state.diagram,
          showIds: action.payload
        }
      }
    }
    case ModelActionTypes.SET_NODES_IN_CLIPBOARD: {
      return {
        ...state,
        diagram: {
          ...state.diagram,
          nodeClipboard: action.payload
        }
      }
    }
    case ModelActionTypes.FORCE_SELECTION_OF_NODES: {
      return {
        ...state,
        diagram: {
          ...state.diagram,
          nodeClipboard: { ...state.diagram?.nodeClipboard, forceSelectionNodeIds: action.payload }
        }
      }
    }
    case ModelActionTypes.SET_SHORTCUT_PRESSED: {
      return {
        ...state,
        diagram: {
          ...state.diagram,
          shortcutPressed: action.payload
        }
      }
    }
    case ModelActionTypes.SET_MODULE_NAVIGATION_HISTORY: {
      return {
        ...state,
        diagram: {
          ...state.diagram,
          moduleNavigationHistory: action.payload
        }
      }
    }
    case ModelActionTypes.SET_NODE_COMPONENT_TO_EDIT: {
      return {
        ...state,
        nodeComponentToEdit: { ...action.payload }
      }
    }
    case ModelActionTypes.SET_DONT_CLEAN_SELECTED_ON_INITIAL_NAVIGATE: {
      return {
        ...state,
        dontCleanSelectedOnInitialNavigate: action.payload
      }
    }
    case ModelActionTypes.SET_VALUE_VARIANT_OF_INDEX: {
      return {
        ...state,
        valueVariantOfIndex: action.payload
      }
    }
    case ModelActionTypes.OPEN_CONSOLE: {
      return {
        ...state,
        diagram: {
          ...state.diagram,
          openConsole: action.payload
        }
      }
    }
    case ModelActionTypes.SHOW_MODULE_ID: {
      return {
        ...state,
        showModuleId: action.payload
      }
    }
    case ModelActionTypes.OPEN_DATA_READ: {
      return {
        ...state,
        openDataRead: action.payload
      }
    }
    case ModelActionTypes.OPEN_INPUT_DATA: {
      return {
        ...state,
        openInputData: action.payload
      }
    }
    case ModelActionTypes.SET_DISABLE_DIAGRAM_SCROLL: {
      return {
        ...state,
        disableDiagramScroll: action.payload
      }
    }
    case ModelActionTypes.CLEAR_CONSOLE_ERRORS: {
      return {
        ...state,
        nodeErrors: [],
        interfaceErrors: [],
        nodeInterfaceErrors: []
      }
    }
    case ModelActionTypes.SET_DEBUG_INFO: {
      return { ...state, debugInfo: [...(state.debugInfo || []), action.payload] }
    }
    case ModelActionTypes.RESET_DEBUG_INFO: {
      return { ...state, debugInfo: [] }
    }
    case ModelActionTypes.ENABLE_ANALYSIS:
      return {
        ...state,
        enableAnalysis: action.payload
      }
    case ModelActionTypes.SET_FILTERED_NODES:
      return {
        ...state,
        filteredNodes: action.payload
      }
    case ModelActionTypes.SET_RESULT_WIDGET_FOCUSED:
      return {
        ...state,
        resultWidgetFocused: action.payload
      }
    case ModelActionTypes.REFRESH_INDEX:
      return {
        ...state,
        refreshIndex: action.payload
      }
    case ModelActionTypes.LOADING_MODULE:
      return {
        ...state,
        loadingModule: action.payload
      }
    case ModelActionTypes.OPEN_ANALIZE_AND_MANIPULATE_DATA_WIDGET:
      return {
        ...state,
        openAnalyzeAndManipulateDataWidget: action.payload
      }
    case ModelActionTypes.SYSTEM_NODE_DEFINITION_HAS_CHANGED:
      return {
        ...state,
        systemNodeDefinitionHasChanged: action.payload
      }
    case ModelActionTypes.SET_HANDLING_DATA_ORIGINAL_NODE_ID:
      return {
        ...state,
        handlingDataOriginalNodeId: action.payload
      }
    case ModelActionTypes.LOADING_MANIPULATE_DATA_WIDGET:
      return {
        ...state,
        loadingManipulateDataWidget: action.payload
      }
    case ModelActionTypes.DISABLE_REDO_ACTION:
      return {
        ...state,
        disableReDoAction: action.payload
      }
    default: {
      return state
    }
  }
}

export { reducer as modelReducer }
