import { Reducer } from 'redux'
import { Layout } from 'react-grid-layout'

import { InterfaceActionTypes, InterfaceState } from './types'
import {
  IBaseComponent,
  IRenderComponent,
  IMinBaseComponent,
  SelectorFormats,
  ComponentTypes,
  SelectorProperties,
  SelectorResult,
  IndexesAndOptions,
  FilterComponent
} from 'pages/interfaces/components/types'
import {
  FilteredComponentValue,
  GridLayout,
  InterfaceEvent,
  InterfaceEventType
} from 'pages/interfaces/containers/types'
import { randomId } from 'common/helpers/strings'
import { defaultLayout } from 'pages/interfaces/helpers'
import { TabItem } from 'common/types/model'

interface SelectorFormatsIndex {
  [key: string]: any
}
const formatIndex: SelectorFormatsIndex = SelectorFormats

const initialState: InterfaceState = {
  selectedComponent: undefined,
  componentsLoading: undefined,
  tabs: [],
  activeTabs: [],
  list: [],
  refreshCurrentInterface: false,
  reloadLayout: 0,
  tabChanged: false,
  filterComponentState: undefined
}

const reducer: Reducer<InterfaceState> = (
  state: InterfaceState = initialState,
  action: { type: InterfaceActionTypes; payload?: any }
) => {
  switch (action.type) {
    case InterfaceActionTypes.SELECT_COMPONENT: {
      return {
        ...state,
        ...{ selectedComponent: action.payload }
      }
    }
    case InterfaceActionTypes.SET_INDEX_FILTER_DATA: {
      return {
        ...state,
        indexFilter: action.payload
      }
    }
    case InterfaceActionTypes.SET_DRILLDOWN_OPTIONS: {
      return {
        ...state,
        drilldownOptions: action.payload
      }
    }
    case InterfaceActionTypes.UPDATE_LAYOUT: {
      const interfaceId: string = action.payload.interfaceId
      const newLayout: GridLayout = action.payload.newLayout
      const triggerData: InterfaceEvent = action.payload.triggerData
      const triggerEvent: (triggerData: InterfaceEvent) => Promise<void> =
        action.payload.triggerEvent
      const eventType = triggerData.event_type
      const activeLayout = state.activeTabs?.find(
        (tab) => tab.activeTabId === interfaceId && tab.currentTab
      ) || {
        activeTabId: interfaceId,
        currentTab: false,
        components: defaultLayout.components,
        properties: defaultLayout.properties
      }
      let selectedComponent = state.selectedComponent
      const componentesToAdd: IMinBaseComponent[] = []
      // Remove
      activeLayout.components = activeLayout.components.filter((layoutItem) =>
        newLayout.components.some(
          (newLayoutItem) => newLayoutItem.component_id === layoutItem.component_id
        )
      )
      // Remove changed component
      if (triggerData.event_type === InterfaceEventType.ChangeComponent) {
        activeLayout.components = activeLayout.components.filter(
          (layoutItem) => layoutItem.component_id !== triggerData.params.component_id
        )
      }
      // Add
      const newItems = newLayout.components.filter(
        (newLayoutItem) =>
          !activeLayout.components.some(
            (layoutItem) => layoutItem.component_id === newLayoutItem.component_id
          )
      )
      if (newItems?.length > 0) {
        newItems.forEach((item) => {
          item.renderKey = randomId()
        })
        componentesToAdd.push(...newItems)
        activeLayout.components = activeLayout.components.concat(componentesToAdd)
      }
      if (eventType === InterfaceEventType.ChangeComponent) {
        // Change selected component
        const componentTab = newLayout.components.find(
          (item) => item.component_id === triggerData.params.component_id
        )
        if (componentTab) {
          selectedComponent =
            state.applicationComponents?.[interfaceId]?.[componentTab.component_id]
        }
      } else if (
        eventType === InterfaceEventType.CreateComponent &&
        newLayout.components.length > 0
      ) {
        // Select last component
        const componentTab = newLayout.components[newLayout.components.length - 1]
        if (componentTab) {
          selectedComponent =
            state.applicationComponents?.[interfaceId]?.[componentTab.component_id]
        }
      }
      componentesToAdd.forEach((component) => {
        const initialUpdate: InterfaceEvent = {
          event_type: InterfaceEventType.InitialUpdate,
          params: {
            component_id: component.component_id
          }
        }
        triggerEvent(initialUpdate)
      })
      const activeTabs = state.activeTabs || []
      const index = activeTabs.findIndex((item) => item.activeTabId === interfaceId)
      if (index !== -1) {
        activeTabs[index] = activeLayout
      } else {
        activeTabs.push(activeLayout)
      }

      return {
        ...state,
        selectedComponent,
        activeTabs: [...activeTabs]
      }
    }
    case InterfaceActionTypes.UPDATE_LAYOUT_POSITIONS: {
      const interfaceId: string = action.payload.interfaceId
      const newLayout: Layout[] = action.payload.newLayout
      const activeLayout = state.activeTabs?.find(
        (tab) => tab.activeTabId === interfaceId && tab.currentTab
      ) || {
        activeTabId: interfaceId,
        currentTab: false,
        components: defaultLayout.components,
        properties: defaultLayout.properties
      }
      newLayout.forEach((itemLayout) => {
        const component_id = itemLayout.i.split('.')[0]
        const component = activeLayout.components.find(
          (itemComponent) => itemComponent.component_id === component_id
        )
        if (component) {
          component.x = itemLayout.x
          component.y = itemLayout.y
          component.h = itemLayout.h
          component.w = itemLayout.w
        }
      })
      const activeTabs = state.activeTabs || []
      const index = activeTabs.findIndex((item) => item.activeTabId === interfaceId)
      if (index !== -1) {
        activeTabs[index] = activeLayout
      } else {
        activeTabs.push(activeLayout)
      }

      return {
        ...state,
        activeTabs: [...activeTabs]
      }
    }
    case InterfaceActionTypes.UPDATE_DEFAULT_INTERFACE_LAYOUT: {
      const newComponentResult: IRenderComponent = action.payload.result
      const defaultInterfaceLayout = state.defaultInterfaceLayout
      let selectedComponent = state.selectedComponent
      if (defaultInterfaceLayout) {
        const component = defaultInterfaceLayout.components.find(
          (layoutItemComponent) =>
            layoutItemComponent.component_id === newComponentResult.component_id
        )
        if (component) {
          const position = defaultInterfaceLayout.components.indexOf(component)
          const appComponent =
            state.applicationComponents?.[action.payload.interfaceId]?.[component.component_id]
          if (appComponent) {
            const newComponent = Object.assign({}, appComponent, newComponentResult)
            newComponent.renderKey = randomId()
            if (
              appComponent?.result &&
              newComponentResult.error_code &&
              newComponentResult.error_detail
            ) {
              newComponent.error_code = newComponentResult.error_code
              newComponent.error_detail = newComponentResult.error_detail
            }
            newComponent.isLoading = false
            defaultInterfaceLayout.components[position] = newComponent
            if (selectedComponent?.component_id === newComponent.component_id) {
              selectedComponent = newComponent
            }
          }
        }
      }
      return {
        ...state,
        defaultInterfaceLayout: { ...defaultInterfaceLayout }
      }
    }
    case InterfaceActionTypes.SET_COMPONENT_IS_LOADING: {
      return {
        ...state,
        componentsLoading: action.payload.componentsList
      }
    }
    case InterfaceActionTypes.SET_FILTER_COMPONENT_IS_LOADING: {
      return {
        ...state,
        filterComponentsLoading: action.payload
      }
    }
    case InterfaceActionTypes.SET_DEFAULT_INTERFACE_LAYOUT: {
      return {
        ...state,
        defaultInterfaceLayout: action.payload
      }
    }
    case InterfaceActionTypes.SAVE_LAYOUT: {
      const interfaceId: string = action.payload.interfaceId
      const newLayoutToSave: GridLayout = action.payload.newLayout
      const activeLayout = state.activeTabs?.find(
        (tab) => tab.activeTabId === interfaceId && tab.currentTab
      ) || {
        activeTabId: interfaceId,
        components: defaultLayout.components,
        properties: defaultLayout.properties
      }
      activeLayout.components?.forEach((component) => {
        const newPropToSave = newLayoutToSave.components.find(
          (componentToSave) => componentToSave.component_id === component.component_id
        )
        if (newPropToSave) {
          component.x = newPropToSave.x
          component.y = newPropToSave.y
          component.w = newPropToSave.w
          component.h = newPropToSave.h
        }
      })
      const activeTabs = state.activeTabs || []
      const index = activeTabs.findIndex((item) => item.activeTabId === interfaceId)
      if (index !== -1) {
        activeTabs[index] = activeLayout
      } else {
        activeTabs.push(activeLayout)
      }

      return {
        ...state,
        activeTabs: [...activeTabs]
      }
    }
    case InterfaceActionTypes.SET_INTERFACE_WIDGET_EXPANDED: {
      return {
        ...state,
        interfaceWidgetExpanded: { componentId: action.payload }
      }
    }
    case InterfaceActionTypes.SET_COMPONENT_TO_EDIT: {
      return {
        ...state,
        componentToEdit: { ...action.payload }
      }
    }
    case InterfaceActionTypes.COPY_STYLES: {
      return {
        ...state,
        stylesToCopy: { ...action.payload }
      }
    }
    case InterfaceActionTypes.SET_RECENTLY_USED_COLORS: {
      const newColors = [...(state.recentlyUsedColors || [])]
      newColors.push(action.payload)
      return {
        ...state,
        recentlyUsedColors: newColors
      }
    }
    case InterfaceActionTypes.SET_INTERFACE_TABS: {
      const activeTabs = state.activeTabs || []
      // remove active tab if it is not in the new tabs
      const newTabsIds = new Set(action.payload.map((tab: TabItem) => tab.id))
      const newActiveTabs = activeTabs.filter((tab) => newTabsIds.has(tab.activeTabId))
      return {
        ...state,
        tabs: action.payload,
        activeTabs: newActiveTabs
      }
    }
    case InterfaceActionTypes.SET_OPEN_INTERFACE_CURRENT_TAB: {
      return {
        ...state,
        openInterfaceCurrentTab: action.payload
      }
    }
    case InterfaceActionTypes.SET_INTERFACE_OPENED_FROM_MANAGER: {
      return {
        ...state,
        interfaceOpenedFromManager: action.payload
      }
    }
    case InterfaceActionTypes.SET_INTERFACE_OPENED_FROM_CREATION: {
      return {
        ...state,
        interfaceOpenedFromCreation: action.payload
      }
    }
    case InterfaceActionTypes.SET_ALL_INTERFACES: {
      return {
        ...state,
        list: action.payload
      }
    }
    case InterfaceActionTypes.SET_INTERFACE_WITH_CHANGES: {
      return {
        ...state,
        interfaceWithChanges: action.payload
      }
    }
    case InterfaceActionTypes.SET_CLICKED_FROM_TOPBAR: {
      return {
        ...state,
        clickedFromTopbar: action.payload
      }
    }
    case InterfaceActionTypes.CHANGE_DEFAULT_INTERFACE: {
      return {
        ...state,
        changeDefaultInterface: action.payload
      }
    }
    case InterfaceActionTypes.SET_ACCORDION_MENU_ITEMS: {
      const accordionMenu = state.accordionMenu || []
      const currentItem = accordionMenu?.find((item) => item.id === action.payload.id)
      if (currentItem) {
        const currentItemIndex = accordionMenu?.findIndex((item) => item.id === action.payload.id)
        if (currentItemIndex !== -1) {
          accordionMenu[currentItemIndex] = action.payload
        }
      } else {
        accordionMenu.push(action.payload)
      }
      return {
        ...state,
        accordionMenu
      }
    }
    case InterfaceActionTypes.SET_MENU_FOLDER_HISTORY: {
      const componentId = action.payload.componentId
      const interfaceId = action.payload.interfaceId
      const folders = action.payload.folders
      const currentFolder = action.payload.currentFolder
      const menuFolderHistory = state.menuFolderHistory || {}
      menuFolderHistory[`${interfaceId}-${componentId}`] = { folders, currentFolder }
      return {
        ...state,
        menuFolderHistory: { ...menuFolderHistory }
      }
    }
    case InterfaceActionTypes.SET_REFRESH_CURRENT_INTERFACE: {
      return {
        ...state,
        refreshCurrentInterface: action.payload
      }
    }
    case InterfaceActionTypes.SET_EXTERNAL_LAYOUT: {
      return {
        ...state,
        externalLayout: action.payload
      }
    }
    case InterfaceActionTypes.SET_INTERFACE_FROM_LINK: {
      return {
        ...state,
        interfaceFromLink: action.payload
      }
    }
    case InterfaceActionTypes.SET_EXTERNAL_INTERFACE_ID: {
      return {
        ...state,
        externalInterfaceId: action.payload
      }
    }
    case InterfaceActionTypes.SET_LAST_ACCESSED_PATH: {
      return {
        ...state,
        lastAccessedPath: action.payload
      }
    }
    case InterfaceActionTypes.SET_CURRENT_NAVIGATION_HISTORY: {
      return {
        ...state,
        currentNavigationHistory: action.payload
      }
    }
    case InterfaceActionTypes.SET_EDITOR_DYNAMIC_HTML: {
      return {
        ...state,
        componentDynamicHtml: action.payload
      }
    }
    case InterfaceActionTypes.SET_VERTICAL_DYNAMIC_HTML_SIZE: {
      return {
        ...state,
        verticalDynamicHtmlSize: action.payload
      }
    }
    case InterfaceActionTypes.SET_HORIZONTAL_DYNAMIC_HTML_SIZE: {
      return {
        ...state,
        horizontalDynamicHtmlSize: action.payload
      }
    }
    case InterfaceActionTypes.SET_OPEN_DEFAULT_INTERFACE_FROM_TOPBAR: {
      return {
        ...state,
        openDefaultInterfaceFromTopbar: action.payload
      }
    }
    case InterfaceActionTypes.SET_VERTICAL_ACTIONS_DYNAMIC_HTML_SIZE: {
      return {
        ...state,
        verticalActionsDynamicHtmlSize: action.payload
      }
    }
    case InterfaceActionTypes.SET_HORIZONTAL_ACTIONS_DYNAMIC_HTML_SIZE: {
      return {
        ...state,
        horizontalActionsDynamicHtmlSize: action.payload
      }
    }
    case InterfaceActionTypes.SET_EDITING_INTERFACE: {
      return {
        ...state,
        editingInterface: action.payload
      }
    }
    case InterfaceActionTypes.SET_SCROLL_ROW_TABLE: {
      return {
        ...state,
        scrollRowTable: action.payload
      }
    }
    case InterfaceActionTypes.SET_SCROLL_COLUMN_TABLE: {
      return {
        ...state,
        scrollColumnTable: action.payload
      }
    }
    case InterfaceActionTypes.LAST_COMPONENT_DRILLDOWNED: {
      return {
        ...state,
        lastComponentDrilldowned: action.payload
      }
    }
    case InterfaceActionTypes.SET_ACTIVE_TAB: {
      const activeTabs = state.activeTabs || []
      const index = activeTabs.findIndex((item) => item.activeTabId === action.payload.activeTabId)
      const defaultInterfaceLayoutId = state.defaultInterfaceLayout?.properties?.id
      if (index === -1) {
        if (activeTabs.length >= 5) {
          const defaultTab = activeTabs.findIndex(
            (item) => item.activeTabId === defaultInterfaceLayoutId
          )
          if (defaultTab === -1) {
            activeTabs.shift()
          } else {
            const indexToRemove = defaultTab === 0 ? 1 : 0
            activeTabs.splice(indexToRemove, 1)
          }
        }
        action.payload.components.forEach((item: IBaseComponent) => {
          item.renderKey = randomId()
        })
        activeTabs.forEach((tab) => {
          tab.currentTab = false
        })
        activeTabs.push(action.payload)
        return {
          ...state,
          activeTabs
        }
      } else {
        activeTabs.forEach((tab) => {
          tab.currentTab = tab.activeTabId === action.payload.activeTabId
        })
        return {
          ...state,
          activeTabs
        }
      }
    }
    case InterfaceActionTypes.SELECTOR_OPTIONS_MAX: {
      const prevState = state.selectorOptionsMax || []
      if (!action.payload) {
        return {
          ...state,
          selectorOptionsMax: undefined
        }
      } else {
        if (prevState.find((item) => item.componentId === action.payload.componentId)) {
          const existingStateIndex = prevState.findIndex(
            (item) => item.componentId === action.payload.componentId
          )
          if (existingStateIndex !== -1) {
            prevState[existingStateIndex] = action.payload
          }
        } else {
          prevState.push(action.payload)
        }
        return {
          ...state,
          selectorOptionsMax: prevState
        }
      }
    }
    case InterfaceActionTypes.UPDATE_ACTIVE_TAB: {
      const activeTabs = state.activeTabs || []
      const index = activeTabs.findIndex((item) => item.activeTabId === action.payload.activeTabId)
      if (index !== -1) {
        return {
          ...state,
          activeTabs: [
            ...activeTabs.slice(0, index),
            action.payload,
            ...activeTabs.slice(index + 1)
          ]
        }
      }
      return {
        ...state,
        activeTabs
      }
    }
    case InterfaceActionTypes.REMOVE_ACTIVE_TAB: {
      const activeTabs = state.activeTabs || []
      const index = activeTabs.findIndex((item) => item.activeTabId === action.payload)
      if (index !== -1) {
        activeTabs.splice(index, 1)
      }
      return {
        ...state,
        activeTabs
      }
    }
    case InterfaceActionTypes.REMOVE_ACTIVES_TABS: {
      const defaultInterfaceLayout = state.defaultInterfaceLayout || defaultLayout
      if (defaultInterfaceLayout && defaultInterfaceLayout.properties.id) {
        const activeDefaultTab = {
          activeTabId: defaultInterfaceLayout.properties.id,
          currentTab: true,
          components: defaultInterfaceLayout.components,
          properties: defaultInterfaceLayout.properties
        }
        return {
          ...state,
          activeTabs: [activeDefaultTab]
        }
      }
      return state
    }
    case InterfaceActionTypes.SET_FILTERED_COMPONENT_VALUE: {
      return {
        ...state,
        filteredComponentValue: action.payload
      }
    }
    case InterfaceActionTypes.SET_SELECTED_OPTION_STATE: {
      return {
        ...state,
        selectedOptionState: action.payload
      }
    }
    case InterfaceActionTypes.LOADING_TAB_MENU: {
      return {
        ...state,
        loadingTabMenu: action.payload
      }
    }
    case InterfaceActionTypes.SET_APPLICATION_COMPONENT: {
      const interfaceId = action.payload.interfaceId
      const componentId = action.payload.appComponent.component_id
      action.payload.appComponent.renderKey = randomId()
      action.payload.appComponent.isLoading = false

      let selectedComponent = state.selectedComponent
      if (selectedComponent?.component_id === componentId) {
        selectedComponent = action.payload.appComponent
      }

      let componentsLoading = state.componentsLoading
      let selectorOptionsMax = state.selectorOptionsMax
      if (componentsLoading && componentsLoading.includes(componentId)) {
        componentsLoading = componentsLoading.filter((item) => item !== componentId)
      }
      // When the component is a Selector and the format is ClickableChips, Radio or Slider
      // we compare the options and indexes with the previous ones in state.selectorOptionsMax.
      // If they are different, we update the state
      const appComponent = action.payload.appComponent
      if (
        appComponent.component_type === ComponentTypes.Core_Selector &&
        ['ClickableChips', 'Radio', 'Slider'].some((format) =>
          ((appComponent.properties as unknown) as SelectorProperties)?.format?.includes(
            formatIndex[format]
          )
        )
      ) {
        let selectedOptionMax = state.selectorOptionsMax?.find(
          (item) => item.componentId === appComponent.component_id
        )
        if (selectedOptionMax) {
          const optionsAndIndexes = ((appComponent?.result as unknown) as SelectorResult)
            ?.options_and_indexes
          const convertedObject =
            optionsAndIndexes?.reduce((acc: { [key: number]: string }, option) => {
              acc[option.index] = option.value
              return acc
            }, {} as IndexesAndOptions) || {}
          const selectorResults = selectedOptionMax.results
          const areEqual = JSON.stringify(selectorResults) === JSON.stringify(convertedObject)
          if (!areEqual) {
            selectedOptionMax.results = convertedObject
            selectedOptionMax.componentId = appComponent.component_id
            selectorOptionsMax = state.selectorOptionsMax?.filter(
              (item) => item.componentId !== appComponent.component_id
            )
            selectorOptionsMax?.push(selectedOptionMax)
          }
        }
      }

      if (
        state?.filteredComponentValue &&
        state.filteredComponentValue.componentId === componentId
      ) {
        action.payload.appComponent.result.selected_values = state.filteredComponentValue?.values
        action.payload.appComponent.result.selected_indexes = state.filteredComponentValue?.indexes
        delete state.filteredComponentValue
      }

      let filterComponentState = state.filterComponentState
      let filterComponentIds = state.filterComponentIds
      let positionFilterSticky = state.positionFilterSticky
      if (
        appComponent.component_type === ComponentTypes.Core_Filter &&
        appComponent?.result?.filter_components
      ) {
        positionFilterSticky = appComponent.properties.position_sticky
        filterComponentState = appComponent.result.filter_components as FilterComponent[]
        filterComponentIds = filterComponentState.map((item) => item.component_id)
      }
      return {
        ...state,
        selectedComponent: { ...selectedComponent },
        componentsLoading: componentsLoading ? [...componentsLoading] : undefined,
        selectorOptionsMax: selectorOptionsMax ? [...selectorOptionsMax] : undefined,
        filterComponentState: filterComponentState,
        filterComponentIds: filterComponentIds,
        positionFilterSticky: positionFilterSticky,
        applicationComponents: {
          ...state.applicationComponents,
          [interfaceId]: {
            ...state.applicationComponents?.[interfaceId],
            [componentId]: action.payload.appComponent
          }
        }
      }
    }
    case InterfaceActionTypes.UPDATE_APPLICATION_COMPONENT: {
      const appComponents = action.payload.appComponent
        ? {
            [action.payload.appComponent.component_id]: {
              ...state.applicationComponents?.[action.payload.interfaceId]?.[
                action.payload.appComponent?.component_id
              ],
              ...action.payload.appComponent
            }
          }
        : {}

      return {
        ...state,
        applicationComponents: {
          ...state.applicationComponents,
          [action.payload.interfaceId]: {
            ...state.applicationComponents?.[action.payload.interfaceId],
            ...appComponents
          }
        }
      }
    }
    case InterfaceActionTypes.REMOVE_APPLICATION_COMPONENTS: {
      const interfaceId = action.payload
      const appComponents = state.applicationComponents || {}
      delete appComponents[interfaceId]
      if (
        state?.filteredComponentValue &&
        state.filteredComponentValue.interfaceId === interfaceId
      ) {
        delete state.filteredComponentValue
      }
      return {
        ...state,
        applicationComponents: { ...appComponents }
      }
    }
    case InterfaceActionTypes.REMOVE_APPLICATION_COMPONENT: {
      const { componentId, interfaceId } = action.payload
      const appComponents = state.applicationComponents || {}
      const component = appComponents[interfaceId] || {}
      let filterComponentIds = state.filterComponentIds
      let filterComponentState = state.filterComponentState
      if (component[componentId]?.component_type === ComponentTypes.Core_Filter) {
        filterComponentIds = []
        filterComponentState = []
      }
      delete component[componentId]
      return {
        ...state,
        applicationComponents: { ...appComponents },
        filterComponentIds,
        filterComponentState
      }
    }
    case InterfaceActionTypes.SET_RELOAD_LAYOUT: {
      return {
        ...state,
        reloadLayout: Math.max(1, (state.reloadLayout || 0) + 1)
      }
    }
    case InterfaceActionTypes.SET_TAB_CHANGED: {
      return {
        ...state,
        tabChanged: action.payload
      }
    }
    case InterfaceActionTypes.SET_FILTER_COMPONENT_STATE: {
      return {
        ...state,
        filterComponentState: action.payload
      }
    }
    case InterfaceActionTypes.ADD_FILTER_COMPONENT_STATE: {
      const filterComponentState = state.filterComponentState || []
      let filterComponentIds = state.filterComponentIds
      const componentId = action.payload.component_id
      let filterComponent = action.payload
      filterComponent['renderKey'] = randomId()
      const index = filterComponentState.findIndex((item) => item.component_id === componentId)
      let newFilterComponentState
      if (index === -1) {
        newFilterComponentState = [...filterComponentState, filterComponent]
        filterComponentIds = filterComponentIds?.concat(componentId)
      } else {
        newFilterComponentState = filterComponentState.map((item, idx) =>
          idx === index ? filterComponent : item
        )
      }
      return {
        ...state,
        filterComponentState: newFilterComponentState,
        filterComponentIds
      }
    }
    case InterfaceActionTypes.REMOVE_FILTER_COMPONENT_STATE: {
      const filterComponentState = state.filterComponentState || []
      let filterComponentIds = state.filterComponentIds
      const componentId = action.payload
      const index = filterComponentState.findIndex((item) => item.component_id === componentId)
      if (index !== -1) {
        filterComponentState.splice(index, 1)
        filterComponentIds = filterComponentIds?.filter((item) => item !== componentId)
      }
      return {
        ...state,
        filterComponentState,
        filterComponentIds
      }
    }
    case InterfaceActionTypes.UPDATE_FILTER_COMPONENT_STATE_RESULT: {
      const filterComponentStateResult: FilteredComponentValue = action.payload
      const filterComponentState = state.filterComponentState || []
      const index = filterComponentState.findIndex(
        (item) => item.component_id === filterComponentStateResult.componentId
      )
      if (index !== -1) {
        // @ts-ignore
        filterComponentState[index] = {
          ...filterComponentState[index],
          result: {
            ...filterComponentState[index].result,
            selected_values: filterComponentStateResult.values
          }
        }
      }
      return {
        ...state,
        filterComponentState
      }
    }
    case InterfaceActionTypes.ORDER_FILTER_COMPONENT_STATE: {
      const order = action.payload
      const filterComponentState = state.filterComponentState || []
      const orderedFilterComponentState = filterComponentState.sort(
        (a, b) => order.indexOf(a.component_id) - order.indexOf(b.component_id)
      )
      return {
        ...state,
        filterComponentState: orderedFilterComponentState
      }
    }
    case InterfaceActionTypes.SET_FILTER_COMPONENT_DIALOG_OPEN: {
      return {
        ...state,
        filterComponentDialogOpen: action.payload
      }
    }
    case InterfaceActionTypes.SET_OPEN_FILTER_COMPONENT_DIALOG: {
      return {
        ...state,
        openFilterComponentDialog: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as interfaceReducer }
