const messages = {
  'common.code': 'Código',
  'common.diagram': 'Diagrama',
  'common.preview': 'Vista previa',
  'common.result': 'Resultado',
  'common.shortcuts': 'Atajos',
  'common.app': 'Aplicación',
  'common.apps': 'Aplicaciones',
  'common.confirm': 'Confirmar',
  'common.close': 'Cerrar',
  'common.cancel': 'Cancelar',
  'common.run': 'Evaluar',
  'common.stop': 'Detener',
  'common.home': 'Inicio',
  'common.error': 'Error',
  'common.console': 'Consola',
  'common.all': 'Todo',
  'common.allPlural': 'Todos',
  'common.apply': 'Aplicar',
  'common.noResultsFound': 'No se encontraron resultados',
  'common.options': 'Opciones',
  'common.goToNode': 'Ir al nodo',
  'common.top': 'Superior',
  'common.bottom': 'Inferior',
  'common.left': 'Izquierda',
  'common.right': 'Derecha',
  'common.default': 'Por defecto',
  'common.custom': 'Personalizado',
  'common.none': 'Ninguno',
  'common.interface': 'Interfaz',
  'common.text': 'Texto',
  'common.true': 'Verdadero',
  'common.false': 'Falso',
  'common.hide': 'Esconder',
  'common.show': 'Mostrar',
  'common.position': 'Posición',
  'common.inside': 'Dentro',
  'common.outside': 'Fuera',
  'common.running': 'Ejecutando',
  'common.general': 'General',
  'common.value': 'Valor',
  'common.edit': 'Editar',
  'common.username': 'Usuario',
  'common.password': 'Contraseña',
  'common.invalidPassword': 'Contraseña inválida',
  'common.required': 'Obligatorio',
  'common.copy': 'Copiar',
  'common.cut': 'Cortar',
  'common.pasteBelow': 'Pegar debajo',
  'common.about': 'Acerca de',
  'common.documentation': 'Documentación',
  'common.addDocumentation': 'Agregar documentación',
  'common.editDocumentation': 'Editar documentación',
  'common.help': 'Ayuda',
  'common.from': 'Desde',
  'common.to': 'Hasta',
  'common.color': 'Color',
  'common.values': 'Valores',
  'common.applyTo': 'Aplicar a',
  'common.save': 'Guardar',
  'common.paste': 'Pegar',
  'common.total': 'Total',
  'common.totals': 'Totales',
  'common.row': 'Fila',
  'common.rows': 'Filas',
  'common.column': 'Columna',
  'common.columns': 'Columnas',
  'common.measures': 'Medidas',
  'common.labels': 'Etiquetas',
  'common.read': 'Leer',
  'common.properties': 'Propiedades',
  'common.selectInterface': 'Seleccionar interfaz',
  'common.yes': 'Si',
  'common.no': 'No',
  'common.table': 'Tabla',
  'common.cube': 'Cubo',
  'common.discard': 'Descartar',
  'common.settings': 'Ajustes',
  'common.resetView': 'Restablecer vista',
  'common.reloadComponent': 'Recargar componente',
  'common.fields': 'Campos',
  'common.delete': 'Borrar',
  'common.add': 'Agregar',
  'common.information': 'Información',
  'common.openApp': 'Abrir app',
  'common.description': 'Descripción',
  'common.version': 'Versión',
  'common.versions': 'Versiones',
  'common.screenshots': 'Capturas de pantalla',
  'common.showInstallationLogs': 'Mostrar logs de instalación',
  'common.hideInstallationLogs': 'Ocultar logs de instalación',
  'common.noResults': 'Sin resultados',
  'common.conditionFormatType': 'Tipo de condición',
  'common.loadingAppVersion': 'Cargando versión de la aplicación',
  'common.creatingAppVersion': 'Creando versión de la aplicación',
  'common.notAllowedCharacters':
    "Caracter no permitido. Los siguientes caracteres no están permitidos: / \\ '",
  'common.status': 'Estado',
  'common.activate': 'Activar',
  'common.archive': 'Archivar',
  'common.unarchive': 'Desarchivar',
  'common.invalidCharactersMessage': 'No se permiten los siguientes caracteres: {characters}',
  'common.import': 'Importar',
  'common.export': 'Exportar',
  'common.scenarios': 'Escenarios',
  'common.selectANode': 'Elegir un nodo',
  'common.deleteAll': 'Borrar todo',
  'common.creator': 'Creador',
  'common.scenario': 'Escenario',
  'common.validating': 'Validando',
  'common.selectedNode.incorrect': 'El nodo seleccionado es incorrecto. Seleccione otro nodo.',
  'common.max': 'Máximo',
  'common.min': 'Mínimo',
  'common.internalTests': 'Pruebas internas',
  'common.used': 'Usado',
  'common.generalSettings': 'Configuración general',
  'common.clipboard': 'Portapapeles',
  'common.clipboard.clear': 'Vaciar portapapeles',
  'common.apiKeysManager': 'Administrador de claves Api',
  'common.user': 'Usuario',
  'common.items': 'Elementos',
  'common.assistant': 'Asistente',
  'common.assistants': 'Asistentes',
  'common.sources': 'Fuentes',
  'common.appConsolidation': 'Consolidación de la aplicación',
  'common.changes': 'Cambios',
  'common.destination': 'Destino',
  'common.new': 'Nuevo',
  'common.old': 'Viejo',
  'common.definition': 'Definición',
  'common.nodes': 'Nodos',
  'common.appManagement': 'Administración de la aplicación',
  'common.modules': 'Módulos',
  'common.maximize': 'Maximizar',
  'common.minimize': 'Minimizar',
  'common.areYouSureYouWantToProceed': 'Estás seguro de querer continuar?',
  'common.load': 'Cargar',
  'common.colors': 'Colores',
  'common.component': 'Componente',
  'common.source': 'Origen',
  'common.string': 'Texto',
  'common.integer': 'Entero',
  'common.float': 'Decimal',
  'common.boolean': 'Booleano',
  'common.date': 'Fecha',
  'common.extraSettings': 'Opciones adicionales',
  'common.apiKeys': 'Claves de API',
  'common.applicationEnvironment': 'Entorno de la aplicación',
  'common.moduleRestrictions': 'Restricciones de módulos',
  'common.workflowProcesses': 'Procesos de flujo de trabajo',
  'common.lostMessage': 'se perderán.',
  'common.attemptingReconnectServer': 'Intentando reconectar con el servidor, por favor espere...',
  'common.connectionReestablished': 'Conexión restablecida',
  'common.order': 'Orden',
  'common.nodeInterface': 'Interfaz de nodo',
  'common.pleaseWait': 'Por favor espere...',
  'common.context': 'Contexto',
  'common.name': 'Nombre',
  'common.assistantBot': 'Asistente',
  'common.assistantBots': 'Asistentes',
  'common.open': 'Abrir',
  'common.somethingWentWrong': 'Algo anduvo mal',
  'common.details': 'Detalles',
  'common.reloadPage': 'Recargar página',
  'common.titleAndId': 'Título + id',
  'common.expand': 'Expandir',

  copying_app_to_user_workspace: 'Copiando aplicación en espacio de trabajo del usuario',
  creating_environment: 'Creando ambiente',
  starting_environment: 'Iniciando ambiente',
  loading_workspace: 'Cargando espacio de trabajo',
  engine_created: 'Motor de cálculo creado',
  starting_pyplan_calc_engine: 'Inicializando motor de cálculo',
  opening_app: 'Abriendo aplicación',
  installing_requirements_file_libraries: 'Instalando librerías desde archivo requirements.txt',
  no_libraries_to_install: 'Sin librerías para instalar',
  done: 'Listo',
  may_take: 'Esta tarea puede demorar hasta 5 minutos. Por favor espere...',
  'common.checkingPythonLibrariesUsedInModel':
    'Verificando las bibliotecas de python utilizadas en el modelo',
  'common.librariesAlreadyInstalled': 'Librarías ya instaladas',
  'common.resultsNotFound': 'Sin resultados, realiza una búsqueda',
  need_logout: 'Logout requerido',
  need_logout_detail:
    'El administrador ha solicitado cerrar la instancia. Por favor, guarde y cierre la instancia lo antes posible',
  session_killed_detail: 'Logout requerido',
  session_killed: 'El administrador cerró su instancia',
  instance_purged: 'Su instancia expiró debido a inactividad',
  instance_purged_detail: 'Cerrar la instancia',
  session_purged: 'Su sesión expiró debido a inactividad',
  session_purged_detail: 'Iniciar sesión de nuevo',
  engine_down:
    'El motor de cálculo se cerró de forma inesperada. Por favor, cerrar la instancia y abrir una nueva',
  engine_down_detail: 'Cerrar instancia',
  error_installing_libs_from_requirements:
    'Error instalando librerías desde el archivo requirements.txt',
  error_creating_new_version: 'Error creando nueva versión',
  error_version_name_already_exists: 'El nombre de la versión elegido ya existe',
  app_recovered_from_backup: 'Aplicación recuperada desde copia de seguridad',
  creating_assistant: 'Creando asistente',
  updating_assistant: 'Actualizando asistente',
  indexing_documents: 'Indexando documentos',
  recovered_existing_instance: 'La aplicación se recuperó desde una instancia existente.',
  app_closed_in_another_tab: 'La aplicación se cerró en otra pestaña.',
  session_closed_in_another_tab: 'La sesión se cerró en otra pestaña.',

  //home
  'app.components.HomePage.header': 'Página de inicio',
  //notifier
  'common.notifier.dismissSnackbar': 'Cerrar',
  'common.notifier.readMore': 'Ver más',

  //Initial configuration
  'app.pages.initialConfig.initialSetUp': 'Configuración inicial',
  'app.pages.initialConfig.internalTesting': 'Pruebas internas',
  'app.pages.initialConfig.finish': 'Finalizar',
  'app.pages.initialConfig.initialConfig': 'Por favor ingrese el nombre de la compañía',
  'app.pages.initialConfig.userAdminInfo':
    'Ingrese información del usuario administrador de la compañía',
  'app.pages.initialConfig.copyKey':
    'Copie el código de activación y envíeselo al administrador del sistema para generar el código de licencia',
  'app.pages.initialConfig.finishMessage': 'Proceso finalizado con éxito',
  'app.pages.initialConfig.licenseSuccess': 'Licencia creada con éxito',

  // Internal tests
  'app.pages.internalTests.runTests': 'Ejecutar pruebas',
  'app.pages.internalTests.deleteApp': 'Borrar aplicación',
  'app.pages.internalTests.createPublicFolder': 'Crear carpeta pública',
  'app.pages.internalTests.removePublicFolder': 'Borrar carpeta pública',
  'app.pages.internalTests.systemResources': 'Recursos del sistema',

  //cards
  'app.components.cards.instanceCard.openAgo': 'Abierta hace {number} mins',
  'app.components.cards.instanceCard.currentInstance': 'Instancia Actual',
  'app.components.cards.instanceCard.activeAgo': 'Activa hace {number} mins',
  'app.components.cards.instanceCard.deleteInstance': 'Eliminar instancia',
  'app.components.cards.instanceCard.changeInstance':
    'Cambiar a esta instancia:{br}- Nombre: {title}{br}- Versión: {version}{br}- Ruta: {modelPath}',
  'app.components.cards.instanceCard.currentInstanceTooltip':
    '- Nombre: {title}{br}- Versión: {version}{br}- Ruta: {modelPath}',

  //login
  'app.pages.login.init': 'Inicio',
  'app.pages.login.usernameLabel': 'Usuario',
  'app.pages.login.passwordLabel': 'Contraseña',
  'app.pages.login.forgotPassword': '¿Olvidó la contraseña?',
  'app.pages.login.login': 'Iniciar Sesión',
  'app.pages.login.connectWithSocialMedia': 'Conectar con redes sociales',
  'app.pages.login.connectWithApp': 'Conectar con {app}',
  'app.pages.login.or': 'o',
  'app.pages.login.orContinueWith': 'o continuar con:',
  'app.pages.login.SSOTo': 'SSO a {company}',
  'app.pages.login.createAnAccount': 'Crear una cuenta',
  'app.pages.login.copyright': 'Copyright © Pyplan {year}.',
  'app.pages.login.selectCompany': 'Seleccione una empresa',
  'app.pages.login.licenseInfo': 'Información de licencia',
  'app.pages.login.invalidLicense': 'Licencia inválida',
  'app.pages.login.carousel.previous': 'Anterior', //in spanish and pt these are duplicated, but are unique in english
  'app.pages.login.carousel.next': 'Siguiente',
  'app.pages.login.carousel.viewMore': 'Ver más',
  'app.pages.login.carousel.userPasswordExpired':
    '¡La contraseña de tu usuario ha caducado! Por favor, elige una nueva para mantener tu cuenta segura.',
  'app.pages.login.carousel.userPasswordRepeated':
    'La nueva contraseña debe ser diferente de la actual.',
  'app.pages.login.rememberMe': 'Recordarme',
  'app.pages.login.resetPasswordMessage': 'Te enviamos un email para restablecer tu contraseña',
  'app.pages.login.resetPassword': 'Restablecer contraseña',
  'app.pages.login.passwordChangedSuccessfully': 'Contraseña cambiada con éxito',
  'app.pages.login.usernameError': 'El usuario {name} no existe',
  'app.pages.login.invalidToken': 'El token no es válido.',
  'app.pages.login.multiFactorAuthentication': 'Autenticación de múltiples factores',
  'app.pages.login.scanQrCode':
    'Mejora la seguridad de tu cuenta descargando una aplicación de Autenticación en Dos Pasos (2FA), como Google Authenticator o Microsoft Authenticator, en tu dispositivo móvil. Escanea el código QR que se muestra a continuación utilizando la aplicación para generar códigos de verificación únicos cada vez que inicies sesión.',
  'app.pages.login.enterCode': 'Ingresa el código generado por tu aplicación de autenticación',
  'app.pages.login.enterCodePlaceholder': 'Ingresa el código',
  'app.pages.login.enterCodeError': 'El código no es válido',
  'app.pages.login.enterCodeSuccess':
    'La autenticación de múltiples factores se habilitó correctamente',
  'app.pages.login.verify': 'Verificar',
  'app.pages.login.cantUseAuthAppNow':
    'No puedo usar mi aplicación de autenticación en este momento.',
  'app.pages.login.sendCodeToEmail':
    'Te hemos enviado el código de verificación a tu casilla de correo electrónico {userSecretMail}. Por favor ingresa el código para continuar.',
  'app.pages.login.loadingInstanceData': 'Cargando datos de la instancia',
  'app.pages.login.deleteSSOTo': 'Borrar link "SSO a {company}"',

  //topbar
  'app.pages.app.topbar.notifications': 'Notificaciones',
  'app.pages.app.topbar.showMore': 'Ver más',
  'app.pages.app.topbar.showNotifications': 'Ver notificaciones',
  'app.pages.app.topbar.defaultInterface': 'Interfaz por defecto',
  'app.pages.app.topbar.summary': 'Resumen',
  'app.pages.app.topbar.manageLibs': 'Administrar librerías',
  'app.pages.app.topbar.reloadApp': 'Recargar aplicación',
  'app.pages.app.topbar.createNew.app': 'Aplicación',
  'app.pages.app.topbar.layout.diagram': 'Resultado',
  'app.pages.app.topbar.layout.preview': 'Código+Preview',
  'app.pages.app.topbar.layout.result': 'Código+Resultado',
  'app.pages.app.topbar.saveAs': 'Guardar como',
  'app.pages.app.topbar.saveAsNewVersion': 'Guardar como nueva versión',
  'app.pages.app.topbar.saveApplicationInMyWorkspace':
    'Guardar aplicación en mi espacio de trabajo',
  'app.pages.app.topbar.appName': 'Nombre de la aplicación',
  'app.pages.app.topbar.appNameValidationError':
    'El nombre de la aplicación no puede contener alguno de los siguientes caracteres: ',
  'app.pages.app.topbar.selectAppVersionsToCopy':
    'Seleccione las versiones de la aplicación a copiar',
  'app.pages.app.topbar.copiesAllAppVersionMessage':
    'Se copiarán todas las versiones si no hay ninguna seleccionada',
  'app.pages.app.topbar.logout': 'Cerrar sesión',
  'app.pages.app.topbar.libsManager.uninstalled': 'La librería fué desinstalada existosamente',
  'app.pages.app.topbar.libsManager.version': 'Versión',
  'app.pages.app.topbar.libsManager.refresh': 'Refrescar',
  'app.pages.app.topbar.libsManager.location': 'Ubicación',
  'app.pages.app.topbar.libsManager.actions': 'Acciones',
  'app.pages.app.topbar.libsManager.installedLibraries': 'Librerías instaladas',
  'app.pages.app.topbar.libsManager.install': 'Instalar',
  'app.pages.app.topbar.libsManager.appRequirements': 'Requerimientos de la app',
  'app.pages.app.topbar.libsManager.saveFileInstallLibraries':
    'Guardar archivo e instalar librerías',
  'app.pages.app.topbar.libsManager.saveInstallLibraries': 'Guardar e instalar',
  'app.pages.app.topbar.libsManager.requirementsFile': 'Archivo requirements.txt',
  'app.pages.app.topbar.libsManager.pipInstallError': 'No se permiten espacios en blanco',
  'app.pages.app.topbar.libsManager.systemLib': 'lib de sistema',
  'app.pages.app.topbar.libsManager.userLib': 'lib de usuario',
  'app.pages.app.topbar.libsManager.filter': 'Filtrar',
  'app.pages.app.topbar.libsManager.libraries': 'Librerías',
  'app.pages.app.topbar.showConsole': 'Ver consola',
  'app.pages.app.topbar.closeConsole': 'Cerrar consola',
  'app.pages.app.topbar.appProperties': 'Propiedades de la aplicación',
  'app.pages.app.topbar.deparmentsError':
    'Hay una interfaz sin departamentos.Por favor elige al menos 1 departamento.',
  'app.pages.app.topbar.deparmentRepeated': 'El departamento {name} ya tiene una interfaz definida',
  'app.pages.app.topbar.allDeps': 'Todos',
  'app.pages.app.topbar.selecteDefaultInterface':
    'Seleccione la interfaz por defecto para la aplicación',
  'app.pages.app.topbar.libsManager.cantInstallHead':
    'Usted está utilizando un entorno de librerías compartido. Pyplan necesita generar un entorno exclusivo para que usted pueda agregar/quitar librerías (esta tarea puede tardar unos minutos).',
  'app.pages.app.topbar.libsManager.cantInstallTasksHead':
    'Para esto se realizarán las siguientes tareas:',
  'app.pages.app.topbar.libsManager.cantInstallTask1': 'se grabará la aplicación actual',
  'app.pages.app.topbar.libsManager.cantInstallTask2': 'un nuevo entorno exclusivo será generado',
  'app.pages.app.topbar.libsManager.cantInstallTask3':
    'la aplicación se abrirá nuevamente con el entorno exclusivo',
  'app.pages.app.topbar.libsManager.cantInstallConfirmationMessage':
    'Está seguro que desea realizar estas tareas?',
  'app.pages.app.topbar.libsManager.cantInstallButtonText': 'Sí, realizar estas tareas',
  'app.pages.app.topbar.changeAppVersion': 'Cambiar versión de la aplicación',
  'app.pages.app.topbar.changeVersion': 'Cambiar versión',
  'app.pages.app.topbar.takeScreenshot': 'Tomar captura de pantalla',
  'app.pages.app.topbar.screenshotResult': 'Resultado de la captura de pantalla',
  'app.pages.app.topbar.downloadScreenshot': 'Descargar imagen',
  'app.pages.app.topbar.saveImageAsAppThumbnail': 'Guardar imagen como miniatura de la aplicación',
  'app.pages.app.topbar.appIdError':
    'El ID de la aplicación sólo puede contener números, letras, guiones y debe tener más de 7 caracteres',
  'app.pages.app.topbar.appIdMessage':
    'Para que los cambios surtan efecto, debes reiniciar la aplicación.',
  'app.pages.app.topbar.appIdAlert': 'Todos los datos relacionados con:',

  //Info menu dialog:
  'app.pages.app.closeApp': 'Cerrar aplicación',
  'app.pages.app.closedSuccessfully': 'Aplicación cerrada exitosamente',
  'app.pages.app.reloadSuccessfully': 'Aplicación recargada exitosamente',
  'app.pages.app.saveSuccessfully': 'Aplicación guardada exitosamente',
  'app.pages.app.appNewPath': 'La aplicación fue guardada en: ',
  'app.pages.app.saveApp': 'Guardar',
  'app.pages.app.newModelName': 'Nombre del nuevo modelo',
  'app.pages.app.newModelNamePlaceholder': 'Ingrese el nombre del nuevo modelo',
  'app.pages.app.unconfirmedNodes': 'Nodos sin confirmar',
  'app.pages.app.saveDefinitionsAndApp': 'Confirmar definiciones y guardar aplicación',
  'app.pages.app.discardDefinitionsAndSaveApp': 'Descartar definiciones y guardar aplicación',
  'app.pages.app.unconfirmedNodesAlert':
    'Hay {number} nodos con definiciones sin confirmar. ¿Qué le gustaría hacer?',
  'app.pages.app.lostDataAlert':
    'Los cambios no guardados de la aplicación se perderán. ¿Estás seguro?',
  'app.pages.app.unsavedChanges': 'Tiene cambios no guardados. ¿Qué le gustaría hacer?',
  'app.pages.app.saveChangesAndOpenApp': 'Guardar cambios y abrir app',
  'app.pages.app.discardChangesAndOpenApp': 'Descartar cambios y abrir app',
  'app.pages.app.saveChangesAndCreateApp': 'Guardar cambios y crear app',
  'app.pages.app.discardChangesAndCreateApp': 'Descartar cambios y crear app',

  //sidebarMenu
  'app.pages.app.sidebarMenu.fileManager': 'Administrador de archivos',
  'app.pages.app.sidebarMenu.code': 'Código',
  'app.pages.app.sidebarMenu.interfaces': 'Interfaces',
  'app.pages.app.sidebarMenu.tools': 'Herramientas',
  'app.pages.app.sidebarMenu.scheduledTasks': 'Tareas programadas',
  'app.pages.app.sidebarMenu.myAccount': 'Mi cuenta',
  'app.pages.app.sidebarMenu.settings': 'Ajustes',
  'app.pages.app.sidebarMenu.securityOptions': 'Opciones de seguridad',
  'app.pages.app.sidebarMenu.users': 'Usuarios',
  'app.pages.app.sidebarMenu.departments': 'Departamentos',
  'app.pages.app.sidebarMenu.teams': 'Equipos',
  'app.pages.app.sidebarMenu.permissionByRole': 'Permisos por rol',
  'app.pages.app.sidebarMenu.applications': 'Aplicaciones',
  'app.pages.app.sidebarMenu.processManager': 'Procesos',
  'app.pages.app.sidebarMenu.home': 'Inicio',
  'app.pages.app.sidebarMenu.numberLocale': 'Formato numérico',
  'app.pages.app.sidebarMenu.language': 'Idioma',

  //home
  'app.pages.app.home.myPyplan': 'Mi Pyplan',
  'app.pages.app.home.openApps': 'Aplicaciones abiertas',
  'app.pages.app.home.workspaces': 'Espacios de trabajo',
  'app.pages.app.home.folders': 'Carpetas',
  'app.pages.app.home.instanceKilledSuccessfully': 'Instancia eliminada exitosamente',
  'app.pages.app.home.instanceChangedSuccessfully': 'Instancia cambiada exitosamente',
  //home.pyplanResources
  'app.pages.app.home.pyplanResources': 'Recursos Pyplan',
  'app.pages.app.home.pyplanResources.videos': 'Videos',
  'app.pages.app.home.pyplanResources.videosCaption': 'Ver Pyplan en acción',
  'app.pages.app.home.pyplanResources.documentation': 'Documentación',
  'app.pages.app.home.pyplanResources.documentationCaption': 'Pasos básicos',
  'app.pages.app.home.pyplanResources.examples': 'Ejemplos',
  'app.pages.app.home.pyplanResources.examplesCaption': 'de aplicaciones',

  //home.shortcutsPanel
  'app.pages.app.home.shortcutsPanel.tutorials': 'Tutoriales',
  'app.pages.app.home.shortcutsPanel.examples': 'Ejemplos',
  'app.pages.app.home.shortcutsPanel.demos': 'Demostraciones',
  'app.pages.app.home.shortcutsPanel.models': 'Modelos',
  'app.pages.app.home.shortcutsPanel.noModelFound':
    'No se abrieron modelos recientemente. Explora tus archivos o crea uno nuevo.',
  'app.pages.app.home.shortcutsPanel.noExamplesFound': 'No se encontraron ejemplos',
  'app.pages.app.home.shortcutsPanel.my_apps': 'Mis aplicaciones',
  'app.pages.app.home.shortcutsPanel.public_apps': 'Aplicaciones públicas',
  'app.pages.app.home.shortcutsPanel.recently_opened': 'Abiertas recientemente',
  'app.pages.app.home.shortcutsPanel.noPermissionsToViewApps':
    'No tienes permisos para ver aplicaciones. Por favor, contacte a un administrador.',

  //editProfile
  'app.pages.editProfile.editProfile': 'Editar perfil',
  'app.pages.editProfile.changePasswordAlert':
    'Si cambia su contraseña, su instancia se cerrará y deberá iniciar sesión nuevamente',
  'app.pages.editProfile.currentPassword': 'Contraseña actual',
  'app.pages.editProfile.currentPasswordIncorrect': 'La contraseña actual es incorrecta',

  //managers
  'app.pages.app.managers.firstPage': 'Primera',
  'app.pages.app.managers.prevPage': 'Anterior',
  'app.pages.app.managers.nextPage': 'Siguiente',
  'app.pages.app.managers.lastPage': 'Última',
  'app.pages.app.managers.cancel': 'Cancelar',
  'app.pages.app.managers.create': 'Crear',
  'app.pages.app.managers.update': 'Actualizar',
  'app.pages.app.managers.search': 'Buscar',
  'app.pages.app.managers.searchHelperText': 'Escriba al menos 3 caracteres',
  'app.pages.app.managers.companyManager': 'Administrador de compañías',
  'app.pages.app.managers.folder': 'Carpeta',
  'app.pages.app.managers.departmentManager': 'Administrador de Departamentos',
  'app.pages.app.managers.rowsPerPage': 'Filas por página',
  'app.pages.app.managers.company': 'Compañía',
  'app.pages.app.managers.company.companies': 'Compañías',
  'common.name.isRequired': 'El nombre es requerido',
  'app.pages.app.managers.company.notFound': 'Compañía no encontrada',
  'app.pages.app.managers.company.placeholder': 'Elija una compañía',
  'app.pages.app.managers.company.isRequired': 'La compañía es requerida',
  'app.pages.app.managers.roleManager': 'Administrador de roles',
  'app.pages.managers.invalidJSON': 'No es un JSON válido',
  'app.pages.managers.actions': 'Acciones',
  'app.pages.managers.departments': 'Departamentos',
  'app.pages.managers.mainDepartment': 'Departamento principal',
  'app.pages.managers.add': 'Agregar',
  'app.pages.managers.role': 'Rol',
  'app.pages.managers.roles': 'Roles',
  'app.pages.app.managers.back': 'Volver',
  'app.pages.app.managers.unkwownStep': 'Paso desconocido',
  'app.pages.app.managers.saveButton': 'Guardar',
  'app.pages.app.managers.nextButton': 'Siguiente',
  'app.pages.app.managers.companies': 'Compañías',
  'app.pages.managers.instances': 'Instancias',
  'app.pages.managers.password': 'Contraseña',
  'app.pages.managers.newPassword': 'Nueva contraseña',
  'app.pages.managers.confirmPassword': 'Confirmar contraseña',
  'app.pages.managers.passwordIsRequired': 'La contraseña es requerida',
  'app.pages.managers.ConfirmPasswordIsRequired': 'Confirmar contraseña es requerido',
  'app.pages.managers.passwordMinLength':
    'La contraseña es demasiada corta - debe tener  {number} caractéres mínimo',
  'app.pages.managers.passwordInvalidRequisites':
    'La contraseña debe tener al menos una mayúscula, una minúscula y un caracter especial',
  'app.pages.managers.passwordsNotMatch': 'Las contraseñas no coinciden',
  'app.pages.managers.send': 'Enviar',
  'app.pages.app.managers.delete': 'Eliminar',
  'app.pages.app.managers.createAppFromThis': 'Crear aplicación a partir de ésta',
  'app.pages.app.managers.applicationSuccessfullyCreated': 'Aplicación creada exitosamente en',
  'app.pages.app.managers.newFolder': 'Nueva carpeta',
  'app.pages.app.managers.rename': 'Renombrar',
  'app.pages.app.managers.set_permission': 'Configurar permisos',
  'app.pages.app.managers.duplicate': 'Duplicar',
  'app.pages.app.managers.openAsReadOnly': 'Abrir como sólo lectura',
  'app.pages.app.managers.copyAppToWorkspace': 'Copiar app en espacio de trabajo',
  'app.pages.app.managers.copyAppToTeam': 'Copiar app a un equipo',
  'app.pages.app.managers.copyAppToPublic': 'Copiar app a Public',
  'app.pages.app.managers.openInNewInstance': 'Abrir aplicación en nueva instancia',
  'app.pages.app.managers.downloadApp': 'Descargar aplicación',
  'app.pages.app.managers.appDownloaded': 'Aplicación descargada',
  'app.pages.app.managers.copyInterfaceId': 'Copiar id de la interfaz',
  'app.pages.app.managers.idCopied': 'Id copiado',
  'app.pages.app.managers.folderExists': 'La carpeta ya existe',

  // Notifications Manager
  'app.pages.app.notificationsManager.notificationsManager': 'Administrador de notificaciones',
  'app.pages.app.notificationsManager.addNotification': 'Agregar notificación',
  'app.pages.app.notificationsManager.editNotification': 'Editar notificación',
  'app.pages.app.notificationsManager.successChangedNotificationStatus':
    'Estado de notificación cambiado exitosamente',
  'app.pages.app.notificationsManager.removeNotification': 'Eliminar notificación',
  'app.pages.app.notificationsManager.removeNotificationConfirmation':
    '¿Estás seguro que deseas eliminar esta notificación?',
  'app.pages.app.notificationsManager.successRemovedNotification':
    'Notificación eliminada exitosamente',
  'app.pages.app.notificationsManager.successAddedNotification':
    'Notificación agregada exitosamente',
  'app.pages.app.notificationsManager.effectiveDate': 'Fecha de validez',
  'app.pages.app.notificationsManager.noNotifications': 'Sin notificaciones',
  'app.pages.app.notificationsManager.notificationTitle': 'Título de la notificación',
  'app.pages.app.notificationsManager.notificationDescription': 'Descripción de la notificación',
  'app.pages.app.notificationsManager.notification': 'Notificación',

  //companyManager
  'app.pages.app.companyManager.newCompany': 'Crear compañía',
  'app.pages.app.companyManager.editCompany': 'Actualizar compañía',
  'app.pages.app.companyManager.companyPreferences': 'Preferencias',
  'app.pages.app.companyManager.successChangedStatus': 'Estado cambiado con éxito',
  //companyManager.companyTable
  'app.pages.app.companyManager.companyTable.companyStatusColumn': 'Estado',
  'app.pages.app.companyManager.companyTable.companyTableAriaLabel': 'Tabla de compañías',
  'app.pages.app.companyManager.companyTable.companiesNotFound': 'No se han encontrado compañías',
  'app.pages.app.companyManager.hasSaml': 'Tiene configuración SAML',
  //companyManager.addOrEdit
  'app.pages.companyManager.addOrEdit.companyNameField': 'Nombre de la compañía',
  'app.pages.companyManager.addOrEdit.companyCodeField': 'Nombre de la carpeta',
  'app.pages.companyManager.addOrEdit.companyActivationCodeField': 'Código de activación',
  'app.pages.companyManager.addOrEdit.copiedCompanyActivationCode':
    'Código de activación - Copiado ✓',
  'app.pages.companyManager.addOrEdit.companyLicenseField': 'Licencia',
  'app.pages.companyManager.addOrEdit.companyLogo': 'Logo de la compañía',
  'app.pages.companyManager.addOrEdit.dropzoneFirstRow':
    'Arrastra y suelta un logo para la compañía aquí o',
  'app.pages.companyManager.addOrEdit.dropzoneLinkRow': 'Explora tus archivos',
  'app.pages.companyManager.addOrEdit.companyFieldRequiredError': 'Campo requerido',
  'app.pages.companyManager.addOrEdit.companyFieldMaxLengthError':
    'No puede exceder los 255 caracteres',
  'app.pages.companyManager.addOrEdit.companyFieldPatternError':
    'Caracteres Inválidos. Utilizar únicamente letras en minúsculas, letras en mayúsculas, números del 0 al 9 y guiones bajos o medios (_-)',
  'app.pages.companyManager.addOrEdit.companyFolderAlreadyExists':
    'Ya existe una carpeta con ese nombre, por favor elije otro código',
  'app.pages.companyManager.addOrEdit.copyToClipboard': 'Copiar al portapapeles',
  'app.pages.companyManager.addOrEdit.copiedData': 'Datos copiados al portapapeles',
  'app.pages.companyManager.addOrEdit.invalidFileExtension':
    'Archivo inválido. Se requiere una imagen',
  'app.pages.app.companyManager.successAddedCompany': 'Compañía creada con éxito',
  'app.pages.app.companyManager.successEditedCompany': 'Compañía editada con éxito',
  'app.pages.app.companyManager.removeLicense': 'Remover licencia',

  //Department Manager
  'app.pages.DepartmentPage.addDepartment': 'Agregar departamento',
  'app.pages.DepartmentPage.editDepartment': 'Editar departamento',
  'app.pages.DepartmentPage.engineDefinitions': 'Definiciones del motor',
  'app.pages.DepartmentPage.loginAction': 'Acciones al loguearse',
  'app.pages.DepartmentPage.deniedItems': 'Accesos denegados',
  'app.pages.DepartmentPage.name.maxLengthError':
    'El tamaño máximo del nombre es de {number} caracteres',
  'app.pages.DepartmentPage.department.notFound': 'No se han encontrado departamentos',
  'app.pages.DepartmentPage.department.created': 'Departamento creado',
  'app.pages.DepartmentPage.department.updated': 'Departamento actualizado',
  'app.pages.DepartmentPage.departmentManagerTable': 'Tabla de administrador de departamentos',
  'app.pages.DepartmentPage.addLoginAction': 'Agregar acción al loguearse',
  'app.pages.DepartmentPage.department': 'Departamento',

  //Role Manager
  'app.pages.RolePage.addRole': 'Agregar rol',
  'app.pages.RolePage.editRole': 'Editar rol',
  'app.pages.RolePage.role.notFound': 'No se han encontrado roles',
  'app.pages.RolePage.role.permission.template': 'Plantilla de Permisos',
  'app.pages.RolePage.role.permission.administrator': 'Admininistrador',
  'app.pages.RolePage.role.permission.appAdministrator': 'Administrador de App',
  'app.pages.RolePage.role.permission.creatorWithPublicAccess': 'Creador con Acceso al Public',
  'app.pages.RolePage.role.permission.creator': 'Creador',
  'app.pages.RolePage.role.permission.explorer': 'Navegador',
  'app.pages.RolePage.role.permission.viewer': 'Visualizador',
  'app.pages.RolePage.role.permission.loginOnlyUser': 'Usuario con Login Únicamente',
  'app.pages.RolePage.role.permission.override': 'Estás por sobreescribir todos los permisos',
  'app.pages.RolePage.role.created': 'Rol creado',
  'app.pages.RolePage.role.updated': 'Rol actualizado',
  'app.pages.RolePage.name.maxLengthError':
    'El nombre del rol no puede ser mayor a {number} caracteres',
  'app.pages.RolePage.name.exist': 'El nombre del rol ya existe',
  'app.pages.RolePage.roleManagerTable': 'Tabla de administrador de roles',

  //notFoundPage
  'app.components.notFoundPage.message': 'La página que buscas no existe',
  'app.components.notFoundPage.orGoTo': 'o ir a',
  'app.components.notFoundPage.returnToThe': 'Volver a la',
  'app.components.notFoundPage.previousPage': 'página anterior',
  'app.components.notFoundPage.homePage': 'página de inicio',

  //Company Preferences
  'app.pages.app.companyManager.companyPreferences.notFound': 'Preferencias no encontradas',
  'app.pages.app.companyManager.companyPreferences.title': 'Preferencias de la compañia ',
  'app.pages.app.companyManager.companyPreferences.copyDefaultValue': 'Copiar valor por defecto',
  'app.pages.app.companyManager.companyPreferences.defaultValueCopied': 'Valor por defecto copiado',
  'app.pages.app.companyManager.companyPreferences.preferenceUpdated': 'Preferencia actualizada',
  'app.pages.app.companyManager.companyPreferences.preferenceCreated': 'Preferencia creada',
  'app.pages.app.companyManager.companyPreferences.preferenceDelete': 'Preferencia removida',
  'app.pages.app.companyManager.companyPreferences.saveCustomValue': 'Guardar valor personalizado',
  'app.pages.app.companyManager.companyPreferences.removeCustomValue':
    'Remover valor personalizado',

  //Permissions by role
  'app.pages.app.permissionsByRole': 'Permisos por rol',
  'app.pages.app.permissionsByRole.permissionActivated': 'Permiso activado',
  'app.pages.app.permissionsByRole.permissionDeactivated': 'Permiso desactivado',
  'app.pages.app.permissionsByRole.allPermissionsActivated':
    'Todos los permisos para {name} fueron activados',
  'app.pages.app.permissionsByRole.permission': 'Permiso',
  'app.pages.app.permissionsByRole.permissionsUpdated': 'Permisos actualizados',

  //team manager
  'app.pages.app.teamManager.team': 'Administrador de equipos',
  'app.pages.app.teamManager.addTeam': 'Crear equipo',
  'app.pages.app.teamManager.editTeam': 'Editar equipo',
  'app.pages.app.teamManager.accessLevel': 'Niveles de acceso',
  'app.pages.app.teamManager.teamDeleted': 'Equipo {name} borrado',
  'app.pages.app.teamManager.deleteTeam': 'Borrar equipo',
  'app.pages.app.teamManager.areYouSureToDeleteTeam': '¿Estás seguro de borrar el equipo {name}?',
  'app.pages.app.teamManager.departmentsAsociated':
    'Esta asociado con los siguientes departamentos:',
  'app.pages.app.teamManager.readOnly': 'Sólo lectura',
  'app.pages.app.teamManager.readAndWrite': 'Lectura y escritura',
  'app.pages.app.teamManager.updateTeam': 'Actualizar equipo',
  'app.pages.app.teamManager.createTeam': 'Crear equipo',
  'app.pages.app.teamManager.teamDepartments': 'No hay departmentos',
  'app.pages.app.teamManager.teamUpdated': 'Equipo actualizado',
  'app.pages.app.teamManager.teamCreated': 'Equipo creado',
  'app.pages.teamManager.name.maxLengthError':
    'El nombre del grupo no puede ser mayor a {number} caracteres',
  'app.pages.teamManager.teamsNotFound': 'No se encontraron equipos',
  //User Manager
  'app.pages.UserPage.userManager': 'Administrador de usuarios',
  'app.pages.UserPage.addUser': 'Agregar usuario',
  'app.pages.UserPage.editUser': 'Editar usuario',
  'app.pages.UserPage.email': 'Correo electrónico',
  'app.pages.UserPage.login': 'Nombre de usuario',
  'app.pages.UserPage.usersNotFound': 'Usuarios no encontrados',
  'app.pages.UserPage.changePassword': 'Cambiar contraseña',
  'app.pages.UserPage.manageCompanies': 'Administrar compañias',
  'app.pages.UserPage.passwordChanged': 'Contraseña cambiada',
  'app.pages.UserPage.downloadReport': 'Descargar Reporte de usuarios',
  'app.pages.UserPage.reportsDownloaded': 'Reporte de usuarios descargado',
  'app.pages.UserPage.firstName': 'Nombre',
  'app.pages.UserPage.firstNameIsRequired': 'El nombre es requerido',
  'app.pages.UserPage.firstNameMaxLength': 'El tamaño maximo del nombre es {number} caracteres',
  'app.pages.UserPage.firstNamePlaceholder': 'Ingrese su nombre',
  'app.pages.UserPage.lastNamePlaceholder': 'Ingrese su apellido',
  'app.pages.UserPage.lastNameIsRequired': 'El apellido es requerido',
  'app.pages.UserPage.lastNameMaxLength': 'El tamaño maximo del apellido es {number} caracteres',
  'app.pages.UserPage.emailPlaceholder': 'Ingrese su correo electrónico',
  'app.pages.UserPage.emailIsInvalid': 'Correo electrónico incorrecto',
  'app.pages.UserPage.emailContainingÑ': 'El email no puede contener "Ñ"',
  'app.pages.UserPage.emailIsRequired': 'El correo electrónico es requerido',
  'app.pages.UserPage.emailAlreadyExist': 'El correo electrónico ya existe',
  'app.pages.UserPage.lastName': 'Apellido',
  'app.pages.UserPage.userNameIsRequired': 'El nombre de usuario es requerido',
  'app.pages.UserPage.userNamePlaceholder': 'Ingrese su nombre de usuario',
  'app.pages.UserPage.passwordPlaceholder': 'Ingrese su contraseña',
  'app.pages.UserPage.usernameAlreadyExist': 'El nombre de usuario ya existe',
  'app.pages.UserPage.usernameInvalid':
    'El nombre de usuario puede tener "@", "+", "-", "_" o "." caracteres. Los espacios en blanco no están permitidos.',
  'app.pages.UserPage.steps.userInformation': 'Información del usuario',
  'app.pages.UserPage.steps.roleAndCompanySelection': 'Selección de rol y compañía',
  'app.pages.UserPage.steps.roleAndCompanySelection.departmentPlaceholder':
    'Por favor elige un departamento',
  'app.pages.UserPage.steps.roleAndCompanySelection.rolePlaceholder': 'Por favor elige un rol',
  'app.pages.UserPage.steps.roleAndCompanySelection.addCompany': 'Agregar compañia',
  'app.pages.UserPage.steps.roleAndCompanySelection.removeCompany': 'Remover compañia',
  'app.pages.UserPage.steps.roleAndCompanySelection.noCompaniesAdded':
    'No hay compañias agregadas, por favor agrega una',
  'app.pages.UserPage.steps.roleAndCompanySelection.roleWarning':
    'Este rol aplica a todas las compañías',
  'app.pages.UserPage.toggleActiveUser.errorNoRoles':
    '{user} no tiene un rol para la compañía {company}',
  'app.pages.UserPage.toggleActiveUser.activated': 'Usuario activado',
  'app.pages.UserPage.toggleActiveUser.desactivated': 'Usuario desactivado',
  'app.pages.UserPage.userUnlocked': 'Usuario desbloqueado',
  'app.pages.UserPage.userCreated': 'Usuario creado',
  'app.pages.UserPage.userUpdated': 'Usuario actualizado',
  'app.pages.UserPage.errorCompanyWithNoRoles': 'Hay una compañía sin roles',
  'app.pages.UserPage.errorCompanyWithNoDepartments': 'Hay una compañía sin departamentos',
  'app.pages.UserPage.deleteUser': 'Eliminar usuario',
  'app.pages.UserPage.areYouSureToDeleteUser': '¿Estás seguro de eliminar el usuario {name}?',
  'app.pages.UserPage.userDeleted': 'Usuario eliminado',
  'app.pages.UserPage.changeStatus': 'Cambiar estado',
  'app.pages.UserPage.unlockUser': 'Desbloquear usuario',
  'app.pages.UserPage.ChangePasswordLogin': 'Modificar contraseña al iniciar sesión',
  'app.pages.UserPage.enableMfa': 'Habilitar autenticación de múltiples factores',
  'app.pages.UserPage.setNewQrCode': 'Establecer nuevo código QR',
  'app.pages.UserPage.validateSetNewQRCode':
    'Se configurará un nuevo código QR para el usuario {user}. ¿Desea continuar?',
  'app.pages.UserPage.qrCodeSuccesfullySeted': 'Nuevo código QR establecido',

  //Code Diagram Nodes
  'app.pages.Code.Diagram.Nodes.variable': 'Variable',
  'app.pages.Code.Diagram.Nodes.datareading': 'Lectura de datos',
  'app.pages.Code.Diagram.Nodes.button': 'Botón',
  'app.pages.Code.Diagram.Nodes.index': 'Índice',
  'app.pages.Code.Diagram.Nodes.module': 'Módulo',
  'app.pages.Code.Diagram.Nodes.inputscalar': 'Escalar',
  'app.pages.Code.Diagram.Nodes.inputselector': 'Selector',
  'app.pages.Code.Diagram.Nodes.inputchoice': 'Opción',
  'app.pages.Code.Diagram.Nodes.inputdataframe': 'Tabla de datos',
  'app.pages.Code.Diagram.Nodes.inputdataarray': 'Matriz de datos',
  'app.pages.Code.Diagram.Nodes.inputcube': 'Cubo',
  'app.pages.Code.Diagram.Nodes.text': 'Texto',
  'app.pages.Code.Diagram.Nodes.generic': 'Genérico',
  'app.pages.Code.Diagram.Nodes.form': 'Formulario',
  'app.pages.Code.Diagram.Nodes.inputInitialName': 'Nombre del nodo',
  'app.pages.Code.Diagram.Nodes.inputData': 'Entrada de datos',
  'app.pages.Code.Diagram.Nodes.chooseInputType': 'Elija el tipo de entrada de datos',
  'app.pages.Code.Diagram.Nodes.report': 'Reporte',
  'app.pages.Code.Diagram.Nodes.outputnode': 'Salida',
  'app.pages.Code.Diagram.Nodes.reportwizard': 'Wizard de reporte',

  //Code Diagram Menu
  'app.pages.Code.Diagram.Menu.hideMenu': 'Ocultar menu',
  'app.pages.Code.Diagram.Menu.wizards': 'Asistentes',
  'app.pages.Code.Diagram.Menu.importModule': 'Importar módulo',
  'app.pages.Code.Diagram.Menu.importModule.steps.selectModule': 'Selección del módulo',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType': 'Tipo de importación',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType.switch': 'Cambiar',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType.merge': 'Fusionar',
  'app.pages.Code.Diagram.Menu.importModule.sucessful': 'Módulo importado exitosamente',
  'app.pages.Code.Diagram.Menu.importModule.hasOutputsOutsideItsModule':
    'Tiene salidas fuera de su módulo',
  //Code Diagram Delete dialog
  'app.pages.Code.Diagram.DeleteDialog.deleteNodes': '¿Eliminar nodos?',
  'app.pages.Code.Diagram.DeleteDialog.confirmationtext':
    '¿Estás seguro de eliminar {number} nodos seleccionados?',
  //Node Properties Dialog
  'app.pages.Code.Diagram.Nodes.properties.title': 'Título del nodo',
  'app.pages.Code.Diagram.Nodes.properties.id': 'Identificador del nodo',
  'app.pages.Code.Diagram.Nodes.properties.nodeClass': 'Tipo de nodo',
  'app.pages.Code.Diagram.Nodes.properties.units': 'Unidades',
  'app.pages.Code.Diagram.Nodes.properties.evaluateOnStart': 'Evaluar al inicio',
  'app.pages.Code.Diagram.Nodes.properties.releaseMemory': 'Liberar memoria',
  'app.pages.Code.Diagram.Nodes.properties.prefixIdentifier': 'Prefijo del módulo',
  'app.pages.Code.Diagram.Nodes.properties.idHelperText':
    'El identificador debe comenzar con una letra, y sólo puede contener letras minúsculas, números y guiones bajos',
  'app.pages.Code.Diagram.Nodes.properties.idRequired':
    'El identificador del nodo no puede estar vacío',
  'app.pages.Code.Diagram.Nodes.properties.prefixIdHelperText':
    'El prefijo del módulo debe comenzar con una letra, y sólo puede contener letras minúsculas, números y guiones bajos',
  'app.pages.Code.Diagram.Nodes.properties.hierarchyMap': 'Mapa de jerarquía',
  'app.pages.Code.Diagram.Nodes.inputs': 'Entradas',
  'app.pages.Code.Diagram.Nodes.outputs': 'Salidas',
  'app.pages.Code.Diagram.Nodes.general': 'General',
  'app.pages.Code.Diagram.Nodes.metadata': 'Metadatos',
  'app.pages.Code.Diagram.Nodes.creationDate': 'Fecha de creación',
  'app.pages.Code.Diagram.Nodes.lastModification': 'Última modificación',
  'app.pages.Code.Diagram.Nodes.lastUserThatModified': 'Último usuario que modificó',
  'app.pages.Code.Diagram.Nodes.docs': 'Documentación',
  'app.pages.Code.Diagram.Nodes.noInputs': 'No hay entradas',
  'app.pages.Code.Diagram.Nodes.noOutputs': 'No hay salidas',
  'app.pages.Code.Diagram.Nodes.noDocs': 'No hay documentación para esta sección.',
  'app.pages.Code.Diagram.Nodes.editDocs': 'Editar docs',
  'app.pages.Code.Diagram.Nodes.docsEdited': 'Documentación editada',
  'app.pages.Code.Diagram.Nodes.selectNodeToShowDocs':
    'Seleccione un nodo para mostrar la documentación',
  'app.pages.Code.itemHeader.saveDefaultView': 'Guardar vista por defecto',
  //Context Menu
  'app.pages.Code.Diagram.ContextMenu.resize': 'Redimensionar nodos como {string}',
  'app.pages.Code.Diagram.ContextMenu.alignment': 'Alinear nodos a {string}',
  'app.pages.Code.Diagram.ContextMenu.showInputs': 'Mostrar entradas',
  'app.pages.Code.Diagram.ContextMenu.showOutputs': 'Mostrar salidas',
  'app.pages.Code.Diagram.ContextMenu.sizes.width': 'Mismo ancho',
  'app.pages.Code.Diagram.ContextMenu.sizes.height': 'Misma altura',
  'app.pages.Code.Diagram.ContextMenu.sizes.size': 'Mismo tamaño',
  'app.pages.Code.Diagram.ContextMenu.alignment.top': 'Bordes superiores',
  'app.pages.Code.Diagram.ContextMenu.alignment.centerH': 'Centrar horizontalmente',
  'app.pages.Code.Diagram.ContextMenu.alignment.centerV': 'Centrar verticalmente',
  'app.pages.Code.Diagram.ContextMenu.alignment.bottom': 'Bordes inferiores',
  'app.pages.Code.Diagram.ContextMenu.alignment.right': 'Bordes derechos',
  'app.pages.Code.Diagram.ContextMenu.alignment.left': 'Bordes izquierdos',
  'app.pages.Code.Diagram.ContextMenu.exportModule': 'Exportar módulo',
  'app.pages.Code.Diagram.ContextMenu.moduleExported': 'Módulo exportado',
  'app.pages.Code.Diagram.ContextMenu.setRestriction': 'Establecer restricción',
  'app.pages.Code.Diagram.ContextMenu.pinNode': 'Fijar nodo',
  'app.pages.Code.Diagram.ContextMenu.unpinNode': 'Quitar nodo',
  'app.pages.Code.Diagram.ContextMenu.apiEndpoint': 'Obtener API URL',
  'app.pages.Code.Diagram.apiEndpoint.endpointFor': 'API URL para: ',
  'app.pages.Code.Diagram.apiEndpoint.shareInstance': 'Compartir instancia',
  'app.pages.Code.Diagram.apiEndpoint.apiURLcopied': 'API URL copiada ',
  'app.pages.Code.Diagram.apiEndpoint.message':
    'y envía los parámetros de la función como un objeto JSON en el cuerpo de la petición.',
  'app.pages.Code.Diagram.ContextMenu.openWizard': 'Abrir asistente',
  'app.pages.Code.Diagram.ContextMenu.compareNodes': 'Comparar nodos',
  'app.pages.Code.Diagram.ContextMenu.handlingDataWidget': 'Manipulación de datos',
  'app.pages.Code.Diagram.Wizards.dropDuplicates': 'Eliminar duplicados',
  'app.pages.Code.Diagram.ContextMenu.dataSystemNodeNoData': 'Nodo de sistema sin datos',

  //Code Diagram Wizards
  'app.pages.Code.Diagram.Wizards.openWizard': 'Abrir asistente',
  'app.pages.Code.Diagram.Wizards.selectColumns': 'Seleccionar columnas',
  'app.pages.Code.Diagram.Wizards.editInExcel': 'Editar en Excel',
  'app.pages.Code.Diagram.Wizards.selectRows': 'Seleccionar filas',
  'app.pages.Code.Diagram.Wizards.name': 'Nombre de columna',
  'app.pages.Code.Diagram.Wizards.isIndex': 'Es índice',
  'app.pages.Code.Diagram.Wizards.dtype': 'Tipo de dato',
  'app.pages.Code.Diagram.Wizards.editTableDefinition': 'Editar definición de Tabla',
  'app.pages.Code.Diagram.Wizards.removeColumn': 'Borrar columna',
  'app.pages.Code.Diagram.Wizards.addNewColumn': 'Agregar nueva columna',
  'app.pages.Code.Diagram.Wizards.column': 'Columna',
  'app.pages.Code.Diagram.Wizards.editCubeDefinition': 'Editar definición del Cubo',
  'app.pages.Code.Diagram.Wizards.deleteCubeData': 'Eliminar datos del Cubo',
  'app.pages.Code.Diagram.Wizards.deleteCubeDataMessage':
    '¿Estás seguro de que deseas eliminar los datos del Cubo de la base de datos?',
  'app.pages.Code.Diagram.Wizards.deleteCubeDataConfirm':
    'Los datos del Cubo se eliminaron correctamente de la base de datos',
  'app.pages.Code.Diagram.Wizards.availableIndexes': 'Índices disponibles',
  'app.pages.Code.Diagram.Wizards.selectedIndexes': 'Índices seleccionados',
  'app.pages.Code.Diagram.Wizards.filter': 'Filtrar',
  'app.pages.Code.Diagram.Wizards.defaultValue': 'Valor por defecto',
  'app.pages.Code.Diagram.Wizards.defaultValueType': 'Tipo de valor por defecto',
  'app.pages.Code.Diagram.Wizards.scalar': 'Escalar',
  'app.pages.Code.Diagram.Wizards.model_node': 'Nodo del modelo',
  'app.pages.Code.Diagram.Wizards.app_node': 'Nodo de la app',
  'app.pages.Code.Diagram.Wizards.node_id': 'El identificador del nodo ya existe, seleccione otro',
  'app.pages.Code.Diagram.Wizards.calculatedField': 'Campo calculado',
  'app.pages.Code.Diagram.Wizards.setIndex': 'Establecer índice',
  'app.pages.Code.Diagram.Wizards.groupAgregate': 'Agrupar/agregar',
  'app.pages.Code.Diagram.Wizards.createindex': 'Crear Índice',
  'app.pages.Code.Diagram.Wizards.createcube': 'Crear Cubo',
  'app.pages.Code.Diagram.Wizards.form': 'Formulario',
  'app.pages.Code.Diagram.Wizards.report': 'Reporte',
  'app.pages.Code.Diagram.Wizards.editFormDefinition': 'Editar definición del Formulario',
  'app.pages.Code.Diagram.Wizards.deleteFormData': 'Eliminar datos del Formulario',
  'app.pages.Code.Diagram.Wizards.deleteFormDataMessage':
    '¿Estás seguro de que deseas eliminar los datos del Formulario de la base de datos?',
  'app.pages.Code.Diagram.Wizards.deleteFormDataConfirm':
    'Los datos del Formulario se eliminaron correctamente de la base de datos',
  'app.pages.Code.Diagram.Wizards.formFieldError': 'Id o título de campo no puede ser "id"',
  'app.pages.Code.Diagram.Wizards.fieldId': 'ID de campo',
  'app.pages.Code.Diagram.Wizards.fieldTitle': 'Título de campo',
  'app.pages.Code.Diagram.Wizards.columnType': 'Tipo de columna',
  'app.pages.Code.Diagram.Wizards.hidden': 'Oculto',
  'app.pages.Code.Diagram.Wizards.readOnly': 'Sólo lectura',
  'app.pages.Code.Diagram.Wizards.formula': 'Fórmula',
  'app.pages.Code.Diagram.Wizards.values': 'Valores',
  'app.pages.Code.Diagram.Wizards.filterRowsByDomain': 'Filtrar filas por dominio',
  'app.pages.Code.Diagram.Wizards.strict': 'Estricto',
  'app.pages.Code.Diagram.Wizards.relatedMap': 'Mapa relacionado',
  'app.pages.Code.Diagram.Wizards.relatedMapColumnValues': 'Columna con valores',
  'app.pages.Code.Diagram.Wizards.relatedColumns': 'Columnas relacionadas',
  'app.pages.Code.Diagram.Wizards.fromNode': 'Desde nodo',
  'app.pages.Code.Diagram.Wizards.fromNodeDetails':
    'El resultado del nodo debe ser una función que recibe un DataFrame de Pandas como parámetro y devuelve un DataFrame de Pandas',
  'app.pages.Code.Diagram.Wizards.advancedOptions': 'Opciones avanzadas',
  'app.pages.Code.Diagram.Wizards.appNode': 'Nodo de la app',
  'app.pages.Code.Diagram.Wizards.typeNewValues':
    'Escribir nuevos valores. Presionar Enter para confirmar',
  'app.pages.Code.Diagram.Wizards.columnType.input': 'Input',
  'app.pages.Code.Diagram.Wizards.columnType.checkbox': 'Checkbox',
  'app.pages.Code.Diagram.Wizards.columnType.selector': 'Selector',
  'app.pages.Code.Diagram.Wizards.columnType.related_selector': 'Selector relacionado',
  'app.pages.Code.Diagram.Wizards.columnType.calc': 'Cálculo',
  'app.pages.Code.Diagram.Wizards.columnType.dataframe_column': 'Columna de DataFrame',
  'app.pages.Code.Diagram.Wizards.columnType.system': 'Sistema',
  'app.pages.Code.Diagram.Wizards.tableName': 'Nombre de la tabla',
  'app.pages.Code.Diagram.Wizards.formSettings': 'Opciones de formulario',
  'app.pages.Code.Diagram.Wizards.editFormSettings': 'Editar opciones de formulario',
  'app.pages.Code.Diagram.Wizards.addNewField': 'Agregar nuevo campo',
  'app.pages.Code.Diagram.Wizards.addNewItem': 'Agregar nuevo elemento',
  'app.pages.Code.Diagram.Wizards.databaseEngine': 'Motor de base de datos',
  'app.pages.Code.Diagram.Wizards.databaseConnection': 'Conexión a base de datos',
  'app.pages.Code.Diagram.Wizards.databasePath': 'Ruta a base de datos',
  'app.pages.Code.Diagram.Wizards.host': 'Host',
  'app.pages.Code.Diagram.Wizards.database': 'Base de datos',
  'app.pages.Code.Diagram.Wizards.databaseCustomURL': 'URL personalizada',
  'app.pages.Code.Diagram.Wizards.initialEmptyRows': 'Filas vacías iniciales',
  'app.pages.Code.Diagram.Wizards.allowAddRows': 'Permitir agregar filas',
  'app.pages.Code.Diagram.Wizards.allowRemoveRows': 'Permitir eliminar filas',
  'app.pages.Code.Diagram.Wizards.allowAddNewColumns': 'Permitir agregar nuevas columnas',
  'app.pages.Code.Diagram.Wizards.fixedColumns': 'Columnas fijas',
  'app.pages.Code.Diagram.Wizards.formRecreateTableMessage':
    'Todos los datos del formulario se borrarán y se volverá a crear desde cero.',
  'app.pages.Code.Diagram.Wizards.formSubmitConfirmation':
    'Se sobreescribirá la definición del formulario.',
  'app.pages.Code.Diagram.Wizards.fromDataFrame': 'Desde DataFrame',
  'app.pages.Code.Diagram.Wizards.sourceDataFrameNode': 'Nodo con DataFrame de origen',
  'app.pages.Code.Diagram.Wizards.sourceDataFrameIdColumn': 'Columna ID de DataFrame de origen',
  'app.pages.Code.Diagram.Wizards.removeOldRows': 'Borrar filas antiguas',
  'app.pages.Code.Diagram.Wizards.newFieldName': 'Nombre del nuevo campo',
  'app.pages.Code.Diagram.Wizards.newItemName': 'Nombre del nuevo elemento',
  'app.pages.Code.Diagram.Wizards.expression': 'Expresión',
  'app.pages.Code.Diagram.Wizards.fieldList': 'Campos disponibles',
  'app.pages.Code.Diagram.Wizards.agregate': 'Funciones de agregación',
  'app.pages.Code.Diagram.Wizards.groupBy': 'Agrupar por',
  'app.pages.Code.Diagram.Wizards.filterDataFrame': 'Filtrar DataFrame',
  'app.pages.Code.Diagram.Wizards.condition': 'Condición',
  'app.pages.Code.Diagram.Wizards.addNewFilter': 'Agregar nuevo filtro',
  'app.pages.Code.Diagram.Wizards.valueIsRequired': 'El valor es requerido',
  'app.pages.Code.Diagram.Wizards.mustHaveAtLeastValues': 'Debe tener al menos {number} valor/es',
  'app.pages.Code.Diagram.Wizards.filterDataArray': 'Filtrar DataArray',
  'app.pages.Code.Diagram.Wizards.dimension': 'Dimensión',
  'app.pages.Code.Diagram.Wizards.editIndex': 'Editar índice',
  'app.pages.Code.Diagram.Wizards.itemList': 'Listado de items',
  'app.pages.Code.Diagram.Wizards.count': 'Cantidad',
  'app.pages.Code.Diagram.Wizards.insertAbove': 'Insertar fila arriba',
  'app.pages.Code.Diagram.Wizards.insertBelow': 'Insertar fila abajo',
  'app.pages.Code.Diagram.Wizards.removeRow': 'Eliminar fila',
  'app.pages.Code.Diagram.Wizards.index': 'Índice',
  'app.pages.Code.Diagram.Wizards.renameIndex': 'Renombrar índice',
  'app.pages.Code.Diagram.Wizards.files': 'Archivos',
  'app.pages.Code.Diagram.Wizards.databases': 'Bases de datos',
  'app.pages.Code.Diagram.Wizards.online': 'En línea',
  'app.pages.Code.Diagram.Wizards.dataReadingNode': 'Nodo de lectura de datos',
  'app.pages.Code.Diagram.Wizards.readCsvFile': 'Leer archivo CSV',
  'app.pages.Code.Diagram.Wizards.readExcelFile': 'Leer archivo Excel',
  'app.pages.Code.Diagram.Wizards.dataPreview': 'Vista previa de datos',
  'app.pages.Code.Diagram.Wizards.schema': 'Esquema',
  'app.pages.Code.Diagram.Wizards.csvDataSource': 'Fuente de datos CSV',
  'app.pages.Code.Diagram.Wizards.excelDataSource': 'Fuente de datos Excel',
  'app.pages.Code.Diagram.Wizards.backStep': 'Regresar',
  'app.pages.Code.Diagram.Wizards.nextStep': 'Siguiente',
  'app.pages.Code.Diagram.Wizards.chooseDataSource': 'Seleccione una fuente de datos',
  'app.pages.Code.Diagram.Wizards.selectFile': 'Seleccione archivo desde el servidor',
  'app.pages.Code.Diagram.Wizards.selectFileFromUrl': 'Seleccione archivo desde URL',
  'app.pages.Code.Diagram.Wizards.encoding': 'Codificación',
  'app.pages.Code.Diagram.Wizards.separator': 'Separador',
  'app.pages.Code.Diagram.Wizards.dataSetSample': 'Muestra del conjunto de datos',
  'app.pages.Code.Diagram.Wizards.sheetName': 'Nombre de la hoja',
  'app.pages.Code.Diagram.Wizards.definedRanges': 'Rangos definidos',
  'app.pages.Code.Diagram.Wizards.customRange': 'Rango personalizado',
  'app.pages.Code.Diagram.Wizards.setNewHeaderFirstRow': 'Setear encabezado con primera fila',
  'app.pages.Code.Diagram.Wizards.exampleCustomRange': 'Ejemplo: A1:B10',
  'app.pages.Code.Diagram.Wizards.sortDataFrame': 'Ordenar DataFrame',
  'app.pages.Code.Diagram.Wizards.criteria': 'Criterio',
  'app.pages.Code.Diagram.Wizards.sort': 'Ordenar',
  'app.pages.Code.Diagram.Wizards.ascending': 'Ascendente',
  'app.pages.Code.Diagram.Wizards.descending': 'Descendente',
  'app.pages.Code.Diagram.Wizards.addNewOrderCondition': 'Agregar nueva condición de orden',
  'app.pages.Code.Diagram.Wizards.indexOptions': 'Opciones de índice',
  'app.pages.Code.Diagram.Wizards.transform': 'Transformar',
  'app.pages.Code.Diagram.Wizards.toDataFrame': 'A DataFrame',
  'app.pages.Code.Diagram.Wizards.changeIndex': 'Cambiar índice',
  'app.pages.Code.Diagram.Wizards.oldIndex': 'Índice anterior',
  'app.pages.Code.Diagram.Wizards.newIndex': 'Nuevo índice',
  'app.pages.Code.Diagram.Wizards.compareMode': 'Modo de comparación',
  'app.pages.Code.Diagram.Wizards.searchIndex': 'Buscar índice',
  'app.pages.Code.Diagram.Wizards.byPosition': 'Por posición',
  'app.pages.Code.Diagram.Wizards.byValue': 'Por valor',
  'app.pages.Code.Diagram.Wizards.list': 'Lista',
  'app.pages.Code.Diagram.Wizards.rangeType': 'Tipo de rango',
  'app.pages.Code.Diagram.Wizards.frequency': 'Frecuencia',
  'app.pages.Code.Diagram.Wizards.cumulative': 'Acumulativo',
  'app.pages.Code.Diagram.Wizards.periods': 'Periodos',
  'app.pages.Code.Diagram.Wizards.stringPrefix': 'Prefijo de cadena',
  'app.pages.Code.Diagram.Wizards.to': 'A',
  'app.pages.Code.Diagram.Wizards.steps': 'Pasos',
  'app.pages.Code.Diagram.Wizards.enterNumericalValue': 'Ingrese un valor numérico',
  'app.pages.Code.Diagram.Wizards.invalidDate':
    'Fecha inválida, por favor introduzca una fecha con formato: AAAA-MM-DD',
  'app.pages.Code.Diagram.Wizards.startDateIsRequired': 'Fecha de inicio es requerida',
  'app.pages.Code.Diagram.Wizards.endDateIsRequired': 'Fecha de fin es requerida',
  'app.pages.Code.Diagram.Wizards.yearly': 'Anual',
  'app.pages.Code.Diagram.Wizards.monthly': 'Mensual',
  'app.pages.Code.Diagram.Wizards.weekly': 'Semanal',
  'app.pages.Code.Diagram.Wizards.daily': 'Diario',
  'app.pages.Code.Diagram.Wizards.saveLabels': 'Guardar etiquetas',
  'app.pages.Code.Diagram.Wizards.nestedHeaders': 'Encabezados anidados',
  'app.pages.Code.Diagram.Wizards.nestedHeadersTypeNewValues':
    'Escribir nuevos encabezados anidados. Presionar Enter para confirmar',
  'app.pages.Code.Diagram.Wizards.deleteAndRecreateTable': 'Borrar y recrear tabla',
  'app.pages.Code.Diagram.Wizards.itemName': 'Nombre de elemento',
  'app.pages.Code.Diagram.Wizards.editReportDefinition': 'Editar definición de Reporte',
  'app.pages.Code.Diagram.Wizards.itemsFromIndex': 'Elementos desde índice',
  'app.pages.Code.Diagram.Wizards.indexNode': 'Nodo índice',
  'app.pages.Code.Diagram.Wizards.addItemsFromIndex': 'Agregar elementos desde índice',
  'app.pages.Code.Diagram.Wizards.asNegative': 'En negativo',
  'app.pages.Code.Diagram.Wizards.itemIsNotInItemsList':
    'El elemento "{item}" no existe en la lista de elementos del nodo "{nodeId}"',
  'app.pages.Code.Diagram.Wizards.reportSubmitConfirmation':
    'Se sobreescribirá la definición del reporte.',
  'app.pages.Code.Diagram.Wizards.outputType': 'Tipo de salida',
  'app.pages.Code.Diagram.Wizards.removeNonExistingCombinations':
    'Eliminar combinaciones inexistentes',
  'app.pages.Code.Diagram.Wizards.allowDimensionChanges': 'Permitir cambio de dimensiones',
  'app.pages.Code.Diagram.Wizards.confirmWizardChanges': 'Confirmar cambios del asistente',
  'app.pages.Code.Diagram.Wizards.overWriteDefinition': 'Sobreescribir definición',
  'app.pages.Code.Diagram.Wizards.createNewNodeWithCurrentChanges':
    'Crear nuevo nodo con los cambios actuales',
  'app.pages.Code.Diagram.Wizards.undo': 'Deshacer',
  'app.pages.Code.Diagram.Wizards.redo': 'Rehacer',
  'app.pages.Code.Diagram.Wizards.renameColumn': 'Renombrar columna',
  'app.pages.Code.Diagram.Wizards.deleteColumn': 'Eliminar columna',
  'app.pages.Code.Diagram.Wizards.duplicateColumn': 'Duplicar columna',
  'app.pages.Code.Diagram.Wizards.changeDataType': 'Cambiar tipo de dato',
  'app.pages.Code.Diagram.Wizards.sortColumn': 'Ordenar columna',
  'app.pages.Code.Diagram.Wizards.removeDuplicates': 'Eliminar duplicados',
  'app.pages.Code.Diagram.Wizards.newColumnName': 'Nuevo nombre de columna',
  'app.pages.Code.Diagram.Wizards.deleteColumnMessage':
    'La columna {column} será eliminada del dataframe. ¿desea continuar?',
  'app.pages.Code.Diagram.Wizards.newDuplicateColumnName': 'Nuevo nombre de columna duplicada',
  'app.pages.Code.Diagram.Wizards.changeColumnType': 'Cambiar tipo de columna',
  'app.pages.Code.Diagram.Wizards.columnSanitization': 'Limpieza de columna',
  'app.pages.Code.Diagram.Wizards.addNewSanitizeAction': 'Agregar nueva acción de limpieza',
  'app.pages.Code.Diagram.Wizards.sanitizationColumnOptions': 'Opciones para limpiar la columna',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveDuplicatesWords':
    'Eliminar palabras duplicadas de la columna',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceMultiSpace':
    'Reemplazar múltiples espacios por un solo espacio',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemovePunctuation':
    'Eliminar la puntuación del texto',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveNumbers': 'Eliminar números del texto',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.KeepOnlyAlphaNumeric':
    'Conservar solo caracteres alfanuméricos',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.NormalizeAccents':
    'Normalizar acentos del texto',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveSpaces': 'Eliminar espacios del texto',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.AddSpaceBetweenWords':
    'Añadir espacio entre palabras',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveRepeatedChars':
    'Eliminar caracteres repetidos',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.UpperWordCase': 'Palabras en mayúscula',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.LowerWordCase': 'Palabras en minúscula',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.TitleWordCase': 'Palabras en título',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveHiddenChars':
    'Eliminar caracteres ocultos',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceHypensWithSpace':
    'Reemplazar guiones por espacios',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceText': 'Reemplazar texto',
  'app.pages.Code.Diagram.Wizards.textToReplace': 'Texto a reemplazar',
  'app.pages.Code.Diagram.Wizards.replaceWith': 'Reemplazar con',
  'app.pages.Code.Diagram.Wizards.stopWords': 'Palabras vacías',
  'app.pages.Code.Wizards.Upper': 'Mayúsculas',
  'app.pages.Code.Wizards.Lower': 'Minúsculas',
  'app.pages.Code.Wizards.Title': 'Título',
  'app.pages.Code.Wizards.Capitalize': 'Capitalizar',
  'app.pages.Code.Wizards.Swapcase': 'Intercambiar mayúsculas y minúsculas',
  'app.pages.Code.Diagram.Wizards.applyChangesInNewColumn': 'Aplicar cambios en nueva columna',
  'app.pages.Code.Diagram.Wizards.fillEmptyValues': 'Completar valores vacíos',
  'app.pages.Code.Diagram.Wizards.fillValue': 'Valor de relleno',
  'app.pages.Code.Diagram.Wizards.fillMethod': 'Método de relleno',
  'app.pages.Code.Diagram.Wizards.searchConditionValue': 'Buscar valor de condición',

  //Interfaces
  'app.pages.Interfaces.Table.add1RowAbove': 'Insertar 1 fila arriba',
  'app.pages.Interfaces.Table.add5RowsAbove': 'Insertar 5 filas arriba',
  'app.pages.Interfaces.Table.add1RowBelow': 'Insertar 1 fila debajo',
  'app.pages.Interfaces.Table.add5RowsBelow': 'Insertar 5 filas debajo',
  'app.pages.Interfaces.Table.addRow': 'Insertar fila',
  'app.pages.Interfaces.Table.removeRow': 'Eliminar fila/s',
  'app.pages.Interfaces.Table.copy': 'Copiar',
  'app.pages.Interfaces.Table.copyWithHeaders': 'Copiar con encabezados',
  'app.pages.Interfaces.Table.copyOnlyHeaders': 'Copiar sólo encabezados',
  'app.pages.Interfaces.Table.copyAllGroupHeaders': 'Copiar con todos los grupos de encabezados',
  'app.pages.Interfaces.Table.drilldownBy': 'Expandir por:',
  'app.pages.Interfaces.Table.drilldownBack': 'Volver a:',
  'app.pages.Interfaces.Table.drilldownsApplied': 'Drilldowns aplicados:',
  'app.pages.Interfaces.Table.removeDrilldowns': 'Quitar drilldowns',
  'app.pages.Interfaces.Table.drilldownBackOneLevel': 'Volver un nivel',
  'app.pages.Interfaces.SelectWithAutocomplete.noOptions': 'Sin opciones',
  'app.pages.Interfaces.SelectWithAutocomplete.loading': 'Cargando...',
  'app.pages.Interfaces.IndexFilterDialog.availableElements': 'Elementos disponibles',
  'app.pages.Interfaces.IndexFilterDialog.selectedElements': 'Elementos seleccionados',
  'app.pages.Interfaces.IndexFilterDialog.filterElements': 'Filtrar elementos',
  'app.pages.Interfaces.IndexFilterDialog.contains': 'Contiene',
  'app.pages.Interfaces.IndexFilterDialog.notcontains': 'No contiene',
  'app.pages.Interfaces.IndexFilterDialog.equal': 'Igual a',
  'app.pages.Interfaces.IndexFilterDialog.equal_to_node_values': 'Igual a los valores del nodo',
  'app.pages.Interfaces.IndexFilterDialog.regular_expression': 'Expresión regular',
  'app.pages.Interfaces.IndexFilterDialog.notequal': 'Distinto de',
  'app.pages.Interfaces.IndexFilterDialog.greater_than': 'Mayor a',
  'app.pages.Interfaces.IndexFilterDialog.greater_than_equal_to': 'Mayor o igual a',
  'app.pages.Interfaces.IndexFilterDialog.less_than': 'Menor a',
  'app.pages.Interfaces.IndexFilterDialog.less_than_equal_to': 'Menor o igual a',
  'app.pages.Interfaces.IndexFilterDialog.begin_with': 'Comienza con',
  'app.pages.Interfaces.IndexFilterDialog.not_begin_with': 'No comienza con',
  'app.pages.Interfaces.IndexFilterDialog.end_with': 'Termina con',
  'app.pages.Interfaces.IndexFilterDialog.not_end_with': 'No termina con',
  'app.pages.Interfaces.IndexFilterDialog.earliest': 'Primero',
  'app.pages.Interfaces.IndexFilterDialog.latest': 'Último',
  'app.pages.Interfaces.IndexFilterDialog.between': 'Entre',
  'app.pages.Interfaces.IndexFilterDialog.notbetween': 'No entre',
  'app.pages.Interfaces.IndexFilterDialog.rowsCount': 'Cuenta',
  'app.pages.Interfaces.Components.core.Node': 'Nodo',
  'app.pages.Interfaces.Components.core.Index': 'Índice',
  'app.pages.Interfaces.Components.core.Selector': 'Selector',
  'app.pages.Interfaces.Components.core.Chart': 'Gráfico',
  'app.pages.Interfaces.Components.core.PlotlyChart': 'Gráfico plotly',
  'app.pages.Interfaces.Components.core.InputValue': 'Entrada de valor',
  'app.pages.Interfaces.Components.core.Table': 'Tabla',
  'app.pages.Interfaces.Components.core.InputDataArray': 'Entrada de dataarray',
  'app.pages.Interfaces.Components.core.InputDataFrame': 'Entrada de dataframe',
  'app.pages.Interfaces.Components.core.Html': 'Html',
  'app.pages.Interfaces.Components.core.Menu': 'Menú',
  'app.pages.Interfaces.Components.core.Empty': 'Vacío',
  'app.pages.Interfaces.Components.core.Generic': 'Genérico',
  'app.pages.Interfaces.Components.core.Indicator': 'Indicador',
  'app.pages.Interfaces.Components.core.Choice': 'Elección',
  'app.pages.Interfaces.Components.core.Button': 'Botón',
  'app.pages.Interfaces.Components.core.Button.runButtonDefaultMessage':
    '¿Estás seguro/a de que quieres ejecutar este botón?',
  'app.pages.Interfaces.Components.core.Form': 'Formulario',
  'app.pages.Interfaces.Components.core.MyTasks': 'Tareas',
  'app.pages.Interfaces.Components.core.ScheduledTasks': 'Tareas programadas',
  'app.pages.Interfaces.Components.core.InputData': 'Entrada de datos',
  'app.pages.Interfaces.Components.core.MatplotlibChart': 'Gráfico matplotlib',
  'app.pages.Interfaces.Components.core.Assistant': 'Asistente',
  'app.pages.Interfaces.Components.core.Notifications': 'Notificaciones',
  'app.pages.Interfaces.Components.core.DynamicHtml': 'Html dinámico',
  'app.pages.Interfaces.Components.core.Filter': 'Filtro',
  'app.pages.Interfaces.Components.core.Filters': 'Filtros',
  'app.pages.Interfaces.Components.core.ApplyFilters': 'Aplicar filtros',
  'app.pages.Interfaces.Components.core.multiple': 'Múltiple',
  'app.pages.Interfaces.Components.core.multiSelect': 'Multi-selección',
  'app.pages.Interfaces.View.InterfaceName': 'Interfaz {name}',
  'app.pages.Interfaces.View.share': 'Compartir',
  'app.pages.Interfaces.View.Empty':
    'La interfaz está vacía actualmente. Ingresa al modo edición para agregar componentes.',
  'app.pages.Interfaces.View.openMenu': 'Abrir menú',
  'app.pages.Interfaces.Edit': 'Editar interfaz',
  'app.pages.Interfaces.Edit.Menu.componentConfig': 'Configuración del componente',
  'app.pages.Interfaces.Edit.Menu.newComponent': 'Nuevo componente',
  'app.pages.Interfaces.Edit.Menu.title': 'Título',
  'app.pages.Interfaces.Edit.Menu.chartEvents': 'Eventos',
  'app.pages.Interfaces.Edit.Menu.data': 'Datos',
  'app.pages.Interfaces.Edit.Menu.indexSync': 'Índices sincronizados',
  'app.pages.Interfaces.Edit.Menu.styleEditor': 'Estilos',
  'app.pages.Interfaces.Edit.Menu.icon': 'Ícono',
  'app.pages.Interfaces.Edit.Menu.advancedPivot': 'Pivot avanzado',
  'app.pages.Interfaces.Edit.Menu.layoutProperties': 'Diseño',
  'app.pages.Interfaces.Edit.Menu.valueFormat': 'Formato de valores',
  'app.pages.Interfaces.Edit.Menu.valueFormatSecondaryAxis': 'Formato de valores - Eje secundario',
  'app.pages.Interfaces.Edit.Menu.conditionalFormat': 'Formato condicional',
  'app.pages.Interfaces.Edit.Menu.cellProperties': 'Propiedades de celdas',
  'app.pages.Interfaces.Edit.Menu.progressBar': 'Barra de progreso',
  'app.pages.Interfaces.Edit.Menu.heatmap': 'Mapa de calor',
  'app.pages.Interfaces.Edit.Menu.componentStyle': 'Componente',
  'app.pages.Interfaces.Edit.Menu.general': 'General',
  'app.pages.Interfaces.Edit.Menu.scenarios': 'Escenarios',
  'app.pages.Interfaces.Edit.Menu.columnsToShow': 'Columnas a mostrar',
  'app.pages.Interfaces.Edit.Menu.confirmationDialog': 'Diálogo de confirmación',
  'app.pages.Interfaces.Edit.Menu.showConfirmationDialog': 'Mostrar diálogo',
  'app.pages.Interfaces.Edit.Menu.confirmationDialogMessage': 'Mensaje',
  'app.pages.Interfaces.Edit.Menu.formProperties': 'Propiedades de formulario',
  'app.pages.Interfaces.Edit.Menu.validations': 'Validaciones',
  'app.pages.Interfaces.Edit.Menu.inputCubeProperties': 'Propiedades de cubo',
  'app.pages.Interfaces.Edit.selectNode': 'Selecciona un nodo para crear un componente de tipo',
  'app.pages.Interfaces.Edit.nodeType': 'Tipo de nodo',
  'app.pages.Interfaces.Edit.selectedNodeId': 'Identificador del nodo seleccionado',
  'app.pages.Interfaces.Edit.refresh': 'Refrescar interfaz',
  'app.pages.Interfaces.Edit.copy': 'Copiar interfaz',
  'app.pages.Interfaces.Edit.hidePanel': 'Ocultar panel',
  'app.pages.Interfaces.Edit.showPanel': 'Mostrar panel',
  'app.pages.Interfaces.Edit.removeComponent': 'Quitar componente',
  'app.pages.Interfaces.Edit.remove': 'Quitar',
  'app.pages.Interfaces.Edit.removeConfirmationText':
    '¿Estás seguro de querer quitar este componente?',
  'app.pages.Interfaces.Edit.discardUnsavedChanges': '¿Descartar cambios sin guardar?',
  'app.pages.Interfaces.Edit.discardUnsavedChangesText':
    '¿Estás seguro de querer descartar los cambios sin guardar?',
  'app.pages.Interfaces.Edit.showInterfaceCode': 'Mostrar código de interfaz',
  'app.pages.Interfaces.Edit.interfaceCodeEditor': 'Editor de código de interfaz',
  'app.pages.Interfaces.Edit.closeComponentCodeEditor': 'Cerrar editor de código de componente',
  'app.pages.Interfaces.Edit.customComponentCode': 'Código personalizado de componente',
  'app.pages.Interfaces.Edit.HTMLEditor': 'Editor HTML',
  'app.pages.Interfaces.Edit.menuEditor': 'Editor de menú',
  'app.pages.Interfaces.Edit.title.enabled': 'Mostrar',
  'app.pages.Interfaces.Edit.title.editTitle': 'Editar título',
  'app.pages.Interfaces.Edit.title.customTitle': 'Título personalizado',
  'app.pages.Interfaces.Edit.table.size.rowsPerPage': 'Filas por página',
  'app.pages.Interfaces.Edit.table.size.columnsPerPage': 'Columnas por página',
  'app.pages.Interfaces.Edit.table.data.showRowTotals': 'Mostrar totales de filas',
  'app.pages.Interfaces.Edit.table.data.rowTotalsPosition': 'Posición de totales de filas',
  'app.pages.Interfaces.Edit.table.data.showColumnTotals': 'Mostrar totales de columnas',
  'app.pages.Interfaces.Edit.table.data.columnTotalsPosition': 'Posición de totales de columnas',
  'app.pages.Interfaces.Edit.table.data.compareScenarios': 'Comparar escenarios',
  'app.pages.Interfaces.Edit.table.data.disableCompareScenarios':
    'Deshabilitar comparar escenarios',
  'app.pages.Interfaces.Edit.table.data.showValues': 'Mostrar valores',
  'app.pages.Interfaces.Edit.table.data.showDifference': 'Mostrar diferencia',
  'app.pages.Interfaces.Edit.table.data.showPercentageDifference': 'Mostrar diferencia porcentual',
  'app.pages.Interfaces.Edit.table.data.differenceEndText': 'Texto final de diferencia',
  'app.pages.Interfaces.Edit.table.data.percentageDifferenceEndText':
    'Texto final de diferencia porcentual',
  'app.pages.Interfaces.Edit.table.data.columnsProperties': 'Propiedades de columnas',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData': 'Ocultar datos vacíos',
  'app.pages.Interfaces.Edit.table.data.totalsPositions.end': 'Final',
  'app.pages.Interfaces.Edit.table.data.totalsPositions.beginning': 'Comienzo',
  'app.pages.Interfaces.Edit.table.data.showColumnFilterIcon':
    'Mostrar ícono de filtrado de columna',
  'app.pages.Interfaces.Edit.table.data.hideFooter': 'Ocultar pie de página',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.column': 'Columna',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.row': 'Fila',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.both': 'Ambas',
  'app.pages.Interfaces.Edit.table.data.pagination': 'Paginación',
  'app.pages.Interfaces.Edit.events.selectedEvent': 'Evento de selección',
  'app.pages.Interfaces.Edit.events.hoverEvent': 'Evento  hover',
  'app.pages.Interfaces.Edit.events.drilldownEvent': 'Evento drilldown',
  'app.pages.Interfaces.Edit.pivot': 'Pivot',
  'app.pages.Interfaces.Edit.filter': 'Filtro',
  'app.pages.Interfaces.Edit.dataHandling': 'Manipulación de datos',
  'app.pages.Interfaces.Edit.appliedFiltersOn': 'Filtros aplicados en',
  'app.pages.Interfaces.Edit.style.fontSize': 'Tamaño de fuente',
  'app.pages.Interfaces.Edit.style.fontStyle': 'Estilo de fuente',
  'app.pages.Interfaces.Edit.style.fontStyle.bold': 'Negrita',
  'app.pages.Interfaces.Edit.style.fontStyle.italic': 'Cursiva',
  'app.pages.Interfaces.Edit.style.fontStyle.underline': 'Subrayado',
  'app.pages.Interfaces.Edit.style.fontColor': 'Color de fuente',
  'app.pages.Interfaces.Edit.style.backgroundColor': 'Color de fondo',
  'app.pages.Interfaces.Edit.style.headerBackgroundColor': 'Color de fondo del encabezado',
  'app.pages.Interfaces.Edit.style.headerColor': 'Color del encabezado',
  'app.pages.Interfaces.Edit.style.textAlign': 'Alineación de texto',
  'app.pages.Interfaces.Edit.style.textAlign.left': 'Izquierda',
  'app.pages.Interfaces.Edit.style.textAlign.center': 'Centrado',
  'app.pages.Interfaces.Edit.style.textAlign.right': 'Derecha',
  'app.pages.Interfaces.Edit.style.textAlign.justify': 'Justificado',
  'app.pages.Interfaces.Edit.style.hideHeader': 'Ocultar encabezado',
  'app.pages.Interfaces.Edit.style.hideBorder': 'Ocultar borde',
  'app.pages.Interfaces.Edit.style.hideMaximizeButton': 'Ocultar botón de maximizar',
  'app.pages.Interfaces.Edit.style.hideDataHandlingIcon': 'Ocultar icono de manipulación de datos',
  'app.pages.Interfaces.Edit.style.note': '(aplica en modo visualización)',
  'app.pages.Interfaces.Edit.style.setDefaultColor': 'Establecer color por defecto',
  'app.pages.Interfaces.Edit.style.applyAndClose': 'Aplicar y cerrar',
  'app.pages.Interfaces.Edit.style.seriesColors': 'Color de series',
  'app.pages.Interfaces.Edit.style.colorPalette.increasing': 'Incremento',
  'app.pages.Interfaces.Edit.style.colorPalette.decreasing': 'Disminución',
  'app.pages.Interfaces.Edit.layout.style.axesTitles': 'Títulos de ejes',
  'app.pages.Interfaces.Edit.layout.style.showYAxisTitle': 'Mostrar título de eje Y',
  'app.pages.Interfaces.Edit.layout.style.showXAxisTitle': 'Mostrar título de eje X',
  'app.pages.Interfaces.Edit.layout.color_palette': 'Paleta de colores',
  'app.pages.Interfaces.Edit.layout.color_palette.color_continuous_scale': 'Escala contínua',
  'app.pages.Interfaces.Edit.layout.color_palette.color_discrete_sequence': 'Secuencia discreta',
  'app.pages.Interfaces.Edit.layout.legend': 'Leyenda',
  'app.pages.Interfaces.Edit.layout.legend.showlegend': 'Mostrar leyenda',
  'app.pages.Interfaces.Edit.layout.legend.toggleOthersWithSingleClick':
    'Alternar otros con un solo click',
  'app.pages.Interfaces.Edit.layout.legend.coloraxis_showscale': 'Mostrar escala',
  'app.pages.Interfaces.Edit.layout.legend.orientation': 'Orientación',
  'app.pages.Interfaces.Edit.layout.legend.xanchor': 'Punto de anclaje X',
  'app.pages.Interfaces.Edit.layout.legend.x': 'X',
  'app.pages.Interfaces.Edit.layout.legend.yanchor': 'Punto de anclaje Y',
  'app.pages.Interfaces.Edit.layout.legend.y': 'Y',
  'app.pages.Interfaces.Edit.layout.margins': 'Márgenes',
  'app.pages.Interfaces.Edit.layout.selectValues.auto': 'Auto',
  'app.pages.Interfaces.Edit.layout.selectValues.left': 'Izquierda',
  'app.pages.Interfaces.Edit.layout.selectValues.center': 'Centro',
  'app.pages.Interfaces.Edit.layout.selectValues.right': 'Derecha',
  'app.pages.Interfaces.Edit.layout.selectValues.top': 'Superior',
  'app.pages.Interfaces.Edit.layout.selectValues.middle': 'Medio',
  'app.pages.Interfaces.Edit.layout.selectValues.bottom': 'Inferior',
  'app.pages.Interfaces.Edit.layout.chartMode': 'Variante de gráfico',
  'app.pages.Interfaces.Edit.layout.chartMode.modePreset': 'Variante predefinida',
  'app.pages.Interfaces.Edit.layout.chartMode.stack': 'Apilado',
  'app.pages.Interfaces.Edit.layout.chartMode.relative': 'Relativo',
  'app.pages.Interfaces.Edit.layout.chartMode.group': 'Agrupado',
  'app.pages.Interfaces.Edit.layout.chartMode.overlay': 'Superpuesto',
  'app.pages.Interfaces.Edit.layout.chartMode.barmode': 'Variante de barra',
  'app.pages.Interfaces.Edit.layout.chartMode.boxmode': 'Variante de caja',
  'app.pages.Interfaces.Edit.layout.chartMode.funnelmode': 'Variante de embudo',
  'app.pages.Interfaces.Edit.layout.chartMode.violinmode': 'Variante de violín',
  'app.pages.Interfaces.Edit.layout.chartMode.waterfallmode': 'Variante de cascada',
  'app.pages.Interfaces.Edit.layout.chartMode.percent': 'Porcentaje',
  'app.pages.Interfaces.Edit.layout.chartNorm': 'Normalización de gráfico',
  'app.pages.Interfaces.Edit.layout.chartNorm.barnorm': 'Normalización de barra',
  'app.pages.Interfaces.Edit.layout.chartNorm.groupnorm': 'Normalización de grupo',
  'app.pages.Interfaces.Edit.layout.chartNorm.fraction': 'Fracción',
  'app.pages.Interfaces.Edit.layout.chartNorm.percent': 'Porcentaje',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels': 'Etiquetas',
  'app.pages.Interfaces.Edit.layout.chartNorm.labelsSecondaryAxis': 'Etiquetas - Eje secundario',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.showLabels': 'Mostrar etiquetas',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.labelField': 'Campo',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.minSize': 'Tamaño mínimo',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.mode': 'Modo',
  'app.pages.Interfaces.Edit.layout.customSeriesColors': 'Colores de series personalizados',
  'app.pages.Interfaces.Edit.layout.customName': 'Nombre personalizado',
  'app.pages.Interfaces.Edit.layout.addValue': 'Agregar valor',
  'app.pages.Interfaces.Edit.layout.addColor': 'Agregar color',
  'app.pages.Interfaces.Edit.layout.generateValuesFromColumn': 'Generar valores desde columna',
  'app.pages.Interfaces.Edit.layout.y_axis_range': 'Rango de eje Y',
  'app.pages.Interfaces.Edit.valueFormat.formatPreset': 'Formato preestablecido',
  'app.pages.Interfaces.Edit.valueFormat.number': 'Número',
  'app.pages.Interfaces.Edit.valueFormat.decimal': 'Decimal',
  'app.pages.Interfaces.Edit.valueFormat.integer': 'Entero',
  'app.pages.Interfaces.Edit.valueFormat.percent': 'Porcentaje',
  'app.pages.Interfaces.Edit.valueFormat.currency': 'Moneda',
  'app.pages.Interfaces.Edit.valueFormat.currencyRounded': 'Moneda (redondeado)',
  'app.pages.Interfaces.Edit.valueFormat.date': 'Fecha',
  'app.pages.Interfaces.Edit.valueFormat.datetime': 'Fecha hora',
  'app.pages.Interfaces.Edit.valueFormat.thousandsSeparator': 'Separador de miles',
  'app.pages.Interfaces.Edit.valueFormat.decimalPlaces': 'Decimales',
  'app.pages.Interfaces.Edit.valueFormat.prefix': 'Prefijo',
  'app.pages.Interfaces.Edit.valueFormat.suffix': 'Sufijo',
  'app.pages.Interfaces.Edit.valueFormat.dateFormat': 'Formato de fecha',
  'app.pages.Interfaces.Edit.valueFormat.datetimeFormat': 'Formato de fecha hora',
  'app.pages.Interfaces.Edit.valueFormat.seePatternReference': 'Ver referencia sobre patrones',
  'app.pages.Interfaces.Run.runningButton': 'Ejecutando botón',
  'app.pages.Interfaces.Edit.editTitle.showUnits': 'Mostrar unidades',
  'app.pages.Interfaces.Edit.progressBar.applyProgressBar': 'Aplicar barra de progreso',
  'app.pages.Interfaces.Edit.progressBar.max_value': 'Valor máximo',
  'app.pages.Interfaces.Edit.progressBar.min_hue': 'Matiz mínima',
  'app.pages.Interfaces.Edit.progressBar.max_hue': 'Matiz máxima',
  'app.pages.Interfaces.Edit.progressBar.saturation': 'Saturación',
  'app.pages.Interfaces.Edit.progressBar.lightness': 'Claridad',
  'app.pages.Interfaces.Edit.progressBar.show_value': 'Mostrar valor',
  'app.pages.Interfaces.Edit.heatmap.applyHeatmap': 'Aplicar mapa de calor',
  'app.pages.Interfaces.Edit.heatmap.by': 'Por',
  'app.pages.Interfaces.Edit.heatmap.hex_base_background_color': 'Color de fondo base',
  'app.pages.Interfaces.Edit.heatmap.hex_text_color': 'Color de texto',
  'app.pages.Interfaces.Edit.heatmap.exclude_nans': 'Excluir NaNs',
  'app.pages.Interfaces.Edit.heatmap.exclude_right_total': 'Excluir totales de filas',
  'app.pages.Interfaces.Edit.heatmap.exclude_bottom_total': 'Excluir totales de columnas',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor': 'Color de mapa de calor',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_green_alt': 'rojo-verde-alt',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_red_alt': 'verde-rojo-alt',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_green': 'rojo-verde',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_red': 'verde-rojo',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_white': 'rojo-blanco',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.white_red': 'blanco-rojo',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.white_green': 'blanco-verde',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_white': 'verde-blanco',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_blue': 'verde-azul',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.blue_green': 'azul-verde',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_blue': 'rojo-azul',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.blue_red': 'azul-rojo',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.custom': 'Personalizado',
  'app.pages.Interfaces.Edit.conditionalFormat.addCondition': 'Agregar condición',
  'app.pages.Interfaces.Edit.conditionalFormat.deleteAll': 'Borrar todas',
  'app.pages.Interfaces.Edit.conditionalFormat.Conditions': 'Condiciones',
  'app.pages.Interfaces.Edit.conditionalFormat.showIconOnly': 'Mostrar sólo el ícono',
  'app.pages.Interfaces.Edit.style.addNewStyle': 'Agregar nuevo estilo',
  'app.pages.Interfaces.Edit.style.deleteStyle': 'Borrar estilo',
  'app.pages.Interfaces.Edit.style.editStyle': 'Edita estilo',
  'app.pages.Interfaces.Edit.style.dimensionName': 'Nombre de dimensión',
  'app.pages.Interfaces.Edit.style.filterType': 'Tipo de filtro',
  'app.pages.Code.Diagram.Wizards.styleConditions': 'Condiciones del estilo',
  'app.pages.Code.Diagram.Wizards.filterIndexes': 'Filtrar índices',
  'app.pages.Interfaces.Edit.colorPicker.themeColors': 'Colores del tema',
  'app.pages.Interfaces.Edit.colorPicker.standardColors': 'Colores estándar',
  'app.pages.Interfaces.Edit.colorPicker.recentColors': 'Colores recientes',
  'app.pages.Interfaces.Edit.colorPicker.selectedColor': 'Color seleccionado',
  'app.pages.Interfaces.Edit.leaveInterfaceTitle': 'Interfaz con cambios no confirmados',
  'app.pages.Interfaces.Edit.leaveInterfaceContent':
    '¿Quieres confirmar los cambios o descartarlos?',
  'app.pages.Interfaces.Edit.inputNodeProperties.hideIdColumn': 'Ocultar columna con ID',
  'app.pages.Interfaces.Edit.inputNodeProperties.confirmOnChange': 'Confirmar al hacer un cambio',
  'app.pages.Interfaces.Edit.inputNodeProperties.invalidateOutputsOnChange':
    'Invalidar outputs al hacer un cambio',
  'app.pages.Interfaces.Edit.inputNodeProperties.invalidateOutputsOnConfirm':
    'Invalidar outputs al confirmar',
  'app.pages.Interfaces.Edit.inputNodeProperties.updateCalcColumnsOnChange':
    'Actualizar columnas calculadas al hacer un cambio',
  'app.pages.Interfaces.Edit.inputNodeProperties.updateCalcColumnsOnConfirm':
    'Actualizar columnas calculadas al confirmar',
  'app.pages.Interfaces.Edit.inputNodeProperties.showSelectColumnsIcon':
    'Mostrar ícono de Seleccionar columnas',
  'app.pages.Interfaces.Edit.inputNodeProperties.showEditExcelIcon':
    'Mostrar ícono de Editar en Excel',
  'app.pages.Interfaces.Edit.filterIndexValues': 'Filtrar valores de índice',
  'app.pages.Interfaces.Edit.filterIndexValues.fromToError':
    'El valor "Desde" debe ser menor o igual al valor "Hasta".',
  'app.pages.Interfaces.Edit.style.applyConditionTo': 'Aplicar condición a',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customView': 'Vista personalizada',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.editingCustomView':
    'Estás editando la vista personalizada de esta interfaz',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.showDefaultView': 'Mostrar vista por defecto',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.showCustomView': 'Mostrar vista personalizada',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.saveCustomView': 'Guardar vista personalizada',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.deleteCustomView':
    'Borrar vista personalizada',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customViewSaved':
    'La vista personalizada de la interfaz se ha guardado correctamente',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customViewDeleted':
    'La vista personalizada de la interfaz se ha borrado correctamente',

  //Instance manager
  'app.pages.app.instanceManager.noInstances': 'No hay instancias',
  'app.pages.app.instanceManager.open': 'Abierto',
  'app.pages.app.instanceManager.currentApplication': 'Aplicación actual',
  'app.pages.app.instanceManager.isPool': 'Es pool',
  'app.pages.app.instanceManager.active': 'Activo',
  'app.pages.app.instanceManager.timeoutIn': 'Tiempo de espera restante',
  'app.pages.app.instanceManager.minutes': ' min',
  'app.pages.app.instanceManager.cpu': 'CPU',
  'app.pages.app.instanceManager.memory': 'Memoria',
  'app.pages.app.instanceManager.peak': 'Pico',
  'app.pages.app.instanceManager.apiResources': 'Recursos de la aplicación',
  'app.pages.app.instanceManager.systemMemory': 'Total',
  'app.pages.app.instanceManager.inUse': 'En uso',
  'app.pages.app.instanceManager.disk': 'Disco',
  'app.pages.app.instanceManager.time': 'Tiempo',
  'app.pages.app.instanceManager.selectedInstance': 'Instancia seleccionada',
  'app.pages.app.instanceManager.pid': 'PID',
  'app.pages.app.instanceManager.memoryPeak': 'Pico de memoria',
  'app.pages.app.instanceManager.lastProcessedNode': 'Último nodo procesado',
  'app.pages.app.instanceManager.requireLogoutTitle': 'Requerir el cierre de instancia',
  'app.pages.app.instanceManager.requireLogoutMessage':
    '¿Estás seguro de enviar una petición para que el usuario cierre su instancia?',
  'app.pages.app.instanceManager.killInstanceTitle': 'Cerrar instancia',
  'app.pages.app.instanceManager.killInstanceMessage':
    'Toda la información de la instancia no guardada se perderá. ¿Estás seguro?',
  'app.pages.app.instanceManager.requestSent': 'Petición enviada',
  'app.pages.app.instanceManager.instanceClosed': 'Instancia cerrada',
  'app.pages.app.instanceManager.editionAvailable': 'Edición disponible',
  'app.pages.app.instanceManager.processing': 'Procesando',
  'app.pages.app.instanceManager.info': 'Info',
  'app.pages.app.instanceManager.openAppsOnly': 'Sólo apps abiertas',
  'app.pages.app.instanceManager.noSelectedInstance': 'No hay instancia seleccionada',
  'app.pages.app.instanceManager.scheduledTask': 'Tarea programada',

  //Log manager
  'app.pages.managers.logs': 'Registros',
  'app.pages.managers.logManager.startDate': 'Fecha desde',
  'app.pages.managers.logManager.endDate': 'Fecha hasta',
  'app.pages.managers.logManager.instanceKey': 'Instancia',
  'app.pages.managers.logManager.user': 'Usuario',
  'app.pages.managers.logManager.user.notFound': 'Usuario no encontrado',
  'app.pages.managers.logManager.user.selectUser': 'Selecciona o busca un usuario',
  'app.pages.managers.logManager.action': 'Acción',
  'app.pages.managers.logManager.action.notFound': 'Acción no encontrada',
  'app.pages.managers.logManager.action.selectAction': 'Selecciona o busca una acción',
  'app.pages.managers.logManager.model': 'Modelo',
  'app.pages.managers.logManager.downloadReport': 'Descargar reporte',
  'app.pages.managers.logManager.reportDownloaded': 'Reporte descargado',
  'app.pages.managers.logManager.clearFilters': 'Borrar filtros',
  'app.pages.managers.logManager.filter': 'Filtrar',
  'app.pages.managers.logManager.dateFilterHelperText': 'Puedes filtrar un máximo de {name} días',
  'app.pages.managers.logManager.table.date': 'Fecha',
  'app.pages.managers.logManager.table.query': 'Consulta',
  'app.pages.managers.logManager.table.body': 'Cuerpo',

  //Interface toolbar
  'app.pages.interfaces.toolbar.cancel': 'Cancelar',
  'app.pages.interfaces.toolbar.mode': 'Modo',
  'app.pages.interfaces.toolbar.mode.single': 'Simple',
  'app.pages.interfaces.toolbar.mode.multi': 'Múltiple',
  'app.pages.interfaces.toolbar.format': 'Formato',
  'app.pages.interfaces.toolbar.menu.alignment': 'Alineación',
  'app.pages.interfaces.toolbar.format.small': 'Pequeño',
  'app.pages.interfaces.toolbar.format.hidden': 'Oculto',
  'app.pages.interfaces.toolbar.format.range': 'Rango',
  'app.pages.interfaces.toolbar.format.select': 'Selección',
  'app.pages.interfaces.toolbar.format.radio': 'Radio',
  'app.pages.interfaces.toolbar.format.slider': 'Deslizador',
  'app.pages.interfaces.toolbar.format.selected_chips': 'Fichas seleccionadas',
  'app.pages.interfaces.toolbar.format.clickable_chips': 'Fichas clicables',
  'app.pages.interfaces.toolbar.orientation': 'Orientación',
  'app.pages.interfaces.toolbar.orientation.horizontal': 'Horizontal',
  'app.pages.interfaces.toolbar.orientation.vertical': 'Vertical',
  'app.pages.interfaces.toolbar.chartType': 'Tipo de gráfico',
  'app.pages.interfaces.toolbar.chartSubtype': 'Subtipo de gráfico',
  'app.pages.interfaces.toolbar.chartType.bar': 'Barra',
  'app.pages.interfaces.toolbar.chartType.column': 'Columna',
  'app.pages.interfaces.toolbar.chartType.normal': 'Normal',
  'app.pages.interfaces.toolbar.chartType.stacked': 'Apilado',
  'app.pages.interfaces.toolbar.chartType.percentStacked': 'Porcentaje apilado',
  'app.pages.interfaces.toolbar.chartType.line': 'Línea',
  'app.pages.interfaces.toolbar.chartType.linesOnly': 'Sólo líneas',
  'app.pages.interfaces.toolbar.chartType.linesAndPoints': 'Líneas y puntos',
  'app.pages.interfaces.toolbar.chartType.pointsOnly': 'Sólo puntos',
  'app.pages.interfaces.toolbar.chartType.pie': 'Torta',
  'app.pages.interfaces.toolbar.chartType.donut': 'Dona',
  'app.pages.interfaces.toolbar.chartType.sunburst': 'Proyección solar',
  'app.pages.interfaces.toolbar.chartType.area': 'Área',
  'app.pages.interfaces.toolbar.chartType.percent': 'Porcentaje',
  'app.pages.interfaces.toolbar.chartType.scatter': 'Dispersión',
  'app.pages.interfaces.toolbar.chartType.combined': 'Combinado',
  'app.pages.interfaces.toolbar.chartType.scatterWithTrendline':
    'Dispersión con línea de tendencia',
  'app.pages.interfaces.toolbar.chartType.others': 'Otros',
  'app.pages.interfaces.toolbar.chartType.waterfall': 'Cascada',
  'app.pages.interfaces.toolbar.chartType.funnel': 'Embudo',
  'app.pages.interfaces.toolbar.chartType.timeline': 'Línea de tiempo',
  'app.pages.interfaces.toolbar.chartType.treemap': 'Mapa de árbol',
  'app.pages.interfaces.toolbar.chartType.icicle': 'Carámbano',
  'app.pages.interfaces.toolbar.chartType.funnelArea': 'Área de embudo',
  'app.pages.interfaces.toolbar.chartType.histogram': 'Histograma',
  'app.pages.interfaces.toolbar.chartType.box': 'Caja',
  'app.pages.interfaces.toolbar.chartType.violin': 'Violín',
  'app.pages.interfaces.toolbar.chartType.strip': 'Banda',
  'app.pages.interfaces.toolbar.chartType.densityHeatmap': 'Mapa de calor de densidad',
  'app.pages.interfaces.toolbar.chartType.densityContour': 'Contorno de densidad',
  'app.pages.interfaces.toolbar.chartType.scatter3d': 'Dispersión 3D',
  'app.pages.interfaces.toolbar.chartType.line3d': 'Línea 3D',
  'app.pages.interfaces.toolbar.chartType.scatterMatrix': 'Matriz de dispersión',
  'app.pages.interfaces.toolbar.chartType.parallelCoordinates': 'Coordenadas paralelas',
  'app.pages.interfaces.toolbar.chartType.parallelCategories': 'Categorías paralelas',
  'app.pages.interfaces.toolbar.chartType.scatterMapbox': 'Mapa de dispersión',
  'app.pages.interfaces.toolbar.chartType.lineMapbox': 'Mapa de línea',
  'app.pages.interfaces.toolbar.chartType.choroplethMapbox': 'Mapa de coropleta',
  'app.pages.interfaces.toolbar.chartType.densityMapbox': 'Mapa de densidad',
  'app.pages.interfaces.toolbar.chartType.scatterPolar': 'Dispersión polar',
  'app.pages.interfaces.toolbar.chartType.linePolar': 'Línea polar',
  'app.pages.interfaces.toolbar.chartType.barPolar': 'Barra polar',
  'app.pages.interfaces.toolbar.chartType.scatterTernary': 'Dispersión ternaria',
  'app.pages.interfaces.toolbar.chartType.lineTernary': 'Línea ternaria',
  'app.pages.interfaces.toolbar.chartType.groups.basics': 'Básicos',
  'app.pages.interfaces.toolbar.chartType.groups.financial': 'Financiero',
  'app.pages.interfaces.toolbar.chartType.groups.partOfWhole': 'Parte-de-todo',
  'app.pages.interfaces.toolbar.chartType.groups.1dDistributions': 'Distribuciones 1D',
  'app.pages.interfaces.toolbar.chartType.groups.2dDistributions': 'Distribuciones 2D',
  'app.pages.interfaces.toolbar.chartType.groups.3dimensional': '3-Dimensional',
  'app.pages.interfaces.toolbar.chartType.groups.multidimensional': 'Multidimensional',
  'app.pages.interfaces.toolbar.chartType.groups.tileMaps': 'Mapas de mosaicos',
  'app.pages.interfaces.toolbar.chartType.groups.polarCharts': 'Gráficos polar',
  'app.pages.interfaces.toolbar.chartType.groups.ternaryCharts': 'Gráficos ternarios',
  'app.pages.interfaces.toolbar.showComponentCode': 'Mostrar código del componente',
  'app.pages.interfaces.toolbar.openComponentCode': 'Abrir código del componente',
  'app.pages.interfaces.toolbar.componentCodeEditor': 'Editor de código del componente',
  'app.pages.interfaces.toolbar.menuFormat': 'Formato del menú',
  'app.pages.interfaces.toolbar.menuFormat.accordion': 'Acordeón',
  'app.pages.interfaces.toolbar.menuFormat.boxes': 'Cajas',
  'app.pages.interfaces.toolbar.menuFormat.mini': 'Mini',
  'app.pages.interfaces.toolbar.menu.newItem': 'Nuevo item',
  'app.pages.interfaces.toolbar.menu.newAction': 'Nueva acción',
  'app.pages.interfaces.toolbar.menu.addItem': 'Agregar item',
  'app.pages.interfaces.toolbar.menu.addAction': 'Agregar acción',
  'app.pages.interfaces.toolbar.menu.removeItem': 'Borrar item',
  'app.pages.interfaces.toolbar.menu.removeAction': 'Borrar acción',
  'app.pages.interfaces.toolbar.menu.root': 'Raíz',
  'app.pages.interfaces.toolbar.menu.actionType': 'Tipo de acción',
  'app.pages.interfaces.toolbar.menu.navigateTo': 'Navegar a',
  'app.pages.interfaces.toolbar.menu.target': 'Destino',
  'app.pages.interfaces.toolbar.menu.currentTab': 'Pestaña actual',
  'app.pages.interfaces.toolbar.menu.newTab': 'Nueva pestaña',
  'app.pages.interfaces.toolbar.menu.expanded': 'Expandido',
  'app.pages.interfaces.toolbar.menu.subtitle': 'Subtítulo',
  'app.pages.interfaces.toolbar.menu.openInterface': 'Abrir interfaz',
  'app.pages.interfaces.toolbar.menu.navigate': 'Navegar',
  'app.pages.interfaces.toolbar.menu.textColor': 'Color del texto',
  'app.pages.interfaces.toolbar.menu.iconColor': 'Color del ícono',
  'app.pages.interfaces.toolbar.menu.backgroundColor': 'Color de fondo',
  'app.pages.interfaces.toolbar.menu.hoverColor': 'Color del hover',
  'app.pages.interfaces.componentHasCustomCodeMessage':
    'El componente tiene código personalizado. Cambiar a definición por defecto para habilitar toolbar de propiedades.',
  'app.pages.interfaces.toolbar.openHtmlEditor': 'Abrir editor HTML',
  'app.pages.interfaces.toolbar.openDynamicHtmlEditor': 'Abrir editor HTML dinámico',
  'app.pages.interfaces.toolbar.dynamicHtmlCustomization': 'Personalización HTML dinámico',
  'app.pages.interfaces.toolbar.selectSectionToGo': 'Selecciona una sección a ir',
  'app.pages.interfaces.toolbar.selectRunAppNode': 'Selecciona un nodo de aplicación a ejecutar',
  'app.pages.interfaces.toolbar.openInNewTab': 'Abrir en nueva pestaña',
  'app.pages.interfaces.toolbar.openMenuEditor': 'Abrir editor de menú',
  'app.pages.interfaces.toolbar.autoOpenSingleAction':
    'Abrir automáticamente la única acción disponible',
  'app.pages.interfaces.toolbar.exportComponent': 'Exportar componente',
  'app.pages.interfaces.toolbar.fileFormat': 'Formato de archivo',
  'app.pages.interfaces.toolbar.numberFormat': 'Formato de número',
  'app.pages.interfaces.toolbar.columnSeparator': 'Separador de columnas',
  'app.pages.interfaces.toolbar.compressToDownload': 'Comprimir archivo para la descarga',
  'app.pages.interfaces.toolbar.excludeNullAndZero': 'Excluir valores ceros y nulos',
  'app.pages.interfaces.toolbar.fullNode': 'Nodo completo',
  'app.pages.interfaces.toolbar.currentView': 'Vista actual',
  'app.pages.interfaces.toolbar.multiline': 'Multilínea',
  'app.pages.interfaces.toolbar.uppercase': 'Mayúsculas',
  'app.pages.interfaces.toolbar.fixedPositionOnTop': 'Posición fija en la parte superior',
  'app.pages.interfaces.toolbar.openFilterEditor': 'Abrir editor de filtro',
  'app.pages.interfaces.chart': 'Gráfico',
  'app.pages.interfaces.indicator': 'Indicador',
  'app.pages.interfaces.interfaceDeleted': 'Interfaz eliminada',
  'app.pages.interfaces.folderDeleted': 'Carpeta eliminada',
  'app.pages.interfaces.interfaceDuplicated': 'Interfaz duplicada',
  'app.pages.interfaces.folderDuplicated': 'Carpeta duplicada',
  'app.pages.interfaces.viewAsChart': 'Ver como gráfico',
  'app.pages.interfaces.viewAsTable': 'Ver como tabla',
  'app.pages.interfaces.defaultInterface': 'Interfaz por defecto',
  'app.pages.interfaces.closeAllTabs': 'Cerrar todas las tabs',
  'app.pages.interfaces.closeSelectedTab': 'Cerrar la tab seleccionada',
  'app.pages.interfaces.openTabActions': 'Abrir acciones de las tabs',
  'app.pages.interfaces.openDefaultInterface': 'Abrir interfaz por defecto',
  'app.pages.interfaces.closeAllButThis': 'Cerrar todas excepto la seleccionada',
  'app.pages.interfaces.importInterface': 'Importar interfaz',
  'app.pages.interfaces.interfaceImportedSuccessfully': 'Interfaz importada con éxito',
  'app.pages.interfaces.exportInterfaceSuccess': 'Interfaz exportada con éxito',
  'app.pages.interfaces.interfaceCopied': 'Interfaz copiada con éxito',
  'app.pages.interfaces.interfaceMoved': 'Interfaz movida con éxito',
  'app.pages.interfaces.deleteFolder': 'Eliminar carpeta',
  'app.pages.interfaces.deleteInterfaces': 'Eliminar interfaces',
  'app.pages.interfaces.folderCopied': 'Carpeta copiada con éxito',
  'app.pages.interfaces.folderMoved': 'Carpeta copiada con éxito',
  'app.pages.interfaces.interfacePermissionChanged': 'Permisos de interfaz cambiados',
  'app.pages.interfaces.htmlPreview': 'Vista previa HTML',
  'app.pages.interfaces.attribute': 'Atributo',
  'app.pages.interfaces.attributeValue': 'Valor del atributo',
  'app.pages.interfaces.typeActionValue': 'Valor de tipo de acción',
  'app.pages.interfaces.typeActionValue.modifyTypeActionValue': 'Modificar valor de tipo de acción',
  'app.pages.interfaces.runAppNode': 'Ejecutar nodo de aplicación',
  'app.pages.interfaces.attributesFromNode': 'Atributos desde nodo',
  'app.pages.interfaces.childrenFromNode': 'Hijo desde nodo',
  'app.pages.interfaces.addNodeResultLikeChildrenElement':
    'Agregar resultado de nodo como elemento hijo',
  'app.pages.interfaces.getAttributesFromNode': 'Obtener atributos desde nodo',
  'app.pages.interfaces.targetAttribute': 'Atributo de destino',
  'app.pages.interfaces.targetAttributeValue': 'Valor de atributo de destino',
  'app.pages.interfaces.toolbar.runNodeToPreviewResult':
    'Ejecutar nodo para previsualizar resultado',
  'app.pages.interfaces.toolbar.insertHereYourHtmlCode': 'Inserte aquí su código HTML',
  'app.pages.interfaces.toolbar.previewAvailableAfterTypingCode':
    'La vista previa estará disponible luego de la entrada de código',
  'app.pages.interfaces.toolbar.runPreviewHtml': 'Ejecutar vista previa HTML',
  'app.pages.interfaces.toolbar.inputValue.dataType': 'Tipo de dato',
  'app.pages.interfaces.toolbar.inputValue.rule': 'Regla',
  'app.pages.interfaces.toolbar.inputValue.rule.notRange': 'Fuera de rango',
  'app.pages.interfaces.toolbar.inputValue.rule.textLength': 'Longitud de texto',
  'app.pages.interfaces.toolbar.inputValue.rule.any': 'Cualquiera',
  'app.pages.interfaces.toolbar.dynamicHtml.openAssistant': 'Abrir asistente',
  'app.pages.interfaces.toolbar.dynamicHtml.inserAssistantId': 'Inserte id del asistente',

  // Interface pivot
  'app.pages.interfaces.pivot.x': 'X',
  'app.pages.interfaces.pivot.y': 'Y',
  'app.pages.interfaces.pivot.z': 'Z',
  'app.pages.interfaces.pivot.facet_row': 'Fila de facetas',
  'app.pages.interfaces.pivot.facet_column': 'Columna de facetas',
  'app.pages.interfaces.pivot.text': 'Texto',
  'app.pages.interfaces.pivot.animation_frame': 'Cuadro de animación',
  'app.pages.interfaces.pivot.animation_group': 'Grupo de animación',
  'app.pages.interfaces.pivot.error_x': 'Error X',
  'app.pages.interfaces.pivot.error_x_minus': 'Error X menos',
  'app.pages.interfaces.pivot.error_y': 'Error Y',
  'app.pages.interfaces.pivot.error_y_minus': 'Error Y menos',
  'app.pages.interfaces.pivot.error_z': 'Error Z',
  'app.pages.interfaces.pivot.error_z_minus': 'Error Z menos',
  'app.pages.interfaces.pivot.latitude': 'Latitud',
  'app.pages.interfaces.pivot.longitude': 'Longitud',
  'app.pages.interfaces.pivot.r': 'R',
  'app.pages.interfaces.pivot.theta': 'Theta',
  'app.pages.interfaces.pivot.measure_1': 'Medida 1',
  'app.pages.interfaces.pivot.measure_2': 'Medida 2',
  'app.pages.interfaces.pivot.measure_3': 'Medida 3',
  'app.pages.interfaces.pivot.color': 'Color',
  'app.pages.interfaces.pivot.size': 'Tamaño',
  'app.pages.interfaces.pivot.base': 'Base',
  'app.pages.interfaces.pivot.symbol': 'Símbolo',
  'app.pages.interfaces.pivot.line_group': 'Grupo de línea',
  'app.pages.interfaces.pivot.line_dash': 'Guión de línea',
  'app.pages.interfaces.pivot.pattern_shape': 'Forma de patrón',
  'app.pages.interfaces.pivot.dimensions': 'Dimensiones',
  'app.pages.interfaces.pivot.locations': 'Ubicaciones',
  'app.pages.interfaces.pivot.names': 'Nombres',
  'app.pages.interfaces.pivot.values': 'Valores',
  'app.pages.interfaces.pivot.x_start': 'X inicial',
  'app.pages.interfaces.pivot.x_end': 'X final',
  'app.pages.interfaces.pivot.path': 'Camino',
  'app.pages.interfaces.pivot.parents': 'Padres',
  'app.pages.interfaces.pivot.ids': 'IDs',
  'app.pages.interfaces.pivot.chart_type_1': 'Tipo de gráfico 1',
  'app.pages.interfaces.pivot.chart_type_2': 'Tipo de gráfico 2',
  'app.pages.interfaces.pivot.dimension': 'Dimensión',
  'app.pages.interfaces.pivot.measure': 'Medida',
  'app.pages.interfaces.pivot.rows': 'Filas',
  'app.pages.interfaces.pivot.columns': 'Columnas',
  'app.pages.interfaces.pivot.measures': 'Medidas',
  'app.pages.interfaces.pivot.series': 'Series',
  'app.pages.interfaces.pivot.sum': 'Suma',
  'app.pages.interfaces.pivot.avg': 'Promedio',
  'app.pages.interfaces.pivot.min': 'Mínimo',
  'app.pages.interfaces.pivot.max': 'Máximo',
  'app.pages.interfaces.pivot.count': 'Cuenta',
  'app.pages.interfaces.pivot.': 'No',
  'app.pages.interfaces.pivot.agg': 'Agregación',
  'app.pages.interfaces.pivot.newMeasure': 'Nueva medida',
  'app.pages.interfaces.pivot.newMeasureName': 'Nombre de nueva medida',
  'app.pages.interfaces.pivot.selectHierarchy': 'Seleccionar jerarquía',
  'app.pages.interfaces.pivot.editCalculatedMeasure': 'Editar medida calculada',
  'app.pages.interfaces.pivot.removeCalculatedMeasure': 'Eliminar medida calculada',
  'app.pages.interfaces.pivot.removeCalculatedMeasureMessage':
    'Estás seguro de querer eliminar "{field}"?',
  'app.pages.interfaces.pivot.function': 'Función',
  'app.pages.interfaces.pivot.calculationMode': 'Modo de cálculo',
  'app.pages.interfaces.pivot.histnorm': 'Normalización de histograma',
  'app.pages.interfaces.pivot.box': 'Caja',
  'app.pages.interfaces.pivot.marginal': 'Marginal',
  'app.pages.interfaces.pivot.calculatedMeasure': 'Medida calculada',
  'app.pages.interfaces.pivot.calculatedItem': 'Elemento calculado',
  'app.pages.interfaces.pivot.editCalculatedItem': 'Edit elemento calculado',
  'app.pages.interfaces.pivot.removeCalculatedItem': 'Eliminar elemento calculado',
  'app.pages.interfaces.pivot.removeCalculatedItemMessage':
    'Estás seguro de querer eliminar "{item}"?',
  'app.pages.interfaces.pivot.itemAlreadyExists': 'El elemento ya existe',
  'app.pages.interfaces.pivot.addSubtotal': 'Agregar subtotal',
  'app.pages.interfaces.pivot.removeSubtotal': 'Eliminar subtotal',
  'app.pages.interfaces.pivot.sortAscending': 'Ordenar de forma ascendente',
  'app.pages.interfaces.pivot.sortDescending': 'Ordenar de forma descendente',
  'app.pages.interfaces.pivot.removeSorting': 'Eliminar ordenamiento',

  //Code widget
  'app.pages.app.code.widget.code.saveAndRun': 'Grabar y Ejecutar Código',
  'app.pages.app.code.widget.code.formatCode': 'Formatear código',

  //Result widget
  'app.pages.app.code.widget.result.defaultview.saved':
    'La vista por defecto del nodo se ha guardado correctamente.',
  'app.pages.app.code.widget.result.notCalculated': 'El nodo no está calculado',
  'app.pages.app.code.widget.result.loading': 'Cargando resultados..',

  //Preview widget
  'app.pages.app.code.widget.preview.resultType': 'Tipo de resultado',
  'app.pages.app.code.widget.preview.indexes': 'Índices',
  'app.pages.app.code.widget.preview.preview': 'Vista previa',
  'app.pages.app.code.widget.preview.clickToOpenSparkUI': 'Click aquí para abrir Spark UI',

  //Console widget
  'app.pages.app.code.widget.console.preserveLog': 'Preservar log',
  'app.pages.app.code.widget.console.clearLogs': 'Borrar logs',
  'app.pages.app.code.widget.console.closeConsole': 'Cerrar consola',
  'app.pages.app.code.widget.console.showConsole': 'Abrir consola',
  'app.pages.app.code.widget.console.clearErrors': 'Limpiar errores',
  'app.pages.app.code.widget.console.copyError': 'Copiar error',
  'app.pages.app.code.widget.console.errorCopied': 'Error copiado',
  'app.pages.app.code.widget.console.performanceAnalysis': 'Análisis de rendimiento',
  'app.pages.app.code.widget.console.performance': 'Rendimiento',
  'app.pages.app.code.widget.console.percentOfTime': '% del tiempo',
  'app.pages.app.code.widget.console.fromDynamic': 'Desde dynamic',
  'app.pages.app.code.widget.console.maxMemory': 'Máximo de memoria',
  'app.pages.app.code.widget.console.usedMemory': 'Memoria usada',
  'app.pages.app.code.widget.console.processingTime': 'Tiempo de procesamiento',
  'app.pages.app.code.widget.console.enableAnalysis': 'Habilitar análisis',
  'app.pages.app.code.widget.console.disableAnalysis': 'Deshabilitar análisis',
  'app.pages.app.code.widget.console.clearResults': 'Limpiar resultados',
  'app.pages.app.code.widget.console.logs': 'Registros',
  'app.pages.app.code.widget.console.resetAppResults': 'Reiniciar resultados de la aplicación',

  //Task manager
  'app.pages.app.taskManager.task': 'Administrador de tareas',
  'app.pages.app.taskManager.addTaskScheduled': 'Crear tarea programada',
  'app.pages.app.taskManager.editTaskScheduled': 'Editar tarea programada',
  'app.pages.app.taskManager.createdBy': 'Creado por',
  'app.pages.app.taskManager.lastRunAt': 'Última ejecucion a las',
  'app.pages.app.taskManager.nextExecution': 'Siguiente ejecución',
  'app.pages.app.taskManager.lastStatus': 'Último estado',
  'app.pages.app.taskManager.appPool': 'App pool',
  'app.pages.app.taskManager.schedulerType': 'Tipo de planificador',
  'app.pages.app.taskManager.params': 'Parámetros',
  'app.pages.app.taskManager.enabled': 'Activada',
  'app.pages.app.taskManager.showTaskLog': 'Mostrar log de la tarea',
  'app.pages.app.taskManager.addAppPool': 'Crear app pool',
  'app.pages.app.taskManager.interval': 'Intervalo',
  'app.pages.app.taskManager.clocked': 'Registrado',
  'app.pages.app.taskManager.taskDeleted': 'Tarea eliminada',
  'app.pages.app.taskManager.deleteTask': 'Eliminar tarea {name}',
  'app.pages.app.taskManager.deleteTaskMessage': 'Estás seguro de eliminar la tarea?',
  'app.pages.app.taskManager.taskActivated': 'Tarea activada',
  'app.pages.app.taskManager.taskDeactivated': 'Tarea desactivada',
  'app.pages.app.taskManager.taskExecuted': 'Tarea ejecutada',
  'app.pages.app.taskManager.editAppPool': 'Editar app pool',
  'app.pages.app.taskManager.taskLogs': 'Registros de la tarea',
  'app.pages.app.taskManager.createdAt': 'Creado el',
  'app.pages.app.taskManager.state': 'Estado',
  'app.pages.app.taskManager.lastTaskExecutions': 'Últimas ejecuciones de la tarea',
  'app.pages.app.taskManager.runTask': 'Ejecutar tarea',
  'app.pages.app.taskManager.advancedFilters': 'Filtros avanzados',
  'app.pages.app.taskManager.taskLogsDetails': 'Detalles de los registros de la tarea',
  'app.pages.app.taskManager.refreshLogs': 'Refrescar registros',
  'app.pages.app.taskManager.taskDidNotRunYet': 'La tarea aún no ha sido ejecutada',
  'app.pages.app.taskManager.description': 'Descripción',
  'app.pages.app.taskManager.activity': 'Actividad',
  'app.pages.app.taskManager.info': 'Informacion',
  'app.pages.app.taskManager.periodicTaskLogNotFound': 'Logs de la tarea no encontrados',
  'app.pages.app.taskManager.success': 'Exitoso',
  'app.pages.app.taskManager.failure': 'Fallido',
  'app.pages.app.taskManager.maxDateError': 'La fecha máxima no puede ser menor a la fecha mínima',
  'app.pages.app.taskManager.minDate': 'Fecha mínima',
  'app.pages.app.taskManager.maxDate': 'Fecha máxima',
  'app.pages.app.taskManager.timeZones': 'Zona horaria',
  'app.pages.app.taskManager.clearForm': 'Limpiar formulario',
  'app.pages.app.taskManager.insertTheTaskName': 'Ingrese el nombre de la tarea',
  'app.pages.app.taskManager.disabled': 'Desactivada',
  'app.pages.app.taskManager.days': 'Dia',
  'app.pages.app.taskManager.hours': 'Hora',
  'app.pages.app.taskManager.minutes': 'Minuto',
  'app.pages.app.taskManager.seconds': 'Segundos',
  'app.pages.app.taskManager.microseconds': 'Microsegundos',
  'app.pages.app.taskManager.every': 'Cada',
  'app.pages.app.taskManager.period': 'Periodo',
  'app.pages.app.taskManager.everyPlaceholder': 'Por favor ingrese un valor entero',
  'app.pages.app.taskManager.appPath': 'Ruta de la aplicación',
  'app.pages.app.taskManager.generalOptions': 'Opciones generales',
  'app.pages.app.taskManager.scheduler': 'Planificador',
  'app.pages.app.taskManager.everyMinute': 'Cada minuto',
  'app.pages.app.taskManager.minutesPlaceholder': 'Selecciona los minutos',
  'app.pages.app.taskManager.everyHour': 'Cada hora',
  'app.pages.app.taskManager.hoursPlaceholder': 'Selecciona las horas',
  'app.pages.app.taskManager.dayMonths': 'Día del mes',
  'app.pages.app.taskManager.everyDayOfMonths': 'Cada dia del mes',
  'app.pages.app.taskManager.everyMonths': 'Cada mes',
  'app.pages.app.taskManager.everyDays': 'Cada día',
  'app.pages.app.taskManager.dayMonthsPlaceholder': 'Selecciona los días del mes',
  'app.pages.app.taskManager.months': 'Mes',
  'app.pages.app.taskManager.monthsPlaceholder': 'Selecciona los meses',
  'app.pages.app.taskManager.daysPlaceholder': 'Selecciona los días',
  'app.pages.app.taskManager.january': 'Enero',
  'app.pages.app.taskManager.february': 'Febrero',
  'app.pages.app.taskManager.march': 'Marzo',
  'app.pages.app.taskManager.april': 'Abril',
  'app.pages.app.taskManager.may': 'Mayo',
  'app.pages.app.taskManager.june': 'Junio',
  'app.pages.app.taskManager.july': 'Julio',
  'app.pages.app.taskManager.august': 'Agosto',
  'app.pages.app.taskManager.september': 'Septiembre',
  'app.pages.app.taskManager.october': 'Octubre',
  'app.pages.app.taskManager.november': 'Noviembre',
  'app.pages.app.taskManager.december': 'Diciembre',
  'app.pages.app.taskManager.monday': 'Lunes',
  'app.pages.app.taskManager.tuesday': 'Martes',
  'app.pages.app.taskManager.wednesday': 'Miércoles',
  'app.pages.app.taskManager.thursday': 'Jueves',
  'app.pages.app.taskManager.friday': 'Viernes',
  'app.pages.app.taskManager.saturday': 'Sábado',
  'app.pages.app.taskManager.sunday': 'Domingo',
  'app.pages.app.taskManager.at': '{at}',
  'app.pages.app.taskManager.on': 'el ',
  'app.pages.app.taskManager.in': 'en ',
  'app.pages.app.taskManager.pastHour': 'Pasado las ',
  'app.pages.app.taskManager.andOn': 'y los ',
  'app.pages.app.taskManager.and': 'y',
  'app.pages.app.taskManager.reset': 'Resetear',
  'app.pages.app.taskManager.nameIsRequired': 'El nombre de la tarea es requerido',
  'app.pages.app.taskManager.nameMaxLength':
    'El tamaño máximo del nombre es de {number} caracteres',
  'app.pages.app.taskManager.schedulerTypeIsRequired': 'El tipo de calendario es requerido',
  'app.pages.app.taskManager.periodIsRequired': 'Periodo es requerido',
  'app.pages.app.taskManager.everyIsRequired': 'Intervalo de tiempo es requerido',
  'app.pages.app.taskManager.everyMustBeInt': 'EL intervalo de tiempo debe ser un número entero',
  'app.pages.app.taskManager.everyMaxLength':
    'EL intervalo de tiempo no puede ser mayor a {number}',
  'app.pages.app.taskManager.clockedIsRequired': 'Hora del reloj es requerida',
  'app.pages.app.taskManager.nodeIsRequired': 'Nodo a evaluar es requerido',
  'app.pages.app.taskManager.appPathIsRequired': 'Ubicación de la aplicación es requerida',
  'app.pages.app.taskManager.type': 'Tipo',
  'app.pages.app.taskManager.label': 'Etiqueta',
  'app.pages.app.taskManager.defaultValue': 'Valor por defecto',
  'app.pages.app.taskManager.source': 'Fuente',
  'app.pages.app.taskManager.paramsTable': 'Tabla de parámetros',
  'app.pages.app.taskManager.noParamsData': 'No hay parámetros',
  'app.pages.app.taskManager.select': 'Selección',
  'app.pages.app.taskManager.input': 'Entrada',
  'app.pages.app.taskManager.checkbox': 'Caja de verificación',
  'app.pages.app.taskManager.paramNameIsRequired': 'El nombre del parámetro es requerido',
  'app.pages.app.taskManager.labelIsRequired': 'La etiqueta es requerida',
  'app.pages.app.taskManager.paramNameInvalid':
    'Debe contener letras en minúsculas y/o números.Espacios en blanco y guiones no están permitidos',
  'app.pages.app.taskManager.paramNamePlaceholder': 'Ingrese el nombre del parámetro',
  'app.pages.app.taskManager.labelPlaceholder': 'Ingrese la etiqueta',
  'app.pages.app.taskManager.defaultValuePlaceholder': 'Ingrese el valor por defecto',
  'app.pages.app.taskManager.sourcePlaceholder': 'Ingrese la fuente',
  'app.pages.app.taskManager.taskNameExists': 'El nombre de la tarea ya existe',
  'app.pages.app.taskManager.taskCreated': 'Tarea creada',
  'app.pages.app.taskManager.taskUpdated': 'Tarea actualizada',
  'app.pages.app.taskManager.keepForEveryone': 'Mantener para todos',
  'app.pages.app.taskManager.keepForUser': 'Mantener para el usuario',
  'app.pages.app.taskManager.actionOnDone': 'Acción al finalizar',
  'app.pages.app.taskManager.instances': 'Instancias',
  'app.pages.app.taskManager.enableInstanceExpire': 'Habilitar expirar la instancia',
  'app.pages.app.taskManager.expireAt': 'Expira a las (UTC)',
  'app.pages.app.taskManager.selectModelFIle': 'Elegir el archivo del modelo',
  'app.pages.app.taskManager.node': 'Nodo',
  'app.pages.app.taskManager.noNodesFounded': 'No se han encontrado nodos',
  'app.pages.app.taskManager.clockedWithTz': 'Registrado UTC {tz}',
  'app.pages.app.taskManager.appPoolCreated': 'App pool creada',
  'app.pages.app.taskManager.appPoolUpdated': 'App pool actualizada',
  'app.pages.app.taskManager.actionIsRequired': 'Acción al finalizar es requerido',
  'app.pages.app.taskManager.instancesPositive':
    'El valor de las instancias debe ser un número positivo',
  'app.pages.app.taskManager.instancesIsRequired': 'Instancias son requeridas',
  'app.pages.app.taskManager.userIsRequired': 'El usuario es requerido',
  'app.pages.app.taskManager.users': 'Usuarios',
  'app.pages.app.taskManager.usersPlaceholder': 'Elegir un usuario',
  'app.pages.app.taskManager.timezoneIsRequired': 'La zona horaria es requerida',
  'app.pages.app.taskManager.noTasksFound': 'Tareas no encontradas',
  'app.pages.app.taskManager.taskUser': 'Usuario de la tarea',
  'app.pages.app.taskManager.systemTask': 'Sistema',
  'app.pages.app.taskManager.noPermissionsToListTasks':
    'No tienes permisos para listar las tareas programadas',
  'app.pages.app.taskManager.resources': 'Recursos',
  'app.pages.app.taskManager.useDepartment': 'Recursos del departamento',

  //Running node
  'app.pages.app.runningNodeModel.runningNode': 'Evaluando nodo',
  'app.pages.app.runningNodeModel.stopping': 'Deteniendo',

  //Saving app
  'app.pages.savingApp.savingApp': 'Guardando aplicación',

  //Interface Manager
  'app.pages.interfaceManager': 'Administrador de interfaces',
  'app.pages.interfaceManager.newInterface': 'Nueva interfaz',
  'app.pages.interfaceManager.createNewInterface': 'Crear nueva interfaz',
  'app.pages.interfaceManager.editInterface': 'Editar interfaz',
  'app.pages.interfaceManager.createNewFolder': 'Crear nueva carpeta',
  'app.pages.interfaceManager.name': 'Nombre de la interfaz',
  'app.pages.interfaceManager.folderName': 'Nombre de la carpeta',
  'app.pages.interfaceManager.deleteInterface': 'Eliminar interfaces',
  'app.pages.interfaceManager.deleteFolder': 'Eliminar carpeta',
  'app.pages.interfaceManager.deleteInterfaceConfirmationText':
    '¿Estás seguro de eliminar definitivamente esta interfaz?',
  'app.pages.interfaceManager.deleteFolderConfirmationText':
    '¿Estás seguro de eliminar definitivamente esta carpeta y todo su contenido?',
  'app.pages.interfaceManager.myInterfaces': 'Mis interfaces',
  'app.pages.interfaceManager.noInterfaces':
    'No hay interfaces disponibles. Cree una usando el botón Crear',
  'app.pages.interfaceManager.publicInterface': 'Pública',
  'app.pages.interfaceManager.setInterfaceVisibility': 'Establecer la visibilidad de la interfaz',
  'app.pages.interfaceManager.deniedFor': 'Denegado para',
  'app.pages.interfaceManager.allowedFor': 'Permitido para',
  'app.pages.interfaceManager.deniedForDetail': 'Denegar acceso a',
  'app.pages.interfaceManager.slashNotAllowed':
    'Caracter inválido. "/" no está permitido en el nombre de la interfaz',
  'app.pages.interfaceManager.interfaceNameRepeated': 'El nombre de la interfaz ya existe',
  'app.pages.interfaceManager.removeDocumentation': 'Eliminar documentación',
  'app.pages.interfaceManager.removeDocumentationConfirm':
    '¿Estás seguro de eliminar la documentación?',
  'app.pages.interfaceManager.shareInterface': 'Compartir interfaz',
  'app.pages.interfaceManager.interfaceLinkCopied': 'Enlace de interfaz copiado',
  'app.pages.interfaceManager.copyInterfaceLink': 'Copiar enlace de interfaz',
  'app.pages.interfaceManager.interfaceLink': 'Enlace de interfaz',
  'app.pages.interfaceManager.interfacesLinks': 'Enlaces de interfaces',
  'app.pages.interfaceManager.interfacesLinks.notFound': 'No se encontraron enlaces de interfaces',
  'app.pages.interfaceManager.activateInterfaceEndpoint': 'Activar interface endpoint',
  'app.pages.interfaceManager.updateInterfaceLink': 'Actualizar enlace de interfaz',
  'app.pages.interfaceManager.interfaceLinkUpdated': 'Enlace de interfaz actualizado con éxito',
  'app.pages.interfaceManager.deleteInterfaceLink': 'Eliminar enlace de interfaz: {interfaceName}',
  'app.pages.interfaceManager.deleteInterfaceLinkConfirmation':
    '¿Estás seguro de eliminar el enlace de la interfaz?',
  'app.pages.interfaceManager.interfaceLinkDeleted': 'Enlace de interfaz eliminado con éxito',
  'app.pages.interfaceManager.folderNameExists': 'El nombre de la carpeta ya existe',
  'app.pages.externalLink.customEndpointExists': 'El endpoint personalizado ya existe',
  'app.pages.externalLink.passwordRequiredDescription':
    'Por favor ingrese la contraseña para continuar',
  'app.pages.externalLink.hasPassword': 'Tiene contraseña',
  'app.pages.externalLink.requirePassword': 'Requiere contraseña',

  //File manager
  'app.pages.FileManagerPage.download': 'Descargar',
  'app.pages.FileManagerPage.zipIt': 'Comprimir',
  'app.pages.FileManagerPage.copyIn': 'Copiar',
  'app.pages.FileManagerPage.archive': 'Archivo',
  'app.pages.FileManagerPage.denyAccess': 'Denegar accesos',
  'app.pages.FileManagerPage.folder': 'Carpeta',
  'app.pages.FileManagerPage.selectAll': 'Seleccionar todos',
  'app.pages.FileManagerPage.unSelectAll': 'Deseleccionar todos',
  'app.pages.FileManagerPage.models': 'Modelos (.ppl)',
  'app.pages.FileManagerPage.csv': 'CSV {extension}',
  'app.pages.FileManagerPage.templates': 'Plantillas(.xls,.xlsx,.xlsm)',
  'app.pages.FileManagerPage.database': 'Base de datos {extension}',
  'app.pages.FileManagerPage.modules': 'Módulos (.ppm)',
  'app.pages.FileManagerPage.interfaces': 'Interfaces',
  'app.pages.FileManagerPage.zipFiles': 'Archivo zip {extension}',
  'app.pages.FileManagerPage.jsonFiles': 'Archivo Json {extension}',
  'app.pages.FileManagerPage.backup': 'Recuperar (.old)',
  'app.pages.FileManagerPage.others': 'Otros',
  'app.pages.FileManagerPage.showHidden': 'Mostrar archivos/carpetas ocultas',
  'app.pages.FileManagerPage.filterBy': 'Filtrar por',
  'app.pages.FileManagerPage.model': 'Modelo',
  'app.pages.FileManagerPage.file': 'Archivo',
  'app.pages.FileManagerPage.type': 'Tipo',
  'app.pages.FileManagerPage.location': 'Ubicación',
  'app.pages.FileManagerPage.opened': 'Abierto',
  'app.pages.FileManagerPage.size': 'Tamaño',
  'app.pages.FileManagerPage.getSize': 'Obtener tamaño',
  'app.pages.FileManagerPage.date': 'Fecha',
  'app.pages.FileManagerPage.access': 'Accesos',
  'app.pages.FileManagerPage.fileName': 'Nombre del archivo',
  'app.pages.FileManagerPage.folders': 'Carpetas',
  'app.pages.FileManagerPage.files': 'Archivos',
  'app.pages.FileManagerPage.root': 'Inicio',
  'app.pages.FileManagerPage.listView': 'Vista lista',
  'app.pages.FileManagerPage.mosaicView': 'Vista mosaico',
  'app.pages.FileManagerPage.folderEmpty': 'La carpeta está vacía',
  'app.pages.FileManagerPage.denyAccessTo': 'Denegar acceso',
  'app.pages.FileManagerPage.allowAccessTo': 'Permitir acceso',
  'app.pages.FileManagerPage.allowAccessOnlyTo': 'Permitir acceso sólo a',
  'app.pages.FileManagerPage.deletedMessage': '{name} eliminado',
  'app.pages.FileManagerPage.wantToDeleteFolder': 'Estás seguro de eliminar la carpeta?',
  'app.pages.FileManagerPage.wantToDeleteFile': 'Estás seguro de eliminar el archivo?',
  'app.pages.FileManagerPage.deletedTitle': 'Eliminar {name}',
  'app.pages.FileManagerPage.wantToZipFile': 'Estás seguro de comprimir este archivo?',
  'app.pages.FileManagerPage.zipFile': 'Comprimir {name}',
  'app.pages.FileManagerPage.unZipFile': 'Descomprimir {name}',
  'app.pages.FileManagerPage.fileZipped': '{name} comprimido',
  'app.pages.FileManagerPage.fileUnZipped': '{name} descomprimido',
  'app.pages.FileManagerPage.nodeChanged': 'Nombre cambiado a {name}',
  'app.pages.FileManagerPage.wantToUnZipFile': 'Estás seguro que querés descomprimir este archivo?',
  'app.pages.FileManagerPage.unzip': 'Descomprimir',
  'app.pages.FileManagerPage.move': 'Mover',
  'app.pages.FileManagerPage.fileCloned': '{name} duplicado',
  'app.pages.FileManagerPage.createFolderTitle': 'Establecer nombre de carpeta',
  'app.pages.FileManagerPage.folderCreated': 'Carpeta creada',
  'app.pages.FileManagerPage.folderName': 'Nombre de carpeta',
  'app.pages.FileManagerPage.deletedFilesMessage': 'Archivos o carpetas borradas',
  'app.pages.FileManagerPage.copyFileIn': 'Copiar {name} en',
  'app.pages.FileManagerPage.upload': 'Subir',
  'app.pages.FileManagerPage.wantToDelete': 'Estás seguro de eliminar :',
  'app.pages.FileManagerPage.duplicatedFiles': 'Archivos duplicados',
  'app.pages.FileManagerPage.zipTitle': 'Comprimir archivos',
  'app.pages.FileManagerPage.wantToZipFileS':
    'Estás seguro de comprimir los archivos seleccionados?',
  'app.pages.FileManagerPage.departmentsRestrictions': 'Departamento/s restringidos',
  'app.pages.FileManagerPage.folderNameError':
    'El nombre de la carpeta es requerido y no puede estar vacío.',
  'app.pages.FileManagerPage.folderNameErrorSlash': 'El nombre de la carpeta no puede tener barra',
  'app.pages.FileManagerPage.newNameIsRequired': 'El nombre es requerido y no puedo estar vacío.',
  'app.pages.FileManagerPage.filesUploaded': 'Archivos subidos',
  'app.pages.FileManagerPage.filesZipped': 'Archivos comprimidos',
  'app.pages.FileManagerPage.filesToCopy': 'Archivos para copiar',
  'app.pages.FileManagerPage.pasteHere': 'Pegar aquí',
  'app.pages.FileManagerPage.pleaseSelectAFile':
    'Seleccione una carpeta o archivo para ver sus detalles aquí.',
  'app.pages.FileManagerPage.filesCopied': 'Archivos copiados',
  'app.pages.FileManagerPage.filesMoved': 'Archivos movidos',
  'app.pages.FileManagerPage.filesDownloaded': 'Archivos descargados',
  'app.pages.FileManagerPage.uploading': 'Subiendo...',
  'app.pages.FileManagerPage.uploaded': 'Archivos cargados exitosamente!',
  'app.pages.FileManagerPage.compressing': 'Comprimiendo...',
  'app.pages.FileManagerPage.unzipping': 'Descomprimiendo...',
  'app.pages.FileManagerPage.duplicating': 'Duplicando...',
  'app.pages.FileManagerPage.loadingLastPath': 'Cargando última ruta..',
  'app.pages.FileManagerPage.uploadCancelled': 'Subida cancelada por el usuario',
  'app.pages.FileManagerPage.deleting': 'Eliminando',
  'app.pages.FileManagerPage.creating': 'Creando la carpeta',
  'app.pages.FileManagerPage.pastingFiles': 'Pegando archivos',
  'app.pages.FileManagerPage.configuringAccess': 'Configurando accesso',
  'app.pages.FileManagerPage.downloading': 'Descargando',
  'app.pages.FileManagerPage.duplicateTitle': 'Duplicar {name}',
  'app.pages.FileManagerPage.wantToDuplicate':
    'Estás seguro de duplicar los archivos seleccionados?',
  'app.pages.FileManagerPage.dragDropFilesHere': 'Arrastre y suelte los archivos aquí o',
  'app.pages.FileManagerPage.overWriteFileOrFolder': 'Sobrescribir archivo o carpeta',
  'app.pages.FileManagerPage.overWriteFileOrFolderMessage':
    '¿Estás seguro de querer sobrescribir {name}?',
  'app.pages.FileManagerPage.clearClipboard': 'Vaciar portapapeles',
  'app.pages.FileManagerPage.clipboardCleared': 'Portapapeles vacio',
  'app.pages.FileManagerPage.copyToWorkspaceTitle': 'Copiar en espacio de trabajo',
  'app.pages.FileManagerPage.copyToWorkspaceMessage':
    '¿Estás seguro de querer copiar {name} a tu espacio de trabajo?',
  'app.pages.FileManagerPage.copyingToWorkspace': 'Copiando en espacio de trabajo',
  'app.pages.FileManagerPage.copiedToWorkspace': 'Copiado en espacio de trabajo',
  'app.pages.FileManagerPage.openTextEditor': 'Abrir editor de texto',
  'app.pages.FileManagerPage.loadingFile': 'Cargando archivo',
  'app.pages.FileManagerPage.savingFile': 'Guardando archivo',
  'app.pages.FileManagerPage.fileSaved': 'Archivo guardado',
  'app.pages.FileManagerPage.fileTooLargeToRead':
    'El archivo es demasiado grande para leer. Tamaño máximo: 1MB',
  'app.pages.FileManagerPage.copyUrl': 'Copiar url',
  'app.pages.FileManagerPage.urlCopied': 'URL copiada',
  'app.pages.FileManagerPage.errorUploading': 'Ocurrió un error subiendo el archivo',

  //Actions (for logManager filter)
  'app.pages.managers.logManager.actions.openApp': 'Abrir aplicación',
  'app.pages.managers.logManager.actions.navigateDiagram': 'Navegar diagrama',
  'app.pages.managers.logManager.actions.getArrows': 'Obtener flechas',
  'app.pages.managers.logManager.actions.getToolbars': 'Obtener toolbars',
  'app.pages.managers.logManager.actions.lastOpenApps': 'Últimas aplicaciones abiertas',
  'app.pages.managers.logManager.actions.changeToOtherInstance': 'Cambiar a otra instancia',
  'app.pages.managers.logManager.actions.getNodeProperties': 'Obtener propiedades del nodo',
  'app.pages.managers.logManager.actions.setNodeProperties': 'Establecer propiedades del nodo',
  'app.pages.managers.logManager.actions.setNodesProperties': 'Establecer propiedades de los nodos',
  'app.pages.managers.logManager.actions.getNodeInputs': 'Obtener inputs del nodo',
  'app.pages.managers.logManager.actions.getNodeOutputs': 'Obtener outputs del nodo',
  'app.pages.managers.logManager.actions.searchNodes': 'Buscar nodos',
  'app.pages.managers.logManager.actions.searchForAutocomplete': 'Buscar autocompletar',
  'app.pages.managers.logManager.actions.previewNode': 'Previsualizar nodo',
  'app.pages.managers.logManager.actions.getSelector': 'Obtener selector',
  'app.pages.managers.logManager.actions.setNodesSize': 'Establecer tamaño del nodo',
  'app.pages.managers.logManager.actions.setNodesPosition': 'Establecer posición del nodo',
  'app.pages.managers.logManager.actions.getNodeProfile': 'Obtener perfil del nodo',
  'app.pages.managers.logManager.actions.createNode': 'Crear nodo',
  'app.pages.managers.logManager.actions.deleteNodes': 'Borrar Nodos',
  'app.pages.managers.logManager.actions.createAlias': 'Crear alias',
  'app.pages.managers.logManager.actions.createInputNode': 'Crear nodo input',
  'app.pages.managers.logManager.actions.copyNodes': 'Copiar nodos',
  'app.pages.managers.logManager.actions.copyAsValues': 'Copiar como valores',
  'app.pages.managers.logManager.actions.moveNodes': 'Mover nodos',
  'app.pages.managers.logManager.actions.stop': 'Detener',
  'app.pages.managers.logManager.actions.setNodeZ': 'Establecer Z del nodo',
  'app.pages.managers.logManager.actions.setNodeIdFromTitle':
    'Establecer identificador del nodo desde título',
  'app.pages.managers.logManager.actions.executeForRefresh': 'Ejecutar para actualizar',
  'app.pages.managers.logManager.actions.exportFlatNode': 'Exportar nodo plano',
  'app.pages.managers.logManager.actions.exportCurrentNode': 'Exportar nodo actual',
  'app.pages.managers.logManager.actions.exportModuleToFile': 'Exportar módulo a archivo',
  'app.pages.managers.logManager.actions.importModuleFromFile': 'Importar módulo desde archivo',
  'app.pages.managers.logManager.actions.getFilesForImportWizard':
    'Obtener archivos desde el asistente de importación',
  'app.pages.managers.logManager.actions.callWizard': 'LLamar al asistente',
  'app.pages.managers.logManager.actions.executeButton': 'Ejecutar botón',
  'app.pages.managers.logManager.actions.isInBackground': 'Está en el fondo',
  'app.pages.managers.logManager.actions.uploadFileToTemp': 'Subir modelo a Temp',
  'app.pages.managers.logManager.actions.installLibrary': 'Instalar librerías',
  'app.pages.managers.logManager.actions.listInstalledLibraries': 'Listar librerías instaladas',
  'app.pages.managers.logManager.actions.uninstallLibrary': 'Desintalar librerías',
  'app.pages.managers.logManager.actions.getInstallProgress': 'Obtener progreso de instalación',
  'app.pages.managers.logManager.actions.getMainFolders': 'Obtener carpetas principales',
  'app.pages.managers.logManager.actions.getFiles': 'Obtener archivos',
  'app.pages.managers.logManager.actions.createFolder': 'Crear carpeta',
  'app.pages.managers.logManager.actions.copyFileOrFolder': 'Copiar archivo o carpeta',
  'app.pages.managers.logManager.actions.renameFile': 'Renombrar archivo',
  'app.pages.managers.logManager.actions.duplicateFiles': 'Duplicar archivos',
  'app.pages.managers.logManager.actions.moveFiles': 'Mover archivos',
  'app.pages.managers.logManager.actions.copyFiles': 'Copiar archivos',
  'app.pages.managers.logManager.actions.deleteFiles': 'Borrar archivos',
  'app.pages.managers.logManager.actions.upload': 'Subir',
  'app.pages.managers.logManager.actions.unzipFile': 'Descomprimir archivos',
  'app.pages.managers.logManager.actions.zipFiles': 'Comprimir archivos',
  'app.pages.managers.logManager.actions.getHome': 'Obtener el inicio',
  'app.pages.managers.logManager.actions.optimizeTemplates': 'Optimizar plantillas',
  'app.pages.managers.logManager.actions.myReports': 'Mis reportes',
  'app.pages.managers.logManager.actions.sharedWithMe': 'Reportes compartidos conmigo',
  'app.pages.managers.logManager.actions.mySharedReports': 'Mis reportes compartidos',
  'app.pages.managers.logManager.actions.reportManager': 'Endpoint de reportes',
  'app.pages.managers.logManager.actions.getNavigator': 'Reportes - Obtener navegador',
  'app.pages.managers.logManager.actions.bulkDelete': 'Reportes - Eliminación masiva',
  'app.pages.managers.logManager.actions.changeOrder': 'Reportes - Cambair orden',
  'app.pages.managers.logManager.actions.search': 'Reportes - Buscar',
  'app.pages.managers.logManager.actions.duplicateItems': 'Reportes - Duplicar items',
  'app.pages.managers.logManager.actions.copyToMyReports': 'Reportes - Copiar a mis reportes',
  'app.pages.managers.logManager.actions.setAsFav': 'Reportes - Establecer como favorito',
  'app.pages.managers.logManager.actions.dropOnReport': 'Reportes - Soltar en el reporte',
  'app.pages.managers.logManager.actions.exportItems': 'Reportes - Exportar items',
  'app.pages.managers.logManager.actions.importItems': 'Reportes - Importar items',
  'app.pages.managers.logManager.actions.publishItems': 'Reportes - Publicar items',
  'app.pages.managers.logManager.actions.shares': 'Obtener shares', //que es get shares
  'app.pages.managers.logManager.actions.setShares': 'Establecer shares', //que es set shares
  'app.pages.managers.logManager.actions.myDashboards': 'Mis tableros', //Uso "dashboards" o "tableros"
  'app.pages.managers.logManager.actions.companyDashboards': 'Tableros - Tableros de la compañía',
  'app.pages.managers.logManager.actions.dashboardsSharedWithMe': 'Tableros - Compartidos conmigo',
  'app.pages.managers.logManager.actions.allMyDashboards': 'Tableros - Todos mis tableros',
  'app.pages.managers.logManager.actions.mySharedDashboards': 'Tableros - Mis tableros compartidos',
  'app.pages.managers.logManager.actions.dashboardById': 'Tableros - Por identificacion',
  'app.pages.managers.logManager.actions.getNodeFullData':
    'Tableros - Obtener todos los datos del nodo',
  'app.pages.managers.logManager.actions.evaluateNode': 'Tableros - Evaluar nodo',
  'app.pages.managers.logManager.actions.getOrCreate': 'Tableros - Obtener o crear',
  'app.pages.managers.logManager.actions.dashboardManager': 'Endpoints de tableros',
  'app.pages.managers.logManager.actions.dashboardBulkDelete': 'Tableros - Eliminación masiva',
  'app.pages.managers.logManager.actions.dashboardChangeOrder': 'Tableros - Cambiar orden',
  'app.pages.managers.logManager.actions.getIndexValues': 'Tableros - Obtener valores índices',
  'app.pages.managers.logManager.actions.getNodeIndexes': 'Tableros - Obtener índices de nodos',
  'app.pages.managers.logManager.actions.isResultComputed':
    'Tableros - Está calculado el resultado',
  'app.pages.managers.logManager.actions.lastOpenDashboards':
    'Tableros - Últimos tableros abiertos',
  'app.pages.managers.logManager.actions.copy': 'Tableros - Copiar',
  'app.pages.managers.logManager.actions.getCubeMetadata':
    'Tableros - Pivote - Obtener metadata del cube', //como traducir cube
  'app.pages.managers.logManager.actions.getCubeValues':
    'Tableros - Pivote - Obtener valores del cube',
  'app.pages.managers.logManager.actions.setCubeChanges':
    'Tableros - Pivote - Establecer cambios del cube',
  'app.pages.managers.logManager.actions.getDimensionValues':
    'Tableros - Pivote - Obtener valores de dimensión',
  'app.pages.managers.logManager.actions.tokenAuthEndpoints': 'Endpoints del Token Auth',
  'app.pages.managers.logManager.actions.sendPasswordResetEmail':
    'Enviar correo de restablecimiento de contraseña',
  'app.pages.managers.logManager.actions.passwordReset': 'Restablecimiento de contraseña',
  'app.pages.managers.logManager.actions.authEndpoints': 'Endpoints del Auth',
  'app.pages.managers.logManager.actions.createInstance': 'Crear instancia', //uso sesion o instancia
  'app.pages.managers.logManager.actions.getInstance': 'Obtener instancia',
  'app.pages.managers.logManager.actions.logout': 'Cerrar sesión',
  'app.pages.managers.logManager.actions.getAllInstances': 'Obtener todas las instancias',
  'app.pages.managers.logManager.actions.getMyInstances': 'Obtener mis instancias',
  'app.pages.managers.logManager.actions.killInstanceByKey': 'Matar instancia por llave', //matar sesion por llave¿?
  'app.pages.managers.logManager.actions.createNewInstance': 'Crear nueva instancia',
  'app.pages.managers.logManager.actions.useExternalLink': 'Usar link externo',
  'app.pages.managers.logManager.actions.getSystemResources': 'Obtener recursos del sistema',
  'app.pages.managers.logManager.actions.requireLogout': 'Requerir cierre de sesión',
  'app.pages.managers.logManager.actions.getGeoData': 'Geo - Obtener información Geo',
  'app.pages.managers.logManager.actions.getGeoDetail': 'Geo - Obtener detalle Geo',
  'app.pages.managers.logManager.actions.getBubbleInfo': 'Geo - Obtener información Burbuja',
  'app.pages.managers.logManager.actions.inputForm': 'Plantilla de entrada - Listar', //input o entradas
  'app.pages.managers.logManager.actions.getMetadata': 'Formularios de entrada - Obtener metadata',
  'app.pages.managers.logManager.actions.getData': 'Plantilla de entrada - Obtener información',
  'app.pages.managers.logManager.actions.setData': 'Plantilla de entrada - Establecer información',
  'app.pages.managers.logManager.actions.result': 'Endpoints de Resultado',
  'app.pages.managers.logManager.actions.resultByNodeId': 'Resultado - Por identificador del nodo',
  'app.pages.managers.logManager.actions.activities': 'Endpoints de actividades',
  'app.pages.managers.logManager.actions.groups': 'Endpoints de grupos',
  'app.pages.managers.logManager.actions.updateGroupsPermissions':
    'Grupos - Actualizar permisos de grupos',
  'app.pages.managers.logManager.actions.users': 'Endpoints de usuarios',
  'app.pages.managers.logManager.actions.companies': 'Endpoints de compañías',
  'app.pages.managers.logManager.actions.preferenceByCode': 'Compañías - Preferencias por código',
  'app.pages.managers.logManager.actions.preferences': 'Compañías - Preferencias',
  'app.pages.managers.logManager.actions.usercompanies': 'Endpoints de compañías de usuario',
  'app.pages.managers.logManager.actions.listByCompanyId':
    'Compañías de usuario - Listar por identificador de compañía',
  'app.pages.managers.logManager.actions.userPreferenceByCode':
    'Compañías de usuario - Preferencias por código',
  'app.pages.managers.logManager.actions.userPreferences': 'Compañías de usuario - Preferences',
  'app.pages.managers.logManager.actions.permissions': 'Endpoints de permisos',
  'app.pages.managers.logManager.actions.departments': 'Endpoints de departamentos',
  'app.pages.managers.logManager.actions.byCurrentCompany': 'Departmentos - Por compañía actual',
  'app.pages.managers.logManager.actions.denied': 'Departmentos - Denegados',
  'app.pages.managers.logManager.actions.denyItems': 'Departmentos - Denegar item',
  'app.pages.managers.logManager.actions.dashboardComments': 'Endpoints de comentarios del tablero',
  'app.pages.managers.logManager.actions.dashboardStyles': 'Endpoints de estilado del tablero',
  'app.pages.managers.logManager.actions.diagramShortcut': 'Endpoints de atajo del tablero',
  'app.pages.managers.logManager.actions.preferenceModules': 'Endpoints de módulos de preferencia',
  'app.pages.managers.logManager.actions.preferencesEndpoints': 'Endpoints de preferencia',
  'app.pages.managers.logManager.actions.userCompanyPreferences':
    'Endpoints de preferencia de usuario de compañía',
  'app.pages.managers.logManager.actions.companyPreferences':
    'Endpoints de preferencias de compañía',
  'app.pages.managers.logManager.actions.apiEndpoints': 'Puntos de acceso a la Api',
  'app.pages.managers.logManager.actions.reportExternalLink':
    'Endpoints de enlaces de reportes externos',
  'app.pages.managers.logManager.actions.dashboardExternalLink':
    'Endpoints de enlaces de tableros externos',
  'app.pages.managers.logManager.actions.scheduler': 'Endpoints de planificador', //scheduler = planificador o planificador de tareas
  'app.pages.managers.logManager.actions.getPythonTimezones':
    'Planificador - Obtener zonas horarias',
  'app.pages.managers.logManager.actions.taskLog': 'Endpoints del registro de tareas',
  'app.pages.managers.logManager.actions.byPeriodicTask':
    'Registro de tareas - Por tareas periódicas',
  'app.pages.managers.logManager.actions.filterLogs': 'Registro de tareas - Filtrar registros',
  'app.pages.managers.logManager.actions.logging': 'Endpoints de registros',
  'app.pages.managers.logManager.actions.restAuth': 'Endpoints de Rest Auth',
  'app.pages.managers.logManager.actions.accounts': 'Endpoints de cuenta',

  //Applications
  'app.pages.applications.createApp': 'Crear aplicación',
  'app.pages.applications.applicationName': 'Nombre de la aplicación',
  'app.pages.applications.fromScratch': 'Desde cero',
  'app.pages.applications.fromScratchDescription': 'Comienza con un simple ejemplo de "Hola mundo"',
  'app.pages.applications.fromDataAnalytics': 'Análisis de datos',
  'app.pages.applications.fromDataAnalyticsDescription':
    'Ejemplo de una aplicación de análisis de datos',
  'app.pages.applications.fromBusinessPlanning': 'Planeamiento de negocios',
  'app.pages.applications.fromBusinessPlanningDescription':
    'Ejemplo de una aplicación de planeamiento de negocios',
  'app.pages.applications.applicationManager': 'Administrador de aplicaciones',
  'app.pages.applications.deleteAppTitle': 'Eliminar aplicación',
  'app.pages.applications.deleteAppMessage': 'Estás seguro de eliminar esta aplicación?',
  'app.pages.applications.results': 'Resultados',
  'app.pages.applications.applicationDeleted': 'Aplicación borrada',
  'app.pages.applications.deletingApplication': 'Borrando aplicación',
  'app.pages.applications.applicationNameChanged': 'Nombre de aplicación renombrado.',
  'app.pages.applications.path': 'Ruta',
  'app.pages.applications.copyAppToWorkspaceTitle': 'Copiar aplicación en espacio de trabajo',
  'app.pages.applications.copyAppToWorkspaceMessage':
    'Estás seguro de querer copiar esta aplicación a tu espacio de trabajo?',
  'app.pages.applications.appCopiedToWorkspace': 'La aplicación fue copiada con éxito en:',
  'app.pages.applications.copyingAppToWorkspace': 'Copiando aplicación a espacio de trabajo',
  'app.pages.applications.appImported': 'Aplicación importada exitosamente',
  'app.pages.applications.createInPublic': 'Crear en espacio Public',
  'app.pages.applications.appCopied': 'Aplicación copiada',
  'app.pages.applications.copyingAppToTeam': 'Copiando aplicación en equipo',
  'app.pages.applications.copyAppToPublicMessage':
    '¿Está seguro de querer copiar esta aplicación al espacio de trabajo Public?',
  'app.pages.applications.appCopiedToPublic': 'Aplicación copiada a Public',
  'app.pages.applications.copyingAppToPublic': 'Copiando aplicación en Public',
  'app.pages.applications.setThumbnail': 'Establecer miniatura',
  'app.pages.applications.replaceThumbnail': 'Reemplazar miniatura',
  'app.pages.applications.removeThumbnail': 'Quitar miniatura',
  'app.pages.applications.removeThumbnailMessage': 'Estás seguro de quitar la miniatura?',
  'app.pages.applications.thumbnailRemoved': 'Miniatura quitada',
  'app.pages.applications.thumbnailUploaded': 'Miniatura subida',
  'app.pages.applications.loadingAppMessage': 'Cargando aplicación',

  //Application Summary
  'app.pages.applications.id': 'Identificador de la aplicación',
  'app.pages.applications.version': 'Versión de la aplicación',
  'app.pages.applications.uri': 'Ubicación',
  'app.pages.applications.isPublic': 'Está en la carpeta pública',
  'app.pages.applications.canwrite': 'Tiene permiso de escritura',
  'app.pages.applications.venvPath': 'Ruta de entorno virtual',

  //Icon selector
  'app.pages.iconSelector.iconType': 'Tipo de ícono',
  'app.pages.iconSelector.iconName': 'Icono',
  'app.pages.iconSelector.solid': 'Sólido',
  'app.pages.iconSelector.regular': 'Regular',

  //Versions
  'app.pages.versions.versionManager': 'Administrador de versiones',
  'app.pages.versions.deleteVersionTitle': 'Eliminar versión',
  'app.pages.versions.deleteVersionMessage':
    'Estás seguro de querer eliminar la versión {version}?',
  'app.pages.versions.deleteVersionsMessage':
    'Estás seguro de querer eliminar las versiones {versions}?',
  'app.pages.versions.versionDeleted': 'La versión "{version}" fue eliminada',
  'app.pages.versions.versionsDeleted': 'La versiones "{versions}" fueron eliminadas',
  'app.pages.versions.deletingVersion': 'Eliminando versión',
  'app.pages.versions.createVersion': 'Crear versión',
  'app.pages.versions.fromVersion': 'Versión de origen',
  'app.pages.versions.versionCreated': 'La versión "{version}" fue creada con éxito',
  'app.pages.versions.creationDate': 'Fecha de creación',
  'app.pages.versions.closingDate': 'Fecha de cierre',
  'app.pages.versions.archiveDate': 'Fecha de archivo',
  'app.pages.versions.status.active': 'Activa',
  'app.pages.versions.status.closed': 'Cerrada',
  'app.pages.versions.status.archived': 'Archivada',
  'app.pages.versions.status.unarchived': 'Desarchivada',
  'app.pages.versions.setAsDefaultVersion': 'Establecer como versión por defecto',
  'app.pages.versions.isDefaultAppVersion': 'Es la versión por defecto de la aplicación',
  'app.pages.versions.setAsDefaultVersionMessage':
    'Estás seguro de querer establecer la versión "{version}" como la versión por defecto de la aplicación?',
  'app.pages.versions.defaultVersionChanged': 'La versión por defecto fue modificada',
  'app.pages.versions.editProperties': 'Editar propiedades',
  'app.pages.versions.cannotDeleteReason':
    'No es posible eliminar la versión de la aplicación abierta actualmente',
  'app.pages.versions.versionModified': 'La versión {version} fue modificada con éxito',
  'app.pages.versions.versionsModified': 'Las versiones {versions} fueron modificadas con éxito',
  'app.pages.versions.cannotModifyNameReason':
    'No es posible modificar el nombre de la versión de la aplicación abierta actualmente',
  'app.pages.versions.updateVersionStatus': 'Actualizar estado de la versión',
  'app.pages.versions.updateVersionStatusMessage':
    'Estás seguro de querer actualizar el estado de la versión {version} a "{newStatus}"?',
  'app.pages.versions.updateVersionsStatusMessage':
    'Estás seguro de querer actualizar el estado de las versiones {versions} a "{newStatus}"?',
  'app.pages.versions.importVersions': 'Importar versiones',
  'app.pages.versions.exportVersions': 'Exportar versiones',
  'app.pages.versions.cannotChangeReason':
    'No es posible modificar la versión de la aplicación abierta actualmente',
  'app.pages.versions.versionsImported': 'Las versiones subidas fueron importadas con éxito',
  'app.pages.versions.uploadInstructions':
    'Subir un archivo con extensión .zip que contenga la carpeta de cada versión que se desee importar',
  'app.pages.versions.openAppVersion': 'Abrir versión de la aplicación',
  'app.pages.versions.openAppVersionOnNewInstance':
    'Abrir versión de la aplicación en una nueva instancia',
  'app.pages.versions.excludeArchivedVersions': 'Excluir versiones archivadas',
  'app.pages.versions.versionSelectedScenariosUpdated':
    'Los escenarios seleccionados de la versión se actualizaron con éxito',
  'app.pages.versions.openAfterCreating': 'Abrir luego de crear',
  'app.pages.versions.unsavedChangesMessage':
    'Los cambios no guardados de la versión abierta se perderán',
  'app.pages.versions.versionExported': 'Versión exportada',
  'app.pages.versions.versionsExported': 'Versiones exportadas',
  'app.pages.versions.nameExists': 'El nombre de la versión ya existe',
  'app.pages.versions.openExistingVersion': 'Abrir versión existente',
  'app.pages.versions.createNewVersion': 'Crear versión nueva',
  'app.pages.versions.createNewVersionInMyWorkspace':
    'Crear nueva versión en mi espacio de trabajo',
  'app.pages.versions.createNewVersionInMyWorkspaceConfirmation':
    'No tienes permisos de escritura en el directorio de la aplicación seleccionada. Se copiará la aplicación a tu espacio de trabajo (de no existir) y se creará la nueva versión allí.',
  'app.pages.versions.newVersionName': 'Nombre de nueva versión',

  //Process manager
  'app.pages.app.processManager.addProcess': 'Crear proceso',
  'app.pages.app.processManager.editProcess': 'Editar proceso',
  'app.pages.app.processManager.duplicateProcess': 'Duplicar proceso',
  'app.pages.app.processManager.showDescription': 'Mostrar descripción',
  'app.pages.app.processManager.noDescriptionText': 'No tiene descripción',
  'app.pages.app.processManager.processTodo': 'En progreso',
  'app.pages.app.processManager.process': 'Proceso',
  'app.pages.app.processManager.subscribers': 'Subscriptores',
  'app.pages.app.processManager.status': 'Estado',
  'app.pages.app.processManager.description': 'Descripción',
  'app.pages.app.processManager.group': 'Grupo',
  'app.pages.app.processManager.task': 'Tarea',
  'app.pages.app.processManager.taskResponsible': 'Responsable de la tarea',
  'app.pages.app.processManager.taskActionType': 'Tipo de acción',
  'app.pages.app.processManager.taskActionData': 'Datos de acción',
  'app.pages.app.processManager.isBlockedBy': 'Bloqueada por',
  'app.pages.app.processManager.expiration': 'Expiración',
  'app.pages.app.processManager.expirationType': 'Tipo de expiración',
  'app.pages.app.processManager.sinceProcess': 'desde que se inició el proceso',
  'app.pages.app.processManager.sinceBlockingTaskCompleted':
    'desde que se completó la tarea bloqueante',
  'app.pages.app.processManager.processCreated': 'Proceso creado',
  'app.pages.app.processManager.processUpdated': 'Proceso actualizado',
  'app.pages.app.processManager.processDuplicated': 'Proceso duplicado',
  'app.pages.app.processManager.undefined': 'Indefinido',
  'app.pages.app.processManager.processDeleted': 'Proceso eliminado',
  'app.pages.app.processManager.processNotFound': 'No se encontraron procesos',
  'app.pages.app.processManager.deleteProcess': 'Eliminar proceso',
  'app.pages.app.processManager.deleteProcessMessage': '¿Está seguro de eliminar este proceso?',
  'app.pages.app.processManager.reviewers': 'Revisores',
  'app.pages.app.processManager.reviewerInterface': 'Interfaz de revisión',
  'app.pages.app.processManager.expirationDays': 'Días de expiración',
  'app.pages.app.processManager.addTask': 'Crear tarea',
  'app.pages.app.processManager.editTask': 'Editar tarea',
  'app.pages.app.processManager.taskStatus.not_ready_to_start': 'No lista para comenzar',
  'app.pages.app.processManager.taskStatus.not_started': 'No iniciada',
  'app.pages.app.processManager.taskStatus.in_progress': 'En progreso',
  'app.pages.app.processManager.taskStatus.pending_review': 'Pendiente de revisión',
  'app.pages.app.processManager.taskStatus.completed': 'Completada',

  //Scenarios
  'app.pages.scenarios.scenarioManager': 'Administrador de escenarios',
  'app.pages.scenarios.deleteScenarioTitle': 'Eliminar escenario',
  'app.pages.scenarios.deleteScenarioMessage':
    'Estás seguro de querer eliminar el escenario "{scenario}"?',
  'app.pages.scenarios.deleteScenariosMessage':
    'Estás seguro de querer eliminar los escenarios "{scenarios}"?',
  'app.pages.scenarios.scenarioDeleted': 'El escenario "{scenario}" fue eliminado',
  'app.pages.scenarios.scenariosDeleted': 'Los escenarios "{scenarios}" fueron eliminados',
  'app.pages.scenarios.deletingScenario': 'Eliminando escenario',
  'app.pages.scenarios.createScenario': 'Crear escenario',
  'app.pages.scenarios.fromScenario': 'Desde escenario',
  'app.pages.scenarios.scenarioCreated': 'El escenario "{scenario}" fue creado con éxito',
  'app.pages.scenarios.lastModifiedDate': 'Última fecha de modificación',
  'app.pages.scenarios.editProperties': 'Editar propiedades',
  'app.pages.scenarios.scenarioModified': 'El escenario "{scenario}" fue modificado con éxito',
  'app.pages.scenarios.importScenarios': 'Importar escenarios',
  'app.pages.scenarios.exportScenario': 'Exportar escenario',
  'app.pages.scenarios.scenariosImported': 'Los escenarios subidos fueron importados con éxito',
  'app.pages.scenarios.uploadInstructions':
    'Subir un archivo con extensión .zip que contenga la carpeta de cada versión (con una carpeta por cada escenario dentro) que se desee importar',
  'app.pages.scenarios.alias': 'Alias',
  'app.pages.scenarios.includedNodes': 'Nodos incluidos',
  'app.pages.scenarios.includeNodesFrom': 'Incluir nodos desde',
  'app.pages.scenarios.currentInterface': 'Interfaz actual',
  'app.pages.scenarios.nodesToBeEvaluatedBeforehand': 'Nodos a evaluar previamente',
  'app.pages.scenarios.runScenarioAfterSave': 'Ejecutar escenario después de guardar',
  'app.pages.scenarios.addNode': 'Agregar nodo',
  'app.pages.scenarios.showCurrentVersionOnly': 'Mostrar sólo versión actual',
  'app.pages.scenarios.scenarioAlreadyExists':
    'Ya existe un escenario con este nombre en la versión "{version}"',
  'app.pages.scenarios.scenarioDuplicated': 'El escenario "{scenario}" fue duplicado con éxito',
  'app.pages.scenarios.duplicateScenario': 'Duplicar escenario',
  'app.pages.scenarios.saveScenario': 'Guardar escenario',
  'app.pages.scenarios.cannotSaveScenarioReason':
    'No es posible guardar escenarios en una versión que no está activa',
  'app.pages.scenarios.cannotChangeScenarioReason':
    'No es posible modificar escenarios en una versión que no está activa',
  'app.pages.scenarios.existingScenario': 'Escenario existente',
  'app.pages.scenarios.createNewScenario': 'Crear nuevo escenario',
  'app.pages.scenarios.baseScenario': 'Escenario base',
  'app.pages.scenarios.scenarioSaved': 'El escenario "{scenario}" fue guardado con éxito',
  'app.pages.scenarios.noNodesMessage':
    'El escenario elegido no incluye ningún nodo para guardar. Edite este escenario en el Administrador de Escenarios para agregarlos.',
  'app.pages.scenarios.selectScenarios': 'Seleccionar escenarios',
  'app.pages.scenarios.selectedScenarios': 'Escenarios seleccionados',
  'app.pages.scenarios.scenarioExported': 'Escenario exportado exitosamente',
  'app.pages.scenarios.scenariosExported': 'Escenarios exportados exitosamente',
  'app.pages.scenarios.scenariosManager': 'Administrador de escenarios',
  'app.pages.scenarios.scenariosTemplates': 'Plantillas de escenarios',
  'app.pages.scenarios.createTemplate': 'Crear plantilla',
  'app.pages.scenarios.editTemplate': 'Editar plantilla',
  'app.pages.scenarios.duplicateTemplate': 'Duplicar plantilla',
  'app.pages.scenarios.deleteTemplate': 'Eliminar plantilla',
  'app.pages.scenarios.scenarioTemplateSuccessfullyCreated':
    'Plantilla de escenario creada exitosamente',
  'app.pages.scenarios.scenarioTemplateSuccessfullyModified':
    'Plantilla de escenario {template} modificada exitosamente',
  'app.pages.scenarios.deleteScenarioTemplateTitle': 'Eliminar plantilla de escenario',
  'app.pages.scenarios.deleteScenarioTemplateMessage':
    '¿Estás seguro de querer eliminar la plantilla de escenario "{scenarioTemplate}"?',
  'app.pages.scenarios.scenarioTemplateSuccessfullyDuplicated':
    'Plantilla de escenario {scenarioTemplate} duplicada exitosamente',
  'app.pages.scenarios.scenarioTemplateNameAlreadyExists':
    'Ya existe una plantilla de escenario con este nombre en la versión {version}',
  'app.pages.scenarios.templateName': 'Nombre de la plantilla',
  'app.pages.scenarios.scenarioNodesTemplate': 'Plantilla de nodos de escenario',
  'app.pages.scenarios.noOptionsTemplate': 'No hay opciones de plantilla',
  'app.pages.scenarios.addNewScenarioNodesTemplate':
    'Agregar nueva plantilla de nodos de escenario',
  'app.pages.scenarios.destinationVersion': 'Versión de destino',
  'app.pages.scenarios.updateSelectedScenarios': 'Actualizar escenarios seleccionados',

  //My tasks
  'app.pages.app.myTasks.title': 'Mis tareas',
  'app.pages.app.myTasks.statusChanged': 'Estado cambiado',
  'app.pages.app.myTasks.showComments': 'Mostrar comentarios',
  'app.pages.app.myTasks.responsible': 'Responsable',
  'app.pages.app.myTasks.deadline': 'Fecha límite',
  'app.pages.app.myTasks.finished': 'Finalizada',
  'app.pages.app.myTasks.commentDeleted': 'Comentario eliminado',
  'app.pages.app.myTasks.comments': 'Comentarios',
  'app.pages.app.myTasks.deleteComment': 'Eliminar comentario',
  'app.pages.app.myTasks.addComment': 'Crear comentario',
  'app.pages.app.myTasks.noAssignedTasks': 'Sin tareas asignadas',

  //General settings
  'app.pages.generalSettings.assistantBotsSettings': 'Configuración de asistentes',
  'app.pages.generalSettings.logsSettings': 'Configuración de los registros',
  'app.pages.generalSettings.initialPrompt': 'Indicación inicial',
  'app.pages.generalSettings.customValue': 'Valor personalizado',
  'app.pages.generalSettings.customValueRemoved': 'Valor personalizado eliminado',
  'app.pages.generalSettings.customValueSaved': 'Valor personalizado guardado exitosamente',
  'app.pages.generalSettings.paramsPlaceholder': 'Ingrese los parámetros en formato JSON',
  'app.pages.generalSettings.applicationAssistantSuggestedInstructions':
    'Instrucciones (prompt de sistema) sugeridas para asistentes de aplicación - {engineType}',

  //App Configuration
  'app.pages.app.appConfiguration': 'Configuración de la aplicación',
  'app.pages.app.installLibrariesOnOpen': 'Instalar librerias al abrir',
  'app.pages.app.openDefaultVersionOnOpen': 'Abrir versión por defecto al abrir',
  'app.pages.app.defaultTabOnOpen': 'Pestaña por defecto al abrir',

  //External links
  'app.pages.externalLink.deleteMessage': '¿Estás seguro de borrar el link externo?',
  'app.pages.externalLink.linkDeleted': 'Link externo eliminado',
  'app.pages.externalLink.deleteTitle': 'Eliminar link externo',
  'app.pages.externalLink.nodeId': 'Id del nodo',
  'app.pages.externalLink.commonInstance': 'Instancia común',
  'app.pages.externalLink.apiEndpoint': 'API URL',
  'app.pages.externalLink.linkUpdated': 'Enlace externo actualizado',
  'app.pages.externalLink.customEndpoint': 'Endpoint personalizado',
  'app.pages.externalLink.customEndpointError': 'Endpoint personalizado ya existe',
  'app.pages.externalLink.externalLinksNotFound': 'No hay enlaces externos',
  'app.pages.externalLink.selectApiKey': 'Selecciona una clave Api',
  'app.pages.externalLink.executor': 'Ejecutor',

  //Api keys manager
  'app.pages.apiKeysManager.alias': 'Alias',
  'app.pages.apiKeysManager.key': 'Clave',
  'app.pages.apiKeysManager.active': 'Activo',
  'app.pages.apiKeysManager.lastKeyChange': 'Último cambio de la clave',
  'app.pages.apiKeysManager.showApiKeys': 'Mostrar claves Api',
  'app.pages.apiKeysManager.createApiKeys': 'Crear clave Api',
  'app.pages.apiKeysManager.editApiKeys': 'Editar clave Api',
  'app.pages.apiKeysManager.deleteApiKeys': 'Eliminar clave Api',
  'app.pages.apiKeysManager.noApiKeys': 'No hay claves Api',
  'app.pages.apiKeysManager.apiKey': 'Clave Api',
  'app.pages.apiKeysManager.deleteApiKeyMessage': 'Estás seguro de eliminar la clave Api?',
  'app.pages.apiKeysManager.apiKeyDeleted': 'Clave Api eliminada',
  'app.pages.apiKeysManager.apiKeyUpdated': 'Clave Api actualizada',
  'app.pages.apiKeysManager.apiKeyCreated': 'Clave Api creada',
  'app.pages.apiKeysManager.keyCopied': 'Clave copiada',
  'app.pages.apiKeysManager.saveKeyAlert':
    'Atención! La clave Api solo estará visible en esta ocasión. Asegúrate de copiarla ahora mismo',
  'app.pages.apiKeysManager.keyId': 'Clave Id',

  //App consolidation
  'app.pages.appConsolidation.sourceAppVersion': 'Versión de la aplicación de origen',
  'app.pages.appConsolidation.differences': 'Diferencias',
  'app.pages.appConsolidation.summary': 'Resumen',
  'app.pages.appConsolidation.businessRules': 'Reglas de negocio',
  'app.pages.appConsolidation.includeAllModules': 'Incluir todos',
  'app.pages.appConsolidation.includeAllInterfaces': 'Incluir todas',
  'app.pages.appConsolidation.replaceMatchingNodes':
    'Reemplazar los nodos coincidentes fuera de estos módulos',
  'app.pages.appConsolidation.versionMetadata': 'Metadatos de la versión',
  'app.pages.appConsolidation.itemAdded': '{name} agregada/o',
  'app.pages.appConsolidation.itemDeleted': '{name} eliminada/o',
  'app.pages.appConsolidation.itemEdited': '{name} editada/o',
  'app.pages.appConsolidation.itemsAdded': '{name} agregadas/os',
  'app.pages.appConsolidation.itemsDeleted': '{name} eliminadas/os',
  'app.pages.appConsolidation.itemsEdited': '{name} editadas/os',
  'app.pages.appConsolidation.noChanges': 'Sin cambios',
  'app.pages.appConsolidation.confirmationDialogTitle': 'Confirmar cambios de consolidación',
  'app.pages.appConsolidation.confirmationDialogText1':
    '¿Estás seguro de querer confirmar los cambios?',
  'app.pages.appConsolidation.confirmationDialogText2':
    'Los cambios aplicados a archivos y metadata de la versión son irreversibles.',
  'app.pages.appConsolidation.compareDefinitionOnly': 'Comparar sólo definición',

  //Office addin
  'app.pages.office.selectNode': 'Selecciona un nodo',

  //Notifications
  'app.pages.notifications.scheduler.taskFinished':
    'La tarea {match1} finalizó con el estado {match2}',
  'app.pages.notifications.workflow.taskReady':
    'La tarea {match1} del proceso {match2} está lista para comenzar',
  'app.pages.notifications.workflow.taskOverdueExtended':
    'La tarea {match1} del proceso {match2} está vencida. Esto puede afectar la finalización de tu tarea {match3}',
  'app.pages.notifications.workflow.taskOverdue':
    'La tarea {match1} del proceso {match2} está vencida',
  'app.pages.notifications.workflow.taskChangedStatus':
    'La tarea {match1} del proceso {match2} ha cambiado de estado {match3} a {match4}',
  'app.pages.notifications.workflow.processStarted': 'El proceso {match1} ha comenzado',
  'app.pages.notifications.workflow.processUpToDateAgain':
    'El proceso {match1} está nuevamente actualizado',
  'app.pages.notifications.workflow.wroteComment':
    '{match1} escribió el siguiente comentario en la tarea {match2} del proceso {match3}: {match4}',
  'app.pages.notifications.title': 'Notificaciones',
  'app.pages.notifications.allNotifications': 'Todas',
  'app.pages.notifications.unReadNotifications': 'Sin leer',
  'app.pages.notifications.noNotifications': 'Sin notificaciones',
  'app.pages.notifications.noUnreadNotifications': 'Sin notificaciones sin leer',
  'app.pages.notifications.deleteNotifications': 'Eliminar notificación',
  'app.pages.notifications.markAsRead': 'Marcar como leída',
  'app.pages.notifications.maskAsUnread': 'Marcar como no leída',
  'app.pages.notifications.notificationDeleted': 'Notificación eliminada',
  'app.pages.notifications.markAllAsRead': 'Marcar todas como leídas',

  // Assistant bots
  'app.pages.assistantBots.send': 'Enviar',
  'app.pages.assistantBots.clearMessages': 'Limpiar mensajes',
  'app.pages.assistantBots.copiedCode': 'Código copiado',
  'app.pages.assistantBots.updateCurrentNode': 'Pegar en nodo seleccionado',
  'app.pages.assistantBots.askAgain': 'Preguntar nuevamente',
  'app.pages.assistantBots.showPrivacyStatement': 'Política de privacidad',
  'app.pages.assistantBots.chatSuccessfullyExported': 'Chat exportado exitosamente',
  'app.pages.assistantBots.ask': 'Preguntar',
  'app.pages.assistantBots.askAboutCoding': 'Pregunta sobre codificación',
  'app.pages.assistantBots.assistantBotsManager': 'Administrador de asistentes',
  'app.pages.assistantBots.instructionsSystemPrompt': 'Instrucciones (prompt de sistema)',
  'app.pages.assistantBots.AIFramework': 'Framework de IA',
  'app.pages.assistantBots.llmModel': 'Modelo de LLM',
  'app.pages.assistantBots.filesInDocsFolder': 'Archivos en carpeta de documentos',
  'app.pages.assistantBots.maxFileSizeWarning': 'El tamaño máximo de un archivo es de {value}',
  'app.pages.assistantBots.maxFilesCount': 'La cantidad máxima de archivos a subir es {value}',
  'app.pages.assistantBots.advancedSettings': 'Configuración avanzada',
  'app.pages.assistantBots.createAssistant': 'Crear asistente',
  'app.pages.assistantBots.createNewAssistant': 'Crear nuevo asistente',
  'app.pages.assistantBots.updateAssistant': 'Actualizar asistente',
  'app.pages.assistantBots.assistantId': 'ID de asistente',
  'app.pages.assistantBots.updateAssistantWithCurrentFilesInFolder':
    'Actualizar asistente con los archivos en carpeta de documentación actuales',
  'app.pages.assistantBots.addNodesInfoFile': 'Agregar archivo con información de nodos',
  'app.pages.assistantBots.addInterfacesInfoFile': 'Agregar archivo con información de interfaces',
  'app.pages.assistantBots.assistantSuccessfullyCreated':
    'El asistente {value} fue creado con éxito',
  'app.pages.assistantBots.assistantSuccessfullyUpdated':
    'El asistente {value} fue actualizado con éxito',
  'app.pages.assistantBots.selectAssistantBot': 'Seleccionar asistente',
  'app.pages.assistantBots.setAsDefaultAppAssistantBot': 'Establecer como asistente por defecto',
  'app.pages.assistantBots.isDefaultAssistantBot': 'Es el asistante por defecto de la aplicación',
  'app.pages.assistantBots.activateAssistantBot': 'Activar asistente',
  'app.pages.assistantBots.deactivateAssistantBot': 'Desactivar asistente',
  'app.pages.assistantBots.active': 'Activo',
  'app.pages.assistantBots.deleteAssistantBotTitle': 'Eliminar asistente',
  'app.pages.assistantBots.botAssistantDeleted': 'El asistente "{name}" fue eliminado',
  'app.pages.assistantBots.botAssistantsDeleted': 'Los asistentes "{names}" fueron eliminados',
  'app.pages.assistantBots.deleteAssistantBotMessage':
    'Estás seguro de querer eliminar el asistente {name}?',
  'app.pages.assistantBots.deleteAssistantBotsMessage':
    'Estás seguro de querer eliminar los asistentes {names}?',
  'app.pages.assistantBots.selectedAssistantBotChanged': 'El asistente fue seleccionado',
  'app.pages.assistantBots.selectAssistantBotMessage':
    'Estás seguro de querer seleccionar el asistente "{name}" como el asistente por defecto de la aplicación?',
  'app.pages.assistantBots.activateAssistantBotMessage':
    'Estás seguro de querer {action} el asistente "{name}"?',
  'app.pages.assistantBots.manageDocumentationFiles': 'Administrar archivos de documentación',
  'app.pages.assistantBots.addFiles': 'Agregar archivos',
  'app.pages.assistantBots.deleteSelectedFiles': 'Eliminar archivos seleccionados',
  'app.pages.assistantBots.uploadFiles': 'Subir archivos',
  'app.pages.assistantBots.initialAssistantMessage': 'Sobre qué tema necesitas ayuda?',
  'app.pages.assistantBots.howCanIHelpYou': 'Cómo te puedo ayudar?',
  'app.pages.assistantBots.testAssistant': 'Asistente de prueba',
  'app.pages.assistantBots.testAssistantAction': 'Probar asistente',
  'app.pages.assistantBots.openAIAssistant': 'Asistente de OpenAI',
  'app.pages.assistantBots.haystack': 'Haystack',
  'app.pages.assistantBots.codeAssistants': 'Asistentes de código',
  'app.pages.assistantBots.codeAssistant': 'Mostrar/ocultar asistente de código',
  'app.pages.assistantBots.codeBugFixer': 'Corrector de errores de código',
  'app.pages.assistantBots.codeOptimizer': 'Optimizador de código',
  'app.pages.assistantBots.codeCompleter': 'Generador de código',
  'app.pages.assistantBots.includeFiles': 'Incluir archivos',
  'app.pages.assistantBots.newAssistant': 'Nuevo asistente',
  'app.pages.assistantBots.noAssistantSelected': 'Ningún asistente seleccionado',

  // Secrets manager
  'app.pages.secretsManager.secretManager': 'Administrador de secretos',
  'app.pages.secretsManager.createSecret': 'Crear secreto',
  'app.pages.secretsManager.editSecret': 'Editar secreto',
  'app.pages.secretsManager.deleteSecret': 'Eliminar secreto',
  'app.pages.secretsManager.deleteSecretConfirmation':
    '¿Estás seguro de querer eliminar este secreto?',
  'app.pages.secretsManager.secretDeleted': 'Secreto eliminado',
  'app.pages.secretsManager.secretUpdated': 'Secreto actualizado',
  'app.pages.secretsManager.secretCreated': 'Secreto creado',
  'app.pages.secretsManager.secretKey': 'Clave del secreto',
  'app.pages.secretsManager.secretValue': 'Valor del secreto',
  'app.pages.secretsManager.noSecrets': 'No hay secretos',
  'app.pages.secretsManager.secret': 'Secreto',
  'app.pages.secretsManager.allUsers': 'Todos los usuarios',
  'app.pages.secretsManager.showSecretValue': 'Mostrar valor del secreto',
  'app.pages.secretsManager.hideSecretValue': 'Ocultar valor del secreto',
  'app.pages.secretsManager.secretKeyExists': 'La clave del secreto ya existe'
}

export default messages
