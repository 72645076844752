import { all, put, call, fork, takeLatest, delay, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { NavigateFunction } from 'react-router-dom'

import { applicationApi } from 'services/application'
import { interfaceManagerApi } from 'services/interfaceManager'
import { securityApi } from 'services/security'
import { versionApi } from 'services/version'

import { UserInstanceData } from 'common/types/instance'
import { AppInstanceInfo } from 'common/types/application'
import { enqueue } from 'common/notifier'

import closeAction from 'common/notifier/CloseBtnEnq'
import { INSTANCE_ID } from 'common/api/storageConsts'

import { ApplicationState } from '..'

import { enqueueNotification } from '../notifier'
import { reloadInstances, resetAppUserInfo, setUserInstanceData } from '../user'
import { setProgress, startWSServer, stopWSServer } from '../socket/actions'

import { HomeItem } from 'common/types/home'
import { cancelCodeNode, removeNodeFromUnsaved, setSelectedCodeNode } from 'store/model'
import { modelApi } from 'services/model'
import { AppActionTypes } from './types'
import {
  addBroadcastChannel,
  openApp,
  removeBroadcastChannel,
  setAppConsolidationDifferences,
  setOpenAppAfterSave,
  showCreateAppDialog
} from '.'
import {
  setOpenApp,
  resetAppInfo,
  setSavingApp,
  setAppInfo,
  setChangingAppVersion,
  setCreatingAppVersion
} from './actions'
import { setChangeDefaultInterface, setInterfaceTabs } from 'store/interface/actions'
import { Interface, ListInterfaceResponse } from 'pages/interfaces/types'
import { consolidationAppApi } from 'services/consolidationApp'
import { GetDifferenceResponse } from 'pages/appConsolidation/types'

function* handleOpenApp({ payload: { navigate, fullPath, openAsReadOnly, version } }: AnyAction) {
  try {
    const currentAppInfo: AppInstanceInfo | undefined = ((state: ApplicationState) =>
      state.app.info)(yield select())
    //show open app dialog
    yield put(setProgress({ show: true, value: 5, message: 'creating_environment' }))
    yield put(resetAppUserInfo())
    yield call(applicationApi.closeApp)
    // Wait for Redis to delete instance
    yield delay(2000)
    yield put(resetAppInfo())
    yield* openAppAndRedirect({ fullPath, navigate, openAsReadOnly, version, currentAppInfo })
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    // Close progress bar if the request has ended or not
    yield delay(1000)
    yield put(setProgress({ show: false, value: 100 }))
  }
}

function* handleOpenAppOnNewInstance({
  payload: { navigate, fullPath, openAsReadOnly, version }
}: AnyAction) {
  try {
    //show open app dialog
    yield put(setProgress({ show: true, value: 5, message: 'creating_environment' }))
    const res: [boolean, UserInstanceData] = yield call(securityApi.createInstance, {})
    const [success, instance] = res
    if (success) {
      yield put(resetAppInfo())
      const userInstance = instance as UserInstanceData
      sessionStorage[INSTANCE_ID] = userInstance.id
      yield put(setUserInstanceData(userInstance))
      yield put(startWSServer())
      yield* openAppAndRedirect({
        fullPath,
        navigate,
        openAsReadOnly,
        version,
        forceNewInstance: true
      })
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    // Close progress bar if the request has ended or not
    yield delay(1000)
    yield put(setProgress({ show: false, value: 100 }))
  }
}

function* handleCreateVersionFromClosedApp({
  payload: { navigate, folder, dataToSend }
}: AnyAction) {
  try {
    //show open app dialog
    yield put(
      setProgress({
        show: true,
        value: 5,
        message: dataToSend?.create_in_my_workspace
          ? 'copying_app_to_user_workspace'
          : 'creating_environment'
      })
    )
    yield put(resetAppUserInfo())
    yield call(applicationApi.closeApp)
    // Wait for Redis to delete instance
    yield delay(2000)
    yield put(resetAppInfo())
    yield put(setCreatingAppVersion(true))
    const appInfo: AppInstanceInfo = yield call(
      applicationApi.createVersionFromClosedApp,
      folder,
      dataToSend
    )
    yield* redirectAfterOpenApp(appInfo, navigate)
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    // Close progress bar if the request has ended or not
    yield delay(1000)
    yield put(setProgress({ show: false, value: 100 }))
    yield put(setCreatingAppVersion(false))
  }
}

function* openAppAndRedirect({
  fullPath,
  navigate,
  openAsReadOnly,
  version,
  currentAppInfo,
  forceNewInstance
}: {
  fullPath: any
  navigate: NavigateFunction
  openAsReadOnly: boolean
  version?: string
  currentAppInfo?: AppInstanceInfo
  forceNewInstance?: boolean
}) {
  const appInfo: AppInstanceInfo = yield call(
    applicationApi.openApp,
    fullPath,
    openAsReadOnly,
    version,
    forceNewInstance
  )
  const channel: BroadcastChannel | undefined = ((state: ApplicationState) =>
    state.app.broadcastChannel)(yield select())
  if (currentAppInfo) {
    if (channel) {
      channel.postMessage(appInfo)
    }
  }
  yield put(
    addBroadcastChannel(
      `app-info-${appInfo.id}-${appInfo.name}-${appInfo.version}-${appInfo.engineParams}`
    )
  )
  yield* redirectAfterOpenApp(appInfo, navigate)
}

function* redirectAfterOpenApp(appInfo: AppInstanceInfo, navigate: NavigateFunction) {
  yield put(setAppInfo(appInfo))
  if (appInfo.newInstanceId) {
    sessionStorage[INSTANCE_ID] = appInfo.newInstanceId
    if (appInfo.needNewWebSocket) {
      yield put(setProgress({ show: true, value: 90 }))
      const instanceData: UserInstanceData = yield call(securityApi.getInstance)
      const instanceWithNewData: UserInstanceData = {
        ...instanceData,
        id: appInfo.newInstanceId,
        applicationInfo: appInfo
      }
      yield put(setUserInstanceData(instanceWithNewData))
      yield put(startWSServer())
    }
  }
  if (appInfo.readonly && appInfo.canWriteInDir) {
    yield put(
      enqueueNotification({
        title: appInfo.readOnlyTitle,
        message: appInfo.readOnlyReason,
        options: {
          variant: 'info',
          persist: true
        }
      })
    )
  }
  if (appInfo.recoveredExistingInstance) {
    yield put(
      enqueueNotification({
        message: 'recovered_existing_instance',
        options: {
          variant: 'info',
          persist: true
        }
      })
    )
  }
  yield put(setOpenApp(appInfo))
  const defaultInterfaceId = appInfo.defaultInterfaceId || ''
  navigate('')
  navigate(`/interfaces${defaultInterfaceId && `/${defaultInterfaceId}`}`)
}

function* handleCloseApp({ payload: { navigate, successMsg } }: AnyAction) {
  try {
    const res: boolean = yield call(applicationApi.closeApp)
    yield put(resetAppInfo())
    yield put(reloadInstances(true))
    const channel: BroadcastChannel | undefined = ((state: ApplicationState) =>
      state.app.broadcastChannel)(yield select())
    if (channel) {
      // Send empty message so that other tabs with the same instance know they have to close the app too
      channel.postMessage(undefined)
    }
    yield put(removeBroadcastChannel())
    if (res) {
      navigate('/')
      const key = (new Date().getTime() + Math.random()).toString()
      yield put(
        enqueueNotification({
          key,
          message: successMsg,
          options: {
            variant: 'success',
            persist: false,
            autoHideDuration: 1500,
            action: () => closeAction(key)
          }
        })
      )
    }
    // Create a new instance to avoid mixing existing instance ids
    yield* handleCreateNewInstance()
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCloseAppFromOtherTab({ payload: { navigate } }: AnyAction) {
  // This is used when the broadcastChannel receives a message to close the app because
  // it was closed explicitly in another tab. We must clean the application info and create a new instance
  try {
    // Delete app info and redirect to home
    yield put(resetAppInfo())
    yield put(reloadInstances(true))
    yield put(removeBroadcastChannel())
    navigate('/')
    // Create a new instance to avoid mixing existing instance ids
    yield* handleCreateNewInstance()
    yield put(
      enqueueNotification({
        message: 'app_closed_in_another_tab',
        options: {
          variant: 'info',
          persist: true
        }
      })
    )
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* confirmNodeDefinitionsBeforeSaving(confirmNodeDefinitions: boolean) {
  try {
    const unconfirmedNodes = ((state: ApplicationState) =>
      state.app.model.diagram?.unconfirmedNodes)(yield select())
    if (confirmNodeDefinitions) {
      if (unconfirmedNodes && unconfirmedNodes.length > 0) {
        for (const nodeToSave of unconfirmedNodes) {
          const properties: { name: string; value: any }[] = [
            { name: 'definition', value: nodeToSave.definition }
          ]
          const res: boolean = yield call(
            modelApi.setNodeProperties,
            nodeToSave.identifier,
            properties
          )
          if (res) {
            yield put(removeNodeFromUnsaved(nodeToSave.identifier))
          }
        }
      }
    } else {
      if (unconfirmedNodes) {
        for (const nodeToSave of unconfirmedNodes) {
          yield put(removeNodeFromUnsaved(nodeToSave.identifier))
        }
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleSaveApp({ payload: { confirmNodeDefinitions, successMsg, openApp } }: AnyAction) {
  try {
    yield* confirmNodeDefinitionsBeforeSaving(confirmNodeDefinitions)
    const unconfirmedNodesAfterIteration = ((state: ApplicationState) =>
      state.app.model.diagram?.unconfirmedNodes)(yield select())
    if (!unconfirmedNodesAfterIteration || unconfirmedNodesAfterIteration?.length === 0) {
      yield put(setSavingApp(true))
      const appInfo: AppInstanceInfo = yield call(applicationApi.saveApp)
      if (!confirmNodeDefinitions) {
        yield put(cancelCodeNode())
      }
      yield put(setAppInfo(appInfo))
      yield put(
        enqueueNotification({
          message: successMsg,
          options: {
            variant: 'success',
            persist: false
          }
        })
      )
      if (openApp && openApp.open) {
        yield put(setOpenAppAfterSave(openApp))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setSavingApp(false))
  }
}

function* handleSaveAppInMyWorkspace({
  payload: { confirmNodeDefinitions, successMsg, newPathMsg, folderName, versions, navigate }
}: AnyAction) {
  try {
    yield* confirmNodeDefinitionsBeforeSaving(confirmNodeDefinitions)
    const unconfirmedNodesAfterIteration = ((state: ApplicationState) =>
      state.app.model.diagram?.unconfirmedNodes)(yield select())
    if (!unconfirmedNodesAfterIteration || unconfirmedNodesAfterIteration?.length === 0) {
      yield put(setSavingApp(true))
      const previousAppVersionName = ((state: ApplicationState) => state.app.info?.version)(
        yield select()
      )
      const appInfo: AppInstanceInfo = yield call(
        applicationApi.saveAppInMyWorkspace,
        folderName,
        versions
      )
      if (!confirmNodeDefinitions) {
        yield put(setSelectedCodeNode(undefined))
      }
      yield put(setAppInfo(appInfo))
      if (previousAppVersionName !== appInfo.version && navigate) {
        const defaultInterfaceId = appInfo.defaultInterfaceId || ''
        navigate('')
        navigate(`/interfaces${defaultInterfaceId && `/${defaultInterfaceId}`}`)
      }
      yield put(
        enqueueNotification({
          message: successMsg,
          options: {
            variant: 'success',
            persist: false
          }
        })
      )
      yield put(
        enqueueNotification({
          message: `${newPathMsg}${appInfo.uri}`,
          options: {
            variant: 'info',
            persist: true
          }
        })
      )
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setSavingApp(false))
  }
}

function* handleCreateApp({ payload: { name, template, onPublic, navigate } }: AnyAction) {
  try {
    const newApp: HomeItem = yield call(applicationApi.createApp, name, template, onPublic)
    yield put(showCreateAppDialog(false))
    yield put(openApp({ navigate, fullPath: newApp.uri }))
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setSavingApp(false))
  }
}

function* handleReloadWithMyVenv() {
  try {
    const newAppInstanceInfo: AppInstanceInfo = yield call(applicationApi.reloadWithMyVenv)
    yield put(setOpenApp(newAppInstanceInfo))
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleDownloadExample({ payload: { name, navigate } }: AnyAction) {
  try {
    const newApp: HomeItem = yield call(applicationApi.downloadExample, name)
    yield put(openApp({ navigate, fullPath: newApp.uri, openAsReadOnly: true }))
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setSavingApp(false))
  }
}

function* handleChangeAppVersion({ payload: { version, navigate } }: AnyAction) {
  try {
    yield put(setChangingAppVersion(true))
    const appInfo: AppInstanceInfo = yield call(versionApi.changeAppVersion, version)
    yield put(resetAppInfo())
    const channel = ((state: ApplicationState) => state.app.broadcastChannel)(yield select())
    if (channel) {
      channel.postMessage(appInfo)
    }
    yield put(
      addBroadcastChannel(
        `app-info-${appInfo.id}-${appInfo.name}-${appInfo.version}-${appInfo.engineParams}`
      )
    )
    yield put(setAppInfo(appInfo))
    if (appInfo.readonly && appInfo.canWriteInDir) {
      yield put(
        enqueueNotification({
          title: appInfo.readOnlyTitle,
          message: appInfo.readOnlyReason,
          options: {
            variant: 'info',
            persist: true
          }
        })
      )
    }
    yield put(setOpenApp(appInfo))
    const defaultInterfaceId = appInfo.defaultInterfaceId || ''
    navigate('')
    navigate(`/interfaces${defaultInterfaceId && `/${defaultInterfaceId}`}`)
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setChangingAppVersion(false))
  }
}

function* handleSaveAppAsNewVersion({
  payload: {
    new_version_name,
    labels,
    set_as_default,
    version_description,
    navigate,
    preventNavigate,
    getConsolidationDifferenceData
  }
}: AnyAction) {
  try {
    yield put(setChangingAppVersion(true))
    yield* confirmNodeDefinitionsBeforeSaving(true)
    const appInfo: AppInstanceInfo = yield call(versionApi.saveAppInNewVersion, {
      new_version_name,
      labels,
      set_as_default,
      version_description
    })
    yield put(resetAppInfo())
    const channel = ((state: ApplicationState) => state.app.broadcastChannel)(yield select())
    if (channel) {
      channel.postMessage(appInfo)
    }
    yield put(
      addBroadcastChannel(
        `app-info-${appInfo.id}-${appInfo.name}-${appInfo.version}-${appInfo.engineParams}`
      )
    )
    yield put(setAppInfo(appInfo))
    if (appInfo.readonly && appInfo.canWriteInDir) {
      yield put(
        enqueueNotification({
          title: appInfo.readOnlyTitle,
          message: appInfo.readOnlyReason,
          options: {
            variant: 'info',
            persist: true
          }
        })
      )
    }
    yield put(setOpenApp(appInfo))
    if (!preventNavigate) {
      const defaultInterfaceId = appInfo.defaultInterfaceId || ''
      navigate('')
      navigate(`/interfaces${defaultInterfaceId && `/${defaultInterfaceId}`}`)
    } else if (getConsolidationDifferenceData) {
      const consolidationDifference: GetDifferenceResponse = yield call(
        consolidationAppApi.getConsolidationDifferences,
        getConsolidationDifferenceData
      )
      yield put(setAppConsolidationDifferences(consolidationDifference))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setChangingAppVersion(false))
  }
}

function* handleUpdateAppVersionProperties({
  payload: { version, newProperties, navigate, location }
}: AnyAction) {
  const previousDefaultInterfaceId = ((state: ApplicationState) =>
    state.app.info?.defaultInterfaceId)(yield select())
  try {
    const appInfo: AppInstanceInfo = yield call(
      versionApi.updateAppVersionProperties,
      version,
      newProperties
    )
    yield put(setAppInfo(appInfo))
    const defaultInterfaceId = appInfo.defaultInterfaceId
    if (defaultInterfaceId !== previousDefaultInterfaceId) {
      const tabs = ((state: ApplicationState) => state.app.interface.tabs)(yield select())
      const interfaces: ListInterfaceResponse<Interface> = yield call(
        interfaceManagerApi.listInterfaces
      )
      const defaultInterface = interfaces?.results?.find((item) => item.id === defaultInterfaceId)
      let newTabs = [...tabs].filter((tab) => tab.id !== defaultInterfaceId)
      if (defaultInterface) {
        newTabs.unshift({ id: defaultInterface.id, name: defaultInterface.name })
      }
      yield put(setChangeDefaultInterface(true))
      if (!location.pathname.includes('/interfaces/')) {
        yield put(setInterfaceTabs(newTabs))
      } else {
        if (defaultInterface) {
          yield put(setInterfaceTabs(newTabs))
          navigate('')
          navigate(`/interfaces/${defaultInterface.id}`)
        }
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCreateNewInstance() {
  const createInstanceRes: [boolean, UserInstanceData] = yield call(securityApi.createInstance, {})
  const [success, instance] = createInstanceRes
  if (success) {
    yield put(stopWSServer())
    const userInstance = instance as UserInstanceData
    sessionStorage[INSTANCE_ID] = userInstance.id
    yield put(setUserInstanceData(userInstance))
    yield put(startWSServer())
  }
}

function* watchGeneralActions() {
  yield all([
    takeLatest(AppActionTypes.OPEN_APP, handleOpenApp),
    takeLatest(AppActionTypes.OPEN_APP_ON_NEW_INSTANCE, handleOpenAppOnNewInstance),
    takeLatest(AppActionTypes.CREATE_VERSION_FROM_CLOSED_APP, handleCreateVersionFromClosedApp),
    takeLatest(AppActionTypes.CLOSE_APP, handleCloseApp),
    takeLatest(AppActionTypes.CLOSE_APP_FROM_OTHER_TAB, handleCloseAppFromOtherTab),
    takeLatest(AppActionTypes.SAVE_APP, handleSaveApp),
    takeLatest(AppActionTypes.SAVE_APP_IN_MY_WORKSPACE, handleSaveAppInMyWorkspace),
    takeLatest(AppActionTypes.SAVE_APP_AS_NEW_VERSION, handleSaveAppAsNewVersion),
    takeLatest(AppActionTypes.CREATE_APP, handleCreateApp),
    takeLatest(AppActionTypes.RELOAD_WITH_MY_VENV, handleReloadWithMyVenv),
    takeLatest(AppActionTypes.DOWNLOAD_EXAMPLE, handleDownloadExample),
    takeLatest(AppActionTypes.CHANGE_APP_VERSION, handleChangeAppVersion),
    takeLatest(AppActionTypes.UPDATE_APP_VERSION_PROPERTIES, handleUpdateAppVersionProperties)
  ])
}

function* appSaga() {
  yield all([fork(watchGeneralActions)])
}

export { appSaga }
