// Local Storage
export const LAST_COMPANY_SELECTED = 'lastCompanySelected'
export const LOCALE = 'locale'
export const HOME_SIDEBAR = 'homeSidebar'
export const ROWS_PER_PAGE_PREFERENCE = 'rowsPerPagePreference'
export const SELECTED_THEME = 'selectedTheme'
export const SELECTED_CODE_LAYOUT = 'selectedCodeLayout'
export const SAML_COMPANY_SELECTED = 'samlCompanySelected'

// Session Storage
export const INSTANCE_ID = 'instanceId'
export const REDIRECT_TOKEN = 'rtk'
