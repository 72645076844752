import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'

import {
  ModuleInfo,
  NodePositionAndSize,
  NodePositionWModule,
  CodeNode,
  DiagramNode,
  SelectorInfo,
  SelectorOptionsAndIndexInfo,
  SelectorParamsInfo,
  ChoiceInfo,
  ChoiceOptionsAndIndexInfo,
  ChoiceParamsInfo,
  NodeParams,
  Node,
  NodePropParams,
  NodePreview,
  WizardTypes,
  OpenWizardParams,
  ApplyWizardParams,
  WizardActions,
  WizardData,
  CopyNodesParams,
  SearchNodesData,
  CreateAliasResponse,
  SetNodesPropertiesParams,
  IndexTextFilterChoices,
  IndexFilterChoices,
  InstalledLibrary,
  GetAllIndexesData,
  NodePropertiesResponse,
  ResultNode,
  NodesPropertiesResponse,
  CompareNodesResponse,
  GetFormColumnsData
} from 'common/types/model'
import { GenericBooleanResponse, PaginatedResponse } from 'common/types/managers'
import { InterfaceIndexValuesData, RowLastUpdateInformation } from 'common/types/interface'
import { ws } from 'store/socket/sagas'
import { InterfaceEvent } from 'pages/interfaces/containers/types'
import { MainFolder } from 'common/types/home'

const ENDPOINT = '/modelManager'
const WS_TIME_INTERVAL_MS = 100

interface ModuleFromFileData {
  fromTemp: boolean
  importType: string
  moduleFile: string
  parentModelId: string
}

export class ModelApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, ModelApi.prototype)
    this.navigateDiagram = this.navigateDiagram.bind(this)
    this.deleteNodes = this.deleteNodes.bind(this)
    this.createNode = this.createNode.bind(this)
    this.getCodeNodeProperties = this.getCodeNodeProperties.bind(this)
    this.getDiagramNodeProperties = this.getDiagramNodeProperties.bind(this)
    this.setNodeProperties = this.setNodeProperties.bind(this)
    this.setNodesProperties = this.setNodesProperties.bind(this)
    this.setNodeIdFromTitle = this.setNodeIdFromTitle.bind(this)
    this.setNodesPosition = this.setNodesPosition.bind(this)
    this.setNodesSize = this.setNodesSize.bind(this)
    this.createNodeResultInterface = this.createNodeResultInterface.bind(this)
    this.triggerNodeInterface = this.triggerNodeInterface.bind(this)
    this.getNodeInterfaceIndexValues = this.getNodeInterfaceIndexValues.bind(this)
    this.getNodeInterfaceTableColumns = this.getNodeInterfaceTableColumns.bind(this)
    this.installLibraries = this.installLibraries.bind(this)
    this.uninstallLibrary = this.uninstallLibrary.bind(this)
    this.getNodeProperties = this.getNodeProperties.bind(this)
    this.getNodesProperties = this.getNodesProperties.bind(this)
    this.getSelectorParams = this.getSelectorParams.bind(this)
    this.getSelectorOptionsAndIndex = this.getSelectorOptionsAndIndex.bind(this)
    this.getChoiceParams = this.getChoiceParams.bind(this)
    this.getChoiceOptionsAndIndex = this.getChoiceOptionsAndIndex.bind(this)
    this.run = this.run.bind(this)
    this.getNodePreview = this.getNodePreview.bind(this)
    this.copyNodes = this.copyNodes.bind(this)
    this.moveNodes = this.moveNodes.bind(this)
    this.openWizard = this.openWizard.bind(this)
    this.applyWizard = this.applyWizard.bind(this)
    this.setInputScalarValue = this.setInputScalarValue.bind(this)
    this.setSelectorValue = this.setSelectorValue.bind(this)
    this.setChoiceValue = this.setChoiceValue.bind(this)
    this.createAlias = this.createAlias.bind(this)
    this.getAllIndexes = this.getAllIndexes.bind(this)
    this.getNodeFormSelectorColumnValues = this.getNodeFormSelectorColumnValues.bind(this)
    this.getNodeInterfaceFormColumns = this.getNodeInterfaceFormColumns.bind(this)
    this.getFormColumnsNames = this.getFormColumnsNames.bind(this)
    this.copyAsValues = this.copyAsValues.bind(this)
    this.getFilesForImportWizard = this.getFilesForImportWizard.bind(this)
    this.getNodeFormRowLastUpdateInfo = this.getNodeFormRowLastUpdateInfo.bind(this)
    this.getNodeIndexValues = this.getNodeIndexValues.bind(this)
    this.createNodeFromCurrent = this.createNodeFromCurrent.bind(this)
    this.getValidatedValuesFromSelectorForm = this.getValidatedValuesFromSelectorForm.bind(this)
    this.uploadFileToTemp = this.uploadFileToTemp.bind(this)
  }

  public async navigateDiagram(moduleId: string | null): Promise<ModuleInfo> {
    try {
      const query = `?moduleId=${moduleId || ''}&includeArrows=true`
      const res: AxiosResponse<ModuleInfo> = await this.get(`${ENDPOINT}/navigateDiagram/${query}`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getArrows(moduleId: string | null): Promise<ModuleInfo> {
    try {
      const query = `?moduleId=${moduleId || ''}`
      const res: AxiosResponse<ModuleInfo> = await this.get(`${ENDPOINT}/getArrows/${query}`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createNode(
    moduleId: string,
    positionX: number,
    positionY: number,
    nodeClass: string
  ): Promise<DiagramNode> {
    try {
      const data = { nodeClass, x: positionX, y: positionY, moduleId }
      const res: AxiosResponse<DiagramNode> = await this.post(`${ENDPOINT}/createNode/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async deleteNodes(nodeIdArr: string[]): Promise<boolean> {
    try {
      const data = { values: nodeIdArr }
      const res: AxiosResponse<boolean> = await this.delete(`${ENDPOINT}/deleteNodes/`, {
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async compareNodes(nodeIds: string[], moduleId: string): Promise<CompareNodesResponse> {
    try {
      const res: AxiosResponse<CompareNodesResponse> = await this.post(
        `${ENDPOINT}/compareNodes/`,
        {
          node_ids: nodeIds,
          module_id: moduleId
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  /**
   *
   * @param node Node Id
   * @param properties ArrayOf objects with name of properties
   * @returns Node attributes
   */
  public async getCodeNodeProperties(
    node: string,
    properties: { name: string }[]
  ): Promise<CodeNode> {
    try {
      const res: AxiosResponse<CodeNode> = await this.post(`${ENDPOINT}/getCodeNodeProperties/`, {
        node,
        properties
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getNodeProperties(
    node: string,
    properties: { name: string }[]
  ): Promise<NodePropertiesResponse> {
    try {
      const res: AxiosResponse<NodePropertiesResponse> = await this.post(
        `${ENDPOINT}/getNodeProperties/`,
        {
          node,
          properties
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async deleteBaseInputNode(node: string): Promise<boolean> {
    try {
      const res: AxiosResponse<boolean> = await this.post(`${ENDPOINT}/deleteBaseInputNode/`, {
        node
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getNodesProperties(
    nodes: string[],
    properties: string[]
  ): Promise<NodesPropertiesResponse> {
    try {
      const res: AxiosResponse<NodesPropertiesResponse> = await this.post(
        `${ENDPOINT}/getNodesProperties/`,
        {
          nodes,
          properties
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  /**
   *
   * @param node Node Id
   * @param properties ArrayOf objects with name of properties
   * @returns Node attributes
   */
  public async getDiagramNodeProperties(
    node: string,
    properties: { name: string }[]
  ): Promise<DiagramNode> {
    try {
      const res: AxiosResponse<DiagramNode> = await this.post(
        `${ENDPOINT}/getDiagramNodeProperties/`,
        {
          node,
          properties
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async setNodeProperties(
    nodeId: string,
    nameValueArr: { name: string; value: any }[]
  ): Promise<boolean> {
    try {
      const data = { node: nodeId, properties: nameValueArr }
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/setNodeProperties/`, data)
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  public async setNodesProperties(data: SetNodesPropertiesParams[]): Promise<boolean> {
    try {
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/setNodesProperties/`, data)
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  public async setNodeIdFromTitle(nodeId: string): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/setNodeIdFromTitle/`, {
        value: nodeId
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async existNode(nodeId: string): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(`${ENDPOINT}/existNode/`, {
        value: nodeId
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async previewNodeIdFromTitle(
    nodeId: string,
    nodeTitle: string,
    prefix?: string
  ): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/previewNodeIdFromTitle/`, {
        nodeId,
        title: nodeTitle,
        prefix
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async setNodesPosition(
    nodesPositionSizeModuleArr: NodePositionWModule[]
  ): Promise<boolean> {
    try {
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/setNodesPosition/`, {
        values: nodesPositionSizeModuleArr
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  public async setNodesSize(nodesSizePositionArr: NodePositionAndSize[]): Promise<boolean> {
    try {
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/setNodesSize/`, {
        values: nodesSizePositionArr
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async listNodesFromModel(params: NodeParams): Promise<PaginatedResponse<Node>> {
    try {
      const res: AxiosResponse<PaginatedResponse<Node>> = await this.get<
        Node,
        AxiosResponse<PaginatedResponse<Node>>
      >(`${ENDPOINT}/list_nodes/`, {
        params
      })

      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async listInstalledLibraries(): Promise<InstalledLibrary[]> {
    try {
      const res = await this.get<InstalledLibrary[]>(`${ENDPOINT}/listInstalledLibraries/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  /**
   * @param lib Space separated library names
   */
  public async installLibraries(lib: string): Promise<string> {
    try {
      const data = { lib }
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/installLibrary/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  /**
   * @param lib Library name
   * @param target Path to library packages folder
   */
  public async uninstallLibrary(lib: string, target: string): Promise<boolean> {
    try {
      const data = { lib, target }
      const res: AxiosResponse<string> = await this.delete(`${ENDPOINT}/uninstallLibrary/`, {
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  public async getSelector(nodeId: string): Promise<SelectorInfo> {
    try {
      const data = { node: nodeId }
      const res: AxiosResponse<SelectorInfo> = await this.post(`${ENDPOINT}/getSelector/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getSelectorParams(nodeId: string): Promise<SelectorParamsInfo> {
    try {
      const data = { nodeId }
      const res: AxiosResponse<SelectorParamsInfo> = await this.post(
        `${ENDPOINT}/getSelectorParams/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getNode(params: NodePropParams): Promise<Node> {
    try {
      const res: AxiosResponse<Node> = await this.get(`${ENDPOINT}/get_node/`, {
        params
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getSelectorOptionsAndIndex(
    nodeId: string,
    page_size: number,
    text_filter?: string | null,
    url?: string | null,
    filter_type?: IndexTextFilterChoices | null
  ): Promise<SelectorOptionsAndIndexInfo> {
    try {
      const data = { nodeId, page_size, text_filter, filter_type, url }
      const res: AxiosResponse<SelectorOptionsAndIndexInfo> = await this.post(
        `${ENDPOINT}/getSelectorOptionsAndIndex/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async setSelectorValue(nodeId: string, value: number | number[]): Promise<boolean> {
    try {
      const data = { nodeId, value }
      const res: AxiosResponse = await this.post(`${ENDPOINT}/setSelectorValue/`, data)
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  public async getChoice(nodeId: string): Promise<ChoiceInfo> {
    try {
      const data = { nodeId }
      const res: AxiosResponse<ChoiceInfo> = await this.post(`${ENDPOINT}/getChoice/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getChoiceParams(nodeId: string): Promise<ChoiceParamsInfo> {
    try {
      const data = { nodeId }
      const res: AxiosResponse<ChoiceParamsInfo> = await this.post(
        `${ENDPOINT}/getChoiceParams/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getChoiceOptionsAndIndex(
    nodeId: string,
    page_size: number,
    text_filter?: string | null,
    url?: string | null,
    filter_type?: IndexTextFilterChoices | null
  ): Promise<ChoiceOptionsAndIndexInfo> {
    try {
      const data = { nodeId, page_size, text_filter, filter_type, url }
      const res: AxiosResponse<ChoiceOptionsAndIndexInfo> = await this.post(
        `${ENDPOINT}/getChoiceOptionsAndIndex/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async setChoiceValue(nodeId: string, value: number): Promise<boolean> {
    try {
      const data = { nodeId, value }
      const res: AxiosResponse = await this.post(`${ENDPOINT}/setChoiceValue/`, data)
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  async _createNodeResultInterface(nodeId: string, fromHandlingData?: boolean): Promise<boolean> {
    const res: AxiosResponse<boolean> = await this.post(`${ENDPOINT}/createNodeResultInterface/`, {
      nodeId,
      fromHandlingData
    })
    return this.success(res)
  }
  public async createNodeResultInterface(nodeId: string | undefined): Promise<boolean> {
    try {
      if (nodeId) {
        // Try _createNodeResultInterface the first time to avoid delay from setInterval
        if (ws?.readyState === 1) {
          return this._createNodeResultInterface(nodeId)
        }
        const triggerCall = setInterval(async () => {
          if (ws?.readyState === 1) {
            clearInterval(triggerCall)
            return await this._createNodeResultInterface(nodeId)
          }
        }, WS_TIME_INTERVAL_MS)
      }
      return false
    } catch (error) {
      throw error
    }
  }
  async _triggerNodeInterface(nodeId: string, eventData: InterfaceEvent): Promise<boolean> {
    const res: AxiosResponse<boolean> = await this.post(`${ENDPOINT}/triggerNodeInterface/`, {
      nodeId,
      eventData
    })
    return this.success(res)
  }
  public async triggerNodeInterface(
    nodeId: string | undefined,
    eventData: InterfaceEvent | undefined
  ): Promise<boolean> {
    try {
      if (nodeId && eventData) {
        // Try _triggerNodeInterface the first time to avoid delay from setInterval
        if (ws?.readyState === 1) {
          return this._triggerNodeInterface(nodeId, eventData)
        }
        const triggerCall = setInterval(async () => {
          if (ws?.readyState === 1) {
            clearInterval(triggerCall)
            return await this._triggerNodeInterface(nodeId, eventData)
          }
        }, WS_TIME_INTERVAL_MS)
      }
      return false
    } catch (error) {
      throw error
    }
  }
  public async getNodeInterfaceIndexValues(
    nodeId: string,
    componentId: string,
    indexId: string,
    paginateResults: boolean,
    page?: number,
    filter?: IndexFilterChoices | null,
    text1?: string | null,
    page_size?: number,
    applyFilters: boolean = true
  ): Promise<InterfaceIndexValuesData> {
    try {
      let data = {
        node_id: nodeId,
        component_id: componentId,
        index_id: indexId,
        paginate_results: paginateResults,
        apply_filters: applyFilters,
        page_size
      }
      if (page) {
        data = { ...data, ...{ page } }
      }
      if (filter && text1) {
        data = { ...data, ...{ filter, text1 } }
      }
      const res: AxiosResponse<InterfaceIndexValuesData> = await this.post(
        `${ENDPOINT}/getNodeInterfaceIndexValues/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getNodeInterfaceTableColumns(
    nodeId: string,
    componentId: string,
    indexId: string,
    paginateResults: boolean,
    page?: number,
    filter?: IndexFilterChoices | null,
    text1?: string | null,
    applyFilters: boolean = true
  ): Promise<InterfaceIndexValuesData> {
    try {
      let data = {
        node_id: nodeId,
        component_id: componentId,
        paginate_results: paginateResults
      }
      if (page) {
        data = { ...data, ...{ page } }
      }
      if (filter && text1) {
        data = { ...data, ...{ filter, text1 } }
      }
      const res: AxiosResponse<InterfaceIndexValuesData> = await this.post(
        `${ENDPOINT}/getNodeInterfaceTableColumns/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateNodeDefaultView(nodeId: string | undefined): Promise<void> {
    try {
      if (nodeId) {
        const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/updateNodeDefaultView/`, {
          nodeId
        })
        this.success(res)
      }
    } catch (error) {
      throw error
    }
  }
  public async setInputScalarValue(nodeId: string, value: string): Promise<boolean> {
    try {
      const data = { nodeId, value }
      const res: AxiosResponse = await this.post(`${ENDPOINT}/setInputScalarValue/`, data)
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  public async run(nodeId: string): Promise<boolean> {
    try {
      const data = { nodeId }
      const res: AxiosResponse<boolean> = await this.post(`${ENDPOINT}/run/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getNodePreview(nodeId: string): Promise<NodePreview> {
    try {
      const query = { nodeId }
      const res: AxiosResponse<NodePreview> = await this.get(`${ENDPOINT}/previewNode/`, {
        params: query
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async copyNodes(values: CopyNodesParams[]): Promise<string> {
    try {
      const data = { values }
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/copyNodes/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async moveNodes(values: CopyNodesParams[]): Promise<string> {
    try {
      const data = { values }
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/moveNodes/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async copyAsValues(nodeId: string): Promise<string> {
    try {
      const data = { nodeId, asNewNode: true }
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/copyAsValues/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async openWizard(
    wizardType: WizardTypes,
    action: WizardActions,
    params: OpenWizardParams
  ): Promise<WizardData> {
    try {
      const data = {
        wizard: wizardType,
        action,
        params
      }
      const res: AxiosResponse<WizardData> = await this.post(`${ENDPOINT}/callWizard/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async applyWizard(
    wizardType: WizardTypes,
    action: WizardActions,
    params: ApplyWizardParams
  ): Promise<[]> {
    try {
      const data = {
        wizard: wizardType,
        action,
        params
      }
      const res: AxiosResponse<[]> = await this.post(`${ENDPOINT}/callWizard/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async searchNodes(
    text: string,
    data: SearchNodesData,
    page_size: number,
    page: number,
    filter_by: string | undefined = ''
  ): Promise<PaginatedResponse<ResultNode>> {
    try {
      const query = `?text=${encodeURIComponent(
        text
      )}&page_size=${page_size}&page=${page}&filter_by=${filter_by}`
      const res: AxiosResponse<PaginatedResponse<ResultNode>> = await this.post(
        `${ENDPOINT}/searchNodes/${query}`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createAlias(nodeIds: string[]): Promise<CreateAliasResponse> {
    try {
      const data = {
        values: nodeIds
      }
      const res: AxiosResponse<CreateAliasResponse> = await this.post(
        `${ENDPOINT}/createAlias/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async exportModule(
    exportType: string,
    moduleId: string
  ): Promise<{ modelProp: object; nodeList: object[] }> {
    try {
      const res: AxiosResponse<{ modelProp: object; nodeList: object[] }> = await this.post(
        `${ENDPOINT}/exportModuleToFile/`,
        {
          exportType,
          moduleId
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async uploadFileToTemp(data: FormData, token: CancelToken): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/uploadFileToTemp/`, data, {
        cancelToken: token
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async importModuleFromFile(data: ModuleFromFileData): Promise<any> {
    try {
      const res: AxiosResponse<any> = await this.post(`${ENDPOINT}/importModuleFromFile/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getAllIndexes(
    nodeId: string,
    paginateResults: boolean,
    page?: number,
    filter?: IndexTextFilterChoices | null,
    text?: string | null
  ): Promise<GetAllIndexesData> {
    try {
      let data = {
        node_id: nodeId,
        paginate_results: paginateResults
      }
      if (page) {
        data = { ...data, ...{ page } }
      }
      if (filter && text) {
        data = { ...data, ...{ filter, text } }
      }
      const res: AxiosResponse<GetAllIndexesData> = await this.post(
        `${ENDPOINT}/getAllIndexes/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getValidatedValuesFromSelectorForm(
    nodeId: string,
    componentId: string,
    newValues: any[],
    text?: string
  ): Promise<boolean[]> {
    try {
      let data = {
        node_id: nodeId,
        component_id: componentId,
        new_values: newValues
      }
      if (text) {
        data = { ...data, ...{ text } }
      }
      const res: AxiosResponse<boolean[]> = await this.post(
        `${ENDPOINT}/getValidatedValuesFromSelectorForm/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getNodeFormSelectorColumnValues(
    nodeId: string,
    componentId: string,
    rowId: number,
    columnName: string,
    text?: string
  ): Promise<(string | number)[]> {
    try {
      let data = {
        node_id: nodeId,
        component_id: componentId,
        row_id: rowId,
        column_name: columnName
      }
      if (text) {
        data = { ...data, ...{ text } }
      }
      const res: AxiosResponse<(string | number)[]> = await this.post(
        `${ENDPOINT}/getNodeFormSelectorColumnValues/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getNodeInterfaceFormColumns(
    nodeId: string,
    componentId: string,
    paginateResults: boolean,
    page?: number,
    filter?: IndexTextFilterChoices | null,
    text1?: string | null,
    page_size?: number
  ): Promise<GetFormColumnsData> {
    try {
      let data = {
        node_id: nodeId,
        paginate_results: paginateResults,
        page_size
      }
      if (page) {
        data = { ...data, ...{ page } }
      }
      if (filter && text1) {
        data = { ...data, ...{ filter, text1 } }
      }
      const res: AxiosResponse<GetFormColumnsData> = await this.post(
        `${ENDPOINT}/getNodeInterfaceFormColumns/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getFormColumnsNames(nodeId: string): Promise<string[]> {
    try {
      const res: AxiosResponse<string[]> = await this.get(
        `${ENDPOINT}/getFormColumnsNames/?node_id=${nodeId}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getFilesForImportWizard(extension: string): Promise<MainFolder[]> {
    try {
      const res: AxiosResponse<MainFolder[]> = await this.get(
        `${ENDPOINT}/getFilesForImportWizard/?extension=${extension}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async stop(): Promise<void> {
    try {
      const res: AxiosResponse = await this.post(`${ENDPOINT}/stop/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async debugMode(debugMode: Boolean): Promise<void> {
    try {
      const data = { debugMode }
      const res: AxiosResponse = await this.post(`${ENDPOINT}/debugMode/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async clearResults(invalidateNodes?: Boolean): Promise<void> {
    try {
      const data = { invalidateNodes }
      const res: AxiosResponse = await this.post(`${ENDPOINT}/clearResults/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async evaluate(definition?: string): Promise<any> {
    try {
      const data = { definition }
      const res: AxiosResponse<any> = await this.post(`${ENDPOINT}/evaluate/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async formatCodeDefinition(definition: string): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/formatCodeDefinition/`, {
        definition
      })

      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async getNodeFormRowLastUpdateInfo(
    formNodeId: string,
    rowId: number | string
  ): Promise<RowLastUpdateInformation> {
    try {
      const data = {
        form_node_id: formNodeId,
        row_id: rowId
      }
      const res: AxiosResponse<RowLastUpdateInformation> = await this.post(
        `${ENDPOINT}/getNodeFormRowLastUpdateInfo/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async getNodeIndexValues(
    nodeId: string,
    paginateResults: boolean,
    page?: number,
    filter?: IndexFilterChoices | null,
    text1?: string | null
  ): Promise<InterfaceIndexValuesData> {
    try {
      let data = {
        node_id: nodeId,
        paginate_results: paginateResults
      }
      if (page) {
        data = { ...data, ...{ page } }
      }
      if (filter && text1) {
        data = { ...data, ...{ filter, text1 } }
      }
      const res: AxiosResponse<InterfaceIndexValuesData> = await this.post(
        `${ENDPOINT}/getNodeIndexValues/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async reWriteHandleDataSystemNode(identifier: string): Promise<boolean> {
    try {
      const data = { identifier }
      const res: AxiosResponse<boolean> = await this.post(
        `${ENDPOINT}/reWriteHandleDataSystemNode/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async undoDefinitionStack(identifier: string, error?: boolean): Promise<boolean> {
    try {
      const data = { identifier, error }
      const res: AxiosResponse<boolean> = await this.post(`${ENDPOINT}/undoDefinitionStack/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async redoDefinitionStack(identifier: string): Promise<boolean> {
    try {
      const data = { identifier }
      const res: AxiosResponse<boolean> = await this.post(`${ENDPOINT}/redoDefinitionStack/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createNodeFromCurrent(identifier: string, definition: string): Promise<string> {
    try {
      const data = { identifier, definition }
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/createNodeFromCurrent/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async searchValueConditionWizard(
    searchText: string,
    column: string,
    nodeId: string,
    page: number
  ) {
    try {
      const data = { searchText, column, nodeId, page }
      const res: AxiosResponse = await this.post(`${ENDPOINT}/searchValueConditionWizard/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const modelApi = new ModelApi(apiConfig)
