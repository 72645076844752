import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'
import { DepartmentPermissionsType } from 'common/types/department'

import {
  InterfaceItems,
  Interface,
  InterfaceFolder,
  InterfaceParam,
  ListParams,
  ListInterfaceResponse,
  InterfaceCopyOrMoveData,
  FolderCopyOrMoveData,
  GetInterfaceByAppPathData,
  InterfacesByPath
} from 'pages/interfaces/types'

const INTERFACES = '/interfaces'

class InterfaceManagerApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, InterfaceManagerApi.prototype)
    this.getFoldersAndInterfaces = this.getFoldersAndInterfaces.bind(this)
    this.createInterfaceFolder = this.createInterfaceFolder.bind(this)
    this.createInterface = this.createInterface.bind(this)
    this.updateInterface = this.updateInterface.bind(this)
    this.renameFolder = this.renameFolder.bind(this)
    this.deleteInterfaceFolders = this.deleteInterfaceFolders.bind(this)
    this.deleteInterfaces = this.deleteInterfaces.bind(this)
    this.duplicateInterface = this.duplicateInterface.bind(this)
    this.duplicateFolder = this.duplicateFolder.bind(this)
    this.searchInterfaces = this.searchInterfaces.bind(this)
    this.listInterfaces = this.listInterfaces.bind(this)
    this.setInterfacePermission = this.setInterfacePermission.bind(this)
    this.interfaceNameExists = this.interfaceNameExists.bind(this)
  }

  public async getFoldersAndInterfaces(path?: string): Promise<InterfaceItems> {
    try {
      const res: AxiosResponse<InterfaceItems> = await this.get(
        `${INTERFACES}/getAll/?path=${encodeURIComponent(path || '')}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async createInterfaceFolder(data: InterfaceParam): Promise<InterfaceFolder> {
    try {
      const res: AxiosResponse<InterfaceFolder> = await this.post(
        `${INTERFACES}/createFolder/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async createInterface(data: InterfaceParam): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.post(`${INTERFACES}/createInterface/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async updateInterface(data: Interface): Promise<Boolean> {
    try {
      const res: AxiosResponse<Boolean> = await this.put(`${INTERFACES}/updateInterface/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async renameFolder(data: InterfaceFolder): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.put(`${INTERFACES}/renameFolder/`, data)
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async deleteInterfaceFolders(uris: string[]): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.delete(`${INTERFACES}/removeFolder/`, {
        data: { uris }
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async deleteInterfaces(ids: string[]): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.delete(`${INTERFACES}/removeInterface/`, {
        data: { ids }
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  public async copyInterface(data: InterfaceCopyOrMoveData): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.post(`${INTERFACES}/copyInterface/`, {
        data: data
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
  public async copyFolder(data: FolderCopyOrMoveData): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.post(`${INTERFACES}/copyFolder/`, {
        data: data
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async moveFolder(data: FolderCopyOrMoveData): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.post(`${INTERFACES}/moveFolder/`, {
        data: data
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async moveInterface(data: InterfaceCopyOrMoveData): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.post(`${INTERFACES}/moveInterface/`, {
        data: data
      })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async duplicateInterface(ids: string[]): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.post(`${INTERFACES}/duplicateInterface/`, { ids })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async duplicateFolder(uri: string): Promise<boolean> {
    try {
      const res: AxiosResponse = await this.post(`${INTERFACES}/duplicateFolder/`, { uri })
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async searchInterfaces(text: string): Promise<InterfaceItems> {
    try {
      const res: AxiosResponse<InterfaceItems> = await this.get(
        `${INTERFACES}/search/?text=${text}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async listInterfaces(data?: ListParams): Promise<ListInterfaceResponse<Interface>> {
    try {
      let query = ''
      if (data) {
        query = `?text=${data.text || ''}&page=${data.page || ''}`
        if (data.page_size) {
          query = `${query}&page_size=${data.page_size}`
        }
      }

      const res: AxiosResponse<ListInterfaceResponse<Interface>> = await this.get(
        `${INTERFACES}/list_interfaces/${query}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getInterfacesByAppPath(
    data: GetInterfaceByAppPathData
  ): Promise<InterfacesByPath[]> {
    try {
      const res: AxiosResponse<InterfacesByPath[]> = await this.post(
        `${INTERFACES}/getInterfacesByAppPath/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async setInterfacePermission(
    data: Interface[],
    permissionType: DepartmentPermissionsType
  ): Promise<boolean> {
    try {
      const apiData = { interfaces: data, permissionType }
      const res: AxiosResponse = await this.put(`${INTERFACES}/setInterfacePermission/`, apiData)
      return res.status === 200
    } catch (error) {
      throw error
    }
  }

  public async interfaceNameExists(
    interfaceName: string,
    path?: string,
    interfaceId?: string
  ): Promise<boolean> {
    try {
      const query = { name: interfaceName.trim(), path, interfaceId }
      const res: AxiosResponse = await this.get(`${INTERFACES}/interfaceNameExists/`, {
        params: query
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async exportInterface(interfaceId: string): Promise<void> {
    try {
      const data = { interfaceId }
      const res: AxiosResponse = await this.post(`${INTERFACES}/exportInterface/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async checkFolderExists(name: string): Promise<boolean> {
    try {
      const data = { name }
      const res: AxiosResponse = await this.post(`${INTERFACES}/folderExists/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async importInterface(interfaceFile: string[], currentFolder?: string): Promise<boolean> {
    try {
      const data = { interfaceFile, currentFolder }
      const res: AxiosResponse = await this.post(`${INTERFACES}/importInterface/`, data)
      return res.status === 200
    } catch (error) {
      throw error
    }
  }
}

export const interfaceManagerApi = new InterfaceManagerApi(apiConfig)
