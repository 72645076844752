import qs from 'qs'
import { PathLike } from 'fs'
import { AxiosRequestConfig } from 'axios'
import { API_BASE_URL } from './connectionConsts'

const baseURL =
  process.env.NODE_ENV === 'production' ? `${window.location.origin}/api` : `${API_BASE_URL}/api`

export const apiConfig: AxiosRequestConfig = {
  withCredentials: true,
  timeout: 14400000, //60 * 60 * 4 * 1000,
  baseURL,
  headers: {
    Accept: 'application/json, text/javascript, */*; q=0.01',
    'Content-Type': 'application/json'
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  paramsSerializer: (params: PathLike) => qs.stringify(params, { indices: false })
}
