import {
  AssistantBotAssistantType,
  AssistantBotChatMessage,
  AssistantBotEngineType
} from 'common/types/assistantBot'
import { AssistantBotKey, AssistantBotActionTypes } from './types'

export const queryChat = (
  assistantType: AssistantBotAssistantType,
  text: string,
  threadId: string | undefined | null,
  regenerateResponse: boolean,
  applicationAssistantId: string | undefined | null,
  engineType: AssistantBotEngineType | undefined
) => ({
  type: AssistantBotActionTypes.QUERY,
  payload: {
    assistantType,
    text,
    threadId,
    regenerateResponse,
    applicationAssistantId,
    engineType
  }
})

export const showAssistantBot = (assistantBotKey: AssistantBotKey, show: boolean) => ({
  type: AssistantBotActionTypes.SHOW,
  payload: { assistantBotKey, show }
})

export const appendChatMessage = (
  assistantBotKey: AssistantBotKey,
  message: AssistantBotChatMessage
) => ({
  type: AssistantBotActionTypes.APPEND_MESSAGE,
  payload: {
    assistantBotKey,
    message
  }
})

export const updateMessages = (
  assistantBotKey: AssistantBotKey,
  messages: AssistantBotChatMessage[]
) => ({
  type: AssistantBotActionTypes.UPDATE_MESSAGES,
  payload: { assistantBotKey, messages }
})
export const setPosition = (assistantBotKey: AssistantBotKey, position: object) => ({
  type: AssistantBotActionTypes.SET_POSITION,
  payload: { assistantBotKey, position }
})
export const setSize = (assistantBotKey: AssistantBotKey, newSize: object) => ({
  type: AssistantBotActionTypes.SET_SIZE,
  payload: { assistantBotKey, newSize }
})

export const setClearMessages = (assistantBotKey: AssistantBotKey) => ({
  type: AssistantBotActionTypes.CLEAR_MESSAGE,
  payload: {
    assistantBotKey
  }
})

export const setIsFloating = (assistantBotKey: AssistantBotKey, isFloating: boolean) => ({
  type: AssistantBotActionTypes.SET_FLOATING,
  payload: { assistantBotKey, isFloating }
})

export const listenBotMessages = (
  assistantBotKey: AssistantBotKey,
  listenBotMessages: boolean
) => ({
  type: AssistantBotActionTypes.LISTEN_BOT_MESSAGES,
  payload: { assistantBotKey, listenBotMessages }
})

export const setExcludedThreadId = (
  assistantBotKey: AssistantBotKey,
  excludedThreadId: string
) => ({
  type: AssistantBotActionTypes.SET_EXCLUDED_THREAD_ID,
  payload: { assistantBotKey, excludedThreadId }
})

export const setClearExcludedThreadId = (assistantBotKey: AssistantBotKey) => ({
  type: AssistantBotActionTypes.CLEAR_EXCLUDED_THREAD_ID,
  payload: { assistantBotKey }
})

export const setAssistantBotType = (
  assistantBotKey: AssistantBotKey,
  assistantBotType: AssistantBotAssistantType,
  assistantBotId?: string | null,
  assistantBotEngineType?: AssistantBotEngineType
) => ({
  type: AssistantBotActionTypes.SET_ASSISTANT_BOT_TYPE,
  payload: { assistantBotKey, assistantBotType, assistantBotId, assistantBotEngineType }
})

export const setAssistantBotTypeQuestionAnswered = (
  assistantBotKey: AssistantBotKey,
  answered: boolean
) => ({
  type: AssistantBotActionTypes.SET_ASSISTANT_BOT_TYPE_QUESTION_ANSWERED,
  payload: { assistantBotKey, answered }
})

export const showApplicationAssistantBot = (assistantBotId: string) => ({
  type: AssistantBotActionTypes.SHOW_APPLICATION_ASSISTANT_BOT,
  payload: assistantBotId
})

export const setApplicationAssistantBot = (assistantBotId?: string) => ({
  type: AssistantBotActionTypes.SET_APPLICATION_ASSISTANT_BOT,
  payload: assistantBotId
})
