import { NotificationLevels } from '../notifier/types'
import { InterfaceFeedback, NodeFeedback, NodeInterfaceFeedback } from 'common/types/model'
import {
  FilterComponent,
  IBaseComponent,
  IRenderComponent
} from 'pages/interfaces/components/types'
import { StatusInternalTests } from 'common/types/internalTests'
import { AssistantBotAssistantType } from 'common/types/assistantBot'

export enum MessageType {
  OPENING_APP = 'OPENING_APP',
  SAVING_APP = 'SAVING_APP',
  NODE_BUTTON_FINISH_PROCESSING = 'NODE_BUTTON_FINISH_PROCESSING',
  NODE_DEBUG_INFORMATION = 'NODE_DEBUG_INFORMATION',
  STANDARD_MESSAGE = 'STANDARD_MESSAGE',
  PROGRESS_BAR = 'PROGRESS_BAR',
  KILLED_INSTANCE = 'KILLED_INSTANCE',
  UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS',
  UPDATE_TASK_LOG = 'UPDATE_TASK_LOG',
  UPDATE_INSTANCE_RESOURCES = 'UPDATE_INSTANCE_RESOURCES',
  SEND_NODE_ERROR = 'SEND_NODE_ERROR',
  SEND_INTERFACE_ERROR = 'SEND_INTERFACE_ERROR',
  SEND_NODE_INTERFACE_ERROR = 'SEND_NODE_INTERFACE_ERROR',
  SEND_NODE_CONSOLE = 'SEND_NODE_CONSOLE',
  APP_UNSAVED_CHANGES = 'APP_UNSAVED_CHANGES',
  LIBRARIES_INSTALL_PROGRESS = 'LIBRARIES_INSTALL_PROGRESS',
  COMPONENTS_THAT_NEED_RENDERING = 'COMPONENTS_THAT_NEED_RENDERING',
  COMPONENT_RESULT = 'COMPONENT_RESULT',
  NODE_INTERFACE_RESULT = 'NODE_INTERFACE_RESULT',
  PURGED_INSTANCE = 'PURGED_INSTANCE',
  PURGED_SESSION = 'PURGED_SESSION',
  ENGINE_DOWN = 'ENGINE_DOWN',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  UPLOAD_FILES = 'UPLOAD_FILES',
  INTERNAL_TESTS_STATUS = 'INTERNAL_TESTS_STATUS',
  REFRESH_CURRENT_INTERFACE = 'REFRESH_CURRENT_INTERFACE',
  APP_LOCK_INFO = 'APP_LOCK_INFO',
  REFRESH_APP_NOTIFICATIONS = 'REFRESH_APP_NOTIFICATIONS',
  ASSISTANT_BOT_RESPONSE = 'ASSISTANT_BOT_RESPONSE',
  CODE_HELPER_RESPONSE = 'CODE_HELPER_RESPONSE',
  FILTER_RESULT = 'FILTER_RESULT',
  FILTER_COMPONENTS_THAT_NEED_RENDERING = 'FILTER_COMPONENTS_THAT_NEED_RENDERING'
}

type BaseSocketMessage = {
  messageType: MessageType
}

export interface SocketMessage extends BaseSocketMessage {
  title?: string
  message: string
  notLevel: NotificationLevels
  progress?: number
  interfaceId?: string
  result?: IBaseComponent
  closeWhenFinished?: boolean
  autoHideDuration?: number
}

export interface SocketMessageInternalTests extends BaseSocketMessage {
  statusTests?: StatusInternalTests
}

export interface SocketInstanceResources {
  cpu: number
  usedMemory: number
  totalMemory: number
}

export enum actionTypes {
  START_CALC = 'startCalc',
  END_CALC = 'endCalc'
}

export interface socketModelDebug {
  action: actionTypes
  cumulativeTime: number
  fromDynamic: string
  instance_id: string
  maxMemory: number
  messageType: MessageType
  node: string
  time: number
  title: string
  totalMemory: number
  usedMemory: number
  order: number
}

export interface socketModelDebugAndId extends socketModelDebug {
  id: number
}

export enum ScheduleTaskStates {
  PROGRESS = 'PROGRESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  FAILURE = 'FAILURE',
  RETRY = 'RETRY',
  SUCCESS = 'SUCCESS',
  REVOKED = 'REVOKED',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  RECEIVED = 'RECEIVED'
}

export interface SocketNodeFeedback extends BaseSocketMessage {
  message: NodeFeedback
}

export interface SocketInterfaceFeedback extends BaseSocketMessage {
  message: InterfaceFeedback
}

export interface SocketNodeInterfaceFeedback extends BaseSocketMessage {
  message: NodeInterfaceFeedback
}

export interface SocketInterfaceComponentsList extends BaseSocketMessage {
  interfaceId: string
  componentsList: string[]
}

export interface SocketInterfaceFilterComponentsList extends BaseSocketMessage {
  interfaceId: string
  componentIds: string[]
}

export interface SocketInterfaceComponentResult extends BaseSocketMessage {
  interfaceId: string
  result: IRenderComponent
}

export interface SocketInterfaceFilterComponentResult extends BaseSocketMessage {
  interfaceId: string
  result: FilterComponent
}

export interface SocketInstanceStatusMessage extends BaseSocketMessage {
  title: string
  message: string
  instanceId: string
}

export interface SocketAssistantBotMessage extends BaseSocketMessage {
  message: string
  instanceId: string
  stop: boolean
  threadId: string
  assistantType: AssistantBotAssistantType
  error?: string
}

export interface SocketCodeHelperMessage extends BaseSocketMessage {
  message: string
  nodeId: string
  instanceId: string
  assistantType: AssistantBotAssistantType
}

export interface InstallationLog {
  log: string
  notLevel: NotificationLevels
}

export interface SocketDownloadMessage extends BaseSocketMessage {
  filePath: string
}

interface UploadedFile {
  uploadedFilePath: string
  targetFileName: string
}

export interface UploaderParams {
  show?: boolean
  targetPath: string
  targetFilename?: string
  allowedExtensions?: string[]
  validationNodeId?: string
  callbackNodeId?: string
  listOfNodeIdsToInvalidate?: string[]
  multiple?: boolean
  uploadText?: string
  uploadedFiles?: UploadedFile[]
}

export interface SocketUploadMessage extends BaseSocketMessage {
  uploadParams: UploaderParams
}

export interface SocketAppInfo extends BaseSocketMessage {
  appIsLocked: boolean
}
