import { Props as SplitPaneProps } from 'react-split-pane'

export enum WidgetType {
  DIAGRAM = 'diagram',
  CODE = 'code',
  RESULT = 'result',
  CONSOLE = 'console',
  DOCS = 'docs'
}

interface IPane {
  key: string
}
export interface ISplitPane extends IPane {
  type: 'pane'
  splitPane: SplitPaneProps
  children: WidgetProps[]
}
interface IWidget extends IPane {
  type: 'widget'
  contentType: WidgetType
  dividerKey?: CodeDividers
  defaultDividerSize?: number
}
export type WidgetProps = ISplitPane | IWidget

export type CodeLayout = {
  id: number
  name: string
  defaultName: string
  icon: string
  widgets: WidgetProps
}

export enum CodeDividers {
  DIAGRAM_DIVIDER = 'diagramDivider',
  DIAGRAM_BOT_DIVIDER = 'diagramBotDivider',
  CODE_DIVIDER = 'codeDivider',
  RESULT_DIVIDER = 'resultDivider',
  CODE_FEEDBACK_DIVIDER = 'codeFeedbackDivider',
  CONSOLE_DIVIDER = 'consoleDivider',
  DOCS_DIVIDER = 'docsDivider'
}

export enum Layout {
  DiagramAndResult = 1,
  DiagramAndCode,
  DiagramCodeAndResult
}

export interface Code {
  layouts?: CodeLayout[]
  selectedLayout?: Layout
  showResultToolbar?: boolean
  diagramDivider?: number
  diagramBotDivider?: number
  codeDivider?: number
  resultDivider?: number
  codeFeedbackDivider?: number
  codeFeedbackExpanded?: boolean
  consoleDivider?: number
  docsDivider?: number
  widgetExpanded?: WidgetType
  preserveLog?: boolean
}
