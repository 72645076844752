import { NavigateFunction } from 'react-router-dom'

import { EditUserProfileForm, UserAuthData } from '../../common/types/user'
import { UserInstanceData } from '../../common/types/instance'
import { LicenseData } from '../../common/types/license'
import { AppInstanceInfo } from '../../common/types/application'
import { CodeDividers, CodeLayout, WidgetType } from '../../common/types/code'
import {
  UserActionTypes,
  IdNumberPayload,
  HistoryAndStringPayload,
  SKStringAndModelinfoPayload,
  HistoryPayload
} from './types'
import { View, NameAndPath, LastPathData } from 'common/types/fileManager'
import { Company } from 'common/types/company'

/**
 * login
 * @param {string} username
 * @param {string} password
 * @return {object}
 */
export const login = ({ username, password }: { username: string; password: string }): object => {
  return {
    type: UserActionTypes.LOGIN,
    payload: { username, password }
  }
}

/**
 * logout
 * @return {object}
 */
export const logout = (navigate: NavigateFunction): HistoryPayload => {
  return {
    type: UserActionTypes.LOGOUT,
    payload: { navigate }
  }
}

/**
 * selectCompany
 * @param {number} companyId
 * @return {object}
 */
export const selectCompany = ({ id }: { id: number }): IdNumberPayload => {
  return {
    type: UserActionTypes.SELECT_COMPANY,
    payload: { id }
  }
}

export const samlSelectCompany = ({ id, navigate }: { id: number; navigate: NavigateFunction }) => {
  return {
    type: UserActionTypes.SAML_SELECT_COMPANY,
    payload: { id, navigate }
  }
}

export const changeCompany = () => {
  return {
    type: UserActionTypes.CHANGE_COMPANY
  }
}

export const companyHasChanged = (companyHasChanged: boolean) => {
  return {
    type: UserActionTypes.COMPANY_HAS_CHANGED,
    payload: companyHasChanged
  }
}

export const resetAppUserInfo = () => {
  return {
    type: UserActionTypes.RESET_APP_USER_INFO
  }
}

export const setUserAuthData = (data: UserAuthData | null) => {
  return {
    type: UserActionTypes.SET_USER_AUTH_DATA,
    payload: data
  }
}

export const setSelectedCompanyId = (selectedCompanyId: number | undefined) => {
  return {
    type: UserActionTypes.SET_SELECTED_COMPANY_ID,
    payload: selectedCompanyId
  }
}

export const loadInstanceData = (
  navigate: NavigateFunction,
  instanceId: string | null,
  pathname: string,
  disableOpenWithLoginAction?: boolean
): HistoryAndStringPayload => {
  return {
    type: UserActionTypes.LOAD_USER_INSTANCE_DATA,
    payload: { navigate, data: instanceId, pathname, disableOpenWithLoginAction }
  }
}

export const changeActiveInstance = ({
  newInstanceId,
  newApplicationInfo
}: {
  newInstanceId: string
  newApplicationInfo: AppInstanceInfo
}): SKStringAndModelinfoPayload => {
  return {
    type: UserActionTypes.CHANGE_ACTIVE_INSTANCE,
    payload: { newInstanceId, newApplicationInfo }
  }
}

export const setUserInstanceData = (data: UserInstanceData | null) => {
  return {
    type: UserActionTypes.SET_USER_INSTANCE_DATA,
    payload: data
  }
}

export const setInvalidLicenseData = (data: LicenseData | null) => {
  return {
    type: UserActionTypes.SET_LICENSE_DATA,
    payload: data
  }
}

export const setInvalidAuthErrorMessage = (data: string | null) => {
  return {
    type: UserActionTypes.SET_INVALID_AUTH_ERROR,
    payload: data
  }
}

export const setUser = (name: string, surname: string) => ({
  type: UserActionTypes.SET_USER,
  payload: { name, surname }
})

export const loadCodeLayouts = () => {
  return {
    type: UserActionTypes.LOAD_CODE_LAYOUTS
  }
}

export const setCodeLayouts = (codeLayouts?: CodeLayout[]) => {
  return {
    type: UserActionTypes.SET_CODE_LAYOUTS,
    payload: codeLayouts
  }
}

export const setSelectedCodeLayout = (
  codeLayoutId: number,
  forceGetNodeResult: boolean = false
) => {
  return {
    type: UserActionTypes.SET_SELECTED_CODE_LAYOUT,
    payload: { codeLayoutId, forceGetNodeResult }
  }
}

export const toggleResultToolbar = () => {
  return {
    type: UserActionTypes.TOGGLE_RESULT_TOOLBAR,
    payload: null
  }
}

export const editUserProfile = (formValues: EditUserProfileForm, navigate: NavigateFunction) => {
  return {
    type: UserActionTypes.EDIT_USER_PROFILE,
    payload: { formValues, navigate }
  }
}

export const setCodeDivider = (divider: CodeDividers, value: number) => {
  return {
    type: UserActionTypes.SET_CODE_DIVIDER,
    payload: { [divider]: value }
  }
}

export const setCodeExpanded = (expanded: boolean) => {
  return {
    type: UserActionTypes.SET_CODE_EXPANDED,
    payload: expanded
  }
}

export const setWidgetExpanded = (widgetType?: WidgetType) => {
  return {
    type: UserActionTypes.SET_WIDGET_EXPANDED,
    payload: widgetType
  }
}

export const setLastPath = (lastPath?: LastPathData) => {
  return {
    type: UserActionTypes.SET_LAST_PATH,
    payload: lastPath
  }
}
export const setViewPreference = (preference?: View) => {
  return {
    type: UserActionTypes.VIEW_PREFERENCE,
    payload: preference
  }
}

export const setSelectedFromSearch = (selected?: NameAndPath) => {
  return {
    type: UserActionTypes.SELECTED_FROM_SEARCH,
    payload: selected
  }
}

export const setPreserveLog = (preserveLog: boolean) => {
  return {
    type: UserActionTypes.PRESERVE_CONSOLE_LOG,
    payload: preserveLog
  }
}

export const setShowHiddenFiles = (value?: boolean) => {
  return {
    type: UserActionTypes.SHOW_HIDDEN_FILES,
    payload: value
  }
}

export const setNeedToChangePassword = (value?: boolean) => {
  return {
    type: UserActionTypes.NEED_TO_CHANGE_PASSWORD,
    payload: value
  }
}

export const setActivateExternalInstance = (userInstanceData: UserInstanceData) => {
  return {
    type: UserActionTypes.ACTIVATE_EXTERNAL_INSTANCE,
    payload: { userInstanceData }
  }
}

export const continueLogin = (companies: Company[]) => {
  return {
    type: UserActionTypes.CONTINUE_LOGIN,
    payload: { companies }
  }
}

export const enableLogin = () => {
  return {
    type: UserActionTypes.ENABLE_LOGIN
  }
}

export const isLoadingCompany = (loading: boolean) => {
  return {
    type: UserActionTypes.IS_LOADING_COMPANY,
    payload: loading
  }
}

export const reloadInstances = (reload: boolean) => {
  return {
    type: UserActionTypes.RELOAD_INSTANCES,
    payload: reload
  }
}
