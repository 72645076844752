import { ActiveTab, OpenInterfaceCurrentTab } from './../../common/types/interface'
import { Interface, NavigationHistory } from 'pages/interfaces/types'
import { IndexFilterInfo, SelectedState, TabItem } from 'common/types/model'
import {
  DrilldownOptionsInfo,
  GenericComponent,
  IndicatorStyle,
  TableStyle,
  ChartStyle,
  IDynamicHTML,
  SelectorOptionsMax,
  ApplicationComponents,
  FilterComponent
} from 'pages/interfaces/components/types'
import { FilteredComponentValue, GridLayout } from 'pages/interfaces/containers/types'

export enum InterfaceActionTypes {
  SELECT_COMPONENT = '@@interface/SELECT_COMPONENT',
  OPEN_INDEX_FILTER_DIALOG = '@@interface/OPEN_INDEX_FILTER_DIALOG',
  SET_INDEX_FILTER_DATA = '@@interface/SET_INDEX_FILTER_DATA',
  CLOSE_INDEX_FILTER_DIALOG = '@@interface/CLOSE_INDEX_FILTER_DIALOG',
  SET_DRILLDOWN_OPTIONS = '@@interface/SET_DRILLDOWN_OPTIONS',
  UPDATE_LAYOUT = '@@interface/UPDATE_LAYOUT',
  UPDATE_LAYOUT_POSITIONS = '@@interface/UPDATE_LAYOUT_POSITIONS',
  SET_COMPONENT_IS_LOADING = '@@interface/SET_COMPONENT_IS_LOADING',
  SAVE_LAYOUT = '@@interface/SAVE_LAYOUT',
  SET_INTERFACE_WIDGET_EXPANDED = '@@interface/SET_INTERFACE_WIDGET_EXPANDED',
  SET_COMPONENT_TO_EDIT = '@@interface/SET_COMPONENT_TO_EDIT',
  COPY_STYLES = '@@interface/COPY_STYLES',
  PASTE_STYLES = '@@interface/PASTE_STYLES',
  SET_RECENTLY_USED_COLORS = '@@interface/SET_RECENTLY_USED_COLORS',
  SET_INTERFACE_TABS = '@@interface/SET_INTERFACE_TABS',
  SET_INTERFACE_OPENED_FROM_MANAGER = '@@interface/SET_INTERFACE_OPENED_FROM_MANAGER',
  SET_ALL_INTERFACES = '@@interface/SET_ALL_INTERFACES',
  SET_INTERFACE_WITH_CHANGES = '@@interface/SET_INTERFACE_WITH_CHANGES',
  SET_CLICKED_FROM_TOPBAR = '@@interface/SET_CLICKED_FROM_TOPBAR',
  CANCEL_ACTION_FROM_TOPBAR = '@@interface/CANCEL_ACTION_FROM_TOPBAR',
  CHANGE_DEFAULT_INTERFACE = '@@interface/CHANGE_DEFAULT_INTERFACE',
  SET_MENU_FOLDER_ID = '@@interface/SET_MENU_FOLDER_ID',
  SET_MENU_FOLDER_HISTORY = '@@interface/SET_MENU_FOLDER_HISTORY',
  SET_ACCORDION_MENU_ITEMS = '@@interface/SET_ACCORDION_MENU_ITEMS',
  SET_INTERFACE_OPENED_FROM_CREATION = '@@interface/SET_INTERFACE_OPENED_FROM_CREATION',
  SET_REFRESH_CURRENT_INTERFACE = '@@interface/SET_REFRESH_CURRENT_INTERFACE',
  SET_EXTERNAL_LAYOUT = '@@interface/SET_EXTERNAL_LAYOUT',
  SET_INTERFACE_FROM_LINK = '@@interface/SET_INTERFACE_FROM_LINK',
  SET_EXTERNAL_INTERFACE_ID = '@@interface/SET_EXTERNAL_INTERFACE_ID',
  SET_LAST_ACCESSED_PATH = '@@interface/SET_LAST_ACCESSED_PATH',
  SET_CURRENT_NAVIGATION_HISTORY = '@@interface/SET_CURRENT_NAVIGATION_HISTORY',
  SET_OPEN_INTERFACE_CURRENT_TAB = '@@interface/SET_OPEN_INTERFACE_CURRENT_TAB',
  SET_DEFAULT_INTERFACE_LAYOUT = '@@interface/SET_DEFAULT_INTERFACE_LAYOUT',
  UPDATE_DEFAULT_INTERFACE_LAYOUT = '@@interface/UPDATE_DEFAULT_INTERFACE_LAYOUT',
  SET_EDITOR_DYNAMIC_HTML = '@@interface/SET_EDITOR_DYNAMIC_HTML',
  SET_VERTICAL_DYNAMIC_HTML_SIZE = '@@interface/SET_VERTICAL_DYNAMIC_HTML_SIZE',
  SET_HORIZONTAL_DYNAMIC_HTML_SIZE = '@@interface/SET_HORIZONTAL_DYNAMIC_HTML_SIZE',
  SET_VERTICAL_ACTIONS_DYNAMIC_HTML_SIZE = '@@interface/SET_VERTICAL_ACTIONS_DYNAMIC_HTML_SIZE',
  SET_HORIZONTAL_ACTIONS_DYNAMIC_HTML_SIZE = '@@interface/SET_HORIZONTAL_ACTIONS_DYNAMIC_HTML_SIZE',
  SET_OPEN_DEFAULT_INTERFACE_FROM_TOPBAR = '@@interface/SET_OPEN_DEFAULT_INTERFACE_FROM_TOPBAR',
  SET_EDITING_INTERFACE = '@@interface/SET_EDITING_INTERFACE',
  SET_SCROLL_ROW_TABLE = '@@interface/SET_SCROLL_ROW_TABLE',
  SET_SCROLL_COLUMN_TABLE = '@@interface/SET_SCROLL_COLUMN_TABLE',
  LAST_COMPONENT_DRILLDOWNED = '@@interface/LAST_COMPONENT_DRILLDOWNED',
  SET_ACTIVE_TAB = '@@interface/SET_ACTIVE_TAB',
  REMOVE_ACTIVE_TAB = '@@interface/REMOVE_ACTIVE_TAB',
  UPDATE_ACTIVE_TAB = '@@interface/UPDATE_ACTIVE_TAB',
  REMOVE_ACTIVES_TABS = '@@interface/REMOVE_ACTIVES_TABS',
  SET_FILTERED_COMPONENT_VALUE = '@@interface/SET_FILTERED_COMPONENT_VALUE',
  SET_SELECTED_OPTION_STATE = '@@interface/SET_SELECTED_OPTION_STATE',
  CALL_DEFAULT_LAYOUT = '@@interface/CALL_DEFAULT_LAYOUT',
  LOADING_TAB_MENU = '@@interface/LOADING_TAB_MENU',
  SELECTOR_OPTIONS_MAX = '@@interface/SELECTOR_OPTIONS_MAX',
  SET_APPLICATION_COMPONENT = '@@interface/SET_APPLICATION_COMPONENT',
  UPDATE_APPLICATION_COMPONENT = '@@interface/UPDATE_APPLICATION_COMPONENT',
  SET_RELOAD_LAYOUT = '@@interface/SET_RELOAD_LAYOUT',
  REMOVE_APPLICATION_COMPONENTS = '@@interface/REMOVE_APPLICATION_COMPONENTS',
  SET_TAB_CHANGED = '@@interface/SET_TAB_CHANGED',
  SET_FILTER_COMPONENT_STATE = '@@interface/SET_FILTER_COMPONENT_STATE',
  ADD_FILTER_COMPONENT_STATE = '@@interface/ADD_FILTER_COMPONENT_STATE',
  REMOVE_FILTER_COMPONENT_STATE = '@@interface/REMOVE_FILTER_COMPONENT_STATE',
  SET_FILTER_COMPONENT_IS_LOADING = '@@interface/SET_FILTER_COMPONENT_IS_LOADING',
  UPDATE_FILTER_COMPONENT_STATE_RESULT = '@@interface/UPDATE_FILTER_COMPONENT_STATE_RESULT',
  ORDER_FILTER_COMPONENT_STATE = '@@interface/ORDER_FILTER_COMPONENT_STATE',
  SET_FILTER_COMPONENT_DIALOG_OPEN = '@@interface/SET_FILTER_COMPONENT_DIALOG_OPEN',
  REMOVE_APPLICATION_COMPONENT = '@@interface/REMOVE_APPLICATION_COMPONENT',
  SET_OPEN_FILTER_COMPONENT_DIALOG = '@@interface/SET_OPEN_FILTER_COMPONENT_DIALOG'
}

export interface InterfaceState {
  readonly selectedComponent?: GenericComponent
  readonly indexFilter?: IndexFilterInfo
  readonly drilldownOptions?: DrilldownOptionsInfo
  readonly defaultInterfaceLayout?: GridLayout
  readonly interfaceWidgetExpanded?: { componentId: string | undefined }
  readonly componentToEdit?: { componentId?: string; interfaceId?: string }
  readonly componentsLoading?: string[]
  readonly filterComponentsLoading?: string[]
  readonly stylesToCopy?: TableStyle | ChartStyle | IndicatorStyle
  readonly recentlyUsedColors?: string[]
  readonly openDefaultInterfaceFromTopbar?: boolean
  tabs: TabItem[]
  activeTabs?: ActiveTab[]
  applicationComponents?: ApplicationComponents
  filteredComponentValue?: FilteredComponentValue
  filterComponentState?: FilterComponent[]
  filterComponentIds?: string[]
  selectedOptionState?: SelectedState
  list: Interface[]
  menuFolderHistory?: { [key: string]: { folders: string[]; currentFolder: string } }
  accordionMenu?: { id: string; isOpen: boolean }[]
  interfaceOpenedFromManager?: string
  interfaceOpenedFromCreation?: string
  interfaceWithChanges?: { id: string }
  clickedFromTopbar?: boolean
  openInterfaceCurrentTab?: OpenInterfaceCurrentTab
  changeDefaultInterface?: boolean
  reloadLayout: number
  tabChanged: boolean
  openFilterComponentDialog?: boolean
  readonly selectorOptionsMax?: SelectorOptionsMax[]
  readonly refreshCurrentInterface?: boolean
  readonly externalLayout?: boolean
  readonly interfaceFromLink?: string
  readonly externalInterfaceId?: string
  readonly lastAccessedPath?: string
  readonly currentNavigationHistory?: NavigationHistory[]
  readonly componentDynamicHtml?: IDynamicHTML
  readonly verticalDynamicHtmlSize?: number
  readonly horizontalDynamicHtmlSize?: number
  readonly verticalActionsDynamicHtmlSize?: number
  readonly horizontalActionsDynamicHtmlSize?: number
  readonly editingInterface?: boolean
  readonly scrollRowTable?: number
  readonly scrollColumnTable?: number
  readonly lastComponentDrilldowned?: string
  readonly loadingTabMenu?: boolean
  readonly filterComponentDialogOpen?: string
  readonly positionFilterSticky?: boolean
}
