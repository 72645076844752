/** 5MB common chunk size  */
export const DEFAULT_CHUNK_SIZE = 5242880

export const ROWS_PER_PAGE = 5

export const TIMEOUT_MS = 1000

export const SHORT_TIMEOUT_MS = 500

export const TOOLTIP_DELAY = 500

export const MAXIMUM_FRACTION_DIGITS = 14

export const SNAPGRID = 10

export const HANDLE_DATA_WIZARD = '_handle_data_wizard_'

export const CODE_NODE_PROPERTIES_TO_GET = [
  { name: 'identifier' },
  { name: 'title' },
  { name: 'nodeClass' },
  { name: 'definition' },
  { name: 'isCalc' },
  { name: 'lastEvaluationConsole' },
  { name: 'lastEvaluationError' },
  { name: 'units' },
  { name: 'prefixIdentifier' },
  { name: 'hierarchy' },
  { name: 'resultType' }
]
