import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useHotkeys } from 'react-hotkeys-hook'
import { Key } from 'ts-key-enum'

import { Box, Typography, Link as MuiLink } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { ApplicationState } from 'store'
import { Notification, removeSnackbar } from 'store/notifier'

let displayed: string[] = []

const useStyles = makeStyles((theme) => ({
  notifier: {
    maxHeight: '30vh',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100vw',
    paddingLeft: '1rem !important',
    '& #notistack-snackbar': {
      maxHeight: '30vh',
      width: '100%'
    }
  },
  message: {
    maxHeight: '10vh',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line'
  },
  contentWrapper: {
    maxHeight: '20vh',
    overflow: 'auto'
  },
  readMore: {
    marginLeft: '.5rem',
    cursor: 'pointer'
  }
}))

const WithTitle: FC<{
  title: string
  message: string
  contentWrapper: string
  messageStyles: string
  readMoreStyles: string
  readMoreMessage: string
}> = ({ title, message, contentWrapper, messageStyles, readMoreStyles, readMoreMessage }) => {
  const [readMore, setReadMore] = useState(false)
  const handleReadMore = () => {
    setReadMore(true)
  }
  return (
    <Box className={contentWrapper}>
      {title.length > 0 && <Typography variant='subtitle1'>{title}</Typography>}
      <Typography variant='caption' className={messageStyles}>
        {message.length > 850 && !readMore ? `${message.slice(0, 850)}...` : message}
      </Typography>
      {message.length > 850 && !readMore && (
        <MuiLink onClick={() => handleReadMore()} underline='hover' className={readMoreStyles}>
          {readMoreMessage}
        </MuiLink>
      )}
    </Box>
  )
}
const Notifier = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const classes = useStyles()

  const notifications: Notification[] = useSelector(
    (store: ApplicationState) => store.notifier.notifications || []
  )
  useHotkeys(`shift+${Key.Delete},shift+${Key.Backspace}`, () => {
    closeSnackbar()
  })
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id]
  }
  const removeDisplayed = (id: string | number) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }
  useEffect(() => {
    notifications.forEach(({ key, title, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return

      const translatedMessage = message
        ? intl.formatMessage({ id: message, defaultMessage: message })
        : ''

      // display snackbar using notistack
      enqueueSnackbar(
        title ? (
          <WithTitle
            title={intl.formatMessage({ id: title, defaultMessage: title })}
            message={translatedMessage}
            contentWrapper={classes.contentWrapper}
            messageStyles={classes.message}
            readMoreStyles={classes.readMore}
            readMoreMessage={intl.formatMessage({ id: 'common.notifier.readMore' })}
          />
        ) : (
          <WithTitle
            title={''}
            message={translatedMessage}
            contentWrapper={classes.contentWrapper}
            messageStyles={classes.message}
            readMoreStyles={classes.readMore}
            readMoreMessage={intl.formatMessage({ id: 'common.notifier.readMore' })}
          />
        ),
        {
          key,
          ...options,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          className: classes.notifier,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey)
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey))
            removeDisplayed(myKey)
          }
        }
      )

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [
    notifications,
    closeSnackbar,
    enqueueSnackbar,
    dispatch,
    intl,
    classes.notifier,
    classes.contentWrapper,
    classes.message,
    classes.readMore
  ])

  return null
}

export default Notifier
