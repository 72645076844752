import { ComponentTypes } from './components/types'
import { GridLayout } from './containers/types'

export const getGridItemSizeByComponentType = (componentType: ComponentTypes) => {
  switch (componentType) {
    case ComponentTypes.Core_Index:
    case ComponentTypes.Core_Selector:
    case ComponentTypes.Core_Choice:
    case ComponentTypes.Core_InputValue:
      return {
        w: 4,
        h: 1
      }
    case ComponentTypes.Core_Button:
    case ComponentTypes.Core_Indicator:
      return {
        w: 2,
        h: 1
      }
    case ComponentTypes.Core_Menu:
    case ComponentTypes.Core_MyTasks:
    case ComponentTypes.Core_ScheduledTasks:
      return {
        w: 12,
        h: 8
      }
    case ComponentTypes.Core_Assistant:
      return {
        w: 4,
        h: 8
      }
    case ComponentTypes.Core_Filter:
      return {
        w: 12,
        h: 1
      }
    default:
      return {
        w: 6,
        h: 3
      }
  }
}

export const getFontSizeInPxFromString = (fontSize: unknown) => {
  const fontSizeString = String(fontSize)
  if (fontSizeString.endsWith('px')) {
    const fontSizeInPx = fontSizeString.replace('px', '')
    return Number(fontSizeInPx)
  } else if (fontSizeString.endsWith('rem')) {
    const fontSizeInRem = fontSizeString.replace('rem', '')
    return Number(fontSizeInRem) * 16
  }
  return undefined
}

export const changeStylesFromDashCaseToCamelCase = (styles: any) => {
  const newStyles: any = {}
  Object.keys(styles).forEach((key) => {
    const newKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
    newStyles[newKey] = styles[key]
  })
  return newStyles
}

export const componentWithoutUnits = [
  ComponentTypes.Core_Button,
  ComponentTypes.Core_Generic,
  ComponentTypes.Core_Html,
  ComponentTypes.Core_Menu
]

export const sanitizeSelectorBooleanValue = (value: string) => {
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
}

export const reverseSelectorBooleanValue = (value: boolean | undefined) => {
  if (value === true) {
    return 'true'
  }
  if (value === false) {
    return 'false'
  }
  return ''
}

export const defaultLayout: GridLayout = {
  properties: {
    grid: {
      compactType: undefined,
      rowHeight: 50,
      breakpoints: { lg: 800, xxs: 0 }, //If user defines custom breakpoints throug interface code, cols and layout object should be updated with the same breakpoints
      cols: { lg: 12, xxs: 1 },
      layouts: { lg: [], xxs: [] },
      autoSize: false
    },
    name: ''
  },
  components: []
}

export const haveRenderKeysChanged = (prevComponents: any, nextComponents: any) => {
  if (prevComponents.length !== nextComponents.length) {
    return true
  }
  for (let i = 0; i < prevComponents.length; i++) {
    if (prevComponents[i].renderKey !== nextComponents[i].renderKey) {
      return true
    }
  }
  return false
}
