import { Location, NavigateFunction } from 'react-router-dom'

import { AppInstanceInfo, AppProperties } from 'common/types/application'
import {
  AppVersionItem,
  CreateAppVersionParams,
  SaveAppInNewVersionParams,
  UpdateAppVersionParams
} from 'common/types/version'
import { AppActionTypes, OpenAppParams } from './types'
import { DataToCompare } from 'pages/appConsolidation/types'

export const openApp = (params: OpenAppParams) => {
  return {
    type: AppActionTypes.OPEN_APP,
    payload: {
      fullPath: params.fullPath,
      navigate: params.navigate,
      openAsReadOnly: params.openAsReadOnly === undefined ? false : params.openAsReadOnly,
      version: params.version
    }
  }
}

export const openAppOnNewInstance = (params: OpenAppParams) => {
  return {
    type: AppActionTypes.OPEN_APP_ON_NEW_INSTANCE,
    payload: {
      fullPath: params.fullPath,
      navigate: params.navigate,
      openAsReadOnly: params.openAsReadOnly === undefined ? false : params.openAsReadOnly,
      version: params.version
    }
  }
}

export const createVersionFromClosedApp = (
  navigate: NavigateFunction,
  folder: string,
  dataToSend: CreateAppVersionParams
) => {
  return {
    type: AppActionTypes.CREATE_VERSION_FROM_CLOSED_APP,
    payload: {
      navigate,
      folder,
      dataToSend
    }
  }
}

export const closeApp = (navigate: NavigateFunction, successMsg: string) => {
  return {
    type: AppActionTypes.CLOSE_APP,
    payload: { navigate, successMsg }
  }
}

export const setOpenApp = (applicationInfo?: AppInstanceInfo) => {
  return {
    type: AppActionTypes.SET_OPEN_APP,
    payload: applicationInfo
  }
}

export const resetAppInfo = () => {
  return {
    type: AppActionTypes.RESET_APP_INFO
  }
}

export const saveApp = (
  confirmNodeDefinitions: boolean,
  successMsg: string,
  openApp?: { open: boolean; openInNewInstance: boolean; modelPath: string }
) => {
  return {
    type: AppActionTypes.SAVE_APP,
    payload: { confirmNodeDefinitions, successMsg, openApp }
  }
}
export const setOpenAppAfterSave = (value?: {
  open: boolean
  openInNewInstance: boolean
  modelPath: string
}) => {
  return {
    type: AppActionTypes.SET_OPEN_APP_AFTER_SAVE,
    payload: value
  }
}
export const saveAppInMyWorkspace = (
  confirmNodeDefinitions: boolean,
  successMsg: string,
  newPathMsg: string,
  folderName: string,
  versions?: string[],
  navigate?: NavigateFunction
) => {
  return {
    type: AppActionTypes.SAVE_APP_IN_MY_WORKSPACE,
    payload: { confirmNodeDefinitions, successMsg, newPathMsg, folderName, versions, navigate }
  }
}

export const saveAppAsNewVersion = (
  data: SaveAppInNewVersionParams,
  navigate?: NavigateFunction,
  preventNavigate?: boolean,
  getConsolidationDifferenceData?: object
) => {
  return {
    type: AppActionTypes.SAVE_APP_AS_NEW_VERSION,
    payload: {
      new_version_name: data.new_version_name,
      labels: data.labels,
      set_as_default: data.set_as_default,
      version_description: data.version_description,
      navigate,
      preventNavigate,
      getConsolidationDifferenceData
    }
  }
}

export const setAppConsolidationDifferences = (data?: DataToCompare) => {
  return {
    type: AppActionTypes.GET_APP_CONSOLIDATION_DIFFERENCES,
    payload: data
  }
}

export const setSavingApp = (saving: boolean) => {
  return {
    type: AppActionTypes.SET_SAVING_APP,
    payload: saving
  }
}

export const setAppHasUnsavedChanges = (hasUnsavedChanges: boolean) => {
  return {
    type: AppActionTypes.SET_APP_HAS_UNSAVED_CHANGES,
    payload: hasUnsavedChanges
  }
}

export const showCreateAppDialog = (show: boolean) => {
  return {
    type: AppActionTypes.SHOW_CREATE_APP_DIALOG,
    payload: show
  }
}

export const createApp = (
  name: string,
  template: string,
  onPublic: boolean,
  navigate: NavigateFunction
) => {
  return {
    type: AppActionTypes.CREATE_APP,
    payload: { name, template, onPublic, navigate }
  }
}

export const setAppInfo = (appInfo: AppInstanceInfo | undefined) => {
  return {
    type: AppActionTypes.SET_APP_INFO,
    payload: appInfo
  }
}

export const updateDefaultInterfaceId = (interfaceId: string) => {
  return {
    type: AppActionTypes.UPDATE_DEFAULT_INTERFACE_ID,
    payload: interfaceId
  }
}

export const setAppReloaded = (value?: boolean) => {
  return {
    type: AppActionTypes.APP_RELOADED,
    payload: value
  }
}

export const reloadWithMyVenv = () => {
  return {
    type: AppActionTypes.RELOAD_WITH_MY_VENV
  }
}

export const downloadExample = (name: string, navigate: NavigateFunction) => {
  return {
    type: AppActionTypes.DOWNLOAD_EXAMPLE,
    payload: { name, navigate }
  }
}

export const changeAppVersion = (version: string, navigate: NavigateFunction) => {
  return {
    type: AppActionTypes.CHANGE_APP_VERSION,
    payload: {
      version,
      navigate
    }
  }
}

export const updateAppVersionProperties = (
  version: string,
  newProperties: UpdateAppVersionParams,
  navigate: NavigateFunction,
  location: Location
) => {
  return {
    type: AppActionTypes.UPDATE_APP_VERSION_PROPERTIES,
    payload: {
      version,
      newProperties,
      navigate,
      location
    }
  }
}

export const setChangingAppVersion = (changingAppVersion: boolean) => {
  return {
    type: AppActionTypes.SET_CHANGING_APP_VERSION,
    payload: changingAppVersion
  }
}
export const setCreatingAppVersion = (creatingAppVersion: boolean) => {
  return {
    type: AppActionTypes.SET_CREATING_APP_VERSION,
    payload: creatingAppVersion
  }
}
export const setHasProcessDelayed = (value?: boolean) => {
  return {
    type: AppActionTypes.HAS_PROCESS_DELAYED,
    payload: value
  }
}
export const setAppVersionsDialog = (
  showAppVersionsDialog: boolean,
  folder?: string,
  defaultVersion?: AppVersionItem,
  openAsReadOnly?: boolean,
  openOnNewInstance?: boolean,
  appProperties?: AppProperties,
  canWriteInDir?: boolean
) => {
  return {
    type: AppActionTypes.SHOW_APP_VERSIONS_DIALOG,
    payload: {
      show: showAppVersionsDialog,
      folder,
      defaultVersion,
      appProperties,
      openAsReadOnly: openAsReadOnly === undefined ? false : openAsReadOnly,
      openOnNewInstance: openOnNewInstance === undefined ? false : openOnNewInstance,
      canWriteInDir
    }
  }
}
export const refreshScenariosTable = () => {
  return {
    type: AppActionTypes.REFRESH_SCENARIOS_TABLE
  }
}

export const addBroadcastChannel = (channelId: string) => {
  return {
    type: AppActionTypes.ADD_BROADCAST_CHANNEL,
    payload: channelId
  }
}

export const removeBroadcastChannel = () => {
  return {
    type: AppActionTypes.REMOVE_BROADCAST_CHANNEL
  }
}
export const setShowUnreadNotifications = (value?: boolean) => {
  return {
    type: AppActionTypes.SHOW_UNREAD_NOTIFICATIONS,
    payload: value
  }
}
export const setSavingAppWithInterfaceEditMode = (value?: boolean) => {
  return {
    type: AppActionTypes.SET_SAVING_APP_WITH_INTERFACE_EDIT_MODE,
    payload: value
  }
}
export const setOpenSaveAppDialogFromInterface = (value?: boolean) => {
  return {
    type: AppActionTypes.SET_OPEN_SAVE_APP_DIALOG_FROM_INTERFACE,
    payload: value
  }
}
export const setWaiting = (value?: boolean) => {
  return {
    type: AppActionTypes.SET_WAITING,
    payload: value
  }
}
export const closeAppFromOtherTab = (navigate: NavigateFunction) => {
  return {
    type: AppActionTypes.CLOSE_APP_FROM_OTHER_TAB,
    payload: { navigate }
  }
}
