import { ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    secondaryBackground: Palette['primary']
    altBackground: Palette['primary']
    primaryText: Palette['primary']
    secondaryText: Palette['primary']
    altText: Palette['primary']
    instanceManagerMarker: Palette['primary']
    shadows: Palette['primary']
    secondaryShadow: Palette['primary']
    folderIcon: Palette['primary']
    fileIcon: Palette['primary']
    black: Palette['primary']
    white: Palette['primary']
    nodeText: Palette['primary']
    basicNodeSelectedBg: Palette['primary']
    dataReadingSelectedBg: Palette['primary']
    outputSelectedBg: Palette['primary']
    reportSelectedBg: Palette['primary']
    indexSelectedBg: Palette['primary']
    inputDataArraySelectedBg: Palette['primary']
    moduleSelectedBg: Palette['primary']
    inputDataArrayIcon: Palette['primary']
    indexHierarchyIcon: Palette['primary']
    tableHeader: Palette['primary']
    tableData: Palette['primary']
    tableDataDimmed: Palette['primary']
    tableEditableData: Palette['primary']
    tableEditableDataDimmed: Palette['primary']
    widgetBackground: Palette['primary']
    altWidgetBackground: Palette['primary']
    taskStatusWarning: Palette['primary']
    taskStatusSuccess: Palette['primary']
    toggleButtonBg: Palette['primary']
    folderBg: Palette['primary']
    hightLightOption: Palette['primary']
    resizerDivider: Palette['primary']
    backgroundPlaceholder: Palette['primary']
    zipIcon: Palette['primary']
    dbIcon: Palette['primary']
    csvIcon: Palette['primary']
    jsonIcon: Palette['primary']
    templateIcon: Palette['primary']
    oldIcon: Palette['primary']
    defaultIcon: Palette['primary']
    disabledAction: Palette['primary']
    primaryOnly: Palette['primary']
    darkGrey: Palette['primary']
    leftChat: Palette['primary']
    rightChat: Palette['primary']
    tabHoverColor: Palette['primary']
    variableNode: Palette['primary']
    readDataNode: Palette['primary']
    inputNode: Palette['primary']
    userTitle: Palette['primary']
    lightGrey: Palette['primary']
    widgetDivider: Palette['primary']
    whiteBlackContrast: Palette['primary']
    companyCard: Palette['primary']
    tdAutocompleteArrow: Palette['primary']
    defaultTableHeaderColor: Palette['primary']
    defaultTableHeaderBackgroundColor: Palette['primary']
    defaultHeaderBackgroundColor: Palette['primary']
    defaultHeaderColor: Palette['primary']
    badgeColor: Palette['primary']
    homeText: Palette['primary']
    homeBox: Palette['primary']
    notificationTitle: Palette['primary']
    appCardShadow: Palette['primary']
    homeMainBox: Palette['primary']
    textFieldBorder: Palette['primary']
    filterComponent: Palette['primary']
  }
  interface PaletteOptions {
    secondaryBackground: PaletteOptions['primary']
    altBackground: PaletteOptions['primary']
    primaryText: PaletteOptions['primary']
    secondaryText: PaletteOptions['primary']
    altText: PaletteOptions['primary']
    shadows: PaletteOptions['primary']
    instanceManagerMarker: PaletteOptions['primary']
    secondaryShadow: PaletteOptions['primary']
    folderIcon: PaletteOptions['primary']
    fileIcon: PaletteOptions['primary']
    black: PaletteOptions['primary']
    white: PaletteOptions['primary']
    nodeText: PaletteOptions['primary']
    basicNodeSelectedBg: PaletteOptions['primary']
    dataReadingSelectedBg: PaletteOptions['primary']
    outputSelectedBg: PaletteOptions['primary']
    reportSelectedBg: PaletteOptions['primary']
    indexSelectedBg: PaletteOptions['primary']
    inputDataArraySelectedBg: PaletteOptions['primary']
    inputDataArrayIcon: PaletteOptions['primary']
    indexHierarchyIcon: PaletteOptions['primary']
    moduleSelectedBg: PaletteOptions['primary']
    tableHeader: PaletteOptions['primary']
    tableData: PaletteOptions['primary']
    tableDataDimmed: PaletteOptions['primary']
    tableEditableData: PaletteOptions['primary']
    tableEditableDataDimmed: PaletteOptions['primary']
    widgetBackground: PaletteOptions['primary']
    altWidgetBackground: PaletteOptions['primary']
    taskStatusWarning: PaletteOptions['primary']
    taskStatusSuccess: PaletteOptions['primary']
    toggleButtonBg: PaletteOptions['primary']
    folderBg: PaletteOptions['primary']
    hightLightOption: PaletteOptions['primary']
    resizerDivider: PaletteOptions['primary']
    backgroundPlaceholder: PaletteOptions['primary']
    zipIcon: PaletteOptions['primary']
    dbIcon: PaletteOptions['primary']
    csvIcon: PaletteOptions['primary']
    jsonIcon: PaletteOptions['primary']
    templateIcon: PaletteOptions['primary']
    oldIcon: PaletteOptions['primary']
    defaultIcon: PaletteOptions['primary']
    disabledAction: PaletteOptions['primary']
    primaryOnly: PaletteOptions['primary']
    darkGrey: PaletteOptions['primary']
    leftChat: PaletteOptions['primary']
    rightChat: PaletteOptions['primary']
    tabHoverColor: PaletteOptions['primary']
    variableNode: PaletteOptions['primary']
    readDataNode: PaletteOptions['primary']
    inputNode: PaletteOptions['primary']
    userTitle: PaletteOptions['primary']
    lightGrey: PaletteOptions['primary']
    widgetDivider: PaletteOptions['primary']
    whiteBlackContrast: PaletteOptions['primary']
    companyCard: PaletteOptions['primary']
    tdAutocompleteArrow: PaletteOptions['primary']
    defaultTableHeaderColor: PaletteOptions['primary']
    defaultHeaderColor: PaletteOptions['primary']
    defaultTableHeaderBackgroundColor: PaletteOptions['primary']
    defaultHeaderBackgroundColor: PaletteOptions['primary']
    badgeColor: PaletteOptions['primary']
    homeText: PaletteOptions['primary']
    homeBox: PaletteOptions['primary']
    notificationTitle: PaletteOptions['primary']
    appCardShadow: PaletteOptions['primary']
    homeMainBox: PaletteOptions['primary']
    textFieldBorder: PaletteOptions['primary']
    filterComponent: PaletteOptions['primary']
  }
}

const lightTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#368EE0'
    },
    tabHoverColor: {
      main: '#014F98'
    },
    defaultHeaderBackgroundColor: {
      main: '#fff'
    },
    defaultTableHeaderBackgroundColor: {
      main: '#25639C'
    },
    appCardShadow: {
      main:
        ' 0 3px 1px -2px rgba(181, 181, 181, 0.2), 0 1px 5px 0 rgba(73, 73, 73, 0.15), 0 2px 2px 0 rgba(181, 181, 181, 0.14)'
    },
    homeText: {
      main: '#3C3A3A'
    },
    defaultTableHeaderColor: {
      main: '#fff'
    },
    homeBox: {
      main: '#F5F5F5'
    },
    homeMainBox: {
      main: '#FFFFFF'
    },
    textFieldBorder: {
      main: 'rgba(0, 0, 0, 0.25)'
    },
    notificationTitle: {
      main: '#676767'
    },
    defaultHeaderColor: {
      main: '#014F98'
    },
    tdAutocompleteArrow: {
      main: '#BDBDBD'
    },
    badgeColor: {
      main: '#66a4de'
    },
    userTitle: {
      main: '#368EE0'
    },
    companyCard: {
      main: '#fff'
    },
    lightGrey: {
      main: '#AAAAAA'
    },
    primaryOnly: {
      main: '#368EE0'
    },
    darkGrey: {
      main: 'rgba(0,0,0,0.5)'
    },
    disabledAction: {
      main: 'rgba(138,151,152,0.33)'
    },
    toggleButtonBg: {
      main: '#014F98'
    },
    resizerDivider: {
      main: '#000000'
    },
    hightLightOption: {
      main: 'rgba(0, 0, 0, 0.08)'
    },
    folderBg: {
      main: '#747474'
    },
    primaryText: {
      main: '#014F98',
      light: '#ededed',
      dark: 'rgb(1 79 152)'
    },
    secondaryBackground: {
      main: '#F8FAFE',
      dark: '#edf3ff',
      light: '#014F982C'
    },
    altBackground: {
      main: '#C3E2FF',
      light: '#ededed',
      dark: '#b0cde8'
    },
    secondary: {
      main: '#C3E2FE'
    },
    taskStatusWarning: {
      main: '#f5f514',
      dark: '#57570f'
    },
    taskStatusSuccess: {
      main: '#127517',
      dark: 'rgb(10 70 13)'
    },
    black: {
      main: 'rgba(0,0,0,0.8)'
    },
    white: {
      main: '#fff'
    },
    secondaryText: {
      main: '#707070'
    },
    altText: {
      main: '#4B4B4B'
    },
    error: {
      main: '#FF0D15'
    },
    instanceManagerMarker: {
      main: '#ff7f0e'
    },
    shadows: {
      main: '#7EBCF5'
    },
    secondaryShadow: {
      main: '#00000029'
    },
    background: {
      default: '#FFF',
      paper: '#fff'
    },
    folderIcon: {
      main: '#A705BF'
    },
    fileIcon: {
      main: '#3F4CC2'
    },
    nodeText: {
      main: '#4B4B4B'
    },
    basicNodeSelectedBg: {
      main: '#DBF2FF'
    },
    dataReadingSelectedBg: {
      main: '#E5F6D5'
    },
    outputSelectedBg: {
      main: '#FFD9E6'
    },
    reportSelectedBg: {
      main: '#E2E2E2'
    },
    indexSelectedBg: {
      main: '#EBEBFF'
    },
    inputDataArraySelectedBg: {
      main: '#FFF3CC'
    },
    moduleSelectedBg: {
      main: '#D9E4FC'
    },
    inputDataArrayIcon: {
      main: '#5E4C10'
    },
    indexHierarchyIcon: {
      main: '#5E4C10'
    },
    tableHeader: {
      main: '#e6e6e6'
    },
    tableData: {
      main: '#FFFFFF',
      contrastText: '#777777'
    },
    tableDataDimmed: {
      main: 'rgba(255, 255, 255, 0.6)',
      contrastText: 'rgba(119, 119, 119, 0.6)'
    },
    tableEditableData: {
      main: '#FFF2CC',
      contrastText: '#373737'
    },
    tableEditableDataDimmed: {
      main: 'rgba(255, 242, 204, 0.6)',
      contrastText: 'rgba(55, 55, 55, 0.6)'
    },
    widgetBackground: {
      main: '#FFF'
    },
    altWidgetBackground: {
      main: '#E1F0FF',
      contrastText: '#368EE0'
    },
    backgroundPlaceholder: {
      main: '#E1F0FF'
    },
    zipIcon: {
      main: '#009984'
    },
    dbIcon: {
      main: '#F80063'
    },
    csvIcon: {
      main: '#3F4CC2'
    },
    jsonIcon: {
      main: '#BE9C14'
    },
    templateIcon: {
      main: '#25B328'
    },
    oldIcon: {
      main: '#5B7F8E'
    },
    defaultIcon: {
      main: '#FF2F18'
    },
    leftChat: {
      main: '#E1F0FF'
    },
    rightChat: {
      main: '#FFF3CC'
    },
    variableNode: {
      main: '#4CBCFF'
    },
    readDataNode: {
      main: '#7FCF2E'
    },
    inputNode: {
      main: '#FFC400'
    },
    widgetDivider: {
      main: 'rgba(0, 0, 0, 0.12)'
    },
    whiteBlackContrast: {
      main: '#000000'
    },
    filterComponent: {
      main: '#f1f5fd'
    }
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 16
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          '*::-webkit-scrollbar': {
            width: '.5rem',
            height: '.5rem'
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: '#00000029'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#808BA4',
            borderRadius: '100px'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF'
        },
        rounded: {
          borderRadius: '5px'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '48px'
        },
        regular: {
          minHeight: '48px',
          height: '48px',
          '@media (min-width: 600px)': {
            minHeight: '48px'
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        vertical: {
          width: '2px',
          height: '32px',
          margin: '0 6px',
          backgroundColor: '#C3E2FE', //may not be necessary anymore with MUIv5
          borderColor: '#C3E2FE'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          border: '1px solid #F8FAFE',
          boxShadow: '0px 0px 10px #014F98',
          borderRadius: '4px',
          color: '#014F98',
          backgroundColor: '#FFFFFF'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
        variant: 'contained'
      },
      styleOverrides: {
        outlinedSizeSmall: {
          color: '#014F98',
          backgroundColor: '#FFF',
          border: '1px solid #7EBCF5',
          borderRadius: '4px',
          height: '32px',
          padding: 8,
          textTransform: 'none',
          fontSize: '0.75rem',
          '&:hover': {
            border: '1px solid #014F98',
            backgroundColor: '#C3E2FF'
          },
          '&$disabled': {
            color: '#B1B1B1',
            backgroundColor: '#F3F3F3',
            border: '1px solid #B1B1B1'
          }
        },
        root: {
          //this was label
          textTransform: 'none'
        },
        iconSizeSmall: {
          '& > *:first-of-type': {
            fontSize: 10
          }
        },
        contained: {
          height: '32px',
          backgroundColor: '#368EE0',
          fontSize: '1.1rem',
          color: '#FFF',
          borderRadius: '4px',
          padding: 8,
          border: 'none',
          '&:hover': {
            backgroundColor: '#014F98'
          },
          '&$disabled': {
            color: '#B1B1B1',
            backgroundColor: '#F3F3F3'
          }
        },
        containedSizeSmall: {
          height: '32px',
          fontSize: '0.9rem'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#368EE0'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
        size: 'small'
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          // height: 19,
          paddingTop: '6.5px',
          paddingBottom: '6.5px',
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '0px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-4px'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          color: '#014F98'
        },
        head: {
          color: '#014F98'
        },
        stickyHeader: {
          backgroundColor: '#F8FAFE'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'none'
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          textDecoration: 'underline',
          textUnderlineOffset: '3px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&:has(> input:-webkit-autofill)': {
            backgroundColor: '#e8f0fe',
            '& input': {
              borderRadius: 'unset'
            }
          }
        }
      }
    }
  }
}

export default lightTheme
