import { Reducer } from 'redux'
import { AssistantBotKey, AssistantBotState, AssistantBotActionTypes } from './types'
import { AssistantBotAssistantType } from 'common/types/assistantBot'

export const initialState: AssistantBotState = {
  general: {
    assistantBotType: AssistantBotAssistantType.pyplan,
    assistantBotTypeQuestionAnswered: false,
    messages: [],
    excludedThreadIds: []
  },
  code: {
    assistantBotType: AssistantBotAssistantType.code,
    assistantBotTypeQuestionAnswered: false,
    messages: [],
    excludedThreadIds: []
  },
  tests: {
    assistantBotType: AssistantBotAssistantType.application,
    assistantBotTypeQuestionAnswered: false,
    messages: [],
    excludedThreadIds: []
  }
}

const reducer: Reducer<AssistantBotState> = (
  state: AssistantBotState = initialState,
  action: { type: AssistantBotActionTypes; payload?: any }
) => {
  const assistantBotKey: AssistantBotKey =
    action?.payload?.assistantBotKey || AssistantBotKey.general
  const assistantBotKeyState = state[assistantBotKey]
  switch (action.type) {
    case AssistantBotActionTypes.APPEND_MESSAGE: {
      return {
        ...state,
        [assistantBotKey]: {
          ...assistantBotKeyState,
          messages: [...assistantBotKeyState.messages, action.payload.message]
        }
      }
    }
    case AssistantBotActionTypes.UPDATE_MESSAGES: {
      return {
        ...state,
        [assistantBotKey]: { ...assistantBotKeyState, messages: action.payload.messages }
      }
    }

    case AssistantBotActionTypes.SHOW: {
      return {
        ...state,
        [assistantBotKey]: { ...assistantBotKeyState, show: action.payload.show }
      }
    }
    case AssistantBotActionTypes.CLEAR_MESSAGE: {
      return { ...state, [assistantBotKey]: { ...assistantBotKeyState, messages: [] } }
    }
    case AssistantBotActionTypes.SET_POSITION: {
      return {
        ...state,
        [assistantBotKey]: { ...assistantBotKeyState, position: action.payload.position }
      }
    }
    case AssistantBotActionTypes.SET_SIZE: {
      return { ...state, [assistantBotKey]: { ...assistantBotKeyState, size: action.payload.size } }
    }
    case AssistantBotActionTypes.SET_FLOATING: {
      return {
        ...state,
        [assistantBotKey]: { ...assistantBotKeyState, isFloating: action.payload.isFloating }
      }
    }
    case AssistantBotActionTypes.LISTEN_BOT_MESSAGES: {
      return {
        ...state,
        [assistantBotKey]: {
          ...assistantBotKeyState,
          listenBotMessages: action.payload.listenBotMessages
        }
      }
    }
    case AssistantBotActionTypes.SET_EXCLUDED_THREAD_ID: {
      return {
        ...state,
        [assistantBotKey]: {
          ...assistantBotKeyState,
          excludedThreadIds: [
            ...assistantBotKeyState.excludedThreadIds,
            action.payload.excludedThreadId
          ]
        }
      }
    }
    case AssistantBotActionTypes.CLEAR_EXCLUDED_THREAD_ID: {
      return { ...state, [assistantBotKey]: { ...assistantBotKeyState, excludedThreadIds: [] } }
    }
    case AssistantBotActionTypes.SET_ASSISTANT_BOT_TYPE: {
      return {
        ...state,
        [assistantBotKey]: {
          ...assistantBotKeyState,
          assistantBotType: action.payload.assistantBotType,
          assistantBotId: action.payload.assistantBotId,
          assistantBotEngineType: action.payload.assistantBotEngineType
        }
      }
    }
    case AssistantBotActionTypes.SET_ASSISTANT_BOT_TYPE_QUESTION_ANSWERED: {
      return {
        ...state,
        [assistantBotKey]: {
          ...assistantBotKeyState,
          assistantBotTypeQuestionAnswered: action.payload.answered
        }
      }
    }
    case AssistantBotActionTypes.SET_APPLICATION_ASSISTANT_BOT: {
      return {
        ...state,
        startApplicationAssistantBot: action.payload
      }
    }

    default: {
      return state
    }
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as assistantBotReducer }
