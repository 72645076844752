import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { SnackbarProvider } from 'notistack'
/*
 * Bug in material-ui with createMuiTheme, it will be fixed in v5
 * https://github.com/mui-org/material-ui/blob/next/CHANGELOG.md
 * Link issue: https://github.com/mui-org/material-ui/issues/13394
 */
import { ThemeProvider, Theme, StyledEngineProvider, createTheme, CssBaseline } from '@mui/material'

import Routes from 'common/router/routes'
import { LOCALE } from 'common/api/storageConsts'
import messages from 'common/lang'
import { light, dark } from 'common/theme'
import ErrorBoundary from 'common/components/ErrorBoundary'
import SuspenseWrapper from 'common/components/SuspenseWrapper'
import Notifier from 'pages/app/components/Notifier'
import { ApplicationState } from 'store'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Any additional component props go here.
interface MainProps {}
const languages = ['en-US', 'es-AR', 'pt-BR']

// Create an intersection type of the component props and our Redux props.
const Main: FC<MainProps> = () => {
  const { theme } = useSelector((state: ApplicationState) => state.layout)
  const instanceData = useSelector((state: ApplicationState) => state.user.instanceData)
  let languageMatched = languages.find((lang) => lang.includes(navigator.language.substring(0, 2)))
  if (languageMatched === undefined) {
    languageMatched = 'en-US'
  }
  const locale = (localStorage.getItem(LOCALE) as keyof typeof messages) || languageMatched
  const usedTheme = useMemo(() => createTheme(theme === 'dark' ? dark : light), [theme])
  const language = instanceData && (instanceData.language as keyof typeof messages)
  return (
    <IntlProvider
      messages={messages[language || locale]}
      locale={locale}
      defaultLocale='en-US'
      onError={() => {}}
    >
      <Router>
        <SuspenseWrapper>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={usedTheme}>
              <ErrorBoundary centerContent>
                <SnackbarProvider maxSnack={10}>
                  <CssBaseline />
                  <Notifier />
                  <Routes />
                </SnackbarProvider>
              </ErrorBoundary>
            </ThemeProvider>
          </StyledEngineProvider>
        </SuspenseWrapper>
      </Router>
    </IntlProvider>
  )
}

// Normally you wouldn't need any generics here (since types infer from the passed functions).
// But since we pass some props from the `index.js` file, we have to include them.
// For an example of a `connect` function without generics, see `./containers/LayoutContainer`.
export default Main
