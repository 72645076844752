import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { ResponsiveProps } from 'react-grid-layout'
import { SetSelectorValueParams } from '../../../common/types/model'
import {
  actionCallback,
  IBaseComponent,
  GetOptionsAndIndexMethod,
  ISelectorComponent,
  GenericComponent,
  IMenu,
  IMinBaseComponent
} from '../components/types'

export enum InterfaceEventType {
  ChoiceSelect = 'ChoiceSelectEvent',
  ColumnResize = 'ColumnResizeEvent',
  CreateComponent = 'CreateComponentEvent',
  ChangeComponent = 'ChangeComponentEvent',
  Drilldown = 'DrilldownEvent',
  Filter = 'FilterEvent',
  Hover = 'HoverEvent',
  InitialUpdate = 'InitialUpdateEvent',
  Paginate = 'PaginateEvent',
  Pivot = 'PivotEvent',
  Relayout = 'RelayoutEvent',
  RemoveComponent = 'RemoveComponentEvent',
  Selected = 'SelectedEvent',
  Select = 'SelectEvent',
  SetInputValue = 'SetInputValueEvent',
  Sort = 'SortEvent',
  TableAddRow = 'TableAddRowEvent',
  TableChange = 'TableChangeEvent',
  TableRemoveRow = 'TableRemoveRowEvent',
  UpdateLayout = 'UpdateLayoutEvent',
  UpdateProperties = 'UpdatePropertiesEvent',
  ButtonEvent = 'ButtonEvent',
  ShowDataHandlingAreaEvent = 'ShowDataHandlingAreaEvent',
  UpdateComponentCode = 'UpdateComponentCodeEvent',
  UpdateChartType = 'UpdateChartType',
  FormAddRowEvent = 'FormAddRowEvent',
  FormRemoveRowEvent = 'FormRemoveRowEvent',
  FormChangeEvent = 'FormChangeEvent',
  FormConfirmChangesEvent = 'FormConfirmChangesEvent',
  FormCancelChangesEvent = 'FormCancelChangesEvent',
  ApplyHierarchyEvent = 'ApplyHierarchyEvent',
  UpdateCalculatedFieldEvent = 'UpdateCalculatedFieldEvent',
  ResetViewEvent = 'ResetViewEvent',
  SelectAllEvent = 'SelectAllEvent',
  FormChangeRowsToAddEvent = 'FormChangeRowsToAddEvent',
  CompareScenariosEvent = 'CompareScenariosEvent',
  RefreshEvent = 'RefreshEvent',
  UpdateCalculatedItemEvent = 'UpdateCalculatedItemEvent',
  DynamicHtmlEvent = 'DynamicHtmlEvent',
  InputCubeChangeEvent = 'InputCubeChangeEvent',
  InputCubeConfirmChangesEvent = 'InputCubeConfirmChangesEvent',
  InputCubeCancelChangesEvent = 'InputCubeCancelChangesEvent',
  ApplySubtotalEvent = 'ApplySubtotalEvent',
  FilterComponentEvent = 'FilterComponentEvent',
  ApplyTotalEvent = 'ApplyTotalEvent'
}

export enum Context {
  Code,
  Interfaces
}

export type GridLayoutProperties = {
  grid: ResponsiveProps
  name: string
  id?: string
  code?: string
  documentation?: string
  hasDocumentation?: boolean
  icon?: IconDefinition
  hasCustomView?: boolean
  customViewActive?: boolean
}

export type GridLayout = {
  components: IMinBaseComponent[]
  properties: GridLayoutProperties
}

export interface PyplanLayout {
  [key: string]: GridLayout
}

export interface FilteredComponentValue {
  values: string[]
  componentId: string
  interfaceId: string
  indexes: string[]
}

export interface InterfaceEvent {
  event_type: InterfaceEventType
  params: any
  componentName?: string
}

export type InterfaceCode = {
  python_code: string
}

export type ComponentCode = {
  python_code: string
}

export interface ComponentProps<T = IBaseComponent> {
  component: T
  eventCallback: (triggerData: InterfaceEvent) => void
  editMode: boolean
  context?: Context
  interfaceId?: string
  widgetResultDimensions?: { width: number; height: number }
  onContentLoaded?: () => void
  showPivotToolbar?: boolean
  updateShowPivotToolbar?: () => void
  interfaceComponentsLength?: number
}

export interface MenuComponentProps extends ComponentProps<IMenu> {
  handleCloseMenuFromInterface?: () => void
  small?: boolean
}

export interface ToolbarProps<T = IBaseComponent> {
  component: T
  eventCallback: (triggerData: InterfaceEvent) => void
  actionCallback?: actionCallback
  context?: Context
  interfaceId?: string
}

export interface SelectorComponentProps extends ComponentProps<ISelectorComponent> {
  triggerCallback: (T: SetSelectorValueParams) => void
  getOptionsAndIndexMethod: GetOptionsAndIndexMethod
}

export interface IndexFilterDialogProps {
  eventCallback: (triggerData: InterfaceEvent) => void
  interfaceId?: string
  nodeId?: string
}

export interface ItemHeaderProps extends ComponentProps<GenericComponent> {
  hideTitle?: boolean
  context?: Context
  fromCustomCode?: boolean
  interfaceId?: string
  showId?: boolean
  showLoader?: boolean
  showToggleView?: boolean
  showResultToolbarIcon?: boolean
  showResultToolbar?: boolean
  showToggleScenarios?: boolean
  handleShowResultToolbar?: () => void
  nodeNotFound?: boolean
  initialIcon?: IconDefinition
  showPivotToolbar?: boolean
  updateShowPivotToolbar?: () => void
}

export interface ComponentResultWrapperProps extends ItemHeaderProps {
  children: JSX.Element
}
