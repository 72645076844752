import enUS from './enUS'
import esAR from './esAR'
import ptBR from './ptBR'

const messages = {
  'en-US': enUS,
  'es-AR': esAR,
  'pt-BR': ptBR
}

export default messages
