const messages = {
  'common.code': 'Code',
  'common.diagram': 'Diagram',
  'common.preview': 'Preview',
  'common.result': 'Result',
  'common.shortcuts': 'Shortcuts',
  'common.app': 'App',
  'common.apps': 'Apps',
  'common.confirm': 'Confirm',
  'common.close': 'Close',
  'common.cancel': 'Cancel',
  'common.run': 'Run',
  'common.stop': 'Stop',
  'common.home': 'Home',
  'common.error': 'Error',
  'common.console': 'Console',
  'common.all': 'All',
  'common.allPlural': 'All',
  'common.apply': 'Apply',
  'common.noResultsFound': 'No results found',
  'common.options': 'Options',
  'common.goToNode': 'Go to node',
  'common.top': 'Top',
  'common.bottom': 'Bottom',
  'common.left': 'Left',
  'common.right': 'Right',
  'common.default': 'Default',
  'common.custom': 'Custom',
  'common.none': 'None',
  'common.interface': 'Interface',
  'common.text': 'Text',
  'common.true': 'True',
  'common.false': 'False',
  'common.hide': 'Hide',
  'common.show': 'Show',
  'common.position': 'Position',
  'common.inside': 'Inside',
  'common.outside': 'Outside',
  'common.running': 'Running',
  'common.general': 'General',
  'common.value': 'Value',
  'common.edit': 'Edit',
  'common.username': 'Username',
  'common.password': 'Password',
  'common.invalidPassword': 'Invalid password',
  'common.required': 'Required',
  'common.copy': 'Copy',
  'common.cut': 'Cut',
  'common.pasteBelow': 'Paste below',
  'common.about': 'About',
  'common.documentation': 'Documentation',
  'common.addDocumentation': 'Add documentation',
  'common.editDocumentation': 'Edit documentation',
  'common.help': 'Help',
  'common.from': 'From',
  'common.to': 'To',
  'common.color': 'Color',
  'common.values': 'Values',
  'common.applyTo': 'Apply to',
  'common.save': 'Save',
  'common.paste': 'Paste',
  'common.total': 'Total',
  'common.totals': 'Totals',
  'common.row': 'Row',
  'common.rows': 'Rows',
  'common.column': 'Column',
  'common.columns': 'Columns',
  'common.measures': 'Measures',
  'common.labels': 'Labels',
  'common.read': 'Read',
  'common.properties': 'Properties',
  'common.selectInterface': 'Select interface',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.table': 'Table',
  'common.cube': 'Cube',
  'common.discard': 'Discard',
  'common.settings': 'Settings',
  'common.resetView': 'Reset view',
  'common.reloadComponent': 'Reload component',
  'common.fields': 'Fields',
  'common.delete': 'Delete',
  'common.add': 'Add',
  'common.information': 'Information',
  'common.openApp': 'Open app',
  'common.description': 'Description',
  'common.version': 'Version',
  'common.versions': 'Versions',
  'common.screenshots': 'Screenshots',
  'common.showInstallationLogs': 'Show installation logs',
  'common.hideInstallationLogs': 'Hide installation logs',
  'common.noResults': 'No results',
  'common.conditionFormatType': 'Condition type',
  'common.loadingAppVersion': 'Loading app version',
  'common.creatingAppVersion': 'Creating app version',
  'common.notAllowedCharacters':
    "Character not allowed. The following characters are not allowed: / \\ '",
  'common.status': 'Status',
  'common.activate': 'Activate',
  'common.archive': 'Archive',
  'common.unarchive': 'Unarchive',
  'common.invalidCharactersMessage': 'The following characters are not allowed: {characters}',
  'common.import': 'Import',
  'common.export': 'Export',
  'common.scenarios': 'Scenarios',
  'common.selectANode': 'Select a node',
  'common.deleteAll': 'Delete all',
  'common.creator': 'Creator',
  'common.scenario': 'Scenario',
  'common.validating': 'Validating',
  'common.selectedNode.incorrect': 'The selected node is incorrect. Please select another node.',
  'common.max': 'Max',
  'common.min': 'Min',
  'common.internalTests': 'Internal tests',
  'common.used': 'Used',
  'common.generalSettings': 'General settings',
  'common.clipboard': 'Clipboard',
  'common.clipboard.clear': 'Clear clipboard',
  'common.apiKeysManager': 'Api keys manager',
  'common.user': 'User',
  'common.items': 'Items',
  'common.assistant': 'Assistant',
  'common.assistants': 'Assistants',
  'common.sources': 'Sources',
  'common.appConsolidation': 'App consolidation',
  'common.changes': 'Changes',
  'common.destination': 'Destination',
  'common.new': 'New',
  'common.old': 'Old',
  'common.definition': 'Definition',
  'common.nodes': 'Nodes',
  'common.appManagement': 'App management',
  'common.modules': 'Modules',
  'common.maximize': 'Maximize',
  'common.minimize': 'Minimize',
  'common.areYouSureYouWantToProceed': 'Are you sure you want to proceed?',
  'common.load': 'Load',
  'common.colors': 'Colors',
  'common.component': 'Component',
  'common.source': 'Source',
  'common.string': 'String',
  'common.integer': 'Integer',
  'common.float': 'Float',
  'common.boolean': 'Boolean',
  'common.date': 'Date',
  'common.extraSettings': 'Extra settings',
  'common.apiKeys': 'Api keys',
  'common.applicationEnvironment': 'Application environment',
  'common.moduleRestrictions': 'Module restrictions',
  'common.workflowProcesses': 'Workflow processes',
  'common.lostMessage': 'will be lost.',
  'common.attemptingReconnectServer': 'Attempting to reconnect to the server, please wait...',
  'common.connectionReestablished': 'Connection re-established',
  'common.order': 'Order',
  'common.nodeInterface': 'Node interface',
  'common.pleaseWait': 'Please wait...',
  'common.context': 'Context',
  'common.name': 'Name',
  'common.assistantBot': 'Assistant bot',
  'common.assistantBots': 'Assistant bots',
  'common.open': 'Open',
  'common.somethingWentWrong': 'Something went wrong',
  'common.details': 'Details',
  'common.reloadPage': 'Reload page',
  'common.titleAndId': 'Title + id',
  'common.expand': 'Expand',

  copying_app_to_user_workspace: "Copying app to user's workspace",
  creating_environment: 'Creating environment',
  starting_environment: 'Starting environment',
  loading_workspace: 'Loading workspace',
  engine_created: 'Engine created',
  starting_pyplan_calc_engine: 'Starting calculation engine',
  opening_app: 'Opening App',
  installing_requirements_file_libraries: 'Installing libraries from requirements.txt file',
  no_libraries_to_install: 'No libraries to install',
  done: 'Done',
  may_take: 'This task may take up to 5 minutes. Please wait...',
  // TODO: return untranslated message from backend and use these two
  'common.checkingPythonLibrariesUsedInModel': 'Checking python libraries used in model',
  'common.librariesAlreadyInstalled': 'Libraries already installed',
  'common.resultsNotFound': 'Results not found, please search',
  need_logout: 'Logout required',
  need_logout_detail:
    'The administrator has requested to close the instance. Please save and close the instance as soon as possible',
  session_killed_detail: 'Logout required',
  session_killed: 'The administrator has closed your instance',
  instance_purged: 'Your instance has expired due to inactivity',
  instance_purged_detail: 'Close the instance',
  session_purged: 'Your session has expired due to inactivity',
  session_purged_detail: 'Login again',
  engine_down:
    'Your calculation engine has unexpectedly ended. Please close your current instance and start a new one',
  engine_down_detail: 'Close instance',
  error_installing_libs_from_requirements: 'Error installing libraries from requirements.txt file',
  error_creating_new_version: 'Error creating new version',
  error_version_name_already_exists: 'Version name chosen already exists',
  app_recovered_from_backup: 'Application recovered from backup',
  creating_assistant: 'Creating assistant',
  updating_assistant: 'Updating assistant',
  indexing_documents: 'Indexing documents',
  recovered_existing_instance: 'The application was retrieved from an existing instance.',
  app_closed_in_another_tab: 'The application was closed in another tab.',
  session_closed_in_another_tab: 'The session was closed in another tab.',

  //home
  'app.components.HomePage.header': 'Home page',
  //notifier
  'common.notifier.dismissSnackbar': 'Dismiss',
  'common.notifier.readMore': 'Read more',

  //Initial configuration
  'app.pages.initialConfig.initialSetUp': 'Initial Setup',
  'app.pages.initialConfig.internalTesting': 'Internal testing',
  'app.pages.initialConfig.finish': 'Finish',
  'app.pages.initialConfig.initialConfig': 'Please enter the company name',
  'app.pages.initialConfig.userAdminInfo': 'Enter company administrator user information',
  'app.pages.initialConfig.copyKey':
    'Copy the activation code and send it to the system administrator to generate the license code',
  'app.pages.initialConfig.finishMessage': 'Initial setup completed successfully',
  'app.pages.initialConfig.licenseSuccess': 'License successfully created',

  // Internal tests
  'app.pages.internalTests.runTests': 'Run tests',
  'app.pages.internalTests.deleteApp': 'Delete Application',
  'app.pages.internalTests.createPublicFolder': 'Create public folder',
  'app.pages.internalTests.removePublicFolder': 'Remove public folder',
  'app.pages.internalTests.systemResources': 'System resources',

  //cards
  'app.components.cards.instanceCard.openAgo': 'Open {number} mins ago',
  'app.components.cards.instanceCard.currentInstance': 'Current instance',
  'app.components.cards.instanceCard.activeAgo': 'Active {number} mins ago',
  'app.components.cards.instanceCard.deleteInstance': 'Delete instance',
  'app.components.cards.instanceCard.changeInstance':
    'Change to this instance:{br}- Name: {title}{br}- Version: {version}{br}- Path: {modelPath}',
  'app.components.cards.instanceCard.currentInstanceTooltip':
    '- Name: {title}{br}- Version: {version}{br}- Path: {modelPath}',

  //login
  'app.pages.login.init': 'Init',
  'app.pages.login.usernameLabel': 'Username',
  'app.pages.login.passwordLabel': 'Password',
  'app.pages.login.forgotPassword': 'Forgot password?',
  'app.pages.login.login': 'Log In',
  'app.pages.login.connectWithSocialMedia': 'Connect with social media',
  'app.pages.login.connectWithApp': 'Connect with {app}',
  'app.pages.login.or': 'or',
  'app.pages.login.orContinueWith': 'Or continue with:',
  'app.pages.login.SSOTo': 'SSO to {company}',
  'app.pages.login.createAnAccount': 'Create an account',
  'app.pages.login.copyright': 'Copyright © Pyplan {year}.',
  'app.pages.login.selectCompany': 'Select a company',
  'app.pages.login.licenseInfo': 'License information',
  'app.pages.login.invalidLicense': 'Invalid license',
  'app.pages.login.carousel.previous': 'Previous',
  'app.pages.login.carousel.next': 'Next',
  'app.pages.login.carousel.viewMore': 'View more',
  'app.pages.login.carousel.userPasswordExpired':
    'Your user password has expired! Please choose a new one to keep your account secure.',
  'app.pages.login.carousel.userPasswordRepeated':
    'New password must be different from current password.',
  'app.pages.login.rememberMe': 'Remember me',
  'app.pages.login.resetPasswordMessage': 'We sent you an email to reset your password',
  'app.pages.login.resetPassword': 'Reset password',
  'app.pages.login.passwordChangedSuccessfully': 'Password changed successfully',
  'app.pages.login.usernameError': 'The user {name} does not exist',
  'app.pages.login.invalidToken': 'The token is invalid',
  'app.pages.login.multiFactorAuthentication': 'Multi-factor authentication',
  'app.pages.login.scanQrCode':
    'Enhance the security of your account by downloading a Two-Factor Authentication (2FA) app, such as Google Authenticator or Microsoft Authenticator, on your mobile device. Scan the QR code below using the app to generate unique verification codes whenever you log in.',
  'app.pages.login.enterCode': 'Enter the code generated by your Authenticator app',
  'app.pages.login.enterCodePlaceholder': 'Enter the code',
  'app.pages.login.enterCodeError': 'The code is invalid',
  'app.pages.login.enterCodeSuccess': 'Multi-factor authentication enabled successfully',
  'app.pages.login.verify': 'Verify',
  'app.pages.login.cantUseAuthAppNow': 'I can’t use my authenticator app right now.',
  'app.pages.login.sendCodeToEmail':
    'We have sent the verification code to your email inbox {userSecretMail}. Please enter the code to proceed.',
  'app.pages.login.loadingInstanceData': 'Loading instance data',
  'app.pages.login.deleteSSOTo': 'Delete "SSO to {company}" link',

  //topbar
  'app.pages.app.topbar.notifications': 'Notifications',
  'app.pages.app.topbar.showMore': 'Show more',
  'app.pages.app.topbar.showNotifications': 'Show notifications',
  'app.pages.app.topbar.defaultInterface': 'Default interface',
  'app.pages.app.topbar.summary': 'Summary',
  'app.pages.app.topbar.manageLibs': 'Manage libraries',
  'app.pages.app.topbar.reloadApp': 'Reload app',
  'app.pages.app.topbar.createNew.app': 'App',
  'app.pages.app.topbar.layout.diagram': 'Result',
  'app.pages.app.topbar.layout.preview': 'Code+Preview',
  'app.pages.app.topbar.layout.result': 'Code+Result',
  'app.pages.app.topbar.saveAs': 'Save as',
  'app.pages.app.topbar.saveAsNewVersion': 'Save as new version',
  'app.pages.app.topbar.saveApplicationInMyWorkspace': 'Save application in my workspace',
  'app.pages.app.topbar.appName': 'App name',
  'app.pages.app.topbar.appNameValidationError':
    "App name can't contain the following characters: ",
  'app.pages.app.topbar.selectAppVersionsToCopy': 'Select app versions to copy',
  'app.pages.app.topbar.copiesAllAppVersionMessage':
    'All versions will be copied when none are selected',
  'app.pages.app.topbar.logout': 'Log out',
  'app.pages.app.topbar.libsManager.uninstalled': 'The library has been uninstalled successfully',
  'app.pages.app.topbar.libsManager.version': 'Version',
  'app.pages.app.topbar.libsManager.refresh': 'Refresh',
  'app.pages.app.topbar.libsManager.location': 'Location',
  'app.pages.app.topbar.libsManager.actions': 'Actions',
  'app.pages.app.topbar.libsManager.installedLibraries': 'Installed libraries',
  'app.pages.app.topbar.libsManager.install': 'Install',
  'app.pages.app.topbar.libsManager.appRequirements': 'App requirements',
  'app.pages.app.topbar.libsManager.saveFileInstallLibraries': 'Save file and install libraries',
  'app.pages.app.topbar.libsManager.saveInstallLibraries': 'Save and install',
  'app.pages.app.topbar.libsManager.requirementsFile': 'requirements.txt file',
  'app.pages.app.topbar.libsManager.pipInstallError': 'Whitespaces are not allowed',
  'app.pages.app.topbar.libsManager.systemLib': 'system lib',
  'app.pages.app.topbar.libsManager.userLib': 'user lib',
  'app.pages.app.topbar.libsManager.filter': 'Filter',
  'app.pages.app.topbar.libsManager.libraries': 'Libraries',
  'app.pages.app.topbar.showConsole': 'Show console',
  'app.pages.app.topbar.closeConsole': 'Close console',
  'app.pages.app.topbar.appProperties': 'App properties',
  'app.pages.app.topbar.deparmentsError':
    'There is an interface without departments.Please select a department.',
  'app.pages.app.topbar.deparmentRepeated':
    'The department {name} already has an interface defined',
  'app.pages.app.topbar.allDeps': 'All',
  'app.pages.app.topbar.selecteDefaultInterface':
    'Select the default interface for the application',
  'app.pages.app.topbar.libsManager.cantInstallHead':
    'You are using a shared library environment. Pyplan needs to generate a unique environment for you to add/remove libraries (this task may take a few minutes).',
  'app.pages.app.topbar.libsManager.cantInstallTasksHead':
    'To do this, the following tasks will be performed:',
  'app.pages.app.topbar.libsManager.cantInstallTask1': 'the current application will be saved',
  'app.pages.app.topbar.libsManager.cantInstallTask2': 'a new unique environment will be generated',
  'app.pages.app.topbar.libsManager.cantInstallTask3':
    'the application will be opened again with the unique environment',
  'app.pages.app.topbar.libsManager.cantInstallConfirmationMessage':
    'Are you sure you want to perform these tasks?',
  'app.pages.app.topbar.libsManager.cantInstallButtonText': 'Yes, perform these tasks',
  'app.pages.app.topbar.changeAppVersion': 'Change app version',
  'app.pages.app.topbar.changeVersion': 'Change version',
  'app.pages.app.topbar.takeScreenshot': 'Take screenshot',
  'app.pages.app.topbar.screenshotResult': 'Screenshot result',
  'app.pages.app.topbar.downloadScreenshot': 'Download screenshot',
  'app.pages.app.topbar.saveImageAsAppThumbnail': 'Save image as app thumbnail',
  'app.pages.app.topbar.appIdError':
    'The app ID may only contain numbers, letters, hyphens and must be more than 7 characters long',
  'app.pages.app.topbar.appIdMessage':
    'To make the changes take effect, you must restart the application.',
  'app.pages.app.topbar.appIdAlert': 'All data related to:',

  //Info menu dialog:
  'app.pages.app.closeApp': 'Close App',
  'app.pages.app.closedSuccessfully': 'Application closed successfully',
  'app.pages.app.reloadSuccessfully': 'Application successfully reloaded',
  'app.pages.app.saveSuccessfully': 'Application saved successfully',
  'app.pages.app.appNewPath': 'The app was saved in: ',
  'app.pages.app.saveApp': 'Save app',
  'app.pages.app.newModelName': 'New model name',
  'app.pages.app.newModelNamePlaceholder': 'Insert new model name',
  'app.pages.app.unconfirmedNodes': 'Unconfirmed nodes',
  'app.pages.app.saveDefinitionsAndApp': 'Confirm definitions and save app',
  'app.pages.app.discardDefinitionsAndSaveApp': 'Discard definitions and save app',
  'app.pages.app.unconfirmedNodesAlert':
    'There are {number} nodes with unconfirmed definitions. What would you like to do?',
  'app.pages.app.lostDataAlert': 'Unsaved application data will be lost. Are you sure?',
  'app.pages.app.unsavedChanges': 'You have unsaved changes. What would you like to do?',
  'app.pages.app.saveChangesAndOpenApp': 'Save changes and open app',
  'app.pages.app.discardChangesAndOpenApp': 'Discard changes and open app',
  'app.pages.app.saveChangesAndCreateApp': 'Save changes and open app',
  'app.pages.app.discardChangesAndCreateApp': 'Discard changes and create app',

  //sidebarMenu
  'app.pages.app.sidebarMenu.fileManager': 'File manager',
  'app.pages.app.sidebarMenu.code': 'Code',
  'app.pages.app.sidebarMenu.interfaces': 'Interfaces',
  'app.pages.app.sidebarMenu.tools': 'Tools',
  'app.pages.app.sidebarMenu.scheduledTasks': 'Scheduled tasks',
  'app.pages.app.sidebarMenu.myAccount': 'My account',
  'app.pages.app.sidebarMenu.settings': 'Settings',
  'app.pages.app.sidebarMenu.securityOptions': 'Security options',
  'app.pages.app.sidebarMenu.users': 'Users',
  'app.pages.app.sidebarMenu.departments': 'Departments',
  'app.pages.app.sidebarMenu.teams': 'Teams',
  'app.pages.app.sidebarMenu.permissionByRole': 'Permissions by role',
  'app.pages.app.sidebarMenu.applications': 'Applications',
  'app.pages.app.sidebarMenu.processManager': 'Processes',
  'app.pages.app.sidebarMenu.home': 'Home',
  'app.pages.app.sidebarMenu.numberLocale': 'Number format',
  'app.pages.app.sidebarMenu.language': 'Language',

  //home
  'app.pages.app.home.myPyplan': 'My Pyplan',
  'app.pages.app.home.openApps': 'Open apps',
  'app.pages.app.home.workspaces': 'Workspaces',
  'app.pages.app.home.folders': 'Folders',
  'app.pages.app.home.instanceKilledSuccessfully': 'Instance killed successfully',
  'app.pages.app.home.instanceChangedSuccessfully': 'Instance changed successfully',
  //home.pyplanResources
  'app.pages.app.home.pyplanResources': 'Pyplan resources',
  'app.pages.app.home.pyplanResources.videos': 'Videos',
  'app.pages.app.home.pyplanResources.videosCaption': 'Watch Pyplan in action',
  'app.pages.app.home.pyplanResources.documentation': 'Documentation',
  'app.pages.app.home.pyplanResources.documentationCaption': 'Basic steps',
  'app.pages.app.home.pyplanResources.examples': 'Examples',
  'app.pages.app.home.pyplanResources.examplesCaption': 'Applications',
  //home.shortcutsPanel
  'app.pages.app.home.shortcutsPanel.tutorials': 'Tutorials',
  'app.pages.app.home.shortcutsPanel.examples': 'Examples',
  'app.pages.app.home.shortcutsPanel.demos': 'Demos',
  'app.pages.app.home.shortcutsPanel.models': 'Models',
  'app.pages.app.home.shortcutsPanel.noModelFound':
    'No models were open recently. Create a new model or explore your files',
  'app.pages.app.home.shortcutsPanel.noExamplesFound': 'No examples found',
  'app.pages.app.home.shortcutsPanel.my_apps': 'My apps',
  'app.pages.app.home.shortcutsPanel.public_apps': 'Public apps',
  'app.pages.app.home.shortcutsPanel.recently_opened': 'Recently opened',
  'app.pages.app.home.shortcutsPanel.noPermissionsToViewApps':
    'You do not have permissions to view applications. Please, contact an administrator.',

  //editProfile
  'app.pages.editProfile.editProfile': 'Edit profile',
  'app.pages.editProfile.changePasswordAlert':
    'If you change your password your instance will be closed and you would need to log in again',
  'app.pages.editProfile.currentPassword': 'Current password',
  'app.pages.editProfile.currentPasswordIncorrect': 'Current password is incorrect',

  //managers
  'app.pages.app.managers.firstPage': 'First page',
  'app.pages.app.managers.prevPage': 'Prev. page',
  'app.pages.app.managers.nextPage': 'Next page',
  'app.pages.app.managers.lastPage': 'Last page',
  'app.pages.app.managers.cancel': 'Cancel',
  'app.pages.app.managers.create': 'Create',
  'app.pages.app.managers.update': 'Update',
  'app.pages.app.managers.search': 'Search',
  'app.pages.app.managers.searchHelperText': 'Please add at least 3 characters',
  'app.pages.app.managers.companyManager': 'Company manager',
  'app.pages.app.managers.folder': 'Folder',
  'app.pages.app.managers.departmentManager': 'Department manager',
  'app.pages.app.managers.rowsPerPage': 'Rows per page',
  'app.pages.app.managers.company': 'Company',
  'app.pages.app.managers.company.companies': 'Companies',
  'common.name.isRequired': 'Name is required',
  'app.pages.app.managers.company.notFound': 'Company not found',
  'app.pages.app.managers.company.placeholder': 'Please select a company',
  'app.pages.app.managers.company.isRequired': 'Company is required',
  'app.pages.app.managers.roleManager': 'Role manager',
  'app.pages.managers.invalidJSON': 'It is not a valid JSON',
  'app.pages.managers.actions': 'Actions',
  'app.pages.managers.departments': 'Departments',
  'app.pages.managers.mainDepartment': 'Main department',
  'app.pages.managers.add': 'Add',
  'app.pages.managers.role': 'Role',
  'app.pages.managers.roles': 'Roles',
  'app.pages.app.managers.back': 'Back',
  'app.pages.app.managers.unkwownStep': 'Unknown step',
  'app.pages.app.managers.saveButton': 'Save',
  'app.pages.app.managers.nextButton': 'Next',
  'app.pages.app.managers.companies': 'Companies',
  'app.pages.managers.password': 'Password',
  'app.pages.managers.newPassword': 'New password',
  'app.pages.managers.confirmPassword': 'Confirm password',
  'app.pages.managers.passwordIsRequired': 'Password is required',
  'app.pages.managers.ConfirmPasswordIsRequired': 'Password confirmation required',
  'app.pages.managers.passwordMinLength':
    'Password is too short - should be {number} chars minimum',
  'app.pages.managers.passwordInvalidRequisites':
    'Password must have at least one uppercase, one lowercase and one special character',
  'app.pages.managers.passwordsNotMatch': 'Passwords do not match',
  'app.pages.managers.send': 'Send',
  'app.pages.app.managers.delete': 'Delete',
  'app.pages.app.managers.createAppFromThis': 'Create app from this',
  'app.pages.app.managers.applicationSuccessfullyCreated': 'Successfully created application in',
  'app.pages.app.managers.newFolder': 'New folder',
  'app.pages.app.managers.rename': 'Rename',
  'app.pages.app.managers.set_permission': 'Set permissions',
  'app.pages.app.managers.duplicate': 'Duplicate',
  'app.pages.app.managers.openAsReadOnly': 'Open as read-only',
  'app.pages.app.managers.copyAppToWorkspace': 'Copy app to workspace',
  'app.pages.app.managers.copyAppToTeam': 'Copy app to team',
  'app.pages.app.managers.copyAppToPublic': 'Copy app to Public',
  'app.pages.app.managers.openInNewInstance': 'Open application in new instance',
  'app.pages.app.managers.downloadApp': 'Download app',
  'app.pages.app.managers.appDownloaded': 'Application downloaded',
  'app.pages.app.managers.copyInterfaceId': 'Copy interface id',
  'app.pages.app.managers.idCopied': 'Id copied',
  'app.pages.app.managers.folderExists': 'Folder already exists',

  // Notifications Manager
  'app.pages.app.notificationsManager.notificationsManager': 'Notifications manager',
  'app.pages.app.notificationsManager.addNotification': 'Add notification',
  'app.pages.app.notificationsManager.editNotification': 'Edit notification',
  'app.pages.app.notificationsManager.successChangedNotificationStatus':
    'Successfully changed notification status',
  'app.pages.app.notificationsManager.removeNotification': 'Remove notification',
  'app.pages.app.notificationsManager.removeNotificationConfirmation':
    'Are you sure you want to remove this notification?',
  'app.pages.app.notificationsManager.successRemovedNotification':
    'Successfully removed notification',
  'app.pages.app.notificationsManager.successAddedNotification': 'Successfully added notification',
  'app.pages.app.notificationsManager.effectiveDate': 'Effective date',
  'app.pages.app.notificationsManager.noNotifications': 'No notifications',
  'app.pages.app.notificationsManager.notificationTitle': 'Notification title',
  'app.pages.app.notificationsManager.notificationDescription': 'Notification description',
  'app.pages.app.notificationsManager.notification': 'Notification',

  //companyManager
  'app.pages.app.companyManager.newCompany': 'Create company',
  'app.pages.app.companyManager.editCompany': 'Update company',
  'app.pages.app.companyManager.companyPreferences': 'Preferences',
  'app.pages.app.companyManager.successChangedStatus': 'Successfully changed company status',
  //companyManager.companyTable
  'app.pages.app.companyManager.companyTable.companyStatusColumn': 'Status',
  'app.pages.app.companyManager.companyTable.companyTableAriaLabel': 'Company table',
  'app.pages.app.companyManager.companyTable.companiesNotFound': 'Companies not found',
  'app.pages.app.companyManager.hasSaml': 'Has SAML config',

  //companyManager.addOrEdit
  'app.pages.companyManager.addOrEdit.companyNameField': 'Company name',
  'app.pages.companyManager.addOrEdit.companyCodeField': 'Folder name',
  'app.pages.companyManager.addOrEdit.companyActivationCodeField': 'Activation code',
  'app.pages.companyManager.addOrEdit.copiedCompanyActivationCode': 'Activation code - Copied ✓',
  'app.pages.companyManager.addOrEdit.companyLicenseField': 'License',
  'app.pages.companyManager.addOrEdit.companyLogo': 'Company logo',
  'app.pages.companyManager.addOrEdit.dropzoneFirstRow': 'Drag and drop company logo or',
  'app.pages.companyManager.addOrEdit.dropzoneLinkRow': 'browse files.',
  'app.pages.companyManager.addOrEdit.companyFieldRequiredError': 'Required field',
  'app.pages.companyManager.addOrEdit.companyFieldMaxLengthError': 'Can not exceed 255 characters',
  'app.pages.companyManager.addOrEdit.companyFieldPatternError':
    'Invalid characters. Only lowercase a-z, uppercase A-Z, 0 to 9 numbers, dashes and underscores are allowed',
  'app.pages.companyManager.addOrEdit.companyFolderAlreadyExists':
    'Company Folder already exists, choose a different code',
  'app.pages.companyManager.addOrEdit.copyToClipboard': 'Copy to clipboard',
  'app.pages.companyManager.addOrEdit.copiedData': 'Data copied to clipboard',
  'app.pages.companyManager.addOrEdit.invalidFileExtension': 'Invalid file. Image is required',
  'app.pages.app.companyManager.successAddedCompany': 'Successfully created new company',
  'app.pages.app.companyManager.successEditedCompany': 'Successfully edited company',
  'app.pages.app.companyManager.removeLicense': 'Remove license',

  //Department Manager
  'app.pages.DepartmentPage.addDepartment': 'Add department',
  'app.pages.DepartmentPage.editDepartment': 'Edit department',
  'app.pages.DepartmentPage.engineDefinitions': 'Engine definitions',
  'app.pages.DepartmentPage.loginAction': 'Login Action',
  'app.pages.DepartmentPage.deniedItems': 'Denied Items',
  'app.pages.DepartmentPage.name.maxLengthError':
    'The department name max length is {number} characters',
  'app.pages.DepartmentPage.department.notFound': 'Departments not found',
  'app.pages.DepartmentPage.department.created': 'Department created',
  'app.pages.DepartmentPage.department.updated': 'Department updated',
  'app.pages.DepartmentPage.departmentManagerTable': 'Department manager table',
  'app.pages.DepartmentPage.addLoginAction': 'Add login action',
  'app.pages.DepartmentPage.department': 'Department',

  //Role Manager
  'app.pages.RolePage.addRole': 'Add role',
  'app.pages.RolePage.editRole': 'Edit role',
  'app.pages.RolePage.role.notFound': 'Roles not found',
  'app.pages.RolePage.role.permission.template': 'Permission template',
  'app.pages.RolePage.role.permission.administrator': 'Admininistrator',
  'app.pages.RolePage.role.permission.appAdministrator': 'App Administrator',
  'app.pages.RolePage.role.permission.creatorWithPublicAccess': 'Creator with Public Access',
  'app.pages.RolePage.role.permission.creator': 'Creator',
  'app.pages.RolePage.role.permission.explorer': 'Explorer',
  'app.pages.RolePage.role.permission.viewer': 'Viewer',
  'app.pages.RolePage.role.permission.loginOnlyUser': 'Login Only User',
  'app.pages.RolePage.role.permission.override': 'This will override all permissions',
  'app.pages.RolePage.role.created': 'Role created',
  'app.pages.RolePage.role.updated': 'Role updated',
  'app.pages.RolePage.name.maxLengthError': 'The role name max length is {number} characters',
  'app.pages.RolePage.name.exist': 'The role name already exist',
  'app.pages.RolePage.roleManagerTable': 'Role manager table',

  //notFoundPage
  'app.components.notFoundPage.message': 'The page you are looking for does not exist',
  'app.components.notFoundPage.orGoTo': 'or go to',
  'app.components.notFoundPage.returnToThe: ': 'Return to the',
  'app.components.notFoundPage.previousPage': 'previous page',
  'app.components.notFoundPage.homePage': 'home page',

  //Company Preferences
  'app.pages.app.companyManager.companyPreferences.notFound': 'Preferences not found',
  'app.pages.app.companyManager.companyPreferences.title': 'Company Preferences of ',
  'app.pages.app.companyManager.companyPreferences.copyDefaultValue': 'Copy Default value',
  'app.pages.app.companyManager.companyPreferences.defaultValueCopied': 'Default value copied',
  'app.pages.app.companyManager.companyPreferences.preferenceUpdated': 'Preference updated',
  'app.pages.app.companyManager.companyPreferences.preferenceCreated': 'Preference created',
  'app.pages.app.companyManager.companyPreferences.preferenceDelete': 'Preference deleted',
  'app.pages.app.companyManager.companyPreferences.saveCustomValue': 'Save custom value',
  'app.pages.app.companyManager.companyPreferences.removeCustomValue': 'Remove custom value',

  //Permissions by role
  'app.pages.app.permissionsByRole': 'Permissions by role',
  'app.pages.app.permissionsByRole.permissionActivated': 'Permission activated',
  'app.pages.app.permissionsByRole.permissionDeactivated': 'Permission deactivated',
  'app.pages.app.permissionsByRole.allPermissionsActivated':
    'All permissions for {name} have been activated',
  'app.pages.app.permissionsByRole.permission': 'Permission',
  'app.pages.app.permissionsByRole.permissionsUpdated': 'Permissions updated',

  //team manager
  'app.pages.app.teamManager.team': 'Team manager',
  'app.pages.app.teamManager.addTeam': 'Add team',
  'app.pages.app.teamManager.editTeam': 'Edit team',
  'app.pages.app.teamManager.accessLevel': 'Access level',
  'app.pages.app.teamManager.teamDeleted': 'Team {name} deleted',
  'app.pages.app.teamManager.deleteTeam': 'Delete team',
  'app.pages.app.teamManager.areYouSureToDeleteTeam':
    'Are you sure you want to delete team {name}?',
  'app.pages.app.teamManager.departmentsAsociated':
    'It is associated with the following departments:',
  'app.pages.app.teamManager.readOnly': 'Read only',
  'app.pages.app.teamManager.readAndWrite': 'Read and write',
  'app.pages.app.teamManager.updateTeam': 'Update team',
  'app.pages.app.teamManager.createTeam': 'Create team',
  'app.pages.app.teamManager.teamDepartments': 'There is no departments',
  'app.pages.app.teamManager.teamUpdated': 'Team updated',
  'app.pages.app.teamManager.teamCreated': 'Team created',
  'app.pages.teamManager.name.maxLengthError': 'The team name max length is {number} characters',
  'app.pages.teamManager.teamsNotFound': 'Teams not found',
  //User Manager
  'app.pages.UserPage.userManager': 'User manager',
  'app.pages.UserPage.addUser': 'Add user',
  'app.pages.UserPage.editUser': 'Edit user',
  'app.pages.UserPage.email': 'Email',
  'app.pages.UserPage.login': 'Username',
  'app.pages.UserPage.usersNotFound': 'Users not found',
  'app.pages.UserPage.changePassword': 'Change password',
  'app.pages.UserPage.manageCompanies': 'Manage companies',
  'app.pages.UserPage.passwordChanged': 'Password changed',
  'app.pages.UserPage.downloadReport': 'Download Users report',
  'app.pages.UserPage.reportsDownloaded': 'Users report downloaded',
  'app.pages.UserPage.firstName': 'First name',
  'app.pages.UserPage.firstNameIsRequired': 'First name is required',
  'app.pages.UserPage.firstNameMaxLength': 'The first name max length is {number} characters',
  'app.pages.UserPage.firstNamePlaceholder': 'Insert your name',
  'app.pages.UserPage.lastNamePlaceholder': 'Insert your last name',
  'app.pages.UserPage.lastNameIsRequired': 'Last name is required',
  'app.pages.UserPage.lastNameMaxLength': 'The last name max length is {number} characters',
  'app.pages.UserPage.emailPlaceholder': 'Insert your email',
  'app.pages.UserPage.emailIsInvalid': 'Invalid email',
  'app.pages.UserPage.emailContainingÑ': 'Email can not contain "Ñ"',
  'app.pages.UserPage.emailIsRequired': 'Email is required',
  'app.pages.UserPage.emailAlreadyExist': 'Email already exists',
  'app.pages.UserPage.lastName': 'Last name',
  'app.pages.UserPage.userNameIsRequired': 'Username is required',
  'app.pages.UserPage.userNamePlaceholder': 'Insert your username',
  'app.pages.UserPage.passwordPlaceholder': 'Insert your password',
  'app.pages.UserPage.usernameAlreadyExist': 'Username already Exist',
  'app.pages.UserPage.usernameInvalid':
    'Username can have "@", "+", "-", "_" or "." characters. Whitespaces are not allowed.',
  'app.pages.UserPage.steps.userInformation': 'User information',
  'app.pages.UserPage.steps.roleAndCompanySelection': 'Role and company selection',
  'app.pages.UserPage.steps.roleAndCompanySelection.departmentPlaceholder':
    'Please select a department',
  'app.pages.UserPage.steps.roleAndCompanySelection.rolePlaceholder': 'Please select a role',
  'app.pages.UserPage.steps.roleAndCompanySelection.addCompany': 'Add company',
  'app.pages.UserPage.steps.roleAndCompanySelection.removeCompany': 'Remove company',
  'app.pages.UserPage.steps.roleAndCompanySelection.noCompaniesAdded':
    'No companies added, please add one',
  'app.pages.UserPage.steps.roleAndCompanySelection.roleWarning':
    'This role applies to all companies',
  'app.pages.UserPage.toggleActiveUser.errorNoRoles':
    '{user} does not have a role for the company {company}',
  'app.pages.UserPage.toggleActiveUser.activated': 'User activated',
  'app.pages.UserPage.toggleActiveUser.desactivated': 'User deactivated',
  'app.pages.UserPage.userUnlocked': 'User unlocked',
  'app.pages.UserPage.userCreated': 'User created',
  'app.pages.UserPage.userUpdated': 'User updated',
  'app.pages.UserPage.errorCompanyWithNoRoles': 'There is a company without roles',
  'app.pages.UserPage.errorCompanyWithNoDepartments': 'There is a company without departments',
  'app.pages.UserPage.deleteUser': 'Delete user',
  'app.pages.UserPage.areYouSureToDeleteUser': 'Are you sure you want to delete user {name}?',
  'app.pages.UserPage.userDeleted': 'User deleted',
  'app.pages.UserPage.changeStatus': 'Change status',
  'app.pages.UserPage.unlockUser': 'Unlock user',
  'app.pages.UserPage.ChangePasswordLogin': 'Change password upon login',
  'app.pages.UserPage.enableMfa': 'Enable multi-factor authentication',
  'app.pages.UserPage.setNewQrCode': 'Set new QR code',
  'app.pages.UserPage.validateSetNewQRCode':
    'A new QR code will be set for the user {user}. Do you want to continue?',
  'app.pages.UserPage.qrCodeSuccesfullySeted': 'New QR code set',

  //Code Diagram Nodes
  'app.pages.Code.Diagram.Nodes.variable': 'Variable',
  'app.pages.Code.Diagram.Nodes.datareading': 'Data reading',
  'app.pages.Code.Diagram.Nodes.button': 'Button',
  'app.pages.Code.Diagram.Nodes.index': 'Index',
  'app.pages.Code.Diagram.Nodes.module': 'Module',
  'app.pages.Code.Diagram.Nodes.inputscalar': 'Scalar',
  'app.pages.Code.Diagram.Nodes.inputselector': 'Selector',
  'app.pages.Code.Diagram.Nodes.inputchoice': 'Choice',
  'app.pages.Code.Diagram.Nodes.inputdataframe': 'DataFrame',
  'app.pages.Code.Diagram.Nodes.inputdataarray': 'DataArray',
  'app.pages.Code.Diagram.Nodes.inputcube': 'Cube',
  'app.pages.Code.Diagram.Nodes.text': 'Text',
  'app.pages.Code.Diagram.Nodes.generic': 'Generic',
  'app.pages.Code.Diagram.Nodes.form': 'Form',
  'app.pages.Code.Diagram.Nodes.inputInitialName': 'Set node name',
  'app.pages.Code.Diagram.Nodes.inputData': 'Input data',
  'app.pages.Code.Diagram.Nodes.chooseInputType': 'Choose input data type',
  'app.pages.Code.Diagram.Nodes.report': 'Report',
  'app.pages.Code.Diagram.Nodes.outputnode': 'Output',
  'app.pages.Code.Diagram.Nodes.reportwizard': 'Report wizard',

  //Code Diagram Menu
  'app.pages.Code.Diagram.Menu.hideMenu': 'Hide menu',
  'app.pages.Code.Diagram.Menu.wizards': 'Wizards',
  'app.pages.Code.Diagram.Menu.importModule': 'Import module',
  'app.pages.Code.Diagram.Menu.importModule.steps.selectModule': 'Select module',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType': 'Import type',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType.switch': 'Switch',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType.merge': 'Merge',
  'app.pages.Code.Diagram.Menu.importModule.sucessful': 'Module successfully imported',
  'app.pages.Code.Diagram.Menu.importModule.hasOutputsOutsideItsModule':
    'Has outputs outside its module',
  //Code Diagram Delete dialog
  'app.pages.Code.Diagram.DeleteDialog.deleteNodes': 'Delete nodes?',
  'app.pages.Code.Diagram.DeleteDialog.confirmationtext':
    'Are you sure you want to delete {number} selected nodes?',
  //Node Properties Dialog
  'app.pages.Code.Diagram.Nodes.properties.title': 'Node title',
  'app.pages.Code.Diagram.Nodes.properties.id': 'Node identifier',
  'app.pages.Code.Diagram.Nodes.properties.nodeClass': 'Node type',
  'app.pages.Code.Diagram.Nodes.properties.units': 'Units',
  'app.pages.Code.Diagram.Nodes.properties.evaluateOnStart': 'Evaluate on start',
  'app.pages.Code.Diagram.Nodes.properties.releaseMemory': 'Release memory',
  'app.pages.Code.Diagram.Nodes.properties.prefixIdentifier': 'Module prefix',
  'app.pages.Code.Diagram.Nodes.properties.idHelperText':
    'Identifier must start with a letter, and only lowercase letters, numbers and underscores are allowed',
  'app.pages.Code.Diagram.Nodes.properties.idRequired': 'Node identifier can not be empty',
  'app.pages.Code.Diagram.Nodes.properties.prefixIdHelperText':
    'Module prefix must start with a letter, and only lowercase letters, numbers and underscores are allowed',
  'app.pages.Code.Diagram.Nodes.properties.hierarchyMap': 'Hierarchy map',
  'app.pages.Code.Diagram.Nodes.inputs': 'Inputs',
  'app.pages.Code.Diagram.Nodes.outputs': 'Outputs',
  'app.pages.Code.Diagram.Nodes.general': 'General',
  'app.pages.Code.Diagram.Nodes.metadata': 'Metadata',
  'app.pages.Code.Diagram.Nodes.creationDate': 'Creation date',
  'app.pages.Code.Diagram.Nodes.lastModification': 'Last modification',
  'app.pages.Code.Diagram.Nodes.lastUserThatModified': 'Last user that modified',
  'app.pages.Code.Diagram.Nodes.noInputs': 'There are no inputs',
  'app.pages.Code.Diagram.Nodes.noOutputs': 'There are no outputs',
  'app.pages.Code.Diagram.Nodes.docs': 'Documentation',
  'app.pages.Code.Diagram.Nodes.noDocs': 'There is no documentation for this section',
  'app.pages.Code.Diagram.Nodes.editDocs': 'Edit docs',
  'app.pages.Code.Diagram.Nodes.docsEdited': 'Docs edited',
  'app.pages.Code.Diagram.Nodes.selectNodeToShowDocs': 'Select a node to display the docs',
  'app.pages.Code.itemHeader.saveDefaultView': 'Save default view',
  //Context Menu
  'app.pages.Code.Diagram.ContextMenu.resize': 'Resize nodes as {string}',
  'app.pages.Code.Diagram.ContextMenu.alignment': 'Align nodes to {string}',
  'app.pages.Code.Diagram.ContextMenu.showInputs': 'Show inputs',
  'app.pages.Code.Diagram.ContextMenu.showOutputs': 'Show outputs',
  'app.pages.Code.Diagram.ContextMenu.sizes.width': 'Make same width',
  'app.pages.Code.Diagram.ContextMenu.sizes.height': 'Make same height',
  'app.pages.Code.Diagram.ContextMenu.sizes.size': 'Make same size',
  'app.pages.Code.Diagram.ContextMenu.alignment.top': 'Top edges',
  'app.pages.Code.Diagram.ContextMenu.alignment.centerH': 'Center horizontally',
  'app.pages.Code.Diagram.ContextMenu.alignment.centerV': 'Center vertically',
  'app.pages.Code.Diagram.ContextMenu.alignment.bottom': 'Bottom edges',
  'app.pages.Code.Diagram.ContextMenu.alignment.right': 'Right edges',
  'app.pages.Code.Diagram.ContextMenu.alignment.left': 'Left edges',
  'app.pages.Code.Diagram.ContextMenu.exportModule': 'Export module',
  'app.pages.Code.Diagram.ContextMenu.moduleExported': 'Module exported',
  'app.pages.Code.Diagram.ContextMenu.setRestriction': 'Set restriction',
  'app.pages.Code.Diagram.ContextMenu.pinNode': 'Pin node',
  'app.pages.Code.Diagram.ContextMenu.unpinNode': 'Unpin node',
  'app.pages.Code.Diagram.ContextMenu.apiEndpoint': 'Get API endpoint',
  'app.pages.Code.Diagram.apiEndpoint.endpointFor': 'Endpoint for: ',
  'app.pages.Code.Diagram.apiEndpoint.shareInstance': 'Share instance',
  'app.pages.Code.Diagram.apiEndpoint.apiURLcopied': 'API URL copied ',
  'app.pages.Code.Diagram.apiEndpoint.message':
    'and send function parameters as JSON in the request body.',
  'app.pages.Code.Diagram.ContextMenu.openWizard': 'Open wizard',
  'app.pages.Code.Diagram.ContextMenu.compareNodes': 'Compare nodes',
  'app.pages.Code.Diagram.ContextMenu.handlingDataWidget': 'Handling data',
  'app.pages.Code.Diagram.Wizards.dropDuplicates': 'Drop duplicates',
  'app.pages.Code.Diagram.ContextMenu.dataSystemNodeNoData': 'Data system node has no data',

  //Code Diagram Wizards
  'app.pages.Code.Diagram.Wizards.openWizard': 'Open wizard',
  'app.pages.Code.Diagram.Wizards.selectColumns': 'Select columns',
  'app.pages.Code.Diagram.Wizards.editInExcel': 'Edit in Excel',
  'app.pages.Code.Diagram.Wizards.selectRows': 'Select rows',
  'app.pages.Code.Diagram.Wizards.name': 'Column name',
  'app.pages.Code.Diagram.Wizards.isIndex': 'Is index',
  'app.pages.Code.Diagram.Wizards.dtype': 'Data type',
  'app.pages.Code.Diagram.Wizards.editTableDefinition': 'Edit Table definition',
  'app.pages.Code.Diagram.Wizards.removeColumn': 'Remove column',
  'app.pages.Code.Diagram.Wizards.addNewColumn': 'Add new column',
  'app.pages.Code.Diagram.Wizards.column': 'Column',
  'app.pages.Code.Diagram.Wizards.editCubeDefinition': 'Edit Cube definition',
  'app.pages.Code.Diagram.Wizards.deleteCubeData': 'Delete Cube data',
  'app.pages.Code.Diagram.Wizards.deleteCubeDataMessage':
    'Are you sure you want to delete the Cube data from the database?',
  'app.pages.Code.Diagram.Wizards.deleteCubeDataConfirm':
    'The Cube data was successfully deleted from the database',
  'app.pages.Code.Diagram.Wizards.availableIndexes': 'Available indexes',
  'app.pages.Code.Diagram.Wizards.selectedIndexes': 'Selected indexes',
  'app.pages.Code.Diagram.Wizards.filter': 'Filter',
  'app.pages.Code.Diagram.Wizards.defaultValue': 'Default value',
  'app.pages.Code.Diagram.Wizards.defaultValueType': 'Default value type',
  'app.pages.Code.Diagram.Wizards.scalar': 'Scalar',
  'app.pages.Code.Diagram.Wizards.model_node': 'Model node',
  'app.pages.Code.Diagram.Wizards.app_node': 'App node',
  'app.pages.Code.Diagram.Wizards.node_id': 'Node id already exists, select another one',
  'app.pages.Code.Diagram.Wizards.calculatedField': 'Calculated field',
  'app.pages.Code.Diagram.Wizards.setIndex': 'Set index',
  'app.pages.Code.Diagram.Wizards.groupAgregate': 'Group/aggregate',
  'app.pages.Code.Diagram.Wizards.createindex': 'Create Index',
  'app.pages.Code.Diagram.Wizards.createcube': 'Create Cube',
  'app.pages.Code.Diagram.Wizards.form': 'Form',
  'app.pages.Code.Diagram.Wizards.report': 'Report',
  'app.pages.Code.Diagram.Wizards.editFormDefinition': 'Edit Form definition',
  'app.pages.Code.Diagram.Wizards.deleteFormData': 'Delete Form data',
  'app.pages.Code.Diagram.Wizards.deleteFormDataMessage':
    'Are you sure you want to delete the Form data from the database?',
  'app.pages.Code.Diagram.Wizards.deleteFormDataConfirm':
    'The Form data was successfully deleted from the database',
  'app.pages.Code.Diagram.Wizards.formFieldError': 'Id or field title cannot be "id"',
  'app.pages.Code.Diagram.Wizards.fieldId': 'Field ID',
  'app.pages.Code.Diagram.Wizards.fieldTitle': 'Field title',
  'app.pages.Code.Diagram.Wizards.columnType': 'Column type',
  'app.pages.Code.Diagram.Wizards.hidden': 'Hidden',
  'app.pages.Code.Diagram.Wizards.readOnly': 'Read-only',
  'app.pages.Code.Diagram.Wizards.formula': 'Formula',
  'app.pages.Code.Diagram.Wizards.values': 'Values',
  'app.pages.Code.Diagram.Wizards.filterRowsByDomain': 'Filter rows by domain',
  'app.pages.Code.Diagram.Wizards.strict': 'Strict',
  'app.pages.Code.Diagram.Wizards.relatedMap': 'Related map',
  'app.pages.Code.Diagram.Wizards.relatedMapColumnValues': 'Column with values',
  'app.pages.Code.Diagram.Wizards.relatedColumns': 'Related columns',
  'app.pages.Code.Diagram.Wizards.fromNode': 'From node',
  'app.pages.Code.Diagram.Wizards.fromNodeDetails':
    'Node result must be a function that takes a Pandas DataFrame as a parameter and returns a Pandas DataFrame',
  'app.pages.Code.Diagram.Wizards.advancedOptions': 'Advanced options',
  'app.pages.Code.Diagram.Wizards.appNode': 'App node',
  'app.pages.Code.Diagram.Wizards.typeNewValues': 'Type new values. Press Enter to confirm',
  'app.pages.Code.Diagram.Wizards.columnType.input': 'Input',
  'app.pages.Code.Diagram.Wizards.columnType.checkbox': 'Checkbox',
  'app.pages.Code.Diagram.Wizards.columnType.selector': 'Selector',
  'app.pages.Code.Diagram.Wizards.columnType.related_selector': 'Related selector',
  'app.pages.Code.Diagram.Wizards.columnType.calc': 'Calc',
  'app.pages.Code.Diagram.Wizards.columnType.dataframe_column': 'DataFrame column',
  'app.pages.Code.Diagram.Wizards.columnType.system': 'System',
  'app.pages.Code.Diagram.Wizards.tableName': 'Table name',
  'app.pages.Code.Diagram.Wizards.formSettings': 'Form settings',
  'app.pages.Code.Diagram.Wizards.editFormSettings': 'Edit form settings',
  'app.pages.Code.Diagram.Wizards.addNewField': 'Add new field',
  'app.pages.Code.Diagram.Wizards.addNewItem': 'Add new item',
  'app.pages.Code.Diagram.Wizards.databaseEngine': 'Database engine',
  'app.pages.Code.Diagram.Wizards.databaseConnection': 'Database connection',
  'app.pages.Code.Diagram.Wizards.databasePath': 'Database path',
  'app.pages.Code.Diagram.Wizards.host': 'Host',
  'app.pages.Code.Diagram.Wizards.database': 'Database',
  'app.pages.Code.Diagram.Wizards.databaseCustomURL': 'Custom URL',
  'app.pages.Code.Diagram.Wizards.initialEmptyRows': 'Initial empty rows',
  'app.pages.Code.Diagram.Wizards.allowAddRows': 'Allow add rows',
  'app.pages.Code.Diagram.Wizards.allowRemoveRows': 'Allow remove rows',
  'app.pages.Code.Diagram.Wizards.allowAddNewColumns': 'Allow add new columns',
  'app.pages.Code.Diagram.Wizards.fixedColumns': 'Fixed columns',
  'app.pages.Code.Diagram.Wizards.formRecreateTableMessage':
    'All data in the form will be erased, and it will be recreated from scratch',
  'app.pages.Code.Diagram.Wizards.formSubmitConfirmation':
    'This will overwrite current form definition.',
  'app.pages.Code.Diagram.Wizards.fromDataFrame': 'From DataFrame',
  'app.pages.Code.Diagram.Wizards.sourceDataFrameNode': 'Source DataFrame node',
  'app.pages.Code.Diagram.Wizards.sourceDataFrameIdColumn': 'Source DataFrame ID column',
  'app.pages.Code.Diagram.Wizards.removeOldRows': 'Remove old rows',
  'app.pages.Code.Diagram.Wizards.newFieldName': 'New field name',
  'app.pages.Code.Diagram.Wizards.newItemName': 'New item name',
  'app.pages.Code.Diagram.Wizards.expression': 'Expression',
  'app.pages.Code.Diagram.Wizards.fieldList': 'Available fields',
  'app.pages.Code.Diagram.Wizards.agregate': 'Aggregate functions',
  'app.pages.Code.Diagram.Wizards.groupBy': 'Group by',
  'app.pages.Code.Diagram.Wizards.filterDataFrame': 'Filter DataFrame',
  'app.pages.Code.Diagram.Wizards.condition': 'Condition',
  'app.pages.Code.Diagram.Wizards.addNewFilter': 'Add new filter',
  'app.pages.Code.Diagram.Wizards.valueIsRequired': 'Value is required',
  'app.pages.Code.Diagram.Wizards.mustHaveAtLeastValues': 'Must have at least {number} value/s',
  'app.pages.Code.Diagram.Wizards.filterDataArray': 'Filter DataArray',
  'app.pages.Code.Diagram.Wizards.dimension': 'Dimension',
  'app.pages.Code.Diagram.Wizards.editIndex': 'Edit index',
  'app.pages.Code.Diagram.Wizards.itemList': 'List of items',
  'app.pages.Code.Diagram.Wizards.count': 'Count',
  'app.pages.Code.Diagram.Wizards.insertAbove': 'Insert row above',
  'app.pages.Code.Diagram.Wizards.insertBelow': 'Insert row below',
  'app.pages.Code.Diagram.Wizards.removeRow': 'Remove row',
  'app.pages.Code.Diagram.Wizards.index': 'Index',
  'app.pages.Code.Diagram.Wizards.renameIndex': 'Rename index',
  'app.pages.Code.Diagram.Wizards.filterIndexes': 'Filter indexes',
  'app.pages.Code.Diagram.Wizards.files': 'Files',
  'app.pages.Code.Diagram.Wizards.databases': 'Databases',
  'app.pages.Code.Diagram.Wizards.online': 'Online',
  'app.pages.Code.Diagram.Wizards.dataReadingNode': 'Data reading node',
  'app.pages.Code.Diagram.Wizards.readCsvFile': 'Read CSV file',
  'app.pages.Code.Diagram.Wizards.readExcelFile': 'Read Excel file',
  'app.pages.Code.Diagram.Wizards.dataPreview': 'Data preview',
  'app.pages.Code.Diagram.Wizards.schema': 'Schema',
  'app.pages.Code.Diagram.Wizards.csvDataSource': 'CSV data source',
  'app.pages.Code.Diagram.Wizards.excelDataSource': 'Excel data source',
  'app.pages.Code.Diagram.Wizards.backStep': 'Back',
  'app.pages.Code.Diagram.Wizards.nextStep': 'Next',
  'app.pages.Code.Diagram.Wizards.chooseDataSource': 'Choose a data source',
  'app.pages.Code.Diagram.Wizards.selectFile': 'Select file from server',
  'app.pages.Code.Diagram.Wizards.selectFileFromUrl': 'Select file from URL',
  'app.pages.Code.Diagram.Wizards.encoding': 'Encoding',
  'app.pages.Code.Diagram.Wizards.separator': 'Separator',
  'app.pages.Code.Diagram.Wizards.dataSetSample': 'DataSet sample',
  'app.pages.Code.Diagram.Wizards.sheetName': 'Sheet name',
  'app.pages.Code.Diagram.Wizards.definedRanges': 'Defined ranges',
  'app.pages.Code.Diagram.Wizards.customRange': 'Custom range',
  'app.pages.Code.Diagram.Wizards.setNewHeaderFirstRow': 'Set new header with first row',
  'app.pages.Code.Diagram.Wizards.exampleCustomRange': 'Example: A1:B10',
  'app.pages.Code.Diagram.Wizards.sortDataFrame': 'Sort DataFrame',
  'app.pages.Code.Diagram.Wizards.criteria': 'Criteria',
  'app.pages.Code.Diagram.Wizards.sort': 'Sort',
  'app.pages.Code.Diagram.Wizards.ascending': 'Ascending',
  'app.pages.Code.Diagram.Wizards.descending': 'Descending',
  'app.pages.Code.Diagram.Wizards.addNewOrderCondition': 'Add new order condition',
  'app.pages.Code.Diagram.Wizards.indexOptions': 'Index options',
  'app.pages.Code.Diagram.Wizards.transform': 'Transform',
  'app.pages.Code.Diagram.Wizards.toDataFrame': 'To DataFrame',
  'app.pages.Code.Diagram.Wizards.changeIndex': 'Change index',
  'app.pages.Code.Diagram.Wizards.oldIndex': 'Old index',
  'app.pages.Code.Diagram.Wizards.newIndex': 'New index',
  'app.pages.Code.Diagram.Wizards.compareMode': 'Compare mode',
  'app.pages.Code.Diagram.Wizards.searchIndex': 'Search index',
  'app.pages.Code.Diagram.Wizards.byPosition': 'By position',
  'app.pages.Code.Diagram.Wizards.byValue': 'By value',
  'app.pages.Code.Diagram.Wizards.list': 'List',
  'app.pages.Code.Diagram.Wizards.rangeType': 'Range type',
  'app.pages.Code.Diagram.Wizards.frequency': 'Frequency',
  'app.pages.Code.Diagram.Wizards.cumulative': 'Cumulative',
  'app.pages.Code.Diagram.Wizards.periods': 'Periods',
  'app.pages.Code.Diagram.Wizards.stringPrefix': 'String prefix',
  'app.pages.Code.Diagram.Wizards.to': 'To',
  'app.pages.Code.Diagram.Wizards.steps': 'Steps',
  'app.pages.Code.Diagram.Wizards.enterNumericalValue': 'Enter a numerical value',
  'app.pages.Code.Diagram.Wizards.invalidDate':
    'Invalid date, please enter a date with format: YYYY-MM-DD',
  'app.pages.Code.Diagram.Wizards.startDateIsRequired': 'Start date is required',
  'app.pages.Code.Diagram.Wizards.endDateIsRequired': 'End date is required',
  'app.pages.Code.Diagram.Wizards.yearly': 'Yearly',
  'app.pages.Code.Diagram.Wizards.monthly': 'Monthly',
  'app.pages.Code.Diagram.Wizards.weekly': 'Weekly',
  'app.pages.Code.Diagram.Wizards.daily': 'Daily',
  'app.pages.Code.Diagram.Wizards.saveLabels': 'Save labels',
  'app.pages.Code.Diagram.Wizards.nestedHeaders': 'Nested headers',
  'app.pages.Code.Diagram.Wizards.nestedHeadersTypeNewValues':
    'Type new nested headers. Press Enter to confirm',
  'app.pages.Code.Diagram.Wizards.deleteAndRecreateTable': 'Delete and recreate table',
  'app.pages.Code.Diagram.Wizards.itemName': 'Item name',
  'app.pages.Code.Diagram.Wizards.editReportDefinition': 'Edit Report definition',
  'app.pages.Code.Diagram.Wizards.itemsFromIndex': 'Items from index',
  'app.pages.Code.Diagram.Wizards.indexNode': 'Index node',
  'app.pages.Code.Diagram.Wizards.addItemsFromIndex': 'Add items from index',
  'app.pages.Code.Diagram.Wizards.asNegative': 'As negative',
  'app.pages.Code.Diagram.Wizards.itemIsNotInItemsList':
    'Item "{item}" is not in items list from node "{nodeId}"',
  'app.pages.Code.Diagram.Wizards.reportSubmitConfirmation':
    'This will overwrite current report definition.',
  'app.pages.Code.Diagram.Wizards.outputType': 'Output type',
  'app.pages.Code.Diagram.Wizards.removeNonExistingCombinations':
    'Remove non-existing combinations',
  'app.pages.Code.Diagram.Wizards.allowDimensionChanges': 'Allow dimension changes',
  'app.pages.Code.Diagram.Wizards.confirmWizardChanges': 'Confirm wizard changes',
  'app.pages.Code.Diagram.Wizards.overWriteDefinition': 'Overwrite current node definition',
  'app.pages.Code.Diagram.Wizards.createNewNodeWithCurrentChanges':
    'Create new node with current changes',
  'app.pages.Code.Diagram.Wizards.undo': 'Undo',
  'app.pages.Code.Diagram.Wizards.redo': 'Redo',
  'app.pages.Code.Diagram.Wizards.renameColumn': 'Rename column',
  'app.pages.Code.Diagram.Wizards.deleteColumn': 'Delete column',
  'app.pages.Code.Diagram.Wizards.duplicateColumn': 'Duplicate column',
  'app.pages.Code.Diagram.Wizards.changeDataType': 'Change data type',
  'app.pages.Code.Diagram.Wizards.sortColumn': 'Sort column',
  'app.pages.Code.Diagram.Wizards.removeDuplicates': 'Remove duplicates',
  'app.pages.Code.Diagram.Wizards.newColumnName': 'New column name',
  'app.pages.Code.Diagram.Wizards.deleteColumnMessage':
    'The column {column} will be removed from the DataFrame. Do you want to continue?',
  'app.pages.Code.Diagram.Wizards.newDuplicateColumnName': 'New duplicate column name',
  'app.pages.Code.Diagram.Wizards.changeColumnType': 'Change column type',
  'app.pages.Code.Diagram.Wizards.columnSanitization': 'Column sanitization',
  'app.pages.Code.Diagram.Wizards.addNewSanitizeAction': 'Add new sanitize action',
  'app.pages.Code.Diagram.Wizards.sanitizationColumnOptions': 'Sanitization column options',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveDuplicatesWords':
    'Remove duplicate words from column',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceMultiSpace':
    'Replace multiple spaces with a single space',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemovePunctuation':
    'Remove punctuation from text',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveNumbers': 'Remove numbers from text',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.KeepOnlyAlphaNumeric':
    'Keep only alphanumeric characters',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.NormalizeAccents':
    'Normalize accents from text',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveSpaces': 'Remove spaces from text',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.AddSpaceBetweenWords':
    'Add space between words',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveRepeatedChars':
    'Remove repeated characters',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.UpperWordCase': 'Upper word case',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.LowerWordCase': 'Lower word case',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.TitleWordCase': 'Title word case',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveHiddenChars': 'Remove hidden characters',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceHypensWithSpace':
    'Replace hyphens with space',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceText': 'Replace text',
  'app.pages.Code.Diagram.Wizards.textToReplace': 'Text to replace',
  'app.pages.Code.Diagram.Wizards.replaceWith': 'Replace with',
  'app.pages.Code.Diagram.Wizards.stopWords': 'Stop words',
  'app.pages.Code.Wizards.Upper': 'Upper',
  'app.pages.Code.Wizards.Lower': 'Lower',
  'app.pages.Code.Wizards.Title': 'Title',
  'app.pages.Code.Wizards.Capitalize': 'Capitalize',
  'app.pages.Code.Wizards.Swapcase': 'Swapcase',
  'app.pages.Code.Diagram.Wizards.applyChangesInNewColumn': 'Apply changes in new column',
  'app.pages.Code.Diagram.Wizards.fillEmptyValues': 'Fill empty values',
  'app.pages.Code.Diagram.Wizards.fillValue': 'Fill value',
  'app.pages.Code.Diagram.Wizards.fillMethod': 'Fill method',
  'app.pages.Code.Diagram.Wizards.searchConditionValue': 'Search condition value',

  //Interfaces
  'app.pages.Interfaces.Table.add1RowAbove': 'Add 1 row above',
  'app.pages.Interfaces.Table.add5RowsAbove': 'Add 5 rows above',
  'app.pages.Interfaces.Table.add1RowBelow': 'Add 1 row below',
  'app.pages.Interfaces.Table.add5RowsBelow': 'Add 5 rows below',
  'app.pages.Interfaces.Table.addRow': 'Add row',
  'app.pages.Interfaces.Table.removeRow': 'Remove row/s',
  'app.pages.Interfaces.Table.copy': 'Copy',
  'app.pages.Interfaces.Table.copyWithHeaders': 'Copy with headers',
  'app.pages.Interfaces.Table.copyOnlyHeaders': 'Copy only headers',
  'app.pages.Interfaces.Table.copyAllGroupHeaders': 'Copy with all group headers',
  'app.pages.Interfaces.Table.drilldownBy': 'Drilldown by:',
  'app.pages.Interfaces.Table.drilldownBack': 'Back to:',
  'app.pages.Interfaces.Table.drilldownsApplied': 'Drilldowns applied:',
  'app.pages.Interfaces.Table.removeDrilldowns': 'Remove drilldowns',
  'app.pages.Interfaces.Table.drilldownBackOneLevel': 'Drilldown back one level',
  'app.pages.Interfaces.SelectWithAutocomplete.noOptions': 'No options',
  'app.pages.Interfaces.SelectWithAutocomplete.loading': 'Loading...',
  'app.pages.Interfaces.IndexFilterDialog.availableElements': 'Available elements',
  'app.pages.Interfaces.IndexFilterDialog.selectedElements': 'Selected elements',
  'app.pages.Interfaces.IndexFilterDialog.filterElements': 'Filter elements',
  'app.pages.Interfaces.IndexFilterDialog.contains': 'Contains',
  'app.pages.Interfaces.IndexFilterDialog.notcontains': 'Does not contain',
  'app.pages.Interfaces.IndexFilterDialog.equal': 'Equal to',
  'app.pages.Interfaces.IndexFilterDialog.equal_to_node_values': 'Equal to node values',
  'app.pages.Interfaces.IndexFilterDialog.regular_expression': 'Regular expression',
  'app.pages.Interfaces.IndexFilterDialog.notequal': 'Not equal to',
  'app.pages.Interfaces.IndexFilterDialog.greater_than': 'Greater than',
  'app.pages.Interfaces.IndexFilterDialog.greater_than_equal_to': 'Greater than or equal to',
  'app.pages.Interfaces.IndexFilterDialog.less_than': 'Less than',
  'app.pages.Interfaces.IndexFilterDialog.less_than_equal_to': 'Less than or equal to',
  'app.pages.Interfaces.IndexFilterDialog.begin_with': 'Begins with',
  'app.pages.Interfaces.IndexFilterDialog.not_begin_with': 'Does not begin with',
  'app.pages.Interfaces.IndexFilterDialog.end_with': 'Ends with',
  'app.pages.Interfaces.IndexFilterDialog.not_end_with': 'Does not end with',
  'app.pages.Interfaces.IndexFilterDialog.earliest': 'Earliest',
  'app.pages.Interfaces.IndexFilterDialog.latest': 'Latest',
  'app.pages.Interfaces.IndexFilterDialog.between': 'Between',
  'app.pages.Interfaces.IndexFilterDialog.notbetween': 'Not Between',
  'app.pages.Interfaces.IndexFilterDialog.rowsCount': 'Count',
  'app.pages.Interfaces.Components.core.Node': 'Node',
  'app.pages.Interfaces.Components.core.Index': 'Index',
  'app.pages.Interfaces.Components.core.Selector': 'Selector',
  'app.pages.Interfaces.Components.core.Chart': 'Chart',
  'app.pages.Interfaces.Components.core.PlotlyChart': 'Plotly chart',
  'app.pages.Interfaces.Components.core.InputValue': 'Input value',
  'app.pages.Interfaces.Components.core.Table': 'Table',
  'app.pages.Interfaces.Components.core.InputDataArray': 'Input dataarray',
  'app.pages.Interfaces.Components.core.InputDataFrame': 'Input dataframe',
  'app.pages.Interfaces.Components.core.Html': 'Html',
  'app.pages.Interfaces.Components.core.Menu': 'Menu',
  'app.pages.Interfaces.Components.core.Empty': 'Empty',
  'app.pages.Interfaces.Components.core.Generic': 'Generic',
  'app.pages.Interfaces.Components.core.Indicator': 'Indicator',
  'app.pages.Interfaces.Components.core.Choice': 'Choice',
  'app.pages.Interfaces.Components.core.Button': 'Button',
  'app.pages.Interfaces.Components.core.Button.runButtonDefaultMessage':
    'Are you sure you want to execute this button?',
  'app.pages.Interfaces.Components.core.Form': 'Form',
  'app.pages.Interfaces.Components.core.MyTasks': 'Tasks',
  'app.pages.Interfaces.Components.core.ScheduledTasks': 'Scheduled tasks',
  'app.pages.Interfaces.Components.core.InputData': 'Input data',
  'app.pages.Interfaces.Components.core.MatplotlibChart': 'Matplotlib chart',
  'app.pages.Interfaces.Components.core.Assistant': 'Assistant',
  'app.pages.Interfaces.Components.core.Notifications': 'Notifications',
  'app.pages.Interfaces.Components.core.DynamicHtml': 'Dynamic html',
  'app.pages.Interfaces.Components.core.Filter': 'Filter',
  'app.pages.Interfaces.Components.core.Filters': 'Filters',
  'app.pages.Interfaces.Components.core.ApplyFilters': 'Apply filters',
  'app.pages.Interfaces.Components.core.multiple': 'Multiple',
  'app.pages.Interfaces.Components.core.multiSelect': 'Multiselect',
  'app.pages.Interfaces.View.InterfaceName': 'Interface {name}',
  'app.pages.Interfaces.View.share': 'Share',
  'app.pages.Interfaces.View.Empty':
    'Interface is currently empty. Enter edit mode and add a component',
  'app.pages.Interfaces.View.openMenu': 'Open menu',
  'app.pages.Interfaces.Edit': 'Edit interface',
  'app.pages.Interfaces.Edit.Menu.componentConfig': 'Component configuration',
  'app.pages.Interfaces.Edit.Menu.newComponent': 'New component',
  'app.pages.Interfaces.Edit.Menu.title': 'Title',
  'app.pages.Interfaces.Edit.Menu.chartEvents': 'Events',
  'app.pages.Interfaces.Edit.Menu.data': 'Data',
  'app.pages.Interfaces.Edit.Menu.indexSync': 'Index sync',
  'app.pages.Interfaces.Edit.Menu.styleEditor': 'Styles',
  'app.pages.Interfaces.Edit.Menu.icon': 'Icon',
  'app.pages.Interfaces.Edit.Menu.advancedPivot': 'Advanced pivot',
  'app.pages.Interfaces.Edit.Menu.layoutProperties': 'Layout',
  'app.pages.Interfaces.Edit.Menu.valueFormat': 'Value format',
  'app.pages.Interfaces.Edit.Menu.valueFormatSecondaryAxis': 'Value format - Secondary axis',
  'app.pages.Interfaces.Edit.Menu.conditionalFormat': 'Conditional format',
  'app.pages.Interfaces.Edit.Menu.cellProperties': 'Cell properties',
  'app.pages.Interfaces.Edit.Menu.progressBar': 'Progress bar',
  'app.pages.Interfaces.Edit.Menu.heatmap': 'Heatmap',
  'app.pages.Interfaces.Edit.Menu.componentStyle': 'Component',
  'app.pages.Interfaces.Edit.Menu.general': 'General',
  'app.pages.Interfaces.Edit.Menu.scenarios': 'Scenarios',
  'app.pages.Interfaces.Edit.Menu.columnsToShow': 'Columns to show',
  'app.pages.Interfaces.Edit.Menu.confirmationDialog': 'Confirmation dialog',
  'app.pages.Interfaces.Edit.Menu.showConfirmationDialog': 'Show dialog',
  'app.pages.Interfaces.Edit.Menu.confirmationDialogMessage': 'Message',
  'app.pages.Interfaces.Edit.Menu.formProperties': 'Form properties',
  'app.pages.Interfaces.Edit.Menu.validations': 'Validations',
  'app.pages.Interfaces.Edit.Menu.inputCubeProperties': 'Input cube properties',
  'app.pages.Interfaces.Edit.selectNode': 'Select a node to create a new component type',
  'app.pages.Interfaces.Edit.nodeType': 'Node type',
  'app.pages.Interfaces.Edit.selectedNodeId': 'Selected node id',
  'app.pages.Interfaces.Edit.refresh': 'Refresh interface',
  'app.pages.Interfaces.Edit.copy': 'Copy interface',
  'app.pages.Interfaces.Edit.hidePanel': 'Hide panel',
  'app.pages.Interfaces.Edit.showPanel': 'Show panel',
  'app.pages.Interfaces.Edit.removeComponent': 'Remove component',
  'app.pages.Interfaces.Edit.remove': 'Remove',
  'app.pages.Interfaces.Edit.removeConfirmationText':
    'Are you sure you want to remove this component?',
  'app.pages.Interfaces.Edit.discardUnsavedChanges': 'Discard unsaved changes?',
  'app.pages.Interfaces.Edit.discardUnsavedChangesText':
    'Are you sure you want to discard the unsaved changes?',
  'app.pages.Interfaces.Edit.showInterfaceCode': 'Show interface code',
  'app.pages.Interfaces.Edit.interfaceCodeEditor': 'Interface code editor',
  'app.pages.Interfaces.Edit.closeComponentCodeEditor': 'Close component code editor',
  'app.pages.Interfaces.Edit.customComponentCode': 'Custom component code',
  'app.pages.Interfaces.Edit.HTMLEditor': 'HTML editor',
  'app.pages.Interfaces.Edit.menuEditor': 'Menu editor',
  'app.pages.Interfaces.Edit.title.enabled': 'Enabled',
  'app.pages.Interfaces.Edit.title.editTitle': 'Edit title',
  'app.pages.Interfaces.Edit.title.customTitle': 'Custom title',
  'app.pages.Interfaces.Edit.table.size.rowsPerPage': 'Rows per page',
  'app.pages.Interfaces.Edit.table.size.columnsPerPage': 'Columns per page',
  'app.pages.Interfaces.Edit.table.data.showRowTotals': 'Show row totals',
  'app.pages.Interfaces.Edit.table.data.rowTotalsPosition': 'Row totals position',
  'app.pages.Interfaces.Edit.table.data.showColumnTotals': 'Show column totals',
  'app.pages.Interfaces.Edit.table.data.columnTotalsPosition': 'Column totals position',
  'app.pages.Interfaces.Edit.table.data.compareScenarios': 'Compare scenarios',
  'app.pages.Interfaces.Edit.table.data.disableCompareScenarios': 'Disable compare scenarios',
  'app.pages.Interfaces.Edit.table.data.showValues': 'Show values',
  'app.pages.Interfaces.Edit.table.data.showDifference': 'Show difference',
  'app.pages.Interfaces.Edit.table.data.showPercentageDifference': 'Show percentage difference',
  'app.pages.Interfaces.Edit.table.data.differenceEndText': 'Difference end text',
  'app.pages.Interfaces.Edit.table.data.percentageDifferenceEndText':
    'Percentage difference end text',
  'app.pages.Interfaces.Edit.table.data.columnsProperties': 'Columns properties',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData': 'Hide empty data',
  'app.pages.Interfaces.Edit.table.data.totalsPositions.end': 'End',
  'app.pages.Interfaces.Edit.table.data.totalsPositions.beginning': 'Beginning',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.column': 'Column',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.row': 'Row',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.both': 'Both',
  'app.pages.Interfaces.Edit.table.data.showColumnFilterIcon': 'Show column filter icon',
  'app.pages.Interfaces.Edit.table.data.hideFooter': 'Hide footer',
  'app.pages.Interfaces.Edit.table.data.pagination': 'Pagination',
  'app.pages.Interfaces.Edit.events.selectedEvent': 'Selection event',
  'app.pages.Interfaces.Edit.events.hoverEvent': 'Hover event',
  'app.pages.Interfaces.Edit.events.drilldownEvent': 'Drilldown event',
  'app.pages.Interfaces.Edit.pivot': 'Pivot',
  'app.pages.Interfaces.Edit.filter': 'Filter',
  'app.pages.Interfaces.Edit.dataHandling': 'Data handling',
  'app.pages.Interfaces.Edit.appliedFiltersOn': 'Applied filters on',
  'app.pages.Interfaces.Edit.style.fontSize': 'Font size',
  'app.pages.Interfaces.Edit.style.fontStyle': 'Font style',
  'app.pages.Interfaces.Edit.style.fontStyle.bold': 'Bold',
  'app.pages.Interfaces.Edit.style.fontStyle.italic': 'Italic',
  'app.pages.Interfaces.Edit.style.fontStyle.underline': 'Underline',
  'app.pages.Interfaces.Edit.style.fontColor': 'Font color',
  'app.pages.Interfaces.Edit.style.backgroundColor': 'Background color',
  'app.pages.Interfaces.Edit.style.headerBackgroundColor': 'Header background color',
  'app.pages.Interfaces.Edit.style.headerColor': 'Header color',
  'app.pages.Interfaces.Edit.style.textAlign': 'Text align',
  'app.pages.Interfaces.Edit.style.textAlign.left': 'Left',
  'app.pages.Interfaces.Edit.style.textAlign.center': 'Center',
  'app.pages.Interfaces.Edit.style.textAlign.right': 'Right',
  'app.pages.Interfaces.Edit.style.textAlign.justify': 'Justify',
  'app.pages.Interfaces.Edit.style.hideHeader': 'Hide header',
  'app.pages.Interfaces.Edit.style.hideBorder': 'Hide border',
  'app.pages.Interfaces.Edit.style.hideMaximizeButton': 'Hide maximize button',
  'app.pages.Interfaces.Edit.style.hideDataHandlingIcon': 'Hide data handling icon',
  'app.pages.Interfaces.Edit.style.note': '(applies in display mode)',
  'app.pages.Interfaces.Edit.style.setDefaultColor': 'Set default color',
  'app.pages.Interfaces.Edit.style.applyAndClose': 'Apply and close',
  'app.pages.Interfaces.Edit.style.seriesColors': 'Series colors',
  'app.pages.Interfaces.Edit.style.colorPalette.increasing': 'Increasing',
  'app.pages.Interfaces.Edit.style.colorPalette.decreasing': 'Decreasing',
  'app.pages.Interfaces.Edit.layout.style.axesTitles': 'Axes titles',
  'app.pages.Interfaces.Edit.layout.style.showYAxisTitle': 'Show Y axis title',
  'app.pages.Interfaces.Edit.layout.style.showXAxisTitle': 'Show X axis title',
  'app.pages.Interfaces.Edit.layout.color_palette': 'Color palette',
  'app.pages.Interfaces.Edit.layout.color_palette.color_continuous_scale': 'Continuous scale',
  'app.pages.Interfaces.Edit.layout.color_palette.color_discrete_sequence': 'Discrete sequence',
  'app.pages.Interfaces.Edit.layout.legend': 'Legend',
  'app.pages.Interfaces.Edit.layout.legend.showlegend': 'Show legend',
  'app.pages.Interfaces.Edit.layout.legend.toggleOthersWithSingleClick':
    'Toggle others with single click',
  'app.pages.Interfaces.Edit.layout.legend.coloraxis_showscale': 'Show scale',
  'app.pages.Interfaces.Edit.layout.legend.orientation': 'Orientation',
  'app.pages.Interfaces.Edit.layout.legend.xanchor': 'X anchor point',
  'app.pages.Interfaces.Edit.layout.legend.x': 'X',
  'app.pages.Interfaces.Edit.layout.legend.yanchor': 'Y anchor point',
  'app.pages.Interfaces.Edit.layout.legend.y': 'Y',
  'app.pages.Interfaces.Edit.layout.margins': 'Margins',
  'app.pages.Interfaces.Edit.layout.selectValues.auto': 'Auto',
  'app.pages.Interfaces.Edit.layout.selectValues.left': 'Left',
  'app.pages.Interfaces.Edit.layout.selectValues.center': 'Center',
  'app.pages.Interfaces.Edit.layout.selectValues.right': 'Right',
  'app.pages.Interfaces.Edit.layout.selectValues.top': 'Top',
  'app.pages.Interfaces.Edit.layout.selectValues.middle': 'Middle',
  'app.pages.Interfaces.Edit.layout.selectValues.bottom': 'Bottom',
  'app.pages.Interfaces.Edit.layout.chartMode': 'Chart mode',
  'app.pages.Interfaces.Edit.layout.chartMode.modePreset': 'Mode preset',
  'app.pages.Interfaces.Edit.layout.chartMode.stack': 'Stack',
  'app.pages.Interfaces.Edit.layout.chartMode.relative': 'Relative',
  'app.pages.Interfaces.Edit.layout.chartMode.group': 'Group',
  'app.pages.Interfaces.Edit.layout.chartMode.overlay': 'Overlay',
  'app.pages.Interfaces.Edit.layout.chartMode.barmode': 'Bar mode',
  'app.pages.Interfaces.Edit.layout.chartMode.boxmode': 'Box mode',
  'app.pages.Interfaces.Edit.layout.chartMode.funnelmode': 'Funnel mode',
  'app.pages.Interfaces.Edit.layout.chartMode.violinmode': 'Violin mode',
  'app.pages.Interfaces.Edit.layout.chartMode.waterfallmode': 'Waterfall mode',
  'app.pages.Interfaces.Edit.layout.chartMode.percent': 'Percent',
  'app.pages.Interfaces.Edit.layout.chartNorm': 'Chart norm',
  'app.pages.Interfaces.Edit.layout.chartNorm.barnorm': 'Bar norm',
  'app.pages.Interfaces.Edit.layout.chartNorm.groupnorm': 'Group norm',
  'app.pages.Interfaces.Edit.layout.chartNorm.fraction': 'Fraction',
  'app.pages.Interfaces.Edit.layout.chartNorm.percent': 'Percent',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels': 'Labels',
  'app.pages.Interfaces.Edit.layout.chartNorm.labelsSecondaryAxis': 'Labels - Secondary axis',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.showLabels': 'Show labels',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.labelField': 'Field',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.minSize': 'Minimum size',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.mode': 'Mode',
  'app.pages.Interfaces.Edit.layout.customSeriesColors': 'Custom series colors',
  'app.pages.Interfaces.Edit.layout.customName': 'Custom name',
  'app.pages.Interfaces.Edit.layout.addValue': 'Add value',
  'app.pages.Interfaces.Edit.layout.addColor': 'Add color',
  'app.pages.Interfaces.Edit.layout.generateValuesFromColumn': 'Generate values from column',
  'app.pages.Interfaces.Edit.layout.y_axis_range': 'Y-axis range',
  'app.pages.Interfaces.Edit.valueFormat.formatPreset': 'Format preset',
  'app.pages.Interfaces.Edit.valueFormat.number': 'Number',
  'app.pages.Interfaces.Edit.valueFormat.decimal': 'Decimal',
  'app.pages.Interfaces.Edit.valueFormat.integer': 'Integer',
  'app.pages.Interfaces.Edit.valueFormat.percent': 'Percent',
  'app.pages.Interfaces.Edit.valueFormat.currency': 'Currency',
  'app.pages.Interfaces.Edit.valueFormat.currencyRounded': 'Currency (rounded)',
  'app.pages.Interfaces.Edit.valueFormat.date': 'Date',
  'app.pages.Interfaces.Edit.valueFormat.datetime': 'Date time',
  'app.pages.Interfaces.Edit.valueFormat.thousandsSeparator': 'Thousands separator',
  'app.pages.Interfaces.Edit.valueFormat.decimalPlaces': 'Decimals',
  'app.pages.Interfaces.Edit.valueFormat.prefix': 'Prefix',
  'app.pages.Interfaces.Edit.valueFormat.suffix': 'Suffix',
  'app.pages.Interfaces.Edit.valueFormat.dateFormat': 'Date format',
  'app.pages.Interfaces.Edit.valueFormat.datetimeFormat': 'Datetime format',
  'app.pages.Interfaces.Edit.valueFormat.seePatternReference': 'See pattern reference',
  'app.pages.Interfaces.Run.runningButton': 'Running button',
  'app.pages.Interfaces.Edit.editTitle.showUnits': 'Show units',
  'app.pages.Interfaces.Edit.progressBar.applyProgressBar': 'Apply progress bar',
  'app.pages.Interfaces.Edit.progressBar.max_value': 'Max value',
  'app.pages.Interfaces.Edit.progressBar.min_hue': 'Min hue',
  'app.pages.Interfaces.Edit.progressBar.max_hue': 'Max hue',
  'app.pages.Interfaces.Edit.progressBar.saturation': 'Saturation',
  'app.pages.Interfaces.Edit.progressBar.lightness': 'Lightness',
  'app.pages.Interfaces.Edit.progressBar.show_value': 'Show value',
  'app.pages.Interfaces.Edit.heatmap.applyHeatmap': 'Apply heatmap',
  'app.pages.Interfaces.Edit.heatmap.by': 'By',
  'app.pages.Interfaces.Edit.heatmap.hex_base_background_color': 'Base background color',
  'app.pages.Interfaces.Edit.heatmap.hex_text_color': 'Text color',
  'app.pages.Interfaces.Edit.heatmap.exclude_nans': 'Exclude NaNs',
  'app.pages.Interfaces.Edit.heatmap.exclude_right_total': 'Exclude row totals',
  'app.pages.Interfaces.Edit.heatmap.exclude_bottom_total': 'Exclude column totals',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor': 'Heatmap color',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_green_alt': 'red-green-alt',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_red_alt': 'green-red-alt',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_green': 'red-green',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_red': 'green-red',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_white': 'red-white',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.white_red': 'white-red',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.white_green': 'white-green',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_white': 'green-white',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_blue': 'green-blue',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.blue_green': 'blue-green',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_blue': 'red-blue',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.blue_red': 'blue-red',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.custom': 'Custom',
  'app.pages.Interfaces.Edit.conditionalFormat.addCondition': 'Add condition',
  'app.pages.Interfaces.Edit.conditionalFormat.deleteAll': 'Delete all',
  'app.pages.Interfaces.Edit.conditionalFormat.Conditions': 'Conditions',
  'app.pages.Interfaces.Edit.conditionalFormat.showIconOnly': 'Show icon only',
  'app.pages.Interfaces.Edit.style.addNewStyle': 'Add new style',
  'app.pages.Interfaces.Edit.style.deleteStyle': 'Delete style',
  'app.pages.Interfaces.Edit.style.editStyle': 'Edit style',
  'app.pages.Interfaces.Edit.style.dimensionName': 'Dimension name',
  'app.pages.Interfaces.Edit.style.filterType': 'Filter type',
  'app.pages.Code.Diagram.Wizards.styleConditions': 'Style conditions',
  'app.pages.Interfaces.Edit.colorPicker.themeColors': 'Theme colors',
  'app.pages.Interfaces.Edit.colorPicker.standardColors': 'Standard colors',
  'app.pages.Interfaces.Edit.colorPicker.recentColors': 'Recent colors',
  'app.pages.Interfaces.Edit.colorPicker.selectedColor': 'Selected color',
  'app.pages.Interfaces.Edit.leaveInterfaceTitle': 'Interface with unconfirmed changes',
  'app.pages.Interfaces.Edit.leaveInterfaceContent':
    'Do you want to confirm changes or discard them?',
  'app.pages.Interfaces.Edit.inputNodeProperties.hideIdColumn': 'Hide ID column',
  'app.pages.Interfaces.Edit.inputNodeProperties.confirmOnChange': 'Confirm on change',
  'app.pages.Interfaces.Edit.inputNodeProperties.invalidateOutputsOnChange':
    'Invalidate outputs on change',
  'app.pages.Interfaces.Edit.inputNodeProperties.invalidateOutputsOnConfirm':
    'Invalidate outputs on confirm',
  'app.pages.Interfaces.Edit.inputNodeProperties.updateCalcColumnsOnChange':
    'Update calculated columns on change',
  'app.pages.Interfaces.Edit.inputNodeProperties.updateCalcColumnsOnConfirm':
    'Update calculated columns on confirm',
  'app.pages.Interfaces.Edit.inputNodeProperties.showSelectColumnsIcon': 'Show Select columns icon',
  'app.pages.Interfaces.Edit.inputNodeProperties.showEditExcelIcon': 'Show Edit in Excel icon',
  'app.pages.Interfaces.Edit.filterIndexValues': 'Filter index values',
  'app.pages.Interfaces.Edit.filterIndexValues.fromToError':
    'The "From" value should be less than or equal to the "To" value.',
  'app.pages.Interfaces.Edit.style.applyConditionTo': 'Apply condition to',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.showDefaultView': 'Show default view',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customView': 'Custom view',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.editingCustomView':
    'You are editing the custom view of this interface',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.showCustomView': 'Show custom view',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.saveCustomView': 'Save custom view',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.deleteCustomView': 'Delete custom view',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customViewSaved':
    'The custom view of the interface has been saved successfully',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customViewDeleted':
    'The custom view of the interface has been deleted successfully',

  //Instance manager
  'app.pages.managers.instances': 'Instances',
  'app.pages.app.instanceManager.noInstances': 'There are no instances',
  'app.pages.app.instanceManager.open': 'Open',
  'app.pages.app.instanceManager.currentApplication': 'Current application',
  'app.pages.app.instanceManager.isPool': 'Is pool',
  'app.pages.app.instanceManager.active': 'Active',
  'app.pages.app.instanceManager.timeoutIn': 'Timeout in',
  'app.pages.app.instanceManager.minutes': ' min',
  'app.pages.app.instanceManager.cpu': 'CPU',
  'app.pages.app.instanceManager.memory': 'Memory',
  'app.pages.app.instanceManager.peak': 'Peak',
  'app.pages.app.instanceManager.apiResources': `Application's resources`,
  'app.pages.app.instanceManager.systemMemory': 'Total',
  'app.pages.app.instanceManager.inUse': 'In use',
  'app.pages.app.instanceManager.disk': 'Disk',
  'app.pages.app.instanceManager.time': 'Time',
  'app.pages.app.instanceManager.selectedInstance': 'Selected instance',
  'app.pages.app.instanceManager.pid': 'PID',
  'app.pages.app.instanceManager.memoryPeak': 'Memory peak',
  'app.pages.app.instanceManager.lastProcessedNode': 'Last processed node',
  'app.pages.app.instanceManager.requireLogoutTitle': 'Logout required',
  'app.pages.app.instanceManager.requireLogoutMessage':
    'Are you sure you want to send a request for logout?',
  'app.pages.app.instanceManager.killInstanceTitle': 'Kill instance',
  'app.pages.app.instanceManager.killInstanceMessage':
    'Unsaved instance data will be lost. Are you sure?',
  'app.pages.app.instanceManager.requestSent': 'Request sent',
  'app.pages.app.instanceManager.instanceClosed': 'Instance closed',
  'app.pages.app.instanceManager.editionAvailable': 'Edition available',
  'app.pages.app.instanceManager.processing': 'Processing',
  'app.pages.app.instanceManager.info': 'Info',
  'app.pages.app.instanceManager.openAppsOnly': 'Open apps only',
  'app.pages.app.instanceManager.noSelectedInstance': 'There is no selected instance',
  'app.pages.app.instanceManager.scheduledTask': 'Scheduled task',

  //Log manager
  'app.pages.managers.logs': 'Logs',
  'app.pages.managers.logManager.startDate': 'Start date',
  'app.pages.managers.logManager.endDate': 'End date',
  'app.pages.managers.logManager.instanceKey': 'Instance',
  'app.pages.managers.logManager.user': 'User',
  'app.pages.managers.logManager.user.notFound': 'User not found',
  'app.pages.managers.logManager.user.selectUser': 'Select or search an user',
  'app.pages.managers.logManager.action': 'Action',
  'app.pages.managers.logManager.action.notFound': 'Action not found',
  'app.pages.managers.logManager.action.selectAction': 'Select or search an action',
  'app.pages.managers.logManager.model': 'Model',
  'app.pages.managers.logManager.downloadReport': 'Download report',
  'app.pages.managers.logManager.reportDownloaded': 'Report downloaded',
  'app.pages.managers.logManager.clearFilters': 'Clear filters',
  'app.pages.managers.logManager.filter': 'Filter',
  'app.pages.managers.logManager.dateFilterHelperText': 'You can filter a maximum of {name} days',
  'app.pages.managers.logManager.table.date': 'Date',
  'app.pages.managers.logManager.table.query': 'Query',
  'app.pages.managers.logManager.table.body': 'Body',

  //Interface toolbar
  'app.pages.interfaces.toolbar.cancel': 'Cancel',
  'app.pages.interfaces.toolbar.mode': 'Mode',
  'app.pages.interfaces.toolbar.mode.single': 'Single select',
  'app.pages.interfaces.toolbar.mode.multi': 'Multi select',
  'app.pages.interfaces.toolbar.format': 'Format',
  'app.pages.interfaces.toolbar.menu.alignment': 'Alignment',
  'app.pages.interfaces.toolbar.format.small': 'Small',
  'app.pages.interfaces.toolbar.format.hidden': 'Hidden',
  'app.pages.interfaces.toolbar.format.range': 'Range',
  'app.pages.interfaces.toolbar.format.select': 'Select',
  'app.pages.interfaces.toolbar.format.radio': 'Radio',
  'app.pages.interfaces.toolbar.format.slider': 'Slider',
  'app.pages.interfaces.toolbar.format.selected_chips': 'Selected chips',
  'app.pages.interfaces.toolbar.format.clickable_chips': 'Clickable chips',
  'app.pages.interfaces.toolbar.orientation': 'Orientation',
  'app.pages.interfaces.toolbar.orientation.horizontal': 'Horizontal',
  'app.pages.interfaces.toolbar.orientation.vertical': 'Vertical',
  'app.pages.interfaces.toolbar.chartType': 'Chart type',
  'app.pages.interfaces.toolbar.chartSubtype': 'Chart subtype',
  'app.pages.interfaces.toolbar.chartType.bar': 'Bar',
  'app.pages.interfaces.toolbar.chartType.column': 'Column',
  'app.pages.interfaces.toolbar.chartType.normal': 'Normal',
  'app.pages.interfaces.toolbar.chartType.stacked': 'Stacked',
  'app.pages.interfaces.toolbar.chartType.percentStacked': 'Percent stacked',
  'app.pages.interfaces.toolbar.chartType.line': 'Line',
  'app.pages.interfaces.toolbar.chartType.linesOnly': 'Lines only',
  'app.pages.interfaces.toolbar.chartType.linesAndPoints': 'Lines and points',
  'app.pages.interfaces.toolbar.chartType.pointsOnly': 'Points only',
  'app.pages.interfaces.toolbar.chartType.pie': 'Pie',
  'app.pages.interfaces.toolbar.chartType.donut': 'Donut',
  'app.pages.interfaces.toolbar.chartType.sunburst': 'Sunburst',
  'app.pages.interfaces.toolbar.chartType.area': 'Area',
  'app.pages.interfaces.toolbar.chartType.percent': 'Percent',
  'app.pages.interfaces.toolbar.chartType.scatter': 'Scatter',
  'app.pages.interfaces.toolbar.chartType.combined': 'Combined',
  'app.pages.interfaces.toolbar.chartType.scatterWithTrendline': 'Scatter with trendline',
  'app.pages.interfaces.toolbar.chartType.others': 'Others',
  'app.pages.interfaces.toolbar.chartType.waterfall': 'Waterfall',
  'app.pages.interfaces.toolbar.chartType.funnel': 'Funnel',
  'app.pages.interfaces.toolbar.chartType.timeline': 'Timeline',
  'app.pages.interfaces.toolbar.chartType.treemap': 'Treemap',
  'app.pages.interfaces.toolbar.chartType.icicle': 'Icicle',
  'app.pages.interfaces.toolbar.chartType.funnelArea': 'Funnel area',
  'app.pages.interfaces.toolbar.chartType.histogram': 'Histogram',
  'app.pages.interfaces.toolbar.chartType.box': 'Box',
  'app.pages.interfaces.toolbar.chartType.violin': 'Violin',
  'app.pages.interfaces.toolbar.chartType.strip': 'Strip',
  'app.pages.interfaces.toolbar.chartType.densityHeatmap': 'Density heatmap',
  'app.pages.interfaces.toolbar.chartType.densityContour': 'Density contour',
  'app.pages.interfaces.toolbar.chartType.scatter3d': 'Scatter 3D',
  'app.pages.interfaces.toolbar.chartType.line3d': 'Line 3D',
  'app.pages.interfaces.toolbar.chartType.scatterMatrix': 'Scatter matrix',
  'app.pages.interfaces.toolbar.chartType.parallelCoordinates': 'Parallel coordinates',
  'app.pages.interfaces.toolbar.chartType.parallelCategories': 'Parallel categories',
  'app.pages.interfaces.toolbar.chartType.scatterMapbox': 'Scatter mapbox',
  'app.pages.interfaces.toolbar.chartType.lineMapbox': 'Line mapbox',
  'app.pages.interfaces.toolbar.chartType.choroplethMapbox': 'Choropleth mapbox',
  'app.pages.interfaces.toolbar.chartType.densityMapbox': 'Density mapbox',
  'app.pages.interfaces.toolbar.chartType.scatterPolar': 'Scatter polar',
  'app.pages.interfaces.toolbar.chartType.linePolar': 'Line polar',
  'app.pages.interfaces.toolbar.chartType.barPolar': 'Bar polar',
  'app.pages.interfaces.toolbar.chartType.scatterTernary': 'Scatter ternary',
  'app.pages.interfaces.toolbar.chartType.lineTernary': 'Line ternary',
  'app.pages.interfaces.toolbar.chartType.groups.basics': 'Basics',
  'app.pages.interfaces.toolbar.chartType.groups.financial': 'Financial',
  'app.pages.interfaces.toolbar.chartType.groups.partOfWhole': 'Part-of-whole',
  'app.pages.interfaces.toolbar.chartType.groups.1dDistributions': '1D distributions',
  'app.pages.interfaces.toolbar.chartType.groups.2dDistributions': '2D distributions',
  'app.pages.interfaces.toolbar.chartType.groups.3dimensional': '3-Dimensional',
  'app.pages.interfaces.toolbar.chartType.groups.multidimensional': 'Multidimensional',
  'app.pages.interfaces.toolbar.chartType.groups.tileMaps': 'Tile maps',
  'app.pages.interfaces.toolbar.chartType.groups.polarCharts': 'Polar charts',
  'app.pages.interfaces.toolbar.chartType.groups.ternaryCharts': 'Ternary charts',
  'app.pages.interfaces.toolbar.showComponentCode': 'Show component code',
  'app.pages.interfaces.toolbar.openComponentCode': 'Open component code',
  'app.pages.interfaces.toolbar.componentCodeEditor': 'Component code editor',
  'app.pages.interfaces.toolbar.menuFormat': 'Menu format',
  'app.pages.interfaces.toolbar.menuFormat.accordion': 'Accordion',
  'app.pages.interfaces.toolbar.menuFormat.boxes': 'Boxes',
  'app.pages.interfaces.toolbar.menuFormat.mini': 'Mini',
  'app.pages.interfaces.toolbar.menu.newItem': 'New item',
  'app.pages.interfaces.toolbar.menu.newAction': 'New action',
  'app.pages.interfaces.toolbar.menu.addItem': 'Add menu item',
  'app.pages.interfaces.toolbar.menu.addAction': 'Add action',
  'app.pages.interfaces.toolbar.menu.removeItem': 'Remove item',
  'app.pages.interfaces.toolbar.menu.removeAction': 'Remove action',
  'app.pages.interfaces.toolbar.menu.root': 'Root',
  'app.pages.interfaces.toolbar.menu.actionType': 'Action type',
  'app.pages.interfaces.toolbar.menu.navigateTo': 'Navigate to',
  'app.pages.interfaces.toolbar.menu.target': 'Target',
  'app.pages.interfaces.toolbar.menu.currentTab': 'Current tab',
  'app.pages.interfaces.toolbar.menu.newTab': 'New tab',
  'app.pages.interfaces.toolbar.menu.expanded': 'Expanded',
  'app.pages.interfaces.toolbar.menu.subtitle': 'Subtitle',
  'app.pages.interfaces.toolbar.menu.openInterface': 'Open interface',
  'app.pages.interfaces.toolbar.menu.navigate': 'Navigate',
  'app.pages.interfaces.toolbar.menu.textColor': 'Text color',
  'app.pages.interfaces.toolbar.menu.iconColor': 'Icon color',
  'app.pages.interfaces.toolbar.menu.backgroundColor': 'Background color',
  'app.pages.interfaces.toolbar.menu.hoverColor': 'Hover color',
  'app.pages.interfaces.componentHasCustomCodeMessage':
    'The component has a custom code definition. Switch to default code definition to enable toolbar properties.',
  'app.pages.interfaces.toolbar.openHtmlEditor': 'Open HTML editor',
  'app.pages.interfaces.toolbar.openDynamicHtmlEditor': 'Open dynamic HTML editor',
  'app.pages.interfaces.toolbar.dynamicHtmlCustomization': 'Dynamic HTML customization',
  'app.pages.interfaces.toolbar.selectSectionToGo': 'Select section to go',
  'app.pages.interfaces.toolbar.selectRunAppNode': 'Select run app node',
  'app.pages.interfaces.toolbar.openInNewTab': 'Open in new tab',
  'app.pages.interfaces.toolbar.openMenuEditor': 'Open menu editor',
  'app.pages.interfaces.toolbar.autoOpenSingleAction': 'Automatically open single action',
  'app.pages.interfaces.toolbar.exportComponent': 'Export component',
  'app.pages.interfaces.toolbar.fileFormat': 'File format',
  'app.pages.interfaces.toolbar.numberFormat': 'Number format',
  'app.pages.interfaces.toolbar.columnSeparator': 'Column separator',
  'app.pages.interfaces.toolbar.compressToDownload': 'Compress file to download',
  'app.pages.interfaces.toolbar.excludeNullAndZero': 'Exclude null and zero values',
  'app.pages.interfaces.toolbar.fullNode': 'Full node',
  'app.pages.interfaces.toolbar.currentView': 'Current view',
  'app.pages.interfaces.toolbar.multiline': 'Multiline',
  'app.pages.interfaces.toolbar.uppercase': 'Uppercase',
  'app.pages.interfaces.toolbar.fixedPositionOnTop': 'Fixed position on top',
  'app.pages.interfaces.toolbar.openFilterEditor': 'Open filter editor',
  'app.pages.interfaces.chart': 'Chart',
  'app.pages.interfaces.indicator': 'Indicator',
  'app.pages.interfaces.interfaceDeleted': 'Interface deleted',
  'app.pages.interfaces.folderDeleted': 'Folder deleted',
  'app.pages.interfaces.interfaceDuplicated': 'Interface duplicated',
  'app.pages.interfaces.folderDuplicated': 'Folder duplicated',
  'app.pages.interfaces.viewAsChart': 'View as chart',
  'app.pages.interfaces.viewAsTable': 'View as table',
  'app.pages.interfaces.defaultInterface': 'Default interface',
  'app.pages.interfaces.closeAllTabs': 'Close all tabs',
  'app.pages.interfaces.closeSelectedTab': 'Close selected tab',
  'app.pages.interfaces.openTabActions': 'Open tab actions',
  'app.pages.interfaces.openDefaultInterface': 'Open default interface',
  'app.pages.interfaces.closeAllButThis': 'Close all but this',
  'app.pages.interfaces.importInterface': 'Import interface',
  'app.pages.interfaces.interfaceImportedSuccessfully': 'Interface imported successfully',
  'app.pages.interfaces.exportInterfaceSuccess': 'Interface exported successfully',
  'app.pages.interfaces.interfaceCopied': 'Interface copied successfully',
  'app.pages.interfaces.interfaceMoved': 'Interface moved successfully',
  'app.pages.interfaces.deleteFolder': 'Delete folder',
  'app.pages.interfaces.deleteInterfaces': 'Delete interfaces',
  'app.pages.interfaces.folderCopied': 'Folder copied successfully',
  'app.pages.interfaces.folderMoved': 'Folder moved successfully',
  'app.pages.interfaces.interfacePermissionChanged': 'Interface permissions changed',
  'app.pages.interfaces.htmlPreview': 'HTML preview',
  'app.pages.interfaces.attribute': 'Attribute',
  'app.pages.interfaces.attributeValue': 'Attribute value',
  'app.pages.interfaces.typeActionValue': 'Type action value',
  'app.pages.interfaces.typeActionValue.modifyTypeActionValue': 'Modify type action value',
  'app.pages.interfaces.runAppNode': 'Run app node',
  'app.pages.interfaces.attributesFromNode': 'Attributes from node',
  'app.pages.interfaces.childrenFromNode': 'Children from node',
  'app.pages.interfaces.addNodeResultLikeChildren': 'Add node result like children element',
  'app.pages.interfaces.getAttributesFromNode': 'Get attributes from node',
  'app.pages.interfaces.targetAttribute': 'Target attribute',
  'app.pages.interfaces.targetAttributeValue': 'Target attribute value',
  'app.pages.interfaces.toolbar.runNodeToPreviewResult': 'Run node to preview result',
  'app.pages.interfaces.toolbar.insertHereYourHtmlCode': 'Insert here your HTML code',
  'app.pages.interfaces.toolbar.previewAvailableAfterTypingCode':
    'Preview available after typing code',
  'app.pages.interfaces.toolbar.runPreviewHtml': 'Run preview HTML',
  'app.pages.interfaces.toolbar.inputValue.dataType': 'Data type',
  'app.pages.interfaces.toolbar.inputValue.rule': 'Rule',
  'app.pages.interfaces.toolbar.inputValue.rule.notRange': 'Not in range',
  'app.pages.interfaces.toolbar.inputValue.rule.textLength': 'Text length',
  'app.pages.interfaces.toolbar.inputValue.rule.any': 'Any',
  'app.pages.interfaces.toolbar.dynamicHtml.openAssistant': 'Open assistant',
  'app.pages.interfaces.toolbar.dynamicHtml.inserAssistantId': 'Insert assistant id',

  // Interface pivot
  'app.pages.interfaces.pivot.x': 'X',
  'app.pages.interfaces.pivot.y': 'Y',
  'app.pages.interfaces.pivot.z': 'Z',
  'app.pages.interfaces.pivot.facet_row': 'Facet row',
  'app.pages.interfaces.pivot.facet_column': 'Facet column',
  'app.pages.interfaces.pivot.text': 'Text',
  'app.pages.interfaces.pivot.animation_frame': 'Animation frame',
  'app.pages.interfaces.pivot.animation_group': 'Animation group',
  'app.pages.interfaces.pivot.error_x': 'Error X',
  'app.pages.interfaces.pivot.error_x_minus': 'Error X minus',
  'app.pages.interfaces.pivot.error_y': 'Error Y',
  'app.pages.interfaces.pivot.error_y_minus': 'Error Y minus',
  'app.pages.interfaces.pivot.error_z': 'Error Z',
  'app.pages.interfaces.pivot.error_z_minus': 'Error Z minus',
  'app.pages.interfaces.pivot.latitude': 'Latitude',
  'app.pages.interfaces.pivot.longitude': 'Longitude',
  'app.pages.interfaces.pivot.r': 'R',
  'app.pages.interfaces.pivot.theta': 'Theta',
  'app.pages.interfaces.pivot.measure_1': 'Measure 1',
  'app.pages.interfaces.pivot.measure_2': 'Measure 2',
  'app.pages.interfaces.pivot.measure_3': 'Measure 3',
  'app.pages.interfaces.pivot.color': 'Color',
  'app.pages.interfaces.pivot.size': 'Size',
  'app.pages.interfaces.pivot.base': 'Base',
  'app.pages.interfaces.pivot.symbol': 'Symbol',
  'app.pages.interfaces.pivot.line_group': 'Line group',
  'app.pages.interfaces.pivot.line_dash': 'Line dash',
  'app.pages.interfaces.pivot.pattern_shape': 'Pattern shape',
  'app.pages.interfaces.pivot.dimensions': 'Dimensions',
  'app.pages.interfaces.pivot.locations': 'Locations',
  'app.pages.interfaces.pivot.names': 'Names',
  'app.pages.interfaces.pivot.values': 'Values',
  'app.pages.interfaces.pivot.x_start': 'X start',
  'app.pages.interfaces.pivot.x_end': 'X end',
  'app.pages.interfaces.pivot.path': 'Path',
  'app.pages.interfaces.pivot.parents': 'Parents',
  'app.pages.interfaces.pivot.ids': 'IDs',
  'app.pages.interfaces.pivot.chart_type_1': 'Chart type 1',
  'app.pages.interfaces.pivot.chart_type_2': 'Chart type 2',
  'app.pages.interfaces.pivot.dimension': 'Dimension',
  'app.pages.interfaces.pivot.measure': 'Measure',
  'app.pages.interfaces.pivot.rows': 'Rows',
  'app.pages.interfaces.pivot.columns': 'Columns',
  'app.pages.interfaces.pivot.measures': 'Measures',
  'app.pages.interfaces.pivot.series': 'Series',
  'app.pages.interfaces.pivot.sum': 'Sum',
  'app.pages.interfaces.pivot.avg': 'Average',
  'app.pages.interfaces.pivot.min': 'Min',
  'app.pages.interfaces.pivot.max': 'Max',
  'app.pages.interfaces.pivot.count': 'Count',
  'app.pages.interfaces.pivot.': 'None',
  'app.pages.interfaces.pivot.agg': 'Aggregate',
  'app.pages.interfaces.pivot.newMeasure': 'New measure',
  'app.pages.interfaces.pivot.newMeasureName': 'New measure name',
  'app.pages.interfaces.pivot.selectHierarchy': 'Select hierarchy',
  'app.pages.interfaces.pivot.editCalculatedMeasure': 'Edit calculated measure',
  'app.pages.interfaces.pivot.removeCalculatedMeasure': 'Remove calculated measure',
  'app.pages.interfaces.pivot.removeCalculatedMeasureMessage':
    'Are you sure you want to remove "{field}"?',
  'app.pages.interfaces.pivot.function': 'Function',
  'app.pages.interfaces.pivot.calculationMode': 'Calculation mode',
  'app.pages.interfaces.pivot.histnorm': 'Histogram normalization',
  'app.pages.interfaces.pivot.box': 'Box',
  'app.pages.interfaces.pivot.marginal': 'Marginal',
  'app.pages.interfaces.pivot.calculatedMeasure': 'Calculated measure',
  'app.pages.interfaces.pivot.calculatedItem': 'Calculated item',
  'app.pages.interfaces.pivot.editCalculatedItem': 'Edit calculated item',
  'app.pages.interfaces.pivot.removeCalculatedItem': 'Remove calculated item',
  'app.pages.interfaces.pivot.removeCalculatedItemMessage':
    'Are you sure you want to remove "{item}"?',
  'app.pages.interfaces.pivot.itemAlreadyExists': 'Item already exists',
  'app.pages.interfaces.pivot.addSubtotal': 'Add subtotal',
  'app.pages.interfaces.pivot.removeSubtotal': 'Remove subtotal',
  'app.pages.interfaces.pivot.sortAscending': 'Sort ascending',
  'app.pages.interfaces.pivot.sortDescending': 'Sort descending',
  'app.pages.interfaces.pivot.removeSorting': 'Remove sorting',

  //Code widget
  'app.pages.app.code.widget.code.saveAndRun': 'Save and Run Code',
  'app.pages.app.code.widget.code.formatCode': 'Format code',

  //Result widget
  'app.pages.app.code.widget.result.defaultview.saved':
    'The default view of node has been saved successfully',
  'app.pages.app.code.widget.result.notCalculated': 'The node is not calculated',
  'app.pages.app.code.widget.result.loading': 'Loading results..',

  //Preview widget
  'app.pages.app.code.widget.preview.resultType': 'Result type',
  'app.pages.app.code.widget.preview.indexes': 'Indexes',
  'app.pages.app.code.widget.preview.preview': 'Preview',
  'app.pages.app.code.widget.preview.clickToOpenSparkUI': 'Click here to open the spark UI',

  //Console widget
  'app.pages.app.code.widget.console.preserveLog': 'Preserve log',
  'app.pages.app.code.widget.console.clearLogs': 'Clear logs',
  'app.pages.app.code.widget.console.closeConsole': 'Close console',
  'app.pages.app.code.widget.console.showConsole': 'Show console',
  'app.pages.app.code.widget.console.clearErrors': 'Clear errors',
  'app.pages.app.code.widget.console.copyError': 'Copy error',
  'app.pages.app.code.widget.console.errorCopied': 'Error copied',
  'app.pages.app.code.widget.console.performanceAnalysis': 'Performance analysis',
  'app.pages.app.code.widget.console.performance': 'Performance',
  'app.pages.app.code.widget.console.percentOfTime': '% of time',
  'app.pages.app.code.widget.console.fromDynamic': 'From dynamic',
  'app.pages.app.code.widget.console.maxMemory': 'Max memory',
  'app.pages.app.code.widget.console.usedMemory': 'Used memory',
  'app.pages.app.code.widget.console.processingTime': 'Processing time',
  'app.pages.app.code.widget.console.enableAnalysis': 'Enable analysis',
  'app.pages.app.code.widget.console.disableAnalysis': 'Disable analysis',
  'app.pages.app.code.widget.console.clearResults': 'Clear results',
  'app.pages.app.code.widget.console.logs': 'Logs',
  'app.pages.app.code.widget.console.resetAppResults': 'Reset app results',

  //Task manager
  'app.pages.app.taskManager.task': 'Task manager',
  'app.pages.app.taskManager.addTaskScheduled': 'Add scheduled task',
  'app.pages.app.taskManager.editTaskScheduled': 'Edit scheduled task',
  'app.pages.app.taskManager.createdBy': 'Created by',
  'app.pages.app.taskManager.lastRunAt': 'Last run at',
  'app.pages.app.taskManager.nextExecution': 'Next execution',
  'app.pages.app.taskManager.lastStatus': 'Last status',
  'app.pages.app.taskManager.appPool': 'App pool',
  'app.pages.app.taskManager.schedulerType': 'Scheduler type',
  'app.pages.app.taskManager.params': 'Params',
  'app.pages.app.taskManager.enabled': 'Enabled',
  'app.pages.app.taskManager.showTaskLog': 'Show task log',
  'app.pages.app.taskManager.addAppPool': 'Add app pool',
  'app.pages.app.taskManager.interval': 'Interval',
  'app.pages.app.taskManager.clocked': 'Clocked',
  'app.pages.app.taskManager.taskDeleted': 'Task deleted',
  'app.pages.app.taskManager.deleteTask': 'Delete task {name}',
  'app.pages.app.taskManager.deleteTaskMessage': 'Are you sure you want to delete the task?',
  'app.pages.app.taskManager.taskActivated': 'Task activated',
  'app.pages.app.taskManager.taskDeactivated': 'Task deactivated',
  'app.pages.app.taskManager.taskExecuted': 'Task executed',
  'app.pages.app.taskManager.editAppPool': 'Edit app pool',
  'app.pages.app.taskManager.taskLogs': 'Task logs',
  'app.pages.app.taskManager.createdAt': 'Created at',
  'app.pages.app.taskManager.state': 'State',
  'app.pages.app.taskManager.lastTaskExecutions': 'Last task executions',
  'app.pages.app.taskManager.runTask': 'Run task',
  'app.pages.app.taskManager.advancedFilters': 'Advanced filters',
  'app.pages.app.taskManager.taskLogsDetails': 'Task logs details',
  'app.pages.app.taskManager.refreshLogs': 'Refresh logs',
  'app.pages.app.taskManager.taskDidNotRunYet': 'The task did not run yet',
  'app.pages.app.taskManager.description': 'Description',
  'app.pages.app.taskManager.activity': 'Activity',
  'app.pages.app.taskManager.info': 'Info',
  'app.pages.app.taskManager.periodicTaskLogNotFound': 'Task logs not found',
  'app.pages.app.taskManager.success': 'Success',
  'app.pages.app.taskManager.failure': 'Failure',
  'app.pages.app.taskManager.maxDateError': 'Max date cannot be before the min date',
  'app.pages.app.taskManager.minDate': 'Min date',
  'app.pages.app.taskManager.maxDate': 'Max date',
  'app.pages.app.taskManager.timeZones': 'Timezones',
  'app.pages.app.taskManager.clearForm': 'Clear form',
  'app.pages.app.taskManager.insertTheTaskName': 'Insert the task name',
  'app.pages.app.taskManager.disabled': 'Disabled',
  'app.pages.app.taskManager.days': 'Day',
  'app.pages.app.taskManager.hours': 'Hour',
  'app.pages.app.taskManager.minutes': 'Minute',
  'app.pages.app.taskManager.seconds': 'Seconds',
  'app.pages.app.taskManager.microseconds': 'Microseconds',
  'app.pages.app.taskManager.every': 'Every',
  'app.pages.app.taskManager.period': 'Period',
  'app.pages.app.taskManager.everyPlaceholder': 'Please enter an interval integer',
  'app.pages.app.taskManager.appPath': 'App path',
  'app.pages.app.taskManager.generalOptions': 'General options',
  'app.pages.app.taskManager.scheduler': 'Scheduler',
  'app.pages.app.taskManager.everyMinute': 'Every minute',
  'app.pages.app.taskManager.minutesPlaceholder': 'Select the minutes',
  'app.pages.app.taskManager.everyHour': 'Every hour',
  'app.pages.app.taskManager.hoursPlaceholder': 'Select the hours',
  'app.pages.app.taskManager.dayMonths': 'Days of month',
  'app.pages.app.taskManager.everyDayOfMonths': 'Every day of month',
  'app.pages.app.taskManager.everyMonths': 'Every month',
  'app.pages.app.taskManager.everyDays': 'Every day',
  'app.pages.app.taskManager.dayMonthsPlaceholder': 'Select the days of month',
  'app.pages.app.taskManager.months': 'Months',
  'app.pages.app.taskManager.monthsPlaceholder': 'Select the months',
  'app.pages.app.taskManager.daysPlaceholder': 'Select the days',
  'app.pages.app.taskManager.january': 'January',
  'app.pages.app.taskManager.february': 'February',
  'app.pages.app.taskManager.march': 'March',
  'app.pages.app.taskManager.april': 'April',
  'app.pages.app.taskManager.may': 'May',
  'app.pages.app.taskManager.june': 'June',
  'app.pages.app.taskManager.july': 'July',
  'app.pages.app.taskManager.august': 'August',
  'app.pages.app.taskManager.september': 'September',
  'app.pages.app.taskManager.october': 'October',
  'app.pages.app.taskManager.november': 'November',
  'app.pages.app.taskManager.december': 'December',
  'app.pages.app.taskManager.monday': 'Monday',
  'app.pages.app.taskManager.tuesday': 'Tuesday',
  'app.pages.app.taskManager.wednesday': 'Wednesday',
  'app.pages.app.taskManager.thursday': 'Thursday',
  'app.pages.app.taskManager.friday': 'Friday',
  'app.pages.app.taskManager.saturday': 'Saturday',
  'app.pages.app.taskManager.sunday': 'Sunday',
  'app.pages.app.taskManager.at': 'At ',
  'app.pages.app.taskManager.on': 'on ',
  'app.pages.app.taskManager.in': 'in ',
  'app.pages.app.taskManager.pastHour': 'Past hour ',
  'app.pages.app.taskManager.andOn': 'And on ',
  'app.pages.app.taskManager.and': 'and',
  'app.pages.app.taskManager.reset': 'Reset',
  'app.pages.app.taskManager.nameIsRequired': 'Task name is required',
  'app.pages.app.taskManager.nameMaxLength': 'Task name max length is {number} characters',
  'app.pages.app.taskManager.schedulerTypeIsRequired': 'Scheduler type is required',
  'app.pages.app.taskManager.periodIsRequired': 'Period is required',
  'app.pages.app.taskManager.everyIsRequired': 'Every is required',
  'app.pages.app.taskManager.everyMustBeInt': 'Every must be an integer number',
  'app.pages.app.taskManager.everyMaxLength': 'Every can not be greather than {number}',
  'app.pages.app.taskManager.clockedIsRequired': 'Clocked time is required',
  'app.pages.app.taskManager.nodeIsRequired': 'Node to evaluate is required',
  'app.pages.app.taskManager.appPathIsRequired': 'App path is required',
  'app.pages.app.taskManager.type': 'Type',
  'app.pages.app.taskManager.label': 'Label',
  'app.pages.app.taskManager.defaultValue': 'Default value',
  'app.pages.app.taskManager.source': 'Source',
  'app.pages.app.taskManager.paramsTable': 'Params table',
  'app.pages.app.taskManager.noParamsData': 'There are not params',
  'app.pages.app.taskManager.select': 'Select',
  'app.pages.app.taskManager.input': 'Input',
  'app.pages.app.taskManager.checkbox': 'Checkbox',
  'app.pages.app.taskManager.paramNameIsRequired': 'Param name is required',
  'app.pages.app.taskManager.labelIsRequired': 'Label is required',
  'app.pages.app.taskManager.paramNameInvalid':
    'Must contain lowercase letters and/or numbers. Whitespaces and dashes are not allowed',
  'app.pages.app.taskManager.paramNamePlaceholder': 'Insert the param name',
  'app.pages.app.taskManager.labelPlaceholder': 'Insert the label',
  'app.pages.app.taskManager.defaultValuePlaceholder': 'Insert the default value',
  'app.pages.app.taskManager.sourcePlaceholder': 'Insert the source',
  'app.pages.app.taskManager.taskNameExists': 'The task name already exists',
  'app.pages.app.taskManager.taskCreated': 'Task created',
  'app.pages.app.taskManager.taskUpdated': 'Task updated',
  'app.pages.app.taskManager.keepForEveryone': 'Keep for everyone',
  'app.pages.app.taskManager.keepForUser': 'Keep for user',
  'app.pages.app.taskManager.actionOnDone': 'Action on done',
  'app.pages.app.taskManager.instances': 'Instances',
  'app.pages.app.taskManager.enableInstanceExpire': 'Enable instance expire',
  'app.pages.app.taskManager.expireAt': 'Expire at (UTC)',
  'app.pages.app.taskManager.selectModelFIle': 'Select model file',
  'app.pages.app.taskManager.node': 'Node',
  'app.pages.app.taskManager.noNodesFounded': 'There are not nodes',
  'app.pages.app.taskManager.clockedWithTz': 'Clocked UTC {tz}',
  'app.pages.app.taskManager.appPoolCreated': 'App pool created',
  'app.pages.app.taskManager.appPoolUpdated': 'App pool updated',
  'app.pages.app.taskManager.actionIsRequired': 'Action on done is required',
  'app.pages.app.taskManager.instancesPositive': 'Instance value must be a positive number',
  'app.pages.app.taskManager.instancesIsRequired': 'Instances are required',
  'app.pages.app.taskManager.userIsRequired': 'User is required',
  'app.pages.app.taskManager.users': 'Users',
  'app.pages.app.taskManager.usersPlaceholder': 'Please select a user',
  'app.pages.app.taskManager.timezoneIsRequired': 'Timezone is required',
  'app.pages.app.taskManager.noTasksFound': 'No tasks found',
  'app.pages.app.taskManager.taskUser': 'Task user',
  'app.pages.app.taskManager.systemTask': 'System',
  'app.pages.app.taskManager.noPermissionsToListTasks':
    "You don't have enough permissions to list scheduled tasks",
  'app.pages.app.taskManager.resources': 'Resources',
  'app.pages.app.taskManager.useDepartment': 'Department resources',

  //Running node
  'app.pages.app.runningNodeModel.runningNode': 'Running node',
  'app.pages.app.runningNodeModel.stopping': 'Stopping',

  //Saving app
  'app.pages.savingApp.savingApp': 'Saving app',

  //Interface Manager
  'app.pages.interfaceManager': 'Interfaces manager',
  'app.pages.interfaceManager.newInterface': 'New interface',
  'app.pages.interfaceManager.createNewInterface': 'Create new interface',
  'app.pages.interfaceManager.editInterface': 'Edit interface',
  'app.pages.interfaceManager.createNewFolder': 'Create new folder',
  'app.pages.interfaceManager.name': 'Interface name',
  'app.pages.interfaceManager.folderName': 'Folder name',
  'app.pages.interfaceManager.deleteInterface': 'Delete interfaces',
  'app.pages.interfaceManager.deleteFolder': 'Delete folder',
  'app.pages.interfaceManager.deleteInterfaceConfirmationText':
    'Are you sure you want to permanently delete this interface?',
  'app.pages.interfaceManager.deleteFolderConfirmationText':
    'Are you sure you want to permanently delete this folder and all its content?',
  'app.pages.interfaceManager.myInterfaces': 'My interfaces',
  'app.pages.interfaceManager.noInterfaces':
    'There are no available interfaces. Create a new one using the Create button',
  'app.pages.interfaceManager.deniedFor': 'Denied for',
  'app.pages.interfaceManager.allowedFor': 'Allowed for',
  'app.pages.interfaceManager.deniedForDetail': 'Deny access to',
  'app.pages.interfaceManager.slashNotAllowed':
    'Invalid character. "/" is not allowed in interface name',
  'app.pages.interfaceManager.interfaceNameRepeated': 'Interface name already exists',
  'app.pages.interfaceManager.removeDocumentation': 'Remove documentation',
  'app.pages.interfaceManager.removeDocumentationConfirm':
    'Do you want to remove the documentation?',
  'app.pages.interfaceManager.shareInterface': 'Share interface',
  'app.pages.interfaceManager.interfaceLinkCopied': 'Interface link copied',
  'app.pages.interfaceManager.copyInterfaceLink': 'Copy interface link',
  'app.pages.interfaceManager.interfaceLink': 'Interface link',
  'app.pages.interfaceManager.interfacesLinks': 'Interface links',
  'app.pages.interfaceManager.interfacesLinks.notFound': 'There are no interface links',
  'app.pages.interfaceManager.activateInterfaceEndpoint': 'Activate interface endpoint',
  'app.pages.interfaceManager.updateInterfaceLink': 'Update interface link',
  'app.pages.interfaceManager.interfaceLinkUpdated': 'Interface link updated successfully',
  'app.pages.interfaceManager.deleteInterfaceLink': 'Delete interface link: {interfaceName}',
  'app.pages.interfaceManager.deleteInterfaceLinkConfirmation':
    'Are you sure you want to delete this interface link?',
  'app.pages.interfaceManager.interfaceLinkDeleted': 'Interface link deleted successfully',
  'app.pages.interfaceManager.folderNameExists': 'Folder name already exists',
  'app.pages.externalLink.customEndpointExists': 'Custom endpoint already exists',
  'app.pages.externalLink.passwordRequiredDescription': 'Please enter the password to continue',
  'app.pages.externalLink.hasPassword': 'Has password',
  'app.pages.externalLink.requirePassword': 'Require password',

  //File manager
  'app.pages.FileManagerPage.download': 'Download',
  'app.pages.FileManagerPage.zipIt': 'Zip it',
  'app.pages.FileManagerPage.copyIn': 'Copy',
  'app.pages.FileManagerPage.archive': 'Archive',
  'app.pages.FileManagerPage.denyAccess': 'Deny access',
  'app.pages.FileManagerPage.folder': 'Folder',
  'app.pages.FileManagerPage.selectAll': 'Select all',
  'app.pages.FileManagerPage.unSelectAll': 'Unselect all',
  'app.pages.FileManagerPage.models': 'Models (.ppl)',
  'app.pages.FileManagerPage.csv': 'CSV {extension}',
  'app.pages.FileManagerPage.templates': 'Templates(.xls,.xlsx,.xlsm)',
  'app.pages.FileManagerPage.database': 'Database {extension}',
  'app.pages.FileManagerPage.modules': 'Modules (.ppm)',
  'app.pages.FileManagerPage.interfaces': 'Interfaces',
  'app.pages.FileManagerPage.zipFiles': 'Zip file {extension}',
  'app.pages.FileManagerPage.jsonFiles': 'JSON file {extension}',
  'app.pages.FileManagerPage.backup': 'Backup (.old)',
  'app.pages.FileManagerPage.others': 'Others',
  'app.pages.FileManagerPage.showHidden': 'Show hidden files/folders',
  'app.pages.FileManagerPage.filterBy': 'Filter by',
  'app.pages.FileManagerPage.model': 'Model',
  'app.pages.FileManagerPage.file': 'File',
  'app.pages.FileManagerPage.type': 'Type',
  'app.pages.FileManagerPage.location': 'Location',
  'app.pages.FileManagerPage.opened': 'Opened',
  'app.pages.FileManagerPage.size': 'Size',
  'app.pages.FileManagerPage.getSize': 'Get size',
  'app.pages.FileManagerPage.date': 'Date',
  'app.pages.FileManagerPage.access': 'Access',
  'app.pages.FileManagerPage.fileName': 'File name',
  'app.pages.FileManagerPage.folders': 'Folders',
  'app.pages.FileManagerPage.files': 'Files',
  'app.pages.FileManagerPage.root': 'Root',
  'app.pages.FileManagerPage.listView': 'List view',
  'app.pages.FileManagerPage.mosaicView': 'Mosaic view',
  'app.pages.FileManagerPage.folderEmpty': 'The folder is empty',
  'app.pages.FileManagerPage.denyAccessTo': 'Deny access',
  'app.pages.FileManagerPage.allowAccessTo': 'Allow access',
  'app.pages.FileManagerPage.allowAccessOnlyTo': 'Allow access only to',
  'app.pages.FileManagerPage.deletedMessage': '{name} deleted',
  'app.pages.FileManagerPage.wantToDeleteFolder': 'Are you sure you want to delete the folder?',
  'app.pages.FileManagerPage.wantToDeleteFile': 'Are you sure you want to delete the file?',
  'app.pages.FileManagerPage.deletedTitle': 'Delete {name}',
  'app.pages.FileManagerPage.wantToZipFile': 'Are you sure you want to zip this file?',
  'app.pages.FileManagerPage.zipFile': 'Zip {name}',
  'app.pages.FileManagerPage.unZipFile': 'Unzip {name}',
  'app.pages.FileManagerPage.fileZipped': '{name} zipped',
  'app.pages.FileManagerPage.fileUnZipped': '{name} unzipped',
  'app.pages.FileManagerPage.nodeChanged': 'Name changed to {name}',
  'app.pages.FileManagerPage.wantToUnZipFile': 'Are you sure you want to unzip this file?',
  'app.pages.FileManagerPage.unzip': 'Unzip',
  'app.pages.FileManagerPage.move': 'Move',
  'app.pages.FileManagerPage.fileCloned': '{name} cloned',
  'app.pages.FileManagerPage.createFolderTitle': 'Set folder name',
  'app.pages.FileManagerPage.folderCreated': 'Folder created',
  'app.pages.FileManagerPage.folderName': 'Folder name',
  'app.pages.FileManagerPage.deletedFilesMessage': 'Files or folder deleted',
  'app.pages.FileManagerPage.copyFileIn': 'Copy {name} in',
  'app.pages.FileManagerPage.upload': 'Upload',
  'app.pages.FileManagerPage.wantToDelete': 'Are you sure you want to delete :',
  'app.pages.FileManagerPage.duplicatedFiles': 'Duplicated files',
  'app.pages.FileManagerPage.zipTitle': 'Zip files',
  'app.pages.FileManagerPage.wantToZipFileS': 'Are you sure you want to zip the selected files?',
  'app.pages.FileManagerPage.departmentsRestrictions': 'Restricted department/s',
  'app.pages.FileManagerPage.folderNameError': 'The folder name is required and cannot be empty.',
  'app.pages.FileManagerPage.folderNameErrorSlash': 'The folder name cannot has slash',
  'app.pages.FileManagerPage.newNameIsRequired': 'The name is required and cannot be empty',
  'app.pages.FileManagerPage.filesUploaded': 'Files uploaded',
  'app.pages.FileManagerPage.filesZipped': 'Files zipped',
  'app.pages.FileManagerPage.filesToCopy': 'Files to copy',
  'app.pages.FileManagerPage.pasteHere': 'Paste here',
  'app.pages.FileManagerPage.pleaseSelectAFile': 'Select a file or folder to see the details here.',
  'app.pages.FileManagerPage.filesCopied': 'Files copied',
  'app.pages.FileManagerPage.filesMoved': 'Files moved',
  'app.pages.FileManagerPage.filesDownloaded': 'Files downloaded',
  'app.pages.FileManagerPage.uploading': 'Uploading...',
  'app.pages.FileManagerPage.uploaded': 'Uploaded!',
  'app.pages.FileManagerPage.compressing': 'Compressing...',
  'app.pages.FileManagerPage.unzipping': 'Unzipping...',
  'app.pages.FileManagerPage.duplicating': 'Duplicating...',
  'app.pages.FileManagerPage.loadingLastPath': 'Loading last path..',
  'app.pages.FileManagerPage.uploadCancelled': 'Upload cancelled by the user',
  'app.pages.FileManagerPage.deleting': 'Deleting',
  'app.pages.FileManagerPage.creating': 'Creating the folder',
  'app.pages.FileManagerPage.pastingFiles': 'Pasting files',
  'app.pages.FileManagerPage.configuringAccess': 'Configuring access',
  'app.pages.FileManagerPage.downloading': 'Downloading',
  'app.pages.FileManagerPage.duplicateTitle': 'Duplicate {name}',
  'app.pages.FileManagerPage.wantToDuplicate':
    'Are you sure you want to duplicate the selected item at the same directory?',
  'app.pages.FileManagerPage.dragDropFilesHere': 'Drag and drop files here or',
  'app.pages.FileManagerPage.overWriteFileOrFolder': 'Overwrite file or folder',
  'app.pages.FileManagerPage.overWriteFileOrFolderMessage':
    'Are you sure you want to overwrite {name}?',
  'app.pages.FileManagerPage.clearClipboard': 'Empty clipboard',
  'app.pages.FileManagerPage.clipboardCleared': 'Clipboard empty',
  'app.pages.FileManagerPage.copyToWorkspaceTitle': 'Copy to workspace',
  'app.pages.FileManagerPage.copyToWorkspaceMessage':
    'Are you sure you want to copy {name} to your workspace?',
  'app.pages.FileManagerPage.copyingToWorkspace': 'Copying to workspace',
  'app.pages.FileManagerPage.copiedToWorkspace': 'Copied to workspace',
  'app.pages.FileManagerPage.openTextEditor': 'Open text editor',
  'app.pages.FileManagerPage.loadingFile': 'Loading file',
  'app.pages.FileManagerPage.savingFile': 'Saving file',
  'app.pages.FileManagerPage.fileSaved': 'File saved',
  'app.pages.FileManagerPage.fileTooLargeToRead': 'File too large to read. Max size: 1MB',
  'app.pages.FileManagerPage.copyUrl': 'Copy url',
  'app.pages.FileManagerPage.urlCopied': 'URL copied',
  'app.pages.FileManagerPage.errorUploading': 'There was an error uploading file',

  //Actions (for logManager filter)
  'app.pages.managers.logManager.actions.openApp': 'Open app',
  'app.pages.managers.logManager.actions.navigateDiagram': 'Navigate diagram',
  'app.pages.managers.logManager.actions.getArrows': 'Get Arrows',
  'app.pages.managers.logManager.actions.getToolbars': 'Get Toolbars',
  'app.pages.managers.logManager.actions.lastOpenApps': 'Last Open Apps',
  'app.pages.managers.logManager.actions.changeToOtherInstance': 'Change to other instance',
  'app.pages.managers.logManager.actions.getNodeProperties': 'Get Node Properties',
  'app.pages.managers.logManager.actions.setNodeProperties': 'Set Node Properties',
  'app.pages.managers.logManager.actions.setNodesProperties': 'Set Nodes Properties',
  'app.pages.managers.logManager.actions.getNodeInputs': 'Get Node Inputs',
  'app.pages.managers.logManager.actions.getNodeOutputs': 'Get Node Outputs',
  'app.pages.managers.logManager.actions.searchNodes': 'Search Nodes',
  'app.pages.managers.logManager.actions.searchForAutocomplete': 'Search For Autocomplete',
  'app.pages.managers.logManager.actions.previewNode': 'Preview Node',
  'app.pages.managers.logManager.actions.getSelector': 'Get Selector',
  'app.pages.managers.logManager.actions.setNodesSize': 'Set Nodes Size',
  'app.pages.managers.logManager.actions.setNodesPosition': 'Set Nodes Position',
  'app.pages.managers.logManager.actions.getNodeProfile': 'Get Node Profile',
  'app.pages.managers.logManager.actions.createNode': 'Create Node',
  'app.pages.managers.logManager.actions.deleteNodes': 'Delete Nodes',
  'app.pages.managers.logManager.actions.createAlias': 'Create Alias',
  'app.pages.managers.logManager.actions.createInputNode': 'Create Input Node',
  'app.pages.managers.logManager.actions.copyNodes': 'Copy Nodes',
  'app.pages.managers.logManager.actions.copyAsValues': 'Copy As Values',
  'app.pages.managers.logManager.actions.moveNodes': 'Move Nodes',
  'app.pages.managers.logManager.actions.stop': 'Stop',
  'app.pages.managers.logManager.actions.setNodeZ': 'Set Node Z',
  'app.pages.managers.logManager.actions.setNodeIdFromTitle': 'Set Node Id From Title',
  'app.pages.managers.logManager.actions.executeForRefresh': 'Execute For Refresh',
  'app.pages.managers.logManager.actions.exportFlatNode': 'Export Flat Node',
  'app.pages.managers.logManager.actions.exportCurrentNode': 'Export Current Node',
  'app.pages.managers.logManager.actions.exportModuleToFile': 'Export Module to File',
  'app.pages.managers.logManager.actions.importModuleFromFile': 'Import Module from File',
  'app.pages.managers.logManager.actions.getFilesForImportWizard': 'Get Files for Import Wizard',
  'app.pages.managers.logManager.actions.callWizard': 'Call Wizard',
  'app.pages.managers.logManager.actions.executeButton': 'Execute Button',
  'app.pages.managers.logManager.actions.isInBackground': 'Is in Background',
  'app.pages.managers.logManager.actions.uploadFileToTemp': 'Upload File to Temp',
  'app.pages.managers.logManager.actions.installLibrary': 'Install Library',
  'app.pages.managers.logManager.actions.listInstalledLibraries': 'List Installed Libraries',
  'app.pages.managers.logManager.actions.uninstallLibrary': 'Uninstall Library',
  'app.pages.managers.logManager.actions.getInstallProgress': 'Get Install Progress',
  'app.pages.managers.logManager.actions.getMainFolders': 'Get Main Folders',
  'app.pages.managers.logManager.actions.getFiles': 'Get Files',
  'app.pages.managers.logManager.actions.createFolder': 'Create Folder',
  'app.pages.managers.logManager.actions.copyFileOrFolder': 'Copy File Or Folder',
  'app.pages.managers.logManager.actions.renameFile': 'Rename File',
  'app.pages.managers.logManager.actions.duplicateFiles': 'Duplicate Files',
  'app.pages.managers.logManager.actions.moveFiles': 'Move Files',
  'app.pages.managers.logManager.actions.copyFiles': 'Copy Files',
  'app.pages.managers.logManager.actions.deleteFiles': 'Delete Files',
  'app.pages.managers.logManager.actions.upload': 'Upload',
  'app.pages.managers.logManager.actions.unzipFile': 'Unzip File',
  'app.pages.managers.logManager.actions.zipFiles': 'Zip Files',
  'app.pages.managers.logManager.actions.getHome': 'Get Home',
  'app.pages.managers.logManager.actions.optimizeTemplates': 'Optimize Templates',
  'app.pages.managers.logManager.actions.myReports': 'My Reports',
  'app.pages.managers.logManager.actions.sharedWithMe': 'Reports Shared with Me',
  'app.pages.managers.logManager.actions.mySharedReports': 'My Shared Reports',
  'app.pages.managers.logManager.actions.reportManager': 'Report Endpoints',
  'app.pages.managers.logManager.actions.getNavigator': 'Report - Get Navigator',
  'app.pages.managers.logManager.actions.bulkDelete': 'Report - Bulk Delete',
  'app.pages.managers.logManager.actions.changeOrder': 'Report - Change Order',
  'app.pages.managers.logManager.actions.search': 'Report - Search',
  'app.pages.managers.logManager.actions.duplicateItems': 'Report - Duplicate Items',
  'app.pages.managers.logManager.actions.copyToMyReports': 'Report - Copy to My Reports',
  'app.pages.managers.logManager.actions.setAsFav': 'Report - Set as Fav',
  'app.pages.managers.logManager.actions.dropOnReport': 'Report - Drop on Report',
  'app.pages.managers.logManager.actions.exportItems': 'Report - Export Items',
  'app.pages.managers.logManager.actions.importItems': 'Report - Import Items',
  'app.pages.managers.logManager.actions.publishItems': 'Report - Publish Items',
  'app.pages.managers.logManager.actions.shares': 'Get Shares',
  'app.pages.managers.logManager.actions.setShares': 'Set Shares',
  'app.pages.managers.logManager.actions.myDashboards': 'My Dashboards',
  'app.pages.managers.logManager.actions.companyDashboards': 'Dashboard - Company Dashboards',
  'app.pages.managers.logManager.actions.dashboardsSharedWithMe': 'Dashboard - Shared with Me',
  'app.pages.managers.logManager.actions.allMyDashboards': 'Dashboard - All My Dashboards',
  'app.pages.managers.logManager.actions.mySharedDashboards': 'Dashboard - My Shared Dashboards',
  'app.pages.managers.logManager.actions.dashboardById': 'Dashboard - By id',
  'app.pages.managers.logManager.actions.getNodeFullData': 'Dashboard - Get Node Full Data',
  'app.pages.managers.logManager.actions.evaluateNode': 'Dashboard - Evaluate Node',
  'app.pages.managers.logManager.actions.getOrCreate': 'Dashboard - Get or Create',
  'app.pages.managers.logManager.actions.dashboardManager': 'Dashboard Endpoints',
  'app.pages.managers.logManager.actions.dashboardBulkDelete': 'Dashboard - Bulk Delete',
  'app.pages.managers.logManager.actions.dashboardChangeOrder': 'Dashboard - Change Order',
  'app.pages.managers.logManager.actions.getIndexValues': 'Dashboard - Get Index Values',
  'app.pages.managers.logManager.actions.getNodeIndexes': 'Dashboard - Get Node Indexes',
  'app.pages.managers.logManager.actions.isResultComputed': 'Dashboard - Is Result Computed',
  'app.pages.managers.logManager.actions.lastOpenDashboards': 'Dashboard - Last Open Dashboards',
  'app.pages.managers.logManager.actions.copy': 'Dashboard - Copy',
  'app.pages.managers.logManager.actions.getCubeMetadata':
    'Dashboard - Pivot Grid - Get Cube Metadata',
  'app.pages.managers.logManager.actions.getCubeValues': 'Dashboard - Pivot Grid - Get Cube Values',
  'app.pages.managers.logManager.actions.setCubeChanges':
    'Dashboard - Pivot Grid - Set Cube Changes',
  'app.pages.managers.logManager.actions.getDimensionValues':
    'Dashboard - Pivot Grid - Get Dimension Values',
  'app.pages.managers.logManager.actions.tokenAuthEndpoints': 'Token Auth Endpoints',
  'app.pages.managers.logManager.actions.sendPasswordResetEmail': 'Send Password Reset Email',
  'app.pages.managers.logManager.actions.passwordReset': 'Password Reset',
  'app.pages.managers.logManager.actions.authEndpoints': 'Auth Endpoints',
  'app.pages.managers.logManager.actions.createInstance': 'Create Instance',
  'app.pages.managers.logManager.actions.getInstance': 'Get Instance',
  'app.pages.managers.logManager.actions.logout': 'Logout',
  'app.pages.managers.logManager.actions.getAllInstances': 'Get All Instances',
  'app.pages.managers.logManager.actions.getMyInstances': 'Get My Instances',
  'app.pages.managers.logManager.actions.killInstanceByKey': 'Kill Instance by Key',
  'app.pages.managers.logManager.actions.createNewInstance': 'Create New Instance',
  'app.pages.managers.logManager.actions.useExternalLink': 'Use External Link',
  'app.pages.managers.logManager.actions.getSystemResources': 'Get System Resources',
  'app.pages.managers.logManager.actions.requireLogout': 'Require Logout',
  'app.pages.managers.logManager.actions.getGeoData': 'Geo - Get Geo Data',
  'app.pages.managers.logManager.actions.getGeoDetail': 'Geo - Get Geo Detail',
  'app.pages.managers.logManager.actions.getBubbleInfo': 'Geo - Get Bubble Info',
  'app.pages.managers.logManager.actions.inputForm': 'Input Templates - List',
  'app.pages.managers.logManager.actions.getMetadata': 'Input Forms - Get Metadata',
  'app.pages.managers.logManager.actions.getData': 'Input Templates - Get Data',
  'app.pages.managers.logManager.actions.setData': 'Input Templates - Set Data',
  'app.pages.managers.logManager.actions.result': 'Result Endpoints',
  'app.pages.managers.logManager.actions.resultByNodeId': 'Result - By node id',
  'app.pages.managers.logManager.actions.activities': 'Activities Endpoints',
  'app.pages.managers.logManager.actions.groups': 'Groups Endpoints',
  'app.pages.managers.logManager.actions.updateGroupsPermissions':
    'Groups - Update Groups Permissions',
  'app.pages.managers.logManager.actions.users': 'Users Endpoints',
  'app.pages.managers.logManager.actions.companies': 'Companies Endpoints',
  'app.pages.managers.logManager.actions.preferenceByCode': 'Companies - Preference by code',
  'app.pages.managers.logManager.actions.preferences': 'Companies - Preferences',
  'app.pages.managers.logManager.actions.usercompanies': 'User Companies Endpoints',
  'app.pages.managers.logManager.actions.listByCompanyId': 'User Companies - List by Company id',
  'app.pages.managers.logManager.actions.userPreferenceByCode':
    'User Companies - Preference by code',
  'app.pages.managers.logManager.actions.userPreferences': 'User Companies - Preferences',
  'app.pages.managers.logManager.actions.permissions': 'Permissions Endpoints',
  'app.pages.managers.logManager.actions.departments': 'Departments Endpoints',
  'app.pages.managers.logManager.actions.byCurrentCompany': 'Departments - By Current Company',
  'app.pages.managers.logManager.actions.denied': 'Departments - Denied',
  'app.pages.managers.logManager.actions.denyItems': 'Departments - Deny Items',
  'app.pages.managers.logManager.actions.dashboardComments': 'Dashboard Comments Endpoints',
  'app.pages.managers.logManager.actions.dashboardStyles': 'Dashboard Styles Endpoints',
  'app.pages.managers.logManager.actions.diagramShortcut': 'Diagram Shortcut Endpoints',
  'app.pages.managers.logManager.actions.preferenceModules': 'Preference Modules Endpoints',
  'app.pages.managers.logManager.actions.preferencesEndpoints': 'Preferences Endpoints',
  'app.pages.managers.logManager.actions.userCompanyPreferences':
    'User Company Preferences Endpoints',
  'app.pages.managers.logManager.actions.companyPreferences': 'Company Preferences Endpoints',
  'app.pages.managers.logManager.actions.apiEndpoints': 'Api endpoints',
  'app.pages.managers.logManager.actions.reportExternalLink': 'Report External link endpoints',
  'app.pages.managers.logManager.actions.dashboardExternalLink':
    'Dashboard External link endpoints',
  'app.pages.managers.logManager.actions.scheduler': 'Scheduler Endpoints',
  'app.pages.managers.logManager.actions.getPythonTimezones': 'Scheduler - Get Timezones',
  'app.pages.managers.logManager.actions.taskLog': 'TaskLog Endpoints',
  'app.pages.managers.logManager.actions.byPeriodicTask': 'TaskLog - By Periodic Task',
  'app.pages.managers.logManager.actions.filterLogs': 'TaskLog - Filter Logs',
  'app.pages.managers.logManager.actions.logging': 'Logging Endpoints',
  'app.pages.managers.logManager.actions.restAuth': 'Rest Auth Endpoints',
  'app.pages.managers.logManager.actions.accounts': 'Accounts Endpoints',

  //Applications
  'app.pages.applications.createApp': 'Create App',
  'app.pages.applications.applicationName': 'Application name',
  'app.pages.applications.fromScratch': 'From scratch',
  'app.pages.applications.fromScratchDescription': 'Starts with a simple "Hello world" example',
  'app.pages.applications.fromDataAnalytics': 'Data analytics',
  'app.pages.applications.fromDataAnalyticsDescription': 'Example of a data analytics application',
  'app.pages.applications.fromBusinessPlanning': 'Business planning',
  'app.pages.applications.fromBusinessPlanningDescription':
    'Example of a business planning application',
  'app.pages.applications.applicationManager': 'Application manager',
  'app.pages.applications.deleteAppTitle': 'Delete application',
  'app.pages.applications.deleteAppMessage': 'Are you sure you want to delete this application?',
  'app.pages.applications.results': 'Results',
  'app.pages.applications.applicationDeleted': 'Application deleted',
  'app.pages.applications.deletingApplication': 'Deleting application',
  'app.pages.applications.applicationNameChanged': 'Application name changed',
  'app.pages.applications.path': 'Path',
  'app.pages.applications.copyAppToWorkspaceTitle': 'Copy application to workspace',
  'app.pages.applications.copyAppToWorkspaceMessage':
    'Are you sure you want to copy this application to your workspace?',
  'app.pages.applications.appCopiedToWorkspace': 'Application successfully copied in:',
  'app.pages.applications.copyingAppToWorkspace': 'Copying application to workspace',
  'app.pages.applications.appImported': 'Application imported successfully',
  'app.pages.applications.createInPublic': 'Create in Public workspace',
  'app.pages.applications.appCopied': 'Application copied',
  'app.pages.applications.copyingAppToTeam': 'Copying application to team',
  'app.pages.applications.copyAppToPublicMessage':
    'Are you sure you want to copy this application to Public workspace?',
  'app.pages.applications.appCopiedToPublic': 'Application copied to Public workspace',
  'app.pages.applications.copyingAppToPublic': 'Copying application to Public',
  'app.pages.applications.setThumbnail': 'Set thumbnail',
  'app.pages.applications.replaceThumbnail': 'Replace thumbnail',
  'app.pages.applications.removeThumbnail': 'Remove thumbnail',
  'app.pages.applications.removeThumbnailMessage': 'Are you sure to remove thumbnail?',
  'app.pages.applications.thumbnailRemoved': 'Thumbnail removed',
  'app.pages.applications.thumbnailUploaded': 'Thumbnail uploaded',
  'app.pages.applications.loadingAppMessage': 'Loading application',

  //Application Summary

  'app.pages.applications.id': 'Application ID',
  'app.pages.applications.version': 'Application version',
  'app.pages.applications.uri': 'Location',
  'app.pages.applications.isPublic': 'Is in Public folder',
  'app.pages.applications.canwrite': 'Has write permission',
  'app.pages.applications.venvPath': 'Virtual environment path',

  //Icon selector
  'app.pages.iconSelector.iconType': 'Icon type',
  'app.pages.iconSelector.iconName': 'Icon name',
  'app.pages.iconSelector.solid': 'Solid',
  'app.pages.iconSelector.regular': 'Regular',

  //Versions
  'app.pages.versions.versionManager': 'Version manager',
  'app.pages.versions.deleteVersionTitle': 'Delete version',
  'app.pages.versions.deleteVersionMessage': 'Are you sure you want to delete version {version}?',
  'app.pages.versions.deleteVersionsMessage':
    'Are you sure you want to delete versions {versions}?',
  'app.pages.versions.versionDeleted': 'Version "{version}" was deleted',
  'app.pages.versions.versionsDeleted': 'Versions "{versions}" were deleted',
  'app.pages.versions.deletingVersion': 'Deleting version',
  'app.pages.versions.createVersion': 'Create version',
  'app.pages.versions.fromVersion': 'From version',
  'app.pages.versions.versionCreated': 'Version "{version}" was successfully created',
  'app.pages.versions.creationDate': 'Creation date',
  'app.pages.versions.closingDate': 'Closing date',
  'app.pages.versions.archiveDate': 'Archive date',
  'app.pages.versions.status.active': 'Active',
  'app.pages.versions.status.closed': 'Closed',
  'app.pages.versions.status.archived': 'Archived',
  'app.pages.versions.status.unarchived': 'Unarchived',
  'app.pages.versions.setAsDefaultVersion': 'Set as default version',
  'app.pages.versions.isDefaultAppVersion': 'Is the default version of the app',
  'app.pages.versions.setAsDefaultVersionMessage':
    'Are you sure you want to set version "{version}" as the default version of the app?',
  'app.pages.versions.defaultVersionChanged': 'Default version changed',
  'app.pages.versions.editProperties': 'Edit properties',
  'app.pages.versions.cannotDeleteReason':
    'It is not possible to delete the current open version of the app',
  'app.pages.versions.versionModified': 'Version {version} was successfully modified',
  'app.pages.versions.versionsModified': 'Versions {versions} were successfully modified',
  'app.pages.versions.cannotModifyNameReason':
    'It is not possible to modify the name of the current open version of the app',
  'app.pages.versions.updateVersionStatus': 'Update version status',
  'app.pages.versions.updateVersionStatusMessage':
    'Are you sure you want to update {version} version status to "{newStatus}"?',
  'app.pages.versions.updateVersionsStatusMessage':
    'Are you sure you want to update {versions} versions status to "{newStatus}"?',
  'app.pages.versions.importVersions': 'Import versions',
  'app.pages.versions.exportVersions': 'Export versions',
  'app.pages.versions.cannotChangeReason':
    'It is not possible to change the current open version of the app',
  'app.pages.versions.versionsImported': 'Uploaded versions were successfully imported',
  'app.pages.versions.uploadInstructions':
    'Upload a single .zip file that contains a folder for each version you want to import',
  'app.pages.versions.openAppVersion': 'Open app version',
  'app.pages.versions.openAppVersionOnNewInstance': 'Open app version on new instance',
  'app.pages.versions.excludeArchivedVersions': 'Exclude archived versions',
  'app.pages.versions.versionSelectedScenariosUpdated':
    'Version selected scenarios were successfully updated',
  'app.pages.versions.openAfterCreating': 'Open after creating',
  'app.pages.versions.unsavedChangesMessage':
    'Any unsaved changes from the open version will be lost',
  'app.pages.versions.versionExported': 'Version exported',
  'app.pages.versions.versionsExported': 'Versions exported',
  'app.pages.versions.nameExists': 'Version name already exists',
  'app.pages.versions.openExistingVersion': 'Open existing version',
  'app.pages.versions.createNewVersion': 'Create new version',
  'app.pages.versions.createNewVersionInMyWorkspace': 'Create new version in my workspace',
  'app.pages.versions.createNewVersionInMyWorkspaceConfirmation':
    "You don't have write permissions in the selected app's directory. The app will be copied to your workspace (if it doesn't exist) and the new version will be created there.",
  'app.pages.versions.newVersionName': 'New version name',

  //Process manager
  'app.pages.app.processManager.addProcess': 'Add process',
  'app.pages.app.processManager.editProcess': 'Edit process',
  'app.pages.app.processManager.duplicateProcess': 'Duplicate process',
  'app.pages.app.processManager.showDescription': 'Show description',
  'app.pages.app.processManager.noDescriptionText': 'There is not description',
  'app.pages.app.processManager.processTodo': 'To do',
  'app.pages.app.processManager.process': 'Process',
  'app.pages.app.processManager.subscribers': 'Subscribers',
  'app.pages.app.processManager.status': 'Status',
  'app.pages.app.processManager.description': 'Description',
  'app.pages.app.processManager.group': 'group',
  'app.pages.app.processManager.task': 'Task',
  'app.pages.app.processManager.taskResponsible': 'Task responsible',
  'app.pages.app.processManager.taskActionType': 'Action type',
  'app.pages.app.processManager.taskActionData': 'Action data',
  'app.pages.app.processManager.isBlockedBy': 'Is blocked by',
  'app.pages.app.processManager.expiration': 'Expiration',
  'app.pages.app.processManager.expirationType': 'Expiration type',
  'app.pages.app.processManager.sinceProcess': 'since the process was initiated',
  'app.pages.app.processManager.sinceBlockingTaskCompleted': 'since blocking task was completed',
  'app.pages.app.processManager.processCreated': 'Process created',
  'app.pages.app.processManager.processUpdated': 'Process updated',
  'app.pages.app.processManager.processDuplicated': 'Process duplicated',
  'app.pages.app.processManager.undefined': 'Undefined',
  'app.pages.app.processManager.processDeleted': 'Process deleted',
  'app.pages.app.processManager.processNotFound': 'Process not found',
  'app.pages.app.processManager.deleteProcess': 'Delete process',
  'app.pages.app.processManager.deleteProcessMessage':
    '¿Are you sure you want to delete this process?',
  'app.pages.app.processManager.reviewers': 'Reviewers',
  'app.pages.app.processManager.reviewerInterface': 'Reviewer interface',
  'app.pages.app.processManager.expirationDays': 'Expiration days',
  'app.pages.app.processManager.addTask': 'Add task',
  'app.pages.app.processManager.editTask': 'Edit task',
  'app.pages.app.processManager.taskStatus.not_ready_to_start': 'Not ready to start',
  'app.pages.app.processManager.taskStatus.not_started': 'Not started',
  'app.pages.app.processManager.taskStatus.in_progress': 'In progress',
  'app.pages.app.processManager.taskStatus.pending_review': 'Pending review',
  'app.pages.app.processManager.taskStatus.completed': 'Completed',

  //Scenarios
  'app.pages.scenarios.scenarioManager': 'Scenario manager',
  'app.pages.scenarios.deleteScenarioTitle': 'Delete scenario',
  'app.pages.scenarios.deleteScenarioMessage':
    'Are you sure you want to delete scenario "{scenario}"?',
  'app.pages.scenarios.deleteScenariosMessage':
    'Are you sure you want to delete scenarios "{scenarios}"?',
  'app.pages.scenarios.scenarioDeleted': 'Scenario "{scenario}" was deleted',
  'app.pages.scenarios.scenariosDeleted': 'Scenarios "{scenarios}" were deleted',
  'app.pages.scenarios.deletingScenario': 'Deleting scenario',
  'app.pages.scenarios.createScenario': 'Create scenario',
  'app.pages.scenarios.fromScenario': 'From scenario',
  'app.pages.scenarios.scenarioCreated': 'Scenario "{scenario}" was successfully created',
  'app.pages.scenarios.lastModifiedDate': 'Last modified date',
  'app.pages.scenarios.editProperties': 'Edit properties',
  'app.pages.scenarios.scenarioModified': 'Scenario "{scenario}" was successfully modified',
  'app.pages.scenarios.importScenarios': 'Import scenarios',
  'app.pages.scenarios.exportScenario': 'Export scenario',
  'app.pages.scenarios.scenariosImported': 'Uploaded scenarios were successfully imported',
  'app.pages.scenarios.uploadInstructions':
    'Upload a single .zip file that contains a folder for each version (with a folder for each scenario inside) you want to import',
  'app.pages.scenarios.alias': 'Alias',
  'app.pages.scenarios.includedNodes': 'Included nodes',
  'app.pages.scenarios.includeNodesFrom': 'Include nodes from',
  'app.pages.scenarios.currentInterface': 'Current interface',
  'app.pages.scenarios.nodesToBeEvaluatedBeforehand': 'Nodes to be evaluated beforehand',
  'app.pages.scenarios.runScenarioAfterSave': 'Run scenario after save',
  'app.pages.scenarios.addNode': 'Add node',
  'app.pages.scenarios.showCurrentVersionOnly': 'Show current version only',
  'app.pages.scenarios.scenarioAlreadyExists':
    'There is already a scenario with this name in "{version}" version',
  'app.pages.scenarios.scenarioDuplicated': 'Scenario "{scenario}" was successfully duplicated',
  'app.pages.scenarios.duplicateScenario': 'Duplicate scenario',
  'app.pages.scenarios.saveScenario': 'Save scenario',
  'app.pages.scenarios.cannotSaveScenarioReason': 'Cannot save scenarios if version is not active',
  'app.pages.scenarios.cannotChangeScenarioReason':
    'Cannot change scenarios if version is not active',
  'app.pages.scenarios.existingScenario': 'Existing scenario',
  'app.pages.scenarios.createNewScenario': 'Create new scenario',
  'app.pages.scenarios.baseScenario': 'Base scenario',
  'app.pages.scenarios.scenarioSaved': 'Scenario "{scenario}" was successfully saved',
  'app.pages.scenarios.noNodesMessage':
    "The selected scenario doesn't include any nodes to save. Edit this scenario in Scenario Manager to add them.",
  'app.pages.scenarios.selectScenarios': 'Select scenarios',
  'app.pages.scenarios.selectedScenarios': 'Selected scenarios',
  'app.pages.scenarios.scenarioExported': 'Scenario exported successfully',
  'app.pages.scenarios.scenariosExported': 'Scenarios exported successfully',
  'app.pages.scenarios.scenariosManager': 'Scenarios manager',
  'app.pages.scenarios.scenariosTemplates': 'Scenarios templates',
  'app.pages.scenarios.createTemplate': 'Create template',
  'app.pages.scenarios.editTemplate': 'Edit template',
  'app.pages.scenarios.duplicateTemplate': 'Duplicate template',
  'app.pages.scenarios.deleteTemplate': 'Delete template',
  'app.pages.scenarios.scenarioTemplateSuccessfullyCreated':
    'Scenario template successfully created',
  'app.pages.scenarios.scenarioTemplateSuccessfullyModified':
    'Scenario template {template} successfully modified',
  'app.pages.scenarios.deleteScenarioTemplateTitle': 'Delete scenario template',
  'app.pages.scenarios.deleteScenarioTemplateMessage':
    'Are you sure you want to delete template {scenarioTemplate}?',
  'app.pages.scenarios.scenarioTemplateSuccessfullyDuplicated':
    'Scenario template {scenarioTemplate} successfully duplicated',
  'app.pages.scenarios.scenarioTemplateNameAlreadyExists':
    'There is already a scenario template with this name in "{version}" version',
  'app.pages.scenarios.templateName': 'Template name',
  'app.pages.scenarios.scenarioNodesTemplate': 'Scenario nodes template',
  'app.pages.scenarios.noOptionsTemplate': 'No template options',
  'app.pages.scenarios.addNewScenarioNodesTemplate': 'Add new scenario nodes template',
  'app.pages.scenarios.destinationVersion': 'Destination version',
  'app.pages.scenarios.updateSelectedScenarios': 'Update selected scenarios',

  //My tasks
  'app.pages.app.myTasks.title': 'My tasks',
  'app.pages.app.myTasks.statusChanged': 'Status changed',
  'app.pages.app.myTasks.showComments': 'Show comments',
  'app.pages.app.myTasks.responsible': 'Responsible',
  'app.pages.app.myTasks.deadline': 'Deadline',
  'app.pages.app.myTasks.finished': 'Finished',
  'app.pages.app.myTasks.commentDeleted': 'Comment deleted',
  'app.pages.app.myTasks.comments': 'Comments',
  'app.pages.app.myTasks.deleteComment': 'Delete comment',
  'app.pages.app.myTasks.addComment': 'Add comment',
  'app.pages.app.myTasks.noAssignedTasks': 'No assigned tasks',

  //General settings
  'app.pages.generalSettings.assistantBotsSettings': 'Assistant bots settings',
  'app.pages.generalSettings.logsSettings': 'Logs settings',
  'app.pages.generalSettings.initialPrompt': 'Initial prompt',
  'app.pages.generalSettings.customValue': 'Custom Value',
  'app.pages.generalSettings.customValueRemoved': 'Custom value removed',
  'app.pages.generalSettings.customValueSaved': 'Custom value saved successfully',
  'app.pages.generalSettings.paramsPlaceholder': 'Enter the parameters in json format',
  'app.pages.generalSettings.applicationAssistantSuggestedInstructions':
    'Application assistant suggested instructions (system prompt) - {engineType}',

  //App Configuration
  'app.pages.app.appConfiguration': 'App configuration',
  'app.pages.app.installLibrariesOnOpen': 'Install libraries on open',
  'app.pages.app.openDefaultVersionOnOpen': 'Open default version on open',
  'app.pages.app.defaultTabOnOpen': 'Default tab on open',

  //External links
  'app.pages.externalLink.deleteMessage': 'Are you sure you want to delete the external link?',
  'app.pages.externalLink.linkDeleted': 'External link deleted',
  'app.pages.externalLink.deleteTitle': 'Delete external link',
  'app.pages.externalLink.nodeId': 'Node id',
  'app.pages.externalLink.commonInstance': 'Common instance',
  'app.pages.externalLink.apiEndpoint': 'API endpoint',
  'app.pages.externalLink.linkUpdated': 'External link updated',
  'app.pages.externalLink.customEndpoint': 'Custom endpoint',
  'app.pages.externalLink.customEndpointError': 'Custom endpoint already exists',
  'app.pages.externalLink.externalLinksNotFound': 'There are no external links',
  'app.pages.externalLink.selectApiKey': 'Select an Api key',
  'app.pages.externalLink.executor': 'Executor',

  //Api keys manager
  'app.pages.apiKeysManager.alias': 'Alias',
  'app.pages.apiKeysManager.key': 'Key',
  'app.pages.apiKeysManager.active': 'Active',
  'app.pages.apiKeysManager.lastKeyChange': 'Last key change',
  'app.pages.apiKeysManager.showApiKeys': 'Show Api keys',
  'app.pages.apiKeysManager.createApiKeys': 'Create Api key',
  'app.pages.apiKeysManager.editApiKeys': 'Edit Api key',
  'app.pages.apiKeysManager.deleteApiKeys': 'Delete Api key',
  'app.pages.apiKeysManager.noApiKeys': 'There are no Api keys',
  'app.pages.apiKeysManager.apiKey': 'Api key',
  'app.pages.apiKeysManager.deleteApiKeyMessage': 'Are you sure you want to delete the Api key?',
  'app.pages.apiKeysManager.apiKeyDeleted': 'Api key deleted',
  'app.pages.apiKeysManager.apiKeyUpdated': 'Api key updated',
  'app.pages.apiKeysManager.apiKeyCreated': 'Api key created',
  'app.pages.apiKeysManager.keyCopied': 'Key copied',
  'app.pages.apiKeysManager.saveKeyAlert':
    'Attention! The Api key will only be visible on this occasion. Make sure to copy it now',
  'app.pages.apiKeysManager.keyId': 'Key id',

  //App consolidation
  'app.pages.appConsolidation.sourceAppVersion': 'Source app version',
  'app.pages.appConsolidation.differences': 'Differences',
  'app.pages.appConsolidation.summary': 'Summary',
  'app.pages.appConsolidation.businessRules': 'Business rules',
  'app.pages.appConsolidation.includeAllModules': 'Include all',
  'app.pages.appConsolidation.includeAllInterfaces': 'Include all',
  'app.pages.appConsolidation.replaceMatchingNodes': 'Replace matching nodes outside these modules',
  'app.pages.appConsolidation.versionMetadata': 'Version metadata',
  'app.pages.appConsolidation.itemAdded': '{name} added',
  'app.pages.appConsolidation.itemDeleted': '{name} deleted',
  'app.pages.appConsolidation.itemEdited': '{name} edited',
  'app.pages.appConsolidation.itemsAdded': '{name} added',
  'app.pages.appConsolidation.itemsDeleted': '{name} deleted',
  'app.pages.appConsolidation.itemsEdited': '{name} edited',
  'app.pages.appConsolidation.noChanges': 'No changes',
  'app.pages.appConsolidation.confirmationDialogTitle': 'Confirm consolidation changes',
  'app.pages.appConsolidation.confirmationDialogText1':
    'Are you sure you want to commit these changes?',
  'app.pages.appConsolidation.confirmationDialogText2':
    'Changes applied to files and version metadata are irreversible.',
  'app.pages.appConsolidation.compareDefinitionOnly': 'Compare definition only',

  //Office addin
  'app.pages.office.selectNode': 'Select a node',

  //Notifications
  'app.pages.notifications.scheduler.taskFinished':
    'The task {match1} has finished with state {match2}',
  'app.pages.notifications.workflow.taskReady':
    'The task {match1} of the process {match2} is ready to be started',
  'app.pages.notifications.workflow.taskOverdueExtended':
    'The task {match1} of the process {match2} is overdue. This may affect the completion of your task {match3}',
  'app.pages.notifications.workflow.taskOverdue':
    'The task {match1} of the process {match2} is overdue',
  'app.pages.notifications.workflow.taskChangedStatus':
    'The task {match1} of the process {match2} has changed status from {match3} to {match4}',
  'app.pages.notifications.workflow.processStarted': 'The process {match1} has started',
  'app.pages.notifications.workflow.processUpToDateAgain':
    'The process {match1} is up to date again',
  'app.pages.notifications.workflow.wroteComment':
    '{match1} wrote the following comment in task {match2} of the process {match3}: {match4}',
  'app.pages.notifications.title': 'Notifications',
  'app.pages.notifications.allNotifications': 'All',
  'app.pages.notifications.unReadNotifications': 'Unread',
  'app.pages.notifications.noNotifications': 'No notifications',
  'app.pages.notifications.noUnreadNotifications': 'No unread notifications',
  'app.pages.notifications.deleteNotifications': 'Delete notification',
  'app.pages.notifications.markAsRead': 'Mark as read',
  'app.pages.notifications.maskAsUnread': 'Mark as unread',
  'app.pages.notifications.notificationDeleted': 'Notification deleted',
  'app.pages.notifications.markAllAsRead': 'Mark all as read',

  // Assistant bots
  'app.pages.assistantBots.send': 'Send',
  'app.pages.assistantBots.clearMessages': 'Clear messages',
  'app.pages.assistantBots.copiedCode': 'Copied code',
  'app.pages.assistantBots.updateCurrentNode': 'Paste on selected node',
  'app.pages.assistantBots.askAgain': 'Ask again',
  'app.pages.assistantBots.showPrivacyStatement': 'Privacy statement',
  'app.pages.assistantBots.chatSuccessfullyExported': 'Chat successfully exported',
  'app.pages.assistantBots.ask': 'Ask',
  'app.pages.assistantBots.askAboutCoding': 'Ask about coding',
  'app.pages.assistantBots.assistantBotsManager': 'Assistant bots manager',
  'app.pages.assistantBots.instructionsSystemPrompt': 'Instructions (system prompt)',
  'app.pages.assistantBots.AIFramework': 'AI framework',
  'app.pages.assistantBots.llmModel': 'LLM model',
  'app.pages.assistantBots.filesInDocsFolder': 'Files in documents folder',
  'app.pages.assistantBots.maxFileSizeWarning': 'The maximum file size is {value}',
  'app.pages.assistantBots.maxFilesCount': 'The maximum number of files to upload is {value}',
  'app.pages.assistantBots.advancedSettings': 'Advanced settings',
  'app.pages.assistantBots.createAssistant': 'Create assistant',
  'app.pages.assistantBots.createNewAssistant': 'Create new assistant',
  'app.pages.assistantBots.updateAssistant': 'Update assistant',
  'app.pages.assistantBots.assistantId': 'Assistant ID',
  'app.pages.assistantBots.updateAssistantWithCurrentFilesInFolder':
    'Update assistant with current files in documentation folder',
  'app.pages.assistantBots.addNodesInfoFile': 'Add file with nodes information',
  'app.pages.assistantBots.addInterfacesInfoFile': 'Add file with interfaces information',
  'app.pages.assistantBots.assistantSuccessfullyCreated':
    'Assistant {value} was successfully created',
  'app.pages.assistantBots.assistantSuccessfullyUpdated':
    'Assistant {value} was successfully updated',
  'app.pages.assistantBots.selectAssistantBot': 'Select assistant bot',
  'app.pages.assistantBots.setAsDefaultAppAssistantBot': 'Set as default assistant',
  'app.pages.assistantBots.isDefaultAssistantBot': 'Is the default assistant bot of the app',
  'app.pages.assistantBots.activateAssistantBot': 'Activate assistant',
  'app.pages.assistantBots.deactivateAssistantBot': 'Deactivate assistant',
  'app.pages.assistantBots.active': 'Active',
  'app.pages.assistantBots.deleteAssistantBotTitle': 'Delete assistant bot',
  'app.pages.assistantBots.botAssistantDeleted': 'Bot assistant "{name}" was deleted',
  'app.pages.assistantBots.botAssistantsDeleted': 'Bot assistants "{names}" were deleted',
  'app.pages.assistantBots.deleteAssistantBotMessage':
    'Are you sure you want to delete assistant bot {name}?',
  'app.pages.assistantBots.deleteAssistantBotsMessage':
    'Are you sure you want to delete assistant bots {names}?',
  'app.pages.assistantBots.selectedAssistantBotChanged': 'Bot assistant selected',
  'app.pages.assistantBots.selectAssistantBotMessage':
    'Are you sure you want to select assistant "{name}" as the default assistant of the app?',
  'app.pages.assistantBots.activateAssistantBotMessage':
    'Are you sure you want to {action} assistant "{name}"?',
  'app.pages.assistantBots.manageDocumentationFiles': 'Manage documentation files',
  'app.pages.assistantBots.addFiles': 'Add files',
  'app.pages.assistantBots.deleteSelectedFiles': 'Delete selected files',
  'app.pages.assistantBots.uploadFiles': 'Upload files',
  'app.pages.assistantBots.initialAssistantMessage': 'What topic do you need assistance with?',
  'app.pages.assistantBots.howCanIHelpYou': 'How can I help you?',
  'app.pages.assistantBots.testAssistant': 'Test assistant',
  'app.pages.assistantBots.testAssistantAction': 'Test assistant',
  'app.pages.assistantBots.openAIAssistant': 'OpenAI assistant',
  'app.pages.assistantBots.haystack': 'Haystack',
  'app.pages.assistantBots.codeAssistants': 'Code assistants',
  'app.pages.assistantBots.codeAssistant': 'Show/hide code assistant',
  'app.pages.assistantBots.codeBugFixer': 'Code bug fixer',
  'app.pages.assistantBots.codeOptimizer': 'Code optimizer',
  'app.pages.assistantBots.codeCompleter': 'Code completer',
  'app.pages.assistantBots.includeFiles': 'Include files',
  'app.pages.assistantBots.newAssistant': 'New assistant',
  'app.pages.assistantBots.noAssistantSelected': 'No assistant selected',

  // Secrets manager
  'app.pages.secretsManager.secretManager': 'Secrets manager',
  'app.pages.secretsManager.createSecret': 'Create secret',
  'app.pages.secretsManager.editSecret': 'Edit secret',
  'app.pages.secretsManager.deleteSecret': 'Delete secret',
  'app.pages.secretsManager.deleteSecretConfirmation':
    'Are you sure you want to delete the secret?',
  'app.pages.secretsManager.secretDeleted': 'Secret deleted',
  'app.pages.secretsManager.secretUpdated': 'Secret updated',
  'app.pages.secretsManager.secretCreated': 'Secret created',
  'app.pages.secretsManager.secretKey': 'Secret key',
  'app.pages.secretsManager.secretValue': 'Secret value',
  'app.pages.secretsManager.noSecrets': 'There are no secrets',
  'app.pages.secretsManager.secret': 'Secret',
  'app.pages.secretsManager.allUsers': 'All users',
  'app.pages.secretsManager.showSecretValue': 'Show secret value',
  'app.pages.secretsManager.hideSecretValue': 'Hide secret value',
  'app.pages.secretsManager.secretKeyExists': 'Secret key already exists'
}

export default messages
