import { all, call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { CreateOrUpdateUserParams, EditUserProfileParams, UserAuthData } from 'common/types/user'
import { InstanceData, InstancesResult, UserInstanceData } from 'common/types/instance'
import { LicenseData } from 'common/types/license'
import { enqueue } from 'common/notifier'
import {
  INSTANCE_ID,
  LAST_COMPANY_SELECTED,
  SAML_COMPANY_SELECTED,
  SELECTED_CODE_LAYOUT
} from 'common/api/storageConsts'
import { ApplicationState } from 'store'
import {
  addBroadcastChannel,
  openApp,
  removeBroadcastChannel,
  resetAppInfo,
  setAppHasUnsavedChanges,
  setOpenApp
} from 'store/application/actions'
import { startWSServer, stopWSServer } from 'store/socket/actions'
import { userApi } from 'services/user'
import { securityApi } from 'services/security'
import { applicationApi } from 'services/application'
import {
  UserActionTypes,
  IdNumberPayload,
  HistoryAndStringPayload,
  SKStringAndModelinfoPayload,
  HistoryPayload,
  UserInstanceDataPayload
} from './types'
import {
  setUserAuthData,
  setSelectedCompanyId,
  setUserInstanceData,
  setInvalidLicenseData,
  setInvalidAuthErrorMessage,
  setCodeLayouts,
  loadCodeLayouts,
  setSelectedCodeLayout,
  setLastPath,
  logout,
  setNeedToChangePassword,
  isLoadingCompany
} from './actions'
import { hardcodedLayouts } from './hardcodedCodeLayouts'
import { setExternalLayout } from 'store/interface'
import { enqueueNotification } from 'store/notifier'
import closeAction from 'common/notifier/CloseBtnEnq'
import { OptionsObject } from 'notistack'
import { licenseApi } from 'services/license'
import { NavigateFunction } from 'react-router'
import { API_BASE_URL } from 'common/api/connectionConsts'

export function* authorize({
  username,
  password
}: {
  username: string
  password: string
}): Generator<AnyAction, UserAuthData | undefined, any> {
  try {
    yield call(securityApi.setCSRF)
    const res = yield call(securityApi.logIn, { username, password })
    yield put(setInvalidAuthErrorMessage(null))
    return res
  } catch (error: any) {
    let errorMsg = ''
    if (error.response?.data) {
      const property = Object.keys(error.response.data)
      property.forEach((prop) => {
        const propValue = error.response.data[prop]
        if (Array.isArray(propValue)) {
          propValue.forEach((pv) => {
            errorMsg += `${pv} `
          })
        } else {
          errorMsg += `${propValue} `
        }
      })
    } else {
      errorMsg = error.message || error.code
    }
    yield put(setInvalidAuthErrorMessage(errorMsg))
  }
}

function* handleLogin({ payload: { username, password } }: AnyAction) {
  const winner: { auth: UserAuthData; logout: never } = yield race({
    auth: call(authorize, { username, password }),
    logout: take(UserActionTypes.LOGOUT)
  })
  if (winner?.auth?.companies) {
    yield put(setLastPath(undefined))
    yield put(setUserAuthData(winner.auth))
    if (winner.auth.password_has_expired) {
      yield put(setNeedToChangePassword(true))
    } else if (!winner.auth.mfa_enabled || winner.auth.otp_verified) {
      if (winner.auth.companies.length === 1) {
        yield selectCompany(winner.auth.companies[0].id)
      } else {
        const selectedCompanyId: string | number =
          localStorage.getItem(LAST_COMPANY_SELECTED) || winner.auth.companies[0].id
        yield put(setSelectedCompanyId(+selectedCompanyId))
      }
    }
  }
}

function* handleContinueLogin({ payload: { companies } }: AnyAction) {
  if (companies.length === 1) {
    yield selectCompany(companies[0].id)
  } else {
    const selectedCompanyId: string | number =
      localStorage.getItem(LAST_COMPANY_SELECTED) || companies[0].id
    yield put(setSelectedCompanyId(+selectedCompanyId))
  }
}

function* selectCompany(companyId: number) {
  try {
    yield put(isLoadingCompany(true))
    yield sessionStorage.removeItem(INSTANCE_ID)
    localStorage[LAST_COMPANY_SELECTED] = companyId
    yield put(setSelectedCompanyId(companyId))
    const [success, data]: [boolean, LicenseData | UserInstanceData | string] = yield call(
      securityApi.createInstance,
      {
        companyId
      }
    )
    if (success) {
      const userInstance = data as UserInstanceData
      sessionStorage[INSTANCE_ID] = userInstance.id
      yield put(setUserInstanceData(userInstance))
      yield put(loadCodeLayouts())
    } else {
      const key = (new Date().getTime() + Math.random()).toString()
      const options: OptionsObject = {
        variant: 'warning',
        persist: false,
        action: () => closeAction(key)
      }
      if (data instanceof String) {
        yield put(
          enqueueNotification({
            key,
            message: data as string,
            options
          })
        )
      } else if (data instanceof Object && 'activation_code' in data) {
        yield put(setInvalidLicenseData(data as LicenseData))
        yield put(
          enqueueNotification({
            key,
            message: data.text,
            options
          })
        )
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(isLoadingCompany(false))
  }
}

function* handleSamlSelectCompany({ payload: { id, navigate } }: any) {
  yield selectCompany(id)
  navigate('/')
}

function* handleSelectCompany({ payload: { id } }: IdNumberPayload) {
  yield selectCompany(id)
}

function* handleChangeCompany() {
  let userInstance: UserInstanceData = {} as UserInstanceData
  userInstance = yield call(securityApi.getInstance)
  /**
   * Load redux state using instance data.
   */
  if (userInstance.applicationInfo.name !== '') {
    yield put(setOpenApp(userInstance.applicationInfo))
  }
  if (userInstance.companyName) {
    yield put(setUserInstanceData(userInstance))
    yield put(startWSServer())
    yield put(loadCodeLayouts())
  }
}

function* handleLoadUserInstanceData({
  payload: { navigate, data, pathname, disableOpenWithLoginAction }
}: HistoryAndStringPayload) {
  /**
   * It is assumed that the user is authenticated.
   */
  try {
    let instanceId: string | null = null
    let instancesResult = {} as InstancesResult

    if (data) {
      /**
       * If theres an Instance Id in the URL Query String, set SessionStorage with it.
       * So it will be used in Axios Request Interceptor as header.
       */
      sessionStorage[INSTANCE_ID] = data
      instanceId = data
    }

    // Get user authentication data.
    const authData: UserAuthData = yield call(userApi.userInfo)
    yield put(setUserAuthData(authData))
    instanceId = sessionStorage.getItem(INSTANCE_ID)
    const samlCompanySelected = localStorage.getItem(SAML_COMPANY_SELECTED)

    /**
     * If User has to change password or has to verify OTP and is not logged in with SAML, logout.
     * Else, continue loading user instance data.
     */
    if (
      !authData.last_login_with_saml &&
      !samlCompanySelected &&
      (authData.password_has_expired || (authData.mfa_enabled && !authData.otp_verified)) &&
      !instanceId
    ) {
      yield put(logout(navigate))
    } else {
      instancesResult = yield call(
        getInstancesResults,
        pathname,
        instanceId,
        authData,
        navigate,
        instancesResult
      )
      if (instancesResult.activesInstances.length > 1) {
        const companyIdFromLocalStorage = localStorage.getItem(LAST_COMPANY_SELECTED)
        if (companyIdFromLocalStorage) {
          navigate('/')
          yield selectCompany(+companyIdFromLocalStorage)
        } else {
          navigate('/auth/')
        }
      } else {
        yield loadReduxState(instancesResult.userInstance, navigate, disableOpenWithLoginAction)
        if (
          authData.last_login_with_saml &&
          instancesResult.userInstance &&
          samlCompanySelected === instancesResult.userInstance.company_code
        ) {
          setSamlCookie(instancesResult.userInstance)
        }
      }
      // Create a new BroadcastChannel to communicate session changes between tabs
      new BroadcastChannel('session-channel')
    }
  } catch (error) {
    yield navigate('/auth/')
  }
}

/**
 * Returns an object with the userInstanceData and instanceData to be used to set redux state.
 */
function* getInstancesResults(
  pathname: string,
  instanceId: string | null,
  authData: UserAuthData,
  navigate: NavigateFunction,
  instancesResult: InstancesResult
) {
  let companyId: number | string | null = null
  let activesInstances: InstanceData[] = []
  let userInstance: UserInstanceData = {} as UserInstanceData

  if (pathname !== '/' && !instanceId) {
    /**
     * When user need to recover instances opened in other tabs.
     */
    if (authData.last_login_with_saml) {
      companyId = yield getCompanyIdFromSamlLogin(authData, companyId)
    } else {
      companyId = localStorage.getItem(LAST_COMPANY_SELECTED)
    }
    if (companyId) {
      const instances: InstanceData[] = yield call(securityApi.getMyCompanyInstances, companyId)
      activesInstances = instances.filter(
        (instance) =>
          instance.currentApplicationName !== '' && companyId && instance.companyId === +companyId
      )

      if (activesInstances.length === 1) {
        instanceId = activesInstances[0].id
        sessionStorage[INSTANCE_ID] = instanceId
        userInstance = yield call(securityApi.getInstance)
        const applicationInfo = userInstance.applicationInfo
        yield put(
          addBroadcastChannel(
            `app-info-${applicationInfo.id}-${applicationInfo.name}-${applicationInfo.version}-${applicationInfo.engineParams}`
          )
        )
      }
    }
  }
  if (
    (!instanceId && authData.companies.length === 1 && pathname === '/') ||
    (!instanceId && authData.last_login_with_saml && pathname === '/')
  ) {
    /**
     * Option Base. Creates a new instance if theres no instance id available
     * and there is only one company.
     * or if the user logged in with saml and there is not instance id available.
     */
    companyId = yield getCompanyIdFromSamlLogin(authData, companyId)

    if (!companyId) {
      companyId = authData.companies[0].id
    }
    yield selectCompany(companyId as number)
    // Get userInstanceData from store created in selectCompany
    const userInstanceData: UserInstanceData = yield select(
      (state: ApplicationState) => state.user.instanceData
    )
    if (userInstanceData) {
      userInstance = userInstanceData
      sessionStorage[INSTANCE_ID] = userInstance.id
    } else {
      const [success, data]: [true, UserInstanceData] | [false, LicenseData | any] = yield call(
        securityApi.createInstance,
        {
          companyId: companyId as number
        }
      )
      if (success) {
        userInstance = data
        sessionStorage[INSTANCE_ID] = userInstance.id
      } else {
        navigate('/auth/')
      }
    }
  } else {
    /**
     * Option Secondary. Get the instance using the instanceId saved in sessionStorage.
     */
    if (activesInstances.length === 0) {
      userInstance = yield call(securityApi.getInstance)
    }
  }
  instancesResult.activesInstances = activesInstances
  instancesResult.userInstance = userInstance
  return instancesResult
}

function* loadReduxState(
  userInstance: UserInstanceData,
  navigate: NavigateFunction,
  disableOpenWithLoginAction?: boolean
) {
  /**
   * Load redux state using instance data.
   */
  if (userInstance.applicationInfo.name !== '') {
    yield put(setOpenApp(userInstance.applicationInfo))
  }
  if (userInstance.companyName) {
    yield put(setUserInstanceData(userInstance))
    yield put(startWSServer())
    yield put(loadCodeLayouts())
    try {
      if (userInstance.companyId) {
        yield call(licenseApi.checkDaysToExpire, userInstance.companyId)
      }
    } catch (error: any) {
      yield put(enqueue(error))
    }
    if (userInstance.applicationInfo.id !== '') {
      try {
        const status: boolean = yield call(applicationApi.getAppStatus)
        yield put(setAppHasUnsavedChanges(status))
      } catch (error: any) {
        yield put(enqueue(error))
      }
    }
    if (
      userInstance.loginAction &&
      userInstance.loginAction?.open_app &&
      !disableOpenWithLoginAction
    ) {
      yield put(openApp({ navigate, fullPath: userInstance.loginAction.open_app }))
    }
  } else {
    navigate('/auth/')
  }
}

/**
 * Set samlLink cookie if it doesn't exist when user logs in with saml.
 */
const setSamlCookie = (userInstance: UserInstanceData) => {
  const samlCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith(`samlLink${userInstance.companyName}=`))
  if (!samlCookie && userInstance.company_code && userInstance.companyName) {
    const baseURL =
      process.env.NODE_ENV === 'production' ? `${window.location.origin}` : API_BASE_URL
    const expires = new Date(Date.now() + 100 * 365 * 24 * 60 * 60 * 1000).toUTCString()
    document.cookie = `samlLink${userInstance.companyName}=${encodeURIComponent(
      `${baseURL}/saml/${userInstance.company_code}`
    )}; path=/; expires=${expires}; SameSite=None; Secure`
  }
}

function getCompanyIdFromSamlLogin(authData: UserAuthData, companyId: number | string | null) {
  // get last samlCompanySelected from localStorage
  const samlCompanySelected = localStorage.getItem(SAML_COMPANY_SELECTED)
  // find the company in authData
  if (samlCompanySelected) {
    const company = authData.companies.find((company) => company.code === samlCompanySelected)
    if (company) {
      companyId = company.id
    }
  }
  return companyId
}

function* handleChangeActiveInstance({
  payload: { newInstanceId, newApplicationInfo }
}: SKStringAndModelinfoPayload) {
  try {
    const instanceData: UserInstanceData = yield call(securityApi.getInstance)
    const instanceWithNewData: UserInstanceData = {
      ...instanceData,
      id: newInstanceId,
      applicationInfo: newApplicationInfo
    }
    sessionStorage[INSTANCE_ID] = newInstanceId
    yield put(setOpenApp(newApplicationInfo))
    yield put(setUserInstanceData(instanceWithNewData))
    yield put(
      addBroadcastChannel(
        `app-info-${newApplicationInfo.id}-${newApplicationInfo.name}-${newApplicationInfo.version}-${newApplicationInfo.engineParams}`
      )
    )
    yield put(startWSServer())
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleActivateExternalInstance({
  payload: { userInstanceData }
}: UserInstanceDataPayload) {
  try {
    sessionStorage[INSTANCE_ID] = userInstanceData.id
    yield put(setOpenApp(userInstanceData.applicationInfo))
    yield put(setUserInstanceData(userInstanceData))
    yield put(startWSServer())
    yield put(setExternalLayout(true))
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleLogout({ payload: { navigate } }: HistoryPayload) {
  try {
    yield call(securityApi.logOut)
    yield put(removeBroadcastChannel())
    yield navigate('/auth/', { replace: true })
    yield put(stopWSServer())
    yield sessionStorage.removeItem(INSTANCE_ID)
    yield localStorage.removeItem(SAML_COMPANY_SELECTED)
    yield put(setUserAuthData(null))
    yield put(setUserInstanceData(null))
    yield put(setInvalidAuthErrorMessage(null))
    yield put(resetAppInfo())
    // post message to session channel to close all tabs
    const sessionChannel = new BroadcastChannel('session-channel')
    sessionChannel.postMessage('close')
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleLoadCodeLayouts() {
  // TODO: retrieve layouts from API
  // import { CodeLayouts } from '../../common/types/code'
  // const codeLayouts: CodeLayouts = yield call(modelApi.getLayouts)
  yield put(setCodeLayouts(hardcodedLayouts))
  const selectedCodeLayout = localStorage.getItem(SELECTED_CODE_LAYOUT)
  yield put(
    setSelectedCodeLayout(
      selectedCodeLayout ? parseInt(selectedCodeLayout) : hardcodedLayouts[0].id
    )
  )
}

function* handleEditUserProfile({ payload: { formValues, navigate } }: AnyAction) {
  try {
    const instanceData = ((state: ApplicationState) => state.user.instanceData)(yield select())
    const authData = ((state: ApplicationState) => state.user.authData)(yield select())
    const dataToUpdate: EditUserProfileParams = { id: formValues?.id || '' }
    if (formValues.first_name !== instanceData?.userFirstName) {
      dataToUpdate.first_name = formValues.first_name
    }
    if (formValues.last_name !== instanceData?.userLastName) {
      dataToUpdate.last_name = formValues.last_name
    }
    if (formValues.email !== authData?.email) {
      dataToUpdate.email = formValues.email
    }
    if (
      formValues.changePassword &&
      formValues.password &&
      formValues.confirmPassword &&
      formValues.currentPassword
    ) {
      dataToUpdate.password = formValues.password
      dataToUpdate.current_password = formValues.currentPassword
    }
    const updatedUser: CreateOrUpdateUserParams = yield call(
      userApi.updateUserProfile,
      dataToUpdate
    )
    if (updatedUser && authData && instanceData) {
      if (!formValues.changePassword) {
        const newAuthData: UserAuthData = {
          ...authData,
          email: dataToUpdate.email || authData.email
        }
        const newInstanceData: UserInstanceData = {
          ...instanceData,
          userFirstName: dataToUpdate.first_name || instanceData.userFirstName,
          userLastName: dataToUpdate.last_name || instanceData.userLastName,
          userFullName: `${dataToUpdate.first_name || instanceData.userFirstName} ${
            dataToUpdate.last_name || instanceData.userLastName
          }`
        }
        if (dataToUpdate.first_name || dataToUpdate.last_name) {
          yield put(setUserInstanceData(newInstanceData))
        }
        if (dataToUpdate.email) {
          yield put(setUserAuthData(newAuthData))
        }
        navigate('/')
      } else {
        yield put(logout(navigate))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* watchSecurityActions() {
  yield all([
    takeLatest(UserActionTypes.LOAD_USER_INSTANCE_DATA, handleLoadUserInstanceData),
    takeLatest(UserActionTypes.LOGIN, handleLogin),
    takeLatest(UserActionTypes.SELECT_COMPANY, handleSelectCompany),
    takeLatest(UserActionTypes.LOGOUT, handleLogout),
    takeLatest(UserActionTypes.CHANGE_ACTIVE_INSTANCE, handleChangeActiveInstance),
    takeLatest(UserActionTypes.EDIT_USER_PROFILE, handleEditUserProfile),
    takeLatest(UserActionTypes.CHANGE_COMPANY, handleChangeCompany),
    takeLatest(UserActionTypes.ACTIVATE_EXTERNAL_INSTANCE, handleActivateExternalInstance),
    takeLatest(UserActionTypes.CONTINUE_LOGIN, handleContinueLogin),
    takeLatest(UserActionTypes.SAML_SELECT_COMPANY, handleSamlSelectCompany)
  ])
}

function* watchUserActions() {
  yield all([takeLatest(UserActionTypes.LOAD_CODE_LAYOUTS, handleLoadCodeLayouts)])
}

function* usersSaga() {
  yield all([fork(watchSecurityActions), fork(watchUserActions)])
}

export { usersSaga }
