import { FC, lazy, useEffect } from 'react'
import { Route, Routes as RoutesDom, useLocation } from 'react-router-dom'
import { REDIRECT_TOKEN } from 'common/api/storageConsts'

const AppPage = lazy(() => import('pages/app'))
const LoginPage = lazy(() => import('pages/login'))
const SamlPage = lazy(() => import('pages/saml'))
const SharedPage = lazy(() => import('pages/shared'))
const PrivateRoute = lazy(() => import('./PrivateRoute'))
const InitialConfigPage = lazy(() => import('pages/initialConfig'))
const SharedInterfacePage = lazy(() => import('pages/interfaces/SharedInterfacePage'))
const OfficeAddin = lazy(() => import('pages/officeAddin/'))

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Routes: FC = () => {
  const query = useQuery()

  useEffect(() => {
    const rtk = query?.get('rtk')
    // store redirect token to
    rtk && sessionStorage.setItem(REDIRECT_TOKEN, rtk)
  }, [query])

  return (
    <RoutesDom>
      <Route path='/auth/' element={<LoginPage />} />
      <Route path='/resetpassword/:token' caseSensitive element={<LoginPage />} />
      <Route path='/initial-config/' element={<InitialConfigPage />} />
      <Route path='/saml/:companyCode' caseSensitive element={<SamlPage />} />
      <Route path='/shared/*' element={<SharedPage />} />
      <Route path='/office/*' element={<OfficeAddin />} />

      <Route path='/*' element={<PrivateRoute component={AppPage} />} />
      <Route
        path='/ext/:companyId/:currentInterfaceId/:externalInterfaceId'
        element={<SharedInterfacePage />}
      />
    </RoutesDom>
  )
}

export default Routes
