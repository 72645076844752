import { Reducer } from 'redux'
import { LayoutState, LayoutActionTypes } from './types'

export const initialState: LayoutState = {
  theme: 'light',
  layoutDivider: 0
}

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<LayoutState> = (
  state: LayoutState = initialState,
  action: { type: LayoutActionTypes; payload?: any }
) => {
  switch (action.type) {
    case LayoutActionTypes.SET_THEME: {
      return { ...state, theme: action.payload }
    }
    case LayoutActionTypes.SET_LAYOUT_DIVIDER: {
      return { ...state, layoutDivider: action.payload }
    }
    default: {
      return state
    }
  }
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as layoutReducer }
