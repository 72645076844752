import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'
import { GenericBooleanResponse, PaginatedResponse } from 'common/types/managers'

import {
  User,
  UserAuthData,
  ChangeUserParams,
  DownloadUsersProps,
  ToggleParams,
  CreateOrUpdateUserParams,
  EditUserProfileParams,
  UsernameOrEmailValidationProps
} from 'common/types/user'
import GenericPaginationParams from 'common/types/genericPaginationParams'

const ENDPOINT = '/users'

export class UserApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, UserApi.prototype)
    this.userInfo = this.userInfo.bind(this)
    this.userRegister = this.userRegister.bind(this)
    this.getAllUsers = this.getAllUsers.bind(this)
    this.downloadReportUsers = this.downloadReportUsers.bind(this)
    this.updateUserProfile = this.updateUserProfile.bind(this)
  }

  public async userInfo(): Promise<UserAuthData> {
    const res: AxiosResponse<UserAuthData> = await this.get<string, AxiosResponse<UserAuthData>>(
      '/whoami/'
    )
    return this.success<UserAuthData>(res)
  }

  public userRegister(user: User): Promise<number> {
    return this.post<number, User, AxiosResponse<number>>(`${ENDPOINT}/register/`, user)
      .then(this.success)
      .catch((error: AxiosError<Error>) => {
        throw error
      })
  }

  public async getAllUsers(params?: GenericPaginationParams): Promise<PaginatedResponse<User>> {
    try {
      const res: AxiosResponse<PaginatedResponse<User>> = await this.get<
        User,
        AxiosResponse<PaginatedResponse<User>>
      >(`${ENDPOINT}/`, { params: { ...params } })

      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async listUsersForSelector(
    params?: GenericPaginationParams
  ): Promise<PaginatedResponse<User>> {
    try {
      const res: AxiosResponse<PaginatedResponse<User>> = await this.get<
        User,
        AxiosResponse<PaginatedResponse<User>>
      >(`${ENDPOINT}/listUsersForSelector/`, { params: { ...params } })

      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async changeUserPassword(params: ChangeUserParams): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.patch(
        `${ENDPOINT}/update_password/`,
        params,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async downloadReportUsers(): Promise<DownloadUsersProps<string>> {
    try {
      const res: AxiosResponse<DownloadUsersProps<string>> = await this.get(
        `${ENDPOINT}/export_report/`
      )
      return { data: this.success<any>(res), headers: res.headers }
    } catch (error) {
      throw error
    }
  }
  public async getUserById(id: string): Promise<User> {
    try {
      const res: AxiosResponse<User> = await this.get(`${ENDPOINT}/${id}/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateUserProfile(params: EditUserProfileParams): Promise<CreateOrUpdateUserParams> {
    try {
      const res: AxiosResponse<CreateOrUpdateUserParams> = await this.patch(
        `${ENDPOINT}/update_user_profile/`,
        params
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async userNameOrEmailExists(
    username?: string,
    email?: string,
    userId?: string
  ): Promise<UsernameOrEmailValidationProps> {
    try {
      const query = username
        ? `?username=${username}`
        : email && userId
        ? `?email=${email}&userId=${userId}`
        : `?email=${email}`
      const res: AxiosResponse<UsernameOrEmailValidationProps> = await this.get(
        `${ENDPOINT}/username_or_email_exists/${query}`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async toggleUserActive(params: ToggleParams): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.patch(
        `/usercompanies/toggle_user_active/`,
        params,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createUser(userData: CreateOrUpdateUserParams): Promise<User> {
    try {
      const res: AxiosResponse<User> = await this.post(`/usercompanies/`, userData, {
        headers: { 'Content-Type': 'application/json' }
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateUser(id: string | number, userData: CreateOrUpdateUserParams): Promise<User> {
    try {
      const res: AxiosResponse<User> = await this.patch(`/usercompanies/${id}/`, userData, {
        headers: { 'Content-Type': 'application/json' }
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async deleteUser(id: string): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.delete(`${ENDPOINT}/${id}/`, {
        headers: { 'Content-Type': 'application/json' }
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async setNewQrCode(userId: string): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/setNewQrCode/`,
        { userId },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )

      return this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const userApi = new UserApi(apiConfig)
