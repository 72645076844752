import { Interface, NavigationHistory } from './../../pages/interfaces/types'
import { Layout } from 'react-grid-layout'

import { InterfaceActionTypes } from './types'
import {
  GenericComponent,
  IRenderComponent,
  TableStyle,
  ChartStyle,
  IndicatorStyle,
  IndicatorProperties,
  TableProperties,
  ChartProperties,
  ButtonStyle,
  ButtonProperties,
  IDynamicHTML,
  DrilldownOptionsInfo,
  FilterComponent
} from 'pages/interfaces/components/types'
import { SelectedState, TabItem } from 'common/types/model'
import {
  FilteredComponentValue,
  GridLayout,
  InterfaceEvent
} from 'pages/interfaces/containers/types'
import { ActiveTab, OpenInterfaceCurrentTab } from 'common/types/interface'

export const selectComponent = (component: GenericComponent | undefined = undefined) => {
  return {
    type: InterfaceActionTypes.SELECT_COMPONENT,
    payload: component
  }
}

export const openIndexFilterDialog = (
  componentId: string,
  field: string,
  nodeId: string,
  title: string,
  filters: (number | string)[],
  filterComponentOnly: boolean,
  singleSelect?: boolean
) => {
  return {
    type: InterfaceActionTypes.OPEN_INDEX_FILTER_DIALOG,
    payload: { componentId, field, nodeId, title, filters, filterComponentOnly, singleSelect }
  }
}

export const setIndexFilterData = (
  open: boolean,
  componentId?: string,
  field?: string,
  nodeId?: string,
  title?: string,
  filters?: (number | string)[],
  filterComponentOnly?: boolean,
  singleSelect?: boolean
) => {
  return {
    type: InterfaceActionTypes.SET_INDEX_FILTER_DATA,
    payload: { open, componentId, field, nodeId, title, filters, filterComponentOnly, singleSelect }
  }
}

export const closeIndexFilterDialog = (
  componentId: string,
  field: string,
  nodeId: string,
  title: string,
  filters: (number | string)[],
  filterComponentOnly: boolean
) => {
  return {
    type: InterfaceActionTypes.CLOSE_INDEX_FILTER_DIALOG,
    payload: { componentId, field, nodeId, title, filters, filterComponentOnly }
  }
}

export const setDrilldownOptions = (data?: DrilldownOptionsInfo) => {
  return {
    type: InterfaceActionTypes.SET_DRILLDOWN_OPTIONS,
    payload: data
  }
}

export const updateLayout = (
  interfaceId: string,
  newLayout: GridLayout,
  triggerData: InterfaceEvent,
  triggerEvent: (triggerData: InterfaceEvent) => Promise<void>
) => ({
  type: InterfaceActionTypes.UPDATE_LAYOUT,
  payload: { interfaceId, newLayout, triggerData, triggerEvent }
})

export const updateLayoutPositions = (interfaceId: string, newLayout: Layout[]) => ({
  type: InterfaceActionTypes.UPDATE_LAYOUT_POSITIONS,
  payload: { interfaceId, newLayout }
})

export const setUpdateDefaultInterfaceLayout = (result: IRenderComponent, interfaceId: string) => ({
  type: InterfaceActionTypes.UPDATE_DEFAULT_INTERFACE_LAYOUT,
  payload: { result, interfaceId }
})

export const setComponentIsLoading = (
  interfaceId: string,
  componentsList: string[],
  isLoading: boolean
) => ({
  type: InterfaceActionTypes.SET_COMPONENT_IS_LOADING,
  payload: { interfaceId, componentsList, isLoading }
})

export const setFilterComponentsLoading = (componentIds: string[]) => {
  return {
    type: InterfaceActionTypes.SET_FILTER_COMPONENT_IS_LOADING,
    payload: componentIds
  }
}

export const setDefaultInterfaceLayout = (layout: GridLayout) => ({
  type: InterfaceActionTypes.SET_DEFAULT_INTERFACE_LAYOUT,
  payload: layout
})
export const setSelectorOptionsMax = (value?: object) => ({
  type: InterfaceActionTypes.SELECTOR_OPTIONS_MAX,
  payload: value
})

export const saveLayout = (interfaceId: string, newLayout: GridLayout) => ({
  type: InterfaceActionTypes.SAVE_LAYOUT,
  payload: { interfaceId, newLayout }
})

export const setInterfaceWidgetExpanded = (componentId: string | undefined) => ({
  type: InterfaceActionTypes.SET_INTERFACE_WIDGET_EXPANDED,
  payload: componentId
})

export const setComponentToEdit = (componentId?: string, interfaceId?: string) => ({
  type: InterfaceActionTypes.SET_COMPONENT_TO_EDIT,
  payload: { componentId, interfaceId }
})

export const copyStyles = (styles: TableStyle | ChartStyle | IndicatorStyle | ButtonStyle) => ({
  type: InterfaceActionTypes.COPY_STYLES,
  payload: styles
})

export const pasteStyles = (
  componentId: string,
  interfaceId: string,
  componentProperties: TableProperties | ChartProperties | IndicatorProperties | ButtonProperties,
  fromInterface: boolean
) => ({
  type: InterfaceActionTypes.PASTE_STYLES,
  payload: { componentId, interfaceId, componentProperties, fromInterface }
})

export const setRecentlyUsedColors = (color: string) => ({
  type: InterfaceActionTypes.SET_RECENTLY_USED_COLORS,
  payload: color
})

export const setInterfaceTabs = (tabs: TabItem[]) => {
  return {
    type: InterfaceActionTypes.SET_INTERFACE_TABS,
    payload: tabs
  }
}
export const setOpenInterfaceCurrentTab = (data: OpenInterfaceCurrentTab | undefined) => {
  return {
    type: InterfaceActionTypes.SET_OPEN_INTERFACE_CURRENT_TAB,
    payload: data
  }
}

export const setInterfaceOpenedFromManager = (intId?: string) => {
  return {
    type: InterfaceActionTypes.SET_INTERFACE_OPENED_FROM_MANAGER,
    payload: intId
  }
}
export const setInterfaceOpenedFromCreation = (intId?: string) => {
  return {
    type: InterfaceActionTypes.SET_INTERFACE_OPENED_FROM_CREATION,
    payload: intId
  }
}

export const setAllInterfaces = (interfaces: Interface[]) => {
  return {
    type: InterfaceActionTypes.SET_ALL_INTERFACES,
    payload: interfaces
  }
}

export const setInterfaceWithChanges = (value: { id: string }) => {
  return {
    type: InterfaceActionTypes.SET_INTERFACE_WITH_CHANGES,
    payload: value
  }
}
export const setClickedFromTopbar = (value: boolean) => {
  return {
    type: InterfaceActionTypes.SET_CLICKED_FROM_TOPBAR,
    payload: value
  }
}
export const setChangeDefaultInterface = (value?: boolean) => {
  return {
    type: InterfaceActionTypes.CHANGE_DEFAULT_INTERFACE,
    payload: value
  }
}
export const setMenuFolderHistory = (value: {
  currentFolder: string
  folders: string[]
  componentId: string
  interfaceId?: string
}) => {
  return {
    type: InterfaceActionTypes.SET_MENU_FOLDER_HISTORY,
    payload: value
  }
}
export const setAccordionMenuItems = (item: { id: string; isOpen: boolean }) => {
  return {
    type: InterfaceActionTypes.SET_ACCORDION_MENU_ITEMS,
    payload: item
  }
}
export const setRefreshCurrentInterface = (value: boolean) => {
  return {
    type: InterfaceActionTypes.SET_REFRESH_CURRENT_INTERFACE,
    payload: value
  }
}
export const setExternalLayout = (value: boolean) => {
  return {
    type: InterfaceActionTypes.SET_EXTERNAL_LAYOUT,
    payload: value
  }
}
export const setInterfaceFromLink = (interfaceFromLink: string) => {
  return {
    type: InterfaceActionTypes.SET_INTERFACE_FROM_LINK,
    payload: interfaceFromLink
  }
}
export const setExternalInterfaceId = (externalInterfaceId: string) => {
  return {
    type: InterfaceActionTypes.SET_EXTERNAL_INTERFACE_ID,
    payload: externalInterfaceId
  }
}

export const setLastAccessedPath = (path?: string) => {
  return {
    type: InterfaceActionTypes.SET_LAST_ACCESSED_PATH,
    payload: path
  }
}

export const setCurrentNavigationHistory = (currentNavigation: NavigationHistory[]) => {
  return {
    type: InterfaceActionTypes.SET_CURRENT_NAVIGATION_HISTORY,
    payload: currentNavigation
  }
}

export const setEditorDynamicHtml = (component?: IDynamicHTML) => {
  return {
    type: InterfaceActionTypes.SET_EDITOR_DYNAMIC_HTML,
    payload: component
  }
}

export const setVerticalDynamicHtmlSize = (size: number) => {
  return {
    type: InterfaceActionTypes.SET_VERTICAL_DYNAMIC_HTML_SIZE,
    payload: size
  }
}

export const setVerticalActionsDynamicHtmlSize = (size: number) => {
  return {
    type: InterfaceActionTypes.SET_VERTICAL_ACTIONS_DYNAMIC_HTML_SIZE,
    payload: size
  }
}

export const setHorizontalDynamicHtmlSize = (size: number) => {
  return {
    type: InterfaceActionTypes.SET_HORIZONTAL_DYNAMIC_HTML_SIZE,
    payload: size
  }
}

export const setHorizontalActionsDynamicHtmlSize = (size: number) => {
  return {
    type: InterfaceActionTypes.SET_HORIZONTAL_ACTIONS_DYNAMIC_HTML_SIZE,
    payload: size
  }
}
export const setOpenDefaultInterfaceFromTopbar = (value?: boolean) => {
  return {
    type: InterfaceActionTypes.SET_OPEN_DEFAULT_INTERFACE_FROM_TOPBAR,
    payload: value
  }
}
export const setEditingInterface = (value?: boolean) => {
  return {
    type: InterfaceActionTypes.SET_EDITING_INTERFACE,
    payload: value
  }
}

export const setScrollRowTable = (value?: number) => {
  return {
    type: InterfaceActionTypes.SET_SCROLL_ROW_TABLE,
    payload: value
  }
}

export const setScrollColumnTable = (value?: number) => {
  return {
    type: InterfaceActionTypes.SET_SCROLL_COLUMN_TABLE,
    payload: value
  }
}

export const setLastComponentDrilldowned = (value?: string) => {
  return {
    type: InterfaceActionTypes.LAST_COMPONENT_DRILLDOWNED,
    payload: value
  }
}
export const setActiveTab = (activeTab: ActiveTab) => {
  return {
    type: InterfaceActionTypes.SET_ACTIVE_TAB,
    payload: activeTab
  }
}

export const updateActiveTab = (activeTab: ActiveTab) => {
  return {
    type: InterfaceActionTypes.UPDATE_ACTIVE_TAB,
    payload: activeTab
  }
}

export const removeActiveTab = (InactiveTabId: string) => {
  return {
    type: InterfaceActionTypes.REMOVE_ACTIVE_TAB,
    payload: InactiveTabId
  }
}

export const setFilteredComponentValue = (filterComponentValue: FilteredComponentValue) => {
  return {
    type: InterfaceActionTypes.SET_FILTERED_COMPONENT_VALUE,
    payload: filterComponentValue
  }
}

export const setSelectedOptionState = (selectedState?: SelectedState) => {
  return {
    type: InterfaceActionTypes.SET_SELECTED_OPTION_STATE,
    payload: selectedState
  }
}

export const removeActivesTabs = () => {
  return {
    type: InterfaceActionTypes.REMOVE_ACTIVES_TABS
  }
}

export const loadingTabMenu = (loading: boolean) => {
  return {
    type: InterfaceActionTypes.LOADING_TAB_MENU,
    payload: loading
  }
}

export const callDefaultLayout = () => {
  return {
    type: InterfaceActionTypes.CALL_DEFAULT_LAYOUT
  }
}

export const setApplicationComponent = (interfaceId: string, appComponent: IRenderComponent) => {
  return {
    type: InterfaceActionTypes.SET_APPLICATION_COMPONENT,
    payload: { interfaceId, appComponent }
  }
}

export const updateApplicationComponent = (appComponent: GenericComponent, interfaceId: string) => {
  return {
    type: InterfaceActionTypes.UPDATE_APPLICATION_COMPONENT,
    payload: { appComponent, interfaceId }
  }
}

export const removeApplicationComponents = (interfaceId: string) => {
  return {
    type: InterfaceActionTypes.REMOVE_APPLICATION_COMPONENTS,
    payload: interfaceId
  }
}

export const removeApplicationComponent = (interfaceId: string, componentId: string) => {
  return {
    type: InterfaceActionTypes.REMOVE_APPLICATION_COMPONENT,
    payload: { interfaceId, componentId }
  }
}

export const setReloadLayout = () => {
  return {
    type: InterfaceActionTypes.SET_RELOAD_LAYOUT
  }
}

export const setTabChanged = (tabValue: boolean) => {
  return {
    type: InterfaceActionTypes.SET_TAB_CHANGED,
    payload: tabValue
  }
}

export const addFilterToFilterComponentState = (filter: FilterComponent) => {
  return {
    type: InterfaceActionTypes.ADD_FILTER_COMPONENT_STATE,
    payload: filter
  }
}

export const removeFilterFromFilterComponentState = (componentId: string) => {
  return {
    type: InterfaceActionTypes.REMOVE_FILTER_COMPONENT_STATE,
    payload: componentId
  }
}

export const updateFilterComponentStateResult = (
  filterComponentStateResult: FilteredComponentValue
) => {
  return {
    type: InterfaceActionTypes.UPDATE_FILTER_COMPONENT_STATE_RESULT,
    payload: filterComponentStateResult
  }
}

export const orderFilterComponentState = (order: string[]) => {
  return {
    type: InterfaceActionTypes.ORDER_FILTER_COMPONENT_STATE,
    payload: order
  }
}

export const setFilterComponentDialogOpen = (filterComponent?: string) => {
  return {
    type: InterfaceActionTypes.SET_FILTER_COMPONENT_DIALOG_OPEN,
    payload: filterComponent
  }
}

export const setOpenFilterComponentDialog = (open: boolean) => {
  return {
    type: InterfaceActionTypes.SET_OPEN_FILTER_COMPONENT_DIALOG,
    payload: open
  }
}
