import { AssistantBotKey } from 'store/assistantBot'

export enum AssistantBotChatSource {
  user = 'user',
  assistant = 'assistant'
}

interface AssistantBotChatOption {
  assistantType: AssistantBotAssistantType
  assistantId?: string | null
  assistantEngineType?: AssistantBotEngineType
  label: string
}

export interface AssistantBotChatMessage {
  role: AssistantBotChatSource
  content?: string
  options?: AssistantBotChatOption[]
  filter?: boolean
}

export interface AssistantBotProps {
  title: string
  stateAssistantBotKey: AssistantBotKey
  placeholder: string
  isInterfaceWidget?: boolean
  headerColor?: string
  headerBackground?: string
  onContentLoaded?: () => void
  availableAssistants: AssistantBotAvailableAssistant[]
  loadingAvailableAssistants?: boolean
  showAssistantLabel?: boolean
  onClose?: () => void
}

export enum AssistantBotAssistantType {
  code = 'code',
  pyplan = 'pyplan',
  application = 'application',
  application_tests = 'application_tests'
}

export interface AssistantBotAvailableAssistant {
  assistantType: AssistantBotAssistantType
  assistantId?: string | null
  assistantEngineType?: AssistantBotEngineType
  label: string
  labelBackgroundColor: string
  labelColor: string
}

export enum AssistantBotLlmSource {
  openai = 'openai'
}

export enum AssistantBotEngineType {
  openai_assistant = 'openai_assistant',
  haystack = 'haystack'
}

export interface BaseAssistantBotSettings {
  id: string
  engineType: AssistantBotEngineType
  name?: string
  description?: string
  instructions?: string
  model?: string
  llmSource?: AssistantBotLlmSource
  reindexDocuments?: boolean
  addNodesInfoFile?: boolean
  addInterfacesInfoFile?: boolean
  advancedSettings?: { [key: string]: any }
  active?: boolean
}

export interface OpenAIAssistantBot extends BaseAssistantBotSettings {
  llmSource?: AssistantBotLlmSource.openai
}

export interface HaystackAssistantBot extends BaseAssistantBotSettings {}

export interface AssistantBotsData {
  count: number
  next: string | null
  previous: string | null
  results: BaseAssistantBotSettings[]
}

export interface AssistantBotProperties {
  isInterfaceWidget?: boolean
  headerColor?: string
  headerBackground?: string
  onContentLoaded?: () => void
}

export interface GetCompanyApplicationAssistantsParams {
  searchText?: string | null
  engineTypes?: AssistantBotEngineType[]
  page?: number
  pageSize?: number
  throwNoApiKeyError?: boolean
}
