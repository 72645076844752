import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'
import {
  AssistantBotAssistantType,
  BaseAssistantBotSettings,
  AssistantBotChatMessage,
  AssistantBotsData,
  AssistantBotEngineType,
  GetCompanyApplicationAssistantsParams
} from 'common/types/assistantBot'
import { MainFolder } from 'common/types/home'

const ENDPOINT = '/assistantBot'

class AssistantBotApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, AssistantBotApi.prototype)
    this.chat = this.chat.bind(this)
    this.deleteChatThread = this.deleteChatThread.bind(this)
    this.getAvailableModels = this.getAvailableModels.bind(this)
    this.createApplicationAssistant = this.createApplicationAssistant.bind(this)
    this.updateApplicationAssistant = this.updateApplicationAssistant.bind(this)
    this.partialUpdateApplicationAssistant = this.partialUpdateApplicationAssistant.bind(this)
    this.updateApplicationAssistantActiveStatus = this.updateApplicationAssistantActiveStatus.bind(
      this
    )
    this.getCompanyApplicationAssistants = this.getCompanyApplicationAssistants.bind(this)
    this.deleteApplicationAssistants = this.deleteApplicationAssistants.bind(this)
    this.uploadFileToAssistantDocsFolder = this.uploadFileToAssistantDocsFolder.bind(this)
    this.deleteFilesInAssistantDocsFolder = this.deleteFilesInAssistantDocsFolder.bind(this)
    this.tryFixNode = this.tryFixNode.bind(this)
  }

  public async chat(
    assistantType: AssistantBotAssistantType,
    chatMessage: AssistantBotChatMessage,
    chatHistory: AssistantBotChatMessage[],
    regenerateResponse: boolean,
    threadId: string | undefined | null,
    applicationAssistantId: string | undefined | null,
    engineType?: AssistantBotEngineType
  ): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(
        `${ENDPOINT}/chat/`,
        {
          assistant_type: assistantType,
          chat_message: chatMessage,
          chat_history: chatHistory,
          regenerate_response: regenerateResponse,
          thread_id: threadId,
          application_assistant_id: applicationAssistantId,
          engine_type: engineType
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async deleteChatThread(
    assistantType: AssistantBotAssistantType,
    threadId: string,
    applicationAssistantId: string | undefined | null,
    engineType?: AssistantBotEngineType
  ): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/deleteChatThread/`, {
        assistant_type: assistantType,
        thread_id: threadId,
        application_assistant_id: applicationAssistantId,
        engine_type: engineType
      })
      this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getAvailableModels(engineType?: AssistantBotEngineType): Promise<string[]> {
    try {
      const res: AxiosResponse<string[]> = await this.get(`${ENDPOINT}/getAvailableModels/`, {
        params: {
          engine_type: engineType
        }
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getFilesInAssistantDocumentsFolder(
    assistantId: string,
    engineType: AssistantBotEngineType
  ): Promise<MainFolder[]> {
    try {
      const res: AxiosResponse<MainFolder[]> = await this.get(
        `${ENDPOINT}/getFilesInAssistantDocumentsFolder/`,
        {
          params: {
            assistant_id: assistantId,
            engine_type: engineType
          }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createApplicationAssistant(
    engineType: AssistantBotEngineType,
    name: string
  ): Promise<string> {
    try {
      const data = {
        engine_type: engineType,
        name: name
      }
      const res: AxiosResponse<string> = await this.post(
        `${ENDPOINT}/createApplicationAssistant/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateApplicationAssistant(settings: BaseAssistantBotSettings): Promise<string> {
    try {
      const data = {
        engine_type: settings.engineType,
        assistant_id: settings.id,
        name: settings.name,
        description: settings.description,
        instructions: settings.instructions,
        model: settings.model,
        advanced_settings: settings.advancedSettings,
        reindex_documents: settings.reindexDocuments,
        add_nodes_info_file: settings.addNodesInfoFile,
        add_interfaces_info_file: settings.addInterfacesInfoFile,
        active: settings.active
      }
      const res: AxiosResponse<string> = await this.post(
        `${ENDPOINT}/updateApplicationAssistant/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async partialUpdateApplicationAssistant(
    settings: BaseAssistantBotSettings
  ): Promise<string> {
    try {
      const data = {
        engine_type: settings.engineType,
        assistant_id: settings.id,
        name: settings.name,
        description: settings.description,
        instructions: settings.instructions,
        model: settings.model,
        advanced_settings: settings.advancedSettings,
        reindex_documents: settings.reindexDocuments,
        active: settings.active
      }
      const res: AxiosResponse<string> = await this.patch(
        `${ENDPOINT}/partialUpdateApplicationAssistant/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateApplicationAssistantActiveStatus(
    assistantId: string,
    engineType: AssistantBotEngineType,
    active: boolean
  ): Promise<string> {
    try {
      const data = {
        engine_type: engineType,
        assistant_id: assistantId,
        active: active
      }
      const res: AxiosResponse<string> = await this.post(
        `${ENDPOINT}/updateApplicationAssistantActiveStatus/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getCompanyApplicationAssistants({
    searchText,
    engineTypes,
    page,
    pageSize,
    throwNoApiKeyError
  }: GetCompanyApplicationAssistantsParams): Promise<AssistantBotsData> {
    try {
      const res: AxiosResponse<AssistantBotsData> = await this.get(
        `${ENDPOINT}/getCompanyApplicationAssistants/`,
        {
          params: {
            search_text: searchText,
            engine_types: engineTypes,
            page,
            page_size: pageSize
          }
        }
      )
      return this.success(res)
    } catch (error: any) {
      if (
        typeof error?.response?.data === 'string' &&
        error?.response?.data.includes('OpenAI API key')
      ) {
        if (throwNoApiKeyError) {
          throw error
        } else {
          console.error(error?.response?.data)
          return { count: 0, next: null, previous: null, results: [] }
        }
      } else {
        throw error
      }
    }
  }
  public async deleteApplicationAssistants(assistants: BaseAssistantBotSettings[]): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/deleteApplicationAssistants/`, {
        assistants: assistants
      })
      this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async uploadFileToAssistantDocsFolder(data: FormData, token: CancelToken): Promise<void> {
    const res: AxiosResponse<void> = await this.post(
      `${ENDPOINT}/uploadFileToAssistantDocsFolder/`,
      data,
      {
        cancelToken: token
      }
    )
    this.success(res)
  }
  public async deleteFilesInAssistantDocsFolder(
    assistantId: string,
    engineType: AssistantBotEngineType,
    fileNames: string[]
  ): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(
        `${ENDPOINT}/deleteFilesInAssistantDocsFolder/`,
        {
          assistant_id: assistantId,
          engine_type: engineType,
          file_names: fileNames
        }
      )
      this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async tryFixNode(identifier: string): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/tryFixNode/`, {
        identifier
      })

      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async codeOptimizer(identifier: string, definition: string): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/codeOptimizer/`, {
        identifier,
        definition
      })

      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async codeCompleter(identifier: string, definition: string): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.post(`${ENDPOINT}/codeCompleter/`, {
        identifier,
        definition
      })

      return this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const assistantBotApi = new AssistantBotApi(apiConfig)
