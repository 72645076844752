import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'

import {
  ExportComponentResponse,
  InterfaceIndexValuesData,
  RowLastUpdateInformation
} from 'common/types/interface'
import { GetFormColumnsData, IndexFilterChoices, IndexTextFilterChoices } from 'common/types/model'
import { ws } from 'store/socket/sagas'
import { ComponentCode, GridLayout, InterfaceCode } from 'pages/interfaces/containers/types'
import {
  FormChange,
  IFilterComponent,
  TableChange,
  TableChangePageData
} from 'pages/interfaces/components/types'
import { GenericBooleanResponse } from 'common/types/managers'

const ENDPOINT = '/interfaces'
const WS_TIME_INTERVAL_MS = 100

class InterfaceApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, InterfaceApi.prototype)
    this.getInterfaceIndexValues = this.getInterfaceIndexValues.bind(this)
    this.getInterfaceTableColumns = this.getInterfaceTableColumns.bind(this)
    this.getFormSelectorColumnValues = this.getFormSelectorColumnValues.bind(this)
    this.getInterfaceFormColumns = this.getInterfaceFormColumns.bind(this)
    this.silentApplyFormChanges = this.silentApplyFormChanges.bind(this)
    this.applyFormChanges = this.applyFormChanges.bind(this)
    this.silentApplyTableChanges = this.silentApplyTableChanges.bind(this)
    this.applyTableChanges = this.applyTableChanges.bind(this)
    this.getValidatedValuesFromSelectorForm = this.getValidatedValuesFromSelectorForm.bind(this)
    this.addRowsToForm = this.addRowsToForm.bind(this)
    this.removeRowsFromForm = this.removeRowsFromForm.bind(this)
    this.getFormRowLastUpdateInfo = this.getFormRowLastUpdateInfo.bind(this)
    this.exportComponent = this.exportComponent.bind(this)
    this.trigger = this.trigger.bind(this)
    this.getLayout = this.getLayout.bind(this)
    this.setInterfaceCustomViewStatus = this.setInterfaceCustomViewStatus.bind(this)
    this.saveInterfaceCustomView = this.saveInterfaceCustomView.bind(this)
    this.deleteInterfaceCustomView = this.deleteInterfaceCustomView.bind(this)
  }

  public async getLayout(
    interfaceId: string,
    recreateInterfaceNode: boolean
  ): Promise<GridLayout | undefined> {
    try {
      const res: AxiosResponse<GridLayout | undefined> = await this.post(
        `${ENDPOINT}/${interfaceId}/layout/`,
        {
          recreate_interface_node: recreateInterfaceNode
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  async _trigger(interfaceId: string, triggerData: any, fromInterface: boolean): Promise<boolean> {
    const res: AxiosResponse<boolean> = await this.post(`${ENDPOINT}/${interfaceId}/trigger/`, {
      triggerData,
      fromInterface
    })
    return this.success(res)
  }

  public async trigger(
    interfaceId: string,
    triggerData: any,
    fromInterface: boolean
  ): Promise<boolean> {
    try {
      // Try _trigger the first time to avoid delay from setInterval
      if (ws?.readyState === 1) {
        return await this._trigger(interfaceId, triggerData, fromInterface)
      }
      const triggerCall = setInterval(async () => {
        if (ws?.readyState === 1) {
          clearInterval(triggerCall)
          return await this._trigger(interfaceId, triggerData, fromInterface)
        }
      }, WS_TIME_INTERVAL_MS)
      return false
    } catch (error) {
      throw error
    }
  }

  public async getInterfaceCode(interfaceId: string): Promise<InterfaceCode> {
    try {
      const res: AxiosResponse<InterfaceCode> = await this.get(
        `${ENDPOINT}/${interfaceId}/getcode/`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async setInterfaceCode(interfaceId: string, code: string): Promise<void> {
    try {
      const res: AxiosResponse<InterfaceCode> = await this.post(
        `${ENDPOINT}/${interfaceId}/setcode/`,
        { code },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async saveInterfaceCode(interfaceId: string): Promise<GridLayout> {
    try {
      const res: AxiosResponse<GridLayout> = await this.post(`${ENDPOINT}/${interfaceId}/saveCode/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async getComponentCode(interfaceId: string, componentId: string): Promise<ComponentCode> {
    try {
      const res: AxiosResponse<ComponentCode> = await this.get(
        `${ENDPOINT}/${interfaceId}/getComponentCode/${componentId}/`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async getInterfaceIndexValues(
    interfaceId: string,
    componentId: string,
    indexId: string,
    paginateResults: boolean,
    page?: number,
    filter?: IndexFilterChoices | null,
    text1?: string | null,
    page_size?: number,
    applyFilters: boolean = true
  ): Promise<InterfaceIndexValuesData> {
    try {
      let data = {
        interface_id: interfaceId,
        component_id: componentId,
        index_id: indexId,
        paginate_results: paginateResults,
        apply_filters: applyFilters,
        page_size
      }
      if (page) {
        data = { ...data, ...{ page } }
      }
      if (filter && text1) {
        data = { ...data, ...{ filter, text1 } }
      }
      const res: AxiosResponse<InterfaceIndexValuesData> = await this.post(
        `${ENDPOINT}/${interfaceId}/getInterfaceIndexValues/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getInterfaceTableColumns(
    interfaceId: string,
    componentId: string,
    indexId: string,
    paginateResults: boolean,
    page?: number,
    filter?: IndexFilterChoices | null,
    text1?: string | null,
    applyFilters: boolean = true
  ): Promise<InterfaceIndexValuesData> {
    try {
      let data = {
        interface_id: interfaceId,
        component_id: componentId,
        paginate_results: paginateResults
      }
      if (page) {
        data = { ...data, ...{ page } }
      }
      if (filter && text1) {
        data = { ...data, ...{ filter, text1 } }
      }
      const res: AxiosResponse<InterfaceIndexValuesData> = await this.post(
        `${ENDPOINT}/${interfaceId}/getInterfaceTableColumns/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getFormSelectorColumnValues(
    interfaceId: string,
    componentId: string,
    rowId: number,
    columnName: string,
    text?: string
  ): Promise<(string | number)[]> {
    try {
      let data = {
        interface_id: interfaceId,
        component_id: componentId,
        row_id: rowId,
        column_name: columnName
      }
      if (text) {
        data = { ...data, ...{ text } }
      }
      const res: AxiosResponse<(string | number)[]> = await this.post(
        `${ENDPOINT}/${interfaceId}/getFormSelectorColumnValues/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getInterfaceFormColumns(
    interfaceId: string,
    componentId: string,
    paginateResults: boolean,
    page?: number,
    filter?: IndexTextFilterChoices | null,
    text1?: string | null,
    page_size?: number
  ): Promise<GetFormColumnsData> {
    try {
      let data = {
        interface_id: interfaceId,
        component_id: componentId,
        paginate_results: paginateResults,
        page_size
      }
      if (page) {
        data = { ...data, ...{ page } }
      }
      if (filter && text1) {
        data = { ...data, ...{ filter, text1 } }
      }
      const res: AxiosResponse<GetFormColumnsData> = await this.post(
        `${ENDPOINT}/${interfaceId}/getInterfaceFormColumns/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async getValidatedValuesFromSelectorForm(
    interfaceId: string,
    componentId: string,
    newValues: any[],
    text?: string
  ): Promise<boolean[]> {
    try {
      let data = {
        interface_id: interfaceId,
        component_id: componentId,
        new_values: newValues
      }
      if (text) {
        data = { ...data, ...{ text } }
      }
      const res: AxiosResponse<boolean[]> = await this.post(
        `${ENDPOINT}/${interfaceId}/getValidatedValuesFromSelectorForm/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async silentApplyFormChanges(
    interfaceId: string,
    componentId: string,
    changes: FormChange[]
  ): Promise<GenericBooleanResponse> {
    try {
      const data = {
        interface_id: interfaceId,
        component_id: componentId,
        changes
      }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/${interfaceId}/silentApplyFormChanges/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async applyFormChanges(
    interfaceId: string,
    componentId: string,
    changes: FormChange[]
  ): Promise<Array<{ string: any }>> {
    try {
      const data = {
        interface_id: interfaceId,
        component_id: componentId,
        changes
      }
      const res: AxiosResponse<Array<{ string: any }>> = await this.post(
        `${ENDPOINT}/${interfaceId}/applyFormChanges/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async silentApplyTableChanges(
    interfaceId: string,
    componentId: string,
    changes: TableChange[],
    pageData: TableChangePageData
  ): Promise<GenericBooleanResponse> {
    try {
      const data = {
        interface_id: interfaceId,
        component_id: componentId,
        changes,
        page_data: pageData
      }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/${interfaceId}/silentApplyTableChanges/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async applyTableChanges(
    interfaceId: string,
    componentId: string,
    changes: TableChange[],
    pageData: TableChangePageData
  ): Promise<Array<{ string: any }>> {
    try {
      const data = {
        interface_id: interfaceId,
        component_id: componentId,
        changes,
        page_data: pageData
      }
      const res: AxiosResponse<Array<{ string: any }>> = await this.post(
        `${ENDPOINT}/${interfaceId}/applyTableChanges/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async addRowsToForm(
    interfaceId: string,
    componentId: string,
    numberOfRows: number
  ): Promise<Array<{ string: any }>> {
    try {
      const data = {
        interface_id: interfaceId,
        component_id: componentId,
        number_of_rows: numberOfRows
      }
      const res: AxiosResponse<Array<{ string: any }>> = await this.post(
        `${ENDPOINT}/${interfaceId}/addRowsToForm/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async removeRowsFromForm(
    interfaceId: string,
    componentId: string,
    rowIdsToRemove: number[]
  ): Promise<Array<{ string: any }>> {
    try {
      const data = {
        interface_id: interfaceId,
        component_id: componentId,
        row_ids_to_remove: rowIdsToRemove
      }
      const res: AxiosResponse<Array<{ string: any }>> = await this.post(
        `${ENDPOINT}/${interfaceId}/removeRowsFromForm/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async refreshChanges(interfaceId: string, fullRefresh?: boolean): Promise<void> {
    try {
      const res: AxiosResponse<GridLayout> = await this.post(
        `${ENDPOINT}/${interfaceId}/refreshInterfaceChanges/`,
        { full_refresh: fullRefresh || false }
      )
      this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async getFormRowLastUpdateInfo(
    formNodeId: string,
    rowId: number | string
  ): Promise<RowLastUpdateInformation> {
    try {
      const data = {
        form_node_id: formNodeId,
        row_id: rowId
      }
      const res: AxiosResponse<RowLastUpdateInformation> = await this.post(
        `${ENDPOINT}/getFormRowLastUpdateInfo/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async exportComponent(data: any): Promise<ExportComponentResponse<string>> {
    try {
      const res: AxiosResponse<ExportComponentResponse<string>> = await this.post(
        `${ENDPOINT}/exportComponent/`,
        data,
        {
          responseType: 'blob'
        }
      )
      return { data: this.success<any>(res), headers: res.headers }
    } catch (error) {
      throw error
    }
  }

  public async setInterfaceCustomViewStatus(
    interfaceId: string,
    active: boolean
  ): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/${interfaceId}/setInterfaceCustomViewStatus/`,
        {
          active
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async saveInterfaceCustomView(interfaceId: string): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/${interfaceId}/saveInterfaceCustomView/`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async deleteInterfaceCustomView(interfaceId: string): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/${interfaceId}/deleteInterfaceCustomView/`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async addFiltersToFilterComponent(
    nodes: string[],
    componentId: string,
    interfaceId: string
  ): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/addFiltersToFilterComponent/`, {
        nodes,
        componentId,
        interfaceId
      })
      this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async removeFiltersFromFilterComponent(
    componentToRemoveId: string,
    componentId: string,
    interfaceId: string
  ): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(
        `${ENDPOINT}/removeFiltersFromFilterComponent/`,
        {
          componentId,
          componentToRemoveId,
          interfaceId
        }
      )
      this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async saveFilters(
    componentId: string,
    interfaceId: string,
    order: string[]
  ): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/saveFilters/`, {
        componentId,
        interfaceId,
        order
      })
      this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async revertFilters(component: IFilterComponent, interfaceId: string): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(`${ENDPOINT}/revertFilters/`, {
        component,
        interfaceId
      })
      this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const interfaceApi = new InterfaceApi(apiConfig)
