import { CodeDividers, CodeLayout, WidgetProps, WidgetType } from '../../common/types/code'

const panelData: WidgetProps[] = [
  {
    splitPane: {
      split: 'horizontal'
    },
    type: 'pane',
    key: '1',
    children: [
      {
        splitPane: {
          split: 'vertical'
        },
        type: 'pane',
        key: '1',
        children: [
          {
            type: 'widget',
            key: '1',
            contentType: WidgetType.DIAGRAM,
            dividerKey: CodeDividers.DIAGRAM_DIVIDER
          },
          {
            type: 'pane',
            key: '2',
            splitPane: {
              split: 'horizontal'
            },
            children: [
              {
                type: 'widget',
                key: '2',
                contentType: WidgetType.RESULT,
                dividerKey: CodeDividers.RESULT_DIVIDER,
                defaultDividerSize: 90
              },
              {
                type: 'widget',
                key: '1',
                contentType: WidgetType.DOCS,
                dividerKey: CodeDividers.DOCS_DIVIDER,
                defaultDividerSize: 20
              }
            ]
          }
        ]
      }
    ]
  },
  {
    splitPane: {
      split: 'horizontal'
    },
    type: 'pane',
    key: '1',
    children: [
      {
        splitPane: {
          split: 'vertical'
        },
        type: 'pane',
        key: '2',
        children: [
          {
            type: 'widget',
            key: '1',
            contentType: WidgetType.DIAGRAM,
            dividerKey: CodeDividers.DIAGRAM_DIVIDER
          },
          {
            type: 'pane',
            key: '2',
            splitPane: {
              split: 'horizontal'
            },
            children: [
              {
                type: 'widget',
                key: '1',
                contentType: WidgetType.CODE
              }
            ]
          }
        ]
      }
    ]
  },
  {
    splitPane: {
      split: 'horizontal'
    },
    type: 'pane',
    key: '1',
    children: [
      {
        splitPane: {
          split: 'vertical'
        },
        type: 'pane',
        key: '3',
        children: [
          {
            type: 'widget',
            key: '1',
            contentType: WidgetType.DIAGRAM,
            dividerKey: CodeDividers.DIAGRAM_DIVIDER
          },
          {
            type: 'pane',
            key: '2',
            splitPane: {
              split: 'horizontal'
            },
            children: [
              {
                type: 'widget',
                key: '1',
                contentType: WidgetType.CODE,
                dividerKey: CodeDividers.CODE_DIVIDER
              },
              {
                type: 'widget',
                key: '2',
                contentType: WidgetType.RESULT,
                dividerKey: CodeDividers.RESULT_DIVIDER
              }
            ]
          }
        ]
      }
    ]
  }
]

const hardcodedLayouts: CodeLayout[] = [
  {
    id: 1,
    name: 'app.pages.app.topbar.layout.diagram',
    defaultName: 'Result',
    icon: 'diagram',
    widgets: panelData[0]
  },
  {
    id: 3,
    name: 'app.pages.app.topbar.layout.result',
    defaultName: 'Code+result',
    icon: 'result',
    widgets: panelData[2]
  },
  {
    id: 2,
    name: 'app.pages.app.topbar.layout.preview',
    defaultName: 'Code+Preview',
    icon: 'preview',
    widgets: panelData[1]
  }
]
export { hardcodedLayouts }
