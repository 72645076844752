import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'
import {
  ApplyChangesData,
  ApplyChangesResponse,
  GetDifferenceData,
  GetDifferenceResponse
} from 'pages/appConsolidation/types'

const ENDPOINT = '/consolidation'

class ConsolidationAppApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, ConsolidationAppApi.prototype)
    this.getConsolidationDifferences = this.getConsolidationDifferences.bind(this)
    this.applyConsolidationChanges = this.applyConsolidationChanges.bind(this)
  }
  public async getConsolidationDifferences(
    data: GetDifferenceData
  ): Promise<GetDifferenceResponse> {
    try {
      const res: AxiosResponse<GetDifferenceResponse> = await this.post(
        `${ENDPOINT}/getConsolidationDifferences/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async applyConsolidationChanges(data: ApplyChangesData): Promise<ApplyChangesResponse> {
    try {
      const res: AxiosResponse<ApplyChangesResponse> = await this.post(
        `${ENDPOINT}/applyConsolidationChanges/`,
        data,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const consolidationAppApi = new ConsolidationAppApi(apiConfig)
