import { LayoutActionTypes, ThemeColors } from './types'

// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

export const setTheme = (theme: ThemeColors) => ({
  type: LayoutActionTypes.SET_THEME,
  payload: theme
})

export const setLayoutDivider = (value: number) => ({
  type: LayoutActionTypes.SET_LAYOUT_DIVIDER,
  payload: value
})
