const messages = {
  'common.code': 'Código',
  'common.diagram': 'Diagrama',
  'common.preview': 'Pré-visualização',
  'common.result': 'Resultado',
  'common.shortcuts': 'Atalhos',
  'common.app': 'Aplicativo',
  'common.apps': 'Aplicativos',
  'common.confirm': 'Confirme',
  'common.close': 'Fechar',
  'common.cancel': 'Cancelar',
  'common.run': 'Rodar',
  'common.stop': 'Parar',
  'common.home': 'Inicio',
  'common.error': 'Erro',
  'common.console': 'Console',
  'common.all': 'Todo',
  'common.allPlural': 'Todos',
  'common.apply': 'Aplicar',
  'common.noResultsFound': 'Nenhum resultado encontrado',
  'common.options': 'Opções',
  'common.goToNode': 'Ir ao nó',
  'common.top': 'Superior',
  'common.bottom': 'Inferior',
  'common.left': 'Esquerda',
  'common.right': 'Direita',
  'common.default': 'Padrão',
  'common.custom': 'Personalizado',
  'common.none': 'Nenhum',
  'common.interface': 'Interface',
  'common.text': 'Texto',
  'common.true': 'Verdadeiro',
  'common.false': 'Falso',
  'common.hide': 'Ocultar',
  'common.show': 'Mostrar',
  'common.position': 'Posição',
  'common.inside': 'Dentro',
  'common.outside': 'Fora',
  'common.running': 'Executando',
  'common.general': 'Geral',
  'common.value': 'Valor',
  'common.edit': 'Editar',
  'common.username': 'Usuário',
  'common.password': 'Senha',
  'common.invalidPassword': 'Senha inválida',
  'common.required': 'Required',
  'common.copy': 'Copiar',
  'common.cut': 'Cortar',
  'common.pasteBelow': 'Colar abaixo',
  'common.about': 'Cerca de',
  'common.documentation': 'Documentação',
  'common.addDocumentation': 'Adicionar documentação',
  'common.editDocumentation': 'Editar documentação',
  'common.help': 'Ajuda',
  'common.from': 'Desde',
  'common.to': 'Até',
  'common.color': 'Cor',
  'common.values': 'Valores',
  'common.applyTo': 'Aplicar a',
  'common.save': 'Salvar',
  'common.paste': 'Colar',
  'common.total': 'Total',
  'common.totals': 'Totais',
  'common.row': 'Linha',
  'common.rows': 'Linhas',
  'common.column': 'Coluna',
  'common.columns': 'Colunas',
  'common.measures': 'Medidas',
  'common.labels': 'Rótulos',
  'common.read': 'Ler',
  'common.properties': 'Propriedades',
  'common.selectInterface': 'Selecionar interface',
  'common.yes': 'Sim',
  'common.no': 'Não',
  'common.table': 'Tabela',
  'common.cube': 'Cubo',
  'common.discard': 'Descartar',
  'common.settings': 'Ajustes',
  'common.resetView': 'Restaurar vista',
  'common.reloadComponent': 'Recarregar componente',
  'common.fields': 'Campos',
  'common.delete': 'Apagar',
  'common.add': 'Adicionar',
  'common.information': 'Informação',
  'common.openApp': 'Abrir aplicativo',
  'common.description': 'Descrição',
  'common.version': 'Versão',
  'common.versions': 'Versões',
  'common.screenshots': 'Capturas de tela',
  'common.showInstallationLogs': 'Mostrar logs de instalação',
  'common.hideInstallationLogs': 'Ocultar logs de instalação',
  'common.noResults': 'Sem resultados',
  'common.conditionFormatType': 'Tipo de condição',
  'common.loadingAppVersion': 'Carregando versão do aplicativo',
  'common.creatingAppVersion': 'Criando versão do aplicativo',
  'common.notAllowedCharacters':
    "Caráter não permitido. Os seguintes caracteres não são permitidos: / \\ '",
  'common.status': 'Estado',
  'common.activate': 'Activar',
  'common.archive': 'Arquivar',
  'common.unarchive': 'Desarquivar',
  'common.invalidCharactersMessage': 'Não são permitidos os seguintes caracteres: {characters}',
  'common.import': 'Importar',
  'common.export': 'Exportar',
  'common.validating': 'Validando',
  'common.scenarios': 'Cenários',
  'common.selectANode': 'Selecione um nó',
  'common.deleteAll': 'Apagar todo',
  'common.creator': 'Criador',
  'common.scenario': 'Cenário',
  'common.selectedNode.incorrect': 'O nó selecionado está incorreto. Selecione outro nó.',
  'common.max': 'Máximo',
  'common.min': 'Mínimo',
  'common.internalTests': 'Testes internos',
  'common.used': 'Usado',
  'common.generalSettings': 'Configurações Gerais',
  'common.clipboard': 'Prancheta',
  'common.clipboard.clear': 'Limpar prancheta',
  'common.apiKeysManager': 'Administrador de chaves Api',
  'common.user': 'Usuário',
  'common.items': 'Items',
  'common.assistant': 'Assistente',
  'common.assistants': 'Assistentes',
  'common.sources': 'Fontes',
  'common.appConsolidation': 'Consolidação do aplicativo',
  'common.changes': 'Mudanças',
  'common.destination': 'Destino',
  'common.new': 'Novo',
  'common.old': 'Velho',
  'common.definition': 'Definição',
  'common.nodes': 'Nós',
  'common.appManagement': 'Administração do aplicativo',
  'common.modules': 'Módulos',
  'common.maximize': 'Maximizar',
  'common.minimize': 'Minimizar',
  'common.areYouSureYouWantToProceed': 'Você tem certeza de que deseja continuar?',
  'common.load': 'Carregar',
  'common.colors': 'Cores',
  'common.component': 'Componente',
  'common.source': 'Origem',
  'common.string': 'Texto',
  'common.integer': 'Inteiro',
  'common.float': 'Decimal',
  'common.boolean': 'Boleano',
  'common.date': 'Data',
  'common.extraSettings': 'Configurações extras',
  'common.apiKeys': 'Chaves de API',
  'common.applicationEnvironment': 'Ambiente da aplicação',
  'common.moduleRestrictions': 'Restrições de módulos',
  'common.workflowProcesses': 'Processos de fluxo de trabalho',
  'common.lostMessage': 'serão perdidos.',
  'common.attemptingReconnectServer': 'Tentando reconectar com o servidor, por favor aguarde...',
  'common.connectionReestablished': 'Conexão restabelecida',
  'common.order': 'Ordem',
  'common.nodeInterface': 'Interface de nó',
  'common.pleaseWait': 'Por favor espere...',
  'common.context': 'Contexto',
  'common.name': 'Nome',
  'common.assistantBot': 'Assistente',
  'common.assistantBots': 'Assistentes',
  'common.open': 'Abrir',
  'common.somethingWentWrong': 'Algo deu errado',
  'common.details': 'Detalles',
  'common.reloadPage': 'Recarregar página',
  'common.titleAndId': 'Título + id',
  'common.expand': 'Expandir',

  copying_app_to_user_workspace: 'Copiando aplicativo no espaço de trabalho do usuário',
  creating_environment: 'Criando ambiente',
  starting_environment: 'Inicializando o ambiente',
  loading_workspace: 'Carregamento do espaço de trabalho',
  engine_created: 'Motor de cálculo criado',
  starting_pyplan_calc_engine: 'Inicializando o motor de cálculo',
  opening_app: 'Abrindo aplicativo',
  installing_requirements_file_libraries: 'Instalando bibliotecas desde arquivo requirements.txt',
  no_libraries_to_install: 'Sem bibliotecas para instalar',
  done: 'Pronto',
  may_take: 'Esta tarefa pode demorar até 5 minutos. Por favor, aguarde...',
  'common.checkingPythonLibrariesUsedInModel':
    'Verificação das bibliotecas python utilizadas no modelo',
  'common.librariesAlreadyInstalled': 'Bibliotecas já instaladas',
  'common.resultsNotFound': 'Sem resultados, faça uma pesquisa',
  need_logout: 'Logout necessário',
  need_logout_detail:
    'O administrador pediu que a instância fosse encerrada. Favor salvar e encerrar a instância o mais rápido possível',
  session_killed_detail: 'Logout necessário',
  session_killed: 'O administrador encerrou sua instância',
  instance_purged: 'Seu instância expirou devido a inatividade',
  instance_purged_detail: 'Fechar instancia',
  session_purged: 'Sua sessão expirou devido a inatividade',
  session_purged_detail: 'Iniciar sessão de novo',
  engine_down:
    'O motor de cálculo foi fechado de maneira inesperada. Por favor, encerrar a instância e abrir uma nova',
  engine_down_detail: 'Encerrar instância',
  error_installing_libs_from_requirements:
    'Erro instalando bibliotecas desde arquivo requirements.txt',
  error_creating_new_version: 'Erro criando nova versão',
  error_version_name_already_exists: 'O nome da versão escolhido já existe',
  app_recovered_from_backup: 'Aplicativo recuperada do cópia de segurança',
  creating_assistant: 'Criando asisstente',
  updating_assistant: 'Atualizando asisstente',
  indexing_documents: 'Indexando documentos',
  recovered_existing_instance: 'O aplicativo foi recuperado a partir de uma instância existente.',
  app_closed_in_another_tab: 'O aplicativo foi fechado em outra aba.',
  session_closed_in_another_tab: 'A sessão foi fechada em outra aba.',

  //home
  'app.components.HomePage.header': 'Inicio',
  //notifier
  'common.notifier.dismissSnackbar': 'Fechar',
  'common.notifier.readMore': 'Ver mais',

  //Initial configuration
  'app.pages.initialConfig.initialSetUp': 'Configuração inicial',
  'app.pages.initialConfig.internalTesting': 'Teste interno',
  'app.pages.initialConfig.finish': 'Finalizar',
  'app.pages.initialConfig.initialConfig': 'Por favor, digite o nome da empresa',
  'app.pages.initialConfig.userAdminInfo':
    'Digite as informações de usuário do administrador da empresa',
  'app.pages.initialConfig.copyKey':
    'Copie o código de ativação e envie-o para o administrador do sistema para gerar o código de licença',
  'app.pages.initialConfig.finishMessage': 'Processo concluído com sucesso',
  'app.pages.initialConfig.licenseSuccess': 'Licença gerada com sucesso',

  // Internal tests
  'app.pages.internalTests.runTests': 'Executar testes',
  'app.pages.internalTests.deleteApp': 'Apagar aplicativo',
  'app.pages.internalTests.createPublicFolder': 'Criar pasta pública',
  'app.pages.internalTests.removePublicFolder': 'Remover pasta pública',
  'app.pages.internalTests.systemResources': 'Recursos do sistema',

  //cards
  'app.components.cards.instanceCard.openAgo': 'Aberta há {number} mins',
  'app.components.cards.instanceCard.currentInstance': 'Instância atual',
  'app.components.cards.instanceCard.activeAgo': 'Ativa há {number} mins',
  'app.components.cards.instanceCard.deleteInstance': 'Deletar instância',
  'app.components.cards.instanceCard.changeInstance':
    'Mudar para esta instância:{br}- Nome: {title}{br}- Versão: {version}{br}- Caminho: {modelPath}',
  'app.components.cards.instanceCard.currentInstanceTooltip':
    '- Nome: {title}{br}- Versão: {version}{br}- Caminho: {modelPath}',

  //login
  'app.pages.login.init': 'Início',
  'app.pages.login.usernameLabel': 'Usuário',
  'app.pages.login.passwordLabel': 'Senha',
  'app.pages.login.forgotPassword': 'Esqueceu sua senha?',
  'app.pages.login.login': 'Iniciar Sessão',
  'app.pages.login.connectWithSocialMedia': 'Conecte-se com redes sociais',
  'app.pages.login.connectWithApp': 'Conectar com {app}',
  'app.pages.login.or': 'ou',
  'app.pages.login.orContinueWith': 'Ou continue com:',
  'app.pages.login.SSOTo': 'SSO a {company}',
  'app.pages.login.createAnAccount': 'Criar uma conta',
  'app.pages.login.copyright': 'Copyright © Pyplan {year}.',
  'app.pages.login.selectCompany': 'Selecione uma empresa',
  'app.pages.login.licenseInfo': 'Informação da licença',
  'app.pages.login.invalidLicense': 'Licença inválida',
  'app.pages.login.carousel.previous': 'Anterior', //Duplicated on PT and ES, but unique on EN
  'app.pages.login.carousel.next': 'Próxima',
  'app.pages.login.carousel.viewMore': 'Ver mais',
  'app.pages.login.carousel.userPasswordExpired':
    'Sua senha de usuário expirou! Por favor, escolha uma nova para manter sua conta segura.',
  'app.pages.login.carousel.userPasswordRepeated':
    'A nova senha deve ser diferente da senha atual.',
  'app.pages.login.rememberMe': 'Lembre-me',
  'app.pages.login.resetPasswordMessage': 'Enviamos um e-mail para redefinir sua senha',
  'app.pages.login.resetPassword': 'Redefinir senha',
  'app.pages.login.passwordChangedSuccessfully': 'Senha alterada com sucesso',
  'app.pages.login.usernameError': 'o usuário {name} não existe',
  'app.pages.login.invalidToken': 'O token é inválido',
  'app.pages.login.multiFactorAuthentication': 'Autenticação de múltiplos fatores',
  'app.pages.login.scanQrCode':
    'Aprimore a segurança da sua conta baixando um aplicativo de Autenticação em Dois Fatores (2FA), como o Google Authenticator ou o Microsoft Authenticator, em seu dispositivo móvel. Escaneie o código QR abaixo usando o aplicativo para gerar códigos de verificação únicos sempre que fizer login.',
  'app.pages.login.enterCode': 'Insira o código gerado pelo seu aplicativo de autenticação',
  'app.pages.login.enterCodePlaceholder': 'Insira o código',
  'app.pages.login.enterCodeError': 'O código é inválido',
  'app.pages.login.enterCodeSuccess': ' Autenticação de múltiplos fatores habilitada com sucesso',
  'app.pages.login.verify': 'Verificar',
  'app.pages.login.cantUseAuthAppNow': 'Não consigo usar meu aplicativo autenticador no momento.',
  'app.pages.login.sendCodeToEmail':
    'Enviamos o código de verificação para a sua caixa de correio eletrônico {userSecretMail}. Por favor, insira o código para continuar.',
  'app.pages.login.loadingInstanceData': 'Carregando dados da instância',
  'app.pages.login.deleteSSOTo': 'Apagar link "SSO a {company}"',

  //topbar
  'app.pages.app.topbar.notifications': 'Notificações',
  'app.pages.app.topbar.showMore': 'Ver mais',
  'app.pages.app.topbar.showNotifications': 'Ver notificações',
  'app.pages.app.topbar.defaultInterface': 'Interface padrão',
  'app.pages.app.topbar.summary': 'Resumo',
  'app.pages.app.topbar.manageLibs': 'Gerenciar bibliotecas',
  'app.pages.app.topbar.reloadApp': 'Recarregar aplicativo',
  'app.pages.app.topbar.createNew.app': 'Aplicativo',
  'app.pages.app.topbar.layout.diagram': 'Resultado',
  'app.pages.app.topbar.layout.preview': 'Código+Preview',
  'app.pages.app.topbar.layout.result': 'Código+Resultado',
  'app.pages.app.topbar.saveAs': 'Salvar como',
  'app.pages.app.topbar.saveAsNewVersion': 'Salvar como nova versão',
  'app.pages.app.topbar.saveApplicationInMyWorkspace':
    'Salvar aplicativo no meu espaço de trabalho',
  'app.pages.app.topbar.appName': 'Nome do Aplicativo',
  'app.pages.app.topbar.appNameValidationError':
    'O nome do aplicativo não pode conter algum dos seguintes caracteres: ',
  'app.pages.app.topbar.selectAppVersionsToCopy': 'Selecionar versões do aplicativo para copiar',
  'app.pages.app.topbar.copiesAllAppVersionMessage':
    'Todas as versões serão copiadas se nenhuma foi selecionada',
  'app.pages.app.topbar.logout': 'Sair',
  'app.pages.app.topbar.libsManager.uninstalled': 'A biblioteca foi desinstalada com sucesso',
  'app.pages.app.topbar.libsManager.version': 'Versão',
  'app.pages.app.topbar.libsManager.refresh': 'Atualizar',
  'app.pages.app.topbar.libsManager.location': 'Localização',
  'app.pages.app.topbar.libsManager.actions': 'Ações',
  'app.pages.app.topbar.libsManager.installedLibraries': 'Bibliotecas instaladas',
  'app.pages.app.topbar.libsManager.install': 'Instalar',
  'app.pages.app.topbar.libsManager.appRequirements': 'Requisitos do aplicativo',
  'app.pages.app.topbar.libsManager.saveFileInstallLibraries':
    'Salvar arquivo e instalar bibliotecas',
  'app.pages.app.topbar.libsManager.saveInstallLibraries': 'Salvar e instalar',
  'app.pages.app.topbar.libsManager.requirementsFile': 'Arquivo requirements.txt',
  'app.pages.app.topbar.libsManager.pipInstallError': 'Espaços em branco não são permitidos',
  'app.pages.app.topbar.libsManager.systemLib': 'biblioteca do sistema',
  'app.pages.app.topbar.libsManager.userLib': 'biblioteca do usuário',
  'app.pages.app.topbar.libsManager.filter': 'Filtrar',
  'app.pages.app.topbar.libsManager.libraries': 'Bibliotecas',
  'app.pages.app.topbar.showConsole': 'Ver console',
  'app.pages.app.topbar.closeConsole': 'Fechar console',
  'app.pages.app.topbar.appProperties': 'Propriedades do aplicativo',
  'app.pages.app.topbar.deparmentsError':
    'Existe uma interface sem departamentos.Selecione um departamento.',
  'app.pages.app.topbar.deparmentRepeated': 'O departamento {name} já tem uma interface definida',
  'app.pages.app.topbar.allDeps': 'Todos',
  'app.pages.app.topbar.selecteDefaultInterface': 'Selecione a interface padrão para o aplicativo',
  'app.pages.app.topbar.libsManager.cantInstallHead':
    'O aplicativo utiliza um ambiente público. Pyplan precisa de gerar um ambiente único para que possa adicionar/remover bibliotecas (esta tarefa pode levar alguns minutos).',
  'app.pages.app.topbar.libsManager.cantInstallTasksHead':
    'Para este efeito, serão realizadas as seguintes tarefas:',
  'app.pages.app.topbar.libsManager.cantInstallTask1': 'o aplicativo actual será salvado',
  'app.pages.app.topbar.libsManager.cantInstallTask2': 'será criado um novo ambiente exclusivo',
  'app.pages.app.topbar.libsManager.cantInstallTask3':
    'o aplicativo será reaberto com o ambiente único',
  'app.pages.app.topbar.libsManager.cantInstallConfirmationMessage':
    'Está seguro de quer realizar estas tarefas?',
  'app.pages.app.topbar.libsManager.cantInstallButtonText': 'Sim, realizar estas tarefas',
  'app.pages.app.topbar.changeAppVersion': 'Alterar versão do aplicativo',
  'app.pages.app.topbar.changeVersion': 'Alterar versão',
  'app.pages.app.topbar.takeScreenshot': 'Tirar captura de tela',
  'app.pages.app.topbar.screenshotResult': 'Resultado da captura de tela',
  'app.pages.app.topbar.downloadScreenshot': 'Baixar imagem',
  'app.pages.app.topbar.saveImageAsAppThumbnail': 'Salvar imagem como miniatura do aplicativo',
  'app.pages.app.topbar.appIdError':
    'O ID do aplicativo só pode conter números, letras, hífens e deve ter mais de 7 caracteres',
  'app.pages.app.topbar.appIdMessage':
    'Para que as alterações entrem em vigor, você deve reiniciar o aplicativo.',
  'app.pages.app.topbar.appIdAlert': 'Todos os dados relacionados a:',

  //Info menu dialog:
  'app.pages.app.closeApp': 'Fechar aplicativo',
  'app.pages.app.closedSuccessfully': 'Aplicativo fechado com sucesso',
  'app.pages.app.reloadSuccessfully': 'Aplicativo recarregado com sucesso',
  'app.pages.app.saveSuccessfully': 'Aplicativo salvado com sucesso',
  'app.pages.app.appNewPath': 'O aplicativo foi salvado em: ',
  'app.pages.app.saveApp': 'Salvar aplicativo',
  'app.pages.app.newModelName': 'Nome do novo modelo',
  'app.pages.app.newModelNamePlaceholder': 'Insira o nome do novo modelo',
  'app.pages.app.unconfirmedNodes': 'Nós não confirmados',
  'app.pages.app.saveDefinitionsAndApp': 'Confirme as definições e salve o aplicativo',
  'app.pages.app.discardDefinitionsAndSaveApp': 'Descartar as definições e salve o aplicativo',
  'app.pages.app.unconfirmedNodesAlert':
    'Existem {number} nós com definições não confirmadas. O que você gostaria de fazer?',
  'app.pages.app.lostDataAlert':
    'Os dados do aplicativo não salvos serão perdidos. Você tem certeza?',
  'app.pages.app.unsavedChanges': 'Existem alterações não salvas. O que você gostaria de fazer?',
  'app.pages.app.saveChangesAndOpenApp': 'Salvar alterações e abrir aplicativo',
  'app.pages.app.discardChangesAndOpenApp': 'Descartar alterações e abrir aplicativo',
  'app.pages.app.saveChangesAndCreateApp': 'Salvar alterações e criar aplicativo',
  'app.pages.app.discardChangesAndCreateApp': 'Descartar alterações e criar aplicativo',

  //sidebarMenu:
  'app.pages.app.sidebarMenu.fileManager': 'Administrador de arquivos',
  'app.pages.app.sidebarMenu.code': 'Código',
  'app.pages.app.sidebarMenu.interfaces': 'Interfaces',
  'app.pages.app.sidebarMenu.tools': 'Ferramentas',
  'app.pages.app.sidebarMenu.scheduledTasks': 'Tarefas programadas',
  'app.pages.app.sidebarMenu.myAccount': 'Minha conta',
  'app.pages.app.sidebarMenu.settings': 'Ajustes',
  'app.pages.app.sidebarMenu.securityOptions': 'Opções de segurança',
  'app.pages.app.sidebarMenu.users': 'Usuários',
  'app.pages.app.sidebarMenu.departments': 'Departamentos',
  'app.pages.app.sidebarMenu.teams': 'Equipes',
  'app.pages.app.sidebarMenu.permissionByRole': 'Permissão por cargo',
  'app.pages.app.sidebarMenu.applications': 'Aplicativos',
  'app.pages.app.sidebarMenu.processManager': 'Processos',
  'app.pages.app.sidebarMenu.home': 'Inicio',
  'app.pages.app.sidebarMenu.numberLocale': 'Formato numérico',
  'app.pages.app.sidebarMenu.language': 'Idioma',

  //home
  'app.pages.app.home.myPyplan': 'Meu Pyplan',
  'app.pages.app.home.openApps': 'Aplicativos abertos',
  'app.pages.app.home.workspaces': 'Espaços de trabalho',
  'app.pages.app.home.folders': 'Pastas',
  'app.pages.app.home.instanceKilledSuccessfully': 'Instância deletada com sucesso',
  'app.pages.app.home.instanceChangedSuccessfully': 'Instância mudada com sucesso',
  //home.pyplanResources
  'app.pages.app.home.pyplanResources': 'Recursos Pyplan',
  'app.pages.app.home.pyplanResources.videos': 'Videos',
  'app.pages.app.home.pyplanResources.videosCaption': 'Veja o Pyplan em ação',
  'app.pages.app.home.pyplanResources.documentation': 'Documentação',
  'app.pages.app.home.pyplanResources.documentationCaption': 'Passos básicos',
  'app.pages.app.home.pyplanResources.examples': 'Exemplos',
  'app.pages.app.home.pyplanResources.examplesCaption': 'de aplicativos',
  //home.shortcutsPanel
  'app.pages.app.home.shortcutsPanel.tutorials': 'Tutoriais',
  'app.pages.app.home.shortcutsPanel.examples': 'Exemplos',
  'app.pages.app.home.shortcutsPanel.demos': 'Demonstrações',
  'app.pages.app.home.shortcutsPanel.models': 'Modelos',
  'app.pages.app.home.shortcutsPanel.noModelFound':
    'Nenhum modelo foi aberto recentemente. Procure seus arquivos ou crie um novo.',
  'app.pages.app.home.shortcutsPanel.noExamplesFound': 'Sem exemplos',
  'app.pages.app.home.shortcutsPanel.my_apps': 'Meus aplicativos',
  'app.pages.app.home.shortcutsPanel.public_apps': 'Aplicativos públicos',
  'app.pages.app.home.shortcutsPanel.recently_opened': 'Recentemente aberto',
  'app.pages.app.home.shortcutsPanel.noPermissionsToViewApps':
    'Você não tem permissões para visualizar aplicativos. Entre em contato com um administrador.',

  //editProfile
  'app.pages.editProfile.editProfile': 'Editar perfil',
  'app.pages.editProfile.changePasswordAlert':
    'Se você alterar sua senha, sua instância será encerrada e você precisará fazer login novamente',
  'app.pages.editProfile.currentPassword': 'Senha atual',
  'app.pages.editProfile.currentPasswordIncorrect': 'Senha atual incorreta',

  //managers
  'app.pages.app.managers.firstPage': 'Primeira',
  'app.pages.app.managers.prevPage': 'Anterior',
  'app.pages.app.managers.nextPage': 'Próxima',
  'app.pages.app.managers.lastPage': 'Última',
  'app.pages.app.managers.cancel': 'Cancelar',
  'app.pages.app.managers.update': 'Atualizar',
  'app.pages.app.managers.create': 'Criar',
  'app.pages.app.managers.search': 'Procurar',
  'app.pages.app.managers.searchHelperText': 'Escreva pelo menos 3 caracteres',
  'app.pages.app.managers.companyManager': 'Administrador da empresa',
  'app.pages.app.managers.folder': 'Pasta',
  'app.pages.app.managers.departmentManager': 'Administrador de departamentos',
  'app.pages.app.managers.rowsPerPage': 'Linhas por página',
  'app.pages.app.managers.company': 'Empresa',
  'app.pages.app.managers.company.companies': 'Empresas',
  'common.name.isRequired': 'O nome e necessário',
  'app.pages.app.managers.company.notFound': 'Empresa não encontrada',
  'app.pages.app.managers.company.placeholder': 'Escolha una empresa',
  'app.pages.app.managers.company.isRequired': 'A empresa e necessária',
  'app.pages.app.managers.roleManager': 'Administrador de cargos',
  'app.pages.managers.invalidJSON': 'Não e um JSON valido',
  'app.pages.managers.actions': 'Ações',
  'app.pages.managers.departments': 'Departamentos',
  'app.pages.managers.mainDepartment': 'Departamento principal',
  'app.pages.managers.add': 'Adicionar',
  'app.pages.managers.role': 'Cargo',
  'app.pages.managers.roles': 'Cargos',
  'app.pages.app.managers.back': 'Voltar',
  'app.pages.app.managers.unkwownStep': 'Paso desconhecido',
  'app.pages.app.managers.saveButton': 'Salvar',
  'app.pages.app.managers.nextButton': 'Avançar',
  'app.pages.app.managers.companies': 'Empresas',
  'app.pages.managers.instances': 'Instâncias',
  'app.pages.managers.password': 'Senha',
  'app.pages.managers.newPassword': 'Nova senha',
  'app.pages.managers.confirmPassword': 'Confirmar senha',
  'app.pages.managers.passwordIsRequired': 'Senha é necessária',
  'app.pages.managers.ConfirmPasswordIsRequired': 'Confirmar Senha é necessária',
  'app.pages.managers.passwordMinLength':
    'A senha é pequeña - A senha deve ter pelo menos {number} caracteres',
  'app.pages.managers.passwordInvalidRequisites':
    'A senha deve ter pelo menos uma maiúscula, uma minúscula e um símbolo',
  'app.pages.managers.passwordsNotMatch': 'Senhas não coincidem',
  'app.pages.managers.send': 'Enviar',
  'app.pages.app.managers.delete': 'Excluir',
  'app.pages.app.managers.createAppFromThis': 'Criar aplicativo a partir deste',
  'app.pages.app.managers.applicationSuccessfullyCreated': 'Aplicativo criado com sucesso em',
  'app.pages.app.managers.newFolder': 'Nova pasta',
  'app.pages.app.managers.rename': 'Renomear',
  'app.pages.app.managers.set_permission': 'Definir permissões',
  'app.pages.app.managers.duplicate': 'Duplicar',
  'app.pages.app.managers.openAsReadOnly': 'Abrir como somente leitura',
  'app.pages.app.managers.copyAppToWorkspace': 'Copiar aplicativo em espaço de trabalho',
  'app.pages.app.managers.copyAppToTeam': 'Copiar aplicativo para equipe',
  'app.pages.app.managers.copyAppToPublic': 'Copiar aplicativo para Public',
  'app.pages.app.managers.openInNewInstance': 'Abrir o aplicativo em uma nova instância',
  'app.pages.app.managers.downloadApp': 'Baixar aplicativo',
  'app.pages.app.managers.appDownloaded': 'Aplicativo baixado',
  'app.pages.app.managers.copyInterfaceId': 'Copiar id da interface',
  'app.pages.app.managers.idCopied': 'Id copiado',
  'app.pages.app.managers.folderExists': 'A pasta já existe',

  // Notifications Manager
  'app.pages.app.notificationsManager.notificationsManager': 'Administrador de notificações',
  'app.pages.app.notificationsManager.addNotification': 'Adicionar notificação',
  'app.pages.app.notificationsManager.editNotification': 'Editar notificação',
  'app.pages.app.notificationsManager.successChangedNotificationStatus':
    'Estado da notificação alterado com sucesso',
  'app.pages.app.notificationsManager.removeNotification': 'Remover notificação',
  'app.pages.app.notificationsManager.removeNotificationConfirmation':
    'Tem certeza de que deseja excluir esta notificação?',
  'app.pages.app.notificationsManager.successRemovedNotification':
    'Notificação removida com sucesso',
  'app.pages.app.notificationsManager.successAddedNotification':
    'Notificação adicionada com sucesso',
  'app.pages.app.notificationsManager.effectiveDate': 'Data efetiva',
  'app.pages.app.notificationsManager.noNotifications': 'Sem notificações',
  'app.pages.app.notificationsManager.notificationTitle': 'Título da notificação',
  'app.pages.app.notificationsManager.notificationDescription': 'Descrição da notificação',
  'app.pages.app.notificationsManager.notification': 'Notificação',

  //companyManager
  'app.pages.app.companyManager.newCompany': 'Criar empresa',
  'app.pages.app.companyManager.editCompany': 'Atualizar empresa',
  'app.pages.app.companyManager.companyPreferences': 'Preferências',
  'app.pages.app.companyManager.successChangedStatus': 'Status da empresa alterado com sucesso',
  //companyManager.companyTable
  'app.pages.app.companyManager.companyTable.companyStatusColumn': 'Estado',
  'app.pages.app.companyManager.companyTable.companyTableAriaLabel': 'Tabela da empresas',
  'app.pages.app.companyManager.companyTable.companiesNotFound': 'Empresas não encontradas',
  'app.pages.app.companyManager.hasSaml': 'Tem configuração SAML',
  //companyManager.addOrEdit
  'app.pages.companyManager.addOrEdit.companyNameField': 'Nome da empresa',
  'app.pages.companyManager.addOrEdit.companyCodeField': 'Nome da pasta da empresa',
  'app.pages.companyManager.addOrEdit.companyActivationCodeField': 'Código de ativação',
  'app.pages.companyManager.addOrEdit.copiedCompanyActivationCode':
    'Código de ativação - Copiado ✓',
  'app.pages.companyManager.addOrEdit.companyLicenseField': 'Licença',
  'app.pages.companyManager.addOrEdit.companyLogo': 'Logo da empresa',
  'app.pages.companyManager.addOrEdit.dropzoneFirstRow': 'Arraste e solte o logotipo da empresa ou',
  'app.pages.companyManager.addOrEdit.dropzoneLinkRow': 'navegue em seus arquivos',
  'app.pages.companyManager.addOrEdit.companyFieldRequiredError': 'Campo obrigatório',
  'app.pages.companyManager.addOrEdit.companyFieldMaxLengthError':
    'Não pode exceder 255 caracteres',
  'app.pages.companyManager.addOrEdit.companyFieldPatternError':
    'Caracteres inválidos. Somente a-z minúsculo, A-Z maiúsculas, números de 0 a 9 e hífens e sublinhados são permitidos',
  'app.pages.companyManager.addOrEdit.companyFolderAlreadyExists':
    'A pasta da empresa já existe, escolha um código diferente',
  'app.pages.companyManager.addOrEdit.copyToClipboard': 'Copiar para área de transferência',
  'app.pages.companyManager.addOrEdit.copiedData': 'Dados copiados para área de transferência',
  'app.pages.companyManager.addOrEdit.invalidFileExtension':
    'Arquivo inválido. A imagem é necessária',
  'app.pages.app.companyManager.successAddedCompany': 'Nova empresa criada com sucesso',
  'app.pages.app.companyManager.successEditedCompany': 'Empresa editada com sucesso',
  'app.pages.app.companyManager.removeLicense': 'Remover licença',

  //Department Manager
  'app.pages.DepartmentPage.addDepartment': 'Criar departamento',
  'app.pages.DepartmentPage.editDepartment': 'Editar departamento',
  'app.pages.DepartmentPage.engineDefinitions': 'Definiçoes do motor',
  'app.pages.DepartmentPage.loginAction': 'Açoes ao fazer login',
  'app.pages.DepartmentPage.deniedItems': 'Accesos negados',
  'app.pages.DepartmentPage.name.isRequired': 'O nome e necessário',
  'app.pages.DepartmentPage.company.notFound': 'Empresa não encontrada',
  'app.pages.DepartmentPage.company.placeholder': 'Escolha una empresa',
  'app.pages.DepartmentPage.name.maxLengthError': 'o tamaño máximo do nome e {number} caracteres',
  'app.pages.DepartmentPage.department.notFound': 'Departamento não encontrado',
  'app.pages.DepartmentPage.department.created': 'Departamento criado',
  'app.pages.DepartmentPage.department.updated': 'Departamento atualizado',
  'app.pages.DepartmentPage.departmentManagerTable': 'Tabela de administrador de departamentos',
  'app.pages.DepartmentPage.addLoginAction': 'Adicionar ação de login',
  'app.pages.DepartmentPage.department': 'Departamento',

  //Role Manager
  'app.pages.RolePage.addRole': 'Criar cargo',
  'app.pages.RolePage.editRole': 'Editar cargo',
  'app.pages.RolePage.role.notFound': 'Cargo não encontrado',
  'app.pages.RolePage.role.permission.template': 'Modelo de permissões',
  'app.pages.RolePage.role.permission.administrator': 'Admininistrador',
  'app.pages.RolePage.role.permission.appAdministrator': 'Administrador de app',
  'app.pages.RolePage.role.permission.creatorWithPublicAccess': 'Criador com Acesso ao Public',
  'app.pages.RolePage.role.permission.creator': 'Criador',
  'app.pages.RolePage.role.permission.explorer': 'Navegador',
  'app.pages.RolePage.role.permission.viewer': 'Visualizador',
  'app.pages.RolePage.role.permission.loginOnlyUser': 'Usuário Apenas para Login',
  'app.pages.RolePage.role.permission.override': 'Permissões substituídas',
  'app.pages.RolePage.role.created': 'Cargo criado',
  'app.pages.RolePage.role.updated': 'Cargo atualizado',
  'app.pages.RolePage.name.maxLengthError': 'o tamaño máximo do nome e {number} caracteres',
  'app.pages.RolePage.name.exist': 'O nome de cargo existe',
  'app.pages.RolePage.roleManagerTable': 'Tabela de administrador de cargo',

  //notFoundPage
  'app.components.notFoundPage.message': 'a página que você está procurando não existe',
  'app.components.notFoundPage.orGoTo': 'ou ir para',
  'app.components.notFoundPage.returnToThe': 'Retornar para a',
  'app.components.notFoundPage.previousPage': 'página anterior',
  'app.components.notFoundPage.homePage': 'página inicial',

  //Company Preferences
  'app.pages.app.companyManager.companyPreferences.notFound': 'Preferências não encontrado',
  'app.pages.app.companyManager.companyPreferences.title': 'Preferências da empresa ',
  'app.pages.app.companyManager.companyPreferences.copyDefaultValue': 'Valor padrão de cópia',
  'app.pages.app.companyManager.companyPreferences.defaultValueCopied': 'Valor padrão copiado',
  'app.pages.app.companyManager.companyPreferences.preferenceUpdated': 'Preferência atualizada',
  'app.pages.app.companyManager.companyPreferences.preferenceCreated': 'Preferência criada',
  'app.pages.app.companyManager.companyPreferences.preferenceDelete': 'Preferência retirada',
  'app.pages.app.companyManager.companyPreferences.saveCustomValue': 'Salvar valor personalizado',
  'app.pages.app.companyManager.companyPreferences.removeCustomValue':
    'Retirar valor personalizado',

  //Permissions by role
  'app.pages.app.permissionsByRole': 'Permissões por cargo',
  'app.pages.app.permissionsByRole.permissionActivated': 'Permissão ativado',
  'app.pages.app.permissionsByRole.permissionDeactivated': 'Permissão desativado',
  'app.pages.app.permissionsByRole.allPermissionsActivated':
    'todas as permissões de {name} foram ativadas',
  'app.pages.app.permissionsByRole.permission': 'Permissão',
  'app.pages.app.permissionsByRole.permissionsUpdated': 'Permissões atualizadas',

  //team manager
  'app.pages.app.teamManager.team': 'Administrador de equipes',
  'app.pages.app.teamManager.addTeam': 'Criar equipe',
  'app.pages.app.teamManager.editTeam': 'Editar equipe',
  'app.pages.app.teamManager.accessLevel': 'Nível de acessos',
  'app.pages.app.teamManager.teamDeleted': 'Equipe {name} excluido',
  'app.pages.app.teamManager.deleteTeam': 'Borrar equipo',
  'app.pages.app.teamManager.areYouSureToDeleteTeam':
    'Você tem certeza de excluir o equipe {name}?',
  'app.pages.app.teamManager.departmentsAsociated':
    'Está associado com os seguintes departamentos:',
  'app.pages.app.teamManager.readOnly': 'Somente leitura',
  'app.pages.app.teamManager.readAndWrite': 'Leitura e escrita',
  'app.pages.app.teamManager.updateTeam': 'Atualizar equipe',
  'app.pages.app.teamManager.createTeam': 'Criar equipe',
  'app.pages.app.teamManager.teamDepartments': 'Não há departmentos',
  'app.pages.app.teamManager.teamUpdated': 'Equipe atualizado',
  'app.pages.app.teamManager.teamCreated': 'Equipe criado',
  'app.pages.teamManager.name.maxLengthError': 'o tamaño máximo do nome e {number} caracteres',
  'app.pages.teamManager.teamsNotFound': 'Equipes não encontrados',
  //User Manager
  'app.pages.UserPage.userManager': 'Administrador de usuários',
  'app.pages.UserPage.addUser': 'Criar usuário',
  'app.pages.UserPage.editUser': 'Editar usuário',
  'app.pages.UserPage.email': 'Email',
  'app.pages.UserPage.login': 'Nome de usuário',
  'app.pages.UserPage.usersNotFound': 'Usuário não encontrado',
  'app.pages.UserPage.changePassword': 'Mudar a senha',
  'app.pages.UserPage.manageCompanies': 'Gerenciar empresas',
  'app.pages.UserPage.passwordChanged': 'Senha alterada',
  'app.pages.UserPage.downloadReport': 'Baixar Relatório de usuários',
  'app.pages.UserPage.reportsDownloaded': 'Relatório de usuários baixado',
  'app.pages.UserPage.firstName': 'Nome',
  'app.pages.UserPage.firstNameIsRequired': 'Nome é necessário',
  'app.pages.UserPage.firstNameMaxLength': 'o tamaño máximo do nome é {number} caracteres',
  'app.pages.UserPage.firstNamePlaceholder': 'Inserir sua nome',
  'app.pages.UserPage.lastNamePlaceholder': 'Inserir sua sobrenombe',
  'app.pages.UserPage.lastNameIsRequired': 'Sobrenome é necessário',
  'app.pages.UserPage.lastNameMaxLength': 'o tamaño máximo do sobrenombe é {number} caracteres',
  'app.pages.UserPage.emailPlaceholder': 'Inserir sua email',
  'app.pages.UserPage.emailIsInvalid': 'Email errado',
  'app.pages.UserPage.emailContainingÑ': 'E-mail não pode conter "Ñ"',
  'app.pages.UserPage.emailIsRequired': 'Email é necessário',
  'app.pages.UserPage.emailAlreadyExist': 'Email já existe',
  'app.pages.UserPage.lastName': 'Sobrenome',
  'app.pages.UserPage.userNameIsRequired': 'Nome de usuário é necessário',
  'app.pages.UserPage.userNamePlaceholder': 'Inserir sua nome de usuário',
  'app.pages.UserPage.passwordPlaceholder': 'Inserir sua senha',
  'app.pages.UserPage.usernameAlreadyExist': 'Nome de usuário já existe',
  'app.pages.UserPage.usernameInvalid':
    'Nome de usuário pode ter "@", "+", "-", "_" or "." caracteres. Espaços em branco não permitidos',
  'app.pages.UserPage.steps.userInformation': 'Informações de usuário',
  'app.pages.UserPage.steps.roleAndCompanySelection': 'Seleção de cargo e empresas',
  'app.pages.UserPage.steps.roleAndCompanySelection.departmentPlaceholder':
    'Escolha um departamento',
  'app.pages.UserPage.steps.roleAndCompanySelection.rolePlaceholder': 'Escolha un cargo',
  'app.pages.UserPage.steps.roleAndCompanySelection.addCompany': 'Adicionar empresa',
  'app.pages.UserPage.steps.roleAndCompanySelection.removeCompany': 'Remover empresa',
  'app.pages.UserPage.steps.roleAndCompanySelection.noCompaniesAdded':
    'Empresas não encontradas, escolha uma',
  'app.pages.UserPage.steps.roleAndCompanySelection.roleWarning':
    'Este cargo aplica a todas as empresas',
  'app.pages.UserPage.toggleActiveUser.errorNoRoles':
    '{user} não tem um cargo para a empresa {company}',
  'app.pages.UserPage.toggleActiveUser.activated': 'Usuário ativado',
  'app.pages.UserPage.toggleActiveUser.desactivated': 'Usuário desativado',
  'app.pages.UserPage.userUnlocked': 'Usuário desbloqueado',
  'app.pages.UserPage.userCreated': 'Usuário criado',
  'app.pages.UserPage.userUpdated': 'Usuário atualizado',
  'app.pages.UserPage.errorCompanyWithNoRoles': 'Há uma company sem cargos',
  'app.pages.UserPage.errorCompanyWithNoDepartments': 'Há uma company sem departamentos',
  'app.pages.UserPage.deleteUser': 'Eliminar usuário',
  'app.pages.UserPage.areYouSureToDeleteUser': 'Tem certeza que deseja eliminar o usuário {name}?',
  'app.pages.UserPage.userDeleted': 'Usuário eliminado',
  'app.pages.UserPage.changeStatus': 'Alterar estado',
  'app.pages.UserPage.unlockUser': 'Desbloquear usuário',
  'app.pages.UserPage.ChangePasswordLogin': 'Modificar senha ao iniciar sessão',
  'app.pages.UserPage.enableMfa': 'Habilitar autenticação de vários fatores',
  'app.pages.UserPage.setNewQrCode': 'Configurar um novo código QR',
  'app.pages.UserPage.validateSetNewQRCode':
    'Será configurado um novo código QR para o usuário {user}. Deseja continuar?',
  'app.pages.UserPage.qrCodeSuccesfullySeted': 'Novo código QR configurado',

  //Code Diagram Nodes
  'app.pages.Code.Diagram.Nodes.variable': 'Variável',
  'app.pages.Code.Diagram.Nodes.datareading': 'Leitura de dados',
  'app.pages.Code.Diagram.Nodes.button': 'Botão',
  'app.pages.Code.Diagram.Nodes.index': 'Índice',
  'app.pages.Code.Diagram.Nodes.module': 'Módulo',
  'app.pages.Code.Diagram.Nodes.inputscalar': 'Escalar',
  'app.pages.Code.Diagram.Nodes.inputselector': 'Seletor',
  'app.pages.Code.Diagram.Nodes.inputchoice': 'Opção',
  'app.pages.Code.Diagram.Nodes.inputdataframe': 'Tabela de dados',
  'app.pages.Code.Diagram.Nodes.inputdataarray': 'Matriz de dados',
  'app.pages.Code.Diagram.Nodes.inputcube': 'Cubo',
  'app.pages.Code.Diagram.Nodes.text': 'Texto',
  'app.pages.Code.Diagram.Nodes.generic': 'Genérico',
  'app.pages.Code.Diagram.Nodes.form': 'Formulario',
  'app.pages.Code.Diagram.Nodes.inputInitialName': 'Nome do nó',
  'app.pages.Code.Diagram.Nodes.inputData': 'Entrada de dados',
  'app.pages.Code.Diagram.Nodes.chooseInputType': 'Escolha o tipo de entrada de dados',
  'app.pages.Code.Diagram.Nodes.report': 'Relatório',
  'app.pages.Code.Diagram.Nodes.outputnode': 'Saída',
  'app.pages.Code.Diagram.Nodes.reportwizard': 'Wizard de relatório',

  //Code Diagram Menu
  'app.pages.Code.Diagram.Menu.hideMenu': 'Ocultar menu',
  'app.pages.Code.Diagram.Menu.wizards': 'Assistentes',
  'app.pages.Code.Diagram.Menu.importModule': 'Importação do módulo',
  'app.pages.Code.Diagram.Menu.importModule.steps.selectModule': 'Seleção de módulo',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType': 'Tipo de importação',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType.switch': 'Mudança',
  'app.pages.Code.Diagram.Menu.importModule.steps.importType.merge': 'Mesclar',
  'app.pages.Code.Diagram.Menu.importModule.sucessful': 'Módulo importado com sucesso',
  'app.pages.Code.Diagram.Menu.importModule.hasOutputsOutsideItsModule':
    'Tem saídas fora do seu módulo',
  //Code Diagram Delete dialog
  'app.pages.Code.Diagram.DeleteDialog.deleteNodes': 'Deletar nós?',
  'app.pages.Code.Diagram.DeleteDialog.confirmationtext':
    'Tem certeza de que deseja deletar {number} nós selecionados?',
  //Node Properties Dialog
  'app.pages.Code.Diagram.Nodes.properties.title': 'Título do nó',
  'app.pages.Code.Diagram.Nodes.properties.id': 'Identificador do nó',
  'app.pages.Code.Diagram.Nodes.properties.nodeClass': 'Tipo de nó',
  'app.pages.Code.Diagram.Nodes.properties.units': 'Unidades',
  'app.pages.Code.Diagram.Nodes.properties.evaluateOnStart': 'Avaliar no início',
  'app.pages.Code.Diagram.Nodes.properties.releaseMemory': 'Liberar memória',
  'app.pages.Code.Diagram.Nodes.properties.prefixIdentifier': 'Prefixo do módulo',
  'app.pages.Code.Diagram.Nodes.properties.idHelperText':
    'O identificador deve começar com uma letra e pode conter letras minúsculas, números e underlines',
  'app.pages.Code.Diagram.Nodes.properties.idRequired':
    'O identificador de nó não pode estar vazio',
  'app.pages.Code.Diagram.Nodes.properties.prefixIdHelperText':
    'O prefixo do módulo deve começar com uma letra e pode conter letras minúsculas, números e underlines',
  'app.pages.Code.Diagram.Nodes.properties.hierarchyMap': 'Mapa de hierarquia',
  'app.pages.Code.Diagram.Nodes.inputs': 'Entradas',
  'app.pages.Code.Diagram.Nodes.outputs': 'Saídas',
  'app.pages.Code.Diagram.Nodes.general': 'Geral',
  'app.pages.Code.Diagram.Nodes.metadata': 'Metadados',
  'app.pages.Code.Diagram.Nodes.creationDate': 'Data de criação',
  'app.pages.Code.Diagram.Nodes.lastModification': 'Última modificação',
  'app.pages.Code.Diagram.Nodes.lastUserThatModified': 'Último usuário que modificou',
  'app.pages.Code.Diagram.Nodes.docs': 'Documentação',
  'app.pages.Code.Diagram.Nodes.noInputs': 'Não há entradas',
  'app.pages.Code.Diagram.Nodes.noOutputs': 'Não há saídas',
  'app.pages.Code.Diagram.Nodes.noDocs': 'Não há documentação para esta seção',
  'app.pages.Code.Diagram.Nodes.editDocs': 'Editar docs',
  'app.pages.Code.Diagram.Nodes.docsEdited': 'Documentação editada',
  'app.pages.Code.Diagram.Nodes.selectNodeToShowDocs': 'Escolha um nó para mostrar a documentação',
  'app.pages.Code.itemHeader.saveDefaultView': 'Salvar visualização padrão',
  //Context Menu
  'app.pages.Code.Diagram.ContextMenu.resize': 'Redimensionar nós como {string}',
  'app.pages.Code.Diagram.ContextMenu.alignment': 'Alinhar nós para o {string}',
  'app.pages.Code.Diagram.ContextMenu.showInputs': 'Mostrar entradas',
  'app.pages.Code.Diagram.ContextMenu.showOutputs': 'Mostrar saídas',
  'app.pages.Code.Diagram.ContextMenu.sizes.width': 'Mesma largura',
  'app.pages.Code.Diagram.ContextMenu.sizes.height': 'Mesmo alto',
  'app.pages.Code.Diagram.ContextMenu.sizes.size': 'Mesmo tamanho',
  'app.pages.Code.Diagram.ContextMenu.alignment.top': 'Bordas superiores',
  'app.pages.Code.Diagram.ContextMenu.alignment.centerH': 'Centralizar horizontalmente',
  'app.pages.Code.Diagram.ContextMenu.alignment.centerV': 'Centralizar verticalmente',
  'app.pages.Code.Diagram.ContextMenu.alignment.bottom': 'Bordas inferiores',
  'app.pages.Code.Diagram.ContextMenu.alignment.right': 'Bordas direitas',
  'app.pages.Code.Diagram.ContextMenu.alignment.left': 'Bordas esquerdas',
  'app.pages.Code.Diagram.ContextMenu.exportModule': 'Exportar módulo',
  'app.pages.Code.Diagram.ContextMenu.moduleExported': 'Módulo exportado',
  'app.pages.Code.Diagram.ContextMenu.setRestriction': 'Definir restrição',
  'app.pages.Code.Diagram.ContextMenu.pinNode': 'Fixar nó',
  'app.pages.Code.Diagram.ContextMenu.unpinNode': 'Excluir nó',
  'app.pages.Code.Diagram.ContextMenu.apiEndpoint': 'Obter API URL',
  'app.pages.Code.Diagram.apiEndpoint.endpointFor': 'API URL para: ',
  'app.pages.Code.Diagram.apiEndpoint.shareInstance': 'Compartilhar instância',
  'app.pages.Code.Diagram.apiEndpoint.apiURLcopied': 'API URL copiado ',
  'app.pages.Code.Diagram.apiEndpoint.message':
    'e envie os parâmetros da função como um objeto JSON no corpo da solicitação.',
  'app.pages.Code.Diagram.ContextMenu.openWizard': 'Abrir o assistente',
  'app.pages.Code.Diagram.ContextMenu.compareNodes': 'Comparar nós',
  'app.pages.Code.Diagram.ContextMenu.handlingDataWidget': 'Manipulação de dados',
  'app.pages.Code.Diagram.Wizards.dropDuplicates': 'Eliminar duplicados',
  'app.pages.Code.Diagram.ContextMenu.dataSystemNodeNoData': 'Nó de sistema de dados sem dados',

  //Code Diagram Wizards
  'app.pages.Code.Diagram.Wizards.openWizard': 'Abrir o assistente',
  'app.pages.Code.Diagram.Wizards.selectColumns': 'Selecionar colunas',
  'app.pages.Code.Diagram.Wizards.editInExcel': 'Edit no Excel',
  'app.pages.Code.Diagram.Wizards.selectRows': 'Selecionar linhas',
  'app.pages.Code.Diagram.Wizards.name': 'Nome da coluna',
  'app.pages.Code.Diagram.Wizards.isIndex': 'É índice',
  'app.pages.Code.Diagram.Wizards.dtype': 'Tipo de dados',
  'app.pages.Code.Diagram.Wizards.editTableDefinition': 'Editar definição da Tabela',
  'app.pages.Code.Diagram.Wizards.removeColumn': 'Excluir coluna',
  'app.pages.Code.Diagram.Wizards.addNewColumn': 'Inserir nova coluna',
  'app.pages.Code.Diagram.Wizards.column': 'Coluna',
  'app.pages.Code.Diagram.Wizards.editCubeDefinition': 'Editar definição do Cubo',
  'app.pages.Code.Diagram.Wizards.deleteCubeData': 'Excluir dados do Cubo',
  'app.pages.Code.Diagram.Wizards.deleteCubeDataMessage':
    'Tem certeza de que deseja excluir os dados do Cubo do banco de dados?',
  'app.pages.Code.Diagram.Wizards.deleteCubeDataConfirm':
    'Os dados do Cubo foram excluídos com sucesso do banco de dados',
  'app.pages.Code.Diagram.Wizards.availableIndexes': 'Índices disponíveis',
  'app.pages.Code.Diagram.Wizards.selectedIndexes': 'Índices selecionados',
  'app.pages.Code.Diagram.Wizards.filter': 'Filtrar',
  'app.pages.Code.Diagram.Wizards.defaultValue': 'Valor padrão',
  'app.pages.Code.Diagram.Wizards.defaultValueType': 'Tipo de valor padrão',
  'app.pages.Code.Diagram.Wizards.scalar': 'Escalar',
  'app.pages.Code.Diagram.Wizards.model_node': 'Nó do modelo',
  'app.pages.Code.Diagram.Wizards.app_node': 'Nó da app',
  'app.pages.Code.Diagram.Wizards.node_id': 'O identificador do nó já existe, selecione outro',
  'app.pages.Code.Diagram.Wizards.calculatedField': 'Campo calculado',
  'app.pages.Code.Diagram.Wizards.setIndex': 'Definir índice',
  'app.pages.Code.Diagram.Wizards.groupAgregate': 'Agrupar/agregar',
  'app.pages.Code.Diagram.Wizards.createindex': 'Criar Índice',
  'app.pages.Code.Diagram.Wizards.createcube': 'Criar Cubo',
  'app.pages.Code.Diagram.Wizards.form': 'Formulario',
  'app.pages.Code.Diagram.Wizards.report': 'Relatório',
  'app.pages.Code.Diagram.Wizards.editFormDefinition': 'Editar definição do Formulario',
  'app.pages.Code.Diagram.Wizards.deleteFormData': 'Excluir dados do Formulario',
  'app.pages.Code.Diagram.Wizards.deleteFormDataMessage':
    'Tem certeza de que deseja excluir os dados do Formulario do banco de dados?',
  'app.pages.Code.Diagram.Wizards.deleteFormDataConfirm':
    'Os dados do Formulario foram excluídos com sucesso do banco de dados',
  'app.pages.Code.Diagram.Wizards.formFieldError': 'Id ou título do campo não pode ser "id"',
  'app.pages.Code.Diagram.Wizards.fieldId': 'ID do campo',
  'app.pages.Code.Diagram.Wizards.fieldTitle': 'Título do campo',
  'app.pages.Code.Diagram.Wizards.columnType': 'Tipo de coluna',
  'app.pages.Code.Diagram.Wizards.hidden': 'Escondido',
  'app.pages.Code.Diagram.Wizards.readOnly': 'Somente leitura',
  'app.pages.Code.Diagram.Wizards.formula': 'Fórmula',
  'app.pages.Code.Diagram.Wizards.values': 'Valores',
  'app.pages.Code.Diagram.Wizards.filterRowsByDomain': 'Filtrar linhas por domínio',
  'app.pages.Code.Diagram.Wizards.strict': 'Estrito',
  'app.pages.Code.Diagram.Wizards.relatedMap': 'Mapa relacionado',
  'app.pages.Code.Diagram.Wizards.relatedMapColumnValues': 'Coluna com valores',
  'app.pages.Code.Diagram.Wizards.relatedColumns': 'Colunas relacionadas',
  'app.pages.Code.Diagram.Wizards.fromNode': 'Desde nó',
  'app.pages.Code.Diagram.Wizards.fromNodeDetails':
    'O resultado do nó deve ser uma função que recebe um DataFrame de Pandas como parâmetro e retorna um DataFrame de Pandas',
  'app.pages.Code.Diagram.Wizards.advancedOptions': 'Opções avançadas',
  'app.pages.Code.Diagram.Wizards.appNode': 'Nó no app',
  'app.pages.Code.Diagram.Wizards.typeNewValues':
    'Escrever novos valores. Pressionar Enter para confirmar',
  'app.pages.Code.Diagram.Wizards.columnType.input': 'Input',
  'app.pages.Code.Diagram.Wizards.columnType.checkbox': 'Checkbox',
  'app.pages.Code.Diagram.Wizards.columnType.selector': 'Seletor',
  'app.pages.Code.Diagram.Wizards.columnType.related_selector': 'Seletor relacionado',
  'app.pages.Code.Diagram.Wizards.columnType.calc': 'Cálculo',
  'app.pages.Code.Diagram.Wizards.columnType.dataframe_column': 'Coluna de DataFrame',
  'app.pages.Code.Diagram.Wizards.columnType.system': 'Sistema',
  'app.pages.Code.Diagram.Wizards.tableName': 'Nome da tabela',
  'app.pages.Code.Diagram.Wizards.formSettings': 'Opções do formulario',
  'app.pages.Code.Diagram.Wizards.editFormSettings': 'Editar opções do formulario',
  'app.pages.Code.Diagram.Wizards.addNewField': 'Inserir novo campo',
  'app.pages.Code.Diagram.Wizards.addNewItem': 'Inserir novo elemento',
  'app.pages.Code.Diagram.Wizards.databaseEngine': 'Motor de banco de dados',
  'app.pages.Code.Diagram.Wizards.databaseConnection': 'Conexão a banco de dados',
  'app.pages.Code.Diagram.Wizards.databasePath': 'Caminho a banco de dados',
  'app.pages.Code.Diagram.Wizards.host': 'Host',
  'app.pages.Code.Diagram.Wizards.database': 'Banco de dados',
  'app.pages.Code.Diagram.Wizards.databaseCustomURL': 'URL personalizada',
  'app.pages.Code.Diagram.Wizards.initialEmptyRows': 'Linhas vazías iniciais',
  'app.pages.Code.Diagram.Wizards.allowAddRows': 'Permitir inserir linhas',
  'app.pages.Code.Diagram.Wizards.allowRemoveRows': 'Permitir excluir linhas',
  'app.pages.Code.Diagram.Wizards.allowAddNewColumns': 'Permitir inserir novas colunas',
  'app.pages.Code.Diagram.Wizards.fixedColumns': 'Colunas fixas',
  'app.pages.Code.Diagram.Wizards.formRecreateTableMessage':
    'Todos os dados do formulário serão apagados e serão recriados do zero',
  'app.pages.Code.Diagram.Wizards.formSubmitConfirmation':
    'A definição do formulário será substituída.',
  'app.pages.Code.Diagram.Wizards.fromDataFrame': 'Desde DataFrame',
  'app.pages.Code.Diagram.Wizards.sourceDataFrameNode': 'Nó com DataFrame original',
  'app.pages.Code.Diagram.Wizards.sourceDataFrameIdColumn': 'Coluna ID do DataFrame original',
  'app.pages.Code.Diagram.Wizards.removeOldRows': 'Excluir linhas antigas',
  'app.pages.Code.Diagram.Wizards.newFieldName': 'Nome do novo campo',
  'app.pages.Code.Diagram.Wizards.newItemName': 'Nome do novo item',
  'app.pages.Code.Diagram.Wizards.expression': 'Expressão',
  'app.pages.Code.Diagram.Wizards.fieldList': 'Campos disponíveis',
  'app.pages.Code.Diagram.Wizards.agregate': 'funções de agregação',
  'app.pages.Code.Diagram.Wizards.groupBy': 'Agrupar por',
  'app.pages.Code.Diagram.Wizards.filterDataFrame': 'Filtrar DataFrame',
  'app.pages.Code.Diagram.Wizards.condition': 'Condição',
  'app.pages.Code.Diagram.Wizards.addNewFilter': 'Adicionar novo filtro',
  'app.pages.Code.Diagram.Wizards.valueIsRequired': 'Valor é obrigatório',
  'app.pages.Code.Diagram.Wizards.mustHaveAtLeastValues': 'Deve ter pelo menos {number} valor/es',
  'app.pages.Code.Diagram.Wizards.filterDataArray': 'Filtrar DataArray',
  'app.pages.Code.Diagram.Wizards.dimension': 'Dimensão',
  'app.pages.Code.Diagram.Wizards.editIndex': 'Editar índice',
  'app.pages.Code.Diagram.Wizards.itemList': 'Lista de itens',
  'app.pages.Code.Diagram.Wizards.count': 'Contagem',
  'app.pages.Code.Diagram.Wizards.insertAbove': 'Inserir linha acima',
  'app.pages.Code.Diagram.Wizards.insertBelow': 'Inserir linha abaixo',
  'app.pages.Code.Diagram.Wizards.removeRow': 'Remover linha',
  'app.pages.Code.Diagram.Wizards.index': 'Índice',
  'app.pages.Code.Diagram.Wizards.renameIndex': 'Renomear índice',
  'app.pages.Code.Diagram.Wizards.files': 'Arquivos',
  'app.pages.Code.Diagram.Wizards.databases': 'Bancos de dados',
  'app.pages.Code.Diagram.Wizards.online': 'Online',
  'app.pages.Code.Diagram.Wizards.dataReadingNode': 'Nó de leitura de dados',
  'app.pages.Code.Diagram.Wizards.readCsvFile': 'Ler arquivo CSV',
  'app.pages.Code.Diagram.Wizards.readExcelFile': 'Ler arquivo Excel',
  'app.pages.Code.Diagram.Wizards.dataPreview': 'Pré-visualização de dados',
  'app.pages.Code.Diagram.Wizards.schema': 'Esquema',
  'app.pages.Code.Diagram.Wizards.csvDataSource': 'Fonte de dados CSV',
  'app.pages.Code.Diagram.Wizards.excelDataSource': 'Fonte de dados Excel',
  'app.pages.Code.Diagram.Wizards.backStep': 'Voltar',
  'app.pages.Code.Diagram.Wizards.nextStep': 'Próximo',
  'app.pages.Code.Diagram.Wizards.chooseDataSource': 'Escolha uma fonte de dados',
  'app.pages.Code.Diagram.Wizards.selectFile': 'Selecionar arquivo do servidor',
  'app.pages.Code.Diagram.Wizards.selectFileFromUrl': 'Selecionar arquivo de URL',
  'app.pages.Code.Diagram.Wizards.encoding': 'Codificação',
  'app.pages.Code.Diagram.Wizards.separator': 'Separador',
  'app.pages.Code.Diagram.Wizards.dataSetSample': 'Exemplo de conjunto de dados',
  'app.pages.Code.Diagram.Wizards.sheetName': 'Nome da planilha',
  'app.pages.Code.Diagram.Wizards.definedRanges': 'Intervalos definidos',
  'app.pages.Code.Diagram.Wizards.customRange': 'Intervalo personalizado',
  'app.pages.Code.Diagram.Wizards.setNewHeaderFirstRow':
    'Definir novo cabeçalho com a primeira linha',
  'app.pages.Code.Diagram.Wizards.exampleCustomRange': 'Exemplo: A1:B10',
  'app.pages.Code.Diagram.Wizards.sortDataFrame': 'Ordenar DataFrame',
  'app.pages.Code.Diagram.Wizards.criteria': 'Critério',
  'app.pages.Code.Diagram.Wizards.sort': 'Ordenar',
  'app.pages.Code.Diagram.Wizards.ascending': 'Ascendente',
  'app.pages.Code.Diagram.Wizards.descending': 'Descendente',
  'app.pages.Code.Diagram.Wizards.addNewOrderCondition': 'Adicionar nova condição de classificação',
  'app.pages.Code.Diagram.Wizards.indexOptions': 'Opções de índice',
  'app.pages.Code.Diagram.Wizards.transform': 'Transformar',
  'app.pages.Code.Diagram.Wizards.toDataFrame': 'Para DataFrame',
  'app.pages.Code.Diagram.Wizards.changeIndex': 'Alterar índice',
  'app.pages.Code.Diagram.Wizards.oldIndex': 'Índice anterior',
  'app.pages.Code.Diagram.Wizards.newIndex': 'Novo índice',
  'app.pages.Code.Diagram.Wizards.compareMode': 'Modo de comparação',
  'app.pages.Code.Diagram.Wizards.searchIndex': 'Pesquisar índice',
  'app.pages.Code.Diagram.Wizards.byPosition': 'Por posição',
  'app.pages.Code.Diagram.Wizards.byValue': 'Por valor',
  'app.pages.Code.Diagram.Wizards.list': 'Lista',
  'app.pages.Code.Diagram.Wizards.rangeType': 'Tipo de intervalo',
  'app.pages.Code.Diagram.Wizards.frequency': 'Frequência',
  'app.pages.Code.Diagram.Wizards.cumulative': 'Cumulativo',
  'app.pages.Code.Diagram.Wizards.periods': 'Períodos',
  'app.pages.Code.Diagram.Wizards.stringPrefix': 'Prefixo de string',
  'app.pages.Code.Diagram.Wizards.to': 'Para',
  'app.pages.Code.Diagram.Wizards.steps': 'Passos',
  'app.pages.Code.Diagram.Wizards.enterNumericalValue': 'Digite um valor numérico',
  'app.pages.Code.Diagram.Wizards.invalidDate':
    'Data inválida, favor inserir uma data com formato: AAAA-MM-DD',
  'app.pages.Code.Diagram.Wizards.startDateIsRequired': 'Fecha de inicio es requerida',
  'app.pages.Code.Diagram.Wizards.endDateIsRequired': 'Fecha de fin es requerida',
  'app.pages.Code.Diagram.Wizards.yearly': 'Anual',
  'app.pages.Code.Diagram.Wizards.monthly': 'Mensal',
  'app.pages.Code.Diagram.Wizards.weekly': 'Semanal',
  'app.pages.Code.Diagram.Wizards.daily': 'Diário',
  'app.pages.Code.Diagram.Wizards.saveLabels': 'Salvar rótulos',
  'app.pages.Code.Diagram.Wizards.nestedHeaders': 'Cabeçalhos aninhados',
  'app.pages.Code.Diagram.Wizards.nestedHeadersTypeNewValues':
    'Escrever novos cabeçalhos aninhados. Pressionar Enter para confirmar',
  'app.pages.Code.Diagram.Wizards.deleteAndRecreateTable': 'Eliminar e recriar tabela',
  'app.pages.Code.Diagram.Wizards.itemName': 'Nome de elemento',
  'app.pages.Code.Diagram.Wizards.editReportDefinition': 'Editar definição do Relatório',
  'app.pages.Code.Diagram.Wizards.itemsFromIndex': 'Elementos desde índice',
  'app.pages.Code.Diagram.Wizards.indexNode': 'Nó índice',
  'app.pages.Code.Diagram.Wizards.addItemsFromIndex': 'Inserir elementos desde índice',
  'app.pages.Code.Diagram.Wizards.asNegative': 'Como negativo',
  'app.pages.Code.Diagram.Wizards.itemIsNotInItemsList':
    'O elemento "{item}" não existe na lista de elementos do nó "{nodeId}"',
  'app.pages.Code.Diagram.Wizards.reportSubmitConfirmation':
    'A definição do relatório será substituída.',
  'app.pages.Code.Diagram.Wizards.outputType': 'Tipo de saída',
  'app.pages.Code.Diagram.Wizards.removeNonExistingCombinations':
    'Eliminar combinações inexistentes',
  'app.pages.Code.Diagram.Wizards.allowDimensionChanges': 'Permitir alteração de dimensões',
  'app.pages.Code.Diagram.Wizards.confirmWizardChanges': 'Confirmar alterações do assistente',
  'app.pages.Code.Diagram.Wizards.overWriteDefinition': 'Sobrescrever definição',
  'app.pages.Code.Diagram.Wizards.createNewNodeWithCurrentChanges':
    'Criar novo nó com alterações atuais',
  'app.pages.Code.Diagram.Wizards.undo': 'Desfazer',
  'app.pages.Code.Diagram.Wizards.redo': 'Refazer',
  'app.pages.Code.Diagram.Wizards.renameColumn': 'Renomear coluna',
  'app.pages.Code.Diagram.Wizards.deleteColumn': 'Eliminar coluna',
  'app.pages.Code.Diagram.Wizards.duplicateColumn': 'Duplicar coluna',
  'app.pages.Code.Diagram.Wizards.changeDataType': 'Alterar tipo de dados',
  'app.pages.Code.Diagram.Wizards.sortColumn': 'Ordenar coluna',
  'app.pages.Code.Diagram.Wizards.removeDuplicates': 'Eliminar duplicados',
  'app.pages.Code.Diagram.Wizards.newColumnName': 'Novo nome da coluna',
  'app.pages.Code.Diagram.Wizards.deleteColumnMessage':
    'A coluna {column} será removida do dataframe. Deseja continuar?',
  'app.pages.Code.Diagram.Wizards.newDuplicateColumnName': 'Novo nome da coluna duplicada',
  'app.pages.Code.Diagram.Wizards.changeColumnType': 'Alterar tipo de coluna',
  'app.pages.Code.Diagram.Wizards.columnSanitization': 'Limpieza de coluna',
  'app.pages.Code.Diagram.Wizards.addNewSanitizeAction': 'Adicionar nova ação de limpeza',
  'app.pages.Code.Diagram.Wizards.sanitizationColumnOptions': 'Opções para limpar a coluna',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveDuplicatesWords':
    'Remover palavras duplicadas da coluna',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceMultiSpace':
    'Substituir múltiplos espaços por um único espaço',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemovePunctuation':
    'Remover a pontuação do texto',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveNumbers': 'Remover números do texto',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.KeepOnlyAlphaNumeric':
    'Manter apenas caracteres alfanuméricos',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.NormalizeAccents':
    'Normalizar acentos do texto',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveSpaces': 'Remover espaços do texto',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.AddSpaceBetweenWords':
    'Adicionar espaço entre palavras',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveRepeatedChars':
    'Remover caracteres repetidos',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.UpperWordCase': 'Palavra em maiúscula',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.LowerWordCase': 'Palavra em minúscula',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.TitleWordCase': 'Palavra em título',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.RemoveHiddenChars':
    'Remover caracteres ocultos',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceHypensWithSpace':
    'Substituir hífens por espaços',
  'app.pages.Code.Diagram.Wizards.cleanColumnOptions.ReplaceText': 'Substituir texto',
  'app.pages.Code.Diagram.Wizards.textToReplace': 'Texto a ser substituído',
  'app.pages.Code.Diagram.Wizards.replaceWith': 'Substituir por',
  'app.pages.Code.Diagram.Wizards.stopWords': 'Palavras de parada',
  'app.pages.Code.Wizards.Upper': 'Maiúsculas',
  'app.pages.Code.Wizards.Lower': 'Minúsculas',
  'app.pages.Code.Wizards.Title': 'Título',
  'app.pages.Code.Wizards.Capitalize': 'Capitalizar',
  'app.pages.Code.Wizards.Swapcase': 'Trocar maiúsculas e minúsculas',
  'app.pages.Code.Diagram.Wizards.applyChangesInNewColumn': 'Aplicar alterações em nova coluna',
  'app.pages.Code.Diagram.Wizards.fillEmptyValues': 'Preencher valores vazios',
  'app.pages.Code.Diagram.Wizards.fillValue': 'Preencher valor',
  'app.pages.Code.Diagram.Wizards.fillMethod': 'Método de preenchimento',
  'app.pages.Code.Diagram.Wizards.searchConditionValue': 'Valor de condição de pesquisa',

  //Interfaces
  'app.pages.Interfaces.Table.add1RowAbove': 'Inserir 1 linha acima',
  'app.pages.Interfaces.Table.add5RowsAbove': 'Inserir 5 linhas acima',
  'app.pages.Interfaces.Table.add1RowBelow': 'Inserir 1 linha embaixo',
  'app.pages.Interfaces.Table.add5RowsBelow': 'Inserir 5 linhas embaixo',
  'app.pages.Interfaces.Table.addRow': 'Inserir linha',
  'app.pages.Interfaces.Table.removeRow': 'Excluir linha/s',
  'app.pages.Interfaces.Table.copy': 'Copiar',
  'app.pages.Interfaces.Table.copyWithHeaders': 'Copiar com cabeçalho',
  'app.pages.Interfaces.Table.copyOnlyHeaders': 'Copiar apenas o cabeçalho',
  'app.pages.Interfaces.Table.copyAllGroupHeaders': 'Copiar com todos os cabeçalhos de grupo',
  'app.pages.Interfaces.Table.drilldownBy': 'Expandir por:',
  'app.pages.Interfaces.Table.drilldownBack': 'Voltar a:',
  'app.pages.Interfaces.Table.drilldownsApplied': 'Drilldowns aplicados:',
  'app.pages.Interfaces.Table.removeDrilldowns': 'Apagar drilldowns',
  'app.pages.Interfaces.Table.drilldownBackOneLevel': 'Voltar um nível',
  'app.pages.Interfaces.SelectWithAutocomplete.noOptions': 'Sem opções',
  'app.pages.Interfaces.SelectWithAutocomplete.loading': 'Carregando...',
  'app.pages.Interfaces.IndexFilterDialog.availableElements': 'Elementos disponíveis',
  'app.pages.Interfaces.IndexFilterDialog.selectedElements': 'Elementos selecionados',
  'app.pages.Interfaces.IndexFilterDialog.filterElements': 'Filtrar elementos',
  'app.pages.Interfaces.IndexFilterDialog.contains': 'Contém',
  'app.pages.Interfaces.IndexFilterDialog.notcontains': 'Não contém',
  'app.pages.Interfaces.IndexFilterDialog.equal': 'Igual a',
  'app.pages.Interfaces.IndexFilterDialog.equal_to_node_values': 'Igual aos valores dos nós',
  'app.pages.Interfaces.IndexFilterDialog.regular_expression': 'Expressão regular',
  'app.pages.Interfaces.IndexFilterDialog.notequal': 'Não igual a',
  'app.pages.Interfaces.IndexFilterDialog.greater_than': 'Maior que',
  'app.pages.Interfaces.IndexFilterDialog.greater_than_equal_to': 'Maior ou igual que',
  'app.pages.Interfaces.IndexFilterDialog.less_than': 'Menor que',
  'app.pages.Interfaces.IndexFilterDialog.less_than_equal_to': 'Menor ou igual que',
  'app.pages.Interfaces.IndexFilterDialog.begin_with': 'Começa com',
  'app.pages.Interfaces.IndexFilterDialog.not_begin_with': 'Não começa com',
  'app.pages.Interfaces.IndexFilterDialog.end_with': 'Termina com',
  'app.pages.Interfaces.IndexFilterDialog.not_end_with': 'Não termina com',
  'app.pages.Interfaces.IndexFilterDialog.earliest': 'Primeiro',
  'app.pages.Interfaces.IndexFilterDialog.latest': 'Último',
  'app.pages.Interfaces.IndexFilterDialog.between': 'Entre',
  'app.pages.Interfaces.IndexFilterDialog.notbetween': 'Não entre',
  'app.pages.Interfaces.IndexFilterDialog.rowsCount': 'Contagem',
  'app.pages.Interfaces.Components.core.Node': 'Nó',
  'app.pages.Interfaces.Components.core.Index': 'Índice',
  'app.pages.Interfaces.Components.core.Selector': 'Seletor',
  'app.pages.Interfaces.Components.core.Chart': 'Gráfico',
  'app.pages.Interfaces.Components.core.PlotlyChart': 'Gráfico plotly',
  'app.pages.Interfaces.Components.core.InputValue': 'Entrada de valor',
  'app.pages.Interfaces.Components.core.Table': 'Tabela',
  'app.pages.Interfaces.Components.core.InputDataArray': 'Entrada de dataarray',
  'app.pages.Interfaces.Components.core.InputDataFrame': 'Entrada de dataframe',
  'app.pages.Interfaces.Components.core.Html': 'Html',
  'app.pages.Interfaces.Components.core.Menu': 'Menu',
  'app.pages.Interfaces.Components.core.Empty': 'Vazio',
  'app.pages.Interfaces.Components.core.Generic': 'Genérico',
  'app.pages.Interfaces.Components.core.Indicator': 'Indicador',
  'app.pages.Interfaces.Components.core.Choice': 'Escolha',
  'app.pages.Interfaces.Components.core.Button': 'Botão',
  'app.pages.Interfaces.Components.core.Button.runButtonDefaultMessage':
    'Voce tem certeza de que deseja executar este botão?',
  'app.pages.Interfaces.Components.core.Form': 'Formulario',
  'app.pages.Interfaces.Components.core.MyTasks': 'Tarefas',
  'app.pages.Interfaces.Components.core.ScheduledTasks': 'Tarefa agendada',
  'app.pages.Interfaces.Components.core.InputData': 'Entrada de dados',
  'app.pages.Interfaces.Components.core.MatplotlibChart': 'Gráfico matplotlib',
  'app.pages.Interfaces.Components.core.Assistant': 'Assistente',
  'app.pages.Interfaces.Components.core.Notifications': 'Notificações',
  'app.pages.Interfaces.Components.core.DynamicHtml': 'Html dinâmico',
  'app.pages.Interfaces.Components.core.Filter': 'Filtro',
  'app.pages.Interfaces.Components.core.Filters': 'Filtros',
  'app.pages.Interfaces.Components.core.ApplyFilters': 'Aplicar filtros',
  'app.pages.Interfaces.Components.core.multiple': 'Múltiplo',
  'app.pages.Interfaces.Components.core.multiSelect': 'Multi-seleção',
  'app.pages.Interfaces.View.InterfaceName': 'Interface {name}',
  'app.pages.Interfaces.View.share': 'Compartilhar',
  'app.pages.Interfaces.View.Empty':
    'A interface está vazia no momento. Entre no modo de edição para adicionar componentes.',
  'app.pages.Interfaces.View.openMenu': 'Abrir menu',
  'app.pages.Interfaces.Edit': 'Editar interface',
  'app.pages.Interfaces.Edit.Menu.componentConfig': 'Configuração do componente',
  'app.pages.Interfaces.Edit.Menu.newComponent': 'Novo componente',
  'app.pages.Interfaces.Edit.Menu.title': 'Título',
  'app.pages.Interfaces.Edit.Menu.chartEvents': 'Eventos',
  'app.pages.Interfaces.Edit.Menu.data': 'Dados',
  'app.pages.Interfaces.Edit.Menu.indexSync': 'Índices sincronizados',
  'app.pages.Interfaces.Edit.Menu.styleEditor': 'Estilos',
  'app.pages.Interfaces.Edit.Menu.icon': 'Ícone',
  'app.pages.Interfaces.Edit.Menu.advancedPivot': 'Pivot avançado',
  'app.pages.Interfaces.Edit.Menu.layoutProperties': 'Esquema',
  'app.pages.Interfaces.Edit.Menu.valueFormat': 'Formato de valores',
  'app.pages.Interfaces.Edit.Menu.valueFormatSecondaryAxis': 'Formato de valores - Eixo secundário',
  'app.pages.Interfaces.Edit.Menu.conditionalFormat': 'Formato condicional',
  'app.pages.Interfaces.Edit.Menu.cellProperties': 'Propriedades de células',
  'app.pages.Interfaces.Edit.Menu.progressBar': 'Barra de progresso',
  'app.pages.Interfaces.Edit.Menu.heatmap': 'Mapa de calor',
  'app.pages.Interfaces.Edit.Menu.componentStyle': 'Componente',
  'app.pages.Interfaces.Edit.Menu.general': 'Geral',
  'app.pages.Interfaces.Edit.Menu.scenarios': 'Cenários',
  'app.pages.Interfaces.Edit.Menu.columnsToShow': 'Colunas para mostrar',
  'app.pages.Interfaces.Edit.Menu.confirmationDialog': 'Caixa de diálogo de confirmação',
  'app.pages.Interfaces.Edit.Menu.showConfirmationDialog': 'Mostrar caixa de diálogo',
  'app.pages.Interfaces.Edit.Menu.confirmationDialogMessage': 'Mensagem',
  'app.pages.Interfaces.Edit.Menu.formProperties': 'Propriedades de formulário',
  'app.pages.Interfaces.Edit.Menu.validations': 'Validações',
  'app.pages.Interfaces.Edit.Menu.inputCubeProperties': 'Propriedades de cubo',
  'app.pages.Interfaces.Edit.selectNode': 'Selecione um nó para criar um componente de tipo',
  'app.pages.Interfaces.Edit.nodeType': 'Tipo de nó',
  'app.pages.Interfaces.Edit.selectedNodeId': 'ID do nó selecionado',
  'app.pages.Interfaces.Edit.refresh': 'Refrescar interface',
  'app.pages.Interfaces.Edit.copy': 'Copiar interface',
  'app.pages.Interfaces.Edit.hidePanel': 'Esconder painel',
  'app.pages.Interfaces.Edit.showPanel': 'Mostrar painel',
  'app.pages.Interfaces.Edit.removeComponent': 'Excluir componente',
  'app.pages.Interfaces.Edit.remove': 'Excluir',
  'app.pages.Interfaces.Edit.removeConfirmationText':
    'Tem certeza que deseja excluir este componente?',
  'app.pages.Interfaces.Edit.discardUnsavedChanges': 'Descartar alterações não salvas?',
  'app.pages.Interfaces.Edit.discardUnsavedChangesText':
    'Tem certeza que deseja descartar as alterações não salvas?',
  'app.pages.Interfaces.Edit.showInterfaceCode': 'Mostrar código da interface',
  'app.pages.Interfaces.Edit.interfaceCodeEditor': 'Editor de código da interface',
  'app.pages.Interfaces.Edit.closeComponentCodeEditor': 'Fechar editor de código do componente',
  'app.pages.Interfaces.Edit.customComponentCode': 'Código personalizado do componente',
  'app.pages.Interfaces.Edit.HTMLEditor': 'Editor de HTML',
  'app.pages.Interfaces.Edit.menuEditor': 'Editor do menu',
  'app.pages.Interfaces.Edit.title.enabled': 'Mostrar',
  'app.pages.Interfaces.Edit.title.editTitle': 'Editar título',
  'app.pages.Interfaces.Edit.title.customTitle': 'Título personalizado',
  'app.pages.Interfaces.Edit.table.size.rowsPerPage': 'Linhas por página',
  'app.pages.Interfaces.Edit.table.size.columnsPerPage': 'Colunas por página',
  'app.pages.Interfaces.Edit.table.data.showRowTotals': 'Mostrar totais de linhas',
  'app.pages.Interfaces.Edit.table.data.rowTotalsPosition': 'Posição de totais de linhas',
  'app.pages.Interfaces.Edit.table.data.showColumnTotals': 'Mostrar totais de colunas',
  'app.pages.Interfaces.Edit.table.data.columnTotalsPosition': 'Posição de totais de colunas',
  'app.pages.Interfaces.Edit.table.data.compareScenarios': 'Comparar cenários',
  'app.pages.Interfaces.Edit.table.data.disableCompareScenarios': 'Desabilitar comparar cenários',
  'app.pages.Interfaces.Edit.table.data.showValues': 'Mostrar valores',
  'app.pages.Interfaces.Edit.table.data.showDifference': 'Mostrar diferença',
  'app.pages.Interfaces.Edit.table.data.showPercentageDifference': 'Mostrar diferença percentual',
  'app.pages.Interfaces.Edit.table.data.differenceEndText': 'Texto final de diferença',
  'app.pages.Interfaces.Edit.table.data.percentageDifferenceEndText':
    'Texto final de diferença percentual',
  'app.pages.Interfaces.Edit.table.data.columnsProperties': 'Propriedades de colunas',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData': 'Esconder dados vazios',
  'app.pages.Interfaces.Edit.table.data.totalsPositions.end': 'Fim',
  'app.pages.Interfaces.Edit.table.data.totalsPositions.beginning': 'Começo',
  'app.pages.Interfaces.Edit.table.data.showColumnFilterIcon': 'Mostrar ícone de filtro de coluna',
  'app.pages.Interfaces.Edit.table.data.hideFooter': 'Ocultar rodapé',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.column': 'Coluna',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.row': 'Linha',
  'app.pages.Interfaces.Edit.table.data.hideEmptyData.both': 'Ambas',
  'app.pages.Interfaces.Edit.table.data.pagination': 'Paginação',
  'app.pages.Interfaces.Edit.events.selectedEvent': 'Evento de seleção',
  'app.pages.Interfaces.Edit.events.hoverEvent': 'Evento hover',
  'app.pages.Interfaces.Edit.events.drilldownEvent': 'Evento drilldown',
  'app.pages.Interfaces.Edit.pivot': 'Pivot',
  'app.pages.Interfaces.Edit.filter': 'Filtro',
  'app.pages.Interfaces.Edit.dataHandling': 'Manipulação de dados',
  'app.pages.Interfaces.Edit.appliedFiltersOn': 'Filtros aplicados em',
  'app.pages.Interfaces.Edit.style.fontSize': 'Tamanho da fonte',
  'app.pages.Interfaces.Edit.style.fontStyle': 'Estilo da fonte',
  'app.pages.Interfaces.Edit.style.fontStyle.bold': 'Negrito',
  'app.pages.Interfaces.Edit.style.fontStyle.italic': 'Itálico',
  'app.pages.Interfaces.Edit.style.fontStyle.underline': 'Sublinhado',
  'app.pages.Interfaces.Edit.style.fontColor': 'Cor da fonte',
  'app.pages.Interfaces.Edit.style.backgroundColor': 'Cor de fundo',
  'app.pages.Interfaces.Edit.style.headerBackgroundColor': 'Cor de fundo do cabeçalho',
  'app.pages.Interfaces.Edit.style.headerColor': 'Cor do cabeçalho',
  'app.pages.Interfaces.Edit.style.textAlign': 'Alinhamento do texto',
  'app.pages.Interfaces.Edit.style.textAlign.left': 'Esquerda',
  'app.pages.Interfaces.Edit.style.textAlign.center': 'Centralizado',
  'app.pages.Interfaces.Edit.style.textAlign.right': 'Direita',
  'app.pages.Interfaces.Edit.style.textAlign.justify': 'Justificado',
  'app.pages.Interfaces.Edit.style.hideHeader': 'Ocultar header',
  'app.pages.Interfaces.Edit.style.hideBorder': 'Ocultar borda',
  'app.pages.Interfaces.Edit.style.hideMaximizeButton': 'Ocultar botão de maximizar',
  'app.pages.Interfaces.Edit.style.hideDataHandlingIcon': 'Ocultar ícone de manipulação de dados',
  'app.pages.Interfaces.Edit.style.note': '(aplica no modo de visualização)',
  'app.pages.Interfaces.Edit.style.setDefaultColor': 'Definir color padrão',
  'app.pages.Interfaces.Edit.style.applyAndClose': 'Aplicar y fechar',
  'app.pages.Interfaces.Edit.style.seriesColors': 'Cor das séries',
  'app.pages.Interfaces.Edit.style.colorPalette.increasing': 'Aumento',
  'app.pages.Interfaces.Edit.style.colorPalette.decreasing': 'Diminuição',
  'app.pages.Interfaces.Edit.layout.style.axesTitles': 'Títulos de eixos',
  'app.pages.Interfaces.Edit.layout.style.showYAxisTitle': 'Mostrar título de eixo Y',
  'app.pages.Interfaces.Edit.layout.style.showXAxisTitle': 'Mostrar título de eixo X',
  'app.pages.Interfaces.Edit.layout.color_palette': 'Paleta de cores',
  'app.pages.Interfaces.Edit.layout.color_palette.color_continuous_scale': 'Escala contínua',
  'app.pages.Interfaces.Edit.layout.color_palette.color_discrete_sequence': 'Sequência discreta',
  'app.pages.Interfaces.Edit.layout.legend': 'Lenda',
  'app.pages.Interfaces.Edit.layout.legend.showlegend': 'Mostrar lenda',
  'app.pages.Interfaces.Edit.layout.legend.toggleOthersWithSingleClick':
    'Alternar outros com um único clique',
  'app.pages.Interfaces.Edit.layout.legend.coloraxis_showscale': 'Mostrar escala',
  'app.pages.Interfaces.Edit.layout.legend.orientation': 'Orientação',
  'app.pages.Interfaces.Edit.layout.legend.xanchor': 'Ponto de ancoragem X',
  'app.pages.Interfaces.Edit.layout.legend.x': 'X',
  'app.pages.Interfaces.Edit.layout.legend.yanchor': 'Ponto de ancoragem Y',
  'app.pages.Interfaces.Edit.layout.legend.y': 'Y',
  'app.pages.Interfaces.Edit.layout.margins': 'Margens',
  'app.pages.Interfaces.Edit.layout.selectValues.auto': 'Auto',
  'app.pages.Interfaces.Edit.layout.selectValues.left': 'Esquerda',
  'app.pages.Interfaces.Edit.layout.selectValues.center': 'Centro',
  'app.pages.Interfaces.Edit.layout.selectValues.right': 'Direita',
  'app.pages.Interfaces.Edit.layout.selectValues.top': 'Superior',
  'app.pages.Interfaces.Edit.layout.selectValues.middle': 'Meio',
  'app.pages.Interfaces.Edit.layout.selectValues.bottom': 'Inferior',
  'app.pages.Interfaces.Edit.layout.chartMode': 'Variante de gráfico',
  'app.pages.Interfaces.Edit.layout.chartMode.modePreset': 'Variante predefinida',
  'app.pages.Interfaces.Edit.layout.chartMode.stack': 'Empilhado',
  'app.pages.Interfaces.Edit.layout.chartMode.relative': 'Relativo',
  'app.pages.Interfaces.Edit.layout.chartMode.group': 'Agrupado',
  'app.pages.Interfaces.Edit.layout.chartMode.overlay': 'Sobreposição',
  'app.pages.Interfaces.Edit.layout.chartMode.barmode': 'Variante de barra',
  'app.pages.Interfaces.Edit.layout.chartMode.boxmode': 'Variante de caixa',
  'app.pages.Interfaces.Edit.layout.chartMode.funnelmode': 'Variante de funil',
  'app.pages.Interfaces.Edit.layout.chartMode.violinmode': 'Variante de violino',
  'app.pages.Interfaces.Edit.layout.chartMode.waterfallmode': 'Variante de cachoeira',
  'app.pages.Interfaces.Edit.layout.chartMode.percent': 'Percentagem',
  'app.pages.Interfaces.Edit.layout.chartNorm': 'Normalização de gráfico',
  'app.pages.Interfaces.Edit.layout.chartNorm.barnorm': 'Normalización de barra',
  'app.pages.Interfaces.Edit.layout.chartNorm.groupnorm': 'Normalización de grupo',
  'app.pages.Interfaces.Edit.layout.chartNorm.fraction': 'Fração',
  'app.pages.Interfaces.Edit.layout.chartNorm.percent': 'Porcentagem',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels': 'Rótulos',
  'app.pages.Interfaces.Edit.layout.chartNorm.labelsSecondaryAxis': 'Rótulos - Eixo secundário',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.showLabels': 'Mostrar rótulos',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.labelField': 'Campo',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.minSize': 'Tamanho mínimo',
  'app.pages.Interfaces.Edit.layout.chartNorm.labels.mode': 'Modo',
  'app.pages.Interfaces.Edit.layout.customSeriesColors': 'Cores de series personalizados',
  'app.pages.Interfaces.Edit.layout.customName': 'Nome personalizado',
  'app.pages.Interfaces.Edit.layout.addValue': 'Inserir valor',
  'app.pages.Interfaces.Edit.layout.addColor': 'Inserir cor',
  'app.pages.Interfaces.Edit.layout.generateValuesFromColumn': 'Gerar valores desde coluna',
  'app.pages.Interfaces.Edit.layout.y_axis_range': 'Intervalo do eixo Y',
  'app.pages.Interfaces.Edit.valueFormat.formatPreset': 'Formato predefinido',
  'app.pages.Interfaces.Edit.valueFormat.number': 'Número',
  'app.pages.Interfaces.Edit.valueFormat.decimal': 'Decimal',
  'app.pages.Interfaces.Edit.valueFormat.integer': 'Inteiro',
  'app.pages.Interfaces.Edit.valueFormat.percent': 'Porcentagem',
  'app.pages.Interfaces.Edit.valueFormat.scientific': 'Científico',
  'app.pages.Interfaces.Edit.valueFormat.currency': 'Moeda',
  'app.pages.Interfaces.Edit.valueFormat.currencyRounded': 'Moeda (arredondada)',
  'app.pages.Interfaces.Edit.valueFormat.date': 'Data',
  'app.pages.Interfaces.Edit.valueFormat.datetime': 'Data hora',
  'app.pages.Interfaces.Edit.valueFormat.thousandsSeparator': 'Separador de milhares',
  'app.pages.Interfaces.Edit.valueFormat.decimalPlaces': 'Decimais',
  'app.pages.Interfaces.Edit.valueFormat.prefix': 'Prefixo',
  'app.pages.Interfaces.Edit.valueFormat.suffix': 'Sufixo',
  'app.pages.Interfaces.Edit.valueFormat.dateFormat': 'Formato de data',
  'app.pages.Interfaces.Edit.valueFormat.datetimeFormat': 'Formato de data hora',
  'app.pages.Interfaces.Edit.valueFormat.seePatternReference': 'Ver referência de padrão',
  'app.pages.Interfaces.Run.runningButton': 'Executando Botão',
  'app.pages.Interfaces.Edit.editTitle.showUnits': 'Mostrar unidades',
  'app.pages.Interfaces.Edit.progressBar.applyProgressBar': 'Aplicar barra de progresso',
  'app.pages.Interfaces.Edit.progressBar.max_value': 'Valor máximo',
  'app.pages.Interfaces.Edit.progressBar.min_hue': 'Matiz mínima',
  'app.pages.Interfaces.Edit.progressBar.max_hue': 'Matiz máxima',
  'app.pages.Interfaces.Edit.progressBar.saturation': 'Saturação',
  'app.pages.Interfaces.Edit.progressBar.lightness': 'Clareza',
  'app.pages.Interfaces.Edit.progressBar.show_value': 'Mostrar valor',
  'app.pages.Interfaces.Edit.heatmap.applyHeatmap': 'Aplicar mapa de calor',
  'app.pages.Interfaces.Edit.heatmap.by': 'Por',
  'app.pages.Interfaces.Edit.heatmap.hex_base_background_color': 'Cor de fundo base',
  'app.pages.Interfaces.Edit.heatmap.hex_text_color': 'Cor de texto',
  'app.pages.Interfaces.Edit.heatmap.exclude_nans': 'Excluir NaNs',
  'app.pages.Interfaces.Edit.heatmap.exclude_right_total': 'Excluir totais de linhas',
  'app.pages.Interfaces.Edit.heatmap.exclude_bottom_total': 'Excluir totais de colunas',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor': 'Cor de mapa de calor',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_green_alt': 'vermelho-verde-alt',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_red_alt': 'verde-vermelho-alt',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_green': 'vermelho-verde',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_red': 'verde-vermelho',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_white': 'vermelho-branco',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.white_red': 'branco-vermelho',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.white_green': 'branco-verde',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_white': 'verde-branco',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.green_blue': 'verde-azul',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.blue_green': 'azul-verde',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.red_blue': 'vermelho-azul',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.blue_red': 'azul-vermelho',
  'app.pages.Interfaces.Edit.heatmap.heatmapColor.custom': 'Personalizado',
  'app.pages.Interfaces.Edit.conditionalFormat.addCondition': 'Adicionar condição',
  'app.pages.Interfaces.Edit.conditionalFormat.deleteAll': 'Apagar todas',
  'app.pages.Interfaces.Edit.conditionalFormat.Conditions': 'Condições',
  'app.pages.Interfaces.Edit.conditionalFormat.showIconOnly': 'Mostrar só o ícone',
  'app.pages.Interfaces.Edit.style.addNewStyle': 'Adicionar estilo novo',
  'app.pages.Interfaces.Edit.style.deleteStyle': 'Apagar estilo',
  'app.pages.Interfaces.Edit.style.editStyle': 'Editar estilo',
  'app.pages.Interfaces.Edit.style.dimensionName': 'Nome de dimensão',
  'app.pages.Interfaces.Edit.style.filterType': 'Tipo de filtro',
  'app.pages.Code.Diagram.Wizards.styleConditions': 'Condições do estilo',
  'app.pages.Code.Diagram.Wizards.filterIndexes': 'Filtrar índices',
  'app.pages.Interfaces.Edit.colorPicker.themeColors': 'Cores do tema',
  'app.pages.Interfaces.Edit.colorPicker.standardColors': 'Cores padrão',
  'app.pages.Interfaces.Edit.colorPicker.recentColors': 'Cores recentes',
  'app.pages.Interfaces.Edit.colorPicker.selectedColor': 'Cor selecionada',
  'app.pages.Interfaces.Edit.leaveInterfaceTitle': 'Interface com alterações não confirmadas',
  'app.pages.Interfaces.Edit.leaveInterfaceContent':
    'Deseja confirmar as alterações ou descartá-las?',
  'app.pages.Interfaces.Edit.inputNodeProperties.hideIdColumn': 'Ocultar coluna com ID',
  'app.pages.Interfaces.Edit.inputNodeProperties.confirmOnChange':
    'Confirmar ao fazer uma alteração',
  'app.pages.Interfaces.Edit.inputNodeProperties.invalidateOutputsOnChange':
    'Invalidar saídas ao fazer uma alteração',
  'app.pages.Interfaces.Edit.inputNodeProperties.invalidateOutputsOnConfirm':
    'Invalidar saídas ao confirmar',
  'app.pages.Interfaces.Edit.inputNodeProperties.updateCalcColumnsOnChange':
    'Atualizar colunas calculadas ao fazer uma alteração',
  'app.pages.Interfaces.Edit.inputNodeProperties.updateCalcColumnsOnConfirm':
    'Atualizar colunas calculadas ao confirmar',
  'app.pages.Interfaces.Edit.inputNodeProperties.showSelectColumnsIcon':
    'Mostrar ícone de Selecionar colunas',
  'app.pages.Interfaces.Edit.inputNodeProperties.showEditExcelIcon':
    'Mostrar ícone de Editar no Excel',
  'app.pages.Interfaces.Edit.filterIndexValues': 'Filtrar valores de índice',
  'app.pages.Interfaces.Edit.filterIndexValues.fromToError':
    'O valor "Desde" deve ser menor ou igual ao valor "Até".',
  'app.pages.Interfaces.Edit.style.applyConditionTo': 'Aplicar condição a',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customView': 'Visualização personalizada',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.editingCustomView':
    'Você está editando a visualização personalizada desta interface',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.showDefaultView':
    'Mostrar visualização padrão',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.showCustomView':
    'Mostrar visualização personalizada',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.saveCustomView':
    'Salvar visualização personalizada',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.deleteCustomView':
    'Excluir visualização personalizada',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customViewSaved':
    'A visualização personalizada da interface foi salva com sucesso',
  'app.pages.Interfaces.Edit.interfaceCustomViewMenu.customViewDeleted':
    'A visualização personalizada da interface foi excluída com sucesso',

  //Instance manager
  'app.pages.app.instanceManager.noInstances': 'Não há instâncias',
  'app.pages.app.instanceManager.open': 'Aberto',
  'app.pages.app.instanceManager.currentApplication': 'Aplicativo atual',
  'app.pages.app.instanceManager.isPool': 'É pool',
  'app.pages.app.instanceManager.active': 'Ativo',
  'app.pages.app.instanceManager.timeoutIn': 'Tempo de espera restante',
  'app.pages.app.instanceManager.minutes': ' min',
  'app.pages.app.instanceManager.cpu': 'CPU',
  'app.pages.app.instanceManager.memory': 'Memória',
  'app.pages.app.instanceManager.peak': 'Pico',
  'app.pages.app.instanceManager.apiResources': 'Recursos do aplicativo',
  'app.pages.app.instanceManager.systemMemory': 'Total',
  'app.pages.app.instanceManager.inUse': 'Em uso',
  'app.pages.app.instanceManager.disk': 'Disco',
  'app.pages.app.instanceManager.time': 'Tempo',
  'app.pages.app.instanceManager.selectedInstance': 'Instância selecionada',
  'app.pages.app.instanceManager.pid': 'PID',
  'app.pages.app.instanceManager.memoryPeak': 'Pico de memória',
  'app.pages.app.instanceManager.lastProcessedNode': 'Último nodo processado',
  'app.pages.app.instanceManager.requireLogoutTitle': 'Requerer logout',
  'app.pages.app.instanceManager.requireLogoutMessage':
    'Você tem certeza de enviar uma solicitação para Fechar instância?',
  'app.pages.app.instanceManager.killInstanceTitle': 'Fechar instância',
  'app.pages.app.instanceManager.killInstanceMessage':
    'Toda informação desta instância será apagada. Você está certo?',
  'app.pages.app.instanceManager.requestSent': 'Petição enviada',
  'app.pages.app.instanceManager.instanceClosed': 'Instância fechada',
  'app.pages.app.instanceManager.noSelectedInstance': 'Não há instância selecionada',

  //Log manager
  'app.pages.managers.logs': 'Registros',
  'app.pages.managers.logManager.startDate': 'Data de início',
  'app.pages.managers.logManager.endDate': 'Data final',
  'app.pages.managers.logManager.instanceKey': 'Instância',
  'app.pages.managers.logManager.user': 'Usuário',
  'app.pages.managers.logManager.user.notFound': 'Usuário não encontrado',
  'app.pages.managers.logManager.user.selectUser': 'Selecione ou procure um usuário',
  'app.pages.managers.logManager.action': 'Açao',
  'app.pages.managers.logManager.action.notFound': 'Açao não encontrada',
  'app.pages.managers.logManager.action.selectAction': 'Selecione ou procure uma açao',
  'app.pages.managers.logManager.model': 'Modelo',
  'app.pages.managers.logManager.downloadReport': 'Baixar relatório',
  'app.pages.managers.logManager.reportDownloaded': 'Relatório baixado',
  'app.pages.managers.logManager.clearFilters': 'Deletar filtros',
  'app.pages.managers.logManager.filter': 'Filtro',
  'app.pages.managers.logManager.dateFilterHelperText': 'Pode filtrar até {name} días',
  'app.pages.managers.logManager.table.date': 'Data',
  'app.pages.managers.logManager.table.query': 'Consulta',
  'app.pages.managers.logManager.table.body': 'Corpo',

  //Interface toolbar
  'app.pages.interfaces.toolbar.cancel': 'Cancelar',
  'app.pages.interfaces.toolbar.mode': 'Modo',
  'app.pages.interfaces.toolbar.mode.single': 'Seleção simples',
  'app.pages.interfaces.toolbar.mode.multi': 'Seleção múltipla',
  'app.pages.interfaces.toolbar.format': 'Formato',
  'app.pages.interfaces.toolbar.menu.alignment': 'Alinhamento',
  'app.pages.interfaces.toolbar.format.small': 'Pequeno',
  'app.pages.interfaces.toolbar.format.hidden': 'Oculto',
  'app.pages.interfaces.toolbar.format.range': 'Range',
  'app.pages.interfaces.toolbar.format.select': 'Seleção',
  'app.pages.interfaces.toolbar.format.radio': 'Rádio',
  'app.pages.interfaces.toolbar.format.slider': 'Controle deslizante',
  'app.pages.interfaces.toolbar.format.selected_chips': 'Fichas escolhidas',
  'app.pages.interfaces.toolbar.format.clickable_chips': 'Fichas clicáveis',
  'app.pages.interfaces.toolbar.orientation': 'Orientação',
  'app.pages.interfaces.toolbar.orientation.horizontal': 'Horizontal',
  'app.pages.interfaces.toolbar.orientation.vertical': 'Vertical',
  'app.pages.interfaces.toolbar.chartType': 'Tipo de gráfico',
  'app.pages.interfaces.toolbar.chartSubtype': 'Subtipo de gráfico',
  'app.pages.interfaces.toolbar.chartType.bar': 'Barra',
  'app.pages.interfaces.toolbar.chartType.column': 'Coluna',
  'app.pages.interfaces.toolbar.chartType.normal': 'Normal',
  'app.pages.interfaces.toolbar.chartType.stacked': 'Empilhado',
  'app.pages.interfaces.toolbar.chartType.percentStacked': 'Percentagem empilhado',
  'app.pages.interfaces.toolbar.chartType.line': 'Linha',
  'app.pages.interfaces.toolbar.chartType.linesOnly': 'Só linhas',
  'app.pages.interfaces.toolbar.chartType.linesAndPoints': 'Linhas e pontos',
  'app.pages.interfaces.toolbar.chartType.pointsOnly': 'Só pontos',
  'app.pages.interfaces.toolbar.chartType.pie': 'Torta',
  'app.pages.interfaces.toolbar.chartType.donut': 'Rosquinha',
  'app.pages.interfaces.toolbar.chartType.sunburst': 'Projeção solar',
  'app.pages.interfaces.toolbar.chartType.area': 'Área',
  'app.pages.interfaces.toolbar.chartType.percent': 'Percentagem',
  'app.pages.interfaces.toolbar.chartType.scatter': 'Dispersão',
  'app.pages.interfaces.toolbar.chartType.combined': 'Combinado',
  'app.pages.interfaces.toolbar.chartType.scatterWithTrendline': 'Dispersão com linha de tendência',
  'app.pages.interfaces.toolbar.chartType.others': 'Outros',
  'app.pages.interfaces.toolbar.chartType.waterfall': 'Cascata',
  'app.pages.interfaces.toolbar.chartType.funnel': 'Funil',
  'app.pages.interfaces.toolbar.chartType.timeline': 'Linha de tempo',
  'app.pages.interfaces.toolbar.chartType.treemap': 'Mapa de árvore',
  'app.pages.interfaces.toolbar.chartType.icicle': 'Gelo',
  'app.pages.interfaces.toolbar.chartType.funnelArea': 'Área de funil',
  'app.pages.interfaces.toolbar.chartType.histogram': 'Histograma',
  'app.pages.interfaces.toolbar.chartType.box': 'Caixa',
  'app.pages.interfaces.toolbar.chartType.violin': 'Violino',
  'app.pages.interfaces.toolbar.chartType.strip': 'Faixa',
  'app.pages.interfaces.toolbar.chartType.densityHeatmap': 'Mapa de calor de densidade',
  'app.pages.interfaces.toolbar.chartType.densityContour': 'Contorno de densidade',
  'app.pages.interfaces.toolbar.chartType.scatter3d': 'Dispersão 3D',
  'app.pages.interfaces.toolbar.chartType.line3d': 'Linha 3D',
  'app.pages.interfaces.toolbar.chartType.scatterMatrix': 'Matriz de dispersão',
  'app.pages.interfaces.toolbar.chartType.parallelCoordinates': 'Coordenadas paralelas',
  'app.pages.interfaces.toolbar.chartType.parallelCategories': 'Categorias paralelas',
  'app.pages.interfaces.toolbar.chartType.scatterMapbox': 'Mapa de dispersão',
  'app.pages.interfaces.toolbar.chartType.lineMapbox': 'Mapa de linha',
  'app.pages.interfaces.toolbar.chartType.choroplethMapbox': 'Mapa de coropleto',
  'app.pages.interfaces.toolbar.chartType.densityMapbox': 'Mapa de densidade',
  'app.pages.interfaces.toolbar.chartType.scatterPolar': 'Dispersão polar',
  'app.pages.interfaces.toolbar.chartType.linePolar': 'Linha polar',
  'app.pages.interfaces.toolbar.chartType.barPolar': 'Barra polar',
  'app.pages.interfaces.toolbar.chartType.scatterTernary': 'Dispersão ternária',
  'app.pages.interfaces.toolbar.chartType.lineTernary': 'Linha ternária',
  'app.pages.interfaces.toolbar.chartType.groups.basics': 'Básicos',
  'app.pages.interfaces.toolbar.chartType.groups.financial': 'Financeiro',
  'app.pages.interfaces.toolbar.chartType.groups.partOfWhole': 'Parte-de-todo',
  'app.pages.interfaces.toolbar.chartType.groups.1dDistributions': 'Distribuições 1D',
  'app.pages.interfaces.toolbar.chartType.groups.2dDistributions': 'Distribuições 2D',
  'app.pages.interfaces.toolbar.chartType.groups.3dimensional': '3-Dimensional',
  'app.pages.interfaces.toolbar.chartType.groups.multidimensional': 'Multidimensional',
  'app.pages.interfaces.toolbar.chartType.groups.tileMaps': 'Mapas de ladrilhos',
  'app.pages.interfaces.toolbar.chartType.groups.polarCharts': 'Gráficos polar',
  'app.pages.interfaces.toolbar.chartType.groups.ternaryCharts': 'Gráficos ternários',
  'app.pages.interfaces.toolbar.showComponentCode': 'Mostrar código do componente',
  'app.pages.interfaces.toolbar.openComponentCode': 'Abrir código do componente',
  'app.pages.interfaces.toolbar.componentCodeEditor': 'Editor de código do componente',
  'app.pages.interfaces.toolbar.menuFormat': 'Formato do menú',
  'app.pages.interfaces.toolbar.menuFormat.accordion': 'Acordeão',
  'app.pages.interfaces.toolbar.menuFormat.boxes': 'Caixas',
  'app.pages.interfaces.toolbar.menuFormat.mini': 'Mini',
  'app.pages.interfaces.toolbar.menu.newItem': 'Novo item',
  'app.pages.interfaces.toolbar.menu.newAction': 'Nova ação',
  'app.pages.interfaces.toolbar.menu.addItem': 'Adicionar item do menu',
  'app.pages.interfaces.toolbar.menu.addAction': 'Adicionar ação',
  'app.pages.interfaces.toolbar.menu.removeItem': 'Remover item',
  'app.pages.interfaces.toolbar.menu.removeAction': 'Remover ação',
  'app.pages.interfaces.toolbar.menu.root': 'Raiz',
  'app.pages.interfaces.toolbar.menu.actionType': 'Tipo de ação',
  'app.pages.interfaces.toolbar.menu.navigateTo': 'Navegue para',
  'app.pages.interfaces.toolbar.menu.target': 'Target',
  'app.pages.interfaces.toolbar.menu.currentTab': 'Aba atual',
  'app.pages.interfaces.toolbar.menu.newTab': 'Nova aba',
  'app.pages.interfaces.toolbar.menu.expanded': 'Expandido',
  'app.pages.interfaces.toolbar.menu.subtitle': 'Legenda',
  'app.pages.interfaces.toolbar.menu.openInterface': 'Abrir interface',
  'app.pages.interfaces.toolbar.menu.navigate': 'Navegação',
  'app.pages.interfaces.toolbar.menu.textColor': 'Cor do texto',
  'app.pages.interfaces.toolbar.menu.iconColor': 'cor do ícone',
  'app.pages.interfaces.toolbar.menu.backgroundColor': 'Cor de fundo',
  'app.pages.interfaces.toolbar.menu.hoverColor': 'Cor de hover',
  'app.pages.interfaces.componentHasCustomCodeMessage':
    'O componente tem código personalizado. Trocar para definição padrão para habilitar toolbar de propriedades.',
  'app.pages.interfaces.toolbar.openHtmlEditor': 'Abrir editor HTML',
  'app.pages.interfaces.toolbar.openDynamicHtmlEditor': 'Abrir editor HTML dinâmico',
  'app.pages.interfaces.toolbar.dynamicHtmlCustomization': 'Personalização HTML dinâmica',
  'app.pages.interfaces.toolbar.selectSectionToGo': 'Selecione a seção para ir',
  'app.pages.interfaces.toolbar.selectRunAppNode': 'Selecione o nó do aplicativo para executar',
  'app.pages.interfaces.toolbar.openInNewTab': 'Abrir em nova aba',
  'app.pages.interfaces.toolbar.openMenuEditor': 'Abrir editor do menu',
  'app.pages.interfaces.toolbar.autoOpenSingleAction':
    'Abrir automaticamente a única ação disponível',
  'app.pages.interfaces.toolbar.exportComponent': 'Exportar componente',
  'app.pages.interfaces.toolbar.fileFormat': 'Formato de arquivo',
  'app.pages.interfaces.toolbar.numberFormat': 'Formato numérico',
  'app.pages.interfaces.toolbar.columnSeparator': 'Separador de coluna',
  'app.pages.interfaces.toolbar.compressToDownload': 'Compactar arquivo para baixar',
  'app.pages.interfaces.toolbar.excludeNullAndZero': 'Excluir valores zero e nulos',
  'app.pages.interfaces.toolbar.fullNode': 'Nó completo',
  'app.pages.interfaces.toolbar.currentView': 'Visão atual',
  'app.pages.interfaces.toolbar.multiline': 'Multilinha',
  'app.pages.interfaces.toolbar.uppercase': 'Maiúsculas',
  'app.pages.interfaces.toolbar.fixedPositionOnTop': 'Posição fixa no topo',
  'app.pages.interfaces.toolbar.openFilterEditor': 'Abrir editor de filtro',
  'app.pages.app.instanceManager.editionAvailable': 'Edição disponível',
  'app.pages.app.instanceManager.processing': 'Processamento',
  'app.pages.app.instanceManager.info': 'Info',
  'app.pages.app.instanceManager.openAppsOnly': 'Só aplicativos abertos',
  'app.pages.app.instanceManager.scheduledTask': 'Tarefa agendada',
  'app.pages.interfaces.chart': 'Gráfico',
  'app.pages.interfaces.indicator': 'Indicador',
  'app.pages.interfaces.interfaceDeleted': 'Interface removida',
  'app.pages.interfaces.folderDeleted': 'Pasta removida',
  'app.pages.interfaces.interfaceDuplicated': 'Interface duplicada',
  'app.pages.interfaces.folderDuplicated': 'Pasta duplicada',
  'app.pages.interfaces.viewAsChart': 'Ver como gráfico',
  'app.pages.interfaces.viewAsTable': 'Ver como tabela',
  'app.pages.interfaces.defaultInterface': 'Interface padrão',
  'app.pages.interfaces.closeAllTabs': 'Fechar todas as abas',
  'app.pages.interfaces.closeSelectedTab': 'Fechar a guia selecionada',
  'app.pages.interfaces.openTabActions': 'Abrir ações de guia',
  'app.pages.interfaces.openDefaultInterface': 'Abrir interface padrão',
  'app.pages.interfaces.closeAllButThis': 'Fechar abas, exceto a selecionada',
  'app.pages.interfaces.importInterface': 'Importar interface',
  'app.pages.interfaces.interfaceImportedSuccessfully': 'Interface importada com sucesso',
  'app.pages.interfaces.exportInterfaceSuccess': 'Interface exportada com sucesso',
  'app.pages.interfaces.interfaceCopied': 'Interface copiada com sucesso',
  'app.pages.interfaces.interfaceMoved': 'Interface movida com sucesso',
  'app.pages.interfaces.deleteFolder': 'Excluir pasta',
  'app.pages.interfaces.deleteInterfaces': 'Excluir interfaces',
  'app.pages.interfaces.folderCopied': 'Pasta copiada com sucesso',
  'app.pages.interfaces.folderMoved': 'Pasta movida com sucesso',
  'app.pages.interfaces.interfacePermissionChanged': 'Permissões de interface alteradas',
  'app.pages.interfaces.htmlPreview': 'Pré-visualização HTML',
  'app.pages.interfaces.attribute': 'Atributo',
  'app.pages.interfaces.attributeValue': 'Valor do atributo',
  'app.pages.interfaces.typeActionValue': 'Valor de tipo de ação',
  'app.pages.interfaces.typeActionValue.modifyTypeActionValue': 'Modificar valor de tipo de ação',
  'app.pages.interfaces.runAppNode': 'Executar nó do aplicativo',
  'app.pages.interfaces.attributesFromNode': 'Atributos do nó',
  'app.pages.interfaces.childrenFromNode': 'Filho do nó',
  'app.pages.interfaces.addNodeResultLikeChildrenElement':
    'Adicionar resultado do nó como elemento filho',
  'app.pages.interfaces.getAttributesFromNode': 'Obter atributos do nó',
  'app.pages.interfaces.targetAttribute': 'Atributo de destino',
  'app.pages.interfaces.targetAttributeValue': 'Valor do atributo de destino',
  'app.pages.interfaces.toolbar.runNodeToPreviewResult':
    'Executar nó para pré-visualizar resultado',
  'app.pages.interfaces.toolbar.insertHereYourHtmlCode': 'Insira aqui seu código HTML',
  'app.pages.interfaces.toolbar.previewAvailableAfterTypingCode':
    'Pré-visualização disponível após digitar o código',
  'app.pages.interfaces.toolbar.runPreviewHtml': 'Executar pré-visualização HTML',
  'app.pages.interfaces.toolbar.inputValue.dataType': 'Tipo de dado',
  'app.pages.interfaces.toolbar.inputValue.rule': 'Regra',
  'app.pages.interfaces.toolbar.inputValue.rule.notRange': 'Fora de alcance',
  'app.pages.interfaces.toolbar.inputValue.rule.textLength': 'Comprimento do texto',
  'app.pages.interfaces.toolbar.inputValue.rule.any': 'Qualquer',
  'app.pages.interfaces.toolbar.dynamicHtml.openAssistant': 'Abrir asistente',
  'app.pages.interfaces.toolbar.dynamicHtml.inserAssistantId': 'Insira o ID do assistente',

  // Interface pivot
  'app.pages.interfaces.pivot.x': 'X',
  'app.pages.interfaces.pivot.y': 'Y',
  'app.pages.interfaces.pivot.z': 'Z',
  'app.pages.interfaces.pivot.facet_row': 'Linha de facetas',
  'app.pages.interfaces.pivot.facet_column': 'Coluna de facetas',
  'app.pages.interfaces.pivot.text': 'Texto',
  'app.pages.interfaces.pivot.animation_frame': 'Quadro de animação',
  'app.pages.interfaces.pivot.animation_group': 'Grupo de animação',
  'app.pages.interfaces.pivot.error_x': 'Erro X',
  'app.pages.interfaces.pivot.error_x_minus': 'Erro X menos',
  'app.pages.interfaces.pivot.error_y': 'Erro Y',
  'app.pages.interfaces.pivot.error_y_minus': 'Erro Y menos',
  'app.pages.interfaces.pivot.error_z': 'Erro Z',
  'app.pages.interfaces.pivot.error_z_minus': 'Erro Z menos',
  'app.pages.interfaces.pivot.latitude': 'Latitude',
  'app.pages.interfaces.pivot.longitude': 'Longitude',
  'app.pages.interfaces.pivot.r': 'R',
  'app.pages.interfaces.pivot.theta': 'Theta',
  'app.pages.interfaces.pivot.measure_1': 'Medida 1',
  'app.pages.interfaces.pivot.measure_2': 'Medida 2',
  'app.pages.interfaces.pivot.measure_3': 'Medida 3',
  'app.pages.interfaces.pivot.color': 'Cor',
  'app.pages.interfaces.pivot.size': 'Tamanho',
  'app.pages.interfaces.pivot.base': 'Base',
  'app.pages.interfaces.pivot.symbol': 'Símbolo',
  'app.pages.interfaces.pivot.line_group': 'Grupo de linha',
  'app.pages.interfaces.pivot.line_dash': 'Traço de linha',
  'app.pages.interfaces.pivot.pattern_shape': 'Forma padrão',
  'app.pages.interfaces.pivot.dimensions': 'Dimensões',
  'app.pages.interfaces.pivot.locations': 'Localizações',
  'app.pages.interfaces.pivot.names': 'Nomes',
  'app.pages.interfaces.pivot.values': 'Valores',
  'app.pages.interfaces.pivot.x_start': 'X inicial',
  'app.pages.interfaces.pivot.x_end': 'X final',
  'app.pages.interfaces.pivot.path': 'Caminho',
  'app.pages.interfaces.pivot.parents': 'Pais',
  'app.pages.interfaces.pivot.ids': 'IDs',
  'app.pages.interfaces.pivot.chart_type_1': 'Tipo de gráfico 1',
  'app.pages.interfaces.pivot.chart_type_2': 'Tipo de gráfico 2',
  'app.pages.interfaces.pivot.dimension': 'Dimensão',
  'app.pages.interfaces.pivot.measure': 'Medida',
  'app.pages.interfaces.pivot.rows': 'Linhas',
  'app.pages.interfaces.pivot.columns': 'Colunas',
  'app.pages.interfaces.pivot.measures': 'Medidas',
  'app.pages.interfaces.pivot.series': 'Series',
  'app.pages.interfaces.pivot.sum': 'Soma',
  'app.pages.interfaces.pivot.avg': 'Média',
  'app.pages.interfaces.pivot.min': 'Mínimo',
  'app.pages.interfaces.pivot.max': 'Máximo',
  'app.pages.interfaces.pivot.count': 'Contagem',
  'app.pages.interfaces.pivot.': 'Não',
  'app.pages.interfaces.pivot.agg': 'Agregação',
  'app.pages.interfaces.pivot.newMeasure': 'Nova medida',
  'app.pages.interfaces.pivot.newMeasureName': 'Nome da nova medida',
  'app.pages.interfaces.pivot.selectHierarchy': 'Selecionar hierarquia',
  'app.pages.interfaces.pivot.editCalculatedMeasure': 'Editar medida calculada',
  'app.pages.interfaces.pivot.removeCalculatedMeasure': 'Excluir medida calculada',
  'app.pages.interfaces.pivot.removeCalculatedMeasureMessage':
    'Tem certeza que deseja excluir "{field}"?',
  'app.pages.interfaces.pivot.function': 'Função',
  'app.pages.interfaces.pivot.calculationMode': 'Modo de cálculo',
  'app.pages.interfaces.pivot.histnorm': 'Normalização do histograma',
  'app.pages.interfaces.pivot.box': 'Caixa',
  'app.pages.interfaces.pivot.marginal': 'Marginal',
  'app.pages.interfaces.pivot.calculatedMeasure': 'Medida calculada',
  'app.pages.interfaces.pivot.calculatedItem': 'Item calculado',
  'app.pages.interfaces.pivot.editCalculatedItem': 'Editar item calculado',
  'app.pages.interfaces.pivot.removeCalculatedItem': 'Excluir item calculado',
  'app.pages.interfaces.pivot.removeCalculatedItemMessage':
    'Tem certeza que deseja excluir "{item}"?',
  'app.pages.interfaces.pivot.itemAlreadyExists': 'O item ja existe',
  'app.pages.interfaces.pivot.addSubtotal': 'Adicionar subtotal',
  'app.pages.interfaces.pivot.removeSubtotal': 'Excluir subtotal',
  'app.pages.interfaces.pivot.sortAscending': 'Ordenar de forma ascendente',
  'app.pages.interfaces.pivot.sortDescending': 'Ordenar de forma descendente',
  'app.pages.interfaces.pivot.removeSorting': 'Excluir ordenação',

  //Code widget
  'app.pages.app.code.widget.code.saveAndRun': 'Salvar e Rodar Código',
  'app.pages.app.code.widget.code.formatCode': 'Formatar código',

  //result widget
  'app.pages.app.code.widget.result.defaultview.saved':
    'A visão padrão do nó foi salva corretamente.',
  'app.pages.app.code.widget.result.notCalculated': 'O nó não é calculado',
  'app.pages.app.code.widget.result.loading': 'Carregando resultados..',

  //Preview widget
  'app.pages.app.code.widget.preview.resultType': 'Tipo de resultado',
  'app.pages.app.code.widget.preview.indexes': 'Índices',
  'app.pages.app.code.widget.preview.preview': 'Preview',
  'app.pages.app.code.widget.preview.clickToOpenSparkUI': 'Clique aqui para abrir o Spark UI',

  //Console widget
  'app.pages.app.code.widget.console.preserveLog': 'Preservar log',
  'app.pages.app.code.widget.console.clearLogs': 'Excluir logs',
  'app.pages.app.code.widget.console.closeConsole': 'Fechar console',
  'app.pages.app.code.widget.console.showConsole': 'Abrir console',
  'app.pages.app.code.widget.console.clearErrors': 'Apagar erros',
  'app.pages.app.code.widget.console.copyError': 'Copiar erro',
  'app.pages.app.code.widget.console.errorCopied': 'Erro copiado',
  'app.pages.app.code.widget.console.performanceAnalysis': 'Análise de desempenho',
  'app.pages.app.code.widget.console.performance': 'Desempenho',
  'app.pages.app.code.widget.console.percentOfTime': '% do tempo',
  'app.pages.app.code.widget.console.fromDynamic': 'De dynamic',
  'app.pages.app.code.widget.console.maxMemory': 'Memória máxima',
  'app.pages.app.code.widget.console.usedMemory': 'Memória usada',
  'app.pages.app.code.widget.console.processingTime': 'Tempo de processamento',
  'app.pages.app.code.widget.console.enableAnalysis': 'Habilitar análise',
  'app.pages.app.code.widget.console.disableAnalysis': 'Desabilitar análise',
  'app.pages.app.code.widget.console.clearResults': 'Limpar resultados',
  'app.pages.app.code.widget.console.logs': 'Registros',
  'app.pages.app.code.widget.console.resetAppResults': 'Resetar resultados do aplicativo',

  //Task manager
  'app.pages.app.taskManager.task': 'Administrador da tarefas',
  'app.pages.app.taskManager.addTaskScheduled': 'Criar tarefa agendada',
  'app.pages.app.taskManager.editTaskScheduled': 'Editar tarefa agendada',
  'app.pages.app.taskManager.createdBy': 'Criado por',
  'app.pages.app.taskManager.lastRunAt': 'Última execução a las',
  'app.pages.app.taskManager.nextExecution': 'Seguinte execução',
  'app.pages.app.taskManager.lastStatus': 'Último status',
  'app.pages.app.taskManager.appPool': 'App pool',
  'app.pages.app.taskManager.schedulerType': 'Tipo de calendário',
  'app.pages.app.taskManager.params': 'Parâmetros',
  'app.pages.app.taskManager.enabled': 'Ativada',
  'app.pages.app.taskManager.showTaskLog': 'Mostrar log da tarefa',
  'app.pages.app.taskManager.addAppPool': 'Criar app pool',
  'app.pages.app.taskManager.interval': 'Intervalo',
  'app.pages.app.taskManager.clocked': 'Cronometrada',
  'app.pages.app.taskManager.taskDeleted': 'Tarefa removida',
  'app.pages.app.taskManager.deleteTask': 'Remover tarefa {name}',
  'app.pages.app.taskManager.deleteTaskMessage': 'Você tem certeza de remover a tarefa?',
  'app.pages.app.taskManager.taskActivated': 'Tarefa ativada',
  'app.pages.app.taskManager.taskDeactivated': 'Tarefa desativada',
  'app.pages.app.taskManager.taskExecuted': 'Tarea executada',
  'app.pages.app.taskManager.editAppPool': 'Editar app pool',
  'app.pages.app.taskManager.taskLogs': 'Registros da tarefa',
  'app.pages.app.taskManager.createdAt': 'Criado em',
  'app.pages.app.taskManager.state': 'Status',
  'app.pages.app.taskManager.lastTaskExecutions': 'Última execução da tarefa',
  'app.pages.app.taskManager.runTask': 'Rodar tarefa',
  'app.pages.app.taskManager.advancedFilters': 'Filtro avançado',
  'app.pages.app.taskManager.taskLogsDetails': 'Detalhes dos registros da tarefa',
  'app.pages.app.taskManager.refreshLogs': 'Atualizar registros',
  'app.pages.app.taskManager.taskDidNotRunYet': 'A tarefa não foi executado',
  'app.pages.app.taskManager.description': 'Descrição',
  'app.pages.app.taskManager.activity': 'Atividade',
  'app.pages.app.taskManager.info': 'Informação',
  'app.pages.app.taskManager.periodicTaskLogNotFound': 'Não há registros da tarefa',
  'app.pages.app.taskManager.success': 'Êxito',
  'app.pages.app.taskManager.failure': 'Fracasso',
  'app.pages.app.taskManager.maxDateError': 'La fecha máxima no puede ser menor a la fecha mínima',
  'app.pages.app.taskManager.minDate': 'Data mínima',
  'app.pages.app.taskManager.maxDate': 'Data máxima',
  'app.pages.app.taskManager.timeZones': 'Fuso horário',
  'app.pages.app.taskManager.clearForm': 'Limpar formulário',
  'app.pages.app.taskManager.insertTheTaskName': 'Inserir o nome da tarefa',
  'app.pages.app.taskManager.disabled': 'Desativada',
  'app.pages.app.taskManager.days': 'Dia',
  'app.pages.app.taskManager.hours': 'Hora',
  'app.pages.app.taskManager.minutes': 'Minuto',
  'app.pages.app.taskManager.seconds': 'Segundos',
  'app.pages.app.taskManager.microseconds': 'Microssegundos',
  'app.pages.app.taskManager.every': 'Cada',
  'app.pages.app.taskManager.period': 'Período',
  'app.pages.app.taskManager.everyPlaceholder': 'Por favor insere um valor inteiro',
  'app.pages.app.taskManager.appPath': 'Caminho do aplicativo',
  'app.pages.app.taskManager.generalOptions': 'Opções gerais',
  'app.pages.app.taskManager.scheduler': 'Agendador',
  'app.pages.app.taskManager.everyMinute': 'Todos os minutos',
  'app.pages.app.taskManager.minutesPlaceholder': 'Selecione os minutos',
  'app.pages.app.taskManager.everyHour': 'Todas as horas',
  'app.pages.app.taskManager.hoursPlaceholder': 'Selecione as horas',
  'app.pages.app.taskManager.dayMonths': 'Dia do mês',
  'app.pages.app.taskManager.everyDayOfMonths': 'Todos os dias do mês',
  'app.pages.app.taskManager.everyMonths': 'Todos os meses',
  'app.pages.app.taskManager.everyDays': 'Todos os dias',
  'app.pages.app.taskManager.dayMonthsPlaceholder': 'Selecione os dias do mês',
  'app.pages.app.taskManager.months': 'Mês',
  'app.pages.app.taskManager.monthsPlaceholder': 'Selecione os meses',
  'app.pages.app.taskManager.daysPlaceholder': 'Selecione os dias',
  'app.pages.app.taskManager.january': 'Janeiro',
  'app.pages.app.taskManager.february': 'Fevereiro',
  'app.pages.app.taskManager.march': 'Março',
  'app.pages.app.taskManager.april': 'Abril',
  'app.pages.app.taskManager.may': 'Maio',
  'app.pages.app.taskManager.june': 'Junho',
  'app.pages.app.taskManager.july': 'Julho',
  'app.pages.app.taskManager.august': 'Agosto',
  'app.pages.app.taskManager.september': 'Setembro',
  'app.pages.app.taskManager.october': 'Outubro',
  'app.pages.app.taskManager.november': 'Novembro',
  'app.pages.app.taskManager.december': 'Dezembro',
  'app.pages.app.taskManager.monday': 'Segunda-feira',
  'app.pages.app.taskManager.tuesday': 'Terça-feira',
  'app.pages.app.taskManager.wednesday': 'Quarta-feira',
  'app.pages.app.taskManager.thursday': 'Quinta-feira',
  'app.pages.app.taskManager.friday': 'Sexta-feira',
  'app.pages.app.taskManager.saturday': 'Sabado',
  'app.pages.app.taskManager.sunday': 'Domingo',
  'app.pages.app.taskManager.at': 'Às ',
  'app.pages.app.taskManager.on': '{on} ',
  'app.pages.app.taskManager.in': 'de ',
  'app.pages.app.taskManager.pastHour': 'após ',
  'app.pages.app.taskManager.andOn': 'e na ',
  'app.pages.app.taskManager.and': 'e',
  'app.pages.app.taskManager.reset': 'Redefinir',
  'app.pages.app.taskManager.nameIsRequired': 'O nome da tarefa é necessário',
  'app.pages.app.taskManager.nameMaxLength': 'o tamaño máximo do nome é {number} caracteres',
  'app.pages.app.taskManager.schedulerTypeIsRequired': 'O tipo de Agendador é necessário',
  'app.pages.app.taskManager.periodIsRequired': 'Periodo  é necessário',
  'app.pages.app.taskManager.everyIsRequired': 'Intervalo de tempo é necessário',
  'app.pages.app.taskManager.everyMustBeInt': 'Intervalo de tempo deve ser um número inteiro',
  'app.pages.app.taskManager.everyMaxLength': 'o tamaño máximo do sobrenombe é {number} caracteres',
  'app.pages.app.taskManager.clockedIsRequired': 'Hora do relógio é necessária',
  'app.pages.app.taskManager.nodeIsRequired': 'Nó para avaliar é necessário',
  'app.pages.app.taskManager.appPathIsRequired': 'Caminho do aplicativo é necessário',
  'app.pages.app.taskManager.type': 'Tipo',
  'app.pages.app.taskManager.label': 'Rótulo',
  'app.pages.app.taskManager.defaultValue': 'Valores padrão',
  'app.pages.app.taskManager.source': 'Fonte',
  'app.pages.app.taskManager.paramsTable': 'Mesa de Parâmetros',
  'app.pages.app.taskManager.noParamsData': 'Não há Parâmetros',
  'app.pages.app.taskManager.select': 'Selecionar',
  'app.pages.app.taskManager.input': 'Entrada',
  'app.pages.app.taskManager.checkbox': 'Caixa de seleção',
  'app.pages.app.taskManager.paramNameIsRequired': 'O nome de Parâmetro é necessário',
  'app.pages.app.taskManager.labelIsRequired': 'Rótulo é necessário',
  'app.pages.app.taskManager.paramNameInvalid':
    'Deve conter letras minúsculas e números. Espaços em branco e travessões não são permitidos',
  'app.pages.app.taskManager.paramNamePlaceholder': 'Insira o nome de Parâmetro',
  'app.pages.app.taskManager.labelPlaceholder': 'Insira o rótulo',
  'app.pages.app.taskManager.defaultValuePlaceholder': 'Insira o Valor padrão',
  'app.pages.app.taskManager.sourcePlaceholder': 'Insira o fonte',
  'app.pages.app.taskManager.taskNameExists': 'O nome de tarefa já existe',
  'app.pages.app.taskManager.taskCreated': 'Tarefa criada',
  'app.pages.app.taskManager.taskUpdated': 'Tarefa atualizada',
  'app.pages.app.taskManager.keepForEveryone': 'Mantener para todos',
  'app.pages.app.taskManager.keepForUser': 'Mantener para o usuário',
  'app.pages.app.taskManager.actionOnDone': 'Ação após a conclusão',
  'app.pages.app.taskManager.instances': 'Instância',
  'app.pages.app.taskManager.enableInstanceExpire': 'Habilitar instância expira',
  'app.pages.app.taskManager.expireAt': 'Expira às (UTC)',
  'app.pages.app.taskManager.selectModelFIle': 'Selecione o arquivo do modelo',
  'app.pages.app.taskManager.node': 'Nó',
  'app.pages.app.taskManager.noNodesFounded': 'Não existem nós',
  'app.pages.app.taskManager.clockedWithTz': 'Cronometrada UTC {tz}',
  'app.pages.app.taskManager.appPoolCreated': 'App pool criada',
  'app.pages.app.taskManager.appPoolUpdated': 'App pool atualizada',
  'app.pages.app.taskManager.actionIsRequired': 'Ação após a conclusão é necessário',
  'app.pages.app.taskManager.instancesPositive':
    'O valor da instâncias deve ser um número positivo',
  'app.pages.app.taskManager.instancesIsRequired': 'Instâncias são necessárias',
  'app.pages.app.taskManager.userIsRequired': 'O usuário é necessário',
  'app.pages.app.taskManager.users': 'Usuário',
  'app.pages.app.taskManager.usersPlaceholder': 'Selecione um usuário',
  'app.pages.app.taskManager.timezoneIsRequired': 'Fuso horário é necessário',
  'app.pages.app.taskManager.noTasksFound': 'Nenhuma tarefa encontrada',
  'app.pages.app.taskManager.taskUser': 'Usuário da tarefa',
  'app.pages.app.taskManager.systemTask': 'Sistema',
  'app.pages.app.taskManager.noPermissionsToListTasks':
    'Você não tem permissões suficientes para listar tarefas agendadas',
  'app.pages.app.taskManager.resources': 'Recursos',
  'app.pages.app.taskManager.useDepartment': 'Recursos do departamento',

  //Running node
  'app.pages.app.runningNodeModel.runningNode': 'Nó rodando',
  'app.pages.app.runningNodeModel.stopping': 'Parando',

  //Saving app
  'app.pages.savingApp.savingApp': 'Salvando o aplicativo',

  //Interface Manager
  'app.pages.interfaceManager': 'Administrador de interfaces',
  'app.pages.interfaceManager.newInterface': 'Nova interface',
  'app.pages.interfaceManager.createNewInterface': 'Criar nova interface',
  'app.pages.interfaceManager.editInterface': 'Editar interface',
  'app.pages.interfaceManager.createNewFolder': 'Criar nova pasta',
  'app.pages.interfaceManager.name': 'Nome da interface',
  'app.pages.interfaceManager.folderName': 'Nome da pasta',
  'app.pages.interfaceManager.deleteInterface': 'Deletar interfaces',
  'app.pages.interfaceManager.deleteFolder': 'Deletar pasta',
  'app.pages.interfaceManager.deleteInterfaceConfirmationText':
    'Tem certeza de que deseja deletar esta interface permanentemente?',
  'app.pages.interfaceManager.deleteFolderConfirmationText':
    'Tem certeza de que deseja deletar permanentemente esta pasta e tudo o seu conteúdo?',
  'app.pages.interfaceManager.myInterfaces': 'Minhas interfaces',
  'app.pages.interfaceManager.noInterfaces':
    'Não há interfaces disponíveis. Crie uma usando o botão Criar',
  'app.pages.interfaceManager.publicInterface': 'Pública',
  'app.pages.interfaceManager.deniedFor': 'Negado para',
  'app.pages.interfaceManager.allowedFor': 'Permitido para',
  'app.pages.interfaceManager.deniedForDetail': 'Negar acesso a',
  'app.pages.interfaceManager.slashNotAllowed':
    'Caractere inválido. "/" não permitido para o nome da interface',
  'app.pages.interfaceManager.interfaceNameRepeated': 'O nome da interface já existe',
  'app.pages.interfaceManager.removeDocumentation': 'Remover documentação',
  'app.pages.interfaceManager.removeDocumentationConfirm': 'Você quer remover a documentação?',
  'app.pages.interfaceManager.shareInterface': 'Compartilhar interface',
  'app.pages.interfaceManager.interfaceLinkCopied': 'Link da interface copiado',
  'app.pages.interfaceManager.copyInterfaceLink': 'Copiar link da interface',
  'app.pages.interfaceManager.interfaceLink': 'Link da interface',
  'app.pages.interfaceManager.interfacesLinks': 'Links de interfaces',
  'app.pages.interfaceManager.interfacesLinks.notFound': 'Nenhum link de interface encontrado',
  'app.pages.interfaceManager.activateInterfaceEndpoint': 'Ativar interface endpoint',
  'app.pages.interfaceManager.updateInterfaceLink': 'Atualizar link da interface',
  'app.pages.interfaceManager.interfaceLinkUpdated': 'Link da interface atualizado com sucesso',
  'app.pages.interfaceManager.deleteInterfaceLink': 'Deletar link da interface: {interfaceName}',
  'app.pages.interfaceManager.deleteInterfaceLinkConfirmation':
    'Tem certeza de que deseja deletar este link?',
  'app.pages.interfaceManager.interfaceLinkDeleted': 'Link da interface deletado com sucesso',
  'app.pages.interfaceManager.folderNameExists': 'O nome da pasta já existe',
  'app.pages.externalLink.customEndpointExists': 'O endpoint personalizado já existe',
  'app.pages.externalLink.passwordRequiredDescription': 'Por favor insira a senha para continuar',
  'app.pages.externalLink.hasPassword': 'Tem senha',
  'app.pages.externalLink.requirePassword': 'Requer senha',

  //File manager
  'app.pages.FileManagerPage.download': 'Baixar',
  'app.pages.FileManagerPage.zipIt': 'Zipar',
  'app.pages.FileManagerPage.copyIn': 'Copiar',
  'app.pages.FileManagerPage.archive': 'Arquivo',
  'app.pages.FileManagerPage.denyAccess': 'Negar accesos',
  'app.pages.FileManagerPage.folder': 'Pasta',
  'app.pages.FileManagerPage.selectAll': 'Selecionar tudo',
  'app.pages.FileManagerPage.unSelectAll': 'Desmarque todos',
  'app.pages.FileManagerPage.models': 'Modelos (.ppl)',
  'app.pages.FileManagerPage.csv': 'CSV {extension}',
  'app.pages.FileManagerPage.templates': 'Documentos(.xls,.xlsx,.xlsm)',
  'app.pages.FileManagerPage.database': 'Banco de dados {extension}',
  'app.pages.FileManagerPage.modules': 'Modulos (.ppm)',
  'app.pages.FileManagerPage.interfaces': 'Interfaces',
  'app.pages.FileManagerPage.zipFiles': 'Arquivo Zip {extension}',
  'app.pages.FileManagerPage.jsonFiles': 'Arquivo Json {extension}',
  'app.pages.FileManagerPage.backup': 'Cópia de segurança (.old)',
  'app.pages.FileManagerPage.others': 'Outros',
  'app.pages.FileManagerPage.showHidden': 'Mostrar arquivos/pastas escondidas',
  'app.pages.FileManagerPage.filterBy': 'Filtrar por',
  'app.pages.FileManagerPage.model': 'Modelo',
  'app.pages.FileManagerPage.file': 'Arquivo',
  'app.pages.FileManagerPage.type': 'Tipo',
  'app.pages.FileManagerPage.location': 'Localização',
  'app.pages.FileManagerPage.opened': 'Aberto',
  'app.pages.FileManagerPage.size': 'Tamanho',
  'app.pages.FileManagerPage.getSize': 'Calcular tamanho',
  'app.pages.FileManagerPage.date': 'Encontro',
  'app.pages.FileManagerPage.access': 'Acceso',
  'app.pages.FileManagerPage.fileName': 'Nome de arquivo',
  'app.pages.FileManagerPage.folders': 'Pastas',
  'app.pages.FileManagerPage.files': 'Arquivos',
  'app.pages.FileManagerPage.root': 'Raiz',
  'app.pages.FileManagerPage.listView': 'Exibição de lista',
  'app.pages.FileManagerPage.mosaicView': 'Visualização em mosaico',
  'app.pages.FileManagerPage.folderEmpty': 'A pasta está vazia',
  'app.pages.FileManagerPage.denyAccessTo': 'Negar acesso',
  'app.pages.FileManagerPage.allowAccessTo': 'Permitir acesso',
  'app.pages.FileManagerPage.allowAccessOnlyTo': 'Permitir acesso somente a',
  'app.pages.FileManagerPage.deletedMessage': '{name} removido',
  'app.pages.FileManagerPage.wantToDeleteFolder': 'Você tem certeza de remover a pasta ?',
  'app.pages.FileManagerPage.wantToDeleteFile': 'Você tem certeza de remover o arquivo ?',
  'app.pages.FileManagerPage.deletedTitle': 'Remover {name}',
  'app.pages.FileManagerPage.wantToZipFile': 'Você tem certeza de comprimir o arquivo ?',
  'app.pages.FileManagerPage.zipFile': 'Comprimir {name}',
  'app.pages.FileManagerPage.unZipFile': 'Descomprimir {name}',
  'app.pages.FileManagerPage.fileZipped': '{name} comprimido',
  'app.pages.FileManagerPage.fileUnZipped': '{name} descomprimido',
  'app.pages.FileManagerPage.nodeChanged': 'Nome mudado a {name}',
  'app.pages.FileManagerPage.wantToUnZipFile': 'Você tem certeza de descomprimir o arquivo ?',
  'app.pages.FileManagerPage.unzip': 'Descomprimir',
  'app.pages.FileManagerPage.move': 'Mover',
  'app.pages.FileManagerPage.fileCloned': '{name} duplicado',
  'app.pages.FileManagerPage.createFolderTitle': 'Estabelecer nome de pasta',
  'app.pages.FileManagerPage.folderCreated': 'Pasta criada',
  'app.pages.FileManagerPage.folderName': 'Nome de pasta',
  'app.pages.FileManagerPage.deletedFilesMessage': 'Arquivos ou pastas removidas',
  'app.pages.FileManagerPage.copyFileIn': 'Copie {name} em',
  'app.pages.FileManagerPage.upload': 'Upload',
  'app.pages.FileManagerPage.wantToDelete': 'Você tem certeza de remover :',
  'app.pages.FileManagerPage.duplicatedFiles': 'Arquivos duplicados',
  'app.pages.FileManagerPage.zipTitle': 'Comprimir arquivos',
  'app.pages.FileManagerPage.wantToZipFileS':
    'Você tem certeza de comprimir os arquivos seleccionados?',
  'app.pages.FileManagerPage.departmentsRestrictions': 'Departamento/s restrito',
  'app.pages.FileManagerPage.folderNameError':
    'O nome da pasta é requerido e não pode estar vazio.',
  'app.pages.FileManagerPage.folderNameErrorSlash': 'O nome da pasta não pode ter barra',
  'app.pages.FileManagerPage.newNameIsRequired': 'O nome es requerido e não pode estar vazio.',
  'app.pages.FileManagerPage.filesUploaded': 'Arquivos carregados',
  'app.pages.FileManagerPage.filesZipped': 'Arquivos compactados',
  'app.pages.FileManagerPage.filesToCopy': 'Arquivos para copiar',
  'app.pages.FileManagerPage.pasteHere': 'Cole aquí',
  'app.pages.FileManagerPage.pleaseSelectAFile':
    'Selecione um aquivo ou uma pasta e voce vai ver seu detalhes aqui.',
  'app.pages.FileManagerPage.filesCopied': 'Arquivos copiados',
  'app.pages.FileManagerPage.filesMoved': 'Arquivos movidos',
  'app.pages.FileManagerPage.filesDownloaded': 'Arquivos baixados',
  'app.pages.FileManagerPage.uploading': 'Enviando...',
  'app.pages.FileManagerPage.uploaded': 'Carregado!',
  'app.pages.FileManagerPage.compressing': 'Compressão...',
  'app.pages.FileManagerPage.unzipping': 'Descompactando...',
  'app.pages.FileManagerPage.duplicating': 'Duplicando...',
  'app.pages.FileManagerPage.loadingLastPath': 'Carregando o último caminho..',
  'app.pages.FileManagerPage.uploadCancelled': 'Upload cancelado pelo usuário',
  'app.pages.FileManagerPage.deleting': 'Removendo',
  'app.pages.FileManagerPage.creating': 'Criando a pasta',
  'app.pages.FileManagerPage.pastingFiles': 'Colando arquivos',
  'app.pages.FileManagerPage.configuringAccess': 'Configurando acesso',
  'app.pages.FileManagerPage.downloading': 'Baixando',
  'app.pages.FileManagerPage.duplicateTitle': 'Duplicar {name}',
  'app.pages.FileManagerPage.wantToDuplicate':
    'Você tem certeza de duplicar os arquivos selecionados?',
  'app.pages.FileManagerPage.dragDropFilesHere': 'Arraste e solte arquivos aqui ou',
  'app.pages.FileManagerPage.overWriteFileOrFolder': 'Substituir arquivo ou pasta',
  'app.pages.FileManagerPage.overWriteFileOrFolderMessage':
    'Você tem certeza de substituir {name}?',
  'app.pages.FileManagerPage.clearClipboard': 'Vaziar prancheta',
  'app.pages.FileManagerPage.clipboardCleared': 'Prancheta vazía',
  'app.pages.FileManagerPage.copyToWorkspaceTitle': 'Copiar no espaço de trabalho',
  'app.pages.FileManagerPage.copyToWorkspaceMessage':
    'Você tem certeza de quer copiar {name} no seu espaço de trabalho?',
  'app.pages.FileManagerPage.copyingToWorkspace': 'Copiando a espaço de trabalho',
  'app.pages.FileManagerPage.copiedToWorkspace': 'Copiado no espaço de trabalho',
  'app.pages.FileManagerPage.openTextEditor': 'Abrir editor de texto',
  'app.pages.FileManagerPage.loadingFile': 'Carregando arquivo',
  'app.pages.FileManagerPage.savingFile': 'Salvando arquivo',
  'app.pages.FileManagerPage.fileSaved': 'Arquivo salvo',
  'app.pages.FileManagerPage.fileTooLargeToRead':
    'Arquivo muito grande para ler. tamanho máximo: 1MB',
  'app.pages.FileManagerPage.copyUrl': 'Copiar url',
  'app.pages.FileManagerPage.urlCopied': 'URL copiado',
  'app.pages.FileManagerPage.errorUploading': 'Ocorreu um erro ao carregar o arquivo',

  //Actions (for logManager filter)
  'app.pages.managers.logManager.actions.openApp': 'Abrir aplicativo',
  'app.pages.managers.logManager.actions.navigateDiagram': 'Navegar diagrama',
  'app.pages.managers.logManager.actions.getArrows': 'Obter flechas',
  'app.pages.managers.logManager.actions.getToolbars': 'Obter toolbars',
  'app.pages.managers.logManager.actions.lastOpenApps': 'Últimos aplicativos abertos',
  'app.pages.managers.logManager.actions.changeToOtherInstance':
    'Mudar para outra instância de modelo',
  'app.pages.managers.logManager.actions.getNodeProperties': 'Obter propriedades do nó',
  'app.pages.managers.logManager.actions.setNodeProperties': 'Definir propriedades do nó',
  'app.pages.managers.logManager.actions.setNodesProperties': 'Definir propriedades de nós',
  'app.pages.managers.logManager.actions.getNodeInputs': 'Obter entradas de nó',
  'app.pages.managers.logManager.actions.getNodeOutputs': 'Obter saídas de nó',
  'app.pages.managers.logManager.actions.searchNodes': 'Procurar nós',
  'app.pages.managers.logManager.actions.searchForAutocomplete': 'Procure por autocomplete',
  'app.pages.managers.logManager.actions.previewNode': 'Antevisão do nó',
  'app.pages.managers.logManager.actions.getSelector': 'Obter seletor',
  'app.pages.managers.logManager.actions.setNodesSize': 'Definir o tamanho do nó',
  'app.pages.managers.logManager.actions.setNodesPosition': 'Definir a posição do nó',
  'app.pages.managers.logManager.actions.getNodeProfile': 'Obter perfil de nó',
  'app.pages.managers.logManager.actions.createNode': 'Criar nó',
  'app.pages.managers.logManager.actions.deleteNodes': 'Deletar nós',
  'app.pages.managers.logManager.actions.createAlias': 'Criar alias',
  'app.pages.managers.logManager.actions.createInputNode': 'Criar nó de entrada',
  'app.pages.managers.logManager.actions.copyNodes': 'Copiar nós',
  'app.pages.managers.logManager.actions.copyAsValues': 'Copiar como valores',
  'app.pages.managers.logManager.actions.moveNodes': 'Mover nós',
  'app.pages.managers.logManager.actions.stop': 'Parar',
  'app.pages.managers.logManager.actions.setNodeZ': 'Definir Z do nó',
  'app.pages.managers.logManager.actions.setNodeIdFromTitle': 'Definir o id do nó desde título',
  'app.pages.managers.logManager.actions.executeForRefresh': 'Executar para atualizar',
  'app.pages.managers.logManager.actions.exportFlatNode': 'Exportar nó plano',
  'app.pages.managers.logManager.actions.exportCurrentNode': 'Exportar o nó actual',
  'app.pages.managers.logManager.actions.exportModuleToFile': 'Exportar módulo para arquivo',
  'app.pages.managers.logManager.actions.importModuleFromFile': 'Importar módulo desde arquivo',
  'app.pages.managers.logManager.actions.getFilesForImportWizard':
    'Obter arquivos do assistente de importação',
  'app.pages.managers.logManager.actions.callWizard': 'Chamar a asistente',
  'app.pages.managers.logManager.actions.executeButton': 'Executar botão',
  'app.pages.managers.logManager.actions.isInBackground': 'Está em segundo plano',
  'app.pages.managers.logManager.actions.uploadFileToTemp': 'Fazer upload do arquivo para temp',
  'app.pages.managers.logManager.actions.installLibrary': 'Instalar bibiliotecas',
  'app.pages.managers.logManager.actions.listInstalledLibraries': 'Listar bibliotecas instaladas',
  'app.pages.managers.logManager.actions.uninstallLibrary': 'Desinstalar biblioteca',
  'app.pages.managers.logManager.actions.getInstallProgress': 'Obter progresso de instalação',
  'app.pages.managers.logManager.actions.getMainFolders': 'Obter pastas principais',
  'app.pages.managers.logManager.actions.getFiles': 'Obter arquivos',
  'app.pages.managers.logManager.actions.createFolder': 'Criar pasta',
  'app.pages.managers.logManager.actions.copyFileOrFolder': 'Copiar arquivo ou pasta',
  'app.pages.managers.logManager.actions.renameFile': 'Renomear arquivo',
  'app.pages.managers.logManager.actions.duplicateFiles': 'Duplicar arquivos',
  'app.pages.managers.logManager.actions.moveFiles': 'Mover arquivos',
  'app.pages.managers.logManager.actions.copyFiles': 'Copiar arquivos',
  'app.pages.managers.logManager.actions.deleteFiles': 'Deletar arquivos',
  'app.pages.managers.logManager.actions.upload': 'Fazer upload',
  'app.pages.managers.logManager.actions.unzipFile': 'Dezipar arquivos',
  'app.pages.managers.logManager.actions.zipFiles': 'Zipar arquivos',
  'app.pages.managers.logManager.actions.getHome': 'Obter o inicio',
  'app.pages.managers.logManager.actions.optimizeTemplates': 'Otimizar modelos',
  'app.pages.managers.logManager.actions.myReports': 'Meus relatórios',
  'app.pages.managers.logManager.actions.sharedWithMe': 'Relatórios compartilhados comigo',
  'app.pages.managers.logManager.actions.mySharedReports': 'Meus relatórios compartilhados',
  'app.pages.managers.logManager.actions.reportManager': 'Endpoint de relatórios',
  'app.pages.managers.logManager.actions.getNavigator': 'Relatórios - Obter navegador',
  'app.pages.managers.logManager.actions.bulkDelete': 'Relatórios - Deletar em massa',
  'app.pages.managers.logManager.actions.changeOrder': 'Relatórios - Cambiar orden',
  'app.pages.managers.logManager.actions.search': 'Relatórios - Procurar',
  'app.pages.managers.logManager.actions.duplicateItems': 'Relatórios - Duplicar itens',
  'app.pages.managers.logManager.actions.copyToMyReports':
    'Relatórios - Cópia para meus relatórios',
  'app.pages.managers.logManager.actions.setAsFav': 'Relatórios - Definir como favorito',
  'app.pages.managers.logManager.actions.dropOnReport': 'Relatórios - Soltar no relatório',
  'app.pages.managers.logManager.actions.exportItems': 'Relatórios - Exportar itens',
  'app.pages.managers.logManager.actions.importItems': 'Relatórios - Importar itens',
  'app.pages.managers.logManager.actions.publishItems': 'Relatórios - Publicar itens',
  'app.pages.managers.logManager.actions.shares': 'Obter shares', //que es get shares
  'app.pages.managers.logManager.actions.setShares': 'Definir shares', //que es set shares
  'app.pages.managers.logManager.actions.myDashboards': 'Meus painéis', //Uso "dashboards" o "painéis"
  'app.pages.managers.logManager.actions.companyDashboards': 'Painéis - painel da empresa',
  'app.pages.managers.logManager.actions.dashboardsSharedWithMe': 'Painéis - Compartilhados comigo',
  'app.pages.managers.logManager.actions.allMyDashboards': 'Painéis - Todos meus painéis',
  'app.pages.managers.logManager.actions.mySharedDashboards':
    'Painéis - Meus painéis compartilhados',
  'app.pages.managers.logManager.actions.dashboardById': 'Painéis - Por identificação',
  'app.pages.managers.logManager.actions.getNodeFullData': 'Painéis - Obter dados completos do nó',
  'app.pages.managers.logManager.actions.evaluateNode': 'Painéis - Avaliar nó',
  'app.pages.managers.logManager.actions.getOrCreate': 'Painéis - Obter ou criar',
  'app.pages.managers.logManager.actions.dashboardManager': 'Endpoints de painéis',
  'app.pages.managers.logManager.actions.dashboardBulkDelete': 'Painéis - Deletar em massa',
  'app.pages.managers.logManager.actions.dashboardChangeOrder': 'Painéis - Cambiar orden',
  'app.pages.managers.logManager.actions.getIndexValues': 'Painéis - Obter valores de índice',
  'app.pages.managers.logManager.actions.getNodeIndexes': 'Painéis - Obter índices de nó',
  'app.pages.managers.logManager.actions.isResultComputed': 'Painéis - O resultado está calculado',
  'app.pages.managers.logManager.actions.lastOpenDashboards': 'Painéis - Últimos painéis abertos',
  'app.pages.managers.logManager.actions.copy': 'Painéis - Copiar',
  'app.pages.managers.logManager.actions.getCubeMetadata':
    'Painéis - Pivô - Obter metadados de cubo', //como traducir cube
  'app.pages.managers.logManager.actions.getCubeValues': 'Painéis - Pivô  - Obter valores de cubo',
  'app.pages.managers.logManager.actions.setCubeChanges':
    'Painéis - Pivô  - Definir mudanças de cubo',
  'app.pages.managers.logManager.actions.getDimensionValues':
    'Painéis - Pivô - Obter valores de dimensão',
  'app.pages.managers.logManager.actions.tokenAuthEndpoints': 'Endpoints de Token Auth',
  'app.pages.managers.logManager.actions.sendPasswordResetEmail':
    'Enviar e-mail de redefinição de senha',
  'app.pages.managers.logManager.actions.passwordReset': 'Redefinição de senha',
  'app.pages.managers.logManager.actions.authEndpoints': 'Endpoints de Auth',
  'app.pages.managers.logManager.actions.createInstance': 'Criar instância', //uso sesion o instancia
  'app.pages.managers.logManager.actions.getInstance': 'Obter instância',
  'app.pages.managers.logManager.actions.logout': 'Fechar sessão',
  'app.pages.managers.logManager.actions.getAllInstances': 'Obter todas as instâncias',
  'app.pages.managers.logManager.actions.getMyInstances': 'Obter todas as minhas instâncias',
  'app.pages.managers.logManager.actions.killInstanceByKey': 'Matar instância por chave', //matar sesion por llave¿?
  'app.pages.managers.logManager.actions.createNewInstance': 'Criar nova instância',
  'app.pages.managers.logManager.actions.useExternalLink': 'Usar link externo',
  'app.pages.managers.logManager.actions.getSystemResources': 'Obter recursos de sistema',
  'app.pages.managers.logManager.actions.requireLogout': 'Requer logout',
  'app.pages.managers.logManager.actions.getGeoData': 'Geo - Obter informações Geo',
  'app.pages.managers.logManager.actions.getGeoDetail': 'Geo - Obter detalhes Geo',
  'app.pages.managers.logManager.actions.getBubbleInfo': 'Geo - Obter informações sobre a bolha',
  'app.pages.managers.logManager.actions.inputForm': 'Formulários de entrada - Listar', //input o entradas
  'app.pages.managers.logManager.actions.getMetadata': 'Formulários de entrada - Obter metadados',
  'app.pages.managers.logManager.actions.getData': 'Formulários de entrada - Obter informação',
  'app.pages.managers.logManager.actions.setData': 'Formulários de entrada - Definir informação',
  'app.pages.managers.logManager.actions.result': 'Endpoints de Resultado',
  'app.pages.managers.logManager.actions.resultByNodeId': 'Resultado - Por identificador de nó',
  'app.pages.managers.logManager.actions.activities': 'Endpoints de atividades',
  'app.pages.managers.logManager.actions.groups': 'Endpoints de grupos',
  'app.pages.managers.logManager.actions.updateGroupsPermissions':
    'Grupos - Atualizar permissões de grupo',
  'app.pages.managers.logManager.actions.users': 'Endpoints de usuários',
  'app.pages.managers.logManager.actions.companies': 'Endpoints de empresas',
  'app.pages.managers.logManager.actions.preferenceByCode': 'Empresas - Preferências por código',
  'app.pages.managers.logManager.actions.preferences': 'Empresas - Preferências',
  'app.pages.managers.logManager.actions.usercompanies': 'Endpoints de empresas do usuário',
  'app.pages.managers.logManager.actions.listByCompanyId':
    'Empresas do usuário - Listar por identificador da empresa',
  'app.pages.managers.logManager.actions.userPreferenceByCode':
    'Empresas do usuário - Preferências por código',
  'app.pages.managers.logManager.actions.userPreferences': 'Empresas do usuário - Preferências',
  'app.pages.managers.logManager.actions.permissions': 'Endpoints de permissões',
  'app.pages.managers.logManager.actions.departments': 'Endpoints de departamentos',
  'app.pages.managers.logManager.actions.byCurrentCompany': 'Departmentos - Pela empresa atual',
  'app.pages.managers.logManager.actions.denied': 'Departmentos - Negados',
  'app.pages.managers.logManager.actions.denyItems': 'Departmentos - Negar item',
  'app.pages.managers.logManager.actions.dashboardComments': 'Endpoints de comentários de painel',
  'app.pages.managers.logManager.actions.dashboardStyles': 'Endpoints de estilos de painel',
  'app.pages.managers.logManager.actions.diagramShortcut': 'Endpoints de atalhos de diagrama',
  'app.pages.managers.logManager.actions.preferenceModules': 'Endpoints de módulos de preferência',
  'app.pages.managers.logManager.actions.preferencesEndpoints': 'Endpoints de preferências',
  'app.pages.managers.logManager.actions.userCompanyPreferences':
    'Endpoints de preferências da empresa do usuário',
  'app.pages.managers.logManager.actions.companyPreferences':
    'Endpoints de preferências da empresa',
  'app.pages.managers.logManager.actions.apiEndpoints': 'Endpoints da Api',
  'app.pages.managers.logManager.actions.reportExternalLink':
    'Endpoints de links externos do relatórios',
  'app.pages.managers.logManager.actions.dashboardExternalLink':
    'Endpoints de links externo do painel',
  'app.pages.managers.logManager.actions.scheduler': 'Endpoints de agendador', //scheduler = agendador o agendador de tarefas
  'app.pages.managers.logManager.actions.getPythonTimezones': 'Agendador - Obter fusos horários',
  'app.pages.managers.logManager.actions.taskLog': 'Endpoints de registro de tarefas',
  'app.pages.managers.logManager.actions.byPeriodicTask':
    'Registro de tarefas - Por tarefa periódica',
  'app.pages.managers.logManager.actions.filterLogs': 'Registro de tarefas - Filtrar registros',
  'app.pages.managers.logManager.actions.logging': 'Endpoints de registros',
  'app.pages.managers.logManager.actions.restAuth': 'Endpoints de Rest Auth',
  'app.pages.managers.logManager.actions.accounts': 'Endpoints de contas',

  //Applications
  'app.pages.applications.createApp': 'Criar aplicativo',
  'app.pages.applications.applicationName': 'Nome do aplicativo',
  'app.pages.applications.fromScratch': 'Do zero',
  'app.pages.applications.fromScratchDescription': 'Começa com um exemplo simple de "Olá mundo"',
  'app.pages.applications.fromDataAnalytics': 'Análise de dados',
  'app.pages.applications.fromDataAnalyticsDescription':
    'Exemplo de um aplicativo de análise de dados',
  'app.pages.applications.fromBusinessPlanning': 'Planejamento de negócios',
  'app.pages.applications.fromBusinessPlanningDescription':
    'Exemplo de um aplicativo de planejamento de negócios',
  'app.pages.applications.applicationManager': 'Administrador de aplicativos',
  'app.pages.applications.deleteAppTitle': 'Remover aplicativo',
  'app.pages.applications.deleteAppMessage': 'Você tem certeza de remover o aplicativo?',
  'app.pages.applications.results': 'Resultados',
  'app.pages.applications.applicationDeleted': 'Aplicativo removido',
  'app.pages.applications.deletingApplication': 'Removendo aplicativo',
  'app.pages.applications.applicationNameChanged': 'Nome do aplicativo renomado',
  'app.pages.applications.path': 'Caminho',
  'app.pages.applications.copyAppToWorkspaceTitle': 'Copiar aplicativo no espaço de trabalho',
  'app.pages.applications.copyAppToWorkspaceMessage':
    'Você tem certeza de quer copiar este aplicativo no seu espaço de trabalho?',
  'app.pages.applications.appCopiedToWorkspace': 'O aplicativo foi copiado com sucesso em:',
  'app.pages.applications.copyingAppToWorkspace': 'Copiando aplicativo a espaço de trabalho',
  'app.pages.applications.appImported': 'Aplicativo importado com sucesso',
  'app.pages.applications.createInPublic': 'Criar em espaço Public',
  'app.pages.applications.appCopied': 'Aplicativo copiado',
  'app.pages.applications.copyingAppToTeam': 'Copiando aplicativo para a equipe',
  'app.pages.applications.copyAppToPublicMessage':
    'Tem certeza de que deseja copiar este aplicativo para o espaço de trabalho Público?',
  'app.pages.applications.appCopiedToPublic': 'Aplicativo copiado para Public',
  'app.pages.applications.copyingAppToPublic': 'Copiando aplicativo a Public',
  'app.pages.applications.setThumbnail': 'Definir miniatura',
  'app.pages.applications.replaceThumbnail': 'Substituir miniatura',
  'app.pages.applications.removeThumbnail': 'Remover miniatura',
  'app.pages.applications.removeThumbnailMessage':
    'Voce tem certeza de que deseja remover a miniatura?',
  'app.pages.applications.thumbnailRemoved': 'Miniatura removida',
  'app.pages.applications.thumbnailUploaded': 'Miniatura enviada',
  'app.pages.applications.loadingAppMessage': 'Carregando aplicativo',

  //Application Summary
  'app.pages.applications.id': 'Identificador do aplicativo',
  'app.pages.applications.version': 'Versão do aplicativo',
  'app.pages.applications.uri': 'Ubicação',
  'app.pages.applications.isPublic': 'Está na pasta pública',
  'app.pages.applications.canwrite': 'Tem permissão para escrever',
  'app.pages.applications.venvPath': 'Caminho do ambiente virtual',

  //Icon selector
  'app.pages.iconSelector.iconType': 'Tipo de ícono',
  'app.pages.iconSelector.iconName': 'Icono',
  'app.pages.iconSelector.solid': 'Sólido',
  'app.pages.iconSelector.regular': 'Regular',

  //Versions
  'app.pages.versions.versionManager': 'Administrador de versões',
  'app.pages.versions.deleteVersionTitle': 'Apagar versão',
  'app.pages.versions.deleteVersionMessage': 'Você tem certeza de apagar a versão {version}?',
  'app.pages.versions.deleteVersionsMessage': 'Você tem certeza de apagar as versões {versions}?',
  'app.pages.versions.versionDeleted': 'A versão "{version}" foi apagada',
  'app.pages.versions.versionsDeleted': 'As versões "{versions}" foram apagadas',
  'app.pages.versions.deletingVersion': 'Apagando versão',
  'app.pages.versions.createVersion': 'Criar versão',
  'app.pages.versions.fromVersion': 'Versão original',
  'app.pages.versions.versionCreated': 'A versão "{version}" foi criada com sucesso',
  'app.pages.versions.creationDate': 'Data de criação',
  'app.pages.versions.closingDate': 'Data de fechamento',
  'app.pages.versions.archiveDate': 'Data de arquivamento',
  'app.pages.versions.status.active': 'Ativa',
  'app.pages.versions.status.closed': 'Fechada',
  'app.pages.versions.status.archived': 'Arquivada',
  'app.pages.versions.status.unarchived': 'Desarquivada',
  'app.pages.versions.setAsDefaultVersion': 'Definir como versão padrão',
  'app.pages.versions.isDefaultAppVersion': 'É a versão padrão do aplicativo',
  'app.pages.versions.setAsDefaultVersionMessage':
    'Você tem certeza de definir a versão "{version}" como a versão padrão do aplicativo?',
  'app.pages.versions.defaultVersionChanged': 'A versão padrão foi alterada',
  'app.pages.versions.editProperties': 'Editar propriedades',
  'app.pages.versions.cannotDeleteReason':
    'Não é possível apagar a versão do aplicativo aberta atual',
  'app.pages.versions.versionModified': 'A versão {version} foi alterada com sucesso',
  'app.pages.versions.versionsModified': 'As versões {versions} foram alteradas com sucesso',
  'app.pages.versions.cannotModifyNameReason':
    'Não é possível alterar o nome da versão do aplicativo aberta atual',
  'app.pages.versions.updateVersionStatus': 'Atualizar estado da versão',
  'app.pages.versions.updateVersionStatusMessage':
    'Você tem certeza de alterar o estado da versão {version} a "{newStatus}"?',
  'app.pages.versions.updateVersionsStatusMessage':
    'Você tem certeza de alterar o estado das versões {versions} a "{newStatus}"?',
  'app.pages.versions.importVersions': 'Importar versões',
  'app.pages.versions.exportVersions': 'Exportar versões',
  'app.pages.versions.cannotChangeReason':
    'Não é possível alterar a versão do aplicativo aberta atual',
  'app.pages.versions.versionsImported': 'As versões enviadas foram importadas com sucesso',
  'app.pages.versions.uploadInstructions':
    'Fazer upload de um arquivo com extensão .zip que contenha a pasta de cada versão que deseja importar',
  'app.pages.versions.openAppVersion': 'Abrir versão do aplicativo',
  'app.pages.versions.openAppVersionOnNewInstance':
    'Abrir versão do aplicativo em uma nova instância',
  'app.pages.versions.excludeArchivedVersions': 'Excluir versões arquivadas',
  'app.pages.versions.versionSelectedScenariosUpdated':
    'Os cenários selecionados da versão foram atualizados com sucesso',
  'app.pages.versions.openAfterCreating': 'Abrir depois de criar',
  'app.pages.versions.unsavedChangesMessage':
    'Qualquer alteração não salva da versão aberta será perdida',
  'app.pages.versions.versionExported': 'Versão exportada',
  'app.pages.versions.versionsExported': 'Versões exportadas',
  'app.pages.versions.nameExists': 'Nome da versão já existe',
  'app.pages.versions.openExistingVersion': 'Abrir versão existente',
  'app.pages.versions.createNewVersion': 'Criar versão nova',
  'app.pages.versions.createNewVersionInMyWorkspace': 'Criar nova versão no meu espaço de trabalho',
  'app.pages.versions.createNewVersionInMyWorkspaceConfirmation':
    'Você não tem permissões de escrita no diretório do aplicativo selecionado. O aplicativo será copiado para seu espaço de trabalho (se não existir) e a nova versão será criada lá.',
  'app.pages.versions.newVersionName': 'Nome de nova versão',

  //Process manager
  'app.pages.app.processManager.addProcess': 'Criar processo',
  'app.pages.app.processManager.editProcess': 'Atualizar processo',
  'app.pages.app.processManager.duplicateProcess': 'Duplicar o processo',
  'app.pages.app.processManager.showDescription': 'Mostre a descrição',
  'app.pages.app.processManager.noDescriptionText': 'Não há uma descrição',
  'app.pages.app.processManager.processTodo': 'Em andamento',
  'app.pages.app.processManager.process': 'Processo',
  'app.pages.app.processManager.subscribers': 'Assinantes',
  'app.pages.app.processManager.status': 'Status',
  'app.pages.app.processManager.description': 'Descrição',
  'app.pages.app.processManager.group': 'grupo',
  'app.pages.app.processManager.task': 'Tarefa',
  'app.pages.app.processManager.taskResponsible': 'Responsável pela tarefa',
  'app.pages.app.processManager.taskActionType': 'Tipo de acão',
  'app.pages.app.processManager.taskActionData': 'Dados de ação',
  'app.pages.app.processManager.isBlockedBy': 'Está bloqueada por',
  'app.pages.app.processManager.expiration': 'Expiração',
  'app.pages.app.processManager.expirationType': 'Tipo de expiração',
  'app.pages.app.processManager.sinceProcess': 'desde que o processo foi iniciado',
  'app.pages.app.processManager.sinceBlockingTaskCompleted':
    'desde que a tarefa bloqueante foi concluída',
  'app.pages.app.processManager.processCreated': 'Processo criado',
  'app.pages.app.processManager.processUpdated': 'Processo atualizado',
  'app.pages.app.processManager.processDuplicated': 'Processo duplicado',
  'app.pages.app.processManager.undefined': 'Indefinido',
  'app.pages.app.processManager.processDeleted': 'Processo deletado',
  'app.pages.app.processManager.processNotFound': 'Processo não encontrado',
  'app.pages.app.processManager.deleteProcess': 'Deletar processo',
  'app.pages.app.processManager.deleteProcessMessage':
    '¿Você tem certeza que deseja excluir este processo?',
  'app.pages.app.processManager.reviewers': 'Revisores',
  'app.pages.app.processManager.reviewerInterface': 'Interface de revisor',
  'app.pages.app.processManager.expirationDays': 'Dias de validade',
  'app.pages.app.processManager.addTask': 'Criar tarefa',
  'app.pages.app.processManager.editTask': 'Editar tarefa',
  'app.pages.app.processManager.taskStatus.not_ready_to_start': 'Não pronta para começar',
  'app.pages.app.processManager.taskStatus.not_started': 'Não iniciada',
  'app.pages.app.processManager.taskStatus.in_progress': 'Em andamento',
  'app.pages.app.processManager.taskStatus.pending_review': 'Pendente de revisão',
  'app.pages.app.processManager.taskStatus.completed': 'Concluída',

  //Scenarios
  'app.pages.scenarios.scenarioManager': 'Administrador de cenários',
  'app.pages.scenarios.deleteScenarioTitle': 'Apagar cenário',
  'app.pages.scenarios.deleteScenarioMessage': 'Você tem certeza de apagar o cenário "{scenario}"?',
  'app.pages.scenarios.deleteScenariosMessage':
    'Você tem certeza de apagar os cenários "{scenarios}"?',
  'app.pages.scenarios.scenarioDeleted': 'O cenário "{scenario}" foi apagado',
  'app.pages.scenarios.scenariosDeleted': 'Os cenários "{scenarios}" foram apagados',
  'app.pages.scenarios.deletingScenario': 'Apagando cenário',
  'app.pages.scenarios.createScenario': 'Criar cenário',
  'app.pages.scenarios.fromScenario': 'Desde cenário',
  'app.pages.scenarios.scenarioCreated': 'O cenário "{scenario}" foi criado com sucesso',
  'app.pages.scenarios.lastModifiedDate': 'Última data de modificação',
  'app.pages.scenarios.editProperties': 'Editar propriedades',
  'app.pages.scenarios.scenarioModified': 'O cenário "{scenario}" foi alterado com sucesso',
  'app.pages.scenarios.importScenarios': 'Importar cenários',
  'app.pages.scenarios.exportScenario': 'Exportar cenários',
  'app.pages.scenarios.scenariosImported': 'Os cenários enviados foram importados com sucesso',
  'app.pages.scenarios.uploadInstructions':
    'Fazer upload de um arquivo com extensão .zip que contenha a pasta de cada versão (com uma pasta por cada cenário dentro) que deseja importar',
  'app.pages.scenarios.alias': 'Alias',
  'app.pages.scenarios.includedNodes': 'Nós incluídos',
  'app.pages.scenarios.includeNodesFrom': 'Incluir nós desde',
  'app.pages.scenarios.currentInterface': 'Interface atual',
  'app.pages.scenarios.nodesToBeEvaluatedBeforehand': 'Nós a serem avaliados previamente',
  'app.pages.scenarios.runScenarioAfterSave': 'Executar cenário após salvar',
  'app.pages.scenarios.addNode': 'Adicionar nó',
  'app.pages.scenarios.showCurrentVersionOnly': 'Mostrar só a versão atual',
  'app.pages.scenarios.scenarioAlreadyExists':
    'Ja existe um cenário com este nome na versão "{version}"',
  'app.pages.scenarios.scenarioDuplicated': 'O cenário "{scenario}" foi duplicado com sucesso',
  'app.pages.scenarios.duplicateScenario': 'Duplicar cenário',
  'app.pages.scenarios.saveScenario': 'Salvar cenário',
  'app.pages.scenarios.cannotSaveScenarioReason':
    'Não é possível salvar cenários em uma versão não ativa',
  'app.pages.scenarios.cannotChangeScenarioReason':
    'Não é possível alterar cenários em uma versão não ativa',
  'app.pages.scenarios.existingScenario': 'Cenário existente',
  'app.pages.scenarios.createNewScenario': 'Criar novo cenário',
  'app.pages.scenarios.baseScenario': 'Cenário base',
  'app.pages.scenarios.scenarioSaved': 'O cenário "{scenario}" foi salvado com sucesso',
  'app.pages.scenarios.noNodesMessage':
    'O cenário escolhido não inclui nenhum nó para salvar. Edite este cenário no Administrador de Cenários para adicioná-los.',
  'app.pages.scenarios.selectScenarios': 'Selecionar cenários',
  'app.pages.scenarios.selectedScenarios': 'Cenários selecionados',
  'app.pages.scenarios.scenarioExported': 'Cenário exportado com sucesso',
  'app.pages.scenarios.scenariosExported': 'Cenários exportados com sucesso',
  'app.pages.scenarios.scenariosManager': 'Administrador de cenários',
  'app.pages.scenarios.scenariosTemplates': 'Modelos de cenários',
  'app.pages.scenarios.createTemplate': 'Criar modelo',
  'app.pages.scenarios.editTemplate': 'Editar modelo',
  'app.pages.scenarios.duplicateTemplate': 'Duplicar modelo',
  'app.pages.scenarios.deleteTemplate': 'Apagar modelo',
  'app.pages.scenarios.scenarioTemplateSuccessfullyCreated': 'Modelo de cenário criado com sucesso',
  'app.pages.scenarios.scenarioTemplateSuccessfullyModified':
    'Modelo de cenário {template} alterado com sucesso',
  'app.pages.scenarios.deleteScenarioTemplateTitle': 'Apagar modelo de cenário',
  'app.pages.scenarios.deleteScenarioTemplateMessage':
    'Você tem certeza de apagar o modelo de cenário "{scenarioTemplate}"?',
  'app.pages.scenarios.scenarioTemplateSuccessfullyDuplicated':
    'Modelo de cenário {scenarioTemplate} duplicado com sucesso',
  'app.pages.scenarios.scenarioTemplateNameAlreadyExists':
    'Já existe um modelo de cenário com este nome na versão {version}',
  'app.pages.scenarios.templateName': 'Nome do modelo',
  'app.pages.scenarios.scenarioNodesTemplate': 'Modelo de nós de cenário',
  'app.pages.scenarios.noOptionsTemplate': 'Não há opções de modelo',
  'app.pages.scenarios.addNewScenarioNodesTemplate': 'Adicionar novo modelo de nós de cenário',
  'app.pages.scenarios.destinationVersion': 'Versão de destino',
  'app.pages.scenarios.updateSelectedScenarios': 'Atualizar cenários selecionados',

  //My tasks
  'app.pages.app.myTasks.title': 'Minhas tarefas',
  'app.pages.app.myTasks.statusChanged': 'Estado alterado',
  'app.pages.app.myTasks.showComments': 'Mostrar comentários',
  'app.pages.app.myTasks.responsible': 'Responsável',
  'app.pages.app.myTasks.deadline': 'Prazo final',
  'app.pages.app.myTasks.finished': 'Finalizada',
  'app.pages.app.myTasks.commentDeleted': 'Comentário deletado',
  'app.pages.app.myTasks.comments': 'Comentários',
  'app.pages.app.myTasks.deleteComment': 'Excluir comentário',
  'app.pages.app.myTasks.addComment': 'Adicionar comentário',
  'app.pages.app.myTasks.noAssignedTasks': 'Nenhuma tarefa atribuída',

  //General settings
  'app.pages.generalSettings.assistantBotsSettings': 'Configurações do assistentes',
  'app.pages.generalSettings.logsSettings': 'Configurações de registros',
  'app.pages.generalSettings.initialPrompt': 'Prompt inicial',
  'app.pages.generalSettings.customValue': 'Valor customizado',
  'app.pages.generalSettings.customValueRemoved': 'Valor customizado removido',
  'app.pages.generalSettings.customValueSaved': 'Valor customizado salvo com sucesso',
  'app.pages.generalSettings.paramsPlaceholder': 'Insira os parâmetros no formato json',
  'app.pages.generalSettings.applicationAssistantSuggestedInstructions':
    'Instruções (prompt de sistema) sugeridas para assistentes do aplicativo - {engineType}',

  //App Configuration
  'app.pages.app.appConfiguration': 'Configuração do aplicativo',
  'app.pages.app.installLibrariesOnOpen': 'Instalar bibliotecas ao iniciar',
  'app.pages.app.openDefaultVersionOnOpen': 'Abrir a versão padrão ao iniciar',
  'app.pages.app.defaultTabOnOpen': 'Janela padrão ao abrir',

  //External links
  'app.pages.externalLink.deleteMessage': 'Tem certeza de que deseja excluir o link externo?',
  'app.pages.externalLink.linkDeleted': 'Link externo removido',
  'app.pages.externalLink.deleteTitle': 'Remover link externo',
  'app.pages.externalLink.nodeId': 'Id do nó',
  'app.pages.externalLink.commonInstance': 'Instância comum',
  'app.pages.externalLink.apiEndpoint': 'API URL',
  'app.pages.externalLink.linkUpdated': 'Link externo atualizado',
  'app.pages.externalLink.customEndpoint': 'Endpoint personalizado',
  'app.pages.externalLink.customEndpointError': 'Endpoint personalizado já existe',
  'app.pages.externalLink.externalLinksNotFound': 'Não há links externos',
  'app.pages.externalLink.selectApiKey': 'Selecione uma chave Api',
  'app.pages.externalLink.executor': 'Executor',

  //Api keys manager
  'app.pages.apiKeysManager.alias': 'Pseudônimo',
  'app.pages.apiKeysManager.key': 'Chave',
  'app.pages.apiKeysManager.active': 'Ativa',
  'app.pages.apiKeysManager.lastKeyChange': 'Última mudança de chave',
  'app.pages.apiKeysManager.showApiKeys': 'Mostrar chaves Api',
  'app.pages.apiKeysManager.createApiKeys': 'Criar chave Api',
  'app.pages.apiKeysManager.editApiKeys': 'Editar chave Api',
  'app.pages.apiKeysManager.deleteApiKeys': 'Excluir chave Api',
  'app.pages.apiKeysManager.noApiKeys': 'Não há chaves Api',
  'app.pages.apiKeysManager.apiKey': 'Chave Api',
  'app.pages.apiKeysManager.deleteApiKeyMessage':
    'Você tem certeza de que deseja excluir a chave Api?',
  'app.pages.apiKeysManager.apiKeyDeleted': 'Chave Api removida',
  'app.pages.apiKeysManager.apiKeyUpdated': 'Chave Api atualizada',
  'app.pages.apiKeysManager.apiKeyCreated': 'Chave Api criada',
  'app.pages.apiKeysManager.keyCopied': 'Chave copiada',
  'app.pages.apiKeysManager.saveKeyAlert':
    '¡Atenção! A chave Api estará visível apenas nesta ocasião. Certifique-se de copiá-la agora mesmo',
  'app.pages.apiKeysManager.keyId': 'Id da chave',

  //App consolidation
  'app.pages.appConsolidation.sourceAppVersion': 'Versão do aplicativo de origem',
  'app.pages.appConsolidation.differences': 'Diferenças',
  'app.pages.appConsolidation.summary': 'Resumo',
  'app.pages.appConsolidation.businessRules': 'Regras do negócio',
  'app.pages.appConsolidation.includeAllModules': 'Incluir todos',
  'app.pages.appConsolidation.includeAllInterfaces': 'Incluir todas',
  'app.pages.appConsolidation.replaceMatchingNodes':
    'Substituir os nós coincidentes fora destes módulos',
  'app.pages.appConsolidation.versionMetadata': 'Metadados da versão',
  'app.pages.appConsolidation.itemAdded': '{name} adicionada/o',
  'app.pages.appConsolidation.itemDeleted': '{name} deletada/o',
  'app.pages.appConsolidation.itemEdited': '{name} editada/o',
  'app.pages.appConsolidation.itemsAdded': '{name} adicionadas/os',
  'app.pages.appConsolidation.itemsDeleted': '{name} deletadas/os',
  'app.pages.appConsolidation.itemsEdited': '{name} editadas/os',
  'app.pages.appConsolidation.noChanges': 'Sem mudanças',
  'app.pages.appConsolidation.confirmationDialogTitle': 'Confirmar alterações de consolidação',
  'app.pages.appConsolidation.confirmationDialogText1':
    'Tem certeza de que deseja confirmar as alterações?',
  'app.pages.appConsolidation.confirmationDialogText2':
    'As alterações aplicadas aos arquivos e metadados da versão são irreversíveis.',
  'app.pages.appConsolidation.compareDefinitionOnly': 'Comparar somente a definição',

  //Office addin
  'app.pages.office.selectNode': 'Escolha um nó',

  //Notifications
  'app.pages.notifications.scheduler.taskFinished':
    'A tarefa {match1} finalizou com o estado {match2}',
  'app.pages.notifications.workflow.taskReady':
    'A tarefa {match1} do processo {match2} está pronta para começar',
  'app.pages.notifications.workflow.taskOverdueExtended':
    'A tarefa {match1} do processo {match2} está atrasada. Isso pode afetar a conclusão da sua tarefa {match3}',
  'app.pages.notifications.workflow.taskOverdue':
    'A tarefa {match1} do processo {match2} está atrasada',
  'app.pages.notifications.workflow.taskChangedStatus':
    'A tarefa {match1} do processo {match2} teve seu status alterado de {match3} para {match4}',
  'app.pages.notifications.workflow.processStarted': 'O processo {match1} foi iniciado',
  'app.pages.notifications.workflow.processUpToDateAgain':
    'O processo {match1} está novamente atualizado.',
  'app.pages.notifications.workflow.wroteComment':
    '{match1} escreveu o seguinte comentário na tarefa {match2} do processo {match3}: {match4}',
  'app.pages.notifications.title': 'Notificações',
  'app.pages.notifications.allNotifications': 'Todas',
  'app.pages.notifications.unReadNotifications': 'Não lida',
  'app.pages.notifications.noNotifications': 'Sem notificações',
  'app.pages.notifications.noUnreadNotifications': 'Sem notificações não lidas',
  'app.pages.notifications.deleteNotifications': 'Excluir notificação',
  'app.pages.notifications.markAsRead': 'Marcar como Lida',
  'app.pages.notifications.maskAsUnread': 'Marcar como não lida',
  'app.pages.notifications.notificationDeleted': 'Notificação excluída',
  'app.pages.notifications.markAllAsRead': 'Marcar tudo como lido',

  // Assistant bots
  'app.pages.assistantBots.send': 'Enviar',
  'app.pages.assistantBots.clearMessages': 'Apagar mensagens',
  'app.pages.assistantBots.copiedCode': 'Codigo copiado',
  'app.pages.assistantBots.updateCurrentNode': 'Colar em nó selecionado',
  'app.pages.assistantBots.askAgain': 'Perguntar novamente',
  'app.pages.assistantBots.showPrivacyStatement': 'Política de privacidade',
  'app.pages.assistantBots.chatSuccessfullyExported': 'Chat exportado com sucesso',
  'app.pages.assistantBots.ask': 'Perguntar',
  'app.pages.assistantBots.askAboutCoding': 'Pergunte sobre codificação',
  'app.pages.assistantBots.assistantBotsManager': 'Administrador de assistentes',
  'app.pages.assistantBots.instructionsSystemPrompt': 'Instruções (prompt de sistema)',
  'app.pages.assistantBots.AIFramework': 'Framework de IA',
  'app.pages.assistantBots.llmModel': 'Modelo de LLM',
  'app.pages.assistantBots.filesInDocsFolder': 'Arquivos na pasta de documentos',
  'app.pages.assistantBots.maxFileSizeWarning': 'O tamanho máximo de um arquivo é de {value}',
  'app.pages.assistantBots.maxFilesCount':
    'O número máximo de arquivos a serem carregados é {value}',
  'app.pages.assistantBots.advancedSettings': 'Configurações avançadas',
  'app.pages.assistantBots.createAssistant': 'Criar assistente',
  'app.pages.assistantBots.createNewAssistant': 'Criar novo assistente',
  'app.pages.assistantBots.updateAssistant': 'Atualizar assistente',
  'app.pages.assistantBots.assistantId': 'ID do assistente',
  'app.pages.assistantBots.updateAssistantWithCurrentFilesInFolder':
    'Atualizar assistente com os arquivos na pasta de documentação atual',
  'app.pages.assistantBots.addNodesInfoFile': 'Adicionar arquivo com informações de nós',
  'app.pages.assistantBots.addInterfacesInfoFile':
    'Adicionar arquivo com informações de interfaces',
  'app.pages.assistantBots.assistantSuccessfullyCreated':
    'O assistente {value} foi criado com sucesso',
  'app.pages.assistantBots.assistantSuccessfullyUpdated':
    'O assistente {value} foi atualizado com sucesso',
  'app.pages.assistantBots.selectAssistantBot': 'Selecionar assistente',
  'app.pages.assistantBots.setAsDefaultAppAssistantBot': 'Definir como assistente padrão',
  'app.pages.assistantBots.isDefaultAssistantBot': '"É o assistente padrão do aplicativo',
  'app.pages.assistantBots.activateAssistantBot': 'Ativar assistente',
  'app.pages.assistantBots.deactivateAssistantBot': 'Desactivar assistente',
  'app.pages.assistantBots.active': 'Ativo',
  'app.pages.assistantBots.deleteAssistantBotTitle': 'Apagar assistente',
  'app.pages.assistantBots.botAssistantDeleted': 'O assistente "{name}" foi apagado',
  'app.pages.assistantBots.botAssistantsDeleted': 'Os assistentes "{names}" foram apagados',
  'app.pages.assistantBots.deleteAssistantBotMessage':
    'Você tem certeza de apagar o assistente {name}?',
  'app.pages.assistantBots.deleteAssistantBotsMessage':
    'Você tem certeza de apagar os assistentes {names}?',
  'app.pages.assistantBots.selectedAssistantBotChanged': 'O assistente foi selecionado',
  'app.pages.assistantBots.selectAssistantBotMessage':
    'Você tem certeza de selecionar o assistente "{name}" como o assistente padrão do aplicativo?',
  'app.pages.assistantBots.activateAssistantBotMessage':
    'Você tem certeza de {action} o assistente "{name}"?',
  'app.pages.assistantBots.manageDocumentationFiles': 'Administrar arquivos de documentação',
  'app.pages.assistantBots.addFiles': 'Adicionar arquivos',
  'app.pages.assistantBots.deleteSelectedFiles': 'Apagar arquivos selecionados',
  'app.pages.assistantBots.uploadFiles': 'Carregar arquivos',
  'app.pages.assistantBots.initialAssistantMessage': 'Sobre qual tema você precisa de ajuda?',
  'app.pages.assistantBots.howCanIHelpYou': 'Como posso ajudar você?',
  'app.pages.assistantBots.testAssistant': 'Assistente de teste',
  'app.pages.assistantBots.testAssistantAction': 'Testar assistente',
  'app.pages.assistantBots.openAIAssistant': 'Assistente de OpenAI',
  'app.pages.assistantBots.haystack': 'Haystack',
  'app.pages.assistantBots.codeAssistants': 'Assistentes de codificação',
  'app.pages.assistantBots.codeAssistant': 'Mostrar/ocultar assistente de código',
  'app.pages.assistantBots.codeBugFixer': 'Verificador de erros de código',
  'app.pages.assistantBots.codeOptimizer': 'Otimizador de código',
  'app.pages.assistantBots.codeCompleter': 'Gerador de código',
  'app.pages.assistantBots.includeFiles': 'Incluir arquivos',
  'app.pages.assistantBots.newAssistant': 'Novo assistente',
  'app.pages.assistantBots.noAssistantSelected': 'Nenhum assistente selecionado',

  // Secrets manager
  'app.pages.secretsManager.secretManager': 'Administrador de segredos',
  'app.pages.secretsManager.createSecret': 'Criar segredo',
  'app.pages.secretsManager.editSecret': 'Editar segredo',
  'app.pages.secretsManager.deleteSecret': 'Excluir segredo',
  'app.pages.secretsManager.deleteSecretConfirmation': 'Você tem certeza de excluir o segredo?',
  'app.pages.secretsManager.secretDeleted': 'Segredo excluído',
  'app.pages.secretsManager.secretUpdated': 'Segredo atualizado',
  'app.pages.secretsManager.secretCreated': 'Segredo criado',
  'app.pages.secretsManager.secretKey': 'Chave do segredo',
  'app.pages.secretsManager.secretValue': 'Valor do segredo',
  'app.pages.secretsManager.noSecrets': 'Nenhum segredo',
  'app.pages.secretsManager.secret': 'Segredo',
  'app.pages.secretsManager.allUsers': 'Todos os usuários',
  'app.pages.secretsManager.showSecretValue': 'Mostrar valor do segredo',
  'app.pages.secretsManager.hideSecretValue': 'Ocultar valor do segredo',
  'app.pages.secretsManager.secretKeyExists': 'Chave do segredo já existe'
}

export default messages
