import { all, put, call, fork, takeLatest, select, take } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { Node, isNode, XYPosition, FlowElement } from 'react-flow-renderer'

import { modelApi } from 'services/model'

import { ApplicationState } from '..'
import {
  navigateDiagram,
  removeNodeFromUnsaved,
  selectNode,
  setElements,
  setModuleId,
  setSelectedCodeNode,
  updateElements,
  getNodeResult,
  setRunningNode,
  setNodePreview,
  setWizardData,
  unlockPropertiesDialog,
  refreshDiagram,
  forceSelectionOfNodes,
  triggerNodeInterface,
  runSelectedNode,
  setModuleNavigationHistory,
  openDataRead,
  openInputData,
  navigateToNode,
  setUpdateNodeResult,
  openWizard,
  setGoToNodeFromInterface,
  refreshIndex,
  setLoadingModule,
  setCenterNodeFromExternalPage,
  updateNodeError,
  systemNodeDefinitionHasChanged,
  setLoadingManipulateDataWidget,
  disableReDoAction
} from './actions'
import { ModelActionTypes } from './types'
import { setCodeDivider, setSelectedCodeLayout, setWidgetExpanded, UserActionTypes } from '../user'
import { SELECTED_CODE_LAYOUT } from 'common/api/storageConsts'
import { snapToGrid } from 'common/helpers/snapToGrid'
import { enqueue } from 'common/notifier'
import { CodeDividers, Layout, WidgetType } from 'common/types/code'
import {
  CodeNode,
  DefaultWByNodeType,
  DefaultHByNodeType,
  DiagramNode,
  ModuleInfo,
  NodePositionAndSize,
  NodePositionWModule,
  NodeClass,
  SelectColumnsWizard,
  CopyNodesParams,
  NodeInputs,
  CreateAliasResponse,
  WizardTypes,
  NodePropertiesResponse,
  WizardActions
} from 'common/types/model'
import { InterfaceEvent, InterfaceEventType } from 'pages/interfaces/containers/types'
import { Notification } from 'store/notifier'
import { CODE_NODE_PROPERTIES_TO_GET, HANDLE_DATA_WIZARD } from 'common/helpers/commonConstants'
import { setWaiting } from 'store/application'

const NODECLASSES_EXCLUDED_FOR_CODE: NodeClass[] = ['module', 'text']

function* handleInstallLibraries({ payload }: AnyAction) {
  try {
    yield call(modelApi.installLibraries, payload)
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleNavigateDiagram({
  payload: { moduleId, isStatic, cleanSelectedComponent }
}: AnyAction) {
  try {
    yield put(setLoadingModule(true))
    const moduleInfo: ModuleInfo = yield call(modelApi.navigateDiagram, moduleId)
    const moduleNavigationHistory = ((state: ApplicationState) =>
      state.app.model.diagram?.moduleNavigationHistory)(yield select())
    yield put(setElements(moduleInfo, isStatic))
    if (cleanSelectedComponent) {
      yield put(selectNode(undefined))
    }
    if (
      moduleInfo.moduleId &&
      (!moduleNavigationHistory ||
        moduleNavigationHistory[moduleNavigationHistory?.length - 1] !== moduleInfo.moduleId)
    ) {
      yield put(setModuleId(moduleInfo.moduleId))
      const newModuleNavigationHistory = moduleNavigationHistory
        ? [...moduleNavigationHistory, moduleInfo.moduleId]
        : [moduleInfo.moduleId]
      yield put(setModuleNavigationHistory(newModuleNavigationHistory))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setLoadingModule(false))
  }
}

function* handleRefreshDiagram() {
  const moduleId = ((state: ApplicationState) => state.app.model.diagram?.moduleId)(yield select())
  if (moduleId) {
    try {
      const moduleInfo: ModuleInfo = yield call(modelApi.navigateDiagram, moduleId)
      yield put(setElements(moduleInfo))
    } catch (error: any) {
      yield put(enqueue(error))
    }
  }
}

function* handleDeleteNodes({ payload }: AnyAction) {
  try {
    if (payload) {
      const nodesToDelete: string[] = payload
        .filter((el: Node) => isNode(el))
        .map((nd: Node) => nd.id)
      const res: boolean = yield call(modelApi.deleteNodes, nodesToDelete)
      if (res) {
        for (let nodeId of nodesToDelete) {
          yield put(updateNodeError({ nodeId }))
        }
        yield put(refreshDiagram())
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleDeleteNodesFromIds({ payload }: AnyAction) {
  try {
    if (payload) {
      const res: string[] = yield call(modelApi.deleteNodes, payload)
      if (res) {
        yield put(refreshDiagram())
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCreateNode({
  payload: { type, positionX, positionY, diagramPosition }
}: AnyAction) {
  try {
    const moduleId = ((state: ApplicationState) => state.app.model.diagram?.moduleId)(
      yield select()
    )
    if (moduleId) {
      const responseData: DiagramNode = yield call(
        modelApi.createNode,
        moduleId,
        snapToGrid(positionX),
        snapToGrid(positionY),
        type
      )
      const id = responseData.identifier
      if (id) {
        const newNode: Node = {
          id,
          type,
          position: {
            x: snapToGrid(diagramPosition.x),
            y: snapToGrid(diagramPosition.y)
          },
          data: {
            ...responseData,
            id,
            h: DefaultHByNodeType[type],
            w: DefaultWByNodeType[type],
            x: snapToGrid(diagramPosition.x),
            y: snapToGrid(diagramPosition.y),
            title: id,
            inputTitle: true
          },
          style: {
            height: DefaultHByNodeType[type],
            width: DefaultWByNodeType[type]
          }
        }
        const elements =
          ((state: ApplicationState) => state.app.model.diagram?.elements)(yield select()) || []
        yield put(updateElements(elements.concat(newNode)))
        yield put(selectNode(id))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCompleteCreateNode({ payload: { nodeId, properties, typeWizard } }: AnyAction) {
  try {
    const res: string = yield call(modelApi.setNodeProperties, nodeId, properties)
    if (res) {
      const newId: string = yield call(modelApi.setNodeIdFromTitle, nodeId)
      if (newId) {
        const elements =
          ((state: ApplicationState) => state.app.model.diagram?.elements)(yield select()) || []

        const newTitle = properties.find(
          (prop: { name: string; value: any }) => prop.name === 'title'
        )?.value

        const newElements = elements.map((el) => {
          if (el.id === nodeId && isNode(el)) {
            return {
              ...el,
              id: newId,
              data: {
                ...el.data,
                title: newTitle,
                id: newId
              }
            }
          }
          return el
        })
        yield put(updateElements(newElements))
        if (newTitle || typeWizard === WizardTypes.InputScalar) {
          yield put(selectNode(newId))
        }
      }
      if (typeWizard === WizardTypes.DataReadWizard) {
        yield put(openDataRead(true))
      } else if (typeWizard === WizardTypes.CreateIndex) {
        yield put(
          openWizard({
            nodeId: newId,
            wizardType: WizardTypes.CreateIndex,
            action: WizardActions.GetIndexItems
          })
        )
      } else if (typeWizard === WizardTypes.Report) {
        yield put(
          openWizard({
            nodeId: newId,
            wizardType: WizardTypes.Report,
            action: WizardActions.GetNodeSchema
          })
        )
      } else if (typeWizard !== '') {
        yield put(openInputData(true))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}
function* handleSelectNode({ payload: { nodeId, skipGetNodeResult } }: AnyAction) {
  if (nodeId) {
    try {
      const unconfirmedNode = ((state: ApplicationState) =>
        state.app.model.diagram?.unconfirmedNodes)(yield select())?.find(
        (node) => node.identifier === nodeId
      )
      /**
       * If the node has pending changes to be confirmed
       * it will retrieve that object,
       * instead of calling the API.
       * */
      if (unconfirmedNode?.identifier) {
        yield put(setSelectedCodeNode(unconfirmedNode))
      } else {
        const codeNode: CodeNode = yield call(
          modelApi.getCodeNodeProperties,
          nodeId,
          CODE_NODE_PROPERTIES_TO_GET
        )
        yield put(setSelectedCodeNode(codeNode))
      }
      if (!skipGetNodeResult) {
        yield put(getNodeResult(true))
      }
    } catch (error: any) {
      yield put(enqueue(error))
    }
  } else {
    yield put(setSelectedCodeNode(undefined))
  }
}

function* handleMoveNodes({ payload }: AnyAction) {
  try {
    if (payload) {
      const nodePositionDataArr: NodePositionWModule[] = payload
        .filter((el: Node) => isNode(el))
        .map((node: Node) => {
          return {
            id: node.id,
            x: snapToGrid(node.position.x),
            y: snapToGrid(node.position.y),
            w: node.data.w,
            h: node.data.h,
            moduleId: null
          }
        })
      const res: boolean = yield call(modelApi.setNodesPosition, nodePositionDataArr)
      if (res) {
        const elements =
          ((state: ApplicationState) => state.app.model.diagram?.elements)(yield select()) || []

        const newElements = elements.map((el) => {
          if (isNode(el) && payload.map((nd: Node) => nd.id).includes(el.id)) {
            const currentNodePosition: XYPosition = payload.find((nd: Node) => nd.id === el.id)
              .position
            return {
              ...el,
              data: {
                ...el.data,
                x: snapToGrid(currentNodePosition.x),
                y: snapToGrid(currentNodePosition.y)
              },
              position: {
                x: snapToGrid(currentNodePosition.x),
                y: snapToGrid(currentNodePosition.y)
              }
            }
          }
          return el
        })
        yield put(updateElements(newElements))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleResizeNodes({
  payload: { newWidth, newHeight, nodesToResize, direction, delta, nodeDragged }
}: AnyAction) {
  try {
    // if (['bottom', 'bottomRight', 'right'].includes(direction)) {
    const nodesSizePositionArr: NodePositionAndSize[] = nodesToResize.map((nd: Node) => {
      return {
        id: nd.id,
        x: nd.position.x,
        y: nd.position.y,
        h: newHeight,
        w: newWidth
      }
    })
    const res: boolean = yield call(modelApi.setNodesSize, nodesSizePositionArr)
    if (res) {
      const elements =
        ((state: ApplicationState) => state.app.model.diagram?.elements)(yield select()) || []
      const newElements = elements.map((el) => {
        if (isNode(el) && nodesToResize.map((nd: Node) => nd.id).includes(el.id)) {
          return {
            ...el,
            data: {
              ...el.data,
              w: newWidth,
              h: newHeight
            },
            style: {
              ...el.style,
              width: newWidth,
              height: newHeight
            }
          }
        }
        return el
      })
      yield put(updateElements(newElements))
      yield put(forceSelectionOfNodes(nodesToResize.map((nd: Node) => nd.id)))
    }
    // } TODO: handle resize and from left and top
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleGetNodeResult({ payload }: AnyAction) {
  try {
    const selectedNode = ((state: ApplicationState) => state.app.model.selectedNode)(yield select())
    if (selectedNode && selectedNode.codeNode) {
      const codeNode = selectedNode.codeNode

      const nodeClass = codeNode.nodeClass

      if (codeNode.isCalc && nodeClass && !NODECLASSES_EXCLUDED_FOR_CODE.includes(nodeClass)) {
        const selectedLayout = ((state: ApplicationState) => state.user.code?.selectedLayout)(
          yield select()
        )

        const nodeResult = ((state: ApplicationState) => selectedNode.result)(yield select())
        if (
          selectedLayout &&
          [Layout.DiagramAndResult, Layout.DiagramCodeAndResult].includes(selectedLayout) &&
          (!nodeResult || payload)
        ) {
          const nodeId = codeNode.identifier
          yield call(modelApi.createNodeResultInterface, nodeId)
        }
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleGetNodePreview() {
  try {
    const selectedNode = ((state: ApplicationState) => state.app.model.selectedNode)(yield select())
    if (selectedNode && selectedNode.codeNode) {
      const codeNode = selectedNode.codeNode
      const nodeClass = codeNode.nodeClass

      if (
        codeNode.isCalc &&
        nodeClass &&
        !NODECLASSES_EXCLUDED_FOR_CODE.includes(nodeClass) &&
        !codeNode.identifier.includes(HANDLE_DATA_WIZARD)
      ) {
        const nodeId = codeNode.identifier
        yield put(setWaiting(true))
        const res: string = yield call(modelApi.getNodePreview, nodeId)
        yield put(setNodePreview(res))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setWaiting(false))
  }
}

function* handleTriggerNodeInterface({ payload: { nodeId, triggerData } }: AnyAction) {
  try {
    yield call(modelApi.triggerNodeInterface, nodeId, triggerData)
    if (
      [
        InterfaceEventType.RemoveComponent,
        InterfaceEventType.CreateComponent,
        InterfaceEventType.ChangeComponent
      ].includes(triggerData.event_type)
    ) {
      const initialUpdate: InterfaceEvent = {
        event_type: InterfaceEventType.InitialUpdate,
        params: {
          component_id: triggerData.component_id
        }
      }
      yield put(triggerNodeInterface(nodeId, initialUpdate))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleSetSelectedCodeLayout({ payload }: AnyAction) {
  try {
    yield put(getNodeResult(payload.forceGetNodeResult))
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleConfirmCodeNode({ payload: { newDefinition } }: AnyAction) {
  try {
    const selectedNode = ((state: ApplicationState) => state.app.model.selectedNode)(yield select())
    if (selectedNode?.codeNode) {
      const properties: { name: string; value: any }[] = [
        {
          name: 'definition',
          value: newDefinition || selectedNode.codeNode.definition
        }
      ]
      yield call(modelApi.setNodeProperties, selectedNode.codeNode.identifier, properties)
      yield put(removeNodeFromUnsaved(selectedNode.codeNode.identifier))
      yield put(refreshDiagram())
      yield put(setUpdateNodeResult())
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCancelCodeNode({ payload }: AnyAction) {
  try {
    const selectedNode = ((state: ApplicationState) => state.app.model.selectedNode)(yield select())
    if (selectedNode?.codeNode) {
      yield put(removeNodeFromUnsaved(selectedNode.codeNode.identifier))
      yield put(selectNode(selectedNode.codeNode.identifier))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleRunSelectedNode() {
  try {
    let mustRefreshDiagram = false
    const selectedNode = ((state: ApplicationState) => state.app.model.selectedNode)(yield select())
    const codeNodeId = selectedNode?.codeNode?.identifier
    const prevIsCalc = selectedNode?.codeNode?.isCalc || false
    const unconfirmedNodes = ((state: ApplicationState) =>
      state.app.model.diagram?.unconfirmedNodes)(yield select())
    if (
      unconfirmedNodes?.some((node) => node.identifier === codeNodeId) &&
      selectedNode?.codeNode
    ) {
      yield put(setUpdateNodeResult())
      // Confirm nodes. Needs to be executed before running node
      const properties: { name: string; value: any }[] = [
        {
          name: 'definition',
          value: selectedNode.codeNode.definition
        }
      ]
      yield call(modelApi.setNodeProperties, selectedNode.codeNode.identifier, properties)
      yield put(removeNodeFromUnsaved(selectedNode.codeNode.identifier))
      mustRefreshDiagram = true
    }
    yield put(setRunningNode(selectedNode?.codeNode?.title || codeNodeId))
    if (codeNodeId) {
      yield call(modelApi.run, codeNodeId)
      const codeNode: CodeNode = yield call(
        modelApi.getCodeNodeProperties,
        codeNodeId,
        CODE_NODE_PROPERTIES_TO_GET
      )
      yield put(setRunningNode())
      if (codeNode?.identifier) {
        yield put(setSelectedCodeNode(codeNode))
      }
      if (prevIsCalc === false && codeNode?.isCalc) {
        mustRefreshDiagram = true
      }
      // Calling sagas function to force waiting for it to finish. Because getNodeResult creates a new node
      // and that changes model dictionary, throwing error while getting arrows
      yield* handleGetNodeResult({ type: ModelActionTypes.GET_NODE_RESULT, payload: true })
      if (mustRefreshDiagram) {
        yield put(refreshDiagram())
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleConfirmAndUpdateScalarNode() {
  try {
    const selectedNode = ((state: ApplicationState) => state.app.model.selectedNode)(yield select())
    const codeNodeId = selectedNode?.codeNode?.identifier
    yield put(setRunningNode(selectedNode?.codeNode?.title || codeNodeId))
    if (codeNodeId) {
      yield call(modelApi.run, codeNodeId)
      const codeNode: CodeNode = yield call(
        modelApi.getCodeNodeProperties,
        codeNodeId,
        CODE_NODE_PROPERTIES_TO_GET
      )
      yield put(setRunningNode())
      if (codeNode?.identifier) {
        yield put(setSelectedCodeNode(codeNode))
      }
      // Calling sagas function to force waiting for it to finish. Because getNodeResult creates a new node
      // and that changes model dictionary, throwing error while getting arrows
      yield* handleGetNodeResult({ type: ModelActionTypes.GET_NODE_RESULT, payload: true })
      yield put(refreshDiagram())
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleNavigateToNode({
  payload: { moduleId, nodeId, isStatic, changePanePositionFn }
}: AnyAction) {
  try {
    const currentModule = ((state: ApplicationState) => state.app.model.diagram?.moduleId)(
      yield select()
    )
    if (moduleId !== currentModule) {
      yield put(navigateDiagram(moduleId, isStatic))
      //forces to wait until navigateDiagram dispatchs its final step before selecting Node
      yield take(
        isStatic ? ModelActionTypes.SELECT_NODE : ModelActionTypes.SET_MODULE_NAVIGATION_HISTORY
      )
    }
    yield put(selectNode(nodeId))
    if (changePanePositionFn) {
      const elements =
        ((state: ApplicationState) => state.app.model.diagram?.elements)(yield select()) || []
      const node = elements.find((el) => el.id === nodeId && isNode(el)) as Node
      if (node) {
        changePanePositionFn(
          node.position.x + Number(node.style?.width) / 2,
          node.position.y + Number(node.style?.height) / 2
        )
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleSelectAndRunNode({
  payload: { nodeId, propertiesDefinition, overwriteDefinition }
}: AnyAction) {
  try {
    let identifier = nodeId
    if (overwriteDefinition) {
      if (propertiesDefinition) {
        yield call(modelApi.setNodeProperties, identifier, propertiesDefinition)
        yield put(setRunningNode(identifier))
        yield call(modelApi.run, identifier)
        yield put(setRunningNode())
        yield put(selectNode(identifier, false))
      } else {
        yield put(selectNode(identifier, false))
      }
    } else {
      const definition: string = propertiesDefinition?.find(
        (prop: any) => prop.name === 'definition'
      )?.value
      if (definition) {
        const newId: string = yield call(modelApi.createNodeFromCurrent, identifier, definition)
        if (newId) {
          identifier = newId
        }
      }
      yield put(setRunningNode(identifier))
      yield call(modelApi.run, identifier)
      yield put(setRunningNode())
      yield put(selectNode(identifier, false))
    }
    yield put(refreshDiagram())
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleOpenDiagramAndNavigateToNode({ payload: { nodeId, navigate } }: AnyAction) {
  try {
    const nodeProperties: NodePropertiesResponse = yield call(modelApi.getNodeProperties, nodeId, [
      { name: 'moduleId' }
    ])
    if (!!!nodeProperties.node) {
      yield put(setGoToNodeFromInterface(true))
      navigate(`/code`)
      yield put(
        enqueue({
          key: (new Date().getTime() + Math.random()).toString(),
          message: `Node '${nodeId}' does not exist`
        } as Notification)
      )
    } else {
      const moduleId = nodeProperties.properties[0].value
      //by setting the moduleId before going to /code we prevent double calls to navigateDiagram
      yield put(setGoToNodeFromInterface(true))
      yield put(setModuleId(moduleId))
      navigate(`/code`)
      //entering /code triggers a navigateDiagram so
      //by using yield take(...) we ensure it finishes before selecting node
      yield take(ModelActionTypes.SELECT_NODE)
      yield put(selectNode(nodeId))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleOpenDiagramAndNavigateToNodeFromGoTo({
  payload: { nodeId, navigate, codeLayoutId, runNode }
}: AnyAction) {
  try {
    yield* handleOpenDiagramAndNavigateToNode({
      type: ModelActionTypes.OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE,
      payload: { nodeId, navigate }
    })
    yield put(setCenterNodeFromExternalPage(nodeId))
    if (codeLayoutId) {
      // Set code layout
      let codeLayoutIdNumber = Number(codeLayoutId)
      switch (codeLayoutIdNumber) {
        case 1:
          yield put(setCodeDivider(CodeDividers.DOCS_DIVIDER, 20))
          yield put(setCodeDivider(CodeDividers.RESULT_DIVIDER, 80))
          break
        case 2:
          yield put(setCodeDivider(CodeDividers.CODE_DIVIDER, 100))
          break
        case 3:
          yield put(setCodeDivider(CodeDividers.RESULT_DIVIDER, 50))
          yield put(setCodeDivider(CodeDividers.CODE_DIVIDER, 50))
          break
        default:
          yield put(setCodeDivider(CodeDividers.DOCS_DIVIDER, 20))
          yield put(setCodeDivider(CodeDividers.RESULT_DIVIDER, 80))
          codeLayoutIdNumber = 1
          break
      }
      yield put(setSelectedCodeLayout(codeLayoutIdNumber, true))
      localStorage[SELECTED_CODE_LAYOUT] = codeLayoutIdNumber
    }
    if (['true', 'True'].includes(runNode)) {
      yield* handleSelectNode({
        type: ModelActionTypes.SELECT_NODE,
        payload: { nodeId, skipGetNodeResult: false }
      })
      yield* handleRunSelectedNode()
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

interface NodeProperty {
  name: string
  value: any
}

function* handleSetNodeProperties({ payload: { nodeId, properties, type } }: AnyAction) {
  try {
    const res: boolean = yield call(modelApi.setNodeProperties, nodeId, properties)
    if (res) {
      let newId: string =
        properties.find((prop: NodeProperty) => prop.name === 'identifier')?.value || nodeId
      if (
        properties.some((prop: NodeProperty) =>
          ['identifier', 'title', 'nodeClass', 'hierarchy'].includes(prop.name)
        )
      ) {
        const elements =
          ((state: ApplicationState) => state.app.model.diagram?.elements)(yield select()) || []
        const newElements = elements.filter((el) => el.id !== nodeId) as Node[]
        if (type === 'inputselector' || type === 'inputscalar') {
          const newElement = elements.find((el) => el.id === nodeId) as Node
          type === 'inputselector'
            ? (newElement.data.selectorParams = {
                selected_indexes: [0, 1, 2],
                selected_values: ['Item1', 'Item2', 'Item3'],
                multiselect: false
              })
            : (newElement.data.inputScalarValue = '0')
          yield put(updateElements(newElements.concat(newElement)))
          yield put(refreshDiagram())
        } else if (type && type === 'input') {
          yield put(updateElements(newElements))
        } else {
          yield put(updateElements(newElements))
          yield put(refreshDiagram())
        }
      }
      yield put(selectNode(newId))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(unlockPropertiesDialog(true))
  }
}

function* handlePasteNodes({ payload: { center, copyOrCutCenter } }: AnyAction) {
  try {
    const nodeClipboard = ((state: ApplicationState) => state.app.model.diagram?.nodeClipboard)(
      yield select()
    )
    const moduleId = ((state: ApplicationState) => state.app.model.diagram?.moduleId)(
      yield select()
    )
    if (nodeClipboard && moduleId) {
      const nodesToPaste: CopyNodesParams[] = nodeClipboard.nodePositionCopied.map((node) => {
        if (nodeClipboard.nodePositionCopied.length === 1) {
          return {
            identifier: node.id,
            moduleId,
            x: Math.round(center.x),
            y: Math.round(center.y)
          }
        }
        const diffX = Math.abs(copyOrCutCenter.x - node.x)
        const diffY = Math.abs(copyOrCutCenter.y - node.y)
        return {
          identifier: node.id,
          moduleId,
          x:
            copyOrCutCenter.x > node.x
              ? Math.round(center.x - diffX)
              : Math.round(center.x + diffX),
          y:
            copyOrCutCenter.y > node.y ? Math.round(center.y - diffY) : Math.round(center.y + diffY)
        }
      })
      let res: string = ''
      if (nodeClipboard.action === 'copy') {
        res = yield call(modelApi.copyNodes, nodesToPaste)
      } else if (nodeClipboard.action === 'cut') {
        res = yield call(modelApi.moveNodes, nodesToPaste)
      }
      if (res !== '') {
        const pastedNodesIds = res.split(',')
        yield put(selectNode(pastedNodesIds.length === 1 ? pastedNodesIds[0] : undefined))
        yield put(refreshDiagram())
        yield put(forceSelectionOfNodes(pastedNodesIds))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleDuplicateNodes({ payload }: AnyAction) {
  try {
    const moduleId = ((state: ApplicationState) => state.app.model.diagram?.moduleId)(
      yield select()
    )
    if (payload && moduleId) {
      const nodesToPaste: CopyNodesParams[] = payload.map((identifier: string) => {
        return {
          identifier,
          moduleId
        }
      })
      const res: string = yield call(modelApi.copyNodes, nodesToPaste)
      if (res) {
        const pastedNodesIds = res.split(',')
        yield put(selectNode(pastedNodesIds.length === 1 ? pastedNodesIds[0] : undefined))
        yield put(refreshDiagram())
        yield put(forceSelectionOfNodes(pastedNodesIds))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleOpenWizard({
  payload: {
    nodeId,
    wizardType,
    action,
    params,
    editMode,
    nodeTitle,
    isFromDiagram,
    isFromHandlingData
  }
}: AnyAction) {
  try {
    if (nodeId) {
      const selectColumnsData: SelectColumnsWizard = yield call(
        modelApi.openWizard,
        wizardType,
        action,
        params
      )
      yield put(
        setWizardData(
          true,
          nodeId,
          wizardType,
          selectColumnsData,
          editMode,
          nodeTitle,
          isFromDiagram,
          isFromHandlingData
        )
      )
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCopyAsValues({ payload: { nodeId } }: AnyAction) {
  try {
    if (nodeId) {
      yield call(modelApi.copyAsValues, nodeId)
      yield put(refreshDiagram())
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleApplyWizard({
  payload: { nodeId, wizardType, action, params, editMode, modelDiagramId }
}: AnyAction) {
  try {
    yield put(setLoadingManipulateDataWidget(true))
    if (nodeId) {
      let idNode = nodeId
      const wizardTypeValidation =
        (wizardType !== WizardTypes.DataReadWizard && wizardType !== WizardTypes.IndexFromPandas) ||
        action === WizardActions.GenerateNode
      const response: string = yield call(modelApi.applyWizard, wizardType, action, params)
      yield put(setWizardData(false))

      if (
        wizardType === WizardTypes.IndexFromPandas ||
        action === WizardActions.GenerateNode ||
        wizardType === WizardTypes.DataArrayFromPandas
      ) {
        idNode = response
      }
      if (wizardTypeValidation) {
        if (
          action === WizardActions.GenerateNode ||
          wizardType === WizardTypes.DataArrayFromPandas
        ) {
          yield put(navigateToNode(modelDiagramId, idNode))
        }
        yield call(modelApi.run, idNode)
      }
      const codeNode: CodeNode = yield call(
        modelApi.getCodeNodeProperties,
        idNode,
        CODE_NODE_PROPERTIES_TO_GET
      )
      if (codeNode?.identifier) {
        yield put(setSelectedCodeNode(codeNode))
      }
      if (wizardTypeValidation) {
        if (idNode.includes(HANDLE_DATA_WIZARD)) {
          yield put(systemNodeDefinitionHasChanged(true))
          yield call(modelApi.createNodeResultInterface, idNode)
        } else {
          yield call(modelApi.createNodeResultInterface, idNode)
          const preview: string = yield call(modelApi.getNodePreview, idNode)
          yield put(setNodePreview(preview))
          if (wizardType === WizardTypes.CreateIndex) {
            yield put(refreshIndex(true))
          }
          if (!editMode) yield put(refreshDiagram())
        }
      }
      if (wizardType === WizardTypes.IndexFromPandas) {
        yield put(refreshDiagram())
        yield put(navigateToNode(modelDiagramId, idNode))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  } finally {
    yield put(setLoadingManipulateDataWidget(false))
    yield put(disableReDoAction(true))
  }
}

function* handleCloseWizard({ payload: { nodeId, wizardType } }: AnyAction) {
  try {
    if (nodeId) {
      yield put(setWizardData(false))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleApplyTableChanges({ payload: { nodeId } }: AnyAction) {
  try {
    if (nodeId) {
      const codeNode: CodeNode = yield call(
        modelApi.getCodeNodeProperties,
        nodeId,
        CODE_NODE_PROPERTIES_TO_GET
      )
      if (codeNode?.identifier) {
        yield put(setSelectedCodeNode(codeNode))
      }
      const preview: string = yield call(modelApi.getNodePreview, nodeId)
      yield put(setNodePreview(preview))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleSetInputNodeValue({ payload: { inputNodeParams } }: AnyAction) {
  try {
    const { inputType, nodeId, value } = inputNodeParams
    let res: boolean = false
    switch (inputType) {
      case NodeInputs.scalar:
        res = yield call(modelApi.setInputScalarValue, nodeId, value)
        break
      case NodeInputs.selector:
        res = yield call(modelApi.setSelectorValue, nodeId, value)
        break
      case NodeInputs.choice:
        res = yield call(modelApi.setChoiceValue, nodeId, value[0])
        break
      default:
        break
    }
    if (res) {
      yield put(refreshDiagram())
      yield put(selectNode(nodeId))
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleCreateAlias({ payload: { nodeIds } }: AnyAction) {
  try {
    if (nodeIds && nodeIds.length > 0) {
      const res: CreateAliasResponse = yield call(modelApi.createAlias, nodeIds)
      if (res.length > 0) {
        const nodeId = res[0].id
        yield put(refreshDiagram())
        yield put(selectNode(res.length === 1 ? nodeId : undefined))
        const pastedNodesIds = res.map((item) => item.id)
        yield put(forceSelectionOfNodes(pastedNodesIds))
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* runNodeAndExpandWidget(widget: WidgetType.CODE | WidgetType.RESULT) {
  const codeNode = ((state: ApplicationState) => state.app.model.selectedNode?.codeNode)(
    yield select()
  )
  if (codeNode) {
    yield put(runSelectedNode())
    const selectedLayout = ((state: ApplicationState) => state.user.code?.selectedLayout)(
      yield select()
    )
    if (
      (widget === WidgetType.RESULT && selectedLayout === Layout.DiagramAndCode) ||
      (widget === WidgetType.CODE && selectedLayout === Layout.DiagramAndResult)
    ) {
      yield put(setSelectedCodeLayout(Layout.DiagramCodeAndResult))
    }
    yield put(setWidgetExpanded(widget))
  }
}

function* handleRunNodeAndExpandResult() {
  try {
    yield* runNodeAndExpandWidget(WidgetType.RESULT)
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleRunNodeAndExpandCode() {
  try {
    yield* runNodeAndExpandWidget(WidgetType.CODE)
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* updateAndSetElements(newElements: FlowElement<any>[]) {
  const moduleId = ((state: ApplicationState) => state.app.model.diagram?.moduleId)(yield select())
  if (moduleId) {
    const moduleInfo: ModuleInfo = yield call(modelApi.navigateDiagram, moduleId)
    if (moduleInfo) {
      yield put(updateElements(newElements))
      yield put(setElements(moduleInfo))
    }
  }
}

function* handleSetNodeSizeRelativeToOther({
  payload: { direction, nodesToResize, nodeReference }
}: AnyAction) {
  try {
    const ndRef = nodeReference as Node
    const nodesSizePositionArr: NodePositionAndSize[] = nodesToResize.map((nd: Node) => {
      return {
        id: nd.id,
        x: nd.position.x,
        y: nd.position.y,
        h: direction !== 'width' ? ndRef.style?.height : nd.style?.height,
        w: direction !== 'height' ? ndRef.style?.width : nd.style?.width
      }
    })
    const res: boolean = yield call(modelApi.setNodesSize, nodesSizePositionArr)
    if (res) {
      const elements =
        ((state: ApplicationState) => state.app.model.diagram?.elements)(yield select()) || []
      const newElements = elements.filter(
        (el) => !nodesToResize.map((nd: Node) => nd.id).includes(el.id)
      )
      yield updateAndSetElements(newElements)
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleSetNodePositionRelativeToOther({
  payload: { direction, nodesToPosition, nodeReference }
}: AnyAction) {
  try {
    const ndRef = nodeReference as Node
    const nodePositionDataArr: NodePositionWModule[] = nodesToPosition.map((node: Node) => {
      let newX = node.position.x
      let newY = node.position.y
      switch (direction) {
        case 'left':
          newX = ndRef.position.x
          break
        case 'right':
          newX = ndRef.position.x + Number(ndRef.style?.width) - Number(node.style?.width)
          break
        case 'centerH':
          newX = ndRef.position.x + (Number(ndRef.style?.width) - Number(node.style?.width)) / 2
          break
        case 'top':
          newY = ndRef.position.y
          break
        case 'bottom':
          newY = ndRef.position.y + Number(ndRef.style?.height) - Number(node.style?.height)
          break
        case 'centerV':
          newY = ndRef.position.y + (Number(ndRef.style?.height) - Number(node.style?.height)) / 2
          break
        default:
          break
      }

      return {
        id: node.id,
        x: newX,
        y: newY,
        w: node.data.w,
        h: node.data.h,
        moduleId: null
      }
    })
    const res: boolean = yield call(modelApi.setNodesPosition, nodePositionDataArr)
    if (res) {
      const elements =
        ((state: ApplicationState) => state.app.model.diagram?.elements)(yield select()) || []
      const newElements = elements.filter(
        (el) => !nodesToPosition.map((nd: Node) => nd.id).includes(el.id)
      )
      yield updateAndSetElements(newElements)
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* handleSetShowRelatedNodes({ payload: { relation, nodeIds, show } }: AnyAction) {
  try {
    if (nodeIds.length > 0) {
      const property = [
        {
          name: `nodeInfo.${relation}`,
          value: show ? '1' : '0'
        }
      ]
      const nodesWithProperties = nodeIds.map((ndId: string) => {
        return {
          node: ndId,
          properties: property
        }
      })
      const res: boolean = yield call(modelApi.setNodesProperties, nodesWithProperties)
      if (res) {
        yield put(refreshDiagram())
      }
    }
  } catch (error: any) {
    yield put(enqueue(error))
  }
}

function* watchGeneralActions() {
  yield all([takeLatest(ModelActionTypes.INSTALL_LIBRARIES, handleInstallLibraries)])
}

function* watchDiagramActions() {
  yield all([
    takeLatest(ModelActionTypes.NAVIGATE_DIAGRAM, handleNavigateDiagram),
    takeLatest(ModelActionTypes.REFRESH_DIAGRAM, handleRefreshDiagram),
    takeLatest(ModelActionTypes.DELETE_NODES, handleDeleteNodes),
    takeLatest(ModelActionTypes.DELETE_NODES_FROM_IDS, handleDeleteNodesFromIds),
    takeLatest(ModelActionTypes.CREATE_NODE, handleCreateNode),
    takeLatest(ModelActionTypes.COMPLETE_CREATE_NODE, handleCompleteCreateNode),
    takeLatest(ModelActionTypes.MOVE_NODES, handleMoveNodes),
    takeLatest(ModelActionTypes.RESIZE_NODES, handleResizeNodes),
    takeLatest(ModelActionTypes.SELECT_NODE, handleSelectNode),
    takeLatest(ModelActionTypes.NAVIGATE_TO_NODE, handleNavigateToNode),
    takeLatest(
      ModelActionTypes.OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE,
      handleOpenDiagramAndNavigateToNode
    ),
    takeLatest(
      ModelActionTypes.OPEN_DIAGRAM_AND_NAVIGATE_TO_NODE_FROM_GO_TO,
      handleOpenDiagramAndNavigateToNodeFromGoTo
    ),
    takeLatest(UserActionTypes.SET_SELECTED_CODE_LAYOUT, handleSetSelectedCodeLayout),
    takeLatest(ModelActionTypes.OPEN_WIZARD, handleOpenWizard),
    takeLatest(ModelActionTypes.APPLY_WIZARD, handleApplyWizard),
    takeLatest(ModelActionTypes.CLOSE_WIZARD, handleCloseWizard),
    takeLatest(ModelActionTypes.SET_NODE_PROPERTIES, handleSetNodeProperties),
    takeLatest(ModelActionTypes.PASTE_NODES, handlePasteNodes),
    takeLatest(ModelActionTypes.DUPLICATE_NODES, handleDuplicateNodes),
    takeLatest(ModelActionTypes.SET_INPUT_NODE_VALUE, handleSetInputNodeValue),
    takeLatest(ModelActionTypes.CREATE_ALIAS, handleCreateAlias),
    takeLatest(ModelActionTypes.RUN_NODE_AND_EXPAND_RESULT, handleRunNodeAndExpandResult),
    takeLatest(ModelActionTypes.RUN_NODE_AND_EXPAND_CODE, handleRunNodeAndExpandCode),
    takeLatest(ModelActionTypes.SET_NODE_SIZE_BASED_ON_OTHER, handleSetNodeSizeRelativeToOther),
    takeLatest(
      ModelActionTypes.SET_NODE_POSITION_BASED_ON_OTHER,
      handleSetNodePositionRelativeToOther
    ),
    takeLatest(ModelActionTypes.SET_SHOW_RELATED_NODES, handleSetShowRelatedNodes),
    takeLatest(ModelActionTypes.COPY_AS_VALUES, handleCopyAsValues),
    takeLatest(ModelActionTypes.SELECT_AND_RUN_NODE, handleSelectAndRunNode)
  ])
}

function* watchCodetWidgetActions() {
  yield all([
    takeLatest(ModelActionTypes.CONFIRM_CODE_NODE, handleConfirmCodeNode),
    takeLatest(ModelActionTypes.CANCEL_CODE_NODE, handleCancelCodeNode),
    takeLatest(ModelActionTypes.RUN_SELECTED_NODE, handleRunSelectedNode)
  ])
}

function* watchResultWidgetActions() {
  yield all([
    takeLatest(ModelActionTypes.GET_NODE_RESULT, handleGetNodeResult),
    takeLatest(ModelActionTypes.GET_NODE_PREVIEW, handleGetNodePreview),
    takeLatest(ModelActionTypes.TRIGGER_NODE_INTERFACE, handleTriggerNodeInterface),
    takeLatest(ModelActionTypes.APPLY_TABLE_CHANGES, handleApplyTableChanges),
    takeLatest(ModelActionTypes.CONFIRM_AND_UPDATE_SCALAR_NODE, handleConfirmAndUpdateScalarNode)
  ])
}

function* modelSaga() {
  yield all([
    fork(watchGeneralActions),
    fork(watchDiagramActions),
    fork(watchCodetWidgetActions),
    fork(watchResultWidgetActions)
  ])
}

export { modelSaga }
