import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'
import { HomeItem, ITabs } from 'common/types/home'
import { GenericBooleanResponse } from 'common/types/managers'
import {
  AppInstanceInfo,
  AppProperties,
  ExampleItem,
  ShowVersionsOnOpenData
} from 'common/types/application'
import {
  AppVersionsNamesData,
  CreateAppVersionParams,
  GetAppVersionsParams
} from 'common/types/version'

const ENDPOINT = '/apps'

export class ApplicationApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    Object.setPrototypeOf(this, ApplicationApi.prototype)
    this.getAppStatus = this.getAppStatus.bind(this)
    this.saveApp = this.saveApp.bind(this)
    this.saveAppInMyWorkspace = this.saveAppInMyWorkspace.bind(this)
    this.openApp = this.openApp.bind(this)
    this.closeApp = this.closeApp.bind(this)
    this.createApp = this.createApp.bind(this)
    this.getAppProperties = this.getAppProperties.bind(this)
    this.setAppProperties = this.setAppProperties.bind(this)
    this.getDefaultInterface = this.getDefaultInterface.bind(this)
    this.reloadWithMyVenv = this.reloadWithMyVenv.bind(this)
    this.getRequirementsFile = this.getRequirementsFile.bind(this)
    this.replaceAndInstallRequirementsFile = this.replaceAndInstallRequirementsFile.bind(this)
    this.getExamples = this.getExamples.bind(this)
    this.downloadExample = this.downloadExample.bind(this)
    this.getClosedAppVersions = this.getClosedAppVersions.bind(this)
    this.createVersionFromClosedApp = this.createVersionFromClosedApp.bind(this)
    this.versionExistsInClosedApp = this.versionExistsInClosedApp.bind(this)
  }

  public async getHome(): Promise<ITabs> {
    try {
      const res: AxiosResponse<ITabs> = await this.get(`${ENDPOINT}/getHome/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }

  public async openApp(
    filePath: string,
    openAsReadOnly: boolean = false,
    version?: string,
    forceNewInstance?: boolean
  ): Promise<AppInstanceInfo> {
    try {
      const data = {
        folder: filePath,
        openAsReadOnly,
        version,
        forceNewInstance
      }
      const res: AxiosResponse<AppInstanceInfo> = await this.post(`${ENDPOINT}/open/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async closeApp(): Promise<GenericBooleanResponse> {
    try {
      const res: AxiosResponse<GenericBooleanResponse> = await this.get(`${ENDPOINT}/close/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  /**
   * Get Model unsaved changes status
   * @params
   * @returns boolean for hasUnsavedChanges (true =  unsavedChanges)
   */
  public async getAppStatus(): Promise<boolean> {
    try {
      const res: AxiosResponse<boolean> = await this.get(`${ENDPOINT}/status/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async saveApp(): Promise<AppInstanceInfo> {
    try {
      const res: AxiosResponse<AppInstanceInfo> = await this.get(`${ENDPOINT}/save/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async saveAppInMyWorkspace(
    folderName: string,
    versions?: string[]
  ): Promise<AppInstanceInfo> {
    try {
      const res: AxiosResponse<AppInstanceInfo> = await this.post(
        `${ENDPOINT}/saveAppInMyWorkspace/`,
        {
          folder_name: folderName,
          versions
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async uploadThumbnail(data: FormData, token: CancelToken): Promise<boolean> {
    try {
      const res: AxiosResponse<boolean> = await this.post(`${ENDPOINT}/uploadThumbnail/`, data, {
        cancelToken: token
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async removeThumbnail(path: String): Promise<boolean> {
    try {
      const data = { uri: path }
      const res: AxiosResponse<any> = await this.delete(`${ENDPOINT}/removeThumbnail/`, {
        data: data
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async changeToOtherInstance(instanceId: string): Promise<AppInstanceInfo> {
    const query = `?new_instance_id=${instanceId}`
    const res: AxiosResponse<AppInstanceInfo> = await this.get(
      `${ENDPOINT}/changeToOtherInstance/${query}`
    )
    return this.success(res)
  }

  public async createApp(name: string, template: string, onPublic: boolean): Promise<HomeItem> {
    try {
      const res: AxiosResponse<HomeItem> = await this.post(`${ENDPOINT}/create/`, {
        name,
        template,
        onPublic
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async importApp(data: FormData, token: CancelToken): Promise<any> {
    const res: AxiosResponse<any> = await this.post(`${ENDPOINT}/importApp/`, data, {
      cancelToken: token
    })
    return this.success(res)
  }
  public async deleteApp(path: String): Promise<any> {
    try {
      const data = { uri: path }
      const res: AxiosResponse<any> = await this.delete(`${ENDPOINT}/delete/`, {
        data: data
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async renameApp(uri: string, name: string): Promise<any> {
    try {
      const res: AxiosResponse<any> = await this.put(`${ENDPOINT}/rename/`, {
        uri,
        name
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getAppProperties(): Promise<AppProperties> {
    try {
      const res: AxiosResponse<AppProperties> = await this.get(`${ENDPOINT}/getAppProperties/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async setAppProperties(appProperties: AppProperties): Promise<AppInstanceInfo> {
    try {
      const res: AxiosResponse<AppInstanceInfo> = await this.post(
        `${ENDPOINT}/setAppProperties/`,
        appProperties
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getDefaultInterface(): Promise<{ id: string; name: string } | undefined> {
    try {
      const res: AxiosResponse<{ id: string; name: string } | undefined> = await this.get(
        `${ENDPOINT}/getDefaultInterface/`
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async reloadWithMyVenv(): Promise<AppInstanceInfo> {
    const res: AxiosResponse<AppInstanceInfo> = await this.get(`${ENDPOINT}/reloadWithMyVenv/`)
    return this.success(res)
  }
  public async createAppFromThis(
    name: string,
    source: string
  ): Promise<{ name: string; source: string }> {
    try {
      const data = { name: name.trim(), source: source.trim() }
      const res: AxiosResponse<any> = await this.post(`${ENDPOINT}/createAppFromThis/`, data)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getRequirementsFile(): Promise<string> {
    try {
      const res: AxiosResponse<string> = await this.get(`${ENDPOINT}/getRequirementsFile/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async replaceAndInstallRequirementsFile(value: string): Promise<void> {
    try {
      const res: AxiosResponse<void> = await this.post(
        `${ENDPOINT}/replaceAndInstallRequirementsFile/`,
        value
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getExamples(): Promise<ExampleItem[]> {
    try {
      const res: AxiosResponse<ExampleItem[]> = await this.get(`${ENDPOINT}/getExamples/`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async downloadExample(name: string): Promise<HomeItem> {
    try {
      const res: AxiosResponse<HomeItem> = await this.post(`${ENDPOINT}/downloadExample/`, {
        name
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async showVersionsOnOpen(folder: string): Promise<ShowVersionsOnOpenData> {
    try {
      const data = { folder }
      const res: AxiosResponse<ShowVersionsOnOpenData> = await this.post(
        `${ENDPOINT}/showVersionsOnOpen/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async getClosedAppVersions(data: GetAppVersionsParams): Promise<AppVersionsNamesData> {
    try {
      const res: AxiosResponse<AppVersionsNamesData> = await this.post(
        `${ENDPOINT}/getClosedAppVersions/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async createVersionFromClosedApp(
    folder: string,
    data: CreateAppVersionParams
  ): Promise<AppInstanceInfo> {
    try {
      const allData = { folder, ...data }
      const res: AxiosResponse<AppInstanceInfo> = await this.post(
        `${ENDPOINT}/createVersionFromClosedApp/`,
        allData
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async versionExistsInClosedApp(
    folder: string,
    versionName: string,
    canWriteInDir: boolean
  ): Promise<GenericBooleanResponse> {
    try {
      const data = { folder, version_name: versionName, can_write_in_dir: canWriteInDir }
      const res: AxiosResponse<GenericBooleanResponse> = await this.post(
        `${ENDPOINT}/versionExistsInClosedApp/`,
        data
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const applicationApi = new ApplicationApi(apiConfig)
