import { Suspense, ReactElement, FC } from 'react'

import LoadingIndicator from './layout/loading'
interface Props {
  children: ReactElement
}

const SuspenseWrapper: FC<Props> = ({ children }) => {
  return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
}

export default SuspenseWrapper
