import {
  AssistantBotAssistantType,
  AssistantBotChatMessage,
  AssistantBotEngineType
} from 'common/types/assistantBot'

export enum AssistantBotActionTypes {
  SHOW = '@@assistantBot/SHOW',
  QUERY = '@@assistantBot/QUERY',
  APPEND_MESSAGE = '@@assistantBot/APPEND_MESSAGE',
  UPDATE_MESSAGES = '@@assistantBot/UPDATE_MESSAGES',
  CLEAR_MESSAGE = '@@assistantBot/CLEAR_MESSAGE',
  SET_POSITION = '@@assistantBot/SET_POSITION',
  SET_SIZE = '@@assistantBot/SET_SIZE',
  SET_FLOATING = '@@assistantBot/SET_FLOATING',
  LISTEN_BOT_MESSAGES = '@@socket/LISTEN_BOT_MESSAGES',
  SET_EXCLUDED_THREAD_ID = '@@assistantBot/SET_EXCLUDED_THREAD_ID',
  CLEAR_EXCLUDED_THREAD_ID = '@@assistantBot/CLEAR_EXCLUDED_THREAD_ID',
  SET_ASSISTANT_BOT_TYPE = '@@assistantBot/SET_ASSISTANT_BOT_TYPE',
  SET_ASSISTANT_BOT_TYPE_QUESTION_ANSWERED = '@@assistantBot/SET_ASSISTANT_BOT_TYPE_QUESTION_ANSWERED',
  SHOW_APPLICATION_ASSISTANT_BOT = '@@assistantBot/SHOW_APPLICATION_ASSISTANT_BOT',
  SET_APPLICATION_ASSISTANT_BOT = '@@assistantBot/SET_APPLICATION_ASSISTANT_BOT'
}

export enum AssistantBotKey {
  general = 'general',
  code = 'code',
  tests = 'tests'
}

interface BaseAssistantBotProperties {
  readonly assistantBotTypeQuestionAnswered: false
  readonly messages: AssistantBotChatMessage[]
  readonly excludedThreadIds: string[]
  readonly show?: false
  readonly isFloating?: false
  readonly position?: { x: number; y: number }
  readonly size?: { width: number; height: number }
  readonly listenBotMessages?: boolean
  readonly assistantBotId?: string | null
  readonly assistantBotEngineType?: AssistantBotEngineType
}

export interface GeneralAssistantBotProperties extends BaseAssistantBotProperties {
  readonly assistantBotType: AssistantBotAssistantType.pyplan
}

export interface CodeAssistantBotProperties extends BaseAssistantBotProperties {
  readonly assistantBotType: AssistantBotAssistantType.code
}

export interface TestsAssistantBotProperties extends BaseAssistantBotProperties {
  readonly assistantBotType: AssistantBotAssistantType.application
}

export interface AssistantBotState {
  readonly general: GeneralAssistantBotProperties
  readonly code: CodeAssistantBotProperties
  readonly tests: TestsAssistantBotProperties
  readonly startApplicationAssistantBot?: string
}
