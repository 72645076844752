import { Reducer } from 'redux'

import {
  NotifierActionTypes,
  NotifierState,
  Notification,
  BlockerActionTypes,
  BlockerContent
} from './types'

const initState: NotifierState = {
  notifications: [],
  blockers: []
}

type NotifierAction = {
  type: NotifierActionTypes
  key: string
  notification: Notification
  dismissed?: boolean
  dismissAll?: boolean
}

type BlockerAction = {
  type: BlockerActionTypes
  blocker: BlockerContent
}

const notifierReducer: Reducer<NotifierState, NotifierAction | BlockerAction> = (
  state: NotifierState = initState,
  action: NotifierAction | BlockerAction
) => {
  switch (action.type) {
    case NotifierActionTypes.ENQUEUE_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
            key: action.notification.key
          }
        ]
      }
    case NotifierActionTypes.CLOSE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      }
    case NotifierActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.key !== action.key)
      }
    case BlockerActionTypes.ENQUEUE_BLOCKER:
      return {
        ...state,
        blockers: [
          ...state.blockers,
          {
            ...action.blocker,
            key: action.blocker.key
          }
        ]
      }
    default:
      return state
  }
}

export { notifierReducer }
