import { StatusInternalTests } from 'common/types/internalTests'
import { UploaderParams, SocketInstanceResources } from './constants'
import { SocketActionTypes, SocketProgressState, WebSocketConnectionStatus } from './types'
import { AssistantBotKey } from 'store/assistantBot/types'

/**
 * start channel
 */
export const startWSServer = () => ({ type: SocketActionTypes.START_SERVER })

/**
 * stop channel
 */
export const stopWSServer = () => ({ type: SocketActionTypes.STOP_SERVER })

/**
 * set global app progress
 */
export const setProgress = (payload: SocketProgressState) => ({
  type: SocketActionTypes.SET_PROGRESS,
  payload
})
export const setProgressBar = (payload: SocketProgressState) => ({
  type: SocketActionTypes.SET_PROGRESSBAR,
  payload
})
export const hideProgressBar = () => ({
  type: SocketActionTypes.HIDE_PROGRESSBAR
})

/**
 * Update resources for the current instance
 */
export const updateInstanceResources = (payload: SocketInstanceResources) => ({
  type: SocketActionTypes.UPDATE_INSTANCE_RESOURCES,
  payload
})

export const setUploaderParams = (payload?: UploaderParams) => ({
  type: SocketActionTypes.SET_UPLOADER_PARAMS,
  payload
})

export const internalTestsStatus = (payload: StatusInternalTests) => ({
  type: SocketActionTypes.INTERNAL_TESTS_STATUS,
  payload
})

export const resetInternalTests = () => ({
  type: SocketActionTypes.RESET_INTERNAL_TESTS
})

/**
 * Assistant Bot
 */
export const setAssistantBotStopped = (assistantBotKey: AssistantBotKey, stopped: boolean) => ({
  type: SocketActionTypes.SET_ASSISTANT_BOT_STOPPED,
  payload: { assistantBotKey, stopped }
})

export const setAssistantBotThreadMessageId = (
  assistantBotKey: AssistantBotKey,
  threadMessageId: string | undefined
) => ({
  type: SocketActionTypes.SET_ASSISTANT_BOT_THREAD_MESSAGE_ID,
  payload: { assistantBotKey, threadMessageId }
})

/**
 * App Lock Info
 */
export const setAppLockInfo = (appIsLocked: boolean) => ({
  type: SocketActionTypes.SET_APP_LOCK_INFO,
  payload: appIsLocked
})

/**
 * Refresh app notifications
 */
export const setRefreshAppNotifications = (refresh: boolean) => ({
  type: SocketActionTypes.SET_REFRESH_APP_NOTIFICATIONS,
  payload: refresh
})

/**
 * Channel connection status
 */
export const setPreviousWebSocketConnectionStatus = (status: WebSocketConnectionStatus) => ({
  type: SocketActionTypes.SET_WEBSOCKET_PREVIOUS_CONNECTION_STATUS,
  payload: status
})
