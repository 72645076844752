import { StrictMode } from 'react'
import { render } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'

import Main from './main'

import './assets/index.css'

import reportWebVitals from './reportWebVitals'

import configureStore from './configureStore'
import { Provider } from 'react-redux'

export const store = configureStore()
/**
 * TODO: lo volvemos a implementar cuando podamos identificar mejor los errores que lanzan pantalla en blanco de aquellos que no lo hacen
 */
// window.onerror = function (message, source, lineno, colno, error) {
//   console.error('Global error caught:', { message, source, lineno, colno, error })
//   const currentLocation = window.location.href
//   // To prevent false positives, only redirect if there is an error that is not a DOM event error
//   if (!isDOMEventError(error)) {
//     window.location.href = `/error?message=${message}&location=${currentLocation}`
//   }
// }

// const isDOMEventError = (error?: Error) => {
//   if (!error || !error.stack) {
//     return false
//   }
// window.onerror = function (message, source, lineno, colno, error) {
//   console.error('Global error caught:', { message, source, lineno, colno, error })
//   const currentLocation = window.location.href
// To prevent false positives, only redirect if there is an error that is not a DOM event error
// if (!isDOMEventError(error)) {
//   window.location.href = `/error?message=${message}&location=${currentLocation}`
// }
// }

//   // Check if the error was thrown in a DOM event handler
//   const domEventPattern = /at (HTML(?!UnknownElement)\w+Element|Document|Window)\./
//   return domEventPattern.test(error.stack)
// }

render(
  <StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Main />
      </HelmetProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
