import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { Api } from 'common/api/api'
import { apiConfig } from 'common/api/api.config'
import { CheckLicenseResponse, LicenseParams, LicenseData } from 'common/types/license'

const ENDPOINT = '/license'

class LicenseApi extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config)

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param
    }))

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param
    }))

    // to use in sagas
    Object.setPrototypeOf(this, LicenseApi.prototype)
    this.checkDaysToExpire = this.checkDaysToExpire.bind(this)
  }
  /**
   * Check if Company Code (folder name) is already used
   *
   * @param {code} string
   * @returns {"result":boolean}
   */
  public async checkLicense(params: LicenseParams): Promise<CheckLicenseResponse> {
    try {
      const res: AxiosResponse<CheckLicenseResponse> = await this.get(
        `${ENDPOINT}/check_license/`,
        {
          params: { ...params }
        }
      )
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async updateLicense(companyId: string, licenseKey: string): Promise<LicenseData> {
    const data = {
      license_key: licenseKey
    }
    try {
      const res: AxiosResponse<LicenseData> = await this.put(`${ENDPOINT}/${companyId}/`, data, {
        headers: { 'Content-Type': 'application/json' }
      })
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
  public async checkDaysToExpire(companyId: number): Promise<any> {
    try {
      const query = `?companyId=${companyId}`
      const res: AxiosResponse<any> = await this.get(`${ENDPOINT}/checkDaysToExpire/${query}`)
      return this.success(res)
    } catch (error) {
      throw error
    }
  }
}

export const licenseApi = new LicenseApi(apiConfig)
