import { AssistantBotAssistantType } from 'common/types/assistantBot'
import { AssistantBotKey } from 'store/assistantBot'

export const getAssistantBotStateKey = (assistantType: AssistantBotAssistantType) => {
  switch (assistantType) {
    case AssistantBotAssistantType.code:
      return AssistantBotKey.code
    case AssistantBotAssistantType.application_tests:
      return AssistantBotKey.tests
    case AssistantBotAssistantType.pyplan:
    case AssistantBotAssistantType.application:
    default:
      return AssistantBotKey.general
  }
}
