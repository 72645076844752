import React from 'react'

import { CircularProgress, Theme } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20%',
    width: '100%'
  }
}))

const LoadingIndicator = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

export default LoadingIndicator
