import { Reducer } from 'redux'

import { SocketState, SocketActionTypes, WebSocketConnectionStatus } from './types'
import { AssistantBotKey } from 'store/assistantBot'

export const initialState: SocketState = {
  previousWebSocketConnectionStatus: WebSocketConnectionStatus.ON,
  webSocketConnectionStatus: WebSocketConnectionStatus.ON,
  progress: { show: false, value: 0 },
  instanceResources: undefined,
  uploader: undefined,
  appIsLocked: false,
  assistantBot: {
    general: {},
    code: {},
    tests: {}
  }
}

const reducer: Reducer<SocketState> = (
  state: SocketState = initialState,
  action: { type: SocketActionTypes; payload?: any }
) => {
  switch (action.type) {
    case SocketActionTypes.WEBSOCKET_CONNECTION_STATUS_OFF: {
      return { ...state, webSocketConnectionStatus: WebSocketConnectionStatus.OFF }
    }
    case SocketActionTypes.WEBSOCKET_CONNECTION_STATUS_ON: {
      return { ...state, webSocketConnectionStatus: WebSocketConnectionStatus.ON }
    }
    case SocketActionTypes.SET_WEBSOCKET_PREVIOUS_CONNECTION_STATUS: {
      return { ...state, previousWebSocketConnectionStatus: action.payload }
    }
    case SocketActionTypes.SET_PROGRESS: {
      return { ...state, progress: { ...state.progress, ...action.payload } }
    }
    case SocketActionTypes.SET_PROGRESSBAR: {
      return { ...state, progressbar: { ...state.progressbar, ...action.payload } }
    }
    case SocketActionTypes.HIDE_PROGRESSBAR: {
      return { ...state, progressbar: undefined }
    }
    case SocketActionTypes.UPDATE_INSTANCE_RESOURCES: {
      return { ...state, instanceResources: action.payload }
    }
    case SocketActionTypes.SET_UPLOADER_PARAMS: {
      return { ...state, uploader: action.payload }
    }
    case SocketActionTypes.INTERNAL_TESTS_STATUS: {
      return { ...state, internalTests: action.payload }
    }
    case SocketActionTypes.RESET_INTERNAL_TESTS: {
      return { ...state, internalTests: undefined }
    }
    case SocketActionTypes.SET_ASSISTANT_BOT_STOPPED: {
      const assistantBotKey: AssistantBotKey = action.payload.assistantBotKey
      return {
        ...state,
        assistantBot: {
          ...state.assistantBot,
          [action.payload.assistantBotKey]: {
            ...state.assistantBot?.[assistantBotKey],
            stopped: action.payload.stopped
          }
        }
      }
    }
    case SocketActionTypes.SET_ASSISTANT_BOT_THREAD_MESSAGE_ID: {
      const assistantBotKey: AssistantBotKey = action.payload.assistantBotKey
      return {
        ...state,
        assistantBot: {
          ...state.assistantBot,
          [action.payload.assistantBotKey]: {
            ...state.assistantBot?.[assistantBotKey],
            threadMessageId: action.payload.threadMessageId
          }
        }
      }
    }
    case SocketActionTypes.SET_APP_LOCK_INFO: {
      return { ...state, appIsLocked: action.payload }
    }
    case SocketActionTypes.SET_REFRESH_APP_NOTIFICATIONS: {
      return { ...state, refreshAppNotifications: action.payload }
    }
    default: {
      return state
    }
  }
}

export { reducer as socketReducer }
