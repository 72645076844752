import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { Button, Theme } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { closeSnackbar } from 'store/notifier'
import messages from './messages'

const useStyles = makeStyles((theme: Theme) => ({
  notiButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.altText.main,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.altText.main
    }
  }
}))

type Props = {
  enqKey: string | number
}
const CloseBtnEnq: FC<Props> = ({ enqKey }) => {
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()
  const closeSnack = () => {
    dispatch(closeSnackbar(enqKey))
  }
  return (
    <Button className={classes.notiButton} onClick={closeSnack} data-py-id='dismissNotifier'>
      {intl.formatMessage(messages.dismissNotifier)}
    </Button>
  )
}

const closeAction = (enqKey: string | number) => {
  return <CloseBtnEnq enqKey={enqKey} />
}

export default closeAction
