import React, { Component, ComponentType, ErrorInfo, ReactNode } from 'react'
import { IntlShape, useIntl } from 'react-intl'

import { withStyles, WithStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface WithIntlProps {
  intl?: IntlShape
}

function withIntl<T>(Component: ComponentType<T & WithIntlProps>) {
  return (props: T) => {
    const intl = useIntl()
    return <Component {...props} intl={intl} />
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      height: '100%',
      width: '100%',
      padding: theme.spacing(2),
      overflow: 'scroll'
    },
    rootCenter: {
      alignItems: 'center'
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center'
    },
    title: {
      fontSize: '1.3rem',
      fontWeight: 500
    },
    icon: {
      color: theme.palette.error.main
    },
    details: {
      whiteSpace: 'pre-wrap',
      fontSize: '0.9rem',
      height: '100%',
      width: '100%'
    },
    extraContent: {
      fontSize: '1rem',
      color: theme.palette.primary.main
    },
    extraContentLink: {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  })

interface ErrorBoundaryProps extends WithStyles<typeof styles>, WithIntlProps {
  children: ReactNode
  showReload?: boolean
  centerContent?: boolean
  extraContentText1?: string
  extraContentOnClick1?: () => void
  extraContentText2?: string
  extraContentOnClick2?: () => void
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ errorInfo })
    console.error('ErrorBoundary caught an error', error, errorInfo)
  }

  triggerError(error: Error, errorInfo?: ErrorInfo): void {
    this.setState({ hasError: true, error, errorInfo })
  }

  render(): ReactNode {
    const {
      classes,
      intl,
      showReload = true,
      centerContent = false,
      extraContentText1,
      extraContentOnClick1,
      extraContentText2,
      extraContentOnClick2
    } = this.props

    if (this.state.hasError) {
      return (
        <div className={`${classes.root} ${!!centerContent ? classes.rootCenter : ''}`}>
          <div className={classes.titleWrapper}>
            <FontAwesomeIcon icon={faExclamationCircle} className={classes.icon} />
            <span className={classes.title}>
              {intl?.formatMessage({ id: 'common.somethingWentWrong' })}.
            </span>
          </div>
          {!!extraContentText1 && (
            <span
              className={`${classes.extraContent} ${
                !!extraContentOnClick1 ? classes.extraContentLink : ''
              }`}
              onClick={extraContentOnClick1}
            >
              {extraContentText1}
            </span>
          )}
          {!!extraContentText2 && (
            <span
              className={`${classes.extraContent} ${
                !!extraContentOnClick2 ? classes.extraContentLink : ''
              }`}
              onClick={extraContentOnClick2}
            >
              {extraContentText2}
            </span>
          )}
          {!!showReload && (
            <span
              className={`${classes.extraContent} ${classes.extraContentLink}`}
              onClick={() => window.location.reload()}
            >
              {intl?.formatMessage({ id: 'common.reloadPage' })}
            </span>
          )}
          <div>
            <details className={classes.details}>
              <summary>{intl?.formatMessage({ id: 'common.details' })}</summary>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo?.componentStack}
            </details>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default withIntl(withStyles(styles)(ErrorBoundary))
