import { OptionsObject } from 'notistack'

export enum NotifierActionTypes {
  ENQUEUE_NOTIFICATION = '@@notifier/ENQUEUE_NOTIFICATION',
  CLOSE_NOTIFICATION = '@@notifier/CLOSE_NOTIFICATION',
  REMOVE_NOTIFICATION = '@@notifier/REMOVE_NOTIFICATION'
}

export enum NotificationLevels {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default'
}

export type MessageNotification = {
  key?: string
  title?: string
  message: string
  options: OptionsObject
  data?: any
  dismissed?: boolean
  autoHideDuration?: number
}

export interface Notification extends MessageNotification {
  key: string
}

export enum BlockerActionTypes {
  ENQUEUE_BLOCKER = '@@blocker/ENQUEUE_BLOCKER'
}

export enum BlockerActions {
  LOGOUT = 'LOGOUT',
  CLOSE_INSTANCE = 'CLOSE_INSTANCE'
}

export interface Blocker {
  action: BlockerActions
  title?: string
  message?: string
  instanceId?: string
}

export interface BlockerContent extends Blocker {
  key: string
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface NotifierState {
  readonly notifications: Notification[]
  readonly blockers: BlockerContent[]
}
